import React from 'react';
import PropTypes from 'prop-types';

import { TextLabel } from 'view-components/components/Widgets/Components';
import RetinaImage from 'view-components/components/RetinaImage';

import withMessages from 'component-utilities/intl';

import '../ActionsBase.scss';

import DownloadIcon from '../images/download-normal.png';
import { Asset } from 'global/types';
import { apiReqPromise } from '../../../lib/apiRequest';
import jsApi from '../../../lib/jsApi';

type Props = {
  getMessage: Function
  asset: Asset
}

function DownloadComponent({ getMessage, asset }: Props) {
  const isDisabled = asset.isLoading;

  return (
    <div className={`action-wrapper${isDisabled ? '-disabled' : ''}`}>
      <TextLabel className="action-label">
        <RetinaImage className="action-image" image={DownloadIcon} />
        {getMessage('app.table_actions.download')}
      </TextLabel>
      {!isDisabled && (
        <a
          onClick={async () => {
            const res = await apiReqPromise(jsApi.assetsDownload, {
              urlParams: {
                asset_id: asset.id,
              },
            });
            const data = new Blob([res.data])
            const tempUrl = window.URL.createObjectURL(data)
          
            const tempLink = document.createElement('a')
            tempLink.href = tempUrl
            tempLink.setAttribute('download', `${asset.id}.eml`);
            tempLink.click()
          }}
          className="download-anchor"
          data-action="download-action"
        >
          &nbsp;
        </a>
      )}
    </div>
  );
}

DownloadComponent.propTypes = {
  asset: PropTypes.shape({
    data: PropTypes.shape({
      download_url: PropTypes.string,
    }),
  }).isRequired,
  getMessage: PropTypes.func.isRequired,
};

const Download = withMessages(DownloadComponent);

export default Download;
