/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import toggleInArray from 'component-lib/toggleInArray';
import Shared from 'v-c/Modals/Shared';
import moment from 'moment';

import { ModalContainer } from '../Modals';
import { ModalProps } from 'global/types';

interface Props extends ModalProps {
  isLoading: boolean
}

type State = {
  selectedItems: any[]
  originalSelectedItems: any[]
}

export default class SelectListModal extends Component<Props, State> {
  static propTypes = {
    isLoading: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    modalOptions: PropTypes.shape({
      searchLists: PropTypes.array.isRequired,
      type: PropTypes.string.isRequired,
      criterion: PropTypes.string.isRequired,
      onListApply: PropTypes.func.isRequired,
      currentItems: PropTypes.array.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    isLoading: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedItems: props.modalOptions.currentItems.map(
        currentItem => currentItem.id,
      ),
      originalSelectedItems: props.modalOptions.currentItems.map(
        currentItem => currentItem.id,
      ),
    };
  }

  onCheckItem = (id: string) => () => {
    const newSelectedItems = toggleInArray(this.state.selectedItems, id);
    this.setState({ selectedItems: newSelectedItems });
  };

  timeSortable = (searchListsSort: any[]) =>
    searchListsSort
      .filter(searchList => searchList.type === this.props.modalOptions.type)
      .map(searchList => ({
        lastModifiedTime: moment(searchList.lastModifiedTime),
        name: searchList.name,
        id: searchList.id,
      }));

  render() {
    const {
      isLoading,
      modalOptions: { onListApply, criterion, searchLists },
    } = this.props;
    const searchListsModalData = this.timeSortable(searchLists);
    return (
      <ModalContainer isProcessing={isLoading}>
        <div data-modal-saved-lists>
          <Shared.SavedLists
            items={searchListsModalData}
            selectedItems={this.state.selectedItems}
            onClickCheckItem={this.onCheckItem}
            originalSelectedItems={this.state.originalSelectedItems}
            onCloseClick={() =>
              this.props.closeModal({ from: 'SelectListModal/close' })
            }
            onPrimaryClick={() => {
              onListApply(this.state.selectedItems, criterion);
            }}
            onSecondaryClick={() =>
              this.props.closeModal({ from: 'SelectListModal/cancel' })
            }
          />
        </div>
      </ModalContainer>
    );
  }
}
