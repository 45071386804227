/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isNull, isArray, contains } from 'lodash';
import withMessages from 'component-utilities/intl';

import { LargeCheckbox } from 'v-c/Forms/Checkboxes';
import { DropdownMultiSelect } from 'view-components/components/Widgets/Components';
import toggleInArray from 'component-lib/toggleInArray';

import criteriaViewBase from './CriteriaViewBase';
import headerTextRender from '../Components/HeaderTextRender';

import CriteriaMultiSelect from './CriteriaMultiSelectView';

import styles from './CriteriaViewBase.scss';
import { KEYWORD_LISTS } from '../../../../../data/Criteria';

export default function CriteriaMultiSelectViewComposer({ init = () => {} }) {
  @withMessages
  @headerTextRender
  @criteriaViewBase
  class CriteriaMultiSelectViewHelperComponent extends Component {
    static propTypes = {
      registerCalculateVisibleCriteriaNum: PropTypes.func.isRequired,
      registerIsEmptyCriteria: PropTypes.func.isRequired,
      registerRenderHeaderText: PropTypes.func.isRequired,
      registerIsApplyButtonDisabled: PropTypes.func.isRequired,
      form: PropTypes.object.isRequired,
      changeFormValue: PropTypes.func.isRequired,
      getMessage: PropTypes.func.isRequired,
      listElemResources: PropTypes.object.isRequired,
      renderGreenText: PropTypes.func.isRequired,
      getOriginalFormValues: PropTypes.func.isRequired,
      isDirty: PropTypes.func.isRequired,
      calculateVisibleCriteriaNumHelper: PropTypes.func.isRequired,
      renderHeaderTextHelper: PropTypes.func.isRequired,
      listElem: PropTypes.object.isRequired,
      isViewOnly: PropTypes.bool.isRequired,
      renderContent: PropTypes.func.isRequired,
      showMatchKeywordExact: PropTypes.bool.isRequired,
    };

    constructor(props) {
      super(props);

      props.registerCalculateVisibleCriteriaNum(
        this.calculateVisibleCriteriaNum,
      );
      props.registerIsEmptyCriteria(this.isEmptyCriteria);
      props.registerRenderHeaderText(this.renderHeaderText);
      props.registerIsApplyButtonDisabled(this.isApplyButtonDisabled);

      init.apply(this, [props]);
    }

    onFieldsChanged = (widgetId, newValues) => {
      const { form: { selectedFields } } = this.props;

      this.props.changeFormValue(
        widgetId,
        toggleInArray(selectedFields, newValues),
      );
    };

    getHeaderCalculationObject = (formValues) => {
      const { selectedCriteria = [], selectedFields = [] } = formValues;

      if (selectedCriteria[0] === '__any__') {
        return {
          formValues,
          list: [this.props.getMessage('app.selections.all')],
        };
      }

      const { listElemResources } = this.props;
      const listResource = listElemResources[this.listResourceName];

      const modifiedList = listResource
        .filter(({ id }) => contains(selectedCriteria, id))
        .map(({ value }) => value);

      let preText = '';

      if (!isNull(this.criteriaFields) && isArray(this.criteriaFields)) {
        const criteriaFields = this.criteriaFields.reduce((criteria, field) => {
          let modifiedCriteria = criteria;
          if (contains(selectedFields, field.id)) {
            modifiedCriteria = criteria
              ? [criteria, field.value].join(', ')
              : field.value;
          }
          return modifiedCriteria;
        }, '');

        preText = this.props.renderGreenText(
          `${this.prefixText} ${criteriaFields}`,
        );
      }

      return {
        formValues,
        list: modifiedList,
        preText,
        visibleCriteria: this.state.visibleCriteria,
      };
    };

    isEmptyCriteria = (newValues = this.props.getOriginalFormValues()) => {
      const { selectedCriteria, selectedFields } = newValues;

      return (
        !selectedCriteria.length || (selectedFields && !selectedFields.length)
      );
    };

    shouldShowFieldDropdown = () =>
      !isNull(this.criteriaFields) && isArray(this.criteriaFields);

    isApplyButtonDisabled = () => {
      const isDirty = this.props.isDirty();
      const {
        form: { selectedCriteria, selectedFields, isAnySelected },
      } = this.props;

      if (
        (!selectedCriteria.length && !isAnySelected) ||
        (selectedFields && !selectedFields.length)
      ) {
        return true;
      }

      return !isDirty;
    };

    changeAnyValue = () => {
      const { form: { isAnySelected } } = this.props;
      const selectedCriteria = !isAnySelected ? ['__any__'] : [];

      this.props.changeFormValue('selectedCriteria', selectedCriteria, () => {
        this.props.changeFormValue('isAnySelected', !isAnySelected);
      });
    };

    changeSelectedCriteria = (widgetId, newValues) => {
      const { form: { isAnySelected } } = this.props;

      const modifiedNewValues = isAnySelected ? [newValues[1]] : newValues;

      this.props.changeFormValue(widgetId, modifiedNewValues, () => {
        if (isAnySelected) {
          this.props.changeFormValue('isAnySelected', false);
        }
      });
    };

    calculateVisibleCriteriaNum = (
      formValues = this.props.getOriginalFormValues(),
    ) => {
      const visibleCriteria = this.props.calculateVisibleCriteriaNumHelper(
        this.getHeaderCalculationObject(formValues),
      );

      if (visibleCriteria) {
        this.setState({ visibleCriteria });
      }
    };

    renderHeaderText = () => {
      const formValues = this.props.listElem.values;
      const { selectedCriteria = [] } = formValues;

      const headerCalculationObject = this.getHeaderCalculationObject(
        formValues,
      );

      if (selectedCriteria.length) {
        return this.props.renderHeaderTextHelper(headerCalculationObject);
      }

      return null;
    };

    renderCriteriaDropdownContent = () => {
      const { listElem: { criteriaType }, isViewOnly } = this.props;
      const {
        form: {
          isSeparatedAnyLogic,
          isAnySelected,
          selectedCriteria = [],
          selectedFields = [],
          selectAllOption = true,
          isKeywordExact = false,
        },
        showMatchKeywordExact,
      } = this.props;
      const createList = () => {
        const { listElemResources } = this.props;
        return listElemResources[this.listResourceName];
      };

      const multiSelectProps = {
        widgetId: 'selectedCriteria',
        list: createList(),
        selectedValues: selectedCriteria,
        onChange: this.changeSelectedCriteria,
        onAnyChange: this.changeAnyValue,
        isSeparatedAnyLogic,
        isAnySelected,
        selectAllOption,
        isViewOnly,
      };
      return (
        <div
          className={styles[`${criteriaType}-criteria-dropdown-content`]}
          data-criteria-content={criteriaType}
        >
          {this.shouldShowFieldDropdown() && (
            <div className={styles[`${criteriaType}-group-wrapper`]}>
              <DropdownMultiSelect
                widgetId="selectedFields"
                prefixText={this.dropdownPrefixText}
                color="gray"
                list={this.criteriaFields}
                selectedValues={selectedFields}
                onSelectedValue={!isViewOnly && this.onFieldsChanged}
              />
            </div>
          )}
          {showMatchKeywordExact && criteriaType === KEYWORD_LISTS && (
            <div className={styles.exact}>
              <LargeCheckbox
                checked={isKeywordExact}
                onClick={() => this.props.changeFormValue(
                  'isKeywordExact',
                  !isKeywordExact,
                )}
              >
                {this.props.getMessage('app.criterias.exact')}
              </LargeCheckbox>
            </div>)
          }
          <CriteriaMultiSelect {...multiSelectProps} />
        </div>
      );
    };

    render() {
      return this.props.renderContent(this.renderCriteriaDropdownContent);
    }
  }

  return CriteriaMultiSelectViewHelperComponent;
}
