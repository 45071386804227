import { intersection } from 'lodash';

import CONSTANTS from '../../../config/constants';
import IfFeature from '../../../global/components/IfFeature';
import SETTINGS_DETAILS from '../../../config/constants/settings.json';

// this checks two things for the settings page, that a feature is enabled
// and that the users role is authorized for that specific setting. If both
// are enabled and valid, the setting (ie, policies) is visible.
export default function getVisibleRows({ activeRoles, domainInfo }: any) {
  if (!domainInfo) {
    return {};
  }

  const views = { ...CONSTANTS.SETTINGS };

  SETTINGS_DETAILS.forEach(({ id }) => {
    const settingID = views[id].split('.').pop();

    if (IfFeature.isDisabled(views[id]) && IfFeature.isDisabled(settingID)) {
      delete views[id];
    }

    const details = SETTINGS_DETAILS.find(
      detail => detail.id.toLowerCase() === settingID.toLowerCase(),
    );
    if (details) {
      if (intersection(details.allowedRoles, activeRoles).length === 0) {
        delete views[id];
      }
    }
  });

  if (!domainInfo.ssoSettingsEnabled) {
    delete views.SINGLE_SIGN_ON;
  }

  if (!domainInfo.adSyncSettingsEnabled) {
    delete views.AD_SYNC;
  }

  if (!domainInfo.o365SettingsEnabled) {
    delete views.O365_INTEGRATION;
  }

  return views;
}
