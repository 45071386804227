import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';
import { PromiseObject, Action } from '../reduxStore';

export const BULK_UPLOAD_USERS_START: string = 'bulkUploadUsers/start';
export const BULK_UPLOAD_USERS_FINISHED: string = 'bulkUploadUsers/finished';
export const BULK_UPLOAD_USERS_FAILED: string = 'bulkUploadUsers/failed';
export const RESET: string = 'bulkUploadUsers/reset';

interface BulkUploadUsersState {
  isProcessing: boolean,
  isFinished: boolean,
  isFailed: boolean,
  data: any,
}

// Init
export const INITIAL_STATE = {
  isProcessing: false,
  isFinished: false,
  isFailed: false,
  data: {},
};

// Helpers
export function createErrorURL(error: any) {
  const errorData = JSON.stringify(error);
  const blob = new Blob([errorData], { contentType: 'text/plain' });

  return URL.createObjectURL(blob);
}

export function populateResponseData(data: any) {
  if (!data.created) {
    return { error: true };
  } else if (data.error && data.created) {
    return {
      error: true,
      created: data.created,
      url: createErrorURL(data.error),
    };
  }

  return { created: data.created, error: false };
}

// Actions
export function uploadUsers(users: any, fileName: string) {
  return function* doBulkUploadUsers() {
    yield { type: BULK_UPLOAD_USERS_START };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.bulkUploadUsersUpload, {
        params: { data: { users } },
      });

      yield {
        type: BULK_UPLOAD_USERS_FINISHED,
        payload: { data: { ...populateResponseData(result.data), fileName } },
      };
    } catch (e) {
      console.error(e);

      yield { type: BULK_UPLOAD_USERS_FAILED, payload: { data: e } };
    }
  };
}

export function resetBulkUploadUsers() {
  return { type: RESET };
}

// Store
export default function BulkUploadUsersStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case BULK_UPLOAD_USERS_START:
      return { ...state, isProcessing: true };
    case BULK_UPLOAD_USERS_FINISHED:
    case BULK_UPLOAD_USERS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isFinished: true,
        data: payload.data,
      };
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
