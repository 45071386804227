import months from './months.json';
import exportFileTypes from './export/fileTypes.json';
import dateSelections from './selections/date.json';
import advSizeSelections from './selections/advSize.json';
import advSizeValuesSelections from './selections/advSizeValues.json';
import advParticipantsValuesSelections from './selections/advParticipants.json';
import advKeywordsValuesSelections from './selections/advKeywords.json';
import advAttachmentsValuesSelections from './selections/advAttachments.json';
import advDatesValuesSelections from './selections/advDates.json';

export default {
  months: {
    data: months,
    hash: 'app.months.#{}.full',
  },

  exportFileTypes: {
    data: exportFileTypes,
    hash: 'app.exports.fileTypes.#{}',
  },

  dateSelections: {
    data: dateSelections,
    hash: 'app.selections.date.#{}',
  },

  advSizeSelections: {
    data: advSizeSelections,
    hash: 'app.selections.adv_size.#{}',
  },

  advSizeValuesSelections: {
    data: advSizeValuesSelections,
    hash: 'app.selections.adv_size_values.#{}',
  },

  advParticipantsValuesSelections: {
    data: advParticipantsValuesSelections,
    hash: 'app.selections.adv_participants.#{}',
  },

  advKeywordsValuesSelections: {
    data: advKeywordsValuesSelections,
    hash: 'app.selections.adv_keywords.#{}',
  },

  advAttachmentsValuesSelections: {
    data: advAttachmentsValuesSelections,
    hash: 'app.selections.adv_attachments.#{}',
  },

  advDatesValuesSelections: {
    data: advDatesValuesSelections,
    hash: 'app.selections.adv_dates.#{}',
  },
};
