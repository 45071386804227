import reduxStore from '../reduxStore';

import QUERY_TYPES from '../../../../../config/query_types.json';

export { QUERY_TYPES };

export default function generateFilteredQuery(type: string, filterQuery: string) {
  switch (type) {
    case QUERY_TYPES.SAVED_SEARCH: {
      const { streamQuery } = reduxStore.getState().searchResults;

      return `(and ${streamQuery} ${filterQuery})`;
    }
    case QUERY_TYPES.MY_ARCHIVE: {
      const { query } = reduxStore.getState().currentSavedSearch.search;

      return `(and ${query} ${filterQuery})`;
    }
    case QUERY_TYPES.UNSAVED_MY_ARCHIVE:
    case QUERY_TYPES.HOLD_ASSETS: {
      return filterQuery || '';
    }
    default:
      throw new Error(`${type} is not a valid filtered query type`);
  }
}
