import localizedDataLoader from '../global/lib/localizedDataLoader';
import { getRolesData } from '../global/lib/userRights';

import AUDITING from './configs/auditing.json';
import EXPORTS from './configs/exportExpiration.json';
import FILE_SIZES from './configs/DSL/fileSizes.json';
import FILE_TYPES from './configs/fileTypes.json';
import GENERAL_CONFIGS from './configs/general.json';
import JOBS from './configs/jobs.json';
import JOB_STATUSES from './configs/jobStatuses.json';
import LOCALE from './configs/locale.json';
import MONITORING from './configs/monitoring.json';
import PASSWORD_VALIDATION from './configs/passwordValidation.json';
import POP3 from './configs/pop3.json';
import POP3_STATUSES from '../../../../config/pop3_statuses.json';
import QUERY from './configs/query.json';
import RANDOM_SEARCH from './configs/random_search.json';
import REGEXPS from './configs/regexps.json';
import REVIEW_STATUSES from '../../../../config/review_statuses.json';
import CONTEXTS from '../../../../config/contexts.json';
import BULK_ACTIONS from '../../../../config/bulk_actions.json';
import SECURITY_OPTIONS from './configs/securityOptions.json';
import SORT_KEYS from './configs/sortKeys.json';
import STORES from './configs/stores';
import SUPPORT_URLS from './configs/supportUrls.json';
import URLS from './configs/urls.json';
import CRITERIA from './configs/criteria.json';
import BREADCRUMBS from './configs/breadcrumbs.json';

import { ROLES } from '../../../../config/roles.json';

const getLocalizedData = localizedDataLoader(LOCALE);

const BREADCRUMBS_OBJ = Object.keys(BREADCRUMBS).reduce((breadcrumbs, breadcrumb) => {
  const modifiedBreadCrumbs: any = { ...breadcrumbs };

  modifiedBreadCrumbs[breadcrumb] = {
    isRoot: false,
    ...BREADCRUMBS[breadcrumb],
  };

  return modifiedBreadCrumbs;
}, {});

const DSL_OBJ = {
  FILE_SIZES: getLocalizedData({
    LOCALE_DATA: FILE_SIZES,
    LOCALE_VALUES: {
      value: 'selections.size.#{}',
    },
  }),
};

const USER_ROLES_OBJ = {
  ROLES,
  LOCALIZED_DATA: getLocalizedData({
    LOCALE_DATA: getRolesData(),
    LOCALE_VALUES: {
      value: 'user_roles.#{}',
    },
  }),
};

export {
  AUDITING,
  EXPORTS,
  FILE_TYPES,
  GENERAL_CONFIGS,
  JOB_STATUSES,
  JOBS,
  LOCALE,
  MONITORING,
  PASSWORD_VALIDATION,
  POP3,
  POP3_STATUSES,
  QUERY,
  REGEXPS,
  REVIEW_STATUSES,
  SECURITY_OPTIONS,
  STORES,
  SUPPORT_URLS,
  URLS,
  SORT_KEYS,
  RANDOM_SEARCH,
  CONTEXTS,
  BULK_ACTIONS,
  CRITERIA,
  BREADCRUMBS_OBJ as BREADCRUMBS,
  DSL_OBJ as DSL,
  USER_ROLES_OBJ as USER_ROLES,

};

export default {
  ...{ AUDITING },
  ...{ EXPORTS },
  ...{ FILE_TYPES },
  ...{ GENERAL_CONFIGS },
  ...{ JOB_STATUSES },
  ...{ JOBS },
  ...{ LOCALE },
  ...{ MONITORING },
  ...{ PASSWORD_VALIDATION },
  ...{ POP3 },
  ...{ POP3_STATUSES },
  ...{ QUERY },
  ...{ REGEXPS },
  ...{ REVIEW_STATUSES },
  ...{ SECURITY_OPTIONS },
  ...{ SORT_KEYS },
  ...{ STORES },
  ...{ SUPPORT_URLS },
  ...{ URLS },
  ...{ SORT_KEYS },
  ...{ RANDOM_SEARCH },
  ...{ CONTEXTS },
  ...{ BULK_ACTIONS },
  ...{ CRITERIA },

  BREADCRUMBS: BREADCRUMBS_OBJ,
  DSL: DSL_OBJ,
  USER_ROLES: USER_ROLES_OBJ,
};
