import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

type Props = {
  children: ReactNode
  section: string
}

export default function LayoutContainer({ section, children }: Props) {
  return (
    <section
      data-section={section}
      className={classnames('layout', section)}
    >
      {children}
    </section>
  );
}

LayoutContainer.propTypes = {
  section: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
