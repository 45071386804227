/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/sort-comp */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { contains, isArray } from 'lodash';
import withMessages, { intlPropTypes } from 'component-utilities/intl';

import LoadingIndicator from 'view-components/components/LoadingIndicator';
import {
  PrimaryButton,
  SecondaryButton,
} from 'view-components/components/Buttons/Components';
import {
  CustomCheckbox,
  TextLabel,
} from 'view-components/components/Widgets/Components';

import naturalCmp from '../../lib/NaturalCmp';

import withForm, { formHelperPropTypes } from '../../lib/formHelper';

import { updateAssociatedHoldReasons } from '../../state_managers/AssetStateManager';
import { Asset, intlShape, formHelper } from 'global/types';

import ifFeature from "../IfFeature";

const SELECT_ANY = 'selectAny';

const mapStateToProps = ({ legalHoldReasons }: any) => ({
  reasons: legalHoldReasons.originalReasons,
});

const mapActionsToProps = { updateAssociatedHoldReasons };

interface Props extends intlShape, formHelper {
  asset: Asset,
  legalHoldReasons: {
    reasons: any[]
  },
  selectedIDs: string[],
  assetID?: string,
  updateAssociatedHoldReasons: Function,
  reasons: { enumId: string, name: string }[]
}

type State = {
  hoveredIdx: null | number
}

@withForm({
  form: 'holds-tab',
  fields: ['selectedIDs'],
  })
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withMessages
export default class HoldsTab extends Component<Props, State> {
  static propTypes = {
    legalHoldReasons: PropTypes.object,
    selectedIDs: PropTypes.array,
    assetID: PropTypes.string,
    updateAssociatedHoldReasons: PropTypes.func.isRequired,
    ...intlPropTypes,
    ...formHelperPropTypes,
  };

  static defaultProps = {
    selectedIDs: [],
    legalHoldReasons: {},
    assetID: '',
  };

  static displayName = 'AssetView/HoldsTab';

  constructor(props: Props) {
    super(props);

    this.state = { hoveredIdx: null };

    props.setInitialFields({
      selectedIDs: props.selectedIDs,
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.selectedIDs.length !== this.props.selectedIDs.length) {
      this.props.setInitialFields({
        selectedIDs: this.props.selectedIDs,
      });
    }

    this.delegateCheckboxPaneState(this.props.isDirtyFields);
  }

  onSelectHold = (checkBoxId: string, isChecked: boolean) => () => {
    const {
      fields: { selectedIDs },
    } = this.props;

    if (checkBoxId === SELECT_ANY) {
      let newIds = [];

      if (!isChecked) {
        newIds = this.allReasons;
      }

      selectedIDs.change(newIds);
    } else {
      selectedIDs.toggleInArray(checkBoxId);
    }
  };

  onSave = () => {
    this.props.makeFieldsUndirty();
    this.props.updateAssociatedHoldReasons(
      this.props.assetID,
      this.props.fields.selectedIDs.value,
    );
  };

  onCancel = () => {
    this.props.resetFields();
  };

  setHoveredIdx = (idx: number) => () => {
    this.setState({
      hoveredIdx: idx,
    });
  };

  get allReasons() {
    return this.props.legalHoldReasons.reasons.map(({ enumId }: any) => enumId);
  }

  get sortedHoldReasons() {
    const {
      reasons,
      fields: { selectedIDs },
    } = this.props;
    const allHoldReasons: any = {};
    if (!isArray(selectedIDs.value)) {
      return { sortedIds: [] };
    }

    reasons.forEach(({ enumId, name }) => {
      allHoldReasons[enumId] = {
        enumId,
        name,
      };
    });

    reasons.forEach((enumId) => {
      allHoldReasons[enumId] = allHoldReasons[enumId] || {};
      allHoldReasons[enumId].isSelected = true;
    });

    allHoldReasons.sortedIds = Object.keys(allHoldReasons).sort((a, b) => {
      const { isSelected: aIsSelected, name: aName } = allHoldReasons[a];
      const { isSelected: bIsSelected, name: bName } = allHoldReasons[b];

      if (aIsSelected && !bIsSelected) {
        return -1;
      } else if (!aIsSelected && bIsSelected) {
        return 1;
      }

      return naturalCmp(aName, bName);
    });

    allHoldReasons[SELECT_ANY] = {
      enumId: SELECT_ANY,
      name: this.props.getMessage('app.general.all'),
    };

    allHoldReasons.sortedIds = [SELECT_ANY].concat(allHoldReasons.sortedIds);

    return allHoldReasons;
  }

  get isPaneProcessing() {
    const {
      asset: { isUpdating },
    } = this.props;

    return isUpdating;
  }

  get indicatorText() {
    return `${this.props.getMessage('app.indicators.saving')}...`;
  }

  delegateCheckboxPaneState(newState = false) {
    const { delegateCheckboxPaneState } = this.props;

    if (delegateCheckboxPaneState) {
      delegateCheckboxPaneState(newState);
    }
  }

  render() {
    const {
      getMessage,
      fields: { selectedIDs },
      isDirtyFields,
    } = this.props;
    const { hoveredIdx } = this.state;

    return (
      <div className="accordion-view holds">
        {!this.isPaneProcessing && (
          <data>
            <div
              className="holds-wrapper"
              onMouseOut={this.setHoveredIdx(null)}
            >
              {this.sortedHoldReasons.sortedIds.map((sortedId: string) => {
                const { enumId, name } = this.sortedHoldReasons[sortedId];
                const isAllSelected =
                  this.allReasons.length === selectedIDs.value.length;
                const isChecked =
                  enumId === SELECT_ANY
                    ? isAllSelected
                    : contains(selectedIDs.value, enumId);

                if (!enumId) {
                  return null;
                }

                return (
                  <div
                    key={enumId}
                    className="hold-reason-row"
                    data-row={enumId}
                    onClick={this.onSelectHold(enumId, isChecked)}
                    onMouseOver={this.setHoveredIdx(enumId)}
                    role="checkbox"
                    tabIndex="-1"
                    aria-checked={isChecked}
                  >
                    <CustomCheckbox
                      widgetId={enumId.toString()}
                      className="content-checkbox"
                      isChecked={isChecked}
                      forceHover={enumId === hoveredIdx}
                    />
                    <TextLabel title={name} className="holds-label">
                      {name}
                    </TextLabel>
                  </div>
                );
              })}
            </div>
          </data>
        )}
        {this.isPaneProcessing && (
          <LoadingIndicator
            orientation="middle"
            text={this.indicatorText}
            isLoading={!!this.isPaneProcessing}
          />
        )}
        <div className="control-buttons">
          <PrimaryButton
            extraClassName="save-button"
            data-action="save"
            onClick={this.onSave}
            disabled={!isDirtyFields || !!this.isPaneProcessing}
          >
            {getMessage('app.button.save')}
          </PrimaryButton>
          <SecondaryButton
            extraClassName="cancel-button"
            data-action="cancel"
            onClick={this.onCancel}
            disabled={!isDirtyFields || !!this.isPaneProcessing}
          >
            {getMessage('app.button.cancel')}
          </SecondaryButton>
        </div>
      </div>
    );
  }
}
