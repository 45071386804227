/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withMessages, { FormattedHTMLMessage } from 'component-utilities/intl';

import LoginTemplate from 'v-c/Templates/Login/Login';
import LoginWrapper from 'v-c/Login/LoginWrapper';

import { LoginFormTitle, SingleSignOnContent } from 'v-c/Login';

import { FormText } from 'v-c/Forms';
import loginValidationErrors from 'v-c/Login/loginValidationErrors';

import LoginLogo from '../../global/lib/loginLogo';
import loginLinks from '../../global/lib/loginLinks';
import IfFeature from '../../global/components/IfFeature';

import {
  startAttemptingLogin,
  startAttemptingTokenLogin,
  startAttemptingOpenIDLogin,
  clearLoggedInAs,
  goToHomePage,
} from '../../global/state_managers/AuthenticationStateManager';
import { resetLoginForm } from '../../global/state_managers/LoginFormStateManager';
import { load as loadDomainInfo } from '../../global/state_managers/DomainInfoStateManager';
import { resetSizeLimitModal } from '../../global/state_managers/SearchResultsStateManager';
import { getAccountDetails } from '../../global/lib/domainInfo';

import reduxStore from '../../global/reduxStore';
import { clearAppState } from '../../global/state_managers/AppStateManager';

import EnabledLoginProvider from './components/EnabledLoginProvider';
import { WithDeveloperShowFlash } from '../../global/lib/DeveloperShowFlash';
import developerInterface from '../../global/lib/developerInterface';
import getRedirectToPath from './lib/getRedirectToPath';

import appConfig from '../../../../../config/app.json';

import LoginBaseHOC from './components/LoginBase';
import ToggleZendeskHelp from './components/ToggleZendeskHelp';
import loginAndRedirectHandler from './lib/loginAndRedirectHandler';

import styles from './styles.scss';

import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';

const { LOGIN_PROVIDERS } = appConfig;
const { NONCE_TTL } = LOGIN_PROVIDERS;

type Props = {
  domain: {
    enabledLoginProviders: any,
    siteName: string,
  },
  performLoginWithRedirect: Function,
  isAccountDeleted: boolean,
  siteName: string,
  getMessage: Function,
  onValueChange: Function,
  login: {
    startTokenLogin: boolean,
    values: {
      username: string,
      password: string,
    },
    showInvalidMessage: boolean,
  },
  attemptingLogin: boolean,
  username: string,
  password: string,
  loadDomainInfo: Function,
  supportEmailEnabled: boolean,
  termsUrlEnabled: boolean,
  resetLoginForm: Function,
  resetSizeLimitModal: Function,
}

type State = { 
  simulateProviderFailure: Boolean,
  bypassSso: Boolean
}

const mapStateToProps = ({ domainInfo, authentication, loginForm }: any) => ({
  authentication,
  login: loginForm,
  domain: {
    enabledLoginProviders: domainInfo.enabledLoginProviders,
    siteName: domainInfo.siteName,
  },
});

const mapActionsToProps = {
  loadDomainInfo,
  startAttemptingLogin,
  startAttemptingTokenLogin,
  startAttemptingOpenIDLogin,
  clearLoggedInAs,
  resetLoginForm,
  resetSizeLimitModal,
};

// Alias window.reload so we can test properly.
const reload = window.reload;

@flashConnect(PAGES.LOGIN, 'Login/Login')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withMessages
@loginAndRedirectHandler({
  goToHomePage,
  getAccountDetails,
  })
@WithDeveloperShowFlash
@ToggleZendeskHelp
@LoginBaseHOC({
  state: {
  username: '',
  password: '',
  },
  })
export default class Login extends Component<Props, State> {
  static propTypes = {
    domain: PropTypes.shape({
      enabledLoginProviders: PropTypes.object,
      siteName: PropTypes.string.isRequired,
    }),
    performLoginWithRedirect: PropTypes.func.isRequired,
    isAccountDeleted: PropTypes.bool.isRequired,
    siteName: PropTypes.string,
    getMessage: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    login: PropTypes.shape({
      startTokenLogin: PropTypes.bool.isRequired,
      values: PropTypes.shape({
        username: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
      }).isRequired,
      showInvalidMessage: PropTypes.bool.isRequired,
    }),
    attemptingLogin: PropTypes.bool,
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    loadDomainInfo: PropTypes.func.isRequired,
    supportEmailEnabled: PropTypes.bool.isRequired,
    termsUrlEnabled: PropTypes.bool.isRequired,
    resetLoginForm: PropTypes.func.isRequired,
    resetSizeLimitModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    domain: { enabledLoginProviders: {} },
    siteName: null,
    login: {},
    attemptingLogin: false,
    supportEmailEnabled: true,
    termsUrlEnabled: true,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      simulateProviderFailure: false,
      bypassSso: false,
    };

    props.resetLoginForm();
    // the action that clears local storage in that state manager.
    reduxStore.dispatch(clearAppState());
  }

  componentDidMount() {
    this.props.resetSizeLimitModal();

    // ensure nonces are fresh and catch changes that may occur while a user
    // is on the login screen.
    if (this.hasEnabledLoginProviders) {
      this.interval = setInterval(this.reloadDomainInfo, NONCE_TTL * 1000);
    }

    developerInterface({
      Login: {
        simulateLoginProviderFailure: () => {
          this.setState({ simulateProviderFailure: true });
        },
      },
    });
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  get enabledLoginProviders() {
    return this.props.domain.enabledLoginProviders || {};
  }

  get hasEnabledLoginProviders() {
    return (
      IfFeature.isEnabled('login_providers') &&
      Object.keys(this.enabledLoginProviders).length > 0
    );
  }

  get formError() {
    return loginValidationErrors(this.props);
  }

  reloadDomainInfo = () => {
    this.props.loadDomainInfo(this.props.domain.siteName, getRedirectToPath());
  };

  login = () => {
    const {
      showInvalidMessage,
      values: { username, password },
    } = this.props.login;

    const data = {
      isValid: !showInvalidMessage,
      data: { username, password },
    };

    this.props.performLoginWithRedirect(data);
  };

  handleKeyEvent = () => ({
    onKeyDown: ({ keyCode }: { keyCode: number}) => {
      if (keyCode === 13) {
        this.login();
      }
    },
  });

  toggleBypassSso = () => {
    this.setState({ bypassSso: !this.state.bypassSso });
  };

  renderFlashMessages = () => <FlashMessages />;

  render() {
    const {
      login: { startTokenLogin },
      username,
      password,
      isAccountDeleted,
      siteName,
      supportEmailEnabled,
      termsUrlEnabled,
    } = this.props;

    if (startTokenLogin) {
      return null;
    }

    // NOTE: This will cause an infinite loop if we don't clear out the user's
    //       SSO provider's session during logout. So, at this moment, this should
    //       only ever be used for the Barracuda partner/SSO.
    if (
      IfFeature.isEnabled('redirect-to-sso-login') &&
      this.hasEnabledLoginProviders
    ) {
      reload();
      return null;
    }

    const showUsernamePassword =
      !this.hasEnabledLoginProviders || this.state.bypassSso;

    const templateProps = {
      usernameConfig: {
        value: username,
        onChange: this.props.onValueChange('username'),
      },
      passwordConfig: {
        value: password,
        onChange: this.props.onValueChange('password'),
      },
      commonInputAttributes: this.handleKeyEvent,
      onSubmit: this.login,
      isButtonDisabled: !!this.formError,
      showLoginFailedMessage: !!this.formError,
      formError: this.formError,
      ...this.props,
    };

    const section = 'login-dialog';

    return (
      <LoginWrapper
        {...loginLinks(true)}
        isAccountDeleted={isAccountDeleted}
        siteName={siteName}
        supportEmailEnabled={supportEmailEnabled}
        termsUrlEnabled={termsUrlEnabled}
        viewLogo={<LoginLogo />}
        isPcc={false}
        renderFlashMessages={this.renderFlashMessages()}
        section={section}
      >
        <LoginFormTitle>
          {showUsernamePassword &&
            this.hasEnabledLoginProviders && (
              <FormattedHTMLMessage id="app.login.account_admins_only" />
            )}
          {(!showUsernamePassword || !this.hasEnabledLoginProviders) && (
            <FormattedHTMLMessage id="app.login.title" />
          )}
        </LoginFormTitle>
        {showUsernamePassword && <LoginTemplate {...templateProps} />}
        {!showUsernamePassword && (
          <SingleSignOnContent>
            {Object.keys(this.enabledLoginProviders).map((key) => {
              const href = this.enabledLoginProviders[key];

              if (
                href === LOGIN_PROVIDERS.OPENID.ERRORS.PROVIDER_FAILURE ||
                this.state.simulateProviderFailure
              ) {
                return (
                  <FormText key={key}>
                    <p data-login-provider-failure-message>
                      <FormattedHTMLMessage id="app.login.errors.provider_failure" />
                    </p>
                  </FormText>
                );
              }

              return (
                <EnabledLoginProvider
                  provider={key}
                  href={this.enabledLoginProviders[key]}
                  key={key}
                />
              );
            })}
          </SingleSignOnContent>
        )}
        {this.hasEnabledLoginProviders && (
          <a
            className={styles['bypass-sso']}
            role="presentation"
            data-bypass-sso
            onClick={this.toggleBypassSso}
          >
            {!showUsernamePassword && (
              <FormattedHTMLMessage id="app.login.bypass_sso" />
            )}
            {showUsernamePassword && (
              <FormattedHTMLMessage id="app.login.return_to_sso" />
            )}
          </a>
        )}
      </LoginWrapper>
    );
  }
}
