import React from 'react';
import { Redirect, Route } from 'react-router';
import ReactLoadable from 'react-loadable';

import { buildReactRouter } from 'view-components/lib/url';
import { UI, GLOBAL } from '../../../../config/routes/view-frontend';

import appMonitoring from '../global/lib/AppMonitoring';
import routeAuthentication from '../global/lib/routeAuthentication';
import App from '../global/components/App';
import NotFound from '../global/components/NotFound';

import testRoutes from './testRoutes';

// eslint-disable-next-line arrow-body-style
const Loadable = (opts: any) => {
  return ReactLoadable(Object.assign({
    loading() {
      return <div className="content-view-inner" />;
    },
  }, opts));
};

const Login = Loadable({
  loader: () => import('../features/Authentication/Login.proxy'),
});
const Logout = Loadable({
  loader: () => import('../features/Authentication/Logout.proxy'),
});
const ResetPassword = Loadable({
  loader: () => import('../features/Authentication/ResetPassword.proxy'),
});
const ChangePassword = Loadable({
  loader: () => import('../features/Authentication/ChangePassword.proxy'),
});
const ChangeUserPassword = Loadable({
  loader: () => import('../features/Users/ChangeUserPassword.proxy'),
});

const AccountSummary = Loadable({
  loader: () => import('../features/AccountSummary/AccountSummary.proxy'),
});

const SearchesIndex = Loadable({
  loader: () => import('../features/Searches/SavedSearchList.proxy'),
});
const SearchesSave = Loadable({
  loader: () => import('../features/Searches/SaveCurrentSearch/SaveCurrentSearch.proxy'),
});
const SearchesEdit = Loadable({
  loader: () => import('../features/Searches/SavedSearchEdit.proxy'),
});
const SearchesResultsIndex = Loadable({
  loader: () => import('../features/Searches/SavedSearchResults.proxy'),
});
const SearchesResultsAsset = Loadable({
  loader: () => import('../features/Searches/SavedSearchAssetView.proxy'),
});

const ResultsIndex = Loadable({
  loader: () => import('../features/Searches/SearchResults.proxy'),
});
const ResultsAssets = Loadable({
  loader: () => import('../features/Searches/RenderedAsset.proxy'),
});

const NewSearchIndex = Loadable({
  loader: () => import('../features/Searches/NewSearchResults.proxy'),
});
const NewSearchSave = Loadable({
  loader: () => import('../features/Searches/SaveNewSearch/SaveNewSearch.proxy'),
});
const NewSearchEdit = Loadable({
  loader: () => import('../features/Searches/UnsavedSearchEdit.proxy'),
});
const NewSearchResultsIndex = Loadable({
  loader: () => import('../features/Searches/UnsavedSearchResults.proxy'),
});
const NewSearchResultsAsset = Loadable({
  loader: () => import('../features/Searches/UnsavedSearchRenderedAsset.proxy'),
});

const Exports = Loadable({
  loader: () => import('../features/Exports/Exports.proxy'),
});

const Jobs = Loadable({
  loader: () => import('../features/Jobs/Jobs.proxy'),
});

const HoldReasonsIndex = Loadable({
  loader: () => import('../features/HoldReasons/HoldReasons.proxy'),
});
const HoldReasonsNew = Loadable({
  loader: () => import('../features/HoldReasons/NewHoldReason.proxy'),
});
const HoldReasonsShowIndex = Loadable({
  loader: () => import('../features/HoldReasons/Assets/HoldReasonAssets.proxy'),
});
const HoldReasonsShowAssets = Loadable({
  loader: () => import('../features/HoldReasons/Assets/HoldReasonRenderedAsset.proxy'),
});

const UsersIndex = Loadable({
  loader: () => import('../features/Users/Users.proxy'),
});
const UsersNew = Loadable({
  loader: () => import('../features/Users/NewUser.proxy'),
});
const UsersEdit = Loadable({
  loader: () => import('../features/Users/EditUser.proxy'),
});

const MyArchiveSplitViewIndex = Loadable({
  loader: () => import('../features/EndUser/MyArchive.proxy'),
});

const SettingsIndex = Loadable({
  loader: () => import('../features/Settings/Settings'),
});

const SettingsCollectorsIndex = Loadable({
  loader: () => import('../features/Settings/Collectors.proxy'),
});
const SettingsCollectorsEmail = Loadable({
  loader: () => import('../features/Settings/Email.proxy'),
});

const SettingsPolicies = Loadable({
  loader: () => import('../features/Settings/Policies.proxy'),
});

const SettingsReportsIndex = Loadable({
  loader: () => import('../features/Settings/Reports.proxy'),
});
const SettingsReportsAuditsNew = Loadable({
  loader: () => import('../features/Settings/Reports/NewAuditReport.proxy'),
});
const SettingsReportsReportsEdit = Loadable({
  loader: () => import('../features/Settings/Reports/EditReport.proxy'),
});

const SettingsSearchListsIndex = Loadable({
  loader: () => import('../features/Settings/SearchLists.proxy'),
});
const SettingsSearchListsNew = Loadable({
  loader: () => import('../features/Settings/NewSearchList.proxy'),
});
const SettingsSearchListsEdit = Loadable({
  loader: () => import('../features/Settings/EditSearchList.proxy'),
});

const SettingsAccountSettings = Loadable({
  loader: () => import('../features/Settings/AccountSettings.proxy'),
});

const SettingsSecurity = Loadable({
  loader: () => import('../features/Settings/Security.proxy'),
});

const SettingsO365Integration = Loadable({
  loader: () => import('../features/Settings/components/O365Integration.proxy'),
});

const SettingsSingleSignOn = Loadable({
  loader: () => import('../features/Settings/SingleSignOn'),
});

const SettingsAdSyncIndex = Loadable({
  loader: () => import('../features/Settings/ADSync.proxy'),
});
const SettingsAdSyncAdWizard = Loadable({
  loader: () => import('../features/Settings/ADWizard.proxy'),
});
const SettingsAdSyncAdSuccess = Loadable({
  loader: () => import('../features/Settings/ADSuccess.proxy'),
});
const SettingsAdSyncSyncLog = Loadable({
  loader: () => import('../features/Settings/ADSyncLog.proxy'),
});
const SettingsAdSyncSyncConflicts = Loadable({
  loader: () => import('../features/Settings/ADSyncConflicts.proxy'),
});
const SettingsAdSyncAttributeMappings = Loadable({
  loader: () => import('../features/Settings/ADSyncAttributeMappings.proxy'),
});
const SettingsAdSyncSourceAdGroups = Loadable({
  loader: () => import('../features/Settings/ADSyncSourceADGroups.proxy'),
});
const SettingsAdSyncAppCredentials = Loadable({
  loader: () => import('../features/Settings/ADSyncAppCredentials.proxy'),
});

const routeHandlers = process.env.NODE_ENV === 'test' ? { ...testRoutes } : {
  Login,
  Logout,
  ResetPassword,
  ChangePassword,
  ChangeUserPassword,

  Dashboard: AccountSummary,

  SearchesIndex,
  SearchesShow: SearchesSave,
  SearchesEdit,
  SearchesResultsIndex,
  SearchesResultsAsset,

  ResultsIndex,
  ResultsAssets,
  ResultsSplitViewAssets: ResultsIndex,

  NewSearchIndex,
  NewSearchSave,
  NewSearchEdit,
  NewSearchResultsIndex,
  NewSearchResultsAsset,

  Exports,

  Jobs,

  HoldReasonsIndex,
  HoldReasonsNew,
  HoldReasonsShowIndex,
  HoldReasonsShowAssets,

  UsersIndex,
  UsersNew,
  UsersEdit,

  MyArchiveSplitViewIndex,
  MyArchiveSplitViewAsset: MyArchiveSplitViewIndex,

  SettingsIndex,
  SettingsCollectorsIndex,
  SettingsCollectorsEmail,
  SettingsPolicies,
  SettingsReportsIndex,
  SettingsReportsAuditsNew,
  SettingsReportsReportsEdit,
  SettingsSearchListsIndex,
  SettingsSearchListsNew,
  SettingsSearchListsEdit,
  SettingsAdSyncIndex,
  SettingsAdSyncAdWizard,
  SettingsAdSyncAdSuccess,
  SettingsAdSyncSyncLog,
  SettingsAdSyncSyncConflicts,
  SettingsAdSyncAttributeMappings,
  SettingsAdSyncSourceAdGroups,
  SettingsAdSyncAppCredentials,
  SettingsAccountSettings,
  SettingsSecurity,
  SettingsO365Integration,
  SettingsSingleSignOn,
  // create_settings_section[keys]: new keys go above here
};

const siteName = ':site_name';
const appEntryRoute = `${siteName}/${GLOBAL.APP_NAME}`;
const loginRoute = `${appEntryRoute}/login`;

// TODO: redo all the layouts so they come from here, not from the
// individual components themselves.
[
  routeHandlers.Jobs,
  routeHandlers.SearchesIndex,
  routeHandlers.SearchesShow,
  routeHandlers.NewSearchSave,
  routeHandlers.UsersIndex,
  routeHandlers.SettingsPolicies,
  routeHandlers.MyArchiveSplitViewIndex,
  routeHandlers.ResultsIndex,
  routeHandlers.Dashboard,
  routeHandlers.HoldReasonsIndex,
  routeHandlers.HoldReasonsNew,
  routeHandlers.HoldReasonsShowIndex,
].forEach((_view) => {
  const view = _view;
  view.isNewLayoutCompatible = true;
});

const routes = (
  <Route>
    <Route
      component={App}
      onEnter={(...args: any) => {
        appMonitoring(...args);
      }}
    >
      <Redirect from={appEntryRoute} to={loginRoute} />

      <Route path={appEntryRoute}>
        {buildReactRouter(UI, routeHandlers, {
          onEnter: routeAuthentication,
        })}
      </Route>

      <Redirect from={siteName} to={loginRoute} />
    </Route>

    <Route path="*" component={NotFound} />
  </Route>
);

export default routes;
