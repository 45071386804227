/* eslint-disable no-undef */
/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { browserHistory, withRouter } from 'react-router';
import { isFunction } from 'lodash';

import { ROUTES } from './jsUi';

import { isLoggedIn } from '../state_managers/AuthenticationStateManager';

const routeLeaveHookPropTypes = {
  setRouterWillLeaveValidator: PropTypes.func,
  retryTransition: PropTypes.func,
};

export { routeLeaveHookPropTypes };

type Props = {
  router: {},
  route: {}
}

export default function routeLeaveHook(WrappedComponent: any) {
  class RouteLeaveHook extends Component<Props, any> {
    static propTypes = {
      router: PropTypes.object.isRequired,
      route: PropTypes.object.isRequired,
    };

    constructor(props: Props) {
      super(props);

      this.state = { isUnmounted: false };
      this.resetVars();
    }

    componentDidMount() {
      this.registerRemoveLeaveHook();
    }

    componentDidUpdate() {
      this.registerRemoveLeaveHook();
    }

    setRootComponentLeaveValidator = (fn: Function) => {
      if (isFunction(fn)) {
        this.rootComponentLeaveValidator = fn;
      }
    };

    setRouterWillLeaveValidator = (fn: Function) => {
      if (isFunction(fn)) {
        this.routerWillLeaveValidator = fn;
      }
    };

    registerUnmountCleanupMethod = (fn: Function) => {
      if (isFunction(fn)) {
        this.unmountCleanupMethods.push(fn);
      }
    };

    registerRemoveLeaveHook = () => {
      const { router, route } = this.props;

      if (this.removeLeaveHook) {
        this.removeLeaveHook();
      }

      this.removeLeaveHook = router.setRouteLeaveHook(
        route,
        this.routerWillLeave,
      );
    };

    cleanupWrappedComponent = (nextRoute: {}) => {
      this.unmountCleanupMethods.map(fn => fn(nextRoute));
    };

    resetComponent() {
      this.removeLeaveHook();
      this.setState({ isUnmounted: true });
      this.resetVars();

      this.isUnmounted = true;
    }

    resetVars = () => {
      this.nextRoute = {};
      this.rootComponentLeaveValidator = null;
      this.unmountCleanupMethods = [];
      this.routerWillLeaveValidator = () => true;
    };

    routerWillLeave = (nextRoute: { pathname: string }) => {
      // When the user is not authenticated
      if (nextRoute.pathname === `/${ROUTES.LOGIN}` || !isLoggedIn()) {
        this.resetComponent();
        return true;
      }

      // When the root component leave validator registered and would like
      //   to skip the reset process
      if (
        this.rootComponentLeaveValidator &&
        this.rootComponentLeaveValidator(nextRoute).withoutReset
      ) {
        return true;
      }

      const shouldRouterWillLeave = this.routerWillLeaveValidator(nextRoute);
      this.nextRoute = nextRoute;

      if (shouldRouterWillLeave) {
        this.cleanupWrappedComponent(nextRoute);
        this.resetComponent();

        return true;
      }

      return false;
    };

    retryTransition = (nextRoute: { pathname: string, query: string } = this.nextRoute) => {
      const { pathname, query } = nextRoute;

      if (pathname && query) {
        this.cleanupWrappedComponent();
        this.resetComponent();
        browserHistory.push(nextRoute);
      }
    };

    render() {
      if (this.state.isUnmounted) {
        return null;
      }

      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          setRouterWillLeaveValidator={this.setRouterWillLeaveValidator}
          retryTransition={this.retryTransition}
          registerUnmountCleanupMethod={this.registerUnmountCleanupMethod}
          setRootComponentLeaveValidator={this.setRootComponentLeaveValidator}
        />
      );
    }
  }

  return withRouter(RouteLeaveHook);
}
