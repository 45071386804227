import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { jsUi } from '../../global/lib/jsUi';
import { QUERY_TYPES } from '../../global/lib/generateFilteredQuery';

import routeLeaveHook from '../../global/lib/routeLeaveHook';
import SearchEditingBase from './components/SearchEditingBase';
import { openUnsavedSearchStream } from '../../global/lib/searchResultsStreamManager';
import withSerialResultsLoader, {
  LOAD_TYPES,
} from '../../global/lib/withSerialResultsLoader';

import { updateSavedSearch } from '../../global/state_managers/CurrentSavedSearchStateManager';
import { bodyForSaveUnsavedSearch } from '../../global/state_managers/UnsavedSearchStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';
import {
  resetStreamQuery,
  resetStream,
} from '../../global/state_managers/SearchResults/searchResultsStream';
import { initTableConfig } from '../../global/state_managers/SearchResults/tableConfig';
import {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';
import { Params } from 'global/types';

const mapStateToProps = ({
  currentSavedSearch,
  searchForm,
  searchResults,
}: any) => ({
  searchForm,
  currentSavedSearch,
  clientData: currentSavedSearch.search.clientData,
  searchName: currentSavedSearch.search.name,
  searchId: currentSavedSearch.search.id || '',
  searchResults,
});

const mapActionsToProps = {
  updateSavedSearch,
  setBreadcrumbForModifiedSearchResultsPage:
    breadcrumbsActions.modifiedSearchResultsPage,
  resetStreamQuery,
  initTableConfig,
  resetStream,
};

type Props = {
  setBreadcrumbForModifiedSearchResultsPage: Function,
  initTableConfig: Function,
  resetStreamQuery: Function,
  registerUnmountCleanupMethod: Function,
  params: Params,
  searchForm: any,
  searchName: string,
  currentSavedSearch: any
}

// TODO: get rid of the inheritance
/* eslint-disable class-methods-use-this */
@routeLeaveHook
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@flashConnect(PAGES.SAVED_SEARCH_RESULTS, 'SavedSearchResults/componentWillUnmount')
@withSerialResultsLoader(LOAD_TYPES.SEARCH_RESULTS_LOAD_MORE)
export default class SavedSearchResults extends SearchEditingBase {
  static propTypes = {
    setBreadcrumbForModifiedSearchResultsPage: PropTypes.func.isRequired,
    initTableConfig: PropTypes.func.isRequired,
    resetStreamQuery: PropTypes.func.isRequired,
    registerUnmountCleanupMethod: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    if (!this.searchId) {
      this.goToSearch(props.params.search_id);
    }

    props.initTableConfig(this.searchId);
    openUnsavedSearchStream({ type: QUERY_TYPES.SAVED_SEARCH });
    props.setBreadcrumbForModifiedSearchResultsPage();

    props.registerUnmountCleanupMethod(props.resetStream);
  }

  get searchId() {
    return this.props.searchId;
  }

  get isLoaded() {
    const { searchResults } = this.props;

    if (this.isInitialLoad && searchResults.isLoading) {
      this.isInitialLoad = false;
    } else if (!this.isInitialLoad) {
      return !searchResults.isLoading && searchResults.isLoaded;
    }

    return false;
  }

  goToSearch(id: string) {
    jsUi.searchesShow.goTo({ search_id: id });
  }

  handleEdit = () => {
    jsUi.searchesEdit.goTo({ search_id: this.getCurrentSearchId() });
  };

  handleSave = () => {
    const {
      name,
      description,
      specialAccess,
    } = this.props.currentSavedSearch.search;

    this.props.resetStreamQuery();

    const updateRequest = {
      name,
      description,
      'special-access': specialAccess,
      ...bodyForSaveUnsavedSearch(),
    };

    this.props.updateSavedSearch({
      id: this.searchId,
      requestData: updateRequest,
    });
  };

  goToRenderedAsset = (assetId: string) => {
    const { searchId } = this.props;

    return jsUi.searchesResultsAsset.path({
      search_id: searchId,
      asset_id: assetId,
    });
  };

  getSectionName() {
    return 'results-saved-search';
  }

  getSearchFormViewProps() {
    return {
      searchType: 'saved',
      formView: 'results',
      onSave: this.handleSave,
      onEdit: this.handleEdit,
      header: this.renderSavedSearchHeader('results'),
      content: this.renderResultsContent(),
      clientData: this.props.clientData,
    };
  }
}
