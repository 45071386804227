/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { get, has } from 'lodash';
import { is, fromJS } from 'immutable';

import toggleInArray from 'component-lib/toggleInArray';

const formHelperPropTypes = {
  setInitialFields: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  isDirtyFields: PropTypes.bool.isRequired,
  makeFieldsUndirty: PropTypes.func.isRequired,
  resetFields: PropTypes.func.isRequired,
  registerFieldsChangeCallback: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
};

export { formHelperPropTypes };

type Props = {
  resetFlash: Function
}

type State = {
  form: {
    name: string
    values: any
    originalValues: any
  }
}

export default function formHelper(options: any) {
  return function formHelperFn(WrappedComponent: any) {
    return class FormHelper extends Component<Props, State> {
      static propTypes = {
        resetFlash: PropTypes.func.isRequired,
      }
      constructor(props: Props) {
        super(props);

        const values: any = {};
        options.fields.forEach((field: string) => {
          values[field] = get(options, 'defaultValue', '');
        });

        this.state = {
          form: {
            name: options.form,
            values: fromJS(values).toJS(),
            originalValues: fromJS(values).toJS(),
          },
        };

        this.fieldChangeCallback = () => {};
      }

      componentDidUpdate(prevProps: Props) {
        if (options.onNextProps) {
          options.onNextProps(prevProps, this.props, this.setInitialFields);
        }
      }

      setInitialFields = (values: any, cb = () => {}) => {
        this.setState(
          {
            form: {
              name: options.form,
              values: fromJS(values).toJS(),
              originalValues: fromJS(values).toJS(),
            },
          },
          cb,
        );
      };

      get fields() {
        const { values } = this.state.form;
        const fields = {};

        Object.keys(values).forEach((valueKey: any) => {
          fields[valueKey] = {
            value: values[valueKey],
            onChange: ({ target }: any) => {
              this.setFieldValue(valueKey, target.value);
            },
            change: (value: any) => {
              this.setFieldValue(valueKey, value);
            },
            toggle: () => {
              const value = this.state.form.values[valueKey];

              this.setFieldValue(valueKey, !value);
            },
            toggleInArray: (value: any) => {
              this.toggleInFieldArray(valueKey, value);
            },
          };
        });

        return fields;
      }

      get isDirtyFields() {
        const { form: { values, originalValues } } = this.state;

        return !is(fromJS(values), fromJS(originalValues));
      }

      getFieldValues = () => fromJS(this.state.form.values).toJS();

      getChangedFieldValues = () => {
        const fieldValues = this.getFieldValues();
        const originalValues = this.state.form.originalValues;

        return Object.keys(fieldValues).reduce((changedValues, fieldKey) => {
          const modifedChangedValues = fromJS(changedValues).toJS();
          const fieldValue = fieldValues[fieldKey];

          const isFieldChanged = !is(
            fromJS(fieldValue),
            fromJS(originalValues[fieldKey]),
          );

          if (isFieldChanged) {
            modifedChangedValues[fieldKey] = fieldValue;
          }

          return modifedChangedValues;
        }, {});
      };

      setFields = (newValues: any) => {
        const modifiedForm = fromJS(this.state.form).toJS();
        Object.keys(newValues).forEach((newValueKey) => {
          if (has(modifiedForm.values, newValueKey)) {
            modifiedForm.values[newValueKey] = newValues[newValueKey];
          }
        });

        this.setState({ form: modifiedForm }, () =>
          this.fieldChangeCallback(newValues),
        );
      };

      setFieldValue = (prop: string, value: any) => {
        const modifiedForm = fromJS(this.state.form).toJS();
        modifiedForm.values[prop] = value;

        this.setState({ form: modifiedForm }, () =>
          this.fieldChangeCallback({ [prop]: value }),
        );
      };

      registerFieldsChangeCallback = (cb: Function | any) => {
        if (!cb || typeof cb !== 'function') {
          throw new Error('needs to be a function!');
        }

        this.fieldChangeCallback = cb;
      };

      resetFields = () => {
        const modifiedForm = fromJS(this.state.form).toJS();
        modifiedForm.values = fromJS(modifiedForm.originalValues).toJS();
        if (this.props.resetFlash) {
          this.props.resetFlash({
            from: 'formHelper/resetFields',
          });
        }
        // TODO: deal with unounted component issue
        this.setState({ form: modifiedForm });
      };

      // don't call this until you're sure the data on the other end
      // has been updated. listen for a change to a store property
      // and trigger makeFieldsUndirty on that prop going from false -> true!
      makeFieldsUndirty = () => {
        const modifiedForm = fromJS(this.state.form).toJS();
        modifiedForm.originalValues = fromJS(modifiedForm.values).toJS();

        this.setState({ form: modifiedForm });
      };

      toggleInFieldArray = (prop: string, value: any) => {
        this.setFieldValue(
          prop,
          toggleInArray(this.state.form.values[prop], value),
        );
      };

      render() {
        return (
          <WrappedComponent
            {...this.props}
            setInitialFields={this.setInitialFields}
            setFields={this.setFields}
            fields={this.fields}
            isDirtyFields={this.isDirtyFields}
            makeFieldsUndirty={this.makeFieldsUndirty}
            resetFields={this.resetFields}
            registerFieldsChangeCallback={this.registerFieldsChangeCallback}
            getFieldValues={this.getFieldValues}
            getChangedFieldValues={this.getChangedFieldValues}
          />
        );
      }
    };
  };
}
