import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';
import { Action, PromiseObject } from '../reduxStore';

const IS_LOADING: string = 'user-settings/is-loading';
const IS_READY: string = 'user-settings/is-ready';
const IS_ERROR: string = 'user-settings/is-error';
const SINGLE_USER_SETTINGS: string = 'user-settings/single-user-settings';

export function load(id: string) {
  return function* doLoad() {
    yield {
      type: IS_LOADING,
    };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.usersSettingsShow, { urlParams: { id } });

      yield {
        type: SINGLE_USER_SETTINGS,
        payload: result.data,
      };

      yield {
        type: IS_READY,
      };
    } catch (e) {
      console.error(e);

      yield {
        type: IS_ERROR,
        payload: e,
      };
    }
  };
}

export function update(id: string, options: any) {
  return function* doLoad() {
    try {
      yield apiReqPromise(
        jsApi.usersSettingsUpdate, { urlParams: { id }, params: { data: { options } } },
      );
    } catch (e) {
      console.error(e);
    }
  };
}

const INITIAL_STATE = {
  userSettings: null,
  isLoading: false,
  isReady: false,
  isError: false,
};

export default function UserSettingsStateManager(state = INITIAL_STATE, { type, payload }: Action) {
  switch (type) {
    case IS_LOADING:
      return { ...INITIAL_STATE, isLoading: true };
    case IS_READY:
      return { ...state, isLoading: false, isReady: true };
    case IS_ERROR:
      return { ...state, isLoading: false, isError: true, error: payload };
    case SINGLE_USER_SETTINGS:
      return { ...state, userSettings: payload };
    default:
      return state;
  }
}