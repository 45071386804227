import React from 'react';

import withMessages, { intlPropTypes } from 'component-utilities/intl';
import { TextLabel } from 'v-c/Widgets/Components';
import TooltipGrayIcon from 'v-c/Icons/TooltipGrayIcon';
import Tooltip from 'v-c/Tooltip/Tooltip';
import LoadingIndicator from 'v-c/LoadingIndicator';

import styles from '../../components/Settings.scss';

type Props = {
  mailbox: any,
  server: any,
  getMessage: Function,
}

function SmtpTabComponent(props: Props) {
  const {
    mailbox,
    server,
    getMessage,
  } = props;

  // TODO: fix the label
  /* eslint-disable jsx-a11y/label-has-for */
  return (
    <div className={styles['checkbox-tab-content']}>
      <div>
        <label className={styles['green-text-label']}>
          { getMessage('app.settings.collectors.smtp_address') }
          <Tooltip
            position="right"
            type="medium"
            content={getMessage('app.settings.collectors.smtp_tooltip')}
          >
            <TooltipGrayIcon />
          </Tooltip>
        </label>
      </div>
      {
        !mailbox.length &&
        <LoadingIndicator
          orientation="left"
          text=""
          isLoading
        />
      }
      {
        !!mailbox.length &&
        <TextLabel
          className={styles.label}
          data-field="smtp-address"
        >
          {`${mailbox}@${server}`}
        </TextLabel>
      }
    </div>
  );
}

SmtpTabComponent.propTypes = {
  ...intlPropTypes,
};

const SmtpTab = withMessages(SmtpTabComponent);

export default SmtpTab;
