export default function downloadUrl(url: string) {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', true);
  // Firefox fix
  // We have to add the element to the DOM before triggering the click event
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
