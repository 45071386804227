/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Layout from '../../global/components/Layout';

import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import validateSettingsAccess from './components/validateSettingsAccess';

import './ADSync/ADSync.scss';

const mapStateToProps = null;
const mapActionsToProps = {
  setBreadcrumbForSettingsADSyncADGroupsPage:
    breadcrumbsActions.settingsAdSyncAdGroupsPage,
};

@validateSettingsAccess('settings.ad_sync')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class ADSyncSourceADGroups extends Component {
  static propTypes = {
    setBreadcrumbForSettingsADSyncADGroupsPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    manageButtonText: 'button.manage',
  };

  constructor(props: any) {
    super(props);

    props.setBreadcrumbForSettingsADSyncADGroupsPage();
  }

  render() {
    return <Layout section="ad-sync">SourceADGroups</Layout>;
  }
}
