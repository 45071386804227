/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { includes } from 'lodash';

import withMessages from 'view-components/utilities/intl';

import { Accordion as AccordionComponent } from 'view-components/components/Widgets/Components';
import { LockGrayIcon, LockRedIcon } from 'view-components/components/Icons';

import IfFeature from '../IfFeature';
import dialogHandler from '../../lib/dialogHandler';
import { isPageRoleEnabled, pageRoles } from '../../lib/userRights';

import { showUnsavedHoldReasonDialog } from '../../state_managers/DialogStateManager';

import MetadataTab from './MetadataTab/MetadataTab';
import CommentsTab from './CommentsTab';
import ReviewTab from './ReviewTab';
import HoldsTab from './HoldsTab';

import '../Accordion.scss';
import ifFeature from "../IfFeature";

export const tabs = {
  METADATA: 'metadata',
  REVIEW: 'review',
  HOLDS: 'holds',
  COMMENT: 'comment',
};

const { METADATA, REVIEW, HOLDS, COMMENT } = tabs;

type State = {
  selectedTab: string
}

type Props = {
  asset: {
    isUpdating: boolean,
    data: {
      comments: {}[]
      legalHoldReasons: {}[]
    }
  }
  getMessage: Function
  setRouterWillLeaveValidator: Function
  retryTransition: () => boolean
  currentSavedSearch: {
    search: {
      raw: string
    }
  }
  searchId: string
  legalHoldReasons: {
    reasons: {}[]
  }
  visibleAccordions: {}[]
}

// TODO: create separate component file
function HoldTabTitle(props: Props) {
  const {
    asset: {
      isUpdating: isSavingAsset,
      data: { legalHoldReasons },
    },
    getMessage,
  } = props;
  const count = legalHoldReasons.length;
  const isOnHold = count > 0;
  const iconImage = isOnHold ? (
    <LockRedIcon className="lock-icon" />
  ) : (
    <LockGrayIcon className="lock-icon" />
  );

  const icon = (
    <data>
      (<data data-legal-holds-count={count}>{count}</data>)
      {iconImage}
    </data>
  );

  return (
    <data>
      {`${getMessage(`app.rendered_assets.accordion_tabs.${HOLDS}`)}`}
      {'\u00A0'}
      {!isSavingAsset && icon}
    </data>
  );
}

HoldTabTitle.propTypes = {
  asset: PropTypes.object.isRequired,
  getMessage: PropTypes.func.isRequired,
};

function CommentsTabTitle(props: Props) {
  const {
    asset: {
      isUpdating: isSavingAsset,
      data: { comments },
    },
    getMessage,
  } = props;
  const count = comments.length;

  const icon = (
    <data
      data-legal-holds-count={count}
    >
      ({count})
    </data>
  );

  return (
    <data>
      {`${getMessage(`app.rendered_assets.accordion_tabs.${COMMENT}`)}`}
      {'\u00A0'}
      {!isSavingAsset && icon}
    </data>
  );
}

CommentsTabTitle.propTypes = {
  asset: PropTypes.object.isRequired,
  getMessage: PropTypes.func.isRequired,
};

@withMessages
export default class Accordion extends Component<Props, State> {
  static propTypes = {
    currentSavedSearch: PropTypes.object,
    id: PropTypes.string,
    panelData: PropTypes.node,
    legalHoldReasons: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    visibleAccordions: PropTypes.array,
    searchId: PropTypes.string,
    getMessage: PropTypes.func.isRequired,
    setRouterWillLeaveValidator: PropTypes.func.isRequired,
    retryTransition: PropTypes.func.isRequired,
  };

  static defaultProps = {
    panelName: '',
    itemNumber: null,
    iconName: null,
    panelData: null,
    id: '',
    panelClasses: '',
    currentSavedSearch: null,
    visibleAccordions: null,
    searchId: null,
  };

  constructor(props: Props) {
    super(props);

    this.isCheckboxPaneDirty = false;
    this.state = { selectedTab: METADATA };

    props.setRouterWillLeaveValidator(this.routerWillLeave);
  }

  onSelectTab = (widgetId: string, newSelectedTab: string) => {
    const { selectedTab } = this.state;

    if (selectedTab !== newSelectedTab) {
      this.setState({ selectedTab: newSelectedTab });
    }
  };

  get isRawSearch() {
    if (this.props.currentSavedSearch) {
      return this.props.currentSavedSearch.search.raw;
    }

    return false;
  }

  routerWillLeave = () => {
    if (!this.isCheckboxPaneDirty) {
      return true;
    }

    dialogHandler(showUnsavedHoldReasonDialog, this.props.retryTransition);

    return false;
  };

  delegateCheckboxPaneState = (isDirty: boolean) => {
    this.isCheckboxPaneDirty = isDirty;
  };

  render() {
    const {
      legalHoldReasons: { reasons },
      asset: {
        data: { legalHoldReasons },
      },
      visibleAccordions,
      getMessage,
    } = this.props;
    const { selectedTab } = this.state;

    const holdTabData = {
      available: reasons,
      selectedIDs: legalHoldReasons,
      delegateCheckboxPaneState: this.delegateCheckboxPaneState,
    };

    const filterTabs = (tab: { id: string }) => {
      if (
        (!this.props.searchId && tab.id === REVIEW) ||
        (this.isRawSearch && tab.id === REVIEW)
      ) {
        return false;
      }

      if (tab.id === COMMENT && !isPageRoleEnabled(pageRoles.USER_CAN_USE_COMMENT_API)) {
        return false;
      }

      return true;
    };

    let accordionTabs = [
      {
        id: METADATA,
        content: <MetadataTab {...this.props} />,
      },
      {
        id: REVIEW,
        content: (
          <ReviewTab {...this.props} isSelected={selectedTab === REVIEW} />
        ),
      },
      {
        id: COMMENT,
        content: <CommentsTab {...this.props} />,
        value: <CommentsTabTitle {...this.props} />,
      },
      {

        id: HOLDS,
        content: <HoldsTab {...holdTabData} {...this.props} />,
        value:   <HoldTabTitle {...this.props} />,
      },
    ]
      .filter(filterTabs)
      .map(tab => ({
        isDisabled: (!ifFeature.isEnabled('asset_hold_tag') && (tab.id == HOLDS || tab.id == REVIEW))? true : false,
        value: getMessage(`app.rendered_assets.accordion_tabs.${tab.id}`),
        ...tab,
      }));

    if (IfFeature.isDisabled('comments')) {
      accordionTabs = accordionTabs.filter(({ id }) => id !== COMMENT);
    }

    if (visibleAccordions) {
      accordionTabs = accordionTabs.filter(({ id }) =>
        includes(visibleAccordions, id),
      );
    }

    return (
      <AccordionComponent
        widgetId="renderedAsset"
        type="small"
        tabs={accordionTabs}
        selectedTab={this.state.selectedTab}
        onSelectTab={this.onSelectTab}
      />
    );
  }
}
