import { trim } from 'lodash';

import reduxStore, { Action, PromiseObject } from '../reduxStore';

import configs from '../../config/configs';

import jsApi from '../lib/jsApi';
import { jsUi } from '../lib/jsUi';
import { apiReqPromise } from '../lib/apiRequest';

import { PAGES } from '../components/FlashMessages';

import {
  showSuccessFlash,
  showFailureFlash,
  resetFlash,
} from './FlashMessagesStateManager';
import { closeModal } from './ModalsStateManager';
import { loadSavedSearches } from './SavedSearchesStateManager';
import { resetFilter } from './SearchFormStateManager';
import { openSavedSearchStream } from '../lib/searchResultsStreamManager';
import generateFilteredQuery, { QUERY_TYPES } from '../lib/generateFilteredQuery';
import toDSL from '../lib/convertSimpleSearchIntoSearchDSLStructure';

export const SAVE_UNSAVED_SEARCH_START: string = 'unsavedSearch/saveStart';
export const SAVE_UNSAVED_SEARCH_FINISHED: string = 'unsavedSearch/saveFinished';
export const SAVE_UNSAVED_SEARCH_FAILED: string = 'unsavedSearch/saveFailed';
export const RUN_UNSAVED_SEARCH: string = 'unsavedSearch/run';
export const RUN_MODIFIED_SAVED_SEARCH: string = 'unsavedSearch/runModifiedSavedSearch';
export const RUN_MY_ARCHIVE_SEARCH: string = 'unsavedSearch/runMyArchiveSearch';
export const RUN_FILTERED_UNSAVED_SEARCH: string =
  'unsavedSearch/runFilteredUnsavedSearch';
export const LOAD_SEARCH_SUMMARY_START: string = 'searchSummary/loadStart';
export const LOAD_SEARCH_SUMMARY_FINISHED: string = 'searchSummary/loadFinished';
export const LOAD_SEARCH_SUMMARY_FAILED: string = 'searchSummary/loadFailed';
export const STORE_LOADED_SAVED_SEARCH: string = 'unsavedSearch/storeLoadedSavedSearch';
export const RESET_FILTERED_FORM_VALUES: string =
  'unsavedSearch/resetFilteredFormValues';
export const RESET_ERROR: string = 'unsavedSearch/resetError';
export const RESET: string = 'unsavedSearch/reset';

const {
  CRITERIA_TYPES,
  DEPENDENCIES,
  RELATIONS,
  LIST_ELEM_TYPES,
} = configs.CRITERIA;

export const BASE_FLASH: string = 'unsaved_search';

// Init
export const ERROR_TYPES = {
  ZERO_RESULTS: 'Zero Results',
};

export const DEFAULT_CRITERIA = {
  type: LIST_ELEM_TYPES.CRITERIA,
  criteriaType: null,
  values: {
    relation: RELATIONS.AND,
    dependency: DEPENDENCIES.IS,
  },
};

export const DEFAULT_GROUP = {
  type: LIST_ELEM_TYPES.GROUP,
  relation: RELATIONS.AND,
  children: {},
};

export const DEFAULT_SIMPLE_SEARCH = {
  INITIAL_DATA: {
    keywords: '',
    from: '',
    participants: '',
    dates: {
      from: '',
      to: '',
      criteria: '',
    },
    sizes: '',
    holds: [],
    tags: [],
  },

  CRITERIA_TYPES: [
    CRITERIA_TYPES.KEYWORDS,
    CRITERIA_TYPES.FROM,
    CRITERIA_TYPES.PARTICIPANTS,
    CRITERIA_TYPES.DATES,
    CRITERIA_TYPES.SIZES,
    CRITERIA_TYPES.HOLDS,
    CRITERIA_TYPES.TAGS,
  ],
};

export const DEFAULT_ADVANCED_SEARCH = {
  INITIAL_DATA: {
    form: {
      list: { 0: { ...DEFAULT_GROUP } },
      defaults: {
        defaultGroup: { ...DEFAULT_GROUP },
        defaultCriteria: { ...DEFAULT_CRITERIA },
        relations: { ...RELATIONS },
        listElemTypes: { ...LIST_ELEM_TYPES },
        dependencies: { ...DEPENDENCIES },
      },
    },
  },
  CRITERIA_TYPES: [
    CRITERIA_TYPES.KEYWORDS,
    CRITERIA_TYPES.ADV_PARTICIPANTS,
    CRITERIA_TYPES.DATES,
    CRITERIA_TYPES.ATTACHMENTS,
    CRITERIA_TYPES.SIZES,
    CRITERIA_TYPES.HOLDS,
    CRITERIA_TYPES.LISTS,
  ],
  CRITERIA_LISTS: [CRITERIA_TYPES.KEYWORD_LISTS, CRITERIA_TYPES.USER_LISTS],
};

const fixedFilterTypes = {
  RECEIVED: 'Received',
  SENT: 'Sent',
};

interface UnsavedSearchState {
  isSaving: boolean,
  isSaveFailed: boolean,
  summary: any,
  data: {
    query: string,
    originalQuery: any,
    formValues: any,
    filteredFormValues: any,
    clientData: {
      type: string,
      formValues: any,
      items: any,
    },
  },
}

export const INITIAL_STATE: UnsavedSearchState = {
  isSaving: false,
  isSaveFailed: false,
  summary: {},
  data: {
    query: '',
    originalQuery: null,
    formValues: {},
    filteredFormValues: {},
    clientData: {
      type: '',
      formValues: {},
      items: {},
    },
  },
};

// helpers
export function populateUnsavedSearchData(search: any) {
  return {
    ...search,
    name: trim(search.name),
  };
}

export function bodyForSaveUnsavedSearch() {
  const {
    unsavedSearch: {
      data: { originalQuery, fields = {}, clientData },
    },
  }: any = reduxStore.getState();

  return {
    fields,
    'client-data': JSON.stringify(clientData),
    query: originalQuery,
  };
}

// actions
export function resetError() {
  return { type: RESET_ERROR };
}

export function resetFilteredFormValues() {
  return { type: RESET_FILTERED_FORM_VALUES };
}

export function resetUnsavedSearch() {
  return { type: RESET };
}

export function saveUnsavedSearch(unsavedSearch: any, { isNewSearch = false } = {}, personal = false) {
  return function* doSaveUnsavedSearch() {
    yield { type: SAVE_UNSAVED_SEARCH_START };

    const modifiedUnsavedSearch = { ...unsavedSearch, personal };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.searchesCreate, {
        params: {
          data: { search: populateUnsavedSearchData(modifiedUnsavedSearch) },
        },
      });
      const newPath = isNewSearch ? 'searchesIndex' : 'resultsIndex';
      const validFlashView = isNewSearch
        ? PAGES.SEARCHES
        : PAGES.SEARCH_RESULTS;
      reduxStore.dispatch(
        showSuccessFlash(`${BASE_FLASH}.create`, [validFlashView]),
      );

      if (!personal) {
        jsUi[newPath].goTo({ id: result.data.id });
      } else {
        reduxStore.dispatch(
          showSuccessFlash(`${BASE_FLASH}.create`, PAGES.MY_ARCHIVE_SPLIT_VIEW),
        );
        const { id } = result.data;
        reduxStore.dispatch(loadSavedSearches(0, true));
        openSavedSearchStream(id, null, true);
      }
      yield { type: SAVE_UNSAVED_SEARCH_FINISHED };
    } catch (e) {
      console.error(e);

      let flashPath = `${BASE_FLASH}.update`;

      if (e.status === 409 && e.data.conflict === ERROR_TYPES.ZERO_RESULTS) {
        flashPath = 'random_search.with_zero_results';
      } else if (isNewSearch) {
        reduxStore.dispatch(closeModal());
      }

      reduxStore.dispatch(showFailureFlash(flashPath, [PAGES.SAVE_SEARCH]));
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      yield { type: SAVE_UNSAVED_SEARCH_FAILED };
    }
  };
}

export function runUnsavedSearch(newData: any) {
  return function* doRunUnsavedSearch() {
    yield {
      type: RUN_UNSAVED_SEARCH,
      payload: { data: newData },
    };
    if (!newData.clientData.personal) jsUi.newSearchResultsIndex.goTo();
  };
}

export function runModifiedSavedSearch(newData: any, searchId: string) {
  return function* doRunModifiedSavedSearch() {
    yield {
      type: RUN_MODIFIED_SAVED_SEARCH,
      payload: { data: newData },
    };

    jsUi.searchesResultsIndex.goTo({ search_id: searchId });
  };
}

export function runMyArchiveSearch(newData: any) {
  return {
    type: RUN_MY_ARCHIVE_SEARCH,
    payload: { data: newData },
  };
}

export function runFilteredUnsavedSearch(formValues: any, query: string) {
  return {
    type: RUN_FILTERED_UNSAVED_SEARCH,
    payload: {
      data: {
        filteredFormValues: formValues,
        query,
      },
    },
  };
}

export function runFixedFilterPersonalSearch(fixedFilter: any, deletedSearchEvent = false) {
  return function* doRunFixedFilterPersonalSearch() {
    yield { type: SAVE_UNSAVED_SEARCH_START };

    if (!deletedSearchEvent) {
      reduxStore.dispatch(
        resetFlash({ from: 'UnsavedSearchStateManager/runFixedFilterPersonalSearch' }),
      );
    }
    const { RECEIVED, SENT } = fixedFilterTypes;
    const userHandles = reduxStore.getState().user.user.handles.reduce((handleStore: any, user: any) => {
      handleStore.push(user.handle);
      return handleStore;
    }, []);
    let filteredData: any = '';
    switch (fixedFilter) {
      case SENT: {
        filteredData = { from: { query: userHandles.join(' ') } };

        break;
      }
      case RECEIVED: {
        filteredData = { participants: { query: userHandles.join(' ') } };

        break;
      }
      default:
    }

    const filteredQuery = generateFilteredQuery(
      QUERY_TYPES.UNSAVED_MY_ARCHIVE,
      toDSL(filteredData),
    );

    reduxStore.dispatch(resetFilter());
    reduxStore.dispatch(resetFilteredFormValues());
    reduxStore.dispatch(openSavedSearchStream(null, filteredQuery, true, fixedFilter));

    yield { type: SAVE_UNSAVED_SEARCH_FINISHED };
  };
}

export function loadUnsavedSearchSummary(query: any) {
  return function* doLoadUnsavedSearchSummary() {
    yield { type: LOAD_SEARCH_SUMMARY_START };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.searchesQuerySummaries, {
        params: {
          data: { query },
        },
      });

      yield {
        type: LOAD_SEARCH_SUMMARY_FINISHED,
        payload: { summary: result.data },
      };
    } catch (e) {
      console.error(e);

      yield { type: LOAD_SEARCH_SUMMARY_FAILED };
    }
  };
}

export function storeLoadedSavedSearch(savedSearch: any) {
  return function* doStoreLoadedSavedSearch(getState: Function) {
    const { filteredFormValues, query } = getState().unsavedSearch.data;
    let newData: any = {};

    if (savedSearch.clientData.formValues) {
      newData = {
        ...newData.data,
        ...savedSearch,
        filteredFormValues,
        query: filteredFormValues ? query : savedSearch.query,
        originalQuery: savedSearch.query,
      };
    }

    yield { type: STORE_LOADED_SAVED_SEARCH, payload: { data: newData } };
  };
}

// Store
export default function UnsavedSearchStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case SAVE_UNSAVED_SEARCH_START:
      return {
        ...INITIAL_STATE,
        isSaving: true,
        isSaveFailed: false,
      };
    case SAVE_UNSAVED_SEARCH_FINISHED:
      return {
        ...state,
        isSaving: false,
      };
    case SAVE_UNSAVED_SEARCH_FAILED:
      return {
        ...state,
        isSaving: false,
        isSaveFailed: true,
      };
    case RUN_UNSAVED_SEARCH:
    case RUN_MODIFIED_SAVED_SEARCH:
    case RUN_MY_ARCHIVE_SEARCH:
      return {
        ...INITIAL_STATE,
        data: {
          ...INITIAL_STATE.data,
          ...payload.data,
          originalQuery: payload.data.query,
        },
      };
    case RUN_FILTERED_UNSAVED_SEARCH:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
      };
    case LOAD_SEARCH_SUMMARY_START:
    case LOAD_SEARCH_SUMMARY_FAILED:
      return {
        ...state,
        summary: {},
      };
    case LOAD_SEARCH_SUMMARY_FINISHED:
      return {
        ...state,
        summary: payload.summary,
      };
    case STORE_LOADED_SAVED_SEARCH:
      return {
        ...INITIAL_STATE,
        data: {
          ...INITIAL_STATE.data,
          ...payload.data,
        },
      };
    case RESET_ERROR:
      return {
        ...state,
        isSaveFailed: false,
      };
    case RESET_FILTERED_FORM_VALUES:
      return {
        ...state,
        data: {
          ...state.data,
          filteredFormValues: {},
        },
      };
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
