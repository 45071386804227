import deepmerge from 'deepmerge';
import { omit, camelCase } from 'lodash';
import Metacarattere from 'metacarattere';

function addQueryParams(url, queryParams) {
  if (Object.keys(queryParams).length === 0) {
    return url;
  }

  return `${url}?${new URLSearchParams(queryParams).toString()}`;
}

function buildParamsUrl(baseUrl, params = {}) {
  if (!/[:*]/.test(baseUrl)) {
    return addQueryParams(baseUrl, params);
  }

  // https://www.npmjs.com/package/metacarattere
  // metacarattere is a small matcher for URLs with colon placeholders.
  // it is used to replace things such as the account defined in this route
  // from the route json file ->  "v1/:account_id/users"
  const base = new Metacarattere(baseUrl);
  let builtUrl = base.build(params);
  const usedTerms = base.getPlaceholders();

  // to do: uncomment this section 
  // if (!builtUrl || builtUrl.indexOf(':') !== -1) {
  //   throw new Error(`Incorrect parameters for ${baseUrl}: ${JSON.stringify(params)}`);
  // }

  builtUrl = builtUrl.replace(/\*([a-z0-9_]+)/, (all, term) => {
    if (!params[term]) {
      throw new Error(`Incorrect parameters for ${baseUrl}: ${JSON.stringify(params)}`);
    }

    usedTerms.push(term);

    return params[term];
  });

  const queryParams = {};
  Object.keys(params).forEach((key) => {
    if (usedTerms.indexOf(key) === -1) {
      queryParams[key] = params[key];
    }
  });
  
  return addQueryParams(builtUrl, queryParams);
}

function cleanMetadataKeys(metadata) {
  return Object.keys(metadata).reduce((all, key) => (
    { ...all, [key.replace(/^_/, '')]: metadata[key] }
  ), {});
}

function pathForKey(...parts) {
  return parts.join('/')
    .split('/')
    .filter(k => !/[:*]/.test(k))
    .filter((_, i) => i !== 0);
}

// Builds the routes used in js
function buildNestedRoute(node, {
  prefixFn,
  currentPath = [],
  currentMetadata = {},
  appendVersion = null,
}) {
  const isRoot = currentPath.length === 0;
  let pathsToAppendVersionTo = [];

  if (isRoot) {
    const versionsByBaseName = Object.keys(node).reduce((_all, path) => {
      const all = { ..._all };

      // remove param placeholders to create the basename
      const baseName = path.split('/').filter(
        p => p.substr(0, 1) !== ':',
      )
        .slice(1)
        .join('');

      all[baseName] = (all[baseName] || []);
      all[baseName].push(path);

      return all;
    }, {});

    Object.keys(versionsByBaseName).forEach((baseName) => {
      if (versionsByBaseName[baseName].length > 1) {
        pathsToAppendVersionTo = pathsToAppendVersionTo.concat(
          versionsByBaseName[baseName],
        );
      }
    });
  }

  return Object.keys(node).reduce((all, path) => {
    const newAll = { ...all };

    if (typeof (node[path]) !== 'object') {
      throw new Error(`${node[path]} is not an object! check your routes config.`);
    }

    const version = path.split('/')[0];
    const { actions, metadata } = node[path];
    const rootMetadata = deepmerge(currentMetadata, metadata || {});

    const rootShouldAppendVersion = pathsToAppendVersionTo.indexOf(path) !== -1;

    if (actions) {
      actions.forEach(([actionPath, method, _metadata]) => {
        let keyParts = pathForKey(...currentPath, path, actionPath).join('_');
        if (appendVersion || rootShouldAppendVersion) {
          keyParts = `${keyParts}_${appendVersion || version}`;
        }

        const actionKey = camelCase(keyParts);

        let pathBase = [...currentPath, path];
        if (actionPath.indexOf('!') !== 0) {
          pathBase.push(actionPath);
        }

        pathBase = pathBase.join('/');

        const pathFn = params => buildParamsUrl(prefixFn(pathBase), params);
        const actionMetadata = cleanMetadataKeys(deepmerge(rootMetadata, _metadata || {}));

        newAll[actionKey] = {
          method,
          path: pathFn,
          metadata: actionMetadata,
        };
      });
    }

    return {
      ...newAll,
      ...buildNestedRoute(
        omit(node[path], 'actions', 'metadata'),
        {
          prefixFn,
          currentPath: [...currentPath, path],
          currentMetadata: rootMetadata,
          appendVersion: appendVersion || (rootShouldAppendVersion && version),
        },
      ),
    };
  }, {});
}

export default function jsApi(routes, prefixFn) {
  return buildNestedRoute(routes, { prefixFn });
}
