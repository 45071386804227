import md5 from 'md5';

import sessionTimestampManager from 'component-utilities/sessionTimestampManager/sessionTimestampManager';

import reduxStore from '../../reduxStore';
import IfFeature from '../../components/IfFeature';
import { SESSION_EXPIRATION } from '../../components/Modals/modalMap.json';
import PAGES from '../../constants/Pages';

import createUserPayloadString from '../../../features/Authentication/lib/createUserPayloadString';
import { resetFlash, showFlash } from '../FlashMessagesStateManager';
import { openModal } from '../ModalsStateManager';

export const SESSION_EXPIRED: string = 'Session Expired';
export const ALREADY_LOGGED_OUT: string = 'Already logged out';

export const SESSION_EXPIRED_FLASH_ID: string = 'login.session.failure';
export const VALID_FLASH_VIEWS: string[] = [PAGES.LOGIN];

let actions: any = {};

// init
export default function authenticationHelper(store: any = reduxStore) {
  return store.getState().authentication;
}

export function initAuthenticationHelper(authenticationActions: any) {
  actions = authenticationActions;
}

// session expiration manager
export function onShowTimestampModal(authTokenInterval: any) {
  const { sessionsShow, forceLogout } = actions;

  reduxStore.dispatch(
    openModal(SESSION_EXPIRATION, {
      onStillWorking: () => {
        // Ping an API endpoint to reset auth token expiry on backend
        reduxStore.dispatch(sessionsShow());
      },
      onLogMeOut: () => {
        clearInterval(authTokenInterval);

        reduxStore.dispatch(forceLogout());
      },
    }),
  );
}

export function onTimestampLogout() {
  reduxStore.dispatch(showFlash(SESSION_EXPIRED_FLASH_ID, VALID_FLASH_VIEWS));
  reduxStore.dispatch(actions.forceLogout());
}

export const sessionManager = sessionTimestampManager({
  onShowModal: onShowTimestampModal,
  onLogout: onTimestampLogout,
});

// general api requests middleware
export function requestsMiddleware(config: any) {
  const { forceLogout, getLoggedInAs, isLoggedIn } = actions;

  let newConfig = { ...config };

  if (IfFeature.isEnabled('session.manager')) {
    sessionManager.reset(isLoggedIn());
  }

  if (!config.metadata.isOutsideLink && !isLoggedIn()) {
    reduxStore.dispatch(forceLogout());
  }

  if (isLoggedIn()) {
    const loggedInAs = getLoggedInAs();

    newConfig = {
      ...config,
      headers: {
        ...config.headers,
        'X-AuthenticationToken': loggedInAs.authenticationToken,
      },
      params: {
        ...config.params,
        user_payload_hash: md5(createUserPayloadString(loggedInAs)),
      },
    };
  }

  return newConfig;
}

// general api responses callback
export function responseFailureCallbackBase(error: any) {
  const { forceLogout, isLoggedIn } = actions;

  if ([401, 422].some(status => error.status === status)) {
    let returnValue;

    reduxStore.dispatch(
      resetFlash({
        from: 'AuthenticationStateManagerHelper/responseFailureCallbackBase',
      }),
    );

    switch (error.status) {
      case 401:
      case 422:
        if (isLoggedIn()) {
          returnValue = SESSION_EXPIRED;

          reduxStore.dispatch(forceLogout());
          reduxStore.dispatch(
            showFlash(SESSION_EXPIRED_FLASH_ID, VALID_FLASH_VIEWS),
          );
        } else {
          returnValue = ALREADY_LOGGED_OUT;

          reduxStore.dispatch(forceLogout());
        }

        return returnValue;
      default:
        return `unknown status: ${error.status}`;
    }
  }

  return false;
}

export function responseFailureCallback(error: any) {
  const result = responseFailureCallbackBase(error);

  if (result) {
    throw new Error(result);
  }
}

export function authenticatedResponseFailureCallback() {
  let didFail = false;

  return function handleCallback(error: any) {
    if (!didFail) {
      try {
        responseFailureCallback(error);
      } catch (e) {
        // the failure callback was caught by something we logged the user out
        // of the app from, so we're disabling future checks.
        didFail = true;
      }
    }

    console.log(error);

    return Promise.reject(error);
  };
}