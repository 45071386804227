/* eslint-disable filenames/match-regex */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { jsUi } from '../../../global/lib/jsUi';

import { resetFlash } from '../../../global/state_managers/FlashMessagesStateManager';
import { updateField } from '../../../global/state_managers/LoginFormStateManager';
import domainInfo, {
  isAccountDisabledOrSuspended,
} from '../../../global/lib/domainInfo';

const mapActionsToProps = { resetFlash, updateField };

type Props = {
  resetFlash: Function
  updateField: Function
}

/* eslint-disable react/no-multi-comp */
export default function LoginBase(options: any = {}) {
  return (component: any) => {
    class LoginBaseHOCComponent extends Component<Props> {
      static propTypes = {
        resetFlash: PropTypes.func.isRequired,
        updateField: PropTypes.func.isRequired,
      };

      state = options.state || {};

      onValueChange = (name: string) => ({ target: { value } }: any) => {
        this.setState({ [name]: value });

        this.props.updateField(name, name === 'username' ? value.trim() : value);

        if (!this.isFlashRemoved && !isAccountDisabledOrSuspended()) {
          this.isFlashRemoved = true;

          this.props.resetFlash({ from: 'LoginBase' });
        }
      };

      isFlashRemoved = false;

      render() {
        const {
          siteName,
          status,
          accountId,
          supportEmailEnabled,
          termsUrlEnabled,
          supportEmail,
        } = domainInfo();
        const isAccountDeleted = status === 'deleted';
        /* Will show View logo if no custom logo set, and will show nothing if
         * a custom logo fails to load */

        return React.createElement(component, {
          ...this.props,
          ...this.state,
          onValueChange: this.onValueChange,
          goToLoginPage: jsUi.loginWithRedirect.goTo,
          isAccountDeleted,
          supportEmailEnabled,
          termsUrlEnabled,
          supportEmail,
          siteName,
          accountId,
        });
      }
    }

    return connect(
      null,
      mapActionsToProps,
    )(LoginBaseHOCComponent);
  };
}
