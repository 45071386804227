import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import RenderedAssetViewBase from '../../global/components/AssetView/RenderedAssetViewBase';

import { tabs } from '../../global/components/AssetView/Accordion';
import routeLeaveHook from '../../global/lib/routeLeaveHook';

import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

const mapStateToProps = null;
const mapActionsToProps = {
  setBreadcrumbForUnsavedSearchAssetPage:
    breadcrumbsActions.unsavedSearchAssetPage,
};

@routeLeaveHook
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class UnsavedSearchRenderedAsset extends Component {
  static propTypes = {
    setBreadcrumbForUnsavedSearchAssetPage: PropTypes.func.isRequired,
  };

  constructor(props: any) {
    super(props);

    props.setBreadcrumbForUnsavedSearchAssetPage();
    this.visibleAccordions = [tabs.METADATA, tabs.HOLDS];
  }

  render() {
    return <RenderedAssetViewBase {...this.props} />;
  }
}
