import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { jsUi } from '../../global/lib/jsUi';

import RenderedAssetViewBase from '../../global/components/AssetView/RenderedAssetViewBase';
import { tabs } from '../../global/components/AssetView/Accordion';
import routeLeaveHook from '../../global/lib/routeLeaveHook';

import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

const mapStateToProps = ({ currentSavedSearch }: any) => ({
  currentSavedSearch,
  searchName: currentSavedSearch.search.name,
  searchId: currentSavedSearch.search.id || '',
});

const mapActionsToProps = {
  setBreadcrumbForModifiedSearchAssetPage:
    breadcrumbsActions.modifiedSearchAssetPage,
};

@routeLeaveHook
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class SavedSearchAssetView extends Component {
  static propTypes = {
    params: PropTypes.shape({
      search_id: PropTypes.string,
    }).isRequired,
    searchId: PropTypes.string,
    setBreadcrumbForModifiedSearchAssetPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    searchId: null,
  };

  constructor(props: any) {
    super(props);

    const {
      params: { search_id },
      searchId,
    } = props;

    if (!searchId) {
      jsUi.searchesShow.goTo({ search_id });
    }

    props.setBreadcrumbForModifiedSearchAssetPage();
    this.visibleAccordions = [tabs.METADATA, tabs.HOLDS];
  }

  render() {
    return <RenderedAssetViewBase {...this.props} />;
  }
}
