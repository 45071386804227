/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import cx from 'classnames';
import { uniq } from 'lodash';
import { is, fromJS } from 'immutable';

import PanelManager from 'v-c/CompositeComponents/DropdownPanel/PanelManager';

import Roles from 'v-c/Buttons/ActionButton/Roles';

import MODAL_MAP from '../../Modals/modalMap.json';
import {
  openModal,
  closeModal,
} from '../../../state_managers/ModalsStateManager';

import IfFeature from '../../IfFeature';
import withBulkUserBaseProps from '../../../../features/Users/components/withBulkUserBaseProps';

import styles from './RolesButton.scss';
import '../ActionsBase.scss';

import configs from '../../../../config/configs';
import { User } from 'global/types';

const { USER_ROLES } = configs;

type Props = {
  onCloseAll: Function
  resetUsersBulkOperations: Function
  hasErrors: boolean
  lastBulkUpdate: string
  bulkAddUserRoles: Function
  bulkRemoveUserRoles: Function
  openModal: Function
  closeModal: Function
  actionHelper: {
    rolesButton: {
      onRolesClick: Function
      multiSelect: {
        isAllSelected: boolean
        selectedRows: any[]
      },
      loggedInAsId: string,
    }
  }
  refHandlers: {
    container: any
    button: any
  }
}

type State = {
  activeRolesIcon: any
  isPanelOpen: boolean
  selectedRoles: string[]
  selectedUsers: User[]
  hoveredIdx: null | string
}

const mapActionsToProps = { openModal, closeModal };
@connect(
  null,
  mapActionsToProps,
)
@withBulkUserBaseProps
@PanelManager
export default class RolesButton extends Component<Props, State> {
  static propTypes = {
    ...withBulkUserBaseProps.propTypes,
    resetUsersBulkOperations: PropTypes.func.isRequired,
    bulkAddUserRoles: PropTypes.func.isRequired,
    bulkRemoveUserRoles: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      activeRolesIcon: undefined,
      isPanelOpen: false,
      hoveredIdx: null,
      selectedRoles: [],
      selectedUsers: [],
    };

    this.userRoles = [...USER_ROLES.LOCALIZED_DATA].map((role) => {
      const modifiedRole = { ...role };

      return modifiedRole;
    });

    if (IfFeature.isDisabled('end_user_access')) {
      this.userRoles = this.userRoles.filter(({ tag }) => tag !== 'EU');
    }

    props.onCloseAll(this.onCloseAll);
  }

  componentDidMount() {
    this.props.resetUsersBulkOperations();
  }

  componentDidUpdate(prevProps: Props) {
    const latestBulkUpdateOrError =
      !is(
        fromJS(this.props.lastBulkUpdate),
        fromJS(prevProps.lastBulkUpdate),
      ) || this.props.hasErrors;

    if (latestBulkUpdateOrError && this.state.isPanelOpen) {
      this.onClose();
    }
  }

  onCloseAll = () => {
    this.setState({
      isPanelOpen: false,
      activeRolesIcon: undefined,
      selectedRoles: [],
    });
  };

  onRolesButtonClick = () => {
    const { isPanelOpen } = this.state;

    let shouldOpenModal = false;
    if (!isPanelOpen) {
      shouldOpenModal = this.props.actionHelper.rolesButton.onRolesClick();
    }

    if (shouldOpenModal) {
      this.togglePanel();
    } else if (isPanelOpen) {
      this.onClose();
    }
  };

  onAdd = (selectedRoles: string[]) => {
    this.props.bulkAddUserRoles(this.selectedUsersAndRoles(selectedRoles));
    this.props.closeModal({ from: 'RolesButton ' });
  };

  onRemove = (selectedRoles: string[]) => {
    this.props.bulkRemoveUserRoles(this.selectedUsersAndRoles(selectedRoles));
    this.props.closeModal({ from: 'RolesButton ' });
  };

  selectedUsersAndRoles = (selectedRoles: string[]) => {
    const {
      multiSelect: { isAllSelected, selectedRows },
      loggedInAsId,
    } = this.props.actionHelper.rolesButton;
    const { selectedUsers } = this.state;

    const transformedData = selectedUsers.reduce(
      (all, user) => ({
        ...all,
        [user]: selectedRoles,
      }),
      {},
    );

    return {
      usersRoles: transformedData,
      multiSelect: {
        isAllSelected,
        except: uniq([loggedInAsId].concat(isAllSelected ? selectedRows : [])),
        ids: selectedRows,
        roles: selectedRoles,
      },
    };
  };

  togglePanel = () => {
    this.props.openModal(MODAL_MAP.ROLES_MODAL, {
      bulkOptions: {
        addRoles: this.onAdd,
        removeRoles: this.onRemove,
      },
      disabledRole: false,
      selectedRoles: [],
      userRoles: this.userRoles,
      from: 'RolesButton',
    });
  };

  render() {
    const { container, button } = this.props.refHandlers;

    const extraClassName = cx(styles['roles-button'], {
      [styles.active]: this.state.isPanelOpen,
    });

    return (
      <div
        ref={container}
        className="action-wrapper"
        style={{ marginLeft: 15 }}
      >
        <Roles
          ref={button('roles')}
          extraClassName={extraClassName}
          image={this.state.activeRolesIcon}
          data-action="roles-select"
          onClick={this.onRolesButtonClick}
        />
      </div>
    );
  }
}
