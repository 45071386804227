import { trim } from 'lodash';

import { SortCell } from 'v-c/TableView';

import reduxStore, { Action, PromiseObject } from '../reduxStore';
import { jsUi } from '../lib/jsUi';
import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';
import { simpleSearch, sort } from '../lib/tableviewHelper';

import {
  showSuccessFlash,
  showFailureFlash,
} from './FlashMessagesStateManager';
import { PAGES } from '../components/FlashMessages';

export const LIST_DELETED: string = 'searchLists/deleted';
export const LIST_DELETING: string = 'searchLists/deleting';
export const LIST_FAILED_TO_DELETE: string = 'searchLists/listFailedToDelete';
export const ACTIVE_COLUMNS_CHANGED: string = 'searchLists/activeColumnsChanged';
export const SEARCH: string = 'searchLists/search';
export const SORT_COLUMN: string = 'searchLists/sortColumn';
export const SORT_LIST_SEARCH_COLUMN: string = 'searchLists/sortListSearchColumn';
export const LOADING: string = 'searchLists/loading';
export const LOADED: string = 'searchLists/loaded';
export const FAILED_TO_LOAD: string = 'searchLists/failedToLoad';
export const CREATING_LIST: string = 'searchLists/creatingList';
export const LIST_CREATED: string = 'searchLists/listCreated';
export const LIST_FAILED_TO_CREATE: string = 'searchLists/listFailedToCreate';
export const LOADING_LIST: string = 'searchLists/loadingList';
export const LIST_LOADED: string = 'searchLists/listLoaded';
export const LIST_FAILED_TO_LOAD: string = 'searchLists/listFailedToLoad';
export const UPDATING_LIST: string = 'searchLists/updateList';
export const LIST_UPDATED: string = 'searchLists/listUpdated';
export const LIST_FAILED_TO_UPDATE: string = 'searchLists/listFailedToUpdate';
export const RESET: string = 'searchLists/reset';

export const BASE_FLASH: string = 'search_lists';
export const VALID_FLASH_VIEWS: string[] = [PAGES.LISTS];

// Init
const searchListscolumns = {
  NAME: 'name',
  TYPE: 'type',
  USER: 'createdUser',
  MODIFIED_DATE: 'lastModifiedTime',
  OPEN_SEARCH: 'open',
};

const { NAME, TYPE, USER, MODIFIED_DATE, OPEN_SEARCH } = searchListscolumns;

const allSearchListsColumns = [NAME, TYPE, USER, MODIFIED_DATE];
const disabledSearchListsColumns = [NAME, OPEN_SEARCH];

const {
  sortKeys: { DESC },
} = SortCell;

interface SearchListsState {
  isDeleting: boolean,
  isLoading: boolean,
  isLoaded: boolean,
  isSingleListLoading: boolean,
  searchString: string,
  searchList: any,
  searchLists: any[],
  tableHelper: {
    columns: any,
    allColumns: string[],
    activeColumns: string[],
    disabledColumns: string[],
  },
  sortOptions: {
    sortKey: string,
    sortOrder: string,
  },
}

export const INITIAL_STATE = {
  isDeleting: false,
  isLoading: false,
  isLoaded: false,
  isSingleListLoading: false,
  searchString: '',
  searchList: {},
  searchLists: [],
  tableHelper: {
    columns: searchListscolumns,
    allColumns: allSearchListsColumns,
    activeColumns: allSearchListsColumns,
    disabledColumns: disabledSearchListsColumns,
  },
  sortOptions: {
    sortKey: NAME,
    sortOrder: DESC,
  },
};

// Actions
export function loadSearchLists(type: any) {
  return function* doLoadSearchLists() {
    yield { type: LOADING };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.searchListsIndex, {
        params: { type },
      });

      yield {
        type: LOADED,
        payload: { searchLists: result.data },
      };
    } catch (e) {
      console.error(e);

      yield { type: FAILED_TO_LOAD };
    }
  };
}

export function createSearchList(type: any, list: any) {
  return function* doCreateSearchList() {
    yield { type: CREATING_LIST };

    try {
      yield apiReqPromise(jsApi.searchListsCreate, {
        params: {
          data: {
            list: {
              type,
              ...list,
              name: trim(list.name),
            },
          },
        },
      });

      yield { type: LIST_CREATED };

      jsUi.settingsSearchListsIndex.goTo();
      reduxStore.dispatch(
        showSuccessFlash(`${BASE_FLASH}.create`, VALID_FLASH_VIEWS),
      );
    } catch (e) {
      console.error(e);

      yield { type: LIST_FAILED_TO_CREATE };

      jsUi.settingsSearchListsIndex.goTo();
      reduxStore.dispatch(
        showFailureFlash(`${BASE_FLASH}.create`, VALID_FLASH_VIEWS),
      );
    }
  };
}

export function deleteSearchList(listId: string) {
  return function* doDeleteSearchList() {
    yield { type: LIST_DELETING };

    try {
      yield apiReqPromise(jsApi.searchListsDestroy, {
        urlParams: { id: listId },
      });

      yield { type: LIST_DELETED };

      jsUi.settingsSearchListsIndex.goTo();
      reduxStore.dispatch(
        showSuccessFlash(`${BASE_FLASH}.delete`, VALID_FLASH_VIEWS),
      );
    } catch (e) {
      yield { type: LIST_FAILED_TO_DELETE };

      reduxStore.dispatch(
        showFailureFlash(`${BASE_FLASH}.delete`, [PAGES.EDIT_LIST]),
      );
    }
  };
}

export function loadSearchList(id: string) {
  return function* doLoadSearchList() {
    yield { type: LOADING_LIST };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.searchListsShow, {
        urlParams: { id },
      });

      yield {
        type: LIST_LOADED,
        payload: { searchList: result.data },
      };
    } catch (e) {
      console.error(e);

      jsUi.settingsSearchListsIndex.goTo();
      reduxStore.dispatch(
        showFailureFlash(`${BASE_FLASH}.load`, VALID_FLASH_VIEWS),
      );
      yield { type: LIST_FAILED_TO_LOAD };
    }
  };
}

export function updateSearchList(id: string, list: any) {
  return function* doUpdateSearchList() {
    yield { type: UPDATING_LIST };

    try {
      yield apiReqPromise(jsApi.searchListsUpdate, {
        urlParams: { id },
        params: { data: { list: { ...list, name: trim(list.name) } } },
      });

      yield { type: LIST_UPDATED };

      jsUi.settingsSearchListsIndex.goTo();
      reduxStore.dispatch(
        showSuccessFlash(`${BASE_FLASH}.update`, VALID_FLASH_VIEWS),
      );
    } catch (e) {
      console.error(e);

      yield { type: LIST_FAILED_TO_UPDATE };

      jsUi.settingsSearchListsIndex.goTo();
      reduxStore.dispatch(
        showFailureFlash(`${BASE_FLASH}.update`, VALID_FLASH_VIEWS),
      );
    }
  };
}

export function activeColumnsChanged(newColumns: string[]) {
  return {
    type: ACTIVE_COLUMNS_CHANGED,
    payload: { activeColumns: newColumns },
  };
}

export function sortColumn(id: string, direction: string) {
  return {
    type: SORT_COLUMN,
    payload: { id, direction },
  };
}

export function sortListSearchColumn(id: string, direction: string) {
  return {
    type: SORT_LIST_SEARCH_COLUMN,
    payload: { id, direction },
  };
}

export function searchSearchLists(searchString: string) {
  return {
    type: SEARCH,
    payload: { searchString },
  };
}

export function resetSearchLists() {
  return { type: RESET };
}

// Store
export default function SearchListsStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case ACTIVE_COLUMNS_CHANGED:
      return {
        ...state,
        tableHelper: {
          ...state.tableHelper,
          activeColumns: payload.activeColumns,
        },
      };
    case SEARCH:
      return {
        ...state,
        searchLists: simpleSearch(
          state.originalSearchLists,
          payload.searchString,
          ['name', 'user', 'modified_date'],
        ),
        searchString: payload.searchString,
      };
    case SORT_LIST_SEARCH_COLUMN:
      return {
        ...state,
        searchList: {
          ...state.searchList,
          searches: sort(state.searchList.searches, payload.id, payload.direction),
          sortOptions: {
            sortKey: payload.id,
            sortOrder: payload.direction,
          },
        },
      };
    case SORT_COLUMN:
      return {
        ...state,
        sortOptions: {
          sortKey: payload.id,
          sortOrder: payload.direction,
        },
        searchLists: sort(state.searchLists, payload.id, payload.direction),
        originalSearchLists: sort(
          state.originalSearchLists,
          payload.id,
          payload.direction,
        ),
      };
    case LOADING:
    case CREATING_LIST:
    case UPDATING_LIST:
      return { ...state, isLoading: true, isLoaded: false };
    case LIST_LOADED:
      return {
        ...state,
        isSingleListLoading: false,
        searchList: {
          ...payload.searchList,
          sortOptions: {
            sortKey: NAME,
            sortOrder: DESC,
          },
        },
      };
    case LOADING_LIST:
      return {
        ...state,
        isSingleListLoading: true,
      };
    case LOADED:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        searchLists: payload.searchLists,
        originalSearchLists: payload.searchLists,
      };
    case FAILED_TO_LOAD:
    case LIST_FAILED_TO_LOAD:
    case LIST_CREATED:
    case LIST_FAILED_TO_CREATE:
    case LIST_UPDATED:
    case LIST_FAILED_TO_UPDATE:
      return { ...state, isLoading: false, isLoaded: false };
    case LIST_DELETING:
      return { ...state, isDeleting: true };
    case LIST_FAILED_TO_DELETE:
    case LIST_DELETED:
      return { ...state, isDeleting: false };
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}