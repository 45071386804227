import moment from 'moment';

import SizeValues from 'data/selections/advSizeValues.json';
import AdvancedDSLFields from 'data/AdvancedDSLFields.json';
import {
  KEYWORDS,
  ADV_PARTICIPANTS,
  DATES,
  ATTACHMENTS,
  SIZES,
  HOLDS,
  USER_LISTS,
  KEYWORD_LISTS,
} from 'data/Criteria';
import { DATE_FORMATS, getTZDifference } from 'component-utilities/dates/dates';

import messagesOnly from './MessageDSLOnly';
import parseSearchStringValue from './ParseSearchStringValue';

import { getReasonsAttributeId } from './contextTreeHelper';

import { DEFAULT_ADVANCED_SEARCH } from '../state_managers/UnsavedSearchStateManager';
import removeQuoteWrapsIfExists from './removeQuoteWrapsIfExists';

const {
  INITIAL_DATA: {
    form: { defaults },
  },
} = DEFAULT_ADVANCED_SEARCH;

type Args = {
  DSLList: string[]
  criteriaType: string,
  values: {
    from: {
      value: string
    },
    to: string,
    dependency: {},
    list: any[],
    relation: string,
    selectedFields: string[]
    selectedCriteria: string[],
    isKeywordExact: boolean,
    isAdvParticipantsDomain: boolean
  }
}

export default function convertAdvancedSearchIntoSearchDSLStructure(query: string) {
  const {
    listElemTypes: { CRITERIA, GROUP },
    dependencies: { IS_NOT },
  } = defaults;

  const populateCriteriaDSL = ({ relation, dependency, DSLList }: any) => {
    let DSLString = DSLList.join(' ');

    if (DSLList.length > 1) {
      DSLString = `(${relation} ${DSLString})`;
    }

    if (dependency === IS_NOT) {
      DSLString = `(not ${DSLString})`;
    }

    return DSLString;
  };

  const addMultiTextSelectTypeCriteria = ({ criteriaType, values }: Args) => {
    const { dependency, list, relation, selectedCriteria, isKeywordExact, isAdvParticipantsDomain } = values;
    const DSLList: string[] = [];
    if (!list) return '';

    list.forEach((listElem: string) => {
      const parsedListElem = parseSearchStringValue(listElem);

      parsedListElem.forEach((parsedString) => {
        if (parsedString.length) {
          const textBetweenquotes = new RegExp(/"([^"]+)"/);
          const preText = textBetweenquotes.test(parsedString) ? 'contains-phrase' : 'contains';
          const dslValue = removeQuoteWrapsIfExists(parsedString);
          const criteriaDSL: string[] = [];
          selectedCriteria.forEach((criteria) => {
            AdvancedDSLFields[criteria].forEach((fieldType: string) => {
              let newFieldType = criteriaType === KEYWORDS && isKeywordExact ? `${fieldType}.exact` : fieldType;
              if (isAdvParticipantsDomain) {
                newFieldType = `${fieldType}.domain`;
              }
              criteriaDSL.push(
                `(${preText} ${newFieldType} "${dslValue}")`,
              );
            });
          });
          DSLList.push(`(or ${criteriaDSL.join(' ')})`);
        }
      });
    });

    return populateCriteriaDSL({ relation, dependency, DSLList });
  };

  const addDateTypeCriteria = ({ criteriaType, values }: Args) => {
    const { selectedCriteria, dependency } = values;
    const { from, to, name } = AdvancedDSLFields[selectedCriteria];

    const convertDateValues = () => {
      const convertDate = (value: string, type: string) => {
        let convertedDate = moment(value).endOf('day');

        if (type === 'start') {
          convertedDate = moment(value).startOf('day');
        }

        convertedDate = convertedDate.add(-getTZDifference(), 's');

        return `"${convertedDate.format(DATE_FORMATS.DSL)}"`;
      };

      let newFrom = values.from;
      let newTo = values.to;

      if (to) {
        newTo = convertDate(newTo || newFrom, to);
      }

      if (from) {
        newFrom = convertDate(newFrom, from);
      }

      return { from: newFrom, to: newTo || '' };
    };

    const dateValues = convertDateValues();
    let DSLString = `(${name} ${AdvancedDSLFields[criteriaType]} ${
      dateValues.from
    }`;

    if (dateValues.to.length) {
      DSLString = `${DSLString} ${dateValues.to}`;
    }

    DSLString = `${DSLString})`;

    if (dependency === IS_NOT) {
      DSLString = `(not ${DSLString})`;
    }

    return DSLString;
  };

  const addAttachmentTypeCriteria = ({ criteriaType, values }: Args) => {
    const { dependency, relation, selectedCriteria } = values;
    const DSLList = [];

    if (selectedCriteria[0] === '__any__') {
      DSLList.push('(greater-than attachment-count 0)');
    } else {
      selectedCriteria.forEach((criteria: string) => {
        const criteriaDSL: string[] = [];
        AdvancedDSLFields[criteria].forEach((fileType: string) => {
          criteriaDSL.push(
            `(contains ${AdvancedDSLFields[criteriaType]} "*.${fileType}")`,
          );
        });
        DSLList.push(`(or ${criteriaDSL.join(' ')})`);
      });
    }

    return populateCriteriaDSL({ relation, dependency, DSLList });
  };

  const addSizeTypeCriteria = ({ criteriaType, values }: Args) => {
    const { dependency, from, to, selectedCriteria } = values;
    const populateSizeValue = ({ size, value }: any) => {
      const { multiplier } = SizeValues.find(({ id }) => id === size);
      return value * multiplier;
    };

    let DSLString = `(${AdvancedDSLFields[selectedCriteria]} ${
      AdvancedDSLFields[criteriaType]
    } ${populateSizeValue(from)}`;

    if (to.value.length) {
      DSLString = `${DSLString} ${populateSizeValue(to)}`;
    }

    DSLString = `${DSLString})`;

    if (dependency === IS_NOT) {
      DSLString = `(not ${DSLString})`;
    }

    return DSLString;
  };

  const addHoldTypeCriteria = ({ criteriaType, values }: Args) => {
    const { dependency, relation, selectedCriteria } = values;
    const DSLList: string[] = [];

    selectedCriteria.forEach((criteria) => {
      if (criteria.length) {
        DSLList.push(
          `(${
            AdvancedDSLFields[criteriaType]
          } ${getReasonsAttributeId()} "${criteria}")`,
        );
      }
    });

    return populateCriteriaDSL({ relation, dependency, DSLList });
  };

  const addSearchListCriteria = ({ criteriaType, values }: Args) => {
    const { dependency, relation, selectedCriteria, selectedFields, isKeywordExact = false } = values;
    const fieldsByType = () => {
      if (criteriaType === KEYWORD_LISTS || criteriaType === USER_LISTS) {
        return selectedFields.reduce(
          (dslFields, field) => dslFields.concat(AdvancedDSLFields[field]),
          [],
        );
      }
      return [];
    };

    const DSLList = selectedCriteria.map((criteria) => {
      const list = fieldsByType().map((field) => {
        const newField = isKeywordExact ? `${field}.exact` : field;
        return `(list-op or contains ${newField} ${criteria})`;
      });

      return `(or ${list.join(' ')})`;
    });

    return populateCriteriaDSL({ relation, dependency, DSLList });
  };

  const getDSLForGroup = (group: any) => {
    const criteriaDSL: string[] = [];
    const { relation } = group;

    Object.keys(group.children).forEach((childKey) => {
      const child = group.children[childKey];

      switch (child.type) {
        case CRITERIA:
          switch (child.criteriaType) {
            case KEYWORDS:
            case ADV_PARTICIPANTS:
              criteriaDSL.push(addMultiTextSelectTypeCriteria(child));
              break;
            case DATES:
              criteriaDSL.push(addDateTypeCriteria(child));
              break;
            case ATTACHMENTS:
              criteriaDSL.push(addAttachmentTypeCriteria(child));
              break;
            case SIZES:
              criteriaDSL.push(addSizeTypeCriteria(child));
              break;
            case HOLDS:
              criteriaDSL.push(addHoldTypeCriteria(child));
              break;
            case USER_LISTS:
            case KEYWORD_LISTS:
              criteriaDSL.push(addSearchListCriteria(child));
              break;
            default:
              throw new Error(`unknown type: ${child.criteriaType}`);
          }
          break;
        case GROUP: {
          const nestedDSL = getDSLForGroup(child);
          if (nestedDSL) {
            criteriaDSL.push(nestedDSL);
          }
          break;
        }
        default:
          throw new Error(`unknown type: ${child.type}`);
      }
    });

    if (criteriaDSL.length > 1) {
      return `(${relation} ${criteriaDSL.join(' ')})`;
    }

    return criteriaDSL.join(' ');
  };

  return messagesOnly(getDSLForGroup(query[0]));
}
