/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Actions, { actions, actionsDirtyCheck } from './TableViewActions';
import { stateLogic, renderActionsProps } from './ActionsRendererDeconstructed';

export { actions, actionsDirtyCheck };

type Props = {
  actions: string[],
  actionsConfig?: {},
  actionsProps?: {},
  shouldRenderTitles?: boolean
}

@stateLogic({
  tableActions: actions,
  })
export default class ActionsRenderer extends Component<Props> {
  static propTypes = {
    actions: PropTypes.array.isRequired,
    actionsConfig: PropTypes.object,
    actionsProps: PropTypes.object,
    shouldRenderTitles: PropTypes.bool,
  };

  static defaultProps = {
    actionsProps: {},
    actionsConfig: {},
    shouldRenderTitles: true,
  };

  render() {
    return (
      <div style={{ display: 'inline-block' }}>
        {
          renderActionsProps(this.props).map(({ action, props }) => {
            const Action = Actions[action];

            return <Action {...props} key={action} shouldRenderTitles={this.props.shouldRenderTitles} />;
          })
        }
      </div>
    );
  }
}
