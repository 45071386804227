import reduxStore, { Action, PromiseObject } from '../reduxStore';
import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';

import {
  showSuccessFlash,
  showFailureFlash,
} from '../state_managers/FlashMessagesStateManager';

import PAGES from '../constants/Pages';

const BASE_FLASH: string = 'retention_policy';
const VALID_FLASH_VIEWS: string[] = [PAGES.SETTINGS_RETENTION_POLICY];

export const RESET: string = 'retentionPolicy/reset';
export const LOADING: string = 'retentionPolicy/loading';
export const LOADED: string = 'retentionPolicy/loaded';
export const LOAD_FAILED: string = 'retentionPolicy/loadFailed';
export const UPDATING: string = 'retentionPolicy/updating';
export const UPDATED: string = 'retentionPolicy/updated';
export const UPDATE_FAILED: string = 'retentionPolicy/updateFailed';

interface RetentionPolicyState {
  isLoading: boolean,
  isLoaded: boolean,
  loadFailed: boolean,
  isUpdating: boolean,
  isUpdated: boolean,
  updateFailed: boolean,
  policy: {
    ttl: number,
    enabled: boolean,
    retentionPolicyId: string,
    mtime: string,
    updateRequestId: string,
  },
}

export const INITIAL_STATE: RetentionPolicyState = {
  isLoading: false,
  isLoaded: false,
  loadFailed: false,
  isUpdating: false,
  isUpdated: false,
  updateFailed: false,
  policy: {
    ttl: 0,
    enabled: false,
    retentionPolicyId: '',
    mtime: '',
    updateRequestId: '',
  },
};

export function loading() {
  return { type: LOADING };
}

export function loaded(policy: any) {
  return {
    type: LOADED,
    payload: policy,
  };
}

export function loadFailed() {
  return { type: LOAD_FAILED };
}

export function load() {
  return function* doLoad() {
    try {
      yield loading();

      const result: PromiseObject = yield apiReqPromise(jsApi.retentionPoliciesIndex);

      yield loaded(result.data);
    } catch (e) {
      console.error('Load Retention Policy error', e);
      yield loadFailed();
    }
  };
}

export function updating() {
  return { type: UPDATING };
}

export function updated() {
  return { type: UPDATED };
}

export function updateFailed() {
  return { type: UPDATE_FAILED };
}

export function update(policy: any) {
  return function* doUpdate() {
    try {
      yield updating();

      yield apiReqPromise(jsApi.retentionPoliciesUpdate, {
        params: {
          data: policy,
        },
      });

      yield updated();
      reduxStore.dispatch(showSuccessFlash(`${BASE_FLASH}.update`, VALID_FLASH_VIEWS));
    } catch (e) {
      console.error('Update Retention Policy error', e);
      yield updateFailed();
      reduxStore.dispatch(showFailureFlash(`${BASE_FLASH}.update`, VALID_FLASH_VIEWS));
    }
  };
}

export function reset() {
  return { type: RESET };
}

export default function RetentionPolicyStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case RESET:
      return { ...INITIAL_STATE };
    case LOADING:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case LOADED:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        policy: payload,
      };
    case LOAD_FAILED:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        loadFailed: true,
      };
    case UPDATING:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
      };
    case UPDATED:
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
      };
    case UPDATE_FAILED:
      return {
        ...state,
        isUpdating: false,
        isUpdated: false,
        updateFailed: true,
      };
    default:
      return state;
  }
}
