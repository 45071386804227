import React from 'react';
import PropTypes from 'prop-types';

import {
  InputField,
  FormErrorText,
} from 'v-c/Forms';
import {
  MinusRedIcon,
} from 'v-c/Icons';

import styles from '../components/Settings.scss';

type Props = {
  onDelete: Function,
  onChange: Function,
  name: string,
  index: number,
  isEditable: Boolean,
  isValid: Boolean,
  error: string,
  focused: Boolean,
}

export default function DomainComponent({
  onDelete, onChange, name, index,
  isEditable, isValid, error,
  focused,
}: Props) {
  return (
    <div className={styles['email-domain-row']}>
      <MinusRedIcon
        data-action={`remove-${name}`}
        className={styles['email-domain-icon']}
        onClick={onDelete}
      />
      {!isEditable && <data data-value="name">{name}</data>}
      {isEditable &&
        <data data-section="form">
          <div className={styles['domain-input']}>
            <InputField
              focused={focused}
              onChange={onChange}
              data-field={`new-email-domain-${index}`}
              value={name}
            />
          </div>
          {!isValid &&
            <div data-error={error} className={styles['form-error']}>
              <FormErrorText
                field={`domain-error-${index}`}
                error={error}
              />
            </div>
          }
        </data>
      }
    </div>
  );
}

DomainComponent.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  focused: PropTypes.bool,
};

DomainComponent.defaultProps = {
  error: null,
  focused: false,
};
