import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'component-utilities/intl';

import {
  ButtonContainer,
  ButtonSpacer,
  DangerFilledButton,
  DefaultButton,
} from 'view-components/components/Dialog/Components';

type Props = {
  isConfirmed: boolean
  onConfirm: Function
  onDismiss: Function
  buttonText: string
}

export default function ConfirmableButtonContainer(props: Props) {
  const confirmProps = {
    disabled: !props.isConfirmed,
  };

  // TODO: remove .bind
  /* eslint-disable react/jsx-no-bind */
  const onConfirm = props.onConfirm.bind(this);
  const onDismiss = props.onDismiss.bind(this);

  const buttonText = props.buttonText || 'delete';

  return (
    <ButtonContainer>
      <DangerFilledButton
        data-action="confirm"
        onClick={onConfirm}
        {...confirmProps}
      >
        <FormattedMessage id={`app.button.${buttonText}`} />
      </DangerFilledButton>
      <ButtonSpacer />
      <DefaultButton
        data-action="dismiss"
        onClick={onDismiss}
      >
        <FormattedMessage id="app.button.cancel" />
      </DefaultButton>
    </ButtonContainer>
  );
  /* eslint-enable react/jsx-no-bind */
}

ConfirmableButtonContainer.propTypes = {
  buttonText: PropTypes.string,
  isConfirmed: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

ConfirmableButtonContainer.defaultProps = {
  buttonText: '',
};
