import React from 'react';
import PropTypes from 'prop-types';

import { CustomCheckbox, TextLabel } from 'v-c/Widgets/Components';
import { PrimaryButton, SecondaryButton } from 'v-c/Buttons/Components';
import Processing from '../Processing';

type Props = {
  activeFileTypes: {},
  getButtonText: Function,
  isProcessing: boolean,
  onActionClick: Function,
  onExportSelected: Function,
  onOldExportSubmit: Function,
  processingText: string,
  selectedFileType: string,
  selectedText: string,
}

export default function ExportOldDropdown({
  activeFileTypes,
  getButtonText,
  isProcessing,
  onActionClick,
  onExportSelected,
  onOldExportSubmit,
  processingText,
  selectedFileType,
  selectedText,
}: Props) {
  const selectExportFormat = Object.keys(activeFileTypes).map((type) => {
    const fileType = activeFileTypes[type];
    const isChecked = selectedFileType === fileType;

    return (
      <div
        key={fileType}
        className="export-row"
        data-row={fileType}
        onClick={onExportSelected(fileType)}
        role="checkbox"
        tabIndex="-1"
        aria-checked={isChecked}
      >
        <CustomCheckbox
          widgetId={fileType}
          className="content-checkbox"
          isChecked={isChecked}
        />
        <TextLabel className="text-label">
          {`.${fileType.toUpperCase()}`}
        </TextLabel>
      </div>
    );
  });

  return (
    <div className="content-wrapper">
      <Processing text={processingText} isProcessing={isProcessing} />
      <data>
        <div className="content-header">
          <TextLabel className="head-label">
            {selectedText}
          </TextLabel>
        </div>
        <div className="content-main">
          {selectExportFormat}
        </div>
        <div className="content-footer">
          <PrimaryButton
            extraClassName="add-button"
            onClick={onOldExportSubmit}
            data-action="export"
          >
            {getButtonText('export')}
          </PrimaryButton>
          <SecondaryButton
            extraClassName="cancel-button"
            onClick={onActionClick}
            data-action="cancel"
          >
            {getButtonText('cancel')}
          </SecondaryButton>
        </div>
      </data>
    </div>
  );
}

ExportOldDropdown.propTypes = {
  activeFileTypes: PropTypes.object.isRequired,
  getButtonText: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  onActionClick: PropTypes.func.isRequired,
  onExportSelected: PropTypes.func.isRequired,
  onOldExportSubmit: PropTypes.func.isRequired,
  processingText: PropTypes.string.isRequired,
  selectedFileType: PropTypes.string.isRequired,
  selectedText: PropTypes.string.isRequired,
};
