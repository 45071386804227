import { jsUi } from './jsUi';

export const BASE_KEY = 'app.breadcrumb';

export function generalBreadcrumb(type: string) {
  return ({ getMessage, breadcrumb }: any) => ({
    'data-name': getMessage(`${BASE_KEY}.${breadcrumb.toLowerCase()}`),
    onClick: jsUi[type].goTo,
  });
}

export function searchesResults({ params }: any) {
  return {
    'data-name': params.searchName,
    onClick: () => {
      jsUi.resultsIndex.goTo({ id: params.searchId });
    },
    'data-value': params.searchId,
  };
}

export const mapToBreadcumbs = {
  SEARCHES: generalBreadcrumb('searchesIndex'),
  RETURN_TO_SEARCH_RESULTS: searchesResults,
};

export default function Breadcrumbs({ breadcrumbs, getMessage, params }: any) {
  return breadcrumbs.reduce((all: any[], breadcrumb: any) => {
    if (mapToBreadcumbs[breadcrumb]) {
      return [...all, {
        'data-breadcrumb': breadcrumb,
        'data-action': breadcrumb,
        ...mapToBreadcumbs[breadcrumb]({ breadcrumb, getMessage, params }),
      }];
    }

    return all;
  }, []);
}
