import React from 'react';
import PropTypes from 'prop-types';

import { browserHistory } from 'react-router';
import withMessages, { intlPropTypes } from 'component-utilities/intl';
import New from 'v-c/Buttons/ActionButton/New';

import styles from './New.scss';

type Props = {
  actionHelper: {
    new: {
      path: string
    }
  }
}

function NewAction(props: Props) {
  let value;
  if (props.actionHelper !== undefined && props.actionHelper.new) {
    const {
      actionHelper: { new: { path } },
    } = props;

    value = (
      <New
        extraClassName={styles['new-button']}
        data-action="new-action"
        onClick={() => { browserHistory.push(path); }}
      />
    );
  } else {
    value = (<div />);
  }

  return value;
}

NewAction.propTypes = {
  ...intlPropTypes,
  actionHelper: PropTypes.shape({
    new: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }),
  }),
};

const NewButton = withMessages(NewAction);

export default NewButton;
