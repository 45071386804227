import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withMessages, { FormattedHTMLMessage } from 'component-utilities/intl';

import TooltipGrayIcon from 'view-components/components/Icons/TooltipGrayIcon';
import Tooltip from 'v-c/Tooltip/Tooltip';
import { LinkLabel, TextLabel } from 'view-components/components/Widgets/Components';

import { URLS } from '../../../config/configs';

import style from './AddApplication.scss';

import { jsUi } from '../../../global/lib/jsUi';

type Props = {
  getMessage: Function,
}

@withMessages
export default class AddApplication extends Component<Props> {
  static propTypes = {
    getMessage: PropTypes.func.isRequired,
  };

  render() {
    const { getMessage } = this.props;
    const loginPath = jsUi.login.path();
    const domain = /:\/\/([^/]+)/.exec(window.location.href)[1];
    // TODO: figure out a way to reliably extract user domain from location
    // or set value from config file
    const signOnUrl = `https://${domain}${loginPath}`;
    const learnMore = () => {
      window.open(URLS.AD_SYNC.LEARN_MORE, '_blank');
    };
    return (
      <div className={style['add-application']}>
        <TextLabel className={style['info-text']}>
          <FormattedHTMLMessage
            id="app.ad_sync.add_application.info_text"
          />
        </TextLabel>
        <LinkLabel
          className={style.link}
          onClick={learnMore}
        >
          {getMessage('app.ad_sync.add_application.learn_more')}
        </LinkLabel>

        <TextLabel className={style['info-bold']}>
          {getMessage('app.ad_sync.add_application.use_values')}
        </TextLabel>

        <TextLabel className={style['input-label']}>
          {getMessage('app.ad_sync.add_application.sign_on_url')}
        </TextLabel>
        <Tooltip
          position="right"
          type="single"
          content={getMessage('app.ad_sync.add_application.sign_on_url_tooltip')}
        >
          <TooltipGrayIcon />
        </Tooltip>
        <TextLabel className={style.value}>
          {signOnUrl}
        </TextLabel>
        <TextLabel className={style['input-label']}>
          {getMessage('app.ad_sync.add_application.identifier')}
        </TextLabel>
        <Tooltip
          position="right"
          type="single"
          content={getMessage('app.ad_sync.add_application.identifier_tooltip')}
        >
          <TooltipGrayIcon />
        </Tooltip>
        <TextLabel className={style.value}>
          {signOnUrl}
        </TextLabel>
        <TextLabel className={style['input-label']}>
          {getMessage('app.ad_sync.add_application.reply_url')}
        </TextLabel>
        <Tooltip
          position="right"
          type="single"
          content={getMessage('app.ad_sync.add_application.reply_url_tooltip')}
        >
          <TooltipGrayIcon />
        </Tooltip>
        <TextLabel className={style.value}>
          {signOnUrl}
        </TextLabel>
      </div>
    );
  }
}
