import Login from '../features/Authentication/Login.proxy';
import Logout from '../features/Authentication/Logout.proxy';
import ResetPassword from '../features/Authentication/ResetPassword.proxy';
import ChangePassword from '../features/Authentication/ChangePassword.proxy';
import ChangeUserPassword from '../features/Users/ChangeUserPassword.proxy';

import AccountSummary from '../features/AccountSummary/AccountSummary.proxy';

import SearchesIndex from '../features/Searches/SavedSearchList.proxy';
import SearchesSave from '../features/Searches/SaveCurrentSearch/SaveCurrentSearch.proxy';
import SearchesEdit from '../features/Searches/SavedSearchEdit.proxy';
import SearchesResultsIndex from '../features/Searches/SavedSearchResults.proxy';
import SearchesResultsAsset from '../features/Searches/SavedSearchAssetView.proxy';

import ResultsIndex from '../features/Searches/SearchResults.proxy';
import ResultsAssets from '../features/Searches/RenderedAsset.proxy';

import NewSearchIndex from '../features/Searches/NewSearchResults.proxy';
import NewSearchSave from '../features/Searches/SaveNewSearch/SaveNewSearch.proxy';
import NewSearchEdit from '../features/Searches/UnsavedSearchEdit.proxy';
import NewSearchResultsIndex from '../features/Searches/UnsavedSearchResults.proxy';
import NewSearchResultsAsset from '../features/Searches/UnsavedSearchRenderedAsset.proxy';

import Exports from '../features/Exports/Exports.proxy';

import HoldReasonsIndex from '../features/HoldReasons/HoldReasons.proxy';
import HoldReasonsNew from '../features/HoldReasons/NewHoldReason.proxy';
import HoldReasonsShowIndex from '../features/HoldReasons/Assets/HoldReasonAssets.proxy';
import HoldReasonsShowAssets from '../features/HoldReasons/Assets/HoldReasonRenderedAsset.proxy';

import UsersIndex from '../features/Users/Users.proxy';
import UsersNew from '../features/Users/NewUser.proxy';
import UsersEdit from '../features/Users/EditUser.proxy';

import MyArchiveSplitViewIndex from '../features/EndUser/MyArchive.proxy';

import SettingsIndex from '../features/Settings/Settings';

import SettingsCollectorsIndex from '../features/Settings/Collectors.proxy';
import SettingsCollectorsEmail from '../features/Settings/Email.proxy';

import SettingsPolicies from '../features/Settings/Policies.proxy';

import SettingsReportsIndex from '../features/Settings/Reports.proxy';
import SettingsReportsAuditsNew from '../features/Settings/Reports/NewAuditReport.proxy';
import SettingsReportsReportsEdit from '../features/Settings/Reports/EditReport.proxy';

import SettingsSearchListsIndex from '../features/Settings/SearchLists.proxy';
import SettingsSearchListsNew from '../features/Settings/NewSearchList.proxy';
import SettingsSearchListsEdit from '../features/Settings/EditSearchList.proxy';

import SettingsAccountSettings from '../features/Settings/AccountSettings.proxy';

import SettingsSecurity from '../features/Settings/Security.proxy';

import SettingsO365Integration from '../features/Settings/components/O365Integration.proxy';

import SettingsSingleSignOn from '../features/Settings/SingleSignOn';

import SettingsAdSyncIndex from '../features/Settings/ADSync.proxy';
import SettingsAdSyncAdWizard from '../features/Settings/ADWizard.proxy';
import SettingsAdSyncAdSuccess from '../features/Settings/ADSuccess.proxy';
import SettingsAdSyncSyncLog from '../features/Settings/ADSyncLog.proxy';
import SettingsAdSyncSyncConflicts from '../features/Settings/ADSyncConflicts.proxy';
import SettingsAdSyncAttributeMappings from '../features/Settings/ADSyncAttributeMappings.proxy';
import SettingsAdSyncSourceAdGroups from '../features/Settings/ADSyncSourceADGroups.proxy';
import SettingsAdSyncAppCredentials from '../features/Settings/ADSyncAppCredentials.proxy';

export default {
  Login,
  Logout,
  ResetPassword,
  ChangePassword,
  ChangeUserPassword,

  Dashboard: AccountSummary,

  SearchesIndex,
  SearchesShow: SearchesSave,
  SearchesEdit,
  SearchesResultsIndex,
  SearchesResultsAsset,

  ResultsIndex,
  ResultsAssets,
  ResultsSplitViewAssets: ResultsIndex,

  NewSearchIndex,
  NewSearchSave,
  NewSearchEdit,
  NewSearchResultsIndex,
  NewSearchResultsAsset,

  Exports,

  HoldReasonsIndex,
  HoldReasonsNew,
  HoldReasonsShowIndex,
  HoldReasonsShowAssets,

  UsersIndex,
  UsersNew,
  UsersEdit,

  MyArchiveSplitViewIndex,
  MyArchiveSplitViewAsset: MyArchiveSplitViewIndex,

  SettingsIndex,
  SettingsCollectorsIndex,
  SettingsCollectorsEmail,
  SettingsPolicies,
  SettingsReportsIndex,
  SettingsReportsAuditsNew,
  SettingsReportsReportsEdit,
  SettingsSearchListsIndex,
  SettingsSearchListsNew,
  SettingsSearchListsEdit,
  SettingsAdSyncIndex,
  SettingsAdSyncAdWizard,
  SettingsAdSyncAdSuccess,
  SettingsAdSyncSyncLog,
  SettingsAdSyncSyncConflicts,
  SettingsAdSyncAttributeMappings,
  SettingsAdSyncSourceAdGroups,
  SettingsAdSyncAppCredentials,
  SettingsAccountSettings,
  SettingsSecurity,
  SettingsO365Integration,
  SettingsSingleSignOn,
  // create_settings_section[keys]: new keys go above here
};