export default [
  'EMPTY_ERROR',
  'EMPTY_FIELD',
  'ALREADY_TAKEN',
  'DOES_NOT_MATCH',
  'PASSWORD_COMPLEXITY',
  'INVALID_EMAIL',
  'LENGTH',
  'UPPERCASE',
  'SPECIAL_CHARS',
  'LOWERCASE',
  'DIGITS',
  'INVALID_CHARS',
  'USERNAME_PASSWORD_MATCH',
  'LOGIN_INCORRECT',
  'LOGIN_ACCOUNT_WILL_LOCK_IN',
  'LOGIN_LOCKED',
  'PASSWORDS_NOT_MATCH',
];
