/* eslint-disable filenames/match-regex */
/* eslint-disable no-undef */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { difference, isArray, uniq, get } from 'lodash';
import deepmerge from 'deepmerge';

import {
  commonViewLogicSetup,
  commonViewLogicButtonReturn,
} from '../lib/commonViewLogic.ts';
import validDomain from '../lib/validDomain';

import { LOGIN_PROVIDERS } from '../../../../../../config/app.json';
import isBlank from '../../../global/lib/isBlank';
import { Account } from 'global/types';

const { DEFAULT_PROVIDER } = LOGIN_PROVIDERS;

function not(fn: Function) {
  return (...args: any) => !fn(...args);
}

function asDropdownItem(value: any) {
  return { id: value, value };
}

const getChangedFieldValues = (fields: any) =>
  Object.keys(fields)
    .filter(key => fields[key].dirty)
    .reduce((all, key) => ({ ...all, [key]: fields[key].value }), {});

type State = {
  domainToFocus: number | null
}

type Fields = {
  enabledLoginProviders: {
    value: any,
    onChange: Function
  },
  selfRegistrationDomains: {
    value: any,
    onChange: Function
  }
}

type Props = {
  account: Account,
  ADSyncSetupDone: Boolean,
  fields: Fields,
  isUpdating: Boolean,
  isUserConfirmedForSSO: Boolean,
  dirty: Boolean,
  resetForm: any,
  ssoLoginLink: string,
  isLoadingProviderUrl: string,
  ssoLoginTestResult: any,
  ssoLoginExpectedEmail: string,
  registerUnmountCleanupMethod: Function,
  stopLoadingProviderHref: Function,
  startLoadingProviderHref: Function,
  showRevertLoginProviderDialog: Function,
  updateAccountSettings: Function,
  untouchAll: Function,
  showUpdateSSOSettingsDialog: Function,
}

export function viewLogic(props: Props) {
  const {
    account,
    ADSyncSetupDone,
    fields,
    isUpdating,
    isUserConfirmedForSSO,
  } = props;

  // smooth out any oddities in data returned from the account endpoint.
  const availableLoginProviders = uniq(
    [DEFAULT_PROVIDER].concat(account.availableLoginProviders),
  );

  // technically we could support multiple providers, but for now, we're only ever
  // showing one. If one isn't selected (the default value for this column
  // in otto), show the default provider (direct login) as defined in the app config.
  let enabledLoginProviders = account.enabledLoginProviders || [];
  if (enabledLoginProviders.length === 0) {
    enabledLoginProviders = [DEFAULT_PROVIDER];
  }

  // the ui behaves differently if:
  // * the current provider is different from the selected
  // * the selected provider is not direct login
  const currentLoginProvider = enabledLoginProviders[0];
  const selectedLoginProvider =
    (fields.enabledLoginProviders.value || [])[0] || DEFAULT_PROVIDER;
  const nonDefaultProviderSelected = currentLoginProvider !== DEFAULT_PROVIDER;
  const isSelectedProviderDefault = selectedLoginProvider === DEFAULT_PROVIDER;

  // self-registration domains are only visible when a non-default provider is
  // selected.
  const considerSelfRegistrationDomains =
    !!selectedLoginProvider && !isSelectedProviderDefault;

  let isSelfRegistrationDomainsVisible =
    !isSelectedProviderDefault && !ADSyncSetupDone;

  const selfRegistrationDomains = fields.selfRegistrationDomains.value || [];

  const { isCancelButtonEnabled } = commonViewLogicSetup({
    ...props,
    isDirtyFields: props.dirty,
  });

  let isSaveButtonEnabled = isCancelButtonEnabled;

  // the save button is normally enabled on any change, but if any self-reg
  // domain is invalid, disable it.
  if (isCancelButtonEnabled && considerSelfRegistrationDomains) {
    isSaveButtonEnabled = selfRegistrationDomains.every(validDomain);
  }

  // if the current provider is default, and the selected provider is not,
  // and the user has not tested out their SSO connection, disable the
  // save button.
  const considerSSOConfirmation =
    !nonDefaultProviderSelected && !isSelectedProviderDefault;

  if (considerSSOConfirmation) {
    isSelfRegistrationDomainsVisible =
      !!isUserConfirmedForSSO && !ADSyncSetupDone;
    isSaveButtonEnabled = !!isUserConfirmedForSSO;
  }

  const isUserConfirmedForDesiredSSO =
    considerSSOConfirmation && !!isUserConfirmedForSSO;

  // in case of errors
  const openIDError = get(
    props,
    `location.query.${LOGIN_PROVIDERS.OPENID.ERROR_PARAM}`,
  );

  const output = deepmerge.all([
    props,
    commonViewLogicButtonReturn({
      isSaveButtonEnabled,
      isCancelButtonEnabled,
      isUpdating,
      props,
    }),
    {
      considerSelfRegistrationDomains,
      isSaveButtonEnabledSSO:
        considerSelfRegistrationDomains &&
        selfRegistrationDomains.every(validDomain),
      form: {
        currentLoginProvider,
        selfRegistrationDomains,
        selectedLoginProvider,
        selectedLoginProviders: {
          dropdownData: availableLoginProviders.map(asDropdownItem),
          disabled: nonDefaultProviderSelected,
        },
      },
      changedFieldsWithoutSSO: difference(
        Object.keys(getChangedFieldValues(fields)),
        ['selfRegistrationDomains', 'enabledLoginProviders'],
      ),
      buttons: {
        cancel: {
          onClick: props.resetForm,
        },
        addDomain: {
          visible: selfRegistrationDomains.every(not(isBlank)),
        },
        revertLoginProvider: {
          visible: nonDefaultProviderSelected,
        },
        testLoginProvider: {
          visible:
            !!props.ssoLoginLink &&
            considerSSOConfirmation &&
            !isUserConfirmedForSSO,
          href: props.ssoLoginLink,
        },
      },
      sections: {
        testSsoButtonLoading: {
          visible: !!props.isLoadingProviderUrl,
        },
        selfRegistrationDomains: {
          visible: isSelfRegistrationDomainsVisible,
        },
        openIDError: {
          visible: !!openIDError,
          error: openIDError,
        },
        selfRegistrationDomainMessage: {
          visible: !isSelectedProviderDefault && ADSyncSetupDone,
        },
        loginProviderTestErrors: {
          visible:
            considerSSOConfirmation &&
            !isUserConfirmedForDesiredSSO &&
            !!props.ssoLoginTestResult,
          result: props.ssoLoginTestResult,
          email: props.ssoLoginExpectedEmail,
        },
      },
    },
  ]);

  return output;
}

function determineDirtyFields(props: Props) {
  let isDirtyFields = props.dirty;

  // check for [] vs. null, which is possible after setting, then
  // reverting, the login provider
  if (isDirtyFields) {
    const { account, fields } = props;
    const changedValues: any = getChangedFieldValues(fields);

    if (
      Object.keys(changedValues).length === 1 &&
      changedValues.enabledLoginProviders &&
      isArray(changedValues.enabledLoginProviders) &&
      changedValues.enabledLoginProviders.length === 0 &&
      !account.enabledLoginProviders
    ) {
      isDirtyFields = false;
    }
  }

  return isDirtyFields;
}

export function stateLogic() {
  return (component: any) =>
    class ContentStateLogic extends Component<Props, State> {
      static propTypes = {
        fields: PropTypes.object.isRequired,
        account: PropTypes.object.isRequired,
        untouchAll: PropTypes.func.isRequired,
        stopLoadingProviderHref: PropTypes.func.isRequired,
        startLoadingProviderHref: PropTypes.func.isRequired,
        updateAccountSettings: PropTypes.func.isRequired,
        registerUnmountCleanupMethod: PropTypes.func.isRequired,
        showUpdateSSOSettingsDialog: PropTypes.func.isRequired,
        showRevertLoginProviderDialog: PropTypes.func.isRequired,
      };

      static displayName = 'Settings/SingleSignOn/Content.stateLogic';

      constructor(props: Props) {
        super(props);

        this.state = { domainToFocus: null };

        props.registerUnmountCleanupMethod(() => {
          props.stopLoadingProviderHref();
        });
      }

      componentDidUpdate(prevProps: Props) {
        const currentAccountProvider =
          (prevProps.account.enabledLoginProviders || [])[0] ||
          DEFAULT_PROVIDER;

        const getProviderValue = (fields: Fields) =>
          (fields.enabledLoginProviders.value || [])[0] || DEFAULT_PROVIDER;

        const nextSelectedProvider = getProviderValue(this.props.fields);
        const thisSelectedProvider = getProviderValue(prevProps.fields);

        if (currentAccountProvider === DEFAULT_PROVIDER) {
          if (nextSelectedProvider !== thisSelectedProvider) {
            if (nextSelectedProvider === DEFAULT_PROVIDER) {
              this.props.stopLoadingProviderHref();
            } else {
              this.props.startLoadingProviderHref(nextSelectedProvider);
            }
          }
        }

        setTimeout(this.resetDomainToFocus, 1);
      }

      onRevertLoginProvider = () => {
        this.props.showRevertLoginProviderDialog({
          confirm: this.onConfirmRevertLoginProvider,
        });
      };

      onConfirmRevertLoginProvider = () => {
        this.props.updateAccountSettings({ enabledLoginProviders: [] });

        this.props.fields.enabledLoginProviders.onChange([]);
        this.props.untouchAll();
      };

      onUpdate = () => {
        this.props.showUpdateSSOSettingsDialog({
          confirm: this.onConfirmUpdateAccount,
        });
      };

      onConfirmUpdateAccount = () => {
        const changedValues: any = getChangedFieldValues(this.props.fields);

        if (
          changedValues.enabledLoginProviders &&
          changedValues.enabledLoginProviders[0] === DEFAULT_PROVIDER
        ) {
          changedValues.enabledLoginProviders = [];
        }

        this.props.updateAccountSettings(changedValues);
        this.props.untouchAll();
      };

      onChangeSelfRegistrationDomain = (index: number) => (e: any) => {
        const updatedDomains = [...this.fields.selfRegistrationDomains.value];

        updatedDomains[index] = e.target.value;

        this.fields.selfRegistrationDomains.onChange(updatedDomains);
      };

      onRemoveSelfRegistrationDomain = (index: number) => () => {
        const updatedDomains = [...this.fields.selfRegistrationDomains.value];

        updatedDomains.splice(index, 1);

        this.fields.selfRegistrationDomains.onChange(updatedDomains);
      };

      onAddNewSelfRegistrationDomain = () => {
        const updatedDomains = [
          ...(this.fields.selfRegistrationDomains.value || []),
          '',
        ];

        this.fields.selfRegistrationDomains.onChange(updatedDomains);

        this.setState({ domainToFocus: updatedDomains.length - 1 });
      };

      onSelectLoginProvider = (selectedLoginProvider: any) => {
        this.fields.enabledLoginProviders.onChange([selectedLoginProvider]);
      };

      get fields() {
        return this.props.fields;
      }

      resetDomainToFocus = () => {
        if (this.state.domainToFocus !== null) {
          this.setState({ domainToFocus: null });
        }
      };

      render() {
        return React.createElement(
          component,
          deepmerge.all([
            this.props,
            this.state,
            {
              availableLoginProviders: this.props.account
                .availableLoginProviders,
              onConfirmUpdateAccount: this.onConfirmUpdateAccount,
              onConfirmRevertLoginProvider: this.onConfirmRevertLoginProvider,
              onUpdate: this.onUpdate,
              dirty: determineDirtyFields(this.props),
              form: {
                onChangeSelfRegistrationDomain: this
                  .onChangeSelfRegistrationDomain,
                onAddNewSelfRegistrationDomain: this
                  .onAddNewSelfRegistrationDomain,
                onRemoveSelfRegistrationDomain: this
                  .onRemoveSelfRegistrationDomain,
                onSelectLoginProvider: this.onSelectLoginProvider,
                onRevertLoginProvider: this.onRevertLoginProvider,
              },
            },
          ]),
        );
      }
    };
}
