/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { findDOMNode } from 'react-dom';

import withMessages, { intlPropTypes } from 'component-utilities/intl';
import { isArray, debounce } from 'lodash';

import LoadingIndicator from 'v-c/LoadingIndicator';
import {
  TableView,
  TableHeader,
  TableBody,
  TableRow,
  CheckboxCell,
  UserCell,
  SortCell,
  TextCell,
} from 'v-c/TableView';
import { InputField } from 'v-c/Forms';
import { PrimaryButton, SecondaryButton } from 'v-c/Buttons';

import {
  ModalContainer,
  ModalHeader,
  ModalContentCenter,
  ModalButtons,
  ModalButtonSpacer,
} from '../Modals';

import { GENERAL_CONFIGS } from '../../../config/configs';
import tableUIFixes, { tableUIFixesPropTypes } from '../../lib/tableUIFixes';
import withForm, { formHelperPropTypes } from '../../lib/formHelper';
import sortAboutSelection from '../../lib/sortAboutSelection';

import {
  loadAllUsers,
  filterUsers,
  sortUsers,
  resetUsers,
} from '../../state_managers/UsersStateManager';

import roles from '../../constants/Roles';
import { getPrioritizedUserRole } from '../../lib/userRights';
import { USER_NEEDS_SEARCH_PERMISSION } from '../../../config/constants/PageRoles';

import { setUsersTableSortkey } from '../../artifacts/usersArtifact';

import styles from '../../../features/Users/components/Modals.scss';
import {
  Columns,
  formHelper,
  intlShape,
  ReduxState,
  tableUIFixes as tableUIFixesType,
  User
} from 'global/types';

const {
  sortKeys: { UNSORTED },
} = SortCell;
const {
  rowStatuses: { SELECTED },
} = TableRow;

let searchUsers: any;

// TODO: There's a lot of string refs usage in here and in tableUIFixes.
// These should be rebuilt to either not use refs at all, or to not use
// string refs.
/* eslint-disable react/no-string-refs */

const mapStateToProps = ({ users }: ReduxState) => ({
  tableHelper: users.tableHelper,
  usersAreLoaded: !!users.users.length || !!users.searchString.length,
  isUsersLoading: users.isLoading,
  users: users.users,
  sortOptions: users.sortOptions,
  columns: users.tableHelper.columns,
  searchString: users.searchString,
});

const mapActionsToProps = {
  loadAllUsers,
  filterUsers,
  sortUsers,
  resetUsers,
};

type State = {
  modalUserList: {
    externalId: string
  }[]
  filter: string
}

interface Props extends intlShape, formHelper, tableUIFixesType {
  closeModal: Function,
  modalOptions: {
    userAccessList: any[],
    onAddUser: Function,
    filterUsers: Function,
  },
  loadAllUsers: Function,
  filterUsers: Function,
  sortUsers: Function,
  resetUsers: Function,
  users: User[]
  tableHelper: any
  isUsersLoading: boolean,
  columns: Columns,
  sortOptions: {},
}

@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withForm({
  form: 'add-user',
  fields: ['userAccessList'],
  })
@tableUIFixes
@withMessages
export default class AddUserModal extends Component<Props, State> {
  static propTypes = {
    ...intlPropTypes,
    ...tableUIFixesPropTypes,
    ...formHelperPropTypes,
    closeModal: PropTypes.func.isRequired,
    modalOptions: PropTypes.shape({
      userAccessList: PropTypes.array.isRequired,
      onAddUser: PropTypes.func.isRequired,
      filterUsers: PropTypes.func.isRequired,
    }).isRequired,
    loadAllUsers: PropTypes.func.isRequired,
    filterUsers: PropTypes.func.isRequired,
    sortUsers: PropTypes.func.isRequired,
    resetUsers: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      filter: '',
      modalUserList: this.modalUserList,
    };

    props.setInitialFields({
      userAccessList: props.modalOptions.userAccessList,
    });

    if (!props.users.length) {
      this.props.resetUsers();
      this.props.loadAllUsers();
    }
  }

  componentDidMount() {
    // TODO: replace this with something that doesn't take string refs.
    this.props.registerTableRefs(this.refs);
  }

  componentDidUpdate() {
    // TODO: replace this with something that doesn't take string refs.
    this.props.registerTableRefs(this.refs);

    $('[data-field="search-user"]')
      .focus()
      .val('')
      .val(this.state.filter);

    if (this.lastScrollTopValue) {
      this.tableContent.scrollTop(this.lastScrollTopValue);
    }
  }

  onAddUser = () => {
    this.props.modalOptions.onAddUser(this.userAccessList.value);
    this.props.resetUsers();
    this.props.loadAllUsers();
    this.props.closeModal({ from: 'AddUserModal/onAddUser' });
  };

  onCloseUserModal = () => {
    this.props.resetUsers();
    this.props.loadAllUsers();
    this.props.closeModal({ from: 'AddUserModal/cancel' });
  };

  onClickRow = (externalId: string) => () => {
    this.userAccessList.toggleInArray(externalId);

    this.lastScrollTopValue = this.tableContent.scrollTop();
  };

  onClickAllCheckbox = () => {
    this.userAccessList.change(this.isAllUserSelected ? [] : this.allUserId);
  };

  onSearch = ({ target }: any) => {
    if (!searchUsers) {
      searchUsers = debounce((value) => {
        this.props.filterUsers(value);
      }, GENERAL_CONFIGS.INFINITE_SCROLL.SEARCH_DELAY);
    }

    if (this.state.filter !== target.value) {
      searchUsers(target.value);

      this.setState({ filter: target.value });
    }
  };

  get tableContent() {
    return $(findDOMNode(this.refs.tableContent));
  }

  get userAccessList() {
    return this.props.fields.userAccessList;
  }

  get allUserId() {
    return this.state.modalUserList.map(({ externalId }) => externalId);
  }

  get isAllUserSelected() {
    return (
      !!this.state.modalUserList.length &&
      this.state.modalUserList.length === this.userAccessList.value.length
    );
  }

  get filteredUsers() {
    const { users, fields } = this.props;

    const defaultFilter = (user: User) => user;
    const filteredUsers = users.filter(
      this.props.modalOptions.filterUsers || defaultFilter,
    );

    return sortAboutSelection(
      filteredUsers,
      fields.userAccessList.value,
      'externalId',
    );
  }

  get modalUserList() {
    const { users } = this.props;
    const { Roles } = roles;
    const modalUsers: any[] = [];

    users.map((user) => {
      const prioritizedRole = getPrioritizedUserRole(user.roles);

      if (Roles[prioritizedRole]) {
        const roleCheck = Roles[prioritizedRole].enabledPageRoles.some(
          (pageRole: string) => pageRole === USER_NEEDS_SEARCH_PERMISSION,
        );

        if (roleCheck) {
          modalUsers.push(user);
        }
      }
      return null;
    });
    return modalUsers;
  }

  render() {
    const {
      getMessage,
      isUsersLoading,
      columns,
      sortOptions,
      intl,
      isDirtyFields,
      tableHelper,
    } = this.props;

    const searchPlaceholder = intl.formatMessage(
      { id: 'app.placeholders.search_user' },
      { usersCount: this.filteredUsers.length.toString() },
    );

    if (!isArray(this.userAccessList.value)) {
      return null;
    }

    return (
      <ModalContainer data-modal="add-user">
        <ModalHeader onCloseClick={this.onCloseUserModal}>
          {getMessage('app.save_search_form.manage_user_access')}
        </ModalHeader>
        <ModalContentCenter>
          <div className={styles['search-wrapper']}>
            <InputField
              data-field="search-user"
              value={this.state.filter}
              placeholder={searchPlaceholder}
              onChange={this.onSearch}
            />
          </div>
          <div className={styles['table-wrapper']}>
            <TableView tableId="add-user-modal-header">
              {!isUsersLoading &&
                !!this.filteredUsers.length && (
                  <TableHeader>
                    <TableRow
                      ref="tableHeaderRow"
                      index={0}
                      rowId="table-header-row"
                    >
                      <CheckboxCell
                        onClick={this.onClickAllCheckbox}
                        isChecked={this.isAllUserSelected}
                        cellId="add-user-checkbox"
                        type="small"
                      />
                      {[
                        { type: columns.FULL_NAME, name: 'name' },
                        { type: columns.EMAIL, name: 'email' },
                        { type: columns.USERNAME, name: 'username' },
                        { type: columns.ROLES, name: 'roles' },
                      ].map(({ type, name }) => {
                        const newSortKey = setUsersTableSortkey({
                          sortOptions,
                          cellId: type,
                          FIRST_NAME: tableHelper.FIRST_NAME,
                          FULL_NAME: columns.FULL_NAME,
                          UNSORTED,
                        });

                        return (
                          <SortCell
                            key={type}
                            onClick={this.props.sortUsers}
                            sortKey={newSortKey}
                            text={getMessage(`app.general.${name}`)}
                            cellId={type}
                          />
                        );
                      })}
                    </TableRow>
                  </TableHeader>
                )}
            </TableView>
            <div className={styles['modal-table-content']} ref="tableContent">
              <TableView tableId="add-user-modal-content">
                <TableBody>
                  {this.filteredUsers.map(
                    (
                      {
                        externalId,
                        fullName,
                        email,
                        username,
                        abbreviatedRoles,
                      },
                      idx,
                    ) => {
                      const isSelectedRow = this.userAccessList.value.some(
                        (accessId: string) => accessId === externalId,
                      );

                      return (
                        <TableRow
                          ref={idx === 0 ? 'tableBodyRow' : ''}
                          key={externalId}
                          status={isSelectedRow ? SELECTED : ''}
                          index={idx}
                          rowId={externalId}
                          onClick={this.onClickRow(externalId)}
                        >
                          <CheckboxCell
                            onClick={this.onClickRow(externalId)}
                            isChecked={isSelectedRow}
                            cellId="add-user-checkbox"
                            type="small"
                          />
                          <UserCell
                            isFloatingCell
                            title={fullName}
                            cellId="add-user-name"
                            userName={fullName}
                          />
                          <TextCell cellId="add-user-email" title={email}>
                            {email}
                          </TextCell>
                          <TextCell cellId="add-user-username" title={username}>
                            {username}
                          </TextCell>
                          <TextCell cellId="add-user-roles">
                            {abbreviatedRoles}
                          </TextCell>
                        </TableRow>
                      );
                    },
                  )}
                </TableBody>
              </TableView>
            </div>
          </div>
          {isUsersLoading && (
            <div className={styles.indicator}>
              <LoadingIndicator
                orientation="middle"
                text={`${getMessage('app.indicators.loading')}...`}
                isLoading
              />
            </div>
          )}
        </ModalContentCenter>
        <ModalButtons>
          <PrimaryButton
            data-action="add-users"
            disabled={!isDirtyFields}
            onClick={this.onAddUser}
          >
            {getMessage('app.button.update')}
          </PrimaryButton>
          <ModalButtonSpacer />
          <SecondaryButton
            data-action="cancel-users"
            onClick={this.onCloseUserModal}
          >
            {getMessage('app.button.cancel')}
          </SecondaryButton>
        </ModalButtons>
      </ModalContainer>
    );
  }
}
