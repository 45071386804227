import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Loading from '../components/Loading';

type Props = {
  children: {}
  readyStates: boolean[]
}

const LoadWhenReady = (props: Props) => {
  const [alreadyLoaded, setAlreadyLoaded] = useState(false);

  const {
    children,
    readyStates,
  } = props;

  const isLoading = useMemo(() => readyStates.some(state => !state), [readyStates]);

  useMemo(() => {
    if (!alreadyLoaded && !isLoading) {
      setAlreadyLoaded(true);
    }
  }, [isLoading, alreadyLoaded]);

  return useMemo(() => {
    if (!alreadyLoaded) {
      return <Loading />;
    }

    return children;
  }, [alreadyLoaded, children]);
};

LoadWhenReady.propTypes = {
  children: PropTypes.node.isRequired,
  readyStates: PropTypes.array.isRequired,
};

export default LoadWhenReady;
