import PropTypes from 'prop-types';
import withMessages from 'component-utilities/intl';

import { connect } from 'react-redux';

import {
  bulkAddUserRoles,
  bulkRemoveUserRoles,
  bulkEnableUsers,
  bulkDisableUsers,
  resetUsersBulkOperations,
} from '../../../global/state_managers/UsersBulkOperationsStateManager';

const mapStateToProps = ({ usersBulkOperations }: any) => ({
  ...usersBulkOperations,
  hasErrors: usersBulkOperations.errors !== null,
});

const mapActionsToProps = {
  bulkAddUserRoles,
  bulkRemoveUserRoles,
  bulkEnableUsers,
  bulkDisableUsers,
  resetUsersBulkOperations,
};

export default function withBulkUserBaseProps(component: any) {
  return withMessages(
    connect(
      mapStateToProps,
      mapActionsToProps,
    )(component),
  );
}

withBulkUserBaseProps.propTypes = {
  getMessage: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
};
