import IfFeature from '../../components/IfFeature';

import { ROUTES } from '../../lib/jsUi';
import { ROLES } from '../../../../../../config/roles.json';
import Pages from '../Pages';
import {
  USER_CAN_SEE_COLLECTION_CONFIGURATION,
  USER_CAN_USE_AD_SYNC,
} from '../../../config/constants/PageRoles';

const {
  NEW_USER,
  SETTINGS,
  USER_MANAGEMENT,
  CHANGE_PASSWORD,
  DASHBOARD,
} = Pages;

const { DASHBOARD: DASHBOARD_INDEX, USERS_INDEX }: any = ROUTES || {};

export default {
  name: ROLES.ADMIN_ONLY,
  tag: 'AO',
  homePage: IfFeature.isDisabled('account.summary')
    ? USERS_INDEX
    : DASHBOARD_INDEX,
  visibleMenus: {
    search: [DASHBOARD],
    nav: [USER_MANAGEMENT],
    sub: [NEW_USER],
    other: [CHANGE_PASSWORD, SETTINGS],
  },
  enabledPageRoles: [
    USER_CAN_SEE_COLLECTION_CONFIGURATION,
    USER_CAN_USE_AD_SYNC,
  ],
};
