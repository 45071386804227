/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { findDOMNode } from 'react-dom';

import classNames from 'classnames/bind';
import { throttle, snakeCase, contains } from 'lodash';

import { TextLabel, RadioGroup } from 'v-c/Widgets/Components';
import { PrimaryButton, SecondaryButton } from 'v-c/Buttons/Components';
import ArrowUpIcon from 'v-c/Icons/ArrowUpIcon';
import ArrowDownIcon from 'v-c/Icons/ArrowDownIcon';
import { MinusRedIcon } from 'v-c/Icons';
import { killEvent } from 'event-helpers';

import formBack from '../../../../lib/formBack';

import RemoveIcon from '../../Images/remove.png';

import styles from './CriteriaViewBase.scss';

import { SEARCH } from '../../../../../../../../config/app.json';

const { PATH_INDEX_SEPARATOR } = SEARCH;

const cx = classNames.bind(styles);

type Props = {
  getMessage: Function,
  isViewOnly: boolean
  listElem: {
    values: {
      selectedCriteria: {}
    }
    criteriaType: string
  }
  cachedFormValues?: {},
  index: any,
  listElemOptions: {
    deleteListElem: Function
    setMaskLayerVisibilityFor: Function
    calculateOpenedListElemPosition: Function
    onCriteriaChange: Function
    updateListElem: Function
    changeParentRelation: Function
  },
  defaults: {
    dependencies: {}
    relations: {}
  }
  parentRelation: any,
  changeFormValue: Function,
  makeUndirty: Function,
  form: {
    dependency: {}
    relation: {}
  }
  getFormValues: Function,
  resetForm: Function
  isDirty: Function,
  setInitialFormAndOriginalValues: Function,
  UUID: any,
}

type State = {
  isCriteriaDropdownVisible: boolean
  justCancelled: boolean
  justApplied: boolean
  criteriasVisibilities: {
    isDependencyVisible: boolean
    isRelationVisible: boolean
  }
}

export default function CriteriaViewBase(component: any) {
  @formBack
  class CriteriaViewBaseComponent extends Component<Props, State> {
    static propTypes = {
      getMessage: PropTypes.func.isRequired,
      isViewOnly: PropTypes.bool.isRequired,
      listElem: PropTypes.object.isRequired,
      cachedFormValues: PropTypes.object,
      index: PropTypes.any.isRequired,
      listElemOptions: PropTypes.object.isRequired,
      defaults: PropTypes.object.isRequired,
      parentRelation: PropTypes.any.isRequired,
      changeFormValue: PropTypes.func.isRequired,
      makeUndirty: PropTypes.func.isRequired,
      form: PropTypes.object.isRequired,
      getFormValues: PropTypes.func.isRequired,
      resetForm: PropTypes.func.isRequired,
      isDirty: PropTypes.func.isRequired,
      setInitialFormAndOriginalValues: PropTypes.func.isRequired,
      UUID: PropTypes.any.isRequired,
    };

    static defaultProps = {
      cachedFormValues: null,
    };

    constructor(props: Props) {
      super(props);

      const {
        listElem: { values },
      } = props;

      this.isNewViewBase = true;

      this.disabledCriteriaValues = {
        dependency: [],
        relation: [],
      };

      this.state = {
        criteriasVisibilities: {
          isDependencyVisible: true,
          isRelationVisible: true,
        },
        criteriaValues: [],
        isCriteriaDropdownVisible: !values.selectedCriteria,
        justCancelled: false,
        justApplied: false,
      };

      if (!values.selectedCriteria) {
        this.openCriteria();
      }
    }

    componentDidMount() {
      if (this.calculateVisibleCriteriaNum) {
        const {
          listElem: { values },
        } = this.props;
        window.setTimeout(() => {
          this.calculateVisibleCriteriaNum(values);
        }, 0);

        this.updateFn = throttle(this.update, 50);

        addEventListener('resize', this.updateFn);
      }
    }

    componentDidUpdate(prevProps: Props) {
      const {
        UUID,
        index,
        listElemOptions: { onCriteriaChange },
        form,
        cachedFormValues,
      } = this.props;

      if (prevProps.UUID === UUID) {
        onCriteriaChange(UUID, form);
      }

      const criteriaDropdown = $('[data-ref="criteriaDropdown"]');

      if (this.delegateDropdownPosition && criteriaDropdown.length) {
        const {
          listElemOptions: { calculateOpenedListElemPosition },
          listElem: { criteriaType },
        } = this.props;
        const $listElem = $(findDOMNode(this));
        const $dropdown = criteriaDropdown;
        const additionalHeight = criteriaType === 'dates' ? 230 : 0;

        calculateOpenedListElemPosition({
          index,
          position: $listElem.offset(),
          height:
            $listElem.height() + $dropdown.outerHeight() + additionalHeight,
        });

        this.delegateDropdownPosition = false;
      }

      if (prevProps.UUID !== UUID) {
        this.calculateVisibleCriteriaNum(cachedFormValues);

        this.props.setInitialFormAndOriginalValues(cachedFormValues);
      }
    }

    componentWillUnmount() {
      removeEventListener('mousedown', this.shouldCloseDropdownMenu);

      if (this.calculateVisibleCriteriaNum) {
        removeEventListener('resize', this.updateFn);
      }
    }

    onAppliedContent = () => {
      this.setState({
        justApplied: true,
      });

      if (this.isEmptyCriteria(this.props.getFormValues())) {
        this.deleteListItem();
      } else {
        if (this.calculateVisibleCriteriaNum) {
          this.calculateVisibleCriteriaNum(this.props.getFormValues());
        }

        const {
          listElemOptions: { updateListElem },
          listElem,
          index,
        } = this.props;
        const newListElem = Object.assign({}, listElem);
        newListElem.values = Object.assign(
          {},
          newListElem.values,
          this.props.getFormValues(),
        );

        this.props.makeUndirty();
        this.closeDropdownMenu(false);
        updateListElem(newListElem, index);
      }
    };

    onCancel = () => {
      this.setState({
        justCancelled: true,
      });

      this.props.resetForm();
      this.closeDropdownMenu();
    };

    registerCalculateVisibleCriteriaNum = (cb: Function) => {
      this.calculateVisibleCriteriaNum = cb;
    };

    registerIsEmptyCriteria = (cb: Function) => {
      this.isEmptyCriteria = cb;
    };

    registerRenderHeaderText = (cb: Function) => {
      this.renderHeaderText = cb;
    };

    registerIsApplyButtonDisabled = (cb: Function) => {
      this.isApplyButtonDisabled = cb;
    };

    update = () => {
      this.calculateVisibleCriteriaNum();
    };

    deleteListItem = (e: any) => {
      if (e) killEvent(e);
      const {
        index,
        listElemOptions: { deleteListElem, setMaskLayerVisibilityFor },
      } = this.props;

      setMaskLayerVisibilityFor(false, index);
      this.closeDropdownMenu(false);
      deleteListElem(index);
    };

    openCriteria() {
      setTimeout(
        () => addEventListener('mousedown', this.shouldCloseDropdownMenu),
        1,
      );
      this.delegateDropdownPosition = true;
    }

    toggleCriteriaDropdown = () => {
      const { isCriteriaDropdownVisible } = this.state;

      if (!isCriteriaDropdownVisible) {
        this.setState({
          isCriteriaDropdownVisible: !isCriteriaDropdownVisible,
        });
        this.openCriteria();
      } else {
        this.closeDropdownMenu();
      }
    };

    shouldCloseDropdownMenu = ({ target }: any) => {
      const { index } = this.props;
      const dataIconAttribute = target.getAttribute('data-icon');
      const isOpenIcon = dataIconAttribute === `open-${index}-dropdown`;

      if (isOpenIcon) {
        return;
      }

      const isCloseIcon = dataIconAttribute === `close-${index}-dropdown`;
      const isOutsideClick = !$(target).closest(
        `[data-criteria-index="${index}"]`,
      ).length;

      if (isOutsideClick || isCloseIcon) {
        this.onAppliedContent();
      }
    };

    closeDropdownMenu(withCheck = true) {
      const {
        index,
        listElemOptions: { setMaskLayerVisibilityFor },
      } = this.props;
      setMaskLayerVisibilityFor(false, index);

      removeEventListener('mousedown', this.shouldCloseDropdownMenu);

      if (withCheck && this.isEmptyCriteria()) {
        this.deleteListItem();
        return;
      }

      this.setState({ isCriteriaDropdownVisible: false });
    }

    killEvent = (e: any) => {
      killEvent(e);
      if (this) {
        $('[data-ref="criteriaDropdown"]').click();
      }
    };

    renderCriteriaDropdown(renderContent: Function) {
      const {
        form: { dependency, relation },
      } = this.props;
      const {
        defaults: { dependencies, relations },
        getMessage,
        isViewOnly,
      } = this.props;
      const {
        isDependencyVisible,
        isRelationVisible,
      } = this.state.criteriasVisibilities;
      const createList = (list: any, name: string) => {
        const filterContent = ({ id }: any) =>
          !contains(this.disabledCriteriaValues[name], id);

        return Object.keys(list)
          .map(key => ({
            id: list[key],
            value: getMessage(`app.criterias.${list[key]}`).toLowerCase(),
          }))
          .filter(filterContent);
      };

      return (
        <div className={cx('criteria-dropdown')} data-ref="criteriaDropdown">
          <div className={cx('radio-buttons-wrapper')}>
            <div className={cx('vertical-divider')} />
            {isDependencyVisible && (
              <RadioGroup
                widgetId="dependency"
                className={cx('dependency-select')}
                list={createList(dependencies, 'dependency')}
                selectedValue={dependency}
                onSelectedValue={!isViewOnly && this.props.changeFormValue}
              />
            )}
            {isRelationVisible && (
              <RadioGroup
                widgetId="relation"
                className={cx('relation-select')}
                list={createList(relations, 'relation')}
                selectedValue={relation}
                onSelectedValue={!isViewOnly && this.props.changeFormValue}
              />
            )}
          </div>

          {renderContent()}

          {!isViewOnly && (
            <div className={cx('apply-button')}>
              <PrimaryButton
                extraClassName={cx('fill-button')}
                data-action="apply"
                disabled={this.isApplyButtonDisabled()}
                onClick={this.onAppliedContent}
              >
                {getMessage('app.button.apply')}
              </PrimaryButton>
            </div>
          )}
          {!isViewOnly && (
            <div className={cx('cancel-button')}>
              <SecondaryButton
                extraClassName={cx('fill-button')}
                disabled={!this.props.isDirty()}
                data-action="cancel"
                onClick={this.onCancel}
              >
                {getMessage('app.button.cancel')}
              </SecondaryButton>
            </div>
          )}
        </div>
      );
    }

    renderContent = (renderContent: Function, renderCriteriaLabel: Function) => {
      if (!Object.keys(this.props.form || {}).length) {
        return null;
      }

      const {
        index,
        parentRelation,
        listElem: { criteriaType },
        listElemOptions: { changeParentRelation },
        getMessage,
        isViewOnly,
      } = this.props;
      const isDirty = this.props.isDirty();
      const { isCriteriaDropdownVisible } = this.state;
      const isFirstElemInGroup =
        parseInt(index.split(PATH_INDEX_SEPARATOR).pop(), 10) === 0;
      const wrapperClassName =
        `${isFirstElemInGroup ? 'first' : 'sibling'}-criteria-wrapper` +
        `${isCriteriaDropdownVisible ? '-opened' : ''}`;

      const criteriaLabel = renderCriteriaLabel
        ? renderCriteriaLabel()
        : getMessage(`app.new_search_form.${snakeCase(criteriaType)}`);
      const renderGreenText = (value: string) =>
        getMessage(`app.criterias.${value}`).toLowerCase();

      return (
        <div
          className={`advanced-list-elem ${cx(wrapperClassName)}`}
          data-opened={isCriteriaDropdownVisible}
          data-criteria-index={index}
        >
          {!isViewOnly && (
            <div
              onClick={this.deleteListItem}
              data-action={`remove-${index}`}
              data-type="remove"
              image={RemoveIcon}
              className={cx('delete-icon')}
              role="button"
              tabIndex="-1"
            >
              <MinusRedIcon />
            </div>
          )}
          <div
            data-type="content"
            data-ref="criteria-header"
            className={cx('criteria-view')}
            onClick={this.toggleCriteriaDropdown}
            role="button"
            tabIndex="-1"
          >
            {isDirty && <div className={cx('dirty-criteria')} />}
            {!isFirstElemInGroup && (
              <TextLabel
                className={`parent-relation-label ${cx(
                  `parent-relation-label${
                    this.props.isViewOnly ? '-disabled' : ''
                  }`,
                )}`}
                onClick={changeParentRelation(index)}
              >
                {renderGreenText(parentRelation)}
              </TextLabel>
            )}
            <TextLabel
              data-ref="criteria-label"
              className={cx('criteria-type-label')}
            >
              {criteriaLabel}
            </TextLabel>
            <TextLabel className={cx('additional-header-text')}>
              {this.renderHeaderText()}
            </TextLabel>
            {isCriteriaDropdownVisible && (
              <ArrowUpIcon
                data-icon={`close-${index}-dropdown`}
                className={cx('arrow-icon')}
              />
            )}
            {!isCriteriaDropdownVisible && (
              <ArrowDownIcon
                data-icon={`open-${index}-dropdown`}
                className={cx('arrow-icon')}
              />
            )}
          </div>
          {isCriteriaDropdownVisible &&
            this.renderCriteriaDropdown(renderContent)}
        </div>
      );
    };

    render() {
      return React.createElement(component, {
        ...this.props,
        ...this.state,
        updateBaseState: (values) => {
          this.setState(values);
        },
        renderContent: this.renderContent,
        registerCalculateVisibleCriteriaNum: this
          .registerCalculateVisibleCriteriaNum,
        registerIsEmptyCriteria: this.registerIsEmptyCriteria,
        registerRenderHeaderText: this.registerRenderHeaderText,
        registerIsApplyButtonDisabled: this.registerIsApplyButtonDisabled,
        renderGreenText: (text: string) => (
          <data className={styles['green-header-text']}>{text}</data>
        ),
        killEvent: this.killEvent,
      });
    }
  }

  return CriteriaViewBaseComponent;
}
