import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import TableView from 'view-components/components/Templates/Audits/AuditsTableView';
import HeadlineLabel from 'view-components/components/HeadlineLabel';

import { actions } from '../../../global/components/TableViewActions';

import { jsUi } from '../../../global/lib/jsUi';

import configs from '../../../config/configs';

import {
  sortAudits,
  filterAudits,
} from '../../../global/state_managers/AuditsTableUIStateManager';

const newReportUrl = jsUi.settingsReportsAuditsNew.path();

type Props = {
  auditsTableUI: {
    filter: any,
    visibleColumns: string[],
    sort: {
      key: string,
      order: string,
    }
  },
  audits: {
    jobs: any[]
  },
  getMessage: Function
}

export default function Audits(props: Props) {
  const {
    auditsTableUI: { filter, visibleColumns, sort },
    audits: { jobs },
    getMessage,
  } = props;

  const filteredSortedJobs = filterAudits(sortAudits({ jobs, ...sort }), filter);
  const reportsCount = filteredSortedJobs.length;

  const text = getMessage('app.reports.audits.title', { reportsCount });

  return (
    <div>
      <header>
        <HeadlineLabel
          text={text}
          data-return-count={reportsCount}
        />
      </header>
      <TableView
        {...props}
        reports={filteredSortedJobs}
        sortKey={sort.key}
        sortOrder={sort.order}
        searchString={filter}
        visibleColumns={visibleColumns}
        visibleTableActions={[actions.SEARCH, actions.NEW]}
        jsUi={jsUi}
        configs={configs}
        newReportUrl={newReportUrl}
        browserHistory={browserHistory}
        prefix="app"
      />
    </div>
  );
}

Audits.propTypes = {
  auditsTableUI: PropTypes.object.isRequired,
  audits: PropTypes.object.isRequired,
  getMessage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
