import React from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton, SecondaryButton } from 'v-c/Buttons';

import { ModalButtons, ModalButtonSpacer } from '../../Modals';

export interface Props {
  hasErrors: boolean
  BASE_KEY: string
  getMessage: Function
  okAction: Function
  hideModal: Function
}

export default function Buttons({
  hasErrors,
  BASE_KEY,
  getMessage,
  okAction,
  hideModal,
}: Props) {
  if (hasErrors) {
    return (
      <ModalButtons>
        <PrimaryButton data-action="ok" onClick={hideModal}>
          {getMessage(`${BASE_KEY}.buttons.ok`)}
        </PrimaryButton>
      </ModalButtons>
    );
  }

  return (
    <ModalButtons>
      <PrimaryButton data-action="ok" onClick={okAction}>
        {getMessage(`${BASE_KEY}.buttons.ok`)}
      </PrimaryButton>
      <ModalButtonSpacer />
      <SecondaryButton data-action="cancel" onClick={hideModal}>
        {getMessage(`${BASE_KEY}.buttons.cancel`)}
      </SecondaryButton>
    </ModalButtons>
  );
}

Buttons.propTypes = {
  hasErrors: PropTypes.bool.isRequired,
  BASE_KEY: PropTypes.string.isRequired,
  getMessage: PropTypes.func.isRequired,
  okAction: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

Buttons.displayName = 'Modals/BulkToggleUsers/Buttons';
