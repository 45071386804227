import { SortCell } from 'view-components/components/TableView';

import reduxStore, { PromiseObject, AppDispatch, Action } from '../reduxStore';
import jsApi from '../lib/jsApi';
import { jsUi } from '../lib/jsUi';

import { apiReqPromise } from '../lib/apiRequest';
import Pairs from '../../features/Settings/ADSync/AttributePairs';
import PAGES from '../constants/Pages';
import simpleSearch from './helpers/simpleSearch';

import {
  resetFlash,
  showSuccessFlash,
  showFailureFlash,
} from './FlashMessagesStateManager';

import { setWizardPage } from './ADWizardStateManager';

import {
  update as updateAccount,
} from './AccountSettingsStateManager';

import { getLoggedInAs } from './AuthenticationStateManager';

interface ADSyncState {
  isLoading: Boolean,
  isDeleting: Boolean,
  setupDone: boolean,
  syncName: string | null,
  status: {
    syncStatus: string, // ['paused','success','error','in_progress']
    lastSyncDate: Date | null, // date or null
  },
  attributeMappings: {
    number: number,
    needsAttention: boolean,
  },
  appCredentials: {
    needsAttention: boolean,
  },
  credentialData: {
    credentials: {
      clientId: any,
      clientSecret: any,
      tenantId: any,
    },
  },
  ottoAttributes: string[],
  remoteAttributes: string[],
  pairs: Pairs,
  syncLogs: {
    logEntries: string[],
    tableHelper: {
      columns: Object,
      allColumns: string[],
      activeColumns: string[],
      disabledColumns: string[],
    },
    sortOptions: {
      sortKey: string,
      sortOrder: string,
    },
    searchString: string,
    searchHelper: {
      value: string,
    },
  },
  syncConflicts: {
    number: number,
    needsAttention: boolean,
    conflictEntries: any[],
    tableHelper: {
      columns: Object,
      allColumns: string[],
      activeColumns: string[],
      disabledColumns: string[],
    },
    sortOptions: {
      sortKey: string,
      sortOrder: string,
    },
    searchString: string,
    searchHelper: {
      value: string,
    },
  },
}

const userData = getLoggedInAs;
const VALID_FLASH_VIEWS = [PAGES.AD_SYNC, PAGES.AD_Wizard];

const syncLogColumns = {
  DATE: 'date',
  USER: 'user',
  EVENT: 'event',
  DETAILS: 'details',
};

const {
  DATE,
  USER,
  EVENT,
  DETAILS,
} = syncLogColumns;

const syncConflictColumns = {
  CONFLICT_DATE: 'date',
  CONFLICT_USER: 'user',
  CONFLICT_OBJECT_ID: 'objectId',
  CONFLICT_TYPE: 'type',
  CONFLICT_DESCRIPTION: 'description',
};

const {
  CONFLICT_DATE,
  CONFLICT_USER,
  CONFLICT_OBJECT_ID,
  CONFLICT_TYPE,
  CONFLICT_DESCRIPTION,
} = syncConflictColumns;

const allSyncLogColumns = [
  DATE,
  USER,
  EVENT,
  DETAILS,
];
const disabledSyncLogColumns: string[] = [];

const allSyncConflictColumns = [
  CONFLICT_DATE,
  CONFLICT_USER,
  CONFLICT_OBJECT_ID,
  CONFLICT_TYPE,
  CONFLICT_DESCRIPTION,
];

const disabledSyncConflictColumns: string[] = [];

export const INITIAL_STATE: ADSyncState = {
  isLoading: false,
  setupDone: false,
  syncName: null, // string or null
  status: {
    syncStatus: 'paused', // ['paused','success','error','in_progress']
    lastSyncDate: null, // date or null
  },
  attributeMappings: {
    number: 0,
    needsAttention: false,
  },
  appCredentials: {
    needsAttention: true,
  },
  isDeleting: false,
  credentialData: {
    credentials: {
      clientId: null,
      clientSecret: null,
      tenantId: null,
    },
  },
  ottoAttributes: [],
  remoteAttributes: [],
  pairs: new Pairs(),
  syncLogs: {
    logEntries: [],
    tableHelper: {
      columns: syncLogColumns,
      allColumns: allSyncLogColumns,
      activeColumns: allSyncLogColumns,
      disabledColumns: disabledSyncLogColumns,
    },
    sortOptions: {
      sortKey: syncLogColumns.DATE,
      sortOrder: SortCell.sortKeys.DESC,
    },
    searchString: '',
    searchHelper: {
      value: '',
    },
  },
  syncConflicts: {
    number: 0,
    needsAttention: false,
    conflictEntries: [],
    tableHelper: {
      columns: syncConflictColumns,
      allColumns: allSyncConflictColumns,
      activeColumns: allSyncConflictColumns,
      disabledColumns: disabledSyncConflictColumns,
    },
    sortOptions: {
      sortKey: syncConflictColumns.CONFLICT_DATE,
      sortOrder: SortCell.sortKeys.DESC,
    },
    searchString: '',
    searchHelper: {
      value: '',
    },
  },
};

export const GOT_STATUS: string = 'AzureADStatus/gotStatus';
export const GET_STATUS_FAILED: string = 'AzureADStatus/getStatusFailed';
export const UPDATED_STATUS: string = 'AzureADStatus/updatedStatus';
export const RESET: string = 'ADSync/reset';
export const GETTING_CREDENTIALS: string = 'ADSync/gettingCredentials';
export const GOT_CREDENTIALS: string = 'ADSync/gotCredentials';
export const GET_CREDENTIALS_FAILED: string = 'ADSync/getCredentialsFailed';
export const CREATING_SYNC: string = 'ADSync/creatingSync';
export const CREATED_SYNC: string = 'ADSync/createdSync';
export const CREATE_SYNC_FAILED: string = 'ADSync/createSyncFailed';
export const FORCING_SYNC: string = 'ADSync/forcingSync';
export const FORCED_SYNC: string = 'ADSync/forcedSync';
export const FORCE_SYNC_FAILED: string = 'ADSync/forceSyncFailed';
export const PAUSING_SYNC: string = 'ADSync/pausingSync';
export const PAUSED_SYNC: string = 'ADSync/pausedSync';
export const PAUSE_SYNC_FAILED: string = 'ADSync/pauseSyncFailed';
export const RESUMING_SYNC: string = 'ADSync/resumingSync';
export const RESUMED_SYNC: string = 'ADSync/resumedSync';
export const RESUME_SYNC_FAILED: string = 'ADSync/resumeSyncFailed';
export const UPDATING_CREDENTIALS: string = 'ADSync/updatingCredentials';
export const UPDATED_CREDENTIALS: string = 'ADSync/updatedCredentials';
export const UPDATE_CREDENTIALS_FAILED: string = 'ADSync/updateCredentialsFailed';
export const CHANGED_CREDENTIAL_VALUE: string = 'ADSync/changedCredentialValue';
export const DELETING_SYNC: string = 'ADSync/deletingSync';
export const DELETED_SYNC: string = 'ADSync/deletedSync';
export const DELETE_SYNC_FAILED: string = 'ADSync/deleteSyncFailed';
export const GETTING_REMOTE_ATTRIBUTES: string = 'ADSync/gettingRemoteAttributes';
export const GOT_REMOTE_ATTRIBUTES: string = 'ADSync/gotRemoteAttributes';
export const GET_REMOTE_ATTRIBUTES_FAILED: string = 'ADSync/getRemoteAttributesFailed';
export const GETTING_LOCAL_ATTRIBUTES: string = 'ADSync/gettingLocalAttributes';
export const GOT_LOCAL_ATTRIBUTES: string = 'ADSync/gotLocalAttributes';
export const GET_LOCAL_ATTRIBUTES_FAILED: string = 'ADSync/getLocalAttributesFailed';
export const GETTING_ATTRIBUTE_MAPPINGS: string = 'ADSync/gettingAttributeMappings';
export const GOT_ATTRIBUTE_MAPPINGS: string = 'ADSync/gotAttributeMappings';
export const GET_ATTRIBUTE_MAPPINGS_FAILED: string = 'ADSync/getAttributeMappingsFailed';
export const UPDATING_ATTRIBUTE_MAPPINGS: string = 'ADSync/updatingAttributeMappings';
export const UPDATED_ATTRIBUTE_MAPPINGS: string = 'ADSync/updatedAttributeMappings';
export const UPDATE_ATTRIBUTE_MAPPINGS_FAILED: string = 'ADSync/updateAttributeMappingsFailed';
export const GETTING_SYNC_LOGS: string = 'ADSync/gettingSyncLogs';
export const GOT_SYNC_LOGS: string = 'ADSync/gotSyncLogs';
export const GET_SYNC_LOGS_FAILED: string = 'ADSync/getSyncLogsFailed';
export const GETTING_SYNC_CONFLICTS: string = 'ADSync/gettingSyncConflicts';
export const GOT_SYNC_CONFLICTS: string = 'ADSync/gotSyncConflicts';
export const GET_SYNC_CONFLICTS_FAILED: string = 'ADSync/getSyncConflictsFailed';
export const SEARCHED_LOGS: string = 'ADSync/searchedLogs';
export const SEARCHED_CONFLICTS: string = 'ADSync/searchedConflicts';
export const SET_PAIRS: string = 'ADSync/setPairs';
export const GET_STATUS_STARTED: string = 'ADSync/getStatusStarted';

export function reset() {
  return { type: RESET };
}

export function gettingCredentials() {
  return { type: GETTING_CREDENTIALS };
}

export function gotCredentials(data: Object) {
  return {
    type: GOT_CREDENTIALS,
    payload: data,
  };
}

export function getCredentialsFailed() {
  return { type: GET_CREDENTIALS_FAILED };
}

export function getCredentials(sync = {}) {
  return function* doGetCredentials() {
    try {
      yield gettingCredentials();

      const result: PromiseObject = yield apiReqPromise(jsApi.directorySyncCredentials, {
        params: {
          data: {
            sync,
            user: userData(),
          },
        },
      });

      const { data } = result;

      yield gotCredentials(data);
    } catch (e) {
      yield getCredentialsFailed();
    }
  };
}

export function creatingSync() {
  return { type: CREATING_SYNC };
}

export function createdSync() {
  return { type: CREATED_SYNC };
}

export function createSyncFailed() {
  return { type: CREATE_SYNC_FAILED };
}

export function createSync(sync = {}) {
  return function* doCreateSync() {
    try {
      reduxStore.dispatch(resetFlash({ from: CREATING_SYNC }));
      yield creatingSync();

      yield apiReqPromise(jsApi.directorySyncCreate, {
        params: {
          data: {
            sync,
            user: userData(),
          },
        },
      });

      yield createdSync();
      reduxStore.dispatch(setWizardPage(2));
    } catch (e) {
      reduxStore.dispatch(showFailureFlash('ad_sync.create', VALID_FLASH_VIEWS));
      yield createSyncFailed();
      reduxStore.dispatch(setWizardPage(1));
    }
  };
}

export function forcingSync() {
  return { type: FORCING_SYNC };
}

export function forcedSync() {
  return { type: FORCED_SYNC };
}

export function forceSyncFailed() {
  return { type: FORCE_SYNC_FAILED };
}

export function forceSync(sync = {}) {
  return function* doForceSync() {
    try {
      reduxStore.dispatch(resetFlash({ from: FORCING_SYNC }));
      yield forcingSync();

      yield apiReqPromise(jsApi.directorySyncSyncNow, {
        params: {
          data: {
            sync,
            user: userData(),
          },
        },
      });

      yield forcedSync();
      yield getStatus();
      reduxStore.dispatch(updateAccount({ selfRegistrationDomains: [] }));
    } catch (e) {
      yield forceSyncFailed();
    }
  };
}

export function pausingSync() {
  return { type: PAUSING_SYNC };
}

export function pausedSync() {
  return { type: PAUSED_SYNC };
}

export function pauseSyncFailed() {
  return { type: PAUSE_SYNC_FAILED };
}

export function pauseSync(sync = {}) {
  return function* doPauseSync() {
    try {
      yield pausingSync();

      yield apiReqPromise(jsApi.directorySyncUpdate, {
        params: {
          data: {
            sync,
            user: userData(),
            isPaused: true,
          },
        },
      });

      yield pausedSync();
    } catch (e) {
      yield pauseSyncFailed();
    }
  };
}

export function resumingSync() {
  return { type: RESUMING_SYNC };
}

export function resumedSync() {
  return { type: RESUMED_SYNC };
}

export function resumeSyncFailed() {
  return { type: RESUME_SYNC_FAILED };
}

export function resumeSync(sync = {}) {
  return function* doResumeSync() {
    try {
      yield resumingSync();

      yield apiReqPromise(jsApi.directorySyncUpdate, {
        params: {
          data: {
            sync,
            user: userData(),
            isPaused: false,
          },
        },
      });

      yield resumedSync();
    } catch (e) {
      yield resumeSyncFailed();
    }
  };
}

export function updatingCredentials() {
  return { type: UPDATING_CREDENTIALS };
}

export function updatedCredentials() {
  return { type: UPDATED_CREDENTIALS };
}

export function updateCredentialsFailed() {
  return { type: UPDATE_CREDENTIALS_FAILED };
}

export function updateCredentials(sync = {}) {
  return function* doUpdateCredentials() {
    try {
      reduxStore.dispatch(resetFlash({ from: UPDATING_CREDENTIALS }));
      yield updatingCredentials();

      yield apiReqPromise(jsApi.directorySyncUpdate, {
        params: {
          data: {
            sync,
            user: userData(),
          },
        },
      });

      reduxStore.dispatch(
        showSuccessFlash('ad_sync.update', [
          PAGES.APP_CREDENTIALS,
          PAGES.AD_Wizard,
        ]),
      );

      yield updatedCredentials();
      reduxStore.dispatch(setWizardPage(2));
    } catch (e) {
      reduxStore.dispatch(
        showFailureFlash('ad_sync.update', [
          PAGES.APP_CREDENTIALS,
          PAGES.AD_Wizard,
        ]),
      );

      yield updateCredentialsFailed();
      reduxStore.dispatch(setWizardPage(1));
    }
  };
}

export function changedCredentialValue(property: string, value: string) {
  return {
    type: CHANGED_CREDENTIAL_VALUE,
    payload: {
      property,
      value,
    },
  };
}

export function changeCredentialValue(property: string, value: string) {
  return (dispatch: AppDispatch) => {
    dispatch(changedCredentialValue(property, value));
  };
}

export function deletingSync() {
  return { type: DELETING_SYNC };
}

export function deletedSync() {
  return { type: DELETED_SYNC };
}

export function deleteSyncFailed() {
  return { type: DELETE_SYNC_FAILED };
}

export function deleteSync(sync = {}) {
  return function* doDeleteSync() {
    try {
      yield deletingSync();
      reduxStore.dispatch(setWizardPage(0));

      yield apiReqPromise(jsApi.directorySyncDestroy, {
        params: {
          data: {
            sync,
            user: userData(),
          },
        },
      });

      yield getStatus();
      yield deletedSync();

      jsUi.settingsAdSyncIndex.goTo();
    } catch (e) {
      yield getStatus();
      yield deleteSyncFailed();
    }
  };
}

export function gettingRemoteAttributes() {
  return { type: GETTING_REMOTE_ATTRIBUTES };
}

export function gotRemoteAttributes(data: Object) {
  return {
    type: GOT_REMOTE_ATTRIBUTES,
    payload: data,
  };
}

export function getRemoteAttributesFailed() {
  return { type: GET_REMOTE_ATTRIBUTES_FAILED };
}

export function getRemoteAttributes(sync = {}) {
  return function* doGetRemoteAttributes() {
    try {
      yield gettingRemoteAttributes();

      const result: PromiseObject = yield apiReqPromise(jsApi.directorySyncRemoteAttributes, {
        params: {
          data: {
            sync,
            user: userData(),
          },
        },
      });

      const { data } = result;

      yield gotRemoteAttributes(data);
    } catch (e) {
      yield getRemoteAttributesFailed();
    }
  };
}

export function gettingLocalAttributes() {
  return { type: GETTING_LOCAL_ATTRIBUTES };
}

export function gotLocalAttributes(data: Object) {
  return {
    type: GOT_LOCAL_ATTRIBUTES,
    payload: data,
  };
}

export function getLocalAttributesFailed() {
  return { type: GET_LOCAL_ATTRIBUTES_FAILED };
}

export function getLocalAttributes(sync = {}) {
  return function* doGetLocalAttributes() {
    try {
      yield gettingLocalAttributes();

      const result: PromiseObject = yield apiReqPromise(jsApi.directorySyncLocalAttributes, {
        params: {
          data: {
            sync,
            user: userData(),
          },
        },
      });

      const { data } = result;

      yield gotLocalAttributes(data);
    } catch (e) {
      yield getLocalAttributesFailed();
    }
  };
}

export function gettingAttributeMappings() {
  return { type: GETTING_ATTRIBUTE_MAPPINGS };
}

export function gotAttributeMappings(data: Object) {
  return {
    type: GOT_ATTRIBUTE_MAPPINGS,
    payload: data,
  };
}

export function getAttributeMappingsFailed() {
  return { type: GET_ATTRIBUTE_MAPPINGS_FAILED };
}

export function getAttributeMappings(sync = {}) {
  return function* doGetAttributeMappings() {
    try {
      yield gettingAttributeMappings();

      const result: PromiseObject = yield apiReqPromise(jsApi.directorySyncAttributeMappings, {
        params: {
          data: {
            sync,
            user: userData(),
          },
        },
      });

      const { data } = result;

      yield gotAttributeMappings(data);
    } catch (e) {
      yield getAttributeMappingsFailed();
    }
  };
}

export function updatingAttributeMappings() {
  return { type: UPDATING_ATTRIBUTE_MAPPINGS };
}

export function updatedAttributeMappings() {
  return { type: UPDATED_ATTRIBUTE_MAPPINGS };
}

export function updateAttributeMappingsFailed() {
  return { type: UPDATE_ATTRIBUTE_MAPPINGS_FAILED };
}

export function updateAttributeMappings(sync = {}) {
  return function* doUpdateAttributeMappings() {
    try {
      reduxStore.dispatch(resetFlash({ from: UPDATING_ATTRIBUTE_MAPPINGS }));
      yield updatingAttributeMappings();

      yield apiReqPromise(jsApi.directorySyncUpdateAttributeMappings, {
        params: {
          data: {
            sync,
            user: userData(),
          },
        },
      });

      reduxStore.dispatch(
        showSuccessFlash(
          'ad_sync.attribute_mappings.update',
          VALID_FLASH_VIEWS,
        ),
      );

      yield updatedAttributeMappings();
      reduxStore.dispatch(setWizardPage(3));
    } catch (e) {
      reduxStore.dispatch(
        showFailureFlash(
          'ad_sync.attribute_mappings.update',
          VALID_FLASH_VIEWS,
        ),
      );

      yield updateAttributeMappingsFailed();
      reduxStore.dispatch(setWizardPage(2));
    }
  };
}

export function gettingSyncLogs() {
  return { type: GETTING_SYNC_LOGS };
}

export function gotSyncLogs(data: Object) {
  return {
    type: GOT_SYNC_LOGS,
    payload: data,
  };
}

export function getSyncLogsFailed() {
  return { type: GET_SYNC_LOGS_FAILED };
}

export function getSyncLogs(sync = {}) {
  return function* doGetSyncLogs() {
    try {
      yield gettingSyncLogs();

      const result: PromiseObject = yield apiReqPromise(jsApi.directorySyncLogs, {
        params: {
          data: {
            sync,
            user: userData(),
          },
        },
      });

      const { data } = result;

      yield gotSyncLogs(data);
    } catch (e) {
      yield getSyncLogsFailed();
    }
  };
}

export function gettingSyncConflicts() {
  return { type: GETTING_SYNC_CONFLICTS };
}

export function gotSyncConflicts(data: Object) {
  return {
    type: GOT_SYNC_CONFLICTS,
    payload: data,
  };
}

export function getSyncConflictsFailed() {
  return { type: GET_SYNC_CONFLICTS_FAILED };
}

export function getSyncConflicts(sync = {}) {
  return function* doGetSyncConflicts() {
    try {
      yield gettingSyncConflicts();

      const result: PromiseObject = yield apiReqPromise(jsApi.directorySyncConflicts, {
        params: {
          data: {
            sync,
            user: userData(),
          },
        },
      });

      const { data } = result;

      yield gotSyncConflicts(data);
    } catch (e) {
      yield getSyncConflictsFailed();
    }
  };
}

export function searchedLogs(searchString: string, filteredLogEntries: string[]) {
  return {
    type: SEARCHED_LOGS,
    payload: {
      searchString,
      filteredLogEntries,
    },
  };
}

export function searchLogs(searchString: string, getState: Function = reduxStore.getState) {
  return (dispatch: AppDispatch) => {
    const filteredLogEntries = simpleSearch(
      getState().adSync.syncLogs.originalLogEntries,
      searchString,
      ['date', 'user', 'event', 'details'],
    );

    dispatch(searchedLogs(searchString, filteredLogEntries));
  };
}

export function searchedConflicts(searchString: string, filteredConflictEntries: string[]) {
  return {
    type: SEARCHED_CONFLICTS,
    payload: {
      searchString,
      filteredConflictEntries,
    },
  };
}

export function searchConflicts(searchString: string, getState: Function = reduxStore.getState) {
  return (dispatch: AppDispatch) => {
    const filteredConflictEntries = simpleSearch(
      getState().adSync.syncConflicts.originalConflictEntries,
      searchString,
      ['date', 'user', 'objectId', 'type', 'description'],
    );

    dispatch(searchedConflicts(searchString, filteredConflictEntries));
  };
}

export function setPairs(pairs: Pairs) {
  return {
    type: SET_PAIRS,
    payload: pairs,
  };
}

export function gotStatus(status: any) {
  return {
    type: GOT_STATUS,
    payload: status,
  };
}

export function getStatusFailed(status: any) {
  return {
    type: GET_STATUS_FAILED,
    payload: status,
  };
}

function doGetSyncStatus(sync: any) {
  return function* doDoGetSyncStatus() {
    try {
      const results: PromiseObject = yield apiReqPromise(jsApi.directorySyncStatus, {
        params: {
          data: {
            sync,
            user: userData(),
          },
        },
      });
      return results;
    } catch (e) {
      return e.response; // still need to show loading when deleting and loading when open ad sync
    }
  };
}

export function getStatus(sync = {}) {
  return function* doGetStatus() {
    try {
      yield getStatusStarted();
      let results: PromiseObject = yield doGetSyncStatus(sync);
      // If the setup stuck in a middle state reset all credentials data
      if (!isValidSetupStatus(results.data)) {
        yield deleteSync(sync);
        results = yield doGetSyncStatus(sync);
      }
      const res: any = yield gotStatus(results.data);
      return res;
    } catch (e) {
      yield getStatusFailed(e.data);
    }
  };
}

export function isValidSetupStatus({ initialData }: any) {
  const { areCredentialsSet } = reduxStore.getState().adSync;

  if (!initialData.setupDone && initialData.syncName && !areCredentialsSet) {
    return false;
  }

  return true;
}

export function getStatusStarted() {
  return {
    type: GET_STATUS_STARTED,
  }
}

export function updateStatus(newStatus: any) {
  return {
    type: UPDATED_STATUS,
    payload: newStatus,
  };
}

export default function ADSyncStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case RESET:
      return { ...INITIAL_STATE };
    case GETTING_CREDENTIALS:
      return {
        ...state,
        credentialsLoading: true,
      };
    case GOT_CREDENTIALS:
      return {
        ...state,
        credentialsLoading: false,
        credentialData: payload,
      };
    case GET_CREDENTIALS_FAILED:
      return {
        ...state,
        credentialsLoading: false,
      };
    case CREATING_SYNC:
      return {
        ...state,
        createError: false,
        areCredentialsSet: false,
        isLoading: true,
      };
    case CREATED_SYNC:
      return {
        ...state,
        createError: false,
        areCredentialsSet: true,
        isLoading: false,
      };
    case CREATE_SYNC_FAILED:
      return {
        ...state,
        createError: true,
        isLoading: false,
      };
    case UPDATING_CREDENTIALS:
      return {
        ...state,
        updateError: false,
        areCredentialsSet: false,
        isLoading: true,
      };
    case UPDATED_CREDENTIALS:
      return {
        ...state,
        updateError: false,
        areCredentialsSet: true,
        isLoading: false,
      };
    case UPDATE_CREDENTIALS_FAILED:
      return {
        ...state,
        updateError: true,
        areCredentialsSet: true,
        isLoading: false,
      };
    case CHANGED_CREDENTIAL_VALUE:
      return {
        ...state,
        credentialData: {
          ...state.credentialData,
          credentials: {
            ...state.credentialData.credentials,
            [payload.property]: payload.value,
          },
        },
      };
    case DELETING_SYNC:
      return {
        ...state,
        isDeleting: true,
        isLoading: false,
      };
    case DELETED_SYNC:
    case DELETE_SYNC_FAILED:
      return {
        ...INITIAL_STATE,
      };
    case GETTING_REMOTE_ATTRIBUTES:
      return {
        ...state,
        remoteAttributesLoading: true,
      };
    case GOT_REMOTE_ATTRIBUTES:
      return {
        ...state,
        remoteAttributesLoading: false,
        remoteAttributes: payload,
      };
    case GET_REMOTE_ATTRIBUTES_FAILED:
      return {
        ...state,
        remoteAttributesLoading: false,
      };
    case GETTING_LOCAL_ATTRIBUTES:
      return {
        ...state,
        localAttributesLoading: true,
      };
    case GOT_LOCAL_ATTRIBUTES:
      return {
        ...state,
        localAttributesLoading: false,
        ottoAttributes: payload,
      };
    case GET_LOCAL_ATTRIBUTES_FAILED:
      return {
        ...state,
        localAttributesLoading: false,
      };
    case GETTING_ATTRIBUTE_MAPPINGS:
      return {
        ...state,
        attributeMappingsLoading: true,
      };
    case GOT_ATTRIBUTE_MAPPINGS:
      return {
        ...state,
        attributeMappingsLoading: false,
        pairs: new Pairs(payload),
      };
    case GET_ATTRIBUTE_MAPPINGS_FAILED:
      return {
        ...state,
        attributeMappingsLoading: false,
      };
    case UPDATING_ATTRIBUTE_MAPPINGS:
      return {
        ...state,
        updateError: false,
        areMappingsSet: false,
        isLoading: true,
      };
    case UPDATED_ATTRIBUTE_MAPPINGS:
      return {
        ...state,
        updateError: false,
        areMappingsSet: true,
        isLoading: false,
      };
    case UPDATE_ATTRIBUTE_MAPPINGS_FAILED:
      return {
        ...state,
        updateError: true,
        areMappingsSet: true,
        isLoading: false,
      };
    case GETTING_SYNC_LOGS:
      return {
        ...state,
        syncLogsAreLoading: true,
      };
    case GOT_SYNC_LOGS:
      return {
        ...state,
        syncLogsAreLoading: false,
        syncLogs: {
          ...state.syncLogs,
          logEntries: payload,
          originalLogEntries: payload,
        },
      };
    case GET_SYNC_LOGS_FAILED:
      return {
        ...state,
        syncLogsAreLoading: false,
      };
    case GETTING_SYNC_CONFLICTS:
      return {
        ...state,
        syncConflictsAreLoading: true,
      };
    case GOT_SYNC_CONFLICTS:
      return {
        ...state,
        syncConflictsAreLoading: false,
        syncConflicts: {
          ...state.syncConflicts,
          conflictEntries: payload,
          originalConflictEntries: payload,
        },
      };
    case GET_SYNC_CONFLICTS_FAILED:
      return {
        ...state,
        syncConflictsAreLoading: false,
      };
    case SEARCHED_LOGS:
      return {
        ...state,
        syncLogs: {
          ...state.syncLogs,
          searchString: payload.searchString,
          logEntries: payload.filteredLogEntries,
        },
      };
    case SEARCHED_CONFLICTS:
      return {
        ...state,
        syncConflicts: {
          ...state.syncConflicts,
          searchString: payload.searchString,
          conflictEntries: payload.filteredConflictEntries,
        },
      };
    case SET_PAIRS:
      return {
        ...state,
        pairs: payload,
      };
    case GET_STATUS_STARTED:
      return {
        ...state,
        isLoading: true,
      }
    case GOT_STATUS:
      return {
        ...state,
        ...payload.initialData,
        syncConflicts: {
          ...state.syncConflicts,
          ...payload.initialData.syncConflicts,
        },
        isLoading: false
      };
    case GET_STATUS_FAILED:
      return payload.initialData
        ? { ...payload.initialData, isLoading: false }
        : { ...INITIAL_STATE };
    case UPDATED_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          syncStatus: payload,
        },
      };
    default:
      return state;
  }
}
