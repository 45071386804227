/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { isString, kebabCase } from 'lodash';
import withMessages, { FormattedHTMLMessage } from 'component-utilities/intl';
import { PrimaryButton } from 'v-c/Buttons';
import { closeModal } from '../../state_managers/ModalsStateManager';

import {
  ModalContainer,
  ModalHeader,
  ModalContentCenter,
  ModalButtons,
  ModalText,
} from '../Modals';
import { ModalProps } from 'global/types';

interface Props extends ModalProps {
  getMessage: Function
  displayName: string
  content: any
  header: string
}

@withMessages
@connect(null, { closeModal })
export default class WarningModal extends Component<Props> {
  static propTypes = {
    getMessage: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    displayName: PropTypes.string.isRequired,
    content: PropTypes.any.isRequired,
    header: PropTypes.string.isRequired,
  };

  static displayName = 'Modals/Warning';

  renderContent = () => {
    const { content } = this.props;

    if (isString(content)) {
      return this.props.getMessage(content);
    }
    return <FormattedHTMLMessage {...content} />;
  };

  render() {
    const { displayName, header } = this.props;

    return (
      <ModalContainer dataTags={{ 'data-modal': kebabCase(displayName) }}>
        <ModalHeader
          type="warning"
          onCloseClick={() =>
            this.props.closeModal({ from: `${displayName}/close` })
          }
        >
          {this.props.getMessage(header)}
        </ModalHeader>
        <ModalContentCenter>
          <ModalText>{this.renderContent()}</ModalText>
        </ModalContentCenter>
        <ModalButtons>
          <PrimaryButton
            data-action="ok"
            onClick={() =>
              this.props.closeModal({ from: `${displayName}/submit` })
            }
          >
            {this.props.getMessage('app.general.ok')}
          </PrimaryButton>
        </ModalButtons>
      </ModalContainer>
    );
  }
}
