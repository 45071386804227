import { Action } from '../reduxStore';

interface ADWizardState {
  wizardPage: number
}

export const INITIAL_STATE: ADWizardState = {
  wizardPage: 0,
};

export const RESET: string = 'ADWizard/Reset';
export const SET_WIZARD_PAGE: string = 'ADWizard/setWizardPage';

export function reset() {
  return { type: RESET };
}

export function setWizardPage(page: number) {
  return {
    type: SET_WIZARD_PAGE,
    payload: page,
  };
}

export default function ADWizardStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case RESET:
      return { ...INITIAL_STATE };
    case SET_WIZARD_PAGE:
      return {
        ...state,
        wizardPage: payload,
      };
    default:
      return state;
  }
}