import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import withMessages from 'component-utilities/intl';
import { formatTZDate } from 'component-utilities/dates/dates';

import { TextLabel } from 'view-components/components/Widgets/Components';
import {
  TableRow,
  UserCell,
  TextCell,
} from 'v-c/TableView';

import style from '../SyncLog.scss';
import { jsUi } from '../../../../global/lib/jsUi';
import getUserFullName from '../../../../global/lib/getUserFullName';
import { TableViewContent } from '../../../../global/components/TableView/TableViewContentFactory';

import { searchLogs } from '../../../../global/state_managers/ADSyncStateManager';

const mapActionsToProps = { searchLogs };

@withMessages
@connect(null, mapActionsToProps)
export default class TableView extends TableViewContent {
  static propTypes = {
    searchLogs: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tableType: 'ad_sync_logs',
  };

  static displayName = 'SyncLog/TableView';

  onSearchChange = (newValue: any) => {
    this.props.searchLogs(newValue);
    this.setState({ search: newValue });
  }

  get tableHelper() {
    return this.props.tableHelper;
  }

  get list() {
    const { logEntries } = this.props;
    return logEntries;
  }

  get searchString() {
    const { searchString } = this.props;

    return searchString;
  }

  getDetailsMessage = (details: any) => {
    let message = 'No details available';
    if (details && details.message) {
      message = details.message;
    }
    return message;
  };

  getCellClassName = (event: string) => {
    switch (event) {
      case 'push_to_otto_success':
      case 'get_remote_data_success':
        return style['sync-log-okay'];
      case 'push_to_otto_failure':
      case 'get_remote_data_failure':
        return style['sync-log-error'];
      case 'push_to_otto_conflicted':
        return style['sync-log-warning'];
      default:
        return '';
    }
  }

  subStringBeforePattern = (str: string, pattern: string) => {
    const startIndex = str.indexOf(pattern);
    let retVal = str;
    if (startIndex !== -1) {
      retVal = str.slice(0, startIndex);
    }
    return retVal;
  }

  subStringAfterPattern = (str: string, pattern: string) => {
    const startIndex = str.indexOf(pattern);
    let retVal = '';
    if (startIndex !== -1) {
      retVal = str.slice(startIndex + pattern.length);
    }
    return retVal;
  }

  // TODO: there has to be a nicer way to handle this.
  linkifyMessage = (message: string | any) => {
    const attributeMappingPattern = 'Attribute mapping';
    const credentialsPattern = 'App credentials';
    const conflictsPattern = '_conflicted_';
    let retVal = message;
    [
      {
        pattern: attributeMappingPattern,
        path: jsUi.settingsAdSyncAttributeMappings.path(),
      },
      {
        pattern: credentialsPattern,
        path: jsUi.settingsAdSyncAppCredentials.path(),
      },
      {
        pattern: conflictsPattern,
        path: jsUi.settingsAdSyncSyncConflicts.path(),
      },
    ].forEach(({ pattern, path }) => {
      if (message.includes(pattern)) {
        retVal = (
          <div>
            <TextLabel className={style['sync-log-inline']}>
              {this.subStringBeforePattern(message, pattern)}
            </TextLabel>
            <Link to={path}>
              <TextLabel
                className={`${style['sync-log-inline']} table-link-text`}
              >
                {this.highlightText(pattern.replace(/_/g, ''))}
              </TextLabel>
            </Link>
            <TextLabel className={style['sync-log-inline']}>
              {this.subStringAfterPattern(message, pattern)}
            </TextLabel>
          </div>
        );
      }
    });
    return retVal;
  }

  get headerContent() {
    const visibleColumns: any[] = [];

    this.allColumns.filter(this.filterColumns).forEach((cellId: string, idx: number) => {
      const column = Object.assign({}, {
        key: idx,
        children: this.localizedColumnNames[cellId],
        cellId,
      });

      visibleColumns.push(<TextCell {...column} />);
    });
    return visibleColumns;
  }

  get bodyContent() {
    const { logEntries, getMessage } = this.props;
    const {
      DATE,
      USER,
      EVENT,
      DETAILS,
    } = this.props.tableHelper.columns;

    // TODO: find something better to key these rows off of
    /* eslint-disable react/no-array-index-key */
    return logEntries.map((log: any, rowIdx: number) => {
      const { date, user, event, details } = log;
      const fullName = getUserFullName(user, 'System');
      const message = this.getDetailsMessage(details);
      const visibleColumns: any[] = [];

      this.allColumns.filter(this.filterColumns).forEach((cellId: string) => {
        switch (cellId) {
          case DATE: {
            visibleColumns.push(
              <TextCell
                key={cellId}
                title={date}
                cellId={cellId}
                isFloatingCell
              >
                <div className={style['sync-log-date']}>
                  {this.highlightText(formatTZDate(date))}
                </div>
              </TextCell>,
            );
            break;
          }
          case USER: {
            visibleColumns.push(
              <UserCell
                key={cellId}
                title={fullName}
                cellId={cellId}
                userName={this.highlightText(fullName)}
              />,
            );
            break;
          }
          case EVENT: {
            visibleColumns.push(
              <TextCell
                key={cellId}
                cellId={cellId}
              >
                <div className={this.getCellClassName(event)}>
                  {this.highlightText(getMessage(`app.ad_sync.sync_log.log_events.${event}`))}
                </div>
              </TextCell>,
            );
            break;
          }
          case DETAILS: {
            visibleColumns.push(
              <TextCell
                key={cellId}
                title={message}
                cellId={cellId}
              >
                {this.linkifyMessage(message)}
              </TextCell>,
            );
            break;
          }
          default: {
            visibleColumns.push(null);
          }
        }
      });

      return (
        <TableRow
          key={rowIdx}
          index={rowIdx}
          rowId={rowIdx.toString()}
        >
          {visibleColumns}
        </TableRow>
      );
    });
  }
}
