import ga from 'react-ga';

import { MONITORING } from '../../config/configs';

export default function AppMonitoring() {
  /* eslint-disable no-underscore-dangle */
  const { trackingKey } = MONITORING[window.__env__] || MONITORING.default;
  /* eslint-enable no-underscore-dangle */

  // google analytics
  ga.initialize(trackingKey);
  ga.pageview(window.location.href);
}
