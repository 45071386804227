/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ManagementList from 'v-c/ManagementList';
import withMessages from 'component-utilities/intl';
import LoadingIndicator from 'v-c/LoadingIndicator';
import { connect } from 'react-redux';

import Layout from '../../../global/components/Layout';
import Breadcrumb from '../../../global/components/Breadcrumb';
import HeadlineLabel from '../../../global/components/HeadlineLabel';
import validateSettingsAccess from './validateSettingsAccess';

import { load as loadAccountSettings } from '../../../global/state_managers/AccountSettingsStateManager';
import { breadcrumbsActions } from '../../../global/state_managers/BreadcrumbsStateManager';

import Details from '../O365Integration/Details';

const mapStateToProps = ({ accountSettings }: any) => accountSettings;
const mapActionsToProps = {
  loadAccountSettings,
  setBreadcrumbForSettingsO365IntegrationPage:
    breadcrumbsActions.settingsO365IntegrationPage,
};

type Props = {
  setBreadcrumbForSettingsO365IntegrationPage: Function,
  loadAccountSettings: Function,
  getMessage: Function,
  isLoaded: Boolean,
  account: {
    o365IntegrationDetails: any
  }
}

@withMessages
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@validateSettingsAccess('settings.o365_integration')
export default class O365Integration extends Component<Props> {
  static propTypes = {
    getMessage: PropTypes.func.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    account: PropTypes.object.isRequired,
    loadAccountSettings: PropTypes.func.isRequired,
    setBreadcrumbForSettingsO365IntegrationPage: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    props.setBreadcrumbForSettingsO365IntegrationPage();
    this.props.loadAccountSettings();
  }

  render() {
    const { getMessage, isLoaded, account } = this.props;

    return (
      <Layout section="settings o365-integration" staticView>
        <header>
          <Breadcrumb />
          <HeadlineLabel
            text={getMessage('app.settings.o365_integration.tab_name')}
          />
        </header>
        <ManagementList>
          {!isLoaded && (
            <div style={{ height: 100 }}>
              <LoadingIndicator
                orientation="middle"
                text={getMessage('app.general.loading')}
                isLoading
              />
            </div>
          )}
          {isLoaded && (
            <Details
              o365IntegrationDetails={account.o365IntegrationDetails}
              getMessage={this.props.getMessage}
            />
          )}
        </ManagementList>
      </Layout>
    );
  }
}
