import React from 'react';
import { render } from 'react-dom';
import { Router, browserHistory } from 'react-router';

import { Provider } from 'react-redux';
import reduxStore from '../reduxStore';

import routes from '../../config/routes';

export default function AppRouter() {
  return (
    render(
      <Provider store={reduxStore}>
        <Router routes={routes} history={browserHistory} />
      </Provider>,
      document.getElementById('app'),
    )
  );
}
