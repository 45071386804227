/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isString } from 'lodash';

import { formHelperPropTypes } from '../../../global/lib/formHelper';

const MONTHS_IN_YEAR = 12;
const MIN_RETENTION_MONTHS = 3;

type Fields = {
  months?: any,
  years?: any,
  days?: any,
  enabled?: any
}

type Props = {
  setFields: Function,
  isFlashVisible: Boolean,
  retentionPolicy: {
    isUpdating: Boolean,
    policy: {
      retentionPolicyId: string,
      ttlUnit: string,
    },
  },
  resetFlash: Function,
  updateRetentionPolicy: Function,
  loggedInAsUserId: number,
  makeFieldsUndirty: Function,
  getFieldValues: Function,
  fields: Fields,
  isDirtyFields: Boolean
}

export function getInitialFields(policies: { enabled: Boolean, ttl: number, ttlUnit: string }) {
  const { enabled, ttl, ttlUnit } = policies;

  const years = ttl ? Math.floor(ttl / MONTHS_IN_YEAR) : 0;
  const months = ttl - (years * MONTHS_IN_YEAR);

  return {
    enabled,
    years: years.toString(),
    months: months.toString(),
    days: ttlUnit === 'days' ? ttl : undefined,
  };
}

export function stateLogic() {
  return function PoliciesStateLogicComposer(component: any) {
    return class PoliciesStateLogic extends Component<Props> {
      static propTypes = {
        ...formHelperPropTypes,
        isFlashVisible: PropTypes.bool.isRequired,
        retentionPolicy: PropTypes.shape({
          isUpdating: PropTypes.bool,
          policy: PropTypes.shape({
            retentionPolicyId: PropTypes.string,
          }),
        }).isRequired,
        resetFlash: PropTypes.func.isRequired,
        updateRetentionPolicy: PropTypes.func.isRequired,
        loggedInAsUserId: PropTypes.number.isRequired,
      };

      componentDidUpdate(prevProps: Props) {
        if (
          prevProps.retentionPolicy.isUpdating &&
          !this.props.retentionPolicy.isUpdating
        ) {
          this.props.makeFieldsUndirty();
        }
      }

      onUpdate = () => {
        const {
          retentionPolicy: {
            policy: { retentionPolicyId, ttlUnit },
          },
          getFieldValues,
          loggedInAsUserId,
        } = this.props;
        
        const { years, months, enabled, days } = getFieldValues();

        const ttl =
          days
            ? days
            : parseInt(years, 10) * 12 + parseInt(months, 10);

        this.props.updateRetentionPolicy({
          policy: {
            enabled,
            ttl,
            'ttl-unit': ttlUnit,
            'retention-policy-id': retentionPolicyId,
            'requesting-user': loggedInAsUserId,
            'review-user': loggedInAsUserId,
            'review-status': 'approved',
          },
        });
      };

      onFormChange = () => {
        if (this.props.isFlashVisible) {
          this.props.resetFlash({ from: 'PoliciesArtifact' });
        }
      };

      onDayChange = (event: any) => {
        const newValue = Math.min(
          Math.max(4, event.target.value),
          90
        );
        this.props.setFields({
          days: newValue
        });
      }

      onYearChange = (newValue: any) => {
        this.validateDropdownValues({
          years: newValue,
          months: this.props.fields.months.value,
        });
      };

      onMonthChange = (newValue: any) => {
        this.validateDropdownValues({
          years: this.props.fields.years.value,
          months: newValue,
        });
      };

      validateDropdownValues({ years, months }: Fields) {
        let fixedMonthsValue = months;

        if (!Number(years) || years === 0) {
          fixedMonthsValue = Math.max(
            MIN_RETENTION_MONTHS,
            Number(months),
          ).toString();
        }

        this.props.setFields({
          years,
          months: fixedMonthsValue,
        });
      }

      render() {
        return React.createElement(component, {
          ...this.props,
          onUpdate: this.onUpdate,
          onYearChange: this.onYearChange,
          onMonthChange: this.onMonthChange,
          onFormChange: this.onFormChange,
          onDayChange: this.onDayChange,
        });
      }
    };
  };
}

export function viewLogic(props: Props = {}) {
  const { fields, isDirtyFields, retentionPolicy } = props;

  const fillDropdownValues = (length: any) =>
    [...Array(length).keys()].map(key => ({
      id: key.toString(),
      value: key.toString(),
    }));

  let monthsDropdownValue = fillDropdownValues(12);

  if (!Number(fields.years.value) || Number(fields.years.value) === 0) {
    monthsDropdownValue = monthsDropdownValue.slice(3);
    if (fields.months.value !== '') {
      fields.months.value = Math.max(3, Number(fields.months.value)).toString();
    }
  }

  return {
    isDaysUnit: !!fields.days?.value,
    yearsDropdownValue: fillDropdownValues(51),
    monthsDropdownValue,
    isContentVisible: !isString(fields.enabled.value),
    areDropdownsDisabled: !fields.enabled.value || retentionPolicy.isUpdating,
    updateButton: {
      disabled: !isDirtyFields || retentionPolicy.isUpdating,
      loading: retentionPolicy.isUpdating,
    },
    selectedYear: fields.years.value,
    selectedMonth: fields.months.value,
    isPolicyEnabled: fields.enabled.value,
  };
}
