import { Pages as PagesType } from "global/types";

export const DASHBOARD = 'dashboard';

const Pages: PagesType = {
  DASHBOARD,
  ACCOUNT_SUMMARY: 'account_summary',
  SEARCHES: 'searches',
  SEARCH_RESULTS: 'search_results',
  HOLD_REASON_ASSETS: 'hold_reason_assets',
  MY_ARCHIVE_SPLIT_VIEW: 'my_archive_split_view',
  EXPORTS: 'exports',
  HOLD_REASONS: 'hold_reasons',
  USER_MANAGEMENT: 'user_management',
  SETTINGS: 'account_settings',
  COLLECTORS: 'settings_collectors',
  LOGIN: 'login',
  SAVE_SEARCH: 'save_search',
  SAVED_SEARCH_RESULTS: 'saved_search_results',
  JOBS: 'jobs',

  ASSET_VIEW: 'asset_view',
  NEW_SEARCH: 'new_search',
  NEW_SEARCH_EDIT: 'new_search/edit',
  NEW_HOLD_REASON: 'new_hold_reason',
  NEW_USER: 'new_user',
  NEW_LIST: 'new_list',
  EDIT_LIST: 'edit_list',
  CHANGE_PASSWORD: 'change_user_password',
  RESET_PASSWORD: 'reset_users_password',

  REPORTS: 'reports',
  LISTS: 'lists',
  AD_SYNC: 'ad_sync',
  AD_Wizard: 'ad_wizard',
  SYNC_LOG: 'sync_log',
  SYNC_CONFLICTS: 'sync_conflicts',
  ATTRIBUTE_MAPPINGS: 'attribute_mappings',
  SOURCE_AD_GROUPS: 'source_ad_groups',
  APP_CREDENTIALS: 'app_credentials',
  SECURITY: 'security',
  SETTINGS_ACCOUNT_SETTINGS: 'settings - account settings',
  SETTINGS_O365_INTEGRATION: 'settings - o365 integration',
  SETTINGS_RETENTION_POLICY: 'settings - retention policy',
  SETTINGS_SINGLE_SIGN_ON: 'settings - single sign on',
};

export default Pages;
