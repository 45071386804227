/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';

import { connect } from 'react-redux';

import Loading from '../Loading';

import LoadWhenReady from '../../lib/LoadWhenReady';
import { jsUi } from '../../lib/jsUi';

import Layout from '../Layout';

import {
  loadLegalHoldReasons,
  resetLegalHoldReasons,
  currentHoldReason,
} from '../../state_managers/LegalHoldReasonsStateManager';
import {
  loadAssetViewAsset,
  resetAssetStore,
} from '../../state_managers/AssetStateManager';
import { changeAppView, APP_VIEWS } from '../../state_managers/SearchResultsStateManager';
import {
  flashConnect,
  PAGES,
} from '../FlashMessages';
import { Asset, Params, ReduxState } from 'global/types';

type State = {
  shouldRender: string
}

type Props = {
  params: Params,
  loadLegalHoldReasons: Function,
  resetLegalHoldReasons: Function,
  registerUnmountCleanupMethod: Function,
  asset: Asset,
  loadAssetViewAsset: Function,
  resetAssetStore: Function,
  changeAppView: Function,
  route: {
    metadata: {
      routeRole: string,
    },
  },
  appView: string,
}

const Loaded = Loadable({
  loader: () => import('./Loaded'),
  loading() {
    return <Loading />;
  },
});

const mapStateToProps = ({ legalHoldReasons, asset, searchResults }: ReduxState) => ({
  legalHoldReasons,
  asset,
  appView: searchResults.appView,
});

const mapActionsToProps = {
  loadLegalHoldReasons,
  resetLegalHoldReasons,
  loadAssetViewAsset,
  resetAssetStore,
  changeAppView,
};

@connect(
  mapStateToProps,
  mapActionsToProps,
)
@flashConnect(PAGES.ASSET_VIEW, 'AssetView')
export default class RenderedAssetViewBase extends Component<Props, State> {
  static propTypes = {
    params: PropTypes.object.isRequired,
    loadLegalHoldReasons: PropTypes.func.isRequired,
    resetLegalHoldReasons: PropTypes.func.isRequired,
    registerUnmountCleanupMethod: PropTypes.func.isRequired,
    asset: PropTypes.shape({
      isLoaded: PropTypes.bool,
    }).isRequired,
    loadAssetViewAsset: PropTypes.func.isRequired,
    resetAssetStore: PropTypes.func.isRequired,
    changeAppView: PropTypes.func.isRequired,
    route: PropTypes.shape({
      metadata: PropTypes.shape({
        routeRole: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    appView: PropTypes.string.isRequired,
  };

  static getDerivedStateFromProps(nextProps: Props) {
    if (nextProps.asset.isLoading) {
      return { shouldRender: true };
    }

    return null;
  }

  constructor(props: Props) {
    super(props);

    const {
      params: { asset_id: assetID },
      appView,
    } = props;

    this.state = { assetID, shouldRender: false };
    this.visibleAccordions = null;

    props.loadAssetViewAsset(assetID);
    props.loadLegalHoldReasons({ loadIfNecessary: true });

    // If the user refreshes their browser while on this page
    // or enters url='[site-name]/ui/results/[account-id]/assets/[asset-id]
    // the app loads but doesn't set the app_view state.
    // Therefore leading to different behavior than if the 
    // 'Switch to panel/table' is clicked in search results
    if (appView === APP_VIEWS.SPLIT) {
      this.props.changeAppView(APP_VIEWS.SINGLE);
    }

    props.registerUnmountCleanupMethod(() => {
      props.resetLegalHoldReasons({ from: 'RenderedAssetViewBase' });
    });
  }

  componentDidUpdate(prevProps: Props) {
    const {
      asset: { isLoading, id: assetId },
    } = this.props;

    if (!isLoading && !assetId && prevProps.asset.isLoading) {
      this.handleAssetLoadingError();
    }
  }

  componentWillUnmount() {
    this.props.resetAssetStore();
  }

  handleAssetLoadingError() {
    const { id } = this.props.params;
    const { routeRole } = this.props.route.metadata;

    if (routeRole === 'SEARCHES') {
      jsUi.resultsIndex.goTo({ id });
      this.props.changeAppView('single-view');
    } else if (routeRole === 'HOLD_REASONS') {
      jsUi.holdReasonsIndex.goTo();
    } else {
      jsUi.newSearchResultsIndex.goTo();
    }

    return null;
  }

  render() {
    return (
      <Layout section="rendered-asset">
        <LoadWhenReady
          readyStates={[this.props.asset.isLoaded, this.state.shouldRender]}
        >
          <Loaded
            {...this.state}
            {...this.props}
            currentHoldReason={currentHoldReason}
            visibleAccordions={this.visibleAccordions}
          />
        </LoadWhenReady>
      </Layout>
    );
  }
}
