export default function getUserFullName(user: any, fallback = 'Unknown') {
  if (!user) {
    return fallback;
  }

  const { firstName, lastName } = user;

  // Note with no more BCC - unsure if the below statement is still valid:
  // With the integration of BCC, it's now possible to have a user who
  // doesn't have a last name in our db. We try moderately hard to guess one
  // but if we can't we set it to `none`.
  return [firstName, lastName].filter(part => part !== 'none').join(' ') || fallback;
}
