/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import withMessages from 'component-utilities/intl';

import {
  resetPanel,
  updatePanel,
} from '../../state_managers/HoldPanelStateManager';
import {
  loadLegalHoldReasons,
  resetLegalHoldReasons,
} from '../../state_managers/LegalHoldReasonsStateManager';

import actionsBase, {
  WithinActionsBasePanel as withinPanel,
} from './ActionsBase';

import LockDarkGray from './images/lock-dark-gray.png';

import { viewLogic, stateLogic, viewContents } from './HoldDeconstructed';
import { ReduxState } from 'global/types';

const TYPE = 'hold';

const mapStateToProps = ({ legalHoldReasons, holdPanel }: ReduxState) => ({
  reasons: legalHoldReasons.originalReasons,
  isReasonsLoaded: legalHoldReasons.isLoaded,
  isReasonsLoading: legalHoldReasons.isLoading,
  selectedReasons: holdPanel.selectedReasons,
});

const mapActionsToProps = {
  loadLegalHoldReasons,
  resetLegalHoldReasons,
  resetPanel,
  updatePanel,
};

type Props = {
  resetLegalHoldReasons: Function
  loadLegalHoldReasons: Function
}

@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withMessages
@actionsBase({
  type: TYPE,
  closedStateImage: LockDarkGray,
  openedStateImage: LockDarkGray,
  onMount: () => {
  resetPanel();
  },
  actionCount: (props) => {
  if (props.isActionCounterEnabled) {
  if (props.isActionOpened) {
  return props.selectedReasons.length || props.originallySelectedReasons.length;
  }
  return props.originallySelectedReasons.length;
  }

  return null;
  },
  })
@withinPanel
@stateLogic({
  TYPE,
  })
export default class Hold extends Component<Props> {
  static propTypes = {
    // this is used in actionsBase
    /* eslint-disable react/no-unused-prop-types */
    isActionCounterEnabled: PropTypes.bool,
    assetSelectedReasons: PropTypes.array,
    /* eslint-enable react/no-unused-prop-types */
    loadLegalHoldReasons: PropTypes.func.isRequired,
    resetLegalHoldReasons: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isActionCounterEnabled: false,
    assetSelectedReasons: [],
  };

  constructor(props) {
    super(props);

    props.loadLegalHoldReasons({ loadIfNecessary: true });
  }

  componentWillUnmount() {
    this.props.resetLegalHoldReasons({ from: 'holdsActionPanel' });
  }

  render() {
    return viewContents({ ...this.props, ...viewLogic(this.props) });
  }
}
