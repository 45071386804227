import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import CommentCell from './CommentCell';
import Textarea from 'v-c/Forms/Textarea/Textarea';
import {
  TextLabel,
} from 'view-components/components/Widgets/Components';
import {
  PrimaryButton,
  SecondaryButton,
} from 'view-components/components/Buttons/Components';
import withMessages from 'component-utilities/intl';

import { editComment, saveComment, deleteComment } from '../../state_managers/AssetStateManager';
import { showDeleteCommentDialog } from '../../state_managers/DialogStateManager';
import { Comment, ReduxState } from 'global/types';

const BASE_KEY = 'app.search_results.comments';
const INITIAL_STATE = {
  commentIdEditing: null,
  tempCommentEditing: {
    body: '',
  },
  originalCommentBody: '',
  newCommentBody: '',
};

const mapStateToProps = ({ asset, searchResults }: ReduxState) => ({
  comments: asset.data.comments,
  documentId: asset.id,
  appView: searchResults.appView,
});

type Props = {
  editComment: Function,
  comments: Comment[],
  onActionClick: Function,
  documentId: string,
  saveComment: Function,
  deleteComment: Function,
  getMessage: Function,
  appView: string,
  showDeleteCommentDialog: Function,
}

const mapActionsToProps = {
  editComment,
  saveComment,
  deleteComment,
  showDeleteCommentDialog,
};

@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withMessages
export default class CommentContent extends Component<Props> {
  static propTypes = {
    editComment: PropTypes.func.isRequired,
    comments: PropTypes.array.isRequired,
    onActionClick: PropTypes.func,
    documentId: PropTypes.string.isRequired,
    saveComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    getMessage: PropTypes.func.isRequired,
    appView: PropTypes.string.isRequired,
    showDeleteCommentDialog: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onActionClick: () => {},
  }

  constructor(props: Props) {
    super(props);
    this.state = INITIAL_STATE;
    this.ref = React.createRef()
  }

  onCancel = () => {
    const { appView } = this.props;
    appView === 'single-view' ?
      this.setState(INITIAL_STATE)
      : this.props.onActionClick('');
  };

  onSave = async () => {
    await this.props.saveComment(this.props.documentId, this.state.newCommentBody);
    // scroll to last comment cell
    this.ref.current.lastChild.scrollIntoView(false);
    this.setState(INITIAL_STATE);
  };

  deleteCommentClicked = (comment: Comment) => {
    // confimation modal here
    this.props.showDeleteCommentDialog(
      {
        confirm: () => {
          this.props.deleteComment(this.props.documentId, comment['comment-id']);
        },
      },
      {
        content: 'CurrentSavedSearch',
      },
      true,
    );
  };
  
  setStateCallback = (obj: any) => {
    if (!obj) {
      this.setState({ ...INITIAL_STATE });
    } else {
      this.setState({ ...obj });
    }
  };

  renderComments = () => {
    const { comments, appView, getMessage, documentId } = this.props;
    const { 
      commentIdEditing,
      originalCommentBody,
      tempCommentEditing,
    } = this.state;
    const styles: any = {
      bottom: '253px',
      paddingRight: '30px',
    };
    if (appView !== 'single-view') {
      styles.bottom = '332px';
      styles.top = '65px';
      styles.left = '30px';
    }
    return (
      <data>
        <div ref={this.ref} className="holds-wrapper" style={styles}>
          {comments.map((comment, idx) => (
            <CommentCell
              key={`comment-cell-${comment['comment-id']}`}
              comment={comment}
              isLastCell={idx === comments.length - 1}
              getMessage={getMessage}
              setStateCallback={this.setStateCallback}
              commentIdEditing={commentIdEditing}
              editComment={() =>
                this.props.editComment(
                  documentId,
                  tempCommentEditing.body,
                  commentIdEditing
                )
              }
              originalCommentBody={originalCommentBody}
              tempCommentEditing={tempCommentEditing}
              deleteCommentClicked={this.deleteCommentClicked}
            />
          ))}
        </div>
      </data>
    );
  }

  render() {
    const { newCommentBody } = this.state;
    const { comments, getMessage, appView } = this.props;
    const controlButtonStyles = {
      right: '15px',
      bottom: '2px',
    };
    const textAreaProps = {
      value: newCommentBody,
      onChange: (val: any) => this.setState({ newCommentBody: val.target.value }),
    };

    if (appView !== 'single-view') {
      controlButtonStyles.bottom = '16px';
    } else {
      textAreaProps.className = 'new-comment-textarea';
    }

    return (
      <div className="accordion-view" style={{ fontSize: '12px' }}>
        {this.renderComments()}
        <div className="control-buttons" style={controlButtonStyles}>
          <TextLabel className="head-label">
            {getMessage(`${BASE_KEY}.add`)}
          </TextLabel>
          <TextLabel
            className={`rules-label ${newCommentBody.length > 255 && 'red-color'}`}
          >
            {getMessage(`${BASE_KEY}.rules`)}
          </TextLabel>
          <Textarea
            {...textAreaProps}
          />
          <TextLabel style={{ color: newCommentBody.length > 255 && '#FF0000' }}>
            {newCommentBody.length}/255
          </TextLabel>
          <div className="footer-buttons">
            <PrimaryButton
              extraClassName={`add-button`}
              onClick={this.onSave}
              data-action="save"
              disabled={!newCommentBody.length || newCommentBody.length > 255 || comments.length >= 50}
            >
              {getMessage('app.button.save')}
            </PrimaryButton>
            <SecondaryButton
              extraClassName="cancel-button"
              onClick={this.onCancel}
              data-action="cancel"
            >
              {getMessage('app.button.cancel')}
            </SecondaryButton>
          </div>
        </div>
      </div>
    );
  }
}
