/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

const tableUIFixesPropTypes = {
  registerTableRefs: PropTypes.func,
  forceTableUIUpdate: PropTypes.func,
};

export { tableUIFixesPropTypes };

export default function tableUIFixes(WrappedComponent) {
  return class TableUIFixes extends Component {
    constructor(props) {
      super(props);

      this.tableRefs = {};
      this.originalTablePaddingRigh = null;
      this.originalTableMarginRight = null;
    }

    componentDidMount() {
      this.normalTableUIFixes();
      this.infiniteTableUIFixes();

      window.addEventListener('resize', this.infiniteTableUIFixes);
    }

    componentDidUpdate() {
      this.infiniteTableUIFixes();
      this.normalTableUIFixes();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.infiniteTableUIFixes);
    }

    setHeaderCells = () => {
      const { tableHeaderRow, tableBodyRow } = this.tableRefs;

      if (tableBodyRow && tableHeaderRow) {
        const tableBodyRowNode = findDOMNode(tableBodyRow);
        const headerChildren = findDOMNode(tableHeaderRow).childNodes;

        if (tableBodyRowNode.childNodes.forEach) {
          tableBodyRowNode.childNodes.forEach((node, idx) => {
            const columnWidth = window.getComputedStyle(node).width;

            headerChildren[idx].style.maxWidth = columnWidth;
            headerChildren[idx].style.minWidth = columnWidth;
          });
        }
      }
    }

    // TODO: replace this with something that doesn't accept string refs.
    registerTableRefs = (refs) => {
      this.tableRefs = refs;
    }

    forceTableUIUpdate = () => {
      this.infiniteTableUIFixes();
      this.normalTableUIFixes();
    }

    normalTableUIFixes = () => {
      if (this.tableRefs.tableContent) {
        this.setHeaderCells();
        this.scrollbarFix();
      }
    }

    scrollbarFix() {
      const { tableContent } = this.tableRefs;

      if (tableContent) {
        const tableContentNode = findDOMNode(tableContent);
        const contentHeight = tableContent.offsetHeight;
        const scrollHeight = tableContent.scrollHeight;

        if (contentHeight < scrollHeight) {
          const scrollbarWidth = tableContentNode.offsetWidth - tableContentNode.clientWidth;

          if (scrollbarWidth) {
            this.originalTablePaddingRigh =
              this.originalTablePaddingRigh ||
              parseInt(
                window.getComputedStyle(tableContentNode).paddingRight || 0
                , 10);

            this.originalTableMarginRight =
              this.originalTableMarginRight ||
              parseInt(
                window.getComputedStyle(tableContentNode).marginRight || 0
                , 10);

            const newPaddingRight = Math.max(this.originalTablePaddingRigh - scrollbarWidth, 0);

            tableContentNode.style.paddingRight = `${newPaddingRight}px`;
            tableContentNode.style.marginRight = `-${newPaddingRight + scrollbarWidth}px`;
          }
        } else {
          tableContentNode.style.paddingRight = `${this.originalTablePaddingRigh}px`;
          tableContentNode.style.marginRight = `${this.originalTableMarginRight}px`;
        }
      }
    }

    infiniteTableUIFixes = () => {
      if (this.tableRefs.infiniteTable) {
        this.fixInfiniteTableScrollbar();
        this.fixInfiniteTableHeaderCellsWidth();
      }
    }

    fixInfiniteTableScrollbar = () => {
      const { infiniteTableHeader, infiniteTable } = this.tableRefs;

      const tableWidth = $(findDOMNode(infiniteTable)).find('table').width();

      $(findDOMNode(infiniteTableHeader)).width(tableWidth);
    }

    fixInfiniteTableHeaderCellsWidth = () => {
      const { tableHeaderRow, infiniteTable } = this.tableRefs;
      const tableBodyCells =
        $(findDOMNode(infiniteTable))
          .find('tr[data-index]')
          .filter((i, elem) =>
            ($(elem).attr('data-index').match(/[0-9]/g)),
          )
          .first()
          .find('td');

      if (tableBodyCells.length > 2) {
        $(findDOMNode(tableHeaderRow)).find('th').each((idx, elem) => {
          const bodyCellWidth = $(tableBodyCells[idx]).outerWidth();

          $(elem).css({ minWidth: bodyCellWidth, maxWidth: bodyCellWidth });
        });
      }
    }

    registerTabDirtyStatusCheckFn = (fn) => {
      this.registeredTabDirtyStatusCheckFn.push(fn);
    }

    render() {
      return (
        <WrappedComponent
          registerTableRefs={this.registerTableRefs}
          forceTableUIUpdate={this.forceTableUIUpdate}
          {...this.props}
        />
      );
    }
  };
}
