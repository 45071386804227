import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import withMessages from 'component-utilities/intl';
import {
  FormContainer,
  FormTitle,
  FormRow,
  FormItemLarge,
  FormItemSmall,
  FormLabel,
  InputField,
  Textarea,
} from 'v-c/Forms';
import { PrimaryButton, SecondaryButton, Spacer } from 'v-c/Buttons';

import { jsUi } from '../../global/lib/jsUi';
import routeLeaveHook from '../../global/lib/routeLeaveHook';
import Breadcrumb from '../../global/components/Breadcrumb';
import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';
import {
  createLegalHoldReason,
  resetLegalHoldReasons,
} from '../../global/state_managers/LegalHoldReasonsStateManager';
import { resetFlash } from '../../global/state_managers/FlashMessagesStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import { stateLogic } from './NewHoldReasonDeconstructed';

const BASE_KEY = 'app.new_hold_reason';

const mapStateToProps = ({ legalHoldReasons, flashMessages }: any) => ({
  legalHoldReasons,
  flashMessages,
});

const mapActionsToProps = {
  createLegalHoldReason,
  resetLegalHoldReasons,
  resetFlash,
  setBreadcrumbForHoldReasonsNewReasonPage:
    breadcrumbsActions.holdReasonsNewReasonPage,
};

type Props = {
  getMessage: Function,
  fields: {
    name: any,
    description: any,
  },
  onCancel: Function,
  dirty: Boolean,
  onSave: Function,
  isValidForm: Boolean,
}

@routeLeaveHook
@withMessages
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@flashConnect(PAGES.NEW_HOLD_REASON, 'New-hold-reason')
@reduxForm({
  form: 'new-hold-reason',
  fields: ['name', 'description'],
  initialState: { name: '', description: '' },
  })
@stateLogic({
  goToReasonsIndex: jsUi.holdReasonsIndex.goTo,
  })
export default class NewHoldReason extends Component<Props> {
  static propTypes = {
    getMessage: PropTypes.func.isRequired,
    fields: PropTypes.shape({
      name: PropTypes.object.isRequired,
      description: PropTypes.object.isRequired,
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    isValidForm: PropTypes.bool.isRequired,
  };

  render() {
    const {
      getMessage,
      fields,
      onCancel,
      dirty,
      onSave,
      isValidForm,
    } = this.props;

    return (
      <div data-view="new-hold-reason">
        <div data-content="header">
          <FlashMessages />
          <Breadcrumb {...this.props} />
        </div>
        <div data-content="content">
          <FormContainer>
            <FormTitle>{getMessage(`${BASE_KEY}.title`)}</FormTitle>
            <FormRow>
              <FormItemLarge>
                <FormLabel required>{getMessage('app.general.name')}</FormLabel>
                <InputField
                  focused
                  data-field="new-reason-name"
                  {...fields.name}
                />
              </FormItemLarge>
            </FormRow>
            <FormRow>
              <FormItemLarge>
                <FormLabel required>
                  {getMessage('app.general.description')}
                </FormLabel>
                <Textarea
                  focused={false}
                  data-field="new-reason-description"
                  {...fields.description}
                />
              </FormItemLarge>
            </FormRow>
            <FormRow>
              <FormItemSmall>
                <PrimaryButton
                  data-action="save-reason"
                  disabled={!dirty || !isValidForm}
                  onClick={onSave}
                  loading={false}
                >
                  {getMessage('app.button.save')}
                </PrimaryButton>
                <Spacer />
                <SecondaryButton data-action="cancel-reason" onClick={onCancel}>
                  {getMessage('app.button.cancel')}
                </SecondaryButton>
              </FormItemSmall>
            </FormRow>
          </FormContainer>
        </div>
      </div>
    );
  }
}
