/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { IntlProvider } from 'react-intl';
import { processNode } from 'view-components/utilities/intl';

import Layout from './DifferentLayout';

import IfFeature from './IfFeature';

import AppContainer from './AppContainer';
import DomainInfoLoading from './DomainInfoLoading';
import DomainInfoLoadingFailed from './DomainInfoLoadingFailed';

import { messages } from '../lib/localizedDataLoader';

import { LOCALE } from '../../config/configs';
import { ReduxState } from 'global/types';

type Props = {
  isDomainLoaded: boolean
  domainError: {}
  features: string[]
}

const mapStateToProps = ({ domainInfo, breadcrumbs }: ReduxState) => ({
  isDomainLoaded: domainInfo.isLoaded,
  domainError: domainInfo.error,
  breadcrumbs,
});

@connect(mapStateToProps)
export default class App extends Component<Props> {
  static propTypes = {
    children: PropTypes.node.isRequired,
    params: PropTypes.object.isRequired,
    isDomainLoaded: PropTypes.bool.isRequired,
    domainError: PropTypes.string,
    features: PropTypes.object,
    breadcrumbs: PropTypes.object.isRequired,
  };

  static defaultProps = {
    features: IfFeature.featureData(),
    domainError: null,
  };

  componentDidMount() {
    this.interval = setInterval(this.setZendeskWidth, 100);
  }

  get appView() {
    const { isNewLayoutCompatible } = this.props.children.type;

    if (isNewLayoutCompatible) {
      return <Layout {...this.props} />;
    }

    return <AppContainer {...this.props} />;
  }

  render() {
    const { isDomainLoaded, domainError, features } = this.props;
    const { LANGUAGE } = LOCALE;
    let toRender;

    if (isDomainLoaded) {
      if (domainError) {
        toRender = <DomainInfoLoadingFailed message={domainError} />;
      } else if (features) {
        toRender = (
          <IntlProvider
            locale={LANGUAGE}
            messages={processNode({ ...messages[LANGUAGE] })}
          >
            {this.appView}
          </IntlProvider>
        );
      } else {
        toRender = <DomainInfoLoading />;
      }
    } else {
      toRender = <DomainInfoLoading />;
    }

    return toRender;
  }
}
