/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {inProcessJobs} from "../state_managers/ExportsStateManager";

type Props = {
  allImmediately: Function
  allWhenOptimal: Function
}

type State = {
  initialLoad: boolean
}

// several areas of the app load filbert jobs in the
// exact same way. this standardizes that loading
// approach between them.
export default function withJobsLoad(key: string) {
  return (component: any) =>
    class WithJobsLoad extends Component<Props, State> {
      static displayName = 'withJobsLoad';

      static propTypes = {
        allImmediately: PropTypes.func.isRequired,
      };

      static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        if (nextProps[key].isReady) {
          if (!nextProps[key].isLoading && inProcessJobs(nextProps[key].jobs).length) {
            nextProps.allWhenOptimal();
          }

          if (prevState.initialLoad) {
            return { initialLoad: false };
          }
        }

        return null;
      }

      constructor(props: Props) {
        super(props);
        this.state = { initialLoad: true };
        props.allImmediately();
      }

      render() {
        return React.createElement(component, {
          ...this.props,
          isLoading: this.state.initialLoad,
        });
      }
    };
}
