/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import RenderedAssetViewBase from '../../../global/components/AssetView/RenderedAssetViewBase';

import { tabs } from '../../../global/components/AssetView/Accordion';
import routeLeaveHook from '../../../global/lib/routeLeaveHook';

import { breadcrumbsActions } from '../../../global/state_managers/BreadcrumbsStateManager';

const mapStateToProps = null;
const mapActionsToProps = {
  setBreadcrumbForHoldReasonsAssetsPage:
    breadcrumbsActions.holdReasonsAssetsPage,
};

@routeLeaveHook
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class HoldReasonRenderedAsset extends Component {
  static propTypes = {
    setBreadcrumbForHoldReasonsAssetsPage: PropTypes.func.isRequired,
  };

  constructor(props: any) {
    super(props);

    props.setBreadcrumbForHoldReasonsAssetsPage();
    this.visibleAccordions = [tabs.METADATA, tabs.HOLDS];
  }

  render() {
    return <RenderedAssetViewBase {...this.props} />;
  }
}
