import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import SearchTool, { TABS } from 'v-c/Search/SearchTool';
import SimpleSearchForm, { FORM_FIELDS } from 'v-c/Search/SimpleSearch';

import { jsUi } from '../../../global/lib/jsUi';
import toDSL from '../../../global/lib/convertSimpleSearchIntoSearchDSLStructure';

import AdvancedSearchForm from '../../../global/components/UnsavedSearchForms/AdvancedSearchForm.tsx';
import {
  openModal,
  closeModal,
} from '../../../global/state_managers/ModalsStateManager';
import {
  updateCurrentView,
  updateSimpleSearch,
  updateAdvancedSearch,
} from '../../../global/state_managers/SearchFormStateManager';
import { loadSearchLists } from '../../../global/state_managers/SearchListsStateManager';
import { loadLegalHoldReasons } from '../../../global/state_managers/LegalHoldReasonsStateManager';
import {
  runUnsavedSearch,
  runModifiedSavedSearch,
  loadUnsavedSearchSummary,
} from '../../../global/state_managers/UnsavedSearchStateManager';

import MODAL_MAP from '../../../global/components/Modals/modalMap.json';

import ParseSearchStringValue from '../../../global/lib/ParseSearchStringValue';

import stateLogic from './FormViewDeconstructed';

import styles from './NewSearch.scss';

const mapStateToProps = ({ domainInfo, legalHoldReasons, searchLists, searchResults }: any) => ({
  reasons: legalHoldReasons.reasons,
  searchLists: searchLists.searchLists,
  emailMatchDetails: {
    emailCount: (searchResults.returnCount || 0).toString(),
    loadingTime: (searchResults.loadingTime || 0).toString(),
  },
  showMatchKeywordExact: domainInfo.exactMatchClientEnabled,
  showDomains: domainInfo.domainSearchClientEnabled,
});

const mapActionsToProps = {
  openModal,
  closeModal,
  updateCurrentView,
  updateSimpleSearch,
  updateAdvancedSearch,
  loadLegalHoldReasons,
  loadSearchLists,
  runUnsavedSearch,
  runModifiedSavedSearch,
  loadUnsavedSearchSummary,
};

type Props = {
  values: {
    simpleSearch: any
  },
  fields: {
    simpleSearch: any,
    currentView: string
  },
  searchForm: {
    currentView: string
  },
  updateCurrentView: Function,
  onSearch: Function,
  onCancel: Function,
  onListClick: Function,
  listOfTypeExists: Function,
  holdReasons: any[],
  disabledTabs: any[],
  isInitialForm: Boolean,
  emailMatchDetails: any,
  runUnsavedSearch: Function,
  showMatchKeywordExact: Boolean,
  showDomains: Boolean,
}

@connect(
  mapStateToProps,
  mapActionsToProps,
)
@reduxForm(
  {
  form: 'new-search',
  fields: ['simpleSearch', 'advancedSearch'],
  enableReinitialize: true,
  },
  ({ searchForm }: any) => ({
    initialValues: searchForm,
    }),
)
@stateLogic({
  goToSearchesIndex: jsUi.searchesIndex.goTo,
  goToSearchesShow: jsUi.searchesShow.goTo,
  toDSL,
  TABS,
  MODAL_MAP,
  })
export default class FormView extends Component<Props> {
  static propTypes = {
    values: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    searchForm: PropTypes.object.isRequired,
    updateCurrentView: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onListClick: PropTypes.func.isRequired,
    listOfTypeExists: PropTypes.func.isRequired,
    holdReasons: PropTypes.array.isRequired,
    disabledTabs: PropTypes.array,
    isInitialForm: PropTypes.bool,
    emailMatchDetails: PropTypes.any,
    runUnsavedSearch: PropTypes.func.isRequired,
    showMatchKeywordExact: PropTypes.bool.isRequired,
    showDomains: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    disabledTabs: [],
    isInitialForm: false,
    emailMatchDetails: null,
  };

  static displayName = 'NewSearchResults/FormView';

  render() {
    const {
      values,
      fields: { simpleSearch },
      searchForm: { currentView },
      disabledTabs,
      onSearch,
      onCancel,
      onListClick,
      listOfTypeExists,
      holdReasons,
      emailMatchDetails,
      showMatchKeywordExact,
      showDomains,
    } = this.props;
    const viewProps = {
      formView: 'edit',
      isInitialForm: true,
      onSearch: this.props.runUnsavedSearch,
    };

    if (!values.simpleSearch) {
      return null;
    }

    const isKeywordExact = values.simpleSearch[FORM_FIELDS.KEYWORDS_EXACT] && values.simpleSearch[FORM_FIELDS.KEYWORDS_EXACT].query;
    const isSenderDomains = values.simpleSearch[FORM_FIELDS.SENDER_DOMAINS] && values.simpleSearch[FORM_FIELDS.SENDER_DOMAINS].query;
    const isParticipantsDomains = values.simpleSearch[FORM_FIELDS.PARTICIPANTS_DOMAINS] && values.simpleSearch[FORM_FIELDS.PARTICIPANTS_DOMAINS].query;

    return (
      <div className={styles.wrapper}>
        <SearchTool
          onTabSelect={(newView: string) => this.props.updateCurrentView(newView)}
          activeTab={currentView}
          disabledTabs={disabledTabs}
        >
          {currentView === TABS.SIMPLE && (
            <SimpleSearchForm
              formValues={simpleSearch.value}
              originalFormValues={simpleSearch.initialValue}
              onFormChange={simpleSearch.onChange}
              onSearch={onSearch}
              onCancel={onCancel}
              holdReasons={holdReasons}
              isKeywordListEnabled={listOfTypeExists('keyword')}
              onKeywordListClick={() =>
                onListClick('keyword', FORM_FIELDS.KEYWORDS)
              }
              isUserListEnabled={listOfTypeExists('user')}
              onSenderListClick={() => onListClick('user', FORM_FIELDS.SENDER)}
              onParticipantsListClick={() =>
                onListClick('user', FORM_FIELDS.PARTICIPANTS)
              }
              emailMatchDetails={emailMatchDetails}
              isKeywordExact={isKeywordExact}
              showMatchKeywordExact={showMatchKeywordExact}
              showDomains={showDomains}
              isSenderDomains={isSenderDomains}
              isParticipantsDomains={isParticipantsDomains}
              parseSearchStringValue={ParseSearchStringValue}
            />
          )}

          {currentView === TABS.ADVANCED && (
            <AdvancedSearchForm {...viewProps} />
          )}
        </SearchTool>
      </div>
    );
  }
}
