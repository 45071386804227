/* eslint-disable filenames/match-regex */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isEqual, trim } from 'lodash';

import dialogHandler from '../../global/lib/dialogHandler';
import { showUnsavedHoldReasonDialog } from '../../global/state_managers/DialogStateManager';

type Props = {
  setRouterWillLeaveValidator: Function,
  retryTransition: Function,
  initializeForm: Function,
  dirty: Boolean,
  values?: any,
  createLegalHoldReason: Function,
  resetLegalHoldReasons: Function,
  fields: {
    name: any,
    description: any,
  },
  flashMessages: {
    indicators: any[],
  },
  resetFlash: Function,
  registerUnmountCleanupMethod: Function,
  setBreadcrumbForHoldReasonsNewReasonPage: Function,

}

export function viewLogic() {}

export function stateLogic({ goToReasonsIndex }: any) {
  return function NewHoldReasonStateLogicComposer(component: any) {
    class NewHoldReasonStateLogic extends Component<Props> {
      static propTypes = {
        setRouterWillLeaveValidator: PropTypes.func.isRequired,
        retryTransition: PropTypes.func.isRequired,
        initializeForm: PropTypes.func.isRequired,
        dirty: PropTypes.bool.isRequired,
        values: PropTypes.object.isRequired,
        createLegalHoldReason: PropTypes.func.isRequired,
        resetLegalHoldReasons: PropTypes.func.isRequired,
        fields: PropTypes.shape({
          name: PropTypes.object.isRequired,
          description: PropTypes.object.isRequired,
        }).isRequired,
        flashMessages: PropTypes.shape({
          indicators: PropTypes.array.isRequired,
        }).isRequired,
        resetFlash: PropTypes.func.isRequired,
        registerUnmountCleanupMethod: PropTypes.func.isRequired,
        setBreadcrumbForHoldReasonsNewReasonPage: PropTypes.func.isRequired,
      };

      constructor(props: Props) {
        super(props);

        props.setBreadcrumbForHoldReasonsNewReasonPage();
        props.setRouterWillLeaveValidator(this.routerWillLeave);

        props.registerUnmountCleanupMethod(() => {
          props.resetLegalHoldReasons({ from: 'NewHoldReasonStateLogic' });
        });
      }

      componentDidUpdate(prevProps: Props) {
        if (
          !isEqual(prevProps.values, this.props.values) &&
          this.props.flashMessages.indicators.length
        ) {
          this.props.resetFlash({
            from: 'NewHoldReasonStateLogic/componentDidUpdate',
          });
        }
      }

      onSave = () => {
        const { values } = this.props;

        this.props.initializeForm(values);
        this.props.createLegalHoldReason(values);
      };

      onCancel = () => {
        goToReasonsIndex();
      };

      get isValidForm() {
        const {
          fields: { name, description },
        } = this.props;

        return !!(trim(name.value).length && trim(description.value).length);
      }

      routerWillLeave = () => {
        if (!this.props.dirty) {
          return true;
        }

        dialogHandler(showUnsavedHoldReasonDialog, this.props.retryTransition);

        return false;
      };

      render() {
        return React.createElement(component, {
          ...this.props,
          ...this.state,
          onSave: this.onSave,
          onCancel: this.onCancel,
          isValidForm: this.isValidForm,
          routerWillLeave: this.routerWillLeave,
        });
      }
    }

    return NewHoldReasonStateLogic;
  };
}
