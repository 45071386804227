import uri from 'urijs';
import { camelCase, difference, includes, pull } from 'lodash';

import convertDataKeys from 'view-components/lib/convertDataKeys';
import columns from 'v-c/Templates/UsersTable/columns.json';
import reduxStore, { Action, PromiseObject } from '../reduxStore';

import configs from '../../config/configs';

import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';
import getUserFullName from '../lib/getUserFullName';
import { prioritizeRoles, abbreviatedRoles } from '../lib/userRights';

import { showSuccessFlash, showFailureFlash } from './FlashMessagesStateManager';

import { PAGES } from '../components/FlashMessages';

import { sortKeys } from '../lib/StoresHelper';
import { User } from 'global/types';

export const RESET: string = 'users/reset';
export const USERS_LOADING_ALL: string = 'users/loadingAll';
export const USERS_LOADED_ALL: string = 'users/loadedAll';
export const USERS_FAILED_TO_LOAD_ALL: string = 'users/failedToLoadAll';
export const USERS_LOADING_PACK: string = 'users/loadingPack';
export const USERS_LOADED_PACK: string = 'users/loadedPack';
export const USERS_FAILED_TO_LOAD_PACK: string = 'users/failedToLoadPack';
export const FILTER_USERS: string = 'users/filter';
export const ACTIVE_COLUMNS_CHANGED: string = 'users/activeColumnsChanged';
export const CHANGING_USERS_STATUS: string = 'users/changingUserStatus';
export const USER_STATUS_CHANGED: string = 'users/userStatusChanged';
export const USERS_STATUSES_CHANGED: string = 'users/userStatusesChanged';
export const USERS_ROLES_CHANGED: string = 'users/usersRolesChanged';
export const FAILED_TO_CHANGE_USER_STATUS: string = 'users/failedToChangeUserStatus';
export const SORT_USERS: string = 'users/sortUsers';
const VALID_FLASH_VIEWS = [PAGES.USER_MANAGEMENT];

const { GENERAL_CONFIGS } = configs;

export { columns };

// Init
export const DEFAULT_ROLE = 'endUser';

const { FULL_NAME, EMAIL, USERNAME, ROLES, ACTIVE, EDIT } = columns;

export const FIRST_NAME = 'first-name';
export const LAST_NAME = 'last-name';

const allColumns = [FULL_NAME, EMAIL, USERNAME, ROLES, ACTIVE];
const disabledColumns = [FULL_NAME, EDIT];

interface UsersState {
  isLoading: boolean,
  isLoaded: boolean,
  users: User[],
  allUsers: User[],
  searchString: string,
  totalCount: number,
  isSearchStringChanged: boolean,
  tableHelper: {
    columns: any,
    allColumns: string[],
    activeColumns: string[],
    disabledColumns: string[],
    FIRST_NAME: string,
  },
  sortOptions: {
    sortKey: string[],
    sortOrder: string,
  },
}

export const INITIAL_STATE: UsersState = {
  isLoading: false,
  isLoaded: false,
  users: [],    // changes on search page and special access modal, any other place?
  allUsers: [], // used for special the access table on search detail page
  searchString: '',
  totalCount: 0,
  isSearchStringChanged: false,
  tableHelper: {
    columns,
    allColumns,
    activeColumns: allColumns,
    disabledColumns,
    FIRST_NAME,
  },
  sortOptions: {
    sortKey: [FIRST_NAME, LAST_NAME, USERNAME],
    sortOrder: sortKeys.ASC,
  },
};

// helpers
export function populateUsersData(users: any[]) {
  return users.map((user: any) => {
    const modifiedUser = convertDataKeys({ ...user }, camelCase);

    modifiedUser.roles = prioritizeRoles(modifiedUser.roles);
    modifiedUser.fullName = getUserFullName(modifiedUser);
    modifiedUser.abbreviatedRoles = abbreviatedRoles(modifiedUser.roles);

    return modifiedUser;
  });
}

export function loadUsers(api: any, actions: any, customQueryParams: any) {
  return function* doLoadUsers(getState: Function) {
    const { sortOptions, searchString } = getState().users;

    yield { type: actions.loading };

    const searchQuery = searchString.length
      ? `contains ${searchString} ${[
        EMAIL,
        USERNAME,
        FIRST_NAME,
        LAST_NAME,
        ROLES,
      ].join(',')}`
      : '';
    const queryParams = uri('')
      .search({
        filter: searchQuery,
        'order-by': `${sortOptions.sortOrder === sortKeys.DESC ? '-' : ''}${
          sortOptions.sortKey
        }`,
        ...customQueryParams,
      })
      .toString();

    try {
      const result: PromiseObject = yield apiReqPromise(api, { queryParams });

      yield {
        type: actions.done,
        payload: {
          users: populateUsersData(result.data.users),
          totalCount: result.data.totalCount || 0,
          offset: customQueryParams.offset || 0,
        },
      };
    } catch (e) {
      console.error(e);

      yield { type: actions.failed };
    }
  };
}

// Actions
export function loadAllUsers() {
  const actions = {
    loading: USERS_LOADING_ALL,
    done: USERS_LOADED_ALL,
    failed: USERS_FAILED_TO_LOAD_ALL,
  };
  return loadUsers(jsApi.usersAll, actions, { offset: 0 });
}

export function loadUsersPack(offset = 0) {
  const { isSearchStringChanged } = reduxStore.getState().users;
  const actions = {
    loading: USERS_LOADING_PACK,
    done: USERS_LOADED_PACK,
    failed: USERS_FAILED_TO_LOAD_PACK,
  };
  const modifiedOffset = isSearchStringChanged ? 0 : offset;

  return loadUsers(jsApi.usersIndex, actions, {
    offset: modifiedOffset,
    limit: GENERAL_CONFIGS.INFINITE_SCROLL.LIMIT,
  });
}

export function filterUsers(searchString = '') {
  return function* doFilterUsers() {
    yield { type: FILTER_USERS, payload: { searchString } };

    reduxStore.dispatch(loadUsersPack());
  };
}

export function sortUsers(sortKey: string, sortOrder: string) {
  return function* doSortUsers(getState: Function) {
    const newKey = sortKey === FULL_NAME ? [FIRST_NAME, LAST_NAME] : [sortKey];
    const currentSortKey = getState().users.sortOptions.sortKey;
    const newSortKey = newKey.concat(difference(currentSortKey, newKey));

    yield {
      type: SORT_USERS,
      payload: {
        sortOptions: {
          sortKey: newSortKey,
          sortOrder,
        },
      },
    };

    reduxStore.dispatch(loadUsersPack());
  };
}

export function changeUserStatus(id: string, isActive: boolean) {
  return function* doChangeUserStatus() {
    yield { type: CHANGING_USERS_STATUS };

    try {
      yield apiReqPromise(jsApi.usersUpdate, {
        params: { data: { user: { active: isActive } } },
        urlParams: { id },
      });

      yield { type: USER_STATUS_CHANGED, payload: { id, isActive } };

      reduxStore.dispatch(
        showSuccessFlash('user.update', VALID_FLASH_VIEWS));
    } catch (e) {
      console.error(e);

      yield { type: FAILED_TO_CHANGE_USER_STATUS };
      reduxStore.dispatch(
        showFailureFlash('bulk_user.update.failure', VALID_FLASH_VIEWS));
    }
  };
}

export function activeColumnsChanged(newColumns: string[]) {
  return {
    type: ACTIVE_COLUMNS_CHANGED,
    payload: { activeColumns: newColumns },
  };
}

export function resetUsers() {
  return { type: RESET };
}

// Store
export default function UsersStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case RESET:
      return { ...INITIAL_STATE };
    case USERS_LOADING_ALL:
    case USERS_LOADING_PACK:
      return {
        ...state,
        isLoading: true,
        isSearchStringChanged: false,
      };
    case USERS_LOADED_ALL:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        allUsers: payload.users,
        users: payload.users,
        totalCount: payload.totalCount,
      };
    /* eslint-disable no-case-declarations */
    case USERS_LOADED_PACK:
      const modifiedUsers = [...state.users];
      payload.users.forEach((user: any, idx: number) => {
        modifiedUsers[idx + payload.offset] = user;
      });

      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        users: modifiedUsers,
        totalCount: payload.totalCount,
      };
    case USERS_FAILED_TO_LOAD_ALL:
    case USERS_FAILED_TO_LOAD_PACK:
      return {
        ...state,
        isLoading: false,
      };
    case FILTER_USERS:
      return {
        ...state,
        searchString: payload.searchString,
        isLoading: true,
        users: [],
        totalCount: 0,
        isSearchStringChanged: true,
      };
    case ACTIVE_COLUMNS_CHANGED:
      return {
        ...state,
        tableHelper: {
          ...state.tableHelper,
          activeColumns: payload.activeColumns,
        },
      };
    case USER_STATUS_CHANGED:
      return {
        ...state,
        users: state.users.filter(user => user).map((user) => {
          if (payload.id === user.id) {
            return { ...user, active: payload.isActive };
          }

          return user;
        }),
      };
    case USERS_STATUSES_CHANGED:
      return {
        ...state,
        users: state.users.filter(user => user).map((user) => {
          if (includes(payload.ids, String(user.id))) {
            return { ...user, active: payload.isActive };
          }

          return user;
        }),
      };
    case USERS_ROLES_CHANGED:
      return {
        ...state,
        users: state.users.filter(user => user).map((user) => {
          if (!includes(payload.ids, String(user.id))) {
            return user;
          }

          const modifiedUser = { ...user };

          if (payload.isAdd) {
            modifiedUser.roles = [
              ...modifiedUser.roles,
              ...difference(payload.selectedRoles, modifiedUser.roles),
            ];
          } else {
            modifiedUser.roles = pull(
              modifiedUser.roles,
              ...payload.selectedRoles,
            );
          }

          if (!modifiedUser.roles.length) {
            modifiedUser.roles = [DEFAULT_ROLE];
          }

          modifiedUser.abbreviatedRoles = abbreviatedRoles(modifiedUser.roles);

          return modifiedUser;
        }),
      };
    case SORT_USERS:
      return {
        ...state,
        sortOptions: payload.sortOptions,
        totalCount: 0,
        users: [],
      };
    default:
      return state;
  }
}
