import { contains, uniq } from 'lodash';

import reduxStore from '../reduxStore';

import constantsRoles from '../constants/Roles';
import Pages from '../constants/Pages';
import pageRoles from '../../config/constants/PageRoles';
import { pathOrder } from '../constants/pathsByType';

const { Roles, rolesPriority } = constantsRoles;

export default function userRights(store = reduxStore) {
  return store.getState().userRights;
}

export function prioritizeRoles(
  roles = userRights().activeRoles,
  priorities = rolesPriority,
) {
  return priorities.filter(role => roles.indexOf(role) !== -1);
}

export function getPrioritizedUserRole(
  roles = userRights().activeRoles,
  priorities = rolesPriority,
) {
  return priorities.find(role => roles.indexOf(role) !== -1) || '';
}

export function isPageRoleEnabled(pageRole: string) {
  return userRights().activeRoles.some(
    (role: string) => Roles[role] && contains(Roles[role].enabledPageRoles, pageRole),
  );
}

export function getHomePage(roles = userRights().activeRoles) {
  const firstActiveRole = getPrioritizedUserRole();

  if (!Roles[firstActiveRole]) {
    throw new Error('No valid UI roles found!');
  }

  const homePage = uniq(
    roles.reduce((all: string[], role: string) => [...all, Roles[role].homePage], []),
  ).sort((a, b) => pathOrder.indexOf(a) - pathOrder.indexOf(b))[0];

  if (!homePage) {
    throw new Error(
      `No homepage for ${firstActiveRole}!
      Is ${firstActiveRole} a valid UI role?`,
    );
  }

  return homePage;
}

export function getPageRolesWithTags() {
  return Object.keys(Roles).reduce(
    (rolesWithTags, role) => ({ ...rolesWithTags, [role]: Roles[role].tag }),
    {},
  );
}

export function abbreviatedRoles(roles: string[]) {
  const RoleTypes = getPageRolesWithTags();

  return roles
    .filter(role => RoleTypes[role])
    .map(role => RoleTypes[role])
    .join(', ');
}

export function getAllRoles() {
  return Object.keys(Roles).reduce(
    (all, role) => [...all, Roles[role].name],
    [],
  );
}

export function isRequestedPathFitToActiveRole(routeRole: string) {
  return contains(userRights().visibleMenus.all, Pages[routeRole]);
}

export function isActivePathRole(role: string) {
  return contains(userRights().visibleMenus.all, role);
}

export function getRolesData() {
  return Object.keys(Roles).reduce(
    (rolesData, role) =>
      rolesData.concat({
        id: role,
        tag: Roles[role].tag,
      }),
    [],
  );
}

export function calculateVisibleMenus(INITIAL_STATE, roles: string[]) {
  const visibleMenus = { ...INITIAL_STATE.visibleMenus };

  prioritizeRoles(roles).forEach((role) => {
    if (Roles[role]) {
      const { visibleMenus: roleMenus } = Roles[role];

      if (roleMenus) {
        Object.keys(INITIAL_STATE.visibleMenus).forEach((menuGroup) => {
          visibleMenus[menuGroup] = uniq(
            visibleMenus[menuGroup].concat(roleMenus[menuGroup] || []),
          );
        });
      }
    }
  });

  const { search, nav, sub, other } = visibleMenus;
  visibleMenus.all = [].concat(search, nav, sub, other);

  return visibleMenus;
}

export { pageRoles };
