import React from 'react';
import PropTypes from 'prop-types';

import { FormattedHTMLMessage } from 'view-components/utilities/intl';

import UpperSection from 'view-components/components/Dialog/UpperSection/NonAlert';
import { LowerSectionText } from 'view-components/components/Dialog/Components';

import LowerSectionDismiss from './Components/LowerSectionDismiss';
import confirmableDialog from './Components/ConfirmableDialog';

type Props = {
  length: number
  onDismiss: Function
}

function LowerContent({ length }: any) {
  return (
    <LowerSectionText>
      <FormattedHTMLMessage
        id="app.dialog.delete_list.cannot_delete_lower_section"
        values={{ length }}
      />
    </LowerSectionText>
  );
}

function CannotDeleteListDialogContentFn(props: Props) {
  const {
    length,
    onDismiss,
  } = props;
  return (
    <div>
      <UpperSection >
        <FormattedHTMLMessage
          id="app.dialog.delete_list.cannot_delete_message"
        />
      </UpperSection>

      <LowerSectionDismiss
        additionalTopContent={<LowerContent length={length} />}
        onDismiss={onDismiss}
      />
    </div>
  );
}

LowerContent.propTypes = {
  length: PropTypes.number.isRequired,
};

CannotDeleteListDialogContentFn.propTypes = {
  length: PropTypes.number,
  onDismiss: PropTypes.func,
};

CannotDeleteListDialogContentFn.defaultProps = {
  length: 0,
  deleteName: null,
  isConfirmed: false,
  onConfirm: () => {},
  onDismiss: () => {},
  toggleConfirmed: () => {},
};

const CannotDeleteListDialogContent = confirmableDialog(CannotDeleteListDialogContentFn);

export default CannotDeleteListDialogContent; // CannotDeleteListDialogContent
