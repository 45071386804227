/* eslint-disable filenames/match-regex */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { findDOMNode } from 'react-dom';
import { isEmpty, isEqual } from 'lodash';

import highlightText from 'v-c/Text/HighlightText';
import { intlPropTypes } from 'component-utilities/intl';

import {
  CustomCheckbox,
  TextLabel,
  SearchField,
} from 'view-components/components/Widgets/Components';
import {
  PrimaryButton,
  SecondaryButton,
} from 'view-components/components/Buttons/Components';
import toggleInArray from 'component-lib/toggleInArray';

import Processing from '../Processing';
import HoldReason from './components/HoldReason';

const contains = (array: any[], item: any) => array.indexOf(item) !== -1;

export function searchFilter(newValue: any) {
  if (isEmpty(newValue)) {
    return () => true;
  }

  return ({ name }: { name: string }) =>
    name.toLowerCase().indexOf(newValue.toLowerCase()) !== -1;
}

export function buildHoldReasonsUpdateData({ isAdd, selectedReasons }) {
  const newIDs = isAdd ? selectedReasons : [];
  const deletedIDs = isAdd ? [] : selectedReasons;

  return {
    newIDs,
    deletedIDs,
  };
}

export function stateLogic({ TYPE }) {
  return component =>
    class extends Component {
      static displayName = 'Holds/StateLogic';

      static propTypes = {
        reasons: PropTypes.array.isRequired,
        selectedReasons: PropTypes.array.isRequired,
        isHoldActionDirty: PropTypes.func.isRequired,
        onActionClick: PropTypes.func.isRequired,
        onHoldReasonsUpdate: PropTypes.func.isRequired,
        searchResults: PropTypes.object.isRequired,
        originallySelectedReasons: PropTypes.array,
        assetSelectedReasons: PropTypes.array,
        updateSelectedAssetReasons: PropTypes.func,
        resetPanel: PropTypes.func.isRequired,
        updatePanel: PropTypes.func.isRequired,
        registerActionCleanupMethods: PropTypes.func.isRequired,
        isActionOpened: PropTypes.bool.isRequired,
      };

      static defaultProps = {
        originallySelectedReasons: [],
        assetSelectedReasons: [],
        updateSelectedAssetReasons: () => {},
        registerActionCleanupMethods: () => {},
      };

      constructor(props) {
        super(props);

        this.state = {
          hoveredIdx: null,
          searchText: '',
        };

        if (props.assetSelectedReasons) {
          props.updatePanel(props.originallySelectedReasons);
        }

        this.selectAllCheckbox = null;
        this.setIsHoldActionDirty(props.selectedReasons);
      }

      componentDidMount() {
        this.props.registerActionCleanupMethods(TYPE, () => {
          if (!this.props.isActionOpened) {
            this.clearSelectionThenClosePopup();
            this.props.onActionClick('');
          }
        });
      }

      componentDidUpdate() {
        this.setIsHoldActionDirty(this.props.selectedReasons);
      }

      onSearch = (searchText, isSearchOpened = false) => {
        // TODO: replace this with something more component-driven
        if (this.selectAllCheckbox) {
          const allCheckboxElem = findDOMNode(this.selectAllCheckbox);

          allCheckboxElem.style.zIndex = isSearchOpened ? -1 : 2;
        }

        this.setState({ searchText });
      };

      onSelectAllHold = () => {
        const { reasons, selectedReasons } = this.props;
        let newSelectedReasons = [];

        if (selectedReasons.length !== reasons.length) {
          newSelectedReasons = reasons.map(({ enumId }) => enumId);
        }

        this.updateSelectedReasons(newSelectedReasons);
      };

      onSelectHold = id => () => {
        const { selectedReasons } = this.props;

        this.updateSelectedReasons(toggleInArray(selectedReasons, id));
      };

      setIsHoldActionDirty = (selectedReasons) => {
        this.props.isHoldActionDirty(
          !isEqual(this.props.originallySelectedReasons, selectedReasons),
        );
      };

      setSelectAllCheckbox = (selectAllCheckbox) => {
        this.selectAllCheckbox = selectAllCheckbox;
      };

      setHoveredIdx = hoveredIdx => () => {
        this.setState({ hoveredIdx });
      };

      get isSelectionChanged() {
        return !isEqual(
          this.props.originallySelectedReasons.sort(),
          this.props.selectedReasons.sort(),
        );
      }

      updateSelectedReasons(newSelection) {
        this.props.updatePanel(newSelection);

        if (this.props.updateSelectedAssetReasons) {
          this.props.updateSelectedAssetReasons(newSelection);
        }
      }

      updateHoldReasons = async (isAdd) => {
        const { onHoldReasonsUpdate } = this.props;

        const { newIDs, deletedIDs } = buildHoldReasonsUpdateData({
          ...this.props,
          isAdd,
        });

        await onHoldReasonsUpdate.call(this, { newIDs, deletedIDs });

        this.props.resetPanel();
        this.props.isHoldActionDirty(false);
        this.props.onActionClick('');
      };

      isActionDisabled = () => {
        const {
          searchResults: { isLoading, results = [] },
        } = this.props;

        return isLoading || (!isLoading && !results.length);
      };

      clearSelectionThenClosePopup = () => {
        this.props.resetPanel();
        if (this.props.updateSelectedAssetReasons) {
          this.props.updateSelectedAssetReasons(
            this.props.originallySelectedReasons,
          );
        }

        this.props.isHoldActionDirty(false);
        this.props.onActionClick('');
      };

      render() {
        return React.createElement(component, {
          ...this.props,
          ...this.state,
          onSearch: this.onSearch,
          onRemove: () => {
            this.updateHoldReasons(false);
          },
          onCancel: () => {
            this.clearSelectionThenClosePopup();
          },
          onAdd: () => {
            this.updateHoldReasons(true);
          },
          onSelectAllHold: this.onSelectAllHold,
          onSelectHold: this.onSelectHold,
          setHoveredIdx: this.setHoveredIdx,
          clearSelectionThenClosePopup: this.clearSelectionThenClosePopup,
          updateHoldReasons: this.updateHoldReasons,
          isActionDisabled: this.isActionDisabled,
          filteredReasons: this.props.reasons.filter(
            searchFilter(this.state.searchText),
          ),
          setSelectAllCheckbox: this.setSelectAllCheckbox,
          isSelectionChanged: this.isSelectionChanged,
        });
      }
    };
}

export function viewLogic(props) {
  const {
    asset = {},
    reasons,
    selectedReasons,
    searchText,
    filteredReasons,
    isSelectionChanged,
    withMultipleSelection,
    isUpdateProcessing,
  } = props;

  const selectedCount = selectedReasons.length;
  const isAllCheckboxChecked = reasons.length === selectedReasons.length;
  const isPartiallyChecked = !isAllCheckboxChecked && !!selectedReasons.length;

  const isAssetUpdating =
    (withMultipleSelection && (asset.isLoading || asset.isSaving)) ||
    (!withMultipleSelection && isUpdateProcessing);

  const preppedFilteredReasons = filteredReasons.map(({ enumId, name }) => ({
    enumId,
    reasonName: searchText.length ? highlightText(name, [searchText]) : name,
  }));

  return {
    selectedCount,
    isAllCheckboxChecked,
    isPartiallyChecked,
    isButtonsDisabled: !isSelectionChanged,
    preppedFilteredReasons,
    isAssetUpdating,
  };
}

export function viewContents(props) {
  const {
    selectedCount,
    isAllCheckboxChecked,
    isButtonsDisabled,
    preppedFilteredReasons,
    getMessage,
    isReasonsLoading,
    searchText,
    selectedReasons,
    hoveredIdx,
    setSelectAllCheckbox,
    isBulkHoldAction,
    isAssetUpdating,
    isHoldReasonsJobRunning,
    isPartiallyChecked,
  } = props;

  const selectedText = getMessage('app.general.selected');
  const processingText = `${getMessage('app.general.processing')}...`;

  return (
    <div className="content-wrapper">
      <Processing
        text={processingText}
        isProcessing={
          isReasonsLoading || isAssetUpdating || isHoldReasonsJobRunning
        }
      />
      <div className="content-header">
        <TextLabel className="selected-label">
          {`${selectedText} (${selectedCount})`}
        </TextLabel>
        <div className="search-field-wrapper">
          <div className="search-field">
            <CustomCheckbox
              ref={(_r) => {
                setSelectAllCheckbox(_r);
              }}
              isPartiallyChecked={isPartiallyChecked}
              onClick={props.onSelectAllHold}
              className="select-all-checkbox"
              isChecked={isAllCheckboxChecked}
            />
            <SearchField
              placeholder={'Search'}
              value={searchText}
              onChange={props.onSearch}
            />
          </div>
        </div>
      </div>
      <div className="content-main" onMouseOut={props.setHoveredIdx(null)}>
        {preppedFilteredReasons.map(({ enumId, reasonName }, idx) => (
          <HoldReason
            key={enumId}
            id={enumId}
            onClick={props.onSelectHold(enumId)}
            onMouseOver={props.setHoveredIdx(idx)}
            isChecked={contains(selectedReasons, enumId)}
            forceHover={hoveredIdx === idx}
            reasonName={reasonName}
          />
        ))}
      </div>
      <div className="content-footer">
        {isBulkHoldAction && (
          <PrimaryButton
            extraClassName="add-button"
            onClick={props.onAdd}
            data-action="add"
            disabled={isButtonsDisabled}
          >
            {props.getButtonText('add')}
          </PrimaryButton>
        )}
        {isBulkHoldAction && (
          <SecondaryButton
            warning
            onClick={props.onRemove}
            data-action="remove"
            disabled={isButtonsDisabled}
          >
            {props.getButtonText('remove')}
          </SecondaryButton>
        )}
        {!isBulkHoldAction && (
          <PrimaryButton
            extraClassName="add-button"
            onClick={props.onAdd}
            data-action="save"
            disabled={isButtonsDisabled}
          >
            {props.getButtonText('save')}
          </PrimaryButton>
        )}
        <SecondaryButton
          extraClassName="cancel-button"
          onClick={props.onCancel}
          data-action="cancel"
        >
          {props.getButtonText('cancel')}
        </SecondaryButton>
      </div>
    </div>
  );
}

viewContents.propTypes = {
  ...intlPropTypes,
  getButtonText: PropTypes.func.isRequired,
  isBulkHoldAction: PropTypes.bool,
  isHoldReasonsJobRunning: PropTypes.func.isRequired,
};

viewContents.defaultProps = {
  areUnloadedAssetsChecked: false,
  isReasonLoading: false,
  isBulkHoldAction: false,
};
