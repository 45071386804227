import addrs from 'email-addresses';

export default function FixAuthorColumnValue(author: any) {
  if (!author) return '-';

  const modifiedAuthor = addrs.parseOneAddress(author) || {};

  // this will handle the edge case if author is in a non standard format
  // for example, just a name, which returns an empty object
  if (!modifiedAuthor.name && !modifiedAuthor.address && author.length) {
    modifiedAuthor.name = author;
  }

  return modifiedAuthor.name || modifiedAuthor.address || '-';
}
