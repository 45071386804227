/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withMessages from 'component-utilities/intl';

import RetinaImage from 'view-components/components/RetinaImage';
import SuccessImage from './images/success.png';
import ErrorImage from './images/error.png';
import PausedImage from './images/paused.png';

import style from './StatusArea.scss';

type Props = {
  statusData: {
    status: {
      syncStatus: string,
      lastSyncDate: string
    },
    syncConflicts: {
      number: number
    }
  },
  getMessage: Function
}

@withMessages
export default class StatusArea extends Component<Props> {
  static propTypes = {
    statusData: PropTypes.object.isRequired,
    getMessage: PropTypes.func.isRequired,
  };

  statusCheck(statuses: any[]) {
    const status = this.props.statusData.status.syncStatus;
    return statuses.some(s => s === status);
  }

  isStatusOk() {
    if (this.statusCheck(['success', 'in_progress'])) {
      return true;
    }
    return false;
  }

  isStatusError() {
    if (this.statusCheck(['error'])) {
      return true;
    }
    return false;
  }

  render() {
    const { getMessage } = this.props;

    const statusText = getMessage('app.ad_sync.status.sync_status');
    const lastSyncText = getMessage('app.ad_sync.status.last_synced');
    const numberOfConflictsText = getMessage('app.ad_sync.status.number_of_conflicts');
    const successText = getMessage('app.ad_sync.status.success');
    const pausedText = getMessage('app.ad_sync.status.paused');
    const errorText = getMessage('app.ad_sync.status.error');
    const scheduledText = getMessage('app.ad_sync.status.scheduled');
    const inProgressText = getMessage('app.ad_sync.status.in_progress');

    let lastSyncDate;
    if (this.props.statusData.status.lastSyncDate) {
      lastSyncDate = this.props.statusData.status.lastSyncDate;
    } else {
      lastSyncDate = this.statusCheck(['in_progress']) ?
        inProgressText : scheduledText;
    }

    let statusClass = '';
    if (this.isStatusOk()) {
      statusClass = 'success';
    }
    if (this.isStatusError()) {
      statusClass = 'error';
    }

    let syncConflictClass = '';
    if (this.props.statusData.syncConflicts.number > 0) {
      syncConflictClass = 'error';
    }

    let statusBody;
    if (this.statusCheck(['success', 'in_progress'])) {
      statusBody = <div><RetinaImage image={SuccessImage} /> {successText} </div>;
    } else if (this.statusCheck(['paused'])) {
      statusBody = <div><RetinaImage image={PausedImage} /> {pausedText} </div>;
    } else {
      statusBody = <div><RetinaImage image={ErrorImage} /> {errorText} </div>;
    }

    return (
      <div className={style['status-area']}>
        <div className={style['status-cell']}>
          <div className={style['status-head']}> {statusText} </div>
          <div className={`${style['status-body']} ${style[statusClass]}`}>
            {statusBody}
          </div>
        </div>
        <div className={style['status-cell']}>
          <div className={style['status-head']}> {lastSyncText} </div>
          <div className={`${style['status-body']} ${style['last-sync']}`}> {lastSyncDate} </div>
        </div>
        <div className={style['status-cell']}>
          <div className={style['status-head']}> {numberOfConflictsText} </div>
          <div className={`${style['status-body']} ${style[syncConflictClass]}`}>
            {this.props.statusData.syncConflicts.number}
          </div>
        </div>
      </div>
    );
  }
}
