import { difference } from 'lodash';

import naturalCmp from 'natural-compare-lite';
import { AUTHENTICATION } from '../../../../../../config/app.json';

function hashKeys() {
  return AUTHENTICATION.USER_PAYLOAD.HASH_KEYS;
}

function rolesToRemove() {
  return AUTHENTICATION.USER_PAYLOAD.ROLES_TO_REMOVE;
}

function byDowncase(a: string, b: string) {
  return naturalCmp(a.toLowerCase(), b.toLowerCase());
}

export default function createUserPayloadString(payload: any) {
  return hashKeys().filter(key => payload[key]).map((key) => {
    switch (key) {
      case 'roles': {
        const roles = difference(payload[key], rolesToRemove());

        return `${key}${roles.sort(byDowncase).join('')}`;
      }
      default:
        return `${key}${payload[key]}`;
    }
  }).join('');
}
