/* eslint-disable filenames/match-regex */

import { SearchResults } from "global/types";

// deconstructed component no default export OK
/* eslint-disable import/prefer-default-export */
type Args = {
  searchResults: SearchResults
  searchId: string
}

export function shouldShowTable({ searchResults, searchId }: Args) {
  if (
    searchResults &&
    searchResults.tableConfigs &&
    searchResults.tableConfigs[searchId] &&
    searchResults.tableConfigs[searchId].tableHelper
  ) {
    return true;
  }

  return false;
}
