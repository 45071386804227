import { uniq, includes } from 'lodash';

import { FORM_FIELDS } from 'v-c/Search/SimpleSearch';

import parseSearchStringValue from './ParseSearchStringValue';

import removeQuoteWrapsIfExists from './removeQuoteWrapsIfExists';
import replaceWildcards from './replaceWildcards';
import { HighlightValues } from './collectHighlightKeywords';
import { SearchList } from 'global/types';
import SearchLists from 'features/Settings/SearchLists.proxy';
const FIELDS = {
  keywords: ['subject', 'body', 'attachment_body', 'attachment_name'],
  participants: ['to'],
  from: ['author'],
};

const FIELDS_FOR_HIGHLIGHT = [FORM_FIELDS.KEYWORDS, FORM_FIELDS.SENDER, FORM_FIELDS.PARTICIPANTS];

export default function PrepareSimpleSearchForHighlighting(
  formValues,
  searchLists = [],
  forAssetView = false,
) {
  let keywords: any = [];
  const isKeywordExact = formValues['keywords-exact']?.query;
  const criteria: HighlightValues = {
    subject: [],
    author: [],
    body: [],
    attachment_name: [],
    attachment_body: [],
    to: [],
  };


  const prepareTextField = (query: string, field: string) => (
    parseSearchStringValue(query)
      .filter(str2 => str2.length)
      .map(str => removeQuoteWrapsIfExists(str))
      .map(str => replaceWildcards(str, field === FORM_FIELDS.KEYWORDS && isKeywordExact))
  );


  if (!formValues) {
    return criteria;
  }

  const prepareSearchListValues = (formSearchLists: SearchLists[], field: string) =>
    formSearchLists.reduce((all: SearchList[], { id }: any) => {
      const foundSearchList = (
        searchLists.find((searchList: SearchList) => searchList.id === id) || {}
      ).items;
      return [
        ...all,
        ...foundSearchList.reduce(
          (allItems: SearchList[], item: SearchList) => [
            ...allItems,
            ...item
              .split(/[ ,]+/)
              .map((subString: string) => subString.replace('"', ''))
              .filter((subString: string) => subString.length)
              .map((str: string) => replaceWildcards(str, field === FORM_FIELDS.KEYWORDS && isKeywordExact)),
          ], [],
        ),
      ];
    }, []);

  Object.keys(formValues).forEach((field) => {
    if (includes(FIELDS_FOR_HIGHLIGHT, field)) {
      const fieldQuery = formValues[field].query;
      const fieldItems = formValues[field].items;

      const fields = FIELDS[field];
      fields.forEach((f: string) => {
        criteria[f] = uniq([
          ...(fieldQuery ? prepareTextField(String(fieldQuery), field) : []),
          ...(fieldItems ? prepareSearchListValues(fieldItems, field) : []),
        ]);
      });
    }
  });
  if (forAssetView) {
    Object.keys(criteria).forEach((criterion) => {
      keywords = keywords.concat(criteria[criterion]);
    });
    return keywords;
  }

  return criteria;
}
