import localStore from 'local-store';
import { isBoolean } from 'lodash';
import {
  datesConfig,
  setTimezone as utilSetTimezone,
} from 'component-utilities/dates/dates';

import setFavicon from 'v-c/Login/lib/favicon';

import reduxStore, { Action } from '../reduxStore';
import getCurrentSiteName from '../lib/getCurrentSiteName';
import PAGES from '../constants/Pages';
import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';
import {
  showFailureFlash,
} from '../state_managers/FlashMessagesStateManager';
import { checkIfAccountIsDisabledOrSuspended } from '../lib/domainInfo';

const VALID_FLASH_VIEWS = [PAGES.LOGIN];
const appLocalStore = localStore();

export const RESET = 'domainInfo/reset';
export const DID_RETRIEVE_DOMAIN_INFO = 'domainInfo/didRetrieveDomainInfo';
export const DOMAIN_INFO_NOT_FOUND = 'domainInfo/domainInfoNotFound';
export const DID_SET_TIMEZONE = 'domainInfo/didSetTimezone';

interface DomainInfoState {
  siteName: string,
  accountId: string,
  timezone: string,
  enabledLoginProviders: any,
  termsUrl: string,
  loginLogoPath: string,
  isLoaded: boolean,
  error: any,
  supportEmailEnabled: boolean,
  termsUrlEnabled: boolean,
  ssoSettingsEnabled: boolean,
  adSyncSettingsEnabled: boolean,
  o365SettingsEnabled: boolean,
  pop3SettingsEnabled: boolean,
  retentionPolicy: object
}

interface DomainInfoShowData {
  supportEmailEnabled: boolean,
  termsUrlEnabled: boolean,
  faviconPath: string,
  siteTitle: string,
  status: string,
  isLoaded: boolean,
  accountId: string,
  timezone: string
}

interface DomainInfoShow {
  data: DomainInfoShowData,
  status: number
}

export const INITIAL_STATE: DomainInfoState = {
  siteName: getCurrentSiteName(),
  accountId: appLocalStore.get('accountId'),
  timezone: datesConfig.DEFAULT_TIMEZONE,
  enabledLoginProviders: null,
  termsUrl: '',
  loginLogoPath: '',
  isLoaded: false,
  error: null,
  supportEmailEnabled: true,
  termsUrlEnabled: true,
  ssoSettingsEnabled: true,
  adSyncSettingsEnabled: true,
  o365SettingsEnabled: true,
  pop3SettingsEnabled: true,
  retentionPolicy: {},
};

export function reset() {
  return { type: RESET };
}

export function didSetTimezone(timezone: string) {
  return {
    type: DID_SET_TIMEZONE,
    payload: timezone,
  };
}

export function setTimezone(timezone: string) {
  return (dispatch: any) => {
    utilSetTimezone(timezone);
    dispatch(didSetTimezone(timezone));
  };
}

export function didRetrieveDomainInfo(domainInfo: object) {
  return {
    type: DID_RETRIEVE_DOMAIN_INFO,
    payload: domainInfo,
  };
}

export function domainInfoRetrieved(domainInfo: DomainInfoShowData) {
  return (dispatch: any) => {
    const modifiedDomainInfo = { ...domainInfo };

    modifiedDomainInfo.isLoaded = true;

    if (checkIfAccountIsDisabledOrSuspended(domainInfo.status)) {
      dispatch(showFailureFlash('login.account_status', VALID_FLASH_VIEWS));
    }

    appLocalStore.set('accountId', domainInfo.accountId);
    dispatch(setTimezone(domainInfo.timezone));

    dispatch(didRetrieveDomainInfo(modifiedDomainInfo));
  };
}

export function domainInfoNotFound(message: string) {
  return {
    type: DOMAIN_INFO_NOT_FOUND,
    payload: message,
  };
}

export function load(subdomain: string, redirectTo: string) {
  const options: { params: { headers?: object } } = { params: {} };

  // once interceptors are activated, there will need to be
  // an additional conditional related to the url
  if (subdomain) {
    options.params.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Subdomain: subdomain,
    };
  }

  return function* doLoad() {
    try {
      const urlParams: { redirect_to?: string } = {}
      if (redirectTo) {
        urlParams.redirect_to = redirectTo
      }
      const result: DomainInfoShow = yield apiReqPromise(jsApi.domainInfoShow, {
        ...options,
        urlParams,
      });

      const { data, status } = result;

      if (status === 200) {
        const modifiedData = {
          ...data,
          supportEmailEnabled: isBoolean(data.supportEmailEnabled)
            ? data.supportEmailEnabled
            : true,
          termsUrlEnabled: isBoolean(data.termsUrlEnabled)
            ? data.termsUrlEnabled
            : true,
        };
        const isAccountNotDeleted = data.status !== 'deleted';
        const isNotPhantomJS =
          window.navigator.userAgent.indexOf('PhantomJS') === -1;

        setFavicon(data.faviconPath);
        document.title = data.siteTitle;

        reduxStore.dispatch(domainInfoRetrieved(modifiedData));
      }
    } catch (e: any) {
      console.error(e);

      reduxStore.dispatch(domainInfoNotFound(e.message));
    }
  };
}

export default function DomainInfoStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case RESET:
      return { ...INITIAL_STATE };
    case DID_RETRIEVE_DOMAIN_INFO:
      return {
        ...state,
        ...payload,
      };
    case DOMAIN_INFO_NOT_FOUND:
      return {
        ...state,
        error: payload,
        isLoaded: true,
      };
    case DID_SET_TIMEZONE:
      return {
        ...state,
        timezone: payload,
      };
    default:
      return state;
  }
}
