/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { snakeCase } from 'lodash';

import { TextLabel } from 'view-components/components/Widgets/Components';

import {
  EyeGrayIconBig,
  EyeRedIconBig,
  CircleGrayBigIcon,
  CircleGreenWithCheckmarkIconBig,
} from 'view-components/components/Icons';

import LoadingIndicator from 'view-components/components/LoadingIndicator';
import { FormattedMessage } from 'component-utilities/intl';

import contextTreeHelper from '../../lib/contextTreeHelper';

import { stateLogic, viewLogic, Props } from './ReviewTabDeconstructed';

import {
  getAssociatedReviewStatus,
  updateAssociatedReviewStatus,
} from '../../state_managers/AssetStateManager';

import styles from './ReviewTab.scss';
import { ReduxState } from 'global/types';

const mapStateToProps = ({ currentSavedSearch, asset }: ReduxState) => ({
  searchId: currentSavedSearch.search.id || '',
  isSampledSearch: parseFloat(currentSavedSearch.search.samplePercentage) > 0,
  assetId: asset.id,
});

const mapActionsToProps = {
  getAssociatedReviewStatus,
  updateAssociatedReviewStatus,
};

@connect(
  mapStateToProps,
  mapActionsToProps,
)
@contextTreeHelper
@stateLogic()
export default class ReviewTab extends Component<Props> {
  static propTypes = {
    setStatus: PropTypes.func.isRequired,
    contextTree: PropTypes.shape({
      REVIEW_STATUSES: PropTypes.object.isRequired,
    }).isRequired,
  };

  get statusImages() {
    const {
      REVIEW_STATUSES: { NEEDS_REVIEW, REVIEWED },
    } = this.props.contextTree;

    return {
      [NEEDS_REVIEW]: {
        selected: EyeRedIconBig,
        unselected: EyeGrayIconBig,
      },
      [REVIEWED]: {
        selected: CircleGreenWithCheckmarkIconBig,
        unselected: CircleGrayBigIcon,
      },
    };
  }

  render() {
    const { isAssetUpdating, reviewBoxesProps } = viewLogic(this.props);

    // TODO: explore creating proper child component for this.
    return (
      <div className="accordion-view review">
        {reviewBoxesProps.map(
          ({ status, isActive, imageStatus, kebabCaseStatus }) => (
            <div
              key={status}
              data-status-box-active={isActive}
              data-action={`set-status-${kebabCaseStatus}`}
              className={styles[`${kebabCaseStatus}-status`]}
              onClick={this.props.setStatus(status)}
              role="button"
              tabIndex="-1"
            >
              <div className={styles['status-box-image']}>
                {React.createElement(this.statusImages[status][imageStatus])}
              </div>
              <TextLabel
                className={styles[`${kebabCaseStatus}-text`]}
                data-status-text-active={isActive}
              >
                <FormattedMessage
                  id={`app.rendered_assets.review.${snakeCase(status)}`}
                />
              </TextLabel>
            </div>
          ),
        )}

        {isAssetUpdating && (
          <data>
            <div className={styles['processing-mask-layer']} />
            <LoadingIndicator orientation="middle" text={''} isLoading />
          </data>
        )}
      </div>
    );
  }
}

ReviewTab.displayName = 'AssetView/ReviewTab';
