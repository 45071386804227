import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import ChangePasswordTemplate from 'v-c/Templates/ChangePassword/ChangePassword';

import Layout from '../../global/components/Layout';
import routeLeaveHook from '../../global/lib/routeLeaveHook';
import getUserFullName from '../../global/lib/getUserFullName';

import { updateUser } from '../../global/state_managers/UserStateManager';

const mapStateToProps = ({ authentication }: any) => ({
  loggedInAs: authentication.loggedInAs,
});

const mapActionsToProps = {
  updateUser,
};

type Props = {
  updateUser: Function,
  loggedInAs: {
    userId: string,
    username: string
  }
}

function ChangeUserPasswordComponent(props: Props) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isButtonDisabled, setButtonIsDisabledTo] = useState(false);

  function onChangePassword() {
    setButtonIsDisabledTo(true);

    props.updateUser(props.loggedInAs.userId, { password });
  }

  const templateProps = {
    user: {
      fullName: getUserFullName(props.loggedInAs),
      username: props.loggedInAs.username,
    },
    passwordConfig: {
      value: password,
      onChange: ({ target }: any) => {
        setPassword(target.value);
      },
    },
    confirmPasswordConfig: {
      value: confirmPassword,
      onChange: ({ target }: any) => {
        setConfirmPassword(target.value);
      },
    },
    onSave: onChangePassword,
    isButtonDisabled,
  };

  return (
    <Layout section="edit-user" staticView>
      <ChangePasswordTemplate {...templateProps} />;
    </Layout>
  );
}

ChangeUserPasswordComponent.propTypes = {
  loggedInAs: PropTypes.shape({
    userId: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  updateUser: PropTypes.func.isRequired,
};

const ChangeUserPassword = routeLeaveHook(
  connect(
    mapStateToProps,
    mapActionsToProps,
  )(ChangeUserPasswordComponent),
);

export default ChangeUserPassword;
