import { isEqual } from 'lodash';

import reduxStore, { PromiseObject } from '../../reduxStore';

import { LOAD_TYPES } from '../../lib/withSerialResultsLoader';
import { apiReqPromise } from '../../lib/apiRequest';
import jsApi from '../../lib/jsApi';

import { pushToQueue } from '../SerialResultsLoaderStateManager';
import { sortTable } from './tableConfig';
import {
  showFailureFlash,
} from '../FlashMessagesStateManager';
import { PAGES } from '../../components/FlashMessages';
import {
  searchResultsState,
  newResultsLoaded,
  RESET_ALREADY_REQUESTED_PACKS,
  UPDATE_ALREADY_REQUESTED_PACKS,
  LOAD_MORE_RESULTS_START,
  LOAD_MORE_RESULTS_FINISHED,
  LOAD_MORE_RESULTS_FAILED,
  RESET_SORT_OPTIONS,
} from '../SearchResultsStateManager';

export function loadMoreResults(...args: any) {
  return function* doLoadMoreResults() {
    // Because of the Poltergeist timing issues
    //   we have to disable the pack filtering during the tests
    if (window.simulatePackReset) {
      yield { type: RESET_ALREADY_REQUESTED_PACKS };
    }

    const { alreadyRequestedPacks, stream } = searchResultsState();

    let modifiedArgs = args;

    const findPack = pack => isEqual(pack, args);

    // In very rare cases the old queue system request the initial packages
    //  immediately after the sort options updated but before `onSortCompleted`
    //  action can be performed.
    // TODO: retest this issue after `withSerialResultsLoader` HOC implemented
    //       to all of the search results type tables.
    if (args[0] === 0 && alreadyRequestedPacks.length === 1) {
      yield { type: RESET_ALREADY_REQUESTED_PACKS };

      // When the first requested pack's offset value is other than zero set it to zero
      //  and force reset the scroll view to top
    } else if (!alreadyRequestedPacks.length && args[0] !== 0) {
      modifiedArgs = [0, 50];
      $('.infinite-scroll-inner').scrollTop(0);
    }

    if (!alreadyRequestedPacks.find(findPack) && stream.id) {
      yield {
        type: UPDATE_ALREADY_REQUESTED_PACKS,
        payload: {
          alreadyRequestedPacks: [...alreadyRequestedPacks, modifiedArgs],
        },
      };

      reduxStore.dispatch(
        pushToQueue(LOAD_TYPES.SEARCH_RESULTS_LOAD_MORE, ...modifiedArgs),
      );
    }
  };
}

export function performLoadMoreResults(params: any, id: string) {
  return function* doPerformLoadMoreResults() {
    yield { type: LOAD_MORE_RESULTS_START };

    try {
      const result: PromiseObject = yield apiReqPromise(
        jsApi.searchResultsStreamsSearchResultsIndex,
        {
          params: { data: { ...params } },
          urlParams: { search_results_stream_id: id },
        },
      );

      yield newResultsLoaded(params.stream_params.offset, result.data);
      yield { type: LOAD_MORE_RESULTS_FINISHED };
    } catch (e) {
      console.error(e);

      yield { type: LOAD_MORE_RESULTS_FAILED };
      if (e.status === 400) {
        if (reduxStore.getState().searchResults.sortOptions.sort === 'comment-count') {
          yield { type: RESET_SORT_OPTIONS };
          const { stream } = searchResultsState();

          yield showFailureFlash('search_result.sort', [PAGES.SEARCH_RESULTS]);
          yield sortTable(stream.id, {
            sort: 'date',
            'sort-order': 'desc',
          });
          yield performLoadMoreResults(params, id);
        }
      }
    }
  };
}