import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isEmpty, includes } from 'lodash';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';

import multiSelect from 'component-utilities/multiSelect/MultiSelect';

import routeLeaveHook from '../../global/lib/routeLeaveHook';
import fixedHeightContent from '../../global/lib/fixedHeightContent';
import Loading from '../../global/components/Loading';

import IfFeature from '../../global/components/IfFeature';

import { updateDropdown } from '../../global/state_managers/ExportTableUIStateManager';
import onDemandPurgeEnabledForAccount from '../../global/lib/onDemandPurgeEnabledForAccount';


import withSerialResultsLoader, {
  LOAD_TYPES,
} from '../../global/lib/withSerialResultsLoader';
import LoadWhenReady from '../../global/lib/LoadWhenReady';
import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';
import { openSavedSearchStream } from '../../global/lib/searchResultsStreamManager';
import {
  searchRelatedJobs,
  resetReviewJob,
  resetHoldReasonsjob,
  isHoldReasonsJobRunning,
} from '../../global/state_managers/BulkActionsStateManager';
import { loadSearchLists } from '../../global/state_managers/SearchListsStateManager';
import { resetAssetStore } from '../../global/state_managers/AssetStateManager';
import {
  resetFlash,
  showGeneralFlash,
  showSuccessFlash,
  showFailureFlash,
} from '../../global/state_managers/FlashMessagesStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';
import {
  changeAppView,
  resetResults,
} from '../../global/state_managers/SearchResultsStateManager';
import { initTableConfig } from '../../global/state_managers/SearchResults/tableConfig';
import { resetStream } from '../../global/state_managers/SearchResults/searchResultsStream';
import {
  loadSavedSearch,
  resetCurrentSavedSearch,
} from '../../global/state_managers/CurrentSavedSearchStateManager';

import {
  stateLogic,
  isReady,
  isSplitView,
} from './SearchResults/SearchResultsDeconstructed';
import { Params, SearchResults as SearchResultsType, CurrentSavedSearch, MultiSelect } from 'global/types';

const SearchResultsSplitView = Loadable({
  loader: () => import('./SearchResults/SearchResultsSplitView'),
  loading() {
    return <Loading />;
  },
});

const Loaded = Loadable({
  loader: () => import('./SearchResults/Loaded'),
  loading() {
    return <Loading />;
  },
});

const mapStateToProps = ({
  currentSavedSearch,
  bulkActions,
  unsavedSearch,
  searchResults,
}: any) => ({
  bulkActions,
  currentSavedSearch,
  filteredFormValues: unsavedSearch.data.filteredFormValues,
  filteredQuery: unsavedSearch.data.query,
  searchResults,
  returnCount: searchResults.returnCount,
  sampleSearchRunning: searchResults.sampleSearchRunning,
});

const mapActionsToProps = {
  loadSavedSearch,
  resetCurrentSavedSearch,
  resetFlash,
  showSuccessFlash,
  showFailureFlash,
  showGeneralFlash,
  searchRelatedJobs,
  resetReviewJob,
  resetHoldReasonsjob,
  updateDropdown,
  loadSearchLists,
  resetAssetStore,
  setBreadcrumbForSearchesResultsPage: breadcrumbsActions.searchesResultsPage,
  changeAppView,
  initTableConfig,
  resetResults,
  resetStream,
};

const VALID_FLASH_VIEWS = [PAGES.SEARCH_RESULTS];

type Props = {
  params: Params,
  filteredFormValues: any,
  filteredQuery: any,
  triedLoadingContext: Boolean,
  searchResults: SearchResultsType,
  onChangeAppView: Function,
  sampleSearchRunning: any[],
  showSuccessFlash: Function,
  showGeneralFlash: Function,
  resetFlash: Function,
  currentSavedSearch: CurrentSavedSearch,
  resetReviewJob: Function,
  updateDropdown: Function,
  checkBulkOperationStatuses: Function,
  loadSavedSearch: Function,
  resetCurrentSavedSearch: Function,
  location: {
    query: {},
  },
  setRootComponentLeaveValidator: Function,
  registerUnmountCleanupMethod: Function,
  loadSearchLists: Function,
  resetAssetStore: Function,
  setBreadcrumbForSearchesResultsPage: Function,
  changeAppView: Function,
  initTableConfig: Function,
  resetStream: Function,
  multiSelect: MultiSelect,
}

@routeLeaveHook
@multiSelect
@flashConnect(PAGES.SEARCH_RESULTS, 'SearchResults/componentWillUnmount')
@withSerialResultsLoader(LOAD_TYPES.SEARCH_RESULTS_LOAD_MORE)
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@fixedHeightContent
@stateLogic({
  searchRelatedJobs,
  isHoldReasonsJobRunning,
  VALID_FLASH_VIEWS,
  })
export default class SearchResults extends Component<Props, { shouldRender: Boolean }> {
  static propTypes = {
    params: PropTypes.object.isRequired,
    filteredFormValues: PropTypes.any,
    filteredQuery: PropTypes.any,
    triedLoadingContext: PropTypes.bool.isRequired,
    searchResults: PropTypes.object.isRequired,
    onChangeAppView: PropTypes.func,
    sampleSearchRunning: PropTypes.array,
    showSuccessFlash: PropTypes.func.isRequired,
    showGeneralFlash: PropTypes.func.isRequired,
    resetFlash: PropTypes.func.isRequired,
    currentSavedSearch: PropTypes.object.isRequired,
    resetReviewJob: PropTypes.func.isRequired,
    updateDropdown: PropTypes.func.isRequired,
    checkBulkOperationStatuses: PropTypes.func.isRequired,
    loadSavedSearch: PropTypes.func.isRequired,
    resetCurrentSavedSearch: PropTypes.func.isRequired,
    location: PropTypes.shape({
      query: PropTypes.object,
    }).isRequired,
    setRootComponentLeaveValidator: PropTypes.func.isRequired,
    registerUnmountCleanupMethod: PropTypes.func.isRequired,
    loadSearchLists: PropTypes.func.isRequired,
    resetAssetStore: PropTypes.func.isRequired,
    setBreadcrumbForSearchesResultsPage: PropTypes.func.isRequired,
    changeAppView: PropTypes.func.isRequired,
    initTableConfig: PropTypes.func.isRequired,
    resetStream: PropTypes.func.isRequired,
    multiSelect: PropTypes.shape({
      reset: PropTypes.func.isRequired,
      setResultCounts: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    filteredFormValues: null,
    filteredQuery: null,
    onChangeAppView: () => {},
    sampleSearchRunning: null,
  };

  static displayName = 'Searchresults.proxy';

  static getDerivedStateFromProps(nextProps: Props) {
    if (nextProps.searchResults.isLoading) {
      return { shouldRender: true };
    }

    return null;
  }

  constructor(props: Props) {
    super(props);

    this.state = { shouldRender: false };

    const {
      params: { id: searchId },
      filteredFormValues,
      filteredQuery,
    } = props;

    this.isRandomizationBanner = false;

    props.setBreadcrumbForSearchesResultsPage();
    props.initTableConfig(searchId);
    props.loadSavedSearch({ searchId });
    props.multiSelect.reset();
    openSavedSearchStream(
      searchId,
      isEmpty(filteredFormValues) ? null : filteredQuery,
    );

    if (IfFeature.isDisabled('results-split-view')) {
      this.props.changeAppView(props.searchResults.appViews.SINGLE);
    }

    props.setRootComponentLeaveValidator(this.routerWillLeave);
    props.registerUnmountCleanupMethod(() => {
      props.resetCurrentSavedSearch();
      props.resetStream();

      props.updateDropdown('reset');
      props.multiSelect.reset();
    });
  }

  componentDidUpdate(prevProps: Props) {
    this.setMultiSelectResultCounts(prevProps);

    const {
      params: { id: searchId },
    } = this.props;
    const previouslyRan = includes(prevProps.sampleSearchRunning, searchId);
    const sampleSearchRunning = includes(
      this.props.sampleSearchRunning,
      searchId,
    );

    if (this.isRawSearch) {
      if (!this.isMigratedSearchBannerVisible) {
        this.isMigratedSearchBannerVisible = true;
        this.props.showGeneralFlash(
          'migrated_search.information',
          VALID_FLASH_VIEWS,
        );
      }
    } else {
      if (sampleSearchRunning && !this.isRandomizationBanner) {
        this.isRandomizationBanner = true;
        this.props.showGeneralFlash('random_search.running', VALID_FLASH_VIEWS);
      } else if (previouslyRan && !sampleSearchRunning) {
        this.props.resetFlash({
          from: 'SearchResults/componentDidUpdate',
        });
        this.props.showSuccessFlash('random_search.create', VALID_FLASH_VIEWS);
      }

      this.props.checkBulkOperationStatuses(searchId);
    }
  }

  get isReadyToLoadContent() {
    return isReady(this.checkProps) && this.props.searchResults.streamQuery;
  }

  get checkProps() {
    return {
      ...this.props,
      triedLoadingContext:
        this.props.triedLoadingContext ||
        !isEmpty(this.props.filteredFormValues),
      searchId: this.props.params.id,
      isRawSearch: this.isRawSearch,
    };
  }

  get renderContent() {
    if (isSplitView(this.props)) {
      return (
        <SearchResultsSplitView
          {...this.props}
          userCanDeleteAssets={onDemandPurgeEnabledForAccount}
          withMultipleSelection
        />
      );
    }

    return (
      <div data-view="results-single-view">
        <Loaded
          {...this.checkProps}
          userCanDeleteAssets={onDemandPurgeEnabledForAccount}
        />
      </div>
    );
  }

  get isRawSearch() {
    return this.props.currentSavedSearch.search.raw;
  }

  setMultiSelectResultCounts = (prevProps: Props) => {
    const { searchResults } = this.props;

    if (!searchResults.isLoading && prevProps.searchResults.isLoading) {
      this.props.multiSelect.setResultCounts(searchResults.returnCount);
    }
  };

  routerWillLeave = (nextRoute: any) => {
    if (!isSplitView(this.props)) {
      this.props.resetAssetStore();
    }

    if (nextRoute.pathname.includes(`results/${this.props.params.id}`)) {
      return { withoutReset: true };
    }

    return true;
  };

  render() {
    return (
      <data>
        <FlashMessages />
        <LoadWhenReady
          readyStates={[
            this.state.shouldRender,
            isReady(this.checkProps),
            this.props.searchResults.stream.id,
          ]}
        >
          {this.renderContent}
        </LoadWhenReady>
      </data>
    );
  }
}
