import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Link } from 'react-router';
import withMessages from 'component-utilities/intl';

import { PrimaryButton, SecondaryButton } from 'v-c/Buttons/Components';

import validateSettingsAccess from './components/validateSettingsAccess';

import Layout from '../../global/components/Layout';
import VerticalHeightManager from '../../global/components/VerticalHeightManager';
import Breadcrumb from '../../global/components/Breadcrumb';
import HeadlineLabel from '../../global/components/HeadlineLabel';
import AttributeMappings from './ADSync/AttributeMappings';
import FlashMessages from '../../global/components/FlashMessages';
import { jsUi } from '../../global/lib/jsUi';

import { updateAttributeMappings } from '../../global/state_managers/ADSyncStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import page from './ADSync/AttributeMappings.scss';
import { Pairs } from 'global/types';

const mapStateToProps = ({ adSync }: any) => ({
  adSync,
  pairs: adSync.pairs,
  attributesLoading: adSync.attributesLoading,
});

const mapActionsToProps = {
  updateAttributeMappings,
  setBreadcrumbForSettingsADSyncAttributeMappingsPage:
    breadcrumbsActions.settingsAdSyncAttributeMappingsPage,
};

type Props = {
  setBreadcrumbForSettingsADSyncAttributeMappingsPage: Function,
  updateAttributeMappings: Function,
  pairs: Pairs,
  attributesLoading: Boolean,
  adSync: {},
  getMessage: Function
}

@withMessages
@validateSettingsAccess('settings.ad_sync')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class ADSyncAttributeMappings extends Component<Props> {
  static propTypes = {
    pairs: PropTypes.object,
    attributesLoading: PropTypes.bool,
    adSync: PropTypes.object,
    getMessage: PropTypes.func.isRequired,
    updateAttributeMappings: PropTypes.func.isRequired,
    setBreadcrumbForSettingsADSyncAttributeMappingsPage:
      PropTypes.func.isRequired,
  };

  static defaultProps = {
    manageButtonText: 'button.manage',
    pairs: {},
    attributesLoading: false,
    adSync: {},
  };

  constructor(props: Props) {
    super(props);

    props.setBreadcrumbForSettingsADSyncAttributeMappingsPage();
  }

  updateHandler() {
    this.props.updateAttributeMappings({
      attributeMappings: this.props.pairs.getPairs(),
    });
  }

  render() {
    const { getMessage } = this.props;

    return (
      <Layout section="ad-sync">
        <VerticalHeightManager className="content-wrapper">
          <VerticalHeightManager.Section>
            <header>
              <FlashMessages />
              <Breadcrumb {...this.props} />
              <HeadlineLabel
                text={getMessage('app.breadcrumb.attribute_mappings')}
              />
            </header>
          </VerticalHeightManager.Section>
          <VerticalHeightManager.Section maximize>
            <div className={page['mappings-page-content']}>
              <AttributeMappings
                {...this.props.adSync}
                getMessage={getMessage}
              />
            </div>
          </VerticalHeightManager.Section>
          <VerticalHeightManager.Section>
            {!this.props.attributesLoading && (
              <div className={page['action-buttons']}>
                <PrimaryButton
                  onClick={() => this.updateHandler()}
                  extraClassName={page.update}
                >
                  {getMessage('app.button.update')}
                </PrimaryButton>
                <Link data-action="cancel" to={jsUi.settingsAdSyncIndex.path()}>
                  <SecondaryButton>
                    {getMessage('app.button.cancel')}
                  </SecondaryButton>
                </Link>
              </div>
            )}
          </VerticalHeightManager.Section>
        </VerticalHeightManager>
      </Layout>
    );
  }
}
