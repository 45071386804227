import React from 'react';
import { isObject } from 'lodash';
import reduxStore from '../reduxStore';

import { showFlash } from '../state_managers/FlashMessagesStateManager';

import developerInterface from './developerInterface';

function componentDidMount() {
  developerInterface({
    showFlash: (which = null) => {
      if (which) {
        reduxStore.dispatch(showFlash(which));
      } else {
        const keys = [];

        const process = (obj: any, base = []) => {
          Object.keys(obj).forEach((key: string) => {
            const value = obj[key];

            if (isObject(value)) {
              process(value, base.concat([key]));
            } else {
              keys.push(base.concat([key]).join('.'));
            }
          });
        };

        process(this.context.messages.app.flash);
      }
    },
  });
}

const DeveloperShowFlash = { componentDidMount };

function DeveloperShowFlashFn(Component: any) {
  return class DeveloperShowFlashComponent extends React.Component {
    componentDidMount() {
      componentDidMount.call(this);
    }

    render() {
      return <Component {...this.props} />;
    }
  };
}

export default DeveloperShowFlash;
export { DeveloperShowFlashFn as WithDeveloperShowFlash };
