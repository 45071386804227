/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { formatTZDate } from 'component-utilities/dates/dates';
import withMessages from 'component-utilities/intl';

import {
  TableRow,
  UserCell,
  TextCell,
} from 'v-c/TableView';

import getUserFullName from '../../../../global/lib/getUserFullName';
import { TableViewContent } from '../../../../global/components/TableView/TableViewContentFactory';
import { searchConflicts } from '../../../../global/state_managers/ADSyncStateManager';

const mapActionsToProps = { searchConflicts };

@withMessages
@connect(null, mapActionsToProps)
export default class TableView extends TableViewContent {
  static propTypes = {
    searchConflicts: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tableType: 'ad_sync_conflicts',
  };

  static displayName = 'SyncConflict/TableView';

  onSearchChange = (newValue: any) => {
    this.props.searchConflicts(newValue);
    this.setState({ search: newValue });
  }

  get tableHelper() {
    return this.props.tableHelper;
  }

  get list() {
    const { conflictEntries } = this.props;
    return conflictEntries;
  }

  get searchString() {
    const { searchString } = this.props;

    return searchString;
  }

  getDetailsMessage = (details: any) => {
    let message = 'No details available';
    if (details && details.message) {
      message = details.message;
    }
    return message;
  };

  get headerContent() {
    const visibleColumns: any[] = [];

    this.allColumns.filter(this.filterColumns).forEach((cellId: string, idx: number) => {
      const column = Object.assign({}, {
        key: idx,
        children: this.localizedColumnNames[cellId],
        cellId,
      });

      visibleColumns.push(<TextCell {...column} />);
    });
    return visibleColumns;
  }

  get bodyContent() {
    const { conflictEntries, getMessage } = this.props;
    const {
      CONFLICT_DATE,
      CONFLICT_USER,
      CONFLICT_OBJECT_ID,
      CONFLICT_TYPE,
      CONFLICT_DESCRIPTION,
    } = this.props.tableHelper.columns;

    // TODO: find something better to key these rows off of
    /* eslint-disable react/no-array-index-key */
    return conflictEntries.map((log: any, rowIdx: number) => {
      const { date, user, objectId, type, description } = log;
      const fullName = getUserFullName(user);
      const visibleColumns: any[] = [];

      this.allColumns.filter(this.filterColumns).forEach((cellId: string) => {
        switch (cellId) {
          case CONFLICT_DATE: {
            visibleColumns.push(
              <TextCell
                key={cellId}
                title={date}
                cellId={cellId}
                isFloatingCell
              >
                {this.highlightText(formatTZDate(date))}
              </TextCell>,
            );
            break;
          }
          case CONFLICT_USER: {
            visibleColumns.push(
              <UserCell
                key={cellId}
                title={fullName}
                cellId={cellId}
                userName={this.highlightText(fullName)}
              />,
            );
            break;
          }
          case CONFLICT_OBJECT_ID: {
            visibleColumns.push(
              <TextCell
                key={cellId}
                title={objectId}
                cellId={cellId}
              >
                {this.highlightText(objectId)}
              </TextCell>,
            );
            break;
          }
          case CONFLICT_TYPE: {
            visibleColumns.push(
              <TextCell
                key={cellId}
                cellId={cellId}
              >
                {this.highlightText(getMessage(`app.ad_sync.sync_log.log_events.${type}`))}
              </TextCell>,
            );
            break;
          }
          case CONFLICT_DESCRIPTION: {
            visibleColumns.push(
              <TextCell
                key={cellId}
                title={description}
                cellId={cellId}
              >
                {this.highlightText(description)}
              </TextCell>,
            );
            break;
          }
          default: {
            visibleColumns.push(null);
          }
        }
      });

      return (
        <TableRow
          key={rowIdx}
          index={rowIdx}
          rowId={rowIdx.toString()}
        >
          {visibleColumns}
        </TableRow>
      );
    });
  }
}
