import reduxStore, { Action } from '../reduxStore';

export const SET_VALUES: string = 'UIHelper/setValues';
export const RESET_VALUE: string = 'UIHelper/resetValue';

export const CAME_FROM_SEARCHES_INDEX: string = 'CAME_FROM_SEARCHES_INDEX';

interface UIHelperState {
  values: {
    personalArchive: boolean
  }
}

// Init
export const INITIAL_STATE = {
  values: {
    personalArchive: false,
  },
};

// helpers
export function getUIValue(value: string) {
  return reduxStore.getState().UIHelper.values[value];
}

// Actions
export function setUIValues(values: any) {
  return { type: SET_VALUES, payload: { values } };
}

export function resetUIValue(value: any) {
  return { type: RESET_VALUE, payload: { value } };
}

// Store
/* eslint-disable no-case-declarations */
export default function UIHelperStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case SET_VALUES:
      return {
        ...state,
        values: {
          ...state.values,
          ...payload.values,
        },
      };
    case RESET_VALUE:
      const modifiedValues = { ...state.values };
      delete modifiedValues[payload.value];

      return {
        ...state,
        values: { ...modifiedValues },
      };
    default:
      return state;
  }
}
