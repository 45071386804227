import { ROUTES } from '../../lib/jsUi';
import { ROLES } from '../../../../../../config/roles.json';
import Pages from '../Pages';

import {
  USER_CAN_DOWNLOAD_OR_SEND_MESSAGES,
  USER_CAN_DOWNLOAD_ATTACHMENTS,
  USER_CAN_REQUEST_EXPORT,
  USER_NEEDS_SEARCH_PERMISSION,
  USER_CAN_EDIT_RETENTION_POLICY,
} from '../../../config/constants/PageRoles';

const { EXPORTS, SEARCHES, CHANGE_PASSWORD } = Pages;
const { SEARCHES_INDEX }: any = ROUTES || {};

export default {
  name: ROLES.EXPORTER,
  tag: 'E',
  homePage: SEARCHES_INDEX,
  visibleMenus: {
    search: [SEARCHES],
    nav: [EXPORTS],
    sub: [],
    other: [CHANGE_PASSWORD],
  },
  enabledPageRoles: [
    USER_CAN_DOWNLOAD_OR_SEND_MESSAGES,
    USER_CAN_DOWNLOAD_ATTACHMENTS,
    USER_CAN_REQUEST_EXPORT,
    USER_NEEDS_SEARCH_PERMISSION,
    USER_CAN_EDIT_RETENTION_POLICY,
  ],
};
