import archiveAdministrator from './UserRoles/archiveAdministrator';
import complianceOfficer from './UserRoles/complianceOfficer';
import searchAdministrator from './UserRoles/searchAdministrator';
import exporter from './UserRoles/exporter';
import reviewer from './UserRoles/reviewer';
import endUser from './UserRoles/endUser';
import adminOnly from './UserRoles/adminOnly';

import rolesConfig from '../../../../../config/roles.json';

const Roles = {
  Roles: {
    archiveAdministrator,
    complianceOfficer,
    searchAdministrator,
    adminOnly,
    exporter,
    reviewer,
    endUser,
  },
  rolesPriority: rolesConfig.PRIORITY,
};

export default Roles;
