import domainInfo from './domainInfo';
import IfFeature from '../components/IfFeature';

import { isPageRoleEnabled, pageRoles } from './userRights';

export default function onDemandPurgeEnabledForAccount() {
  return (
    domainInfo().onDemandPurgeEnabled &&
    IfFeature.isEnabled('delete_asset') &&
    isPageRoleEnabled(pageRoles.USER_CAN_DELETE_ASSETS)
  );
}
