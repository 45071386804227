/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withMessages from 'component-utilities/intl';

import LoginWrapper from 'v-c/Login/LoginWrapper';
import ForgottenPasswordRequestTemplate from 'v-c/Templates/ForgottenPasswordRequest/ForgottenPasswordRequest';

import loginLinks from '../../global/lib/loginLinks';
import LoginLogo from '../../global/lib/loginLogo';

import LoginBaseHOC from './components/LoginBase';
import ToggleZendeskHelp from './components/ToggleZendeskHelp';
import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';

import { resetPassword } from '../../global/state_managers/LoginFormStateManager';

const mapStateToProps = ({ loginForm }: any) => ({
  loginForm,
});
const mapActionsToProps = { resetPassword };

type State = {
  requestAlreadySent: Boolean
}

type Props = {
  loginForm: {
    passwordResetIsLoading: Boolean,
  },
  resetPasswordValue: string,
  accountId: string,
  isAccountDeleted: Boolean,
  siteName?: string,
  getMessage: Function,
  onValueChange: Function,
  goToLoginPage: Function,
  supportEmailEnabled: Boolean,
  termsUrlEnabled: Boolean,
  resetPassword: Function,
}

@flashConnect(PAGES.RESET_PASSWORD, 'Login/ResetPassword')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@ToggleZendeskHelp
@LoginBaseHOC({
  state: {
  resetPasswordValue: '',
  },
  })
@withMessages
export default class ResetPassword extends Component<Props, State> {
  static propTypes = {
    loginForm: PropTypes.object.isRequired,
    resetPasswordValue: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    isAccountDeleted: PropTypes.bool.isRequired,
    siteName: PropTypes.string,
    getMessage: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    goToLoginPage: PropTypes.func.isRequired,
    supportEmailEnabled: PropTypes.bool.isRequired,
    termsUrlEnabled: PropTypes.bool.isRequired,
    resetPassword: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isVerifyTokenLoading: false,
    siteName: null,
  };

  state = {
    requestAlreadySent: false,
  };

  continue = () => {
    this.props.resetPassword(this.props.accountId);

    this.setState({ requestAlreadySent: true });
  };

  renderFlashMessages = () => <FlashMessages />;

  render() {
    const { requestAlreadySent } = this.state;

    const {
      loginForm: { passwordResetIsLoading },
      resetPasswordValue,
      isAccountDeleted,
      siteName,
      supportEmailEnabled,
      termsUrlEnabled,
    } = this.props;

    const section = requestAlreadySent
      ? 'reset-password-already-sent'
      : 'reset-password';

    const templateProps = {
      userConfig: {
        value: resetPasswordValue,
        onChange: this.props.onValueChange('resetPasswordValue'),
      },
      onSubmit: this.continue,
      onCancel: this.props.goToLoginPage,
      isButtonDisabled: false,
      isProcessing: passwordResetIsLoading,
      requestAlreadySent,
    };

    return (
      <LoginWrapper
        {...loginLinks()}
        isAccountDeleted={isAccountDeleted}
        siteName={siteName}
        supportEmailEnabled={supportEmailEnabled}
        termsUrlEnabled={termsUrlEnabled}
        viewLogo={<LoginLogo />}
        isPcc={false}
        renderFlashMessages={this.renderFlashMessages()}
        section={section}
      >
        <ForgottenPasswordRequestTemplate {...templateProps} />
      </LoginWrapper>
    );
  }
}
