import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

type Props = {
  children: ReactNode,
  height: any,
  navHeight: any,
  staticView: boolean,
}

export default function MainBodyContainer(props: Props) {
  const { children, height, navHeight: marginTop, staticView } = props;

  const styles = {
    height: staticView ? 'auto' : height,
    marginTop,
    position: staticView ? 'static' : 'relative',
  };

  return (
    <section style={styles} className="contents">
      { children }
    </section>
  );
}

MainBodyContainer.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.any,
  navHeight: PropTypes.any,
  staticView: PropTypes.bool,
};

MainBodyContainer.defaultProps = {
  height: null,
  navHeight: null,
  staticView: null,
};
