import { Action } from '../reduxStore';

export const RESET: string = 'holdsPanel/reset';
export const UPDATE: string = 'holdsPanel/update';

interface HoldPanelState {
  selectedReasons: any[]
}

// init
export const INITIAL_STATE: HoldPanelState = {
  selectedReasons: [],
};

export function resetPanel() {
  return { type: RESET };
}

// actions
export function updatePanel(selectedReasons: any[]) {
  return { type: UPDATE, payload: { selectedReasons } };
}

// Store
export default function HoldPanelStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case RESET:
      return { ...INITIAL_STATE };
    case UPDATE:
      return {
        ...state,
        selectedReasons: payload.selectedReasons,
      };
    default:
      return state;
  }
}
