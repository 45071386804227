import { Action } from '../reduxStore';

export const SHOW_VIEWER: string = 'documentViewer/showViewer';
export const HIDE_VIEWER: string = 'documentViewer/hideViewer';

interface DocumentViewerState {
  showViewer: boolean,
  viewerData: null | any
}

export const INITIAL_STATE: DocumentViewerState = {
  showViewer: false,
  viewerData: null,
};

export function showViewer(data: any) {
  return {
    type: SHOW_VIEWER,
    payload: data,
  };
}

export function hideViewer() {
  return { type: HIDE_VIEWER };
}

export default function DocumentViewerStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case SHOW_VIEWER:
      return {
        ...state,
        showViewer: true,
        viewerData: payload,
      };
    case HIDE_VIEWER:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
