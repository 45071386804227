export default class AttributePairs {
  constructor(pairs = []) { this.pairs = pairs; }

  getPairs() { return this.pairs; }

  mappingExists(internalAttr: any) {
    return this.pairs.find((p: any) => p.internalAttribute === internalAttr);
  }

  getExternal(internalAttr: any) {
    const matchingPair = this.mappingExists(internalAttr);
    if (matchingPair) {
      return matchingPair.externalAttribute;
    }
    return null;
  }

  resetPairs() { this.pairs = []; }

  resetPair(externalAttr: any, internalAttr: any) {
    this.pairs =
      this.pairs.filter((p: any) => (
        p.internalAttribute !== internalAttr || p.externalAttribute !== externalAttr),
      );
  }

  updatePair(externalAttr: any, internalAttr: any) {
    const pairToUpdate = this.mappingExists(internalAttr);
    if (pairToUpdate) {
      pairToUpdate.externalAttribute = externalAttr;
    } else {
      this.pairs.push({ internalAttribute: internalAttr, externalAttribute: externalAttr });
    }
  }
}
