import CriteriaMultiSelectViewComposer from './Components/CriteriaMultiSelectViewComposer';

const AttachmentsCriteriaView = CriteriaMultiSelectViewComposer({
  init: function init(props: any) {
    const { listElem: { values }, form } = props;

    props.updateBaseState({
      criteriasVisibilities: {
        ...props.criteriasVisibilities,
        isRelationVisible: false,
      },
    });

    props.setInitialFormAndOriginalValues(
      values.selectedCriteria
        ? values
        : {
          ...values,
          ...form,
          selectedCriteria: [],
          relation: 'or',
          isSeparatedAnyLogic: true,
          isAnySelected: false,
        },
    );

    this.state = {
      visibleCriteria: 0,
    };

    this.listResourceName = 'attachments';
  },
});

export default AttachmentsCriteriaView;
