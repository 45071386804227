import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import LoadingIndicator from 'v-c/LoadingIndicator';
import withMessages from 'component-utilities/intl';

import {
  sort as doSort,
  sortExports,
  filterExports,
  setFilter,
  setVisibleColumns,
  resetFilter,
} from '../../global/state_managers/ExportTableUIStateManager';

import withJobsLoad from '../../global/components/withJobsLoad';

import Loaded from './Loaded';

import {
  allImmediately,
  allWhenOptimal,
  download,
} from '../../global/state_managers/ExportsStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import Layout from '../../global/components/Layout';

type Props = {
  isLoading: Boolean,
  exportsTableUI: any,
  exports: any,
  doSort: Function,
  setBreadcrumbForExportsPage: Function,
  resetFilter: Function,
  setVisibleColumns: Function,
  setFilter: Function,
  download: Function,
  getMessage: Function
}

const mapStateToProps = ({ exports, exportsTableUI }: any) => ({
  exports,
  exportsTableUI,
});
const mapActionsToProps = {
  allImmediately,
  allWhenOptimal,
  doSort,
  setVisibleColumns,
  setFilter,
  resetFilter,
  download,
  setBreadcrumbForExportsPage: breadcrumbsActions.exportsPage,
};

@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withMessages
@withJobsLoad('exports')
export default class Exports extends Component<Props> {
  static propTypes = {
    exports: PropTypes.object.isRequired,
    exportsTableUI: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,

    doSort: PropTypes.func.isRequired,
    getMessage: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    setVisibleColumns: PropTypes.func.isRequired,
    download: PropTypes.func.isRequired,
    setBreadcrumbForExportsPage: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    props.setBreadcrumbForExportsPage();
    props.resetFilter();
  }

  doSort = (column: string, direction: string) => {
    this.props.doSort({ key: column, order: direction });
  };

  render() {
    const { jobs } = this.props.exports;
    const { filter, visibleColumns, sort } = this.props.exportsTableUI;

    const filteredJobs = sortExports({
      exports: filterExports(jobs, filter),
      ...sort,
    });

    return (
      <Layout section="exports">
        <LoadingIndicator
          isLoading={this.props.isLoading}
          orientation="middle"
          text={this.props.getMessage('app.general.loading')}
        >
          <Loaded
            emptyText={'app.empty_results.exports'}
            returnCount={filteredJobs.length}
            searchString={filter}
            visibleColumns={visibleColumns}
            sortKey={sort.key}
            sortOrder={sort.order}
            jobs={filteredJobs}
            doSort={this.doSort}
            setVisibleColumns={this.props.setVisibleColumns}
            setFilter={this.props.setFilter}
            getMessage={this.props.getMessage}
            download={this.props.download}
          />
        </LoadingIndicator>
      </Layout>
    );
  }
}
