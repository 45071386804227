import React from 'react';
import PropTypes from 'prop-types';

import {
  SingleSignOnGoogleButton,
  SingleSignOnAzureButton,
  SingleSignOnBarracudaButton,
} from 'v-c/Login';

export default function EnabledLoginProvider({ provider, href }: any) {
  switch (provider) {
    case 'azure':
      return <SingleSignOnAzureButton href={href} />;
    case 'google':
      return <SingleSignOnGoogleButton href={href} />;
    default:
      throw new Error(`Unsupported Provider: ${provider}`);
  }
}

EnabledLoginProvider.propTypes = {
  provider: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};
