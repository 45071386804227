import React, { useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { formatDate, DATE_FORMATS } from 'component-utilities/dates/dates';
import Textarea from 'v-c/Forms/Textarea/Textarea';
import {
  TextLabel,
} from 'view-components/components/Widgets/Components';

import FlagCustom from 'view-components/components/Flags/FlagCustom';
import FlagsSmall from 'view-components/components/Flags/FlagsSmall';
import { Comment } from 'global/types';

const BASE_KEY = 'app.search_results.comments';

type Props = {
  isLastCell: boolean,
  comment: Comment,
  getMessage: Function,
  setStateCallback: Function,
  commentIdEditing: string,
  originalCommentBody: string,
  tempCommentEditing: Comment,
  editComment: Function,
  deleteCommentClicked: Function,
}

const CommentCell = (props: Props) => {
  const myRef = useRef(null);

  const {
    isLastCell,
    comment,
    getMessage,
    setStateCallback,
    commentIdEditing,
    originalCommentBody,
    tempCommentEditing,
    editComment,
    deleteCommentClicked,
  } = props;

  const editSaveDisabled = useCallback(() => {
    const { body } = tempCommentEditing;
    return !body.length || body.length > 255 || body === originalCommentBody;
  }, [tempCommentEditing]);

  const editSaveClicked = useCallback(async () => {
    if (editSaveDisabled()) return;

    await editComment();
    setStateCallback(null);
  }, [editSaveDisabled]);

  const renderCommentBody = useMemo(() => {
    if (!comment.body) {
      return (<div className="comment-body disabled">
        {getMessage(`${BASE_KEY}.deleted`)}
      </div>);
    }
    if (commentIdEditing !== comment['comment-id']) {
      return (<div className="comment-body">
        {comment.body}
      </div>);
    }
    return (<div ref={myRef}>
      <Textarea
        rows={3}
        onChange={(val: any) => setStateCallback({
          tempCommentEditing: {
            ...tempCommentEditing,
            body: val.target.value,
          },
        })}
        focused
        value={tempCommentEditing.body}
      />
      <TextLabel style={{ color: tempCommentEditing.body.length > 255 && '#FF0000' }}>
        {tempCommentEditing.body.length}/255
      </TextLabel>
    </div>);
  }, [tempCommentEditing.body, comment.body, commentIdEditing]);

  const renderCommentOptions = useMemo(() => {
    if (commentIdEditing === comment['comment-id']) { // show save and cancel buttons
      return (
        <div>
          <span
            tabIndex={0}
            role="button"
            className={`edit-button ${editSaveDisabled() && 'disabled'}`}
            onClick={editSaveClicked}
          >
            { getMessage('app.button.save').toLowerCase() }
          </span>
          <span
            tabIndex={0}
            role="button"
            className="red-color delete-button"
            onClick={() => setStateCallback(null)}
          >
            { getMessage('app.button.cancel').toLowerCase() }
          </span>
        </div>
      );
    }
    return ( // show edit and delete buttons
      <div>
        <span
          tabIndex={0}
          role="button"
          className="edit-button"
          onClick={async () => {
            await setStateCallback({
              tempCommentEditing: { ...comment },
              commentIdEditing: comment['comment-id'],
              originalCommentBody: comment.body,
            });
            // scroll this comment cell to top of panel
            myRef.current.scrollIntoView(false);
          }}
        >
          { getMessage('app.button.edit').toLowerCase() }
        </span>
        <span
          tabIndex={0}
          role="button"
          className="delete-button"
          onClick={() => deleteCommentClicked(comment)}
        >
          { getMessage('app.button.delete').toLowerCase() }
        </span>
      </div>
    );
  }, [commentIdEditing, tempCommentEditing]);

  return useMemo(() => (
    <div className={`${isLastCell ? '' : 'border-bottom'}`} key={`Comment_${comment['comment-id']}`}>
      <div className="flex-box">
        <div>
          <TextLabel className="head-label">
            {comment.author}
          </TextLabel>
          <TextLabel className="comment-mtime">
            {formatDate(comment.mtime, DATE_FORMATS.EMAIL_LONG)}
          </TextLabel>
          {!!comment.body && comment.ctime !== comment.mtime &&
            <FlagsSmall>
              <FlagCustom>
                <div className="edited-flag">
                  {getMessage(`${BASE_KEY}.edited`)}
                </div>
              </FlagCustom>
            </FlagsSmall>}
        </div>
        {!!comment.body && renderCommentOptions}
      </div>
      {renderCommentBody}
    </div>
  ), [isLastCell, renderCommentBody, renderCommentOptions]);
};

CommentCell.propTypes = {
  comment: PropTypes.object.isRequired,
  isLastCell: PropTypes.bool.isRequired,
  getMessage: PropTypes.func.isRequired,
  setStateCallback: PropTypes.func.isRequired,
  commentIdEditing: PropTypes.string,
  editComment: PropTypes.func.isRequired,
  originalCommentBody: PropTypes.string.isRequired,
  tempCommentEditing: PropTypes.object.isRequired,
  deleteCommentClicked: PropTypes.func.isRequired,
};

CommentCell.defaultProps = {
  commentIdEditing: '',
};

export default CommentCell;
