import React, { Component } from 'react';
import PropTypes from 'prop-types';

type Props = {
  params: {
    search_id: String
  },
  searchId: String,
  loadSavedSearch: Function,
  currentSearch: {
    id: String,
    raw: Boolean,
    samplePercentage: any,
    fields: string[],
  },
  updateSavedSearch: Function,
  loadAllUsers: Function,
}

export default function SaveCurrentSearchDeconstructed({
  unsavedSearchForSave,
  isPageRoleEnabled,
  USER_CAN_EDIT_SAVED_SEARCHES,
}: any) {
  return function SaveCurrentSearchStateLogicComposer(component: any) {
    class SaveCurrentSearchStateLogic extends Component<Props> {
      static propTypes = {
        params: PropTypes.object.isRequired,
        searchId: PropTypes.string.isRequired,
        loadSavedSearch: PropTypes.func.isRequired,
        currentSearch: PropTypes.shape({
          id: PropTypes.string,
          raw: PropTypes.bool,
          samplePercentage: PropTypes.any,
          fields: PropTypes.array,
        }).isRequired,
        updateSavedSearch: PropTypes.func.isRequired,
        loadAllUsers: PropTypes.func.isRequired,
      };

      constructor(props: Props) {
        super(props);

        const {
          params: { search_id: searchId },
        } = props;

        if (isPageRoleEnabled(USER_CAN_EDIT_SAVED_SEARCHES)) {
          props.loadAllUsers();
        }

        props.loadSavedSearch({ searchId, loadIfNecessary: true });
      }

      onSave = (fieldValues: any) => {
        const { searchId, currentSearch } = this.props;

        this.props.updateSavedSearch({
          id: searchId,
          requestData: {
            ...fieldValues,
            ...unsavedSearchForSave(),
            fields: currentSearch.fields,
          },
        });
      };

      get isRawSearch() {
        return this.props.currentSearch.raw || false;
      }

      get isReadyToLoad() {
        const { currentSearch, params } = this.props;

        return !!currentSearch.id && currentSearch.id === params.search_id;
      }

      render() {
        return React.createElement(component, {
          ...this.props,
          ...this.state,
          onSave: this.onSave,
          isRawSearch: this.isRawSearch,
          canEditFields: isPageRoleEnabled(USER_CAN_EDIT_SAVED_SEARCHES),
          isReadyToLoad: this.isReadyToLoad,
        });
      }
    }

    return SaveCurrentSearchStateLogic;
  };
}
