/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import DeleteAssetModalComponent, {
  DELETE_ASSET_MODAL_STATUSES,
} from 'v-c/Modals/Components/DeleteAssetModal';

import { purgeAsset, resetPurge } from '../../state_managers/PurgeStateManager';

import stateLogic from './DeleteAssetModalDeconstructed';
import { ReduxState, ModalProps } from 'global/types';

export { DELETE_ASSET_MODAL_STATUSES };

const mapStateToProps = ({ purge, userRights }: ReduxState) => ({
  purge,
  activeRoles: userRights.activeRoles,
});
const mapActionsToProps = { purgeAsset, resetPurge };

@connect(mapStateToProps, mapActionsToProps)
@reduxForm(
  {
  form: 'delete-asset-modal',
  fields: ['reason', 'isConfirmed'],
  },
  () => ({
    initialValues: { reason: '', isConfirmed: false },
    }),
)
@stateLogic({
  DELETE_ASSET_MODAL_STATUSES,
  })
export default class DeleteAssetModal extends Component<ModalProps> {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    confirmProp: PropTypes.object.isRequired,
    modalStatus: PropTypes.oneOf(
      Object.keys(DELETE_ASSET_MODAL_STATUSES).map(status => status),
    ).isRequired,
  };

  render() {
    const {
      onCloseModal,
      fields,
      onDelete,
      confirmProp,
      modalStatus,
    } = this.props;

    return (
      <DeleteAssetModalComponent
        status={modalStatus}
        onDelete={onDelete}
        onClose={onCloseModal}
        reason={fields.reason}
        confirm={confirmProp}
      />
    );
  }
}
