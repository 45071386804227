/**
 * Single Sign-On deals with testing and configuring OpenID providers
 * for use with View Archive. Along with the JavaScript files referred to
 * here, look at the following Rails code as well:
 *
 * OpenIdController#callback
 * SettingsController#provider_href
 *
 * And look at otto.http.user/ensure-open-id-token-login to see how the
 * backend token check works.
 *
 * Finally, read doc/sso_test_button.md for a high-level overview of
 * the Test Login Provider functionality.
 */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { get } from 'lodash';

import withMessages from 'component-utilities/intl';
import LoadingIndicator from 'v-c/LoadingIndicator';

import { resetFlash } from '../../global/state_managers/FlashMessagesStateManager';

import {
  update as updateAccountSettings,
  load as loadAccountSettings,
  clear as clearAccountSettings,
  startLoadingProviderHref,
  stopLoadingProviderHref,
} from '../../global/state_managers/AccountSettingsStateManager';
import { getStatus as getAzureADStatus } from '../../global/state_managers/ADSyncStateManager';
import {
  showUnsavedAccountSettingsDialog,
  showUpdateSSOSettingsDialog,
  showRevertLoginProviderDialog,
} from '../../global/state_managers/DialogStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import routeLeaveHook, {
  routeLeaveHookPropTypes,
} from '../../global/lib/routeLeaveHook';
import dialogHandler from '../../global/lib/dialogHandler';

import Layout from '../../global/components/DifferentLayout';
import Breadcrumb from '../../global/components/Breadcrumb';
import HeadlineLabel from '../../global/components/HeadlineLabel';
import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';

import Content from './SingleSignOn/Content';
import {
  stateLogic,
  viewLogic,
} from './SingleSignOn/SingleSignOnDeconstructed';
import validateSettingsAccess from './components/validateSettingsAccess';

function ssoGetInitialFields(accountSettings: any) {
  const {
    isLoaded,
    account: { enabledLoginProviders = [], selfRegistrationDomains = [] },
  } = accountSettings;

  if (isLoaded) {
    return {
      enabledLoginProviders,
      selfRegistrationDomains,
    };
  }

  return {};
}

const mapStateToProps = ({
  flashMessages,
  accountSettings,
  adSync,
}: any) => ({
  isFlashVisible: !!flashMessages.indicators.length,
  ...accountSettings,
  initialValues: ssoGetInitialFields(accountSettings),
  ADSyncSetupDone: (adSync && adSync.setupDone) || false,
  azureAD: adSync,
});

const mapActionsToProps = {
  resetFlash,
  loadAccountSettings,
  updateAccountSettings,
  clearAccountSettings,
  startLoadingProviderHref,
  stopLoadingProviderHref,
  getAzureADStatus,
  showUpdateSSOSettingsDialog,
  showRevertLoginProviderDialog,
  setBreadcrumbForSettingsSsoPage: breadcrumbsActions.settingsSsoPage,
};

type State = {
  isReady: boolean
}

type Props = {
  isLoaded: boolean,
  fields: {
    enabledLoginProviders: {
      onChange: Function
    }
  }
  setBreadcrumbForSettingsSsoPage: Function,
  loadAccountSettings: Function,
  getAzureADStatus: Function,
  registerUnmountCleanupMethod: Function,
  setRouterWillLeaveValidator: Function,
  clearAccountSettings: Function,
  untouchAll: Function,
  isUpdated: boolean,
  isFlashVisible: boolean,
  resetFlash: Function,
  dirty: boolean,
  getMessage: Function,
  retryTransition: () => boolean
}
@routeLeaveHook
@withMessages
@flashConnect(PAGES.SETTINGS_SINGLE_SIGN_ON, 'Settings/SingleSignOn')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@reduxForm({
  form: 'single-sign-on',
  fields: ['selfRegistrationDomains', 'enabledLoginProviders'],
  })
@stateLogic()
@validateSettingsAccess('settings.single_sign_on')
export default class SingleSignOn extends Component<Props, State> {
  static propTypes = {
    getMessage: PropTypes.func.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    resetFlash: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    registerUnmountCleanupMethod: PropTypes.func.isRequired,
    getAzureADStatus: PropTypes.func.isRequired,
    setBreadcrumbForSettingsSsoPage: PropTypes.func.isRequired,
    ...routeLeaveHookPropTypes,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    // prevent flash of existing content when loading
    if (!prevState.isReady && nextProps.isLoaded) {
      const testedProvider = get(nextProps, 'location.query.provider');

      if (testedProvider) {
        nextProps.fields.enabledLoginProviders.onChange([testedProvider]);
      }

      return { isReady: true };
    }

    return null;
  }

  constructor(props: Props) {
    super(props);

    this.state = { isReady: false };

    props.setBreadcrumbForSettingsSsoPage();
    props.loadAccountSettings();
    this.props.getAzureADStatus();

    props.setRouterWillLeaveValidator(this.routerWillLeave);
    props.registerUnmountCleanupMethod(() => {
      props.clearAccountSettings();
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isUpdated && this.props.isUpdated) {
      this.props.untouchAll();
    }
  }

  onFormChange = () => {
    if (this.props.isFlashVisible) {
      this.props.resetFlash({ from: 'SingleSignOn/onFormChange' });
    }
  };

  routerWillLeave = () => {
    const { dirty, retryTransition } = this.props;

    if (!dirty) {
      return true;
    }

    dialogHandler(showUnsavedAccountSettingsDialog, retryTransition);

    return false;
  };

  render() {
    const { getMessage } = this.props;
    const { isReady } = this.state;

    return (
      <Layout staticView section="settings single-sign-on">
        <header>
          <FlashMessages />
          <Breadcrumb />
          <HeadlineLabel
            text={getMessage('app.settings.single_sign_on.tab_name')}
          />
        </header>
        {!isReady && (
          <LoadingIndicator
            orientation="middle"
            text={getMessage('app.general.loading')}
            isLoading
          />
        )}
        {isReady && <Content {...viewLogic(this.props)} />}
      </Layout>
    );
  }
}
