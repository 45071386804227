/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import RetinaImage from 'view-components/components/RetinaImage';
import {
  DropdownList,
  LinkLabel,
} from 'view-components/components/Widgets/Components';

import AddIcon from './Images/add.png';

import styles from './FormBase.scss';

type Props = {
  index: string
  label: string
  addCriteria: Function
  dropdownList: {}[]
  calculateOpenedListElemPosition: Function
  setMaskLayerVisibilityFor: Function
}

/* eslint-disable react/no-string-refs */
export default class AddCriteria extends Component<Props> {
  static propTypes = {
    index: PropTypes.string,
    label: PropTypes.string,
    addCriteria: PropTypes.func,
    dropdownList: PropTypes.array,
    calculateOpenedListElemPosition: PropTypes.func,
    setMaskLayerVisibilityFor: PropTypes.func,
  };

  static defaultProps = {
    index: '',
    label: '',
    addCriteria: function addCriteria() {},
    dropdownList: [],
    calculateOpenedListElemPosition: null,
    setMaskLayerVisibilityFor: null,
  };

  constructor(props: Props) {
    super(props);

    this.state = { isDropdownEnabled: false };
  }

  componentDidMount() {
    this.dropdownMounted = true;
  }

  componentWillUnmount() {
    this.dropdownMounted = false;

    this.closeDropdownMenu();
  }

  openDropdownMenu = () => {
    const listener = () => addEventListener('click', this.closeMethod);
    const { calculateOpenedListElemPosition } = this.props;

    this.closeMethod =
      this.customDropdownHelperListenerMethod ||
      this.closeDropdownMenu.bind(this);

    if (!this.state.isDropdownEnabled) {
      this.setState({ isDropdownEnabled: true });
      setTimeout(listener, 1);
    }

    if (calculateOpenedListElemPosition) {
      const calculateListPositions = () => {
        const $dropdownList = $(findDOMNode(this.refs.dropdownList));
        const position = $dropdownList.parent().offset();
        const listElemHeight = $dropdownList.find('li').outerHeight();
        const { dropdownList } = this.props;
        let maxMenuNum = dropdownList.length;

        const validateMaxMenu = (list: any) => {
          const { submenu } = list;

          if (submenu) {
            if (submenu.length > maxMenuNum) maxMenuNum = submenu.length;

            submenu.forEach(validateMaxMenu);
          }
        };

        dropdownList.forEach(validateMaxMenu);

        maxMenuNum += 1;

        calculateOpenedListElemPosition({
          position,
          height: listElemHeight * maxMenuNum,
          isShowMaskLayer: false,
        });
      };

      setTimeout(calculateListPositions, 0);
    }
  }

  closeDropdownMenu = () => {
    if (this.dropdownMounted) {
      const { setMaskLayerVisibilityFor } = this.props;
      if (setMaskLayerVisibilityFor) setMaskLayerVisibilityFor(false);

      removeEventListener('click', this.closeMethod);
      this.setState({ isDropdownEnabled: false });
    }
  }

  render() {
    const { index, addCriteria, dropdownList, label } = this.props;
    const isDropdownEnabled =
      this.state.isDropdownEnabled && dropdownList.length;
    const linkLabel = isDropdownEnabled ? '\u00A0' : label;

    return (
      <div className={styles['add-form-group']}>
        <data
          onClick={this.openDropdownMenu}
          data-action={`add-criteria-${index}`}
          role="button"
          tabIndex="-1"
        >
          <RetinaImage image={AddIcon} className={styles['pre-icon']} />
          <LinkLabel className={styles['add-label']}>{linkLabel}</LinkLabel>
        </data>
        {isDropdownEnabled && (
          <DropdownList
            widgetId="simple-search"
            className={styles['dropdown-list']}
            ref="dropdownList"
            onSelectedValue={addCriteria}
            list={dropdownList}
          />
        )}
      </div>
    );
  }
}
