import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'view-components/components/CustomCheckbox';
import styles from './CheckboxContainer.scss';

type Props = {
  children: ReactNode
  forceHover: boolean
  isConfirmed: boolean
}

export default function CheckboxContainer(props: Props) {
  const { children, forceHover, isConfirmed } = props;

  return (
    <div className={styles.outer}>
      <span className={styles.checkbox}>
        <Checkbox
          forceHover={forceHover}
          isChecked={isConfirmed}
        />
      </span>
      <span className={styles.children}>{children}</span>
    </div>
  );
}

CheckboxContainer.propTypes = {
  children: PropTypes.node.isRequired,
  forceHover: PropTypes.bool,
  isConfirmed: PropTypes.bool,
};

CheckboxContainer.defaultProps = {
  forceHover: false,
  isConfirmed: false,
};
