/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import More from 'v-c/Buttons/ActionButton/More';
import { DropdownList } from 'v-c/Widgets/Components';

import styles from './MoreButton.scss';

type Props = {
  actionHelper: {
    moreButton: {
      moreList: any
      onSelectedValue: Function
    }
  }
}

const MoreButton = (props: Props) => {
  const [activeMoreIcon, setActiveMoreIcon] = useState(undefined);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);

  function onClickOutside() {
    setActiveMoreIcon(undefined);
    setisDropdownOpen(!isDropdownOpen);
  }

  const { onSelectedValue, moreList } = props.actionHelper.moreButton;

  const extraClassName = cx(styles.new, {
    [styles.active]: isDropdownOpen,
  });

  return (
    <span>
      <More
        data-action="more-action"
        extraClassName={extraClassName}
        image={activeMoreIcon}
        onClick={onClickOutside}
      />
      {isDropdownOpen && (
        <div className={styles.dropdown}>
          <DropdownList
            widgetId="more-action-dropdown"
            onClickOutside={onClickOutside}
            onSelectedValue={onSelectedValue}
            list={moreList}
          />
        </div>
      )}
    </span>
  );
};

MoreButton.propTypes = {
  actionHelper: PropTypes.shape({
    moreButton: PropTypes.shape({
      onSelectedValue: PropTypes.func.isRequired,
      moreList: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }),
      ),
    }),
  }).isRequired,
};

export default MoreButton;
