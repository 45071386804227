import { camelCase } from 'lodash';

import Pages from './Pages';

const {
  EXPORTS,
  JOBS,
  HOLD_REASONS,
  NEW_HOLD_REASON,
  NEW_SEARCH,
  NEW_USER,
  SEARCHES,
  SEARCHES_EDIT,
  MY_ARCHIVE_SPLIT_VIEW,
  USER_MANAGEMENT,
  DASHBOARD,
} = Pages;

const pathsByType = [
  { type: DASHBOARD, path: 'DASHBOARD' },
  { type: SEARCHES, path: 'SEARCHES_INDEX' },
  { type: SEARCHES_EDIT, path: 'SEARCHES_EDIT' },
  { type: MY_ARCHIVE_SPLIT_VIEW, path: 'MY_ARCHIVE_SPLIT_VIEW_INDEX' },
  { type: EXPORTS, path: 'EXPORTS' },
  { type: HOLD_REASONS, path: 'HOLD_REASONS_INDEX' },
  { type: USER_MANAGEMENT, path: 'USERS_INDEX' },
  { type: NEW_SEARCH, path: 'NEW_SEARCH_INDEX' },
  { type: NEW_HOLD_REASON, path: 'HOLD_REASONS_NEW' },
  { type: NEW_USER, path: 'USERS_NEW' },
  { type: JOBS, path: 'JOBS' },
];

export const menuOrder = pathsByType.map(({ type }) => type);
export const pathOrder = pathsByType.map(({ path }) => camelCase(path));

export default pathsByType;
