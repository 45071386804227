/* eslint-disable no-undef */
import React, { Children, cloneElement, Component, ReactNode } from 'react';
import PropTypes from 'prop-types';

import Section from './VerticalHeightManager/Section';

type Props = {
  children: ReactNode
}

export default class VerticalHeightManager extends Component<Props> {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  constructor(props: Props) {
    super(props);

    this.heights = [];
  }

  componentDidMount() {
    this.interval = setInterval(this.setHeight, 100);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setHeight = () => {
    const reducer = (s, n) => s - n;
    const height = this.heights.reduce(reducer, this.DOMNodeHeight);

    $(this.vhm)
      .children('[data-maximized="true"]')
      .height(height);

    if (this.lastHeight !== height) {
      const { Event: jqueryEvent } = $;

      $(window).trigger(jqueryEvent('verticalHeightChanged'));
    }

    this.lastHeight = height;
  };

  get DOMNodeHeight() {
    return $(this.vhm).outerHeight(true);
  }

  render() {
    const createNotifyHeight = childId => (height) => {
      if (this.heights[childId] !== height) {
        this.heights[childId] = height;
      }
    };

    const preppedChildren = Children.map(this.props.children, (child, idx) =>
      cloneElement(child, {
        notifyHeight: createNotifyHeight(idx),
      }),
    );

    return (
      <section
        ref={(vhm) => {
          this.vhm = vhm;
        }}
        className="vertical-height-manager"
      >
        {preppedChildren}
      </section>
    );
  }
}

VerticalHeightManager.Section = Section;
