import { jsUi } from '../lib/jsUi';
import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';
import { Action, PromiseObject } from 'global/reduxStore';

export const RESET: string = 'loginAttemptRestriction/reset';
export const LOADING: string = 'loginAttemptRestriction/loading';
export const LOADED: string = 'loginAttemptRestriction/loaded';
export const FAILED_TO_LOAD: string = 'loginAttemptRestriction/failedToLoad';
export const CREATING: string = 'loginAttemptRestriction/creating';
export const CREATED: string = 'loginAttemptRestriction/created';
export const FAILED_TO_CREATE: string = 'loginAttemptRestriction/failedToCreate';

interface LoginAttemptRestrictionState {
  isLoading: boolean,
  isLoaded: boolean,
  isProcessing: boolean,
  data: {
    enabled: boolean,
    failedAttemptCount: string,
    lockDurationMinutes: string,
  },
}

// Init
export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  isProcessing: false,
  data: {
    enabled: false,
    failedAttemptCount: '',
    lockDurationMinutes: '',
  },
};

// Actions
export function loadLoginAttemptRestriction() {
  return function* doLoadLoginAttemptRestriction() {
    yield { type: LOADING };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.loginAttemptRestrictionShow);

      yield {
        type: LOADED,
        payload: { data: result.data },
      };
    } catch (e) {
      console.error(e);

      yield { type: FAILED_TO_LOAD };
    }
  };
}

export function resetLoginAttemptRestriction() {
  return { type: RESET };
}

export function createLoginAttemptRestriction(restriction: any) {
  return function* doCreateLoginAttemptRestriction() {
    yield { type: CREATING };

    try {
      yield apiReqPromise(jsApi.loginAttemptRestrictionCreate, {
        params: { data: { restriction } },
      });

      yield { type: CREATED };

      jsUi.settingsIndex.goTo();
      yield resetLoginAttemptRestriction();
    } catch (e) {
      console.error(e);

      yield { type: FAILED_TO_CREATE };

      jsUi.settingsIndex.goTo();
      yield resetLoginAttemptRestriction();
    }
  };
}

// Store
export default function LoginAttemptRestrictionStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case LOADING:
      return { ...state, isLoading: true, isLoaded: false };
    case CREATING:
      return { ...state, isProcessing: true };
    case LOADED:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: {
          ...state.data,
          ...payload.data,
        },
      };
    case FAILED_TO_LOAD:
      return {
        ...state,
        isLoading: false,
      };
    case CREATED:
    case FAILED_TO_CREATE:
      return {
        ...state,
        isProcessing: false,
      };
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
