import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';

import routeLeaveHook from '../../../global/lib/routeLeaveHook';
import LoadWhenReady from '../../../global/lib/LoadWhenReady';
import { isPageRoleEnabled, pageRoles } from '../../../global/lib/userRights';

import {
  loadSavedSearch,
  updateSavedSearch,
  deleteSavedSearch,
  clearSavedSearchErrors,
} from '../../../global/state_managers/CurrentSavedSearchStateManager';
import { loadAllUsers } from '../../../global/state_managers/UsersStateManager';
import { bodyForSaveUnsavedSearch } from '../../../global/state_managers/UnsavedSearchStateManager';

import Loading from '../../../global/components/Loading';
import stateLogic from './SaveCurrentSearchDeconstructed';

const SearchDetailsForm = Loadable({
  loader: () => import('../components/SearchDetailsView'),
  loading() {
    return <Loading />;
  },
});

const mapStateToProps = ({ currentSavedSearch }: any) => ({
  currentSavedSearch,
  currentSearch: currentSavedSearch.search,
  isRawSearch: currentSavedSearch.search.raw || false,
  searchId: currentSavedSearch.search.id || '',
  searchMetadata: {
    createdBy: currentSavedSearch.search.createdUser,
    creationDate: currentSavedSearch.search.createdTime,
    lastModifiedBy: currentSavedSearch.search.lastModifiedUser,
    lastModifiedDate: currentSavedSearch.search.lastModifiedTime,
  },
  searchErrors: !!Object.keys(currentSavedSearch.errors).length,
  currentSavedSearchErrors: currentSavedSearch.errorTypes,
  isSavedSearchProcessing: currentSavedSearch.isProcessing,
});

const mapActionsToProps = {
  loadSavedSearch,
  updateSavedSearch,
  deleteSavedSearch,
  clearSavedSearchErrors,
  loadAllUsers,
};

type Props = {
  isReadyToLoad: Boolean,
  canEditFields: Boolean,
  onSave: Function,
  isRawSearch: Boolean,
}

@routeLeaveHook
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@stateLogic({
  unsavedSearchForSave: bodyForSaveUnsavedSearch,
  isPageRoleEnabled,
  USER_CAN_EDIT_SAVED_SEARCHES: pageRoles.USER_CAN_EDIT_SAVED_SEARCHES,
  })
export default class SaveCurrentSearch extends Component<Props> {
  static propTypes = {
    isReadyToLoad: PropTypes.bool,
    canEditFields: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    isRawSearch: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isReadyToLoad: false,
  };

  render() {
    return (
      <LoadWhenReady readyStates={[this.props.isReadyToLoad]}>
        <SearchDetailsForm
          {...this.props}
          isNewSearch={false}
          canEditFields={this.props.canEditFields}
          onSave={this.props.onSave}
          isRawSearch={this.props.isRawSearch}
        />
      </LoadWhenReady>
    );
  }
}
