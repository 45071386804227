export default function NaturalCmp(a: string, b: string) {
  if (a === b) return 0;
  if (!a) return -1;
  if (!b) return 1;

  const cmpRegex = /(\.\d+|\d+|\D+)/g;
  const tokens1 = String(a).match(cmpRegex) as RegExpMatchArray;
  const tokens2 = String(b).match(cmpRegex) as RegExpMatchArray;
  const count = Math.min(tokens1.length, tokens2.length);

  for (let i = 0; i < count; i += 1) {
    const a2 = tokens1[i];
    const b2 = tokens2[i];

    if (a2 !== b2) {
      const num1 = +a;
      const num2 = +b;
      if (num1 && num2) {
        return num1 > num2 ? 1 : -1;
      }
      return a2 < b2 ? -1 : 1;
    }
  }

  if (tokens1.length !== tokens2.length) {
    return tokens1.length - tokens2.length;
  }

  return a < b ? -1 : 1;
}
