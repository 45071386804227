import { ReduxState } from 'global/types';
import { ViewLoginLogo } from 'v-c/Login';
import LoginLogoGenerator from 'v-c/Login/lib/LoginLogo';

import reduxStore from '../reduxStore';

export default function loginLogo() {
  const { domainInfo } = reduxStore.getState() as ReduxState;

  return LoginLogoGenerator(domainInfo.loginLogoPath || '', ViewLoginLogo);
}
