import 'es5-shim';
import 'es6-shim';
import 'core-js/es/symbol';
import 'core-js/features/symbol/iterator';
import 'es6-set/implement';
import 'function.name-polyfill';
import 'stylesheets/application.scss';
import { isString } from 'lodash';
// import Perf from 'react-addons-perf';

import 'intl';
import 'component-images/logos/favicon.png';
import 'images/o365/archive.png';
import 'images/o365/archive--barracuda-blue.png';

import './global/lib/jQueryPolyfill';
import './global/lib/DeveloperInterface/features';

import './global/lib/lodash';

import reduxStore from './global/reduxStore';
import { initUserRights } from './global/state_managers/UserRightsStateManager';
import { initAuthentication } from './global/state_managers/AuthenticationStateManager';
import { initLoginFormStore } from './global/state_managers/LoginFormStateManager';

// TODO: figure out how we can remove this disable
/* eslint-disable import/first */
// Here is the entry point of the app. It is a single page app built using the
// Flux pattern (view -> action -> store -> view), using React for views,
// react-router to handle URL routing, Immutable for data structures, and any
// other interesting node.js modules for things like network communications.
import router from 'global/components/AppRouter';
/* eslint-enable import/first */

// if (process.env.NODE_ENV !== 'production') {
//   window.Perf = Perf;
// }

reduxStore.dispatch(initUserRights());
reduxStore.dispatch(initAuthentication());
reduxStore.dispatch(initLoginFormStore());

const isPhantomJS = window.navigator.userAgent.indexOf('PhantomJS') !== -1;

// This is the global error handler. This should be hooked up to something
// that will talk back to a remote server for debugging purposes.
window.onerror = (e: any) => {
  let errorMethod = 'error';
  const message = e.message || (typeof e === 'string' && e);

  if (message) {
    // messages that we are safe to "ignore"
    //
    // detect-browser doesn't like PhantomJS. it's brought in by
    // another library.
    if (isPhantomJS && /detectBrowser2.default.name/.test(e)) {
      errorMethod = 'log';
    }

    console[errorMethod](e);
  }
};

try {
  // React use console.error for the warnings from version 0.14
  // Sometimes Travis drop setState warnings during the tests and
  // the Poltergeist driver drop failed tests because of the
  // console.error messages.
  // Since these errors can't reproduce during the local tests
  // i've added this little hack until we found the reasons of the
  // setState warnings/errors.
  if (isPhantomJS) {
    window.DeveloperInterface.consoleError = console.error;

    console.error = (msg) => {
      if (!isString(msg)) {
        console.log(msg);
      } else if (
        [
          'setState',
          'Mutated style',
          'Warning:',
          'Unhandled',
          'variable: Set',
        ].some(e => msg.indexOf(e) !== -1) ||
        msg === 'Script error.'
      ) {
        console.warn(msg);
      } else {
        window.DeveloperInterface.consoleError.call(console, msg);
      }
    };
  }

  router();
} catch (e) {
  window.onerror(e);
  throw e;
}
