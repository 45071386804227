import React from 'react';

import { JOBS } from '../../../../../../config/app.json';
import WarningModal from './WarningModal';

const BASE_KEY = 'app.general.over_export_limit';

export default function OverExportLimitModal() {
  return (
    <WarningModal
      displayName="OverExportLimit"
      header={`${BASE_KEY}.header`}
      content={{
        id: `${BASE_KEY}.content`,
        values: { limit: JOBS.EXPORT.CONCURRENCY_LIMIT },
      }}
    />
  );
}
