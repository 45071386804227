/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { trim } from 'lodash';

import withMessages from 'component-utilities/intl';

import styles from './CriteriaViewBase.scss';

type Props = {
  getMessage: Function
}

export default function HeaderTextRender(component: any) {
  @withMessages
  class HeaderTextRenderComponent extends Component<Props> {
    static propTypes = {
      getMessage: PropTypes.func.isRequired,
    };

    calculateVisibleCriteriaNumHelper = ({
      formValues,
      list,
      preText = '',
    }: any) => {
      const { dependency, relation } = formValues;

      if (list.length) {
        const minRightOffset = 75;
        const headerLength = $('[data-ref="criteria-header"]').innerWidth();
        const criteriaLabelLength = $('[data-ref="criteria-label"]').width();
        const availableLength =
          headerLength - criteriaLabelLength - minRightOffset;
        const moreText = this.props.getMessage('app.criterias.more');
        let visibleCriteria = 0;
        let isSmaller = true;
        const $testElem = $('<div />');

        $testElem
          .css({
            visibility: 'hidden',
            display: 'inline-block',
            fontSize: '12px',
          })
          .text(`${dependency} +${list.length} ${moreText} ${preText}`)
          .appendTo('body');

        list.forEach((listElem: any) => {
          if (isSmaller) {
            $testElem.text(`${$testElem.text()} ${trim(listElem)} ${relation}`);

            if ($testElem.width() > availableLength) {
              isSmaller = false;
            } else {
              visibleCriteria += 1;
            }
          }
        });

        $testElem.remove();

        return visibleCriteria;
      }

      return false;
    };

    renderGreenText = (value: string) => (
      <data className={styles['green-header-text']}>
        {this.props.getMessage(`app.criterias.${value}`).toLowerCase()}
      </data>
    );

    renderHeaderTextHelper = (params: any) => {
      const { formValues, list, preText, visibleCriteria } = params;
      const { getMessage } = this.props;

      const { dependency, relation } = formValues;

      const moreText = getMessage('app.criterias.more').toLowerCase();
      let clonedVisibleCriteria = list.splice(0, visibleCriteria);
      const moreValue =
        (list.length + clonedVisibleCriteria.length) - visibleCriteria;

      if (list[0] === 'All') {
        clonedVisibleCriteria = [...list];
      }

      return (
        <data>
          {preText}
          {preText && '\u00A0'}
          {this.renderGreenText(dependency)}
          {'\u00A0'}
          {formValues.isKeywordExact &&
            <data className={styles['green-header-text']}>
              {this.props.getMessage('app.criterias.exact').toLowerCase()}
            </data>}
          {formValues.isAdvParticipantsDomain &&
            <data className={styles['green-header-text']}>
              {this.props.getMessage('app.criterias.domains').toLowerCase()}
            </data>}
          {(formValues.isKeywordExact || formValues.isAdvParticipantsDomain) && '\u00A0'}
          {clonedVisibleCriteria.map((listElem: string, idx: number) => (
            <data key={listElem}>
              {listElem}
              {'\u00A0'}
              {idx !== clonedVisibleCriteria.length - 1 && (
                <data>
                  {this.renderGreenText(relation)}
                  {'\u00A0'}
                </data>
              )}
            </data>
          ))}
          {moreValue > 0 && (
            <data>
              {this.renderGreenText(relation)}
              {'\u00A0'}
              {`+${moreValue} ${moreText}`}
            </data>
          )}
        </data>
      );
    };

    render() {
      return React.createElement(component, {
        ...this.props,
        calculateVisibleCriteriaNumHelper: this
          .calculateVisibleCriteriaNumHelper,
        renderHeaderTextHelper: this.renderHeaderTextHelper,
        renderGreenText: this.renderGreenText,
      });
    }
  }

  return HeaderTextRenderComponent;
}
