import React from 'react';
import PropTypes from 'prop-types';

import { capitalize } from 'lodash';
import WarningModal from './WarningModal';
import { ModalProps } from 'global/types';

const BASE_KEY = 'app.general.no_items_selected';

export default function NoItemsSelectedModal(props: ModalProps) {
  const { modalOptions: { name, type } } = props;

  return (
    <WarningModal
      {...props}
      displayName={`No${capitalize(name)}Selected`}
      header={`${BASE_KEY}.${name}_title`}
      content={`${BASE_KEY}.${type}_message`}
    />
  );
}

NoItemsSelectedModal.propTypes = {
  modalOptions: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};
