import React from 'react';
import PropTypes from 'prop-types';

type Props = {
  message: string
}

export default function DomainInfoLoadingFailed({ message }: Props) {
  return (
    <section className="domain-info-loading-failed">
      <h1 data-message="invalid_subdomain">{message}</h1>
    </section>
  );
}

DomainInfoLoadingFailed.propTypes = {
  message: PropTypes.string.isRequired,
};
