/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SessionExpirationWarningModalView from 'v-c/Modals/SessionExpirationWarningModal';
import { ModalProps } from 'global/types';

export default class SessionExpirationWarningModal extends Component<ModalProps> {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    modalOptions: PropTypes.object.isRequired,
  };

  isStillWorking = () => {
    this.props.modalOptions.onStillWorking();

    this.props.closeModal({ from: 'SessionExpirationWarningModal/close' });
  };

  render() {
    return (
      <SessionExpirationWarningModalView
        onClose={this.isStillWorking}
        onStillWorking={this.isStillWorking}
        onLogMeOut={this.props.modalOptions.onLogMeOut}
      />
    );
  }
}
