import { ROUTES } from '../../lib/jsUi';
import { ROLES } from '../../../../../../config/roles.json';
import Pages from '../Pages';

const { MY_ARCHIVE_SPLIT_VIEW, CHANGE_PASSWORD } = Pages;
const { MY_ARCHIVE_SPLIT_VIEW_INDEX }: any = ROUTES || {};

export default {
  name: ROLES.ENDUSER,
  tag: 'EU',
  homePage: MY_ARCHIVE_SPLIT_VIEW_INDEX,
  visibleMenus: {
    search: [MY_ARCHIVE_SPLIT_VIEW],
    nav: [],
    sub: [],
    other: [CHANGE_PASSWORD],
  },
  enabledPageRoles: [],
};
