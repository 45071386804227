import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import RetinaImage from 'v-c/RetinaImage';

import styles from './ActionsBase.scss';

type Props = {
  onClick: Function
  children: ReactNode
}

export default function StatusViewGenerator(image: any) {
  const StatusContent = (props: Props) => (
    <div
      className={styles['action-status-view']}
      data-field="success-action"
      role="button"
      tabIndex="-1"
      onClick={props.onClick}
    >
      <div className={styles['status-image']}>
        <RetinaImage image={image} />
      </div>
      <div className={styles['status-content']}>
        {props.children}
      </div>
    </div>
  );

  StatusContent.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  return StatusContent;
}
