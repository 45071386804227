const DSLFields = {
  keywords: [
    'body',
    'subject',
    'filename',
    'contents',
    'attachments.filename',
    'attachments.content',
  ],
  from: ['author', 'from', 'sender'],
  participants: ['recipients', 'to', 'cc', 'bcc'],
  sizes: ['msg-size'],
  dates: ['date'],
  attachments: ['attachment-count'],
};

export default DSLFields;
