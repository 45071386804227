import React from 'react';
import PropTypes from 'prop-types';

import ReportsListTable from 'v-c/Reports/ReportsListTable';
import sortByNaturalString from 'component-lib/sortByNaturalString';

import HeadlineLabel from '../../../global/components/HeadlineLabel';

import { flashConnect, PAGES } from '../../../global/components/FlashMessages';

type Props = {
  getMessage: Function,
  reports: {
    reports: any[]
  },
  toggle: Function,
  attemptToEditReport: Function,
  setSortColumn: Function,
  sort: Function,
}

function ReportsComponent(props: Props) {
  const {
    getMessage,
    reports,
    toggle,
    attemptToEditReport,
    setSortColumn,
    sort,
  } = props;
  const reportsCount = reports.reports.length;

  const onAttemptToEditReport = (reportId: string, type: string) => () => {
    attemptToEditReport({ reportId, type });
  };

  const onToggle = (...args: any) => () => {
    toggle(...args);
  };

  // we can only ever sort by name (for now).
  // if we ever have to sort by more, move this to
  // a state manager.
  const sortedReports = [...reports.reports].sort(sortByNaturalString(sort));

  const BASE_KEY = 'app.reports.reports';

  return (
    <div>
      <header>
        <HeadlineLabel
          text={getMessage(`${BASE_KEY}.title`, { reportsCount })}
          data-return-count={reportsCount}
        />
      </header>
      <ReportsListTable
        sort={sort}
        reports={sortedReports}
        onToggle={onToggle}
        getReportsMessage={(key: string) => getMessage(`${BASE_KEY}.${key}`)}
        attemptToEditReport={onAttemptToEditReport}
        setSortColumn={(...args: any) => {
          setSortColumn(...args);
        }}
      />
    </div>
  );
}

ReportsComponent.propTypes = {
  getMessage: PropTypes.func.isRequired,
  reports: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  attemptToEditReport: PropTypes.func.isRequired,
  setSortColumn: PropTypes.func.isRequired,
  sort: PropTypes.object,
};

ReportsComponent.defaultProps = {
  sort: null,
};

const Reports = flashConnect(PAGES.REPORTS, 'Settings/reports')(
  ReportsComponent,
);

Reports.displayName = 'SettingsReports';

export default Reports;
