/**
 * Looking to work with something in Settings? Read doc/settings.md.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { kebabCase, difference } from 'lodash';

import withMessages from 'component-utilities/intl';

import ManagementList, { ManagementListRow } from 'v-c/ManagementList';

import { jsUi, ROUTES } from '../../global/lib/jsUi';

import Layout from '../../global/components/Layout';
import HeadlineLabel from '../../global/components/HeadlineLabel';

import styles from './components/Settings.scss';
import Beta from './components/Beta';
import getVisibleRows from './components/getVisibleRows';

import { SETTINGS } from '../../config/constants';
import SETTINGS_DETAILS from '../../config/constants/settings.json';

const HANDLERS = SETTINGS_DETAILS.reduce(
  (all, { id, routeName }) => ({ ...all, [id]: ROUTES[routeName] }),
  {},
);

const mapStateToProps = ({ userRights, domainInfo }: any) => ({
  activeRoles: userRights.activeRoles,
  domainInfo,
});

type Props = {
  getMessage: Function,
}

@connect(mapStateToProps)
@withMessages
export default class Settings extends Component<Props> {
  static propTypes = {
    getMessage: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    // enabled/disabled features will not change while this component is on-screen
    const sectionsDifference = difference(
      Object.keys(HANDLERS),
      Object.keys(SETTINGS),
    ).concat(difference(Object.keys(SETTINGS), Object.keys(HANDLERS)));

    if (sectionsDifference.length > 0) {
      throw new Error(
        `HANDLERS and SETTINGS are out of sync! Fix one or the other.
        Differences: ${JSON.stringify(sectionsDifference)}`,
      );
    }

    this.visibleRows = getVisibleRows(props);
  }

  get contentValues() {
    const { getMessage } = this.props;

    return Object.keys(this.visibleRows).map((view) => {
      const uiHandler = jsUi[HANDLERS[view]];

      const details: any = SETTINGS_DETAILS.find(({ id }) => id === view);

      if (!uiHandler) {
        throw new Error(
          `Unable to find jsUI handler for ${view}: ${HANDLERS[view]}.
            Check DeveloperInterface().getUIRoutes() to make sure you have the
            right route key.`,
        );
      }

      const getTabText = (path: string) =>
        getMessage(`app.${this.visibleRows[view]}.${path}`);
      let mainLabel = getTabText('tab_name');

      if (details.isBeta) {
        mainLabel = (
          <data>
            {mainLabel}
            <Beta />
          </data>
        );
      }

      return {
        view,
        mainLabel,
        description: getTabText('description'),
        path: uiHandler.path(),
        name: kebabCase(view),
        title: mainLabel,
      };
    });
  }

  render() {
    const { getMessage } = this.props;
    const headlineText = getMessage('app.breadcrumb.settings');

    return (
      <Layout section="settings" staticView>
        <header>
          <HeadlineLabel text={headlineText} />
        </header>
        <div className={styles['wrapper-static']}>
          <ManagementList>
            {this.contentValues.map(rowProps => (
              <ManagementListRow key={rowProps.view} {...rowProps} />
            ))}
          </ManagementList>
        </div>
      </Layout>
    );
  }
}
