import createReducer from '../lib/createReducer';

export const OPEN: string = 'modals/open';
export const CLOSE: string = 'modals/close';
export const CONFIRMED: string = 'modals/confirmed';

interface ModalsState {
  type: null | string,
  modalOptions: any,
  confirmData: any | null,
}

export const initialState: ModalsState = {
  type: null,
  modalOptions: {},
  confirmData: null,
};

export function openModal(type: string, modalOptions: any) {
  return { type: OPEN, payload: { type, modalOptions } };
}

export function closeModal(from = {}) {
  return { type: CLOSE, payload: from };
}

export function confirmModal(confirmData: any) {
  return { type: CONFIRMED, payload: { confirmData } };
}

const ModalsStateManager = createReducer(initialState, {
  [OPEN]: (state: ModalsState, { type, modalOptions }: any) => ({
    ...state,
    type,
    modalOptions,
    confirmData: null,
  }),
  [CONFIRMED]: (state: ModalsState, { confirmData }: any) => ({
    ...state,
    type: null,
    modalOptions: {},
    confirmData,
  }),
  [CLOSE]: (state: ModalsState) => ({
    ...state,
    type: null,
    modalOptions: {},
    confirmData: null,
  }),
});

export default ModalsStateManager;
