import React from 'react';
import PropTypes from 'prop-types';

import Container from './Container';
import Audits from './Audits';
import ReportsComponent from './Reports';
import ReportsLoadError from './ReportsLoadError';

import styles from './Reports.scss';

type Props = {
  isReportsLoaded: Boolean,
  reportsSort: any,
  doReportsSort: any,
  doAuditsSort: any
}

export default function LoadedContents(props: Props) {
  return (
    <Container>
      <div className={styles['reports-component-container']}>
        {props.isReportsLoaded && (
          <ReportsComponent
            {...props}
            sort={props.reportsSort}
            setSortColumn={props.doReportsSort}
          />
        )}

        {!props.isReportsLoaded && <ReportsLoadError />}
      </div>

      <Audits {...props} doSort={props.doAuditsSort} />
    </Container>
  );
}

LoadedContents.propTypes = {
  isReportsLoaded: PropTypes.bool.isRequired,
  reportsSort: PropTypes.object.isRequired,
  doReportsSort: PropTypes.func.isRequired,
  doAuditsSort: PropTypes.func.isRequired,
};

LoadedContents.displayName = 'Settings/Reports/LoadedContents';
