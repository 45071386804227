import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';

import { TYPES } from 'v-c/Templates/UserEditView/UserEditView';

import Loading from '../../global/components/Loading';
import Layout from '../../global/components/Layout';

import routeLeaveHook from '../../global/lib/routeLeaveHook';
import LoadWhenReady from '../../global/lib/LoadWhenReady';

import { loadLoginAttemptRestriction } from '../../global/state_managers/LoginAttemptRestrictionStateManager';
import {
  loadUser,
  resetUser,
} from '../../global/state_managers/UserStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';
import { Params } from 'global/types';

const UserForm = Loadable({
  loader: () => import('./components/UserForm'),
  loading() {
    return <Loading />;
  },
});

const mapStateToProps = ({ loginAttemptRestriction, user }: any) => ({
  loginAttemptRestriction: loginAttemptRestriction.data,
  isUserLoaded: user.isLoaded,
  user,
});

const mapActionsToProps = {
  loadLoginAttemptRestriction,
  loadUser,
  resetUser,
  setBreadcrumbForUsersEditUserPage: breadcrumbsActions.usersEditUserPage,
};

type Props = {
  params: Params,
  isUserLoaded: boolean,
  isAttemptsRestrictrionLoaded: boolean,
  loadLoginAttemptRestriction: Function,
  loadUser: Function,
  resetUser: Function,
  setBreadcrumbForUsersEditUserPage: Function,
}

@routeLeaveHook
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class EditUser extends Component<Props> {
  static propTypes = {
    params: PropTypes.object.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    isAttemptsRestrictrionLoaded: PropTypes.bool,
    loadLoginAttemptRestriction: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    resetUser: PropTypes.func.isRequired,
    setBreadcrumbForUsersEditUserPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAttemptsRestrictrionLoaded: false,
  };

  constructor(props: Props) {
    super(props);

    props.loadUser(props.params.user_id);
    props.loadLoginAttemptRestriction();
    props.setBreadcrumbForUsersEditUserPage();
  }

  componentWillUnmount() {
    this.props.resetUser();
  }

  render() {
    return (
      <Layout section="edit-user" staticView>
        <LoadWhenReady readyStates={[this.props.isUserLoaded]}>
          <UserForm {...this.props} type={TYPES.EDIT_USER} />
        </LoadWhenReady>
      </Layout>
    );
  }
}
