import { isArray } from 'lodash';

import { formatTZDate } from 'component-utilities/dates/dates';
import { SortCell } from 'view-components/components/TableView';

import reduxStore, { Action } from '../reduxStore';
import { PAGES } from '../components/FlashMessages';
import configs from '../../config/configs';
import MODAL_MAP from '../components/Modals/modalMap.json';

import { openModal } from './ModalsStateManager';
import { LOAD_ASSET_START } from './AssetStateManager';

const { GENERAL_CONFIGS } = configs;

export const OPEN_SEARCH_RESULTS_STREAM_START: string =
  'searchResults/openSearchResultsStreamStart';
export const OPEN_SEARCH_RESULTS_STREAM_FINISHED: string =
  'searchResults/openSearchResultsStreamFinished';
export const OPEN_SEARCH_RESULTS_STREAM_FAILED: string =
  'searchResults/openSearchResultsStreamFailed';

export const LOAD_MORE_RESULTS_START: string = 'searchResults/loadMoreResultsStart';
export const LOAD_MORE_RESULTS_FINISHED: string =
  'searchResults/loadMoreResultsFinished';
export const LOAD_MORE_RESULTS_FAILED: string = 'searchResults/loadMoreResultsFailed';

export const CLOSE_SEARCH_RESULTS_STREAM: string =
  'searchResults/closeSearchResultsStream';

export const UPDATE_RESULTS: string = 'searchResults/updateResults';

export const SOFT_DELETE_ITEM: string = 'searchResults/softDeleteItem';

export const SHOW_SIZE_LIMIT_MODAL: string =
  'searchResults/isSizeLimitWarningModalAlreadyShown';
export const RESET_SIZE_LIMIT_MODAL: string = 'searchResults/resetSizeLimitModal';

export const ADD_DELAYED_STREAM_ID: string = 'searchResults/addDelayedStreamId';
export const REMOVE_DELAYED_STREAM_ID: string = 'searchResults/removeDelayedStreamId';

export const SET_INITIAL_TABLE_CONFIG: string = 'searchResults/setInitialTableConfig';
export const INIT_TABLE_CONFIG: string = 'searchResults/initTableConfig';

export const SORT_TABLE_START: string = 'searchResults/sortTableStart';
export const SORT_TABLE_FINISHED: string = 'searchResults/sortTableFinished';
export const SORT_TABLE_FAILED: string = 'searchResults/sortTableFailed';

export const UPDATE_REVIEW_TAG_COMPLETED: string =
  'searchResults/updateReviewTagCompleted';
export const UPDATE_LEG_HOLD_REASONS_COMPLETED: string =
  'searchResults/updateLegalHoldReasonsCompleted';

export const CHANGE_APP_VIEW: string = 'searchResults/changeAppView';

export const UPDATE_RETURN_COUNT: string = 'searchResults/updateReturnCount';

export const ACTIVE_COLUMNS_CHANGED: string = 'searchResults/activeColumnsChanged';

export const UPDATE_ALREADY_REQUESTED_PACKS: string =
  'searchResults/updateAlreadyRequestedPacks';
export const RESET_ALREADY_REQUESTED_PACKS: string =
  'searchResults/resetAlreadyRequestedPacks';

export const SET_SELECTED_SEARCH_ID: string = 'searchResults/setSelectedSearchId';
export const SET_SELECTED_FIXED_FILTER: string = 'searchResults/setSelectedFixedFilter';
export const SET_COMMENT_COUNT: string = 'searchResults/setCommentCount';

export const RESET_STREAM_QUERY: string = 'searchResults/resetStreamQuery';
export const RESET_RESULTS: string = 'searchResults/resetResults';
export const RESET_STREAM: string = 'searchResults/resetStream';
export const RESET_LOADING_TIME: string = 'searchResults/resetLoadingTime';
export const RESET_SORT_OPTIONS: string = 'searchResults/resetSortOptions';
export const RESET: string = 'searchResults/reset';

// Init
export const BASE_FLASH: string = 'saved_search';
export const VALID_FLASH_VIEWS: string[] = [PAGES.SEARCHES];
export const PACK_LIMIT: number = 50;

export const COLUMNS = {
  SUBJECT: 'subject',
  COMMENT: 'comment',
  ATTACHMENT: 'attachment',
  FROM: 'from',
  DATE: 'date',
  HOLDS: 'holds',
  REVIEW: 'review',
  OPEN_ASSET: 'openAsset',
};

const TABLE_SORT_KEYS = {
  subject: 'subject.verbatim',
  attachment: 'attachment-count',
  comment: 'comment-count',
  from: 'from.verbatim',
  date: 'date',
  holds: 'holds',
  review: 'review',
};

const { SUBJECT, ATTACHMENT, FROM, DATE, HOLDS, REVIEW, OPEN_ASSET, COMMENT } = COLUMNS;

export const APP_VIEWS = {
  SPLIT: 'split-view',
  SINGLE: 'single-view',
};

// Use the old single view for the rspec tests
const initialAppView =
  window.navigator.userAgent.indexOf('PhantomJS') === -1
    ? APP_VIEWS.SPLIT
    : APP_VIEWS.SINGLE;

interface SearchResultsState {
  isLoading: boolean,
  isLoaded: boolean,
  isProcessing: boolean,

  results: any[],
  isSizeLimitWarningModalAlreadyShown: boolean,
  isMyArchive: boolean,
  stream: any,
  streamReturnCount: number,
  resetIndex: number,
  sampleSearchRunning: any[],
  tableHelper: {
    columns: any,
    allColumns: string[],
    activeColumns: string[],
    disabledColumns: string[],
    tableSortKeys: any,
  },
  sortOptions: {
    sortKey: string,
    sortOrder: string,
  },
  tableConfigs: any,
  activeSearchId: null | string,
  appView: string,
  appViews: any,
  returnCount: number,
  loadingTime: number,
  streamQuery: null | any,
  alreadyRequestedPacks: any[],
  // My Archive
  selectedSearchId: string,
  selectedFixedFilter: string,
}

export const INITIAL_STATE: SearchResultsState = {
  isLoading: false,
  isLoaded: false,
  isProcessing: false,

  results: [],
  isSizeLimitWarningModalAlreadyShown: false,
  isMyArchive: false,
  stream: {},
  streamReturnCount: 0,
  resetIndex: 0,
  sampleSearchRunning: [],
  tableHelper: {
    columns: COLUMNS,
    allColumns: [SUBJECT, ATTACHMENT, COMMENT, FROM, DATE, HOLDS, REVIEW],
    activeColumns: [SUBJECT, ATTACHMENT, COMMENT, FROM, DATE, HOLDS, REVIEW],
    disabledColumns: [SUBJECT, OPEN_ASSET],
    tableSortKeys: TABLE_SORT_KEYS,
  },
  sortOptions: {
    sortKey: TABLE_SORT_KEYS.date,
    sortOrder: SortCell.sortKeys.DESC,
  },
  tableConfigs: {},
  activeSearchId: null,
  appView: initialAppView,
  appViews: APP_VIEWS,
  returnCount: 0,
  loadingTime: 0,
  streamQuery: null,
  alreadyRequestedPacks: [],
  // My Archive
  selectedSearchId: '',
  selectedFixedFilter: 'All',
};

// helpers
export function searchResultsState() {
  return reduxStore.getState().searchResults;
}

export function updateItemData(assetId: string, updateFn: Function) {
  return searchResultsState().results.map((result: any) => {
    if (result) {
      let modifiedResult = { ...result };

      if (result.id === assetId) {
        modifiedResult = updateFn(modifiedResult);
      }

      return modifiedResult;
    }

    return {};
  });
}

// actions
export function setCommentCount(documentId: string, commentCount: number) {
  const res = reduxStore.getState().searchResults.results.map((document: any) => {
    if (document.id === documentId) {
      return {
        ...document,
        commentCount,
      };
    }
    return document;
  });
  return {
    type: SET_COMMENT_COUNT,
    payload: res,
  };
}

export function setSelectedSearchId(searchId: string) {
  return {
    type: SET_SELECTED_SEARCH_ID,
    payload: {
      searchId,
    },
  };
}

export function setSelectedFixedFilter(filter: string) {
  return {
    type: SET_SELECTED_FIXED_FILTER,
    payload: {
      filter,
    },
  };
}

export function newResultsLoaded(min: number, { returnCount, items: results, offset }: any, fixedFilter: string, searchId: string) {
  return function* doNewResultsLoaded() {
    if (!isArray(results)) {
      return;
    }

    // one of these will be true if user clicks
    // another filter/search before the last
    // api request finished
    if (searchResultsState().isMyArchive) {
      if (fixedFilter && searchResultsState().selectedFixedFilter !== fixedFilter) {
        yield { type: LOAD_ASSET_START };
        return;
      } else if (searchId && searchResultsState().selectedSearchId !== searchId) {
        yield { type: LOAD_ASSET_START };
        return;
      }
    }

    const updatedResults = results.reduce(
      (_updatedResults: any, item: any, idx: number) => {
        const modifiedResults = [..._updatedResults];
        const modifiedItem = { ...item };

        // If the subject is array (indexed multiple subjects) join it to a simple string
        modifiedItem.subject = isArray(item.subject)
          ? item.subject.join(' ')
          : item.subject;

        // process dates
        modifiedItem.originalDate = item.date;
        modifiedItem.date = formatTZDate(modifiedItem.originalDate);

        modifiedResults[idx + min] = modifiedItem;

        return modifiedResults;
      },
      [...searchResultsState().results],
    );

    yield {
      type: UPDATE_RESULTS,
      payload: {
        results: updatedResults,
        returnCount,
        limitedReturnCount: Math.min(
          GENERAL_CONFIGS.ASSET_MODAL_LIMIT,
          returnCount,
        ),
      },
    };

    const {
      isSizeLimitWarningModalAlreadyShown,
    } = searchResultsState();

    if (
      offset >= GENERAL_CONFIGS.OFFSET_MODAL_LIMIT &&
      returnCount >= GENERAL_CONFIGS.ASSET_MODAL_LIMIT &&
      !isSizeLimitWarningModalAlreadyShown
    ) {
      reduxStore.dispatch(openModal(MODAL_MAP.SIZE_LIMIT_WARNING));

      yield { type: SHOW_SIZE_LIMIT_MODAL };
    }
  };
}

export function resetSizeLimitModal() {
  return { type: RESET_SIZE_LIMIT_MODAL };
}

export function softDeleteItem(itemId: string, itemIdx: number, cb: Function) {
  return function* doSoftDeleteItem() {
    const modifiedResults = searchResultsState().results.filter(
      (value: any) => !value || value.id !== itemId,
    );

    yield {
      type: SOFT_DELETE_ITEM,
      payload: { results: modifiedResults },
    };

    cb(Math.min(itemIdx + 1, modifiedResults.length) - 1);
  };
}

export function updateLegalHoldReasonsCompleted(reasons: any[], assetId: string) {
  return function* doUpdateLegalHoldReasonsCompleted() {
    const updatedResults = updateItemData(assetId, (result: any) => {
      const modifiedResult = { ...result };
      modifiedResult.reasonsCount = reasons.length;
      modifiedResult.reasons = reasons;

      return modifiedResult;
    });

    yield {
      type: UPDATE_LEG_HOLD_REASONS_COMPLETED,
      payload: { results: updatedResults },
    };
  };
}

export function updateReviewTagCompleted(reviewStatus: any, assetId: string) {
  return function* doUpdateReviewTagCompleted() {
    const updatedResults = updateItemData(assetId, (result: any) => {
      const modifiedResult = { ...result };
      modifiedResult.reviewStatus = reviewStatus;

      return modifiedResult;
    });

    yield {
      type: UPDATE_REVIEW_TAG_COMPLETED,
      payload: { results: updatedResults },
    };
  };
}

export function changeAppView(newView: string) {
  return { type: CHANGE_APP_VIEW, payload: { newView } };
}

export function resetResults() {
  return { type: RESET_RESULTS };
}

export function resetSearchResults() {
  return { type: RESET };
}

export function resetLoadTime() {
  return { type: RESET_LOADING_TIME };
}

// Store
export default function SearchResultsStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case SET_INITIAL_TABLE_CONFIG:
    case ACTIVE_COLUMNS_CHANGED:
      return {
        ...state,
        tableConfigs: payload.tableConfigs,
      };
    case SET_COMMENT_COUNT:
      return {
        ...state,
        results: payload,
      };
    case SET_SELECTED_FIXED_FILTER:
      return {
        ...state,
        selectedFixedFilter: payload.filter,
      };
    case SET_SELECTED_SEARCH_ID:
      return {
        ...state,
        selectedSearchId: payload.searchId,
      };
    case INIT_TABLE_CONFIG:
      return {
        ...state,
        activeSearchId: payload.activeSearchId,
        isMyArchive: payload.isMyArchive,
      };
    case UPDATE_RETURN_COUNT:
      return {
        ...state,
        returnCount: payload.returnCount,
      };
    case RESET_STREAM:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        stream: {},
        results: [],
      };
    case OPEN_SEARCH_RESULTS_STREAM_START:
      return {
        ...state,
        returnCount: 0,
        loadingTime: 0,
        isLoaded: false,
        isLoading: true,
        stream: {},
        results: [],
      };
    case OPEN_SEARCH_RESULTS_STREAM_FINISHED:
      return {
        ...state,
        stream: payload.stream,
        isLoaded: true,
        isLoading: false,
        loadingTime: payload.loadingTime,
        streamQuery: state.streamQuery || payload.stream.query,
        alreadyRequestedPacks: [[0, PACK_LIMIT]],
      };
    case LOAD_MORE_RESULTS_FAILED:
    case OPEN_SEARCH_RESULTS_STREAM_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_DELAYED_STREAM_ID:
      return {
        ...state,
        sampleSearchRunning: [...state.sampleSearchRunning, payload.id],
      };
    case REMOVE_DELAYED_STREAM_ID:
      return {
        ...state,
        sampleSearchRunning: state.sampleSearchRunning.filter(
          id => id !== payload.id,
        ),
      };
    case UPDATE_RESULTS:
      return {
        ...state,
        results: payload.results,
        returnCount: payload.returnCount,
        limitedReturnCount: payload.limitedReturnCount,
      };
    case SOFT_DELETE_ITEM:
      return {
        ...state,
        results: payload.results,
        returnCount: state.returnCount - 1,
        limitedReturnCount: state.limitedReturnCount - 1,
      };
    case UPDATE_LEG_HOLD_REASONS_COMPLETED:
    case UPDATE_REVIEW_TAG_COMPLETED:
      return {
        ...state,
        results: payload.results,
      };
    case SHOW_SIZE_LIMIT_MODAL:
      return {
        ...state,
        isSizeLimitWarningModalAlreadyShown: true,
      };
    case RESET_SIZE_LIMIT_MODAL:
      return {
        ...state,
        isSizeLimitWarningModalAlreadyShown: false,
      };
    case CHANGE_APP_VIEW:
      return {
        ...state,
        appView: payload.newView,
      };
    case UPDATE_ALREADY_REQUESTED_PACKS:
      return {
        ...state,
        alreadyRequestedPacks: payload.alreadyRequestedPacks,
      };
    case SORT_TABLE_START:
      return {
        ...state,
        sortOptions: payload,
        alreadyRequestedPacks: [],
      };
    case RESET_ALREADY_REQUESTED_PACKS:
      return {
        ...state,
        alreadyRequestedPacks: [],
      };
    case RESET_STREAM_QUERY:
      return {
        ...state,
        streamQuery: null,
      };
    case RESET_LOADING_TIME:
      return {
        ...state,
        loadingTime: 0,
      };
    case SORT_TABLE_FINISHED:
    case RESET_RESULTS:
      return {
        ...state,
        results: [],
        alreadyRequestedPacks: [],
      };
    case RESET_SORT_OPTIONS:
      return {
        ...state,
        sortOptions: {
          sortKey: TABLE_SORT_KEYS.date,
          sortOrder: SortCell.sortKeys.DESC,
        },
      };
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
