import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { TYPES } from 'v-c/Templates/UserEditView/UserEditView';

import Layout from '../../global/components/Layout';
import routeLeaveHook from '../../global/lib/routeLeaveHook';

import UserForm from './components/UserForm';

import {
  createUser,
  resetUser,
} from '../../global/state_managers/UserStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

const mapStateToProps = ({ user }: any) => ({
  username: user.user.username,
  user,
});

const mapActionsToProps = {
  createUser,
  resetUser,
  setBreadcrumbForUsersNewUserPage: breadcrumbsActions.usersNewUserPage,
};

type Props = {
  resetUser: Function,
  setBreadcrumbForUsersNewUserPage: Function,
  createUser: Function,
  username: string
}

@routeLeaveHook
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class NewUser extends Component<Props> {
  static propTypes = {
    username: PropTypes.string.isRequired,
    createUser: PropTypes.func.isRequired,
    resetUser: PropTypes.func.isRequired,
    setBreadcrumbForUsersNewUserPage: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    props.resetUser();
    props.setBreadcrumbForUsersNewUserPage();
    this.initialRender = true;
  }

  onSave = (formDataToSubmit: {}) => {
    this.props.createUser(formDataToSubmit);
  };

  render() {
    if (this.props.username.length && this.initialRender) {
      return null;
    }

    this.initialRender = false;

    return (
      <Layout section="new-user" staticView>
        <UserForm {...this.props} type={TYPES.NEW_USER} onSave={this.onSave} />
      </Layout>
    );
  }
}
