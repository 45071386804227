/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component, ReactNode } from 'react';
import PropTypes from 'prop-types';

import withMessages from 'view-components/utilities/intl';

type Props = {
  children: ReactNode
}

@withMessages
export default class AppContainer extends Component<Props> {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <section className="container-fluid app">
        {this.props.children}
      </section>
    );
  }
}
