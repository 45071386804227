/* eslint-disable no-use-before-define */
import { isEqual } from 'lodash';
import Cookie from 'cookies-js';

import reduxStore from '../reduxStore';

import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';
import { jsUi } from '../lib/jsUi';
import gaEvents from '../lib/ga';

import {
  getLoggedInAs as getLocalLoggedInAs,
  setLoggedInAs,
  resetLocalStorage,
} from '../lib/localStorage';
import {
  setRequestInterceptors,
  setResponseInterceptors,
  ejectInterceptors,
} from '../lib/axiosInterceptors';
import authenticationHelper, {
  initAuthenticationHelper,
  authenticatedResponseFailureCallback,
  requestsMiddleware,
  responseFailureCallbackBase,
} from './helpers/authenticationHelper';
import { getAccountDetails } from '../lib/domainInfo';
import { getHomePage } from '../lib/userRights';
import constants from '../constants/Roles';

import { successLogin } from './UserRightsStateManager';
import { resetFlash } from './FlashMessagesStateManager';
import {
  submitForm,
  resetLoginForm,
  loginFailed,
} from './LoginFormStateManager';
import { resetUnsavedSearch } from './UnsavedSearchStateManager';
import { Action } from '../reduxStore';

const { Roles } = constants;

export const INIT: string = 'authentication/init';
export const UPDATE_LOGGED_IN_AS: string = 'authentication/updateLoggedInAs';
export const STARTING_TO_FORCE_LOGOUT: string = 'authentication/startingToForceLogout';
export const FORCED_LOGOUT: string = 'authentication/forcedLogout';
export const FAILED_TO_FORCE_LOGOUT: string = 'authentication/failedToForceLogout';
export const RESET: string = 'authentication/reset';
export const ATTEMPTING_LOGIN: string = 'authentication/attemptingLogin';
export const ATTEMPTING_TOKEN_LOGIN: string = 'authentication/attemptingTokenLogin';
export const ATTEMPTING_OPEN_ID_LOGIN: string = 'authentication/attemptingOpenIdLogin';
export const STARTING_TO_LOGIN: string = 'authentication/startingToLogin';
export const SUCCESS_LOGIN: string = 'authentication/successLogin';
export const FAILED_TO_LOGIN: string = 'authentication/failedToLogin';
export const SHOW_SESSION: string = 'authentication/showSession';
export const SHOW_SESSION_FINISHED: string = 'authentication/showSessionFinished';
export const SHOW_SESSION_FAILED: string = 'authentication/showSessionFailed';
export const CLEAR_LOGGED_IN_AS: string = 'authentication/clearLoggedInAs';

export const AUTH_COOKIE: string = 'authentication_token';
export const NO_UPDATE: string = 'no_update';
export const UNABLE_TO_LOGIN: string = 'unable_to_login';
export const UNABLE_TO_CHECK_SESSION: string = 'unable_to_check_session';
export const UNABLE_TO_FORCE_LOGOUT: string = 'unable_to_force_logout';

interface AuthenticationState {
  loggedInAs: any,
  attemptingLogin: boolean,
  lastAttemptFailed: boolean,
  sessionDataLoadedFromAPI: boolean,
  redirectTo: string | null,
  justForcedLogout: boolean,
}

// Init
export const INITIAL_STATE: AuthenticationState = {
  loggedInAs: null,
  attemptingLogin: false,
  lastAttemptFailed: false,
  sessionDataLoadedFromAPI: false,
  redirectTo: null,
  justForcedLogout: false,
};

// helpers
export function getSessionId(loggedInAs = authenticationHelper().loggedInAs) {
  if (!loggedInAs || !loggedInAs.authenticationToken) {
    return false;
  }

  return loggedInAs.authenticationToken.split('/').reverse()[0];
}

export async function updateStateWithLocalIfNecessary() {
  const localAuthInfo = getLocalLoggedInAs();
  const stateAuthInfo = authenticationHelper().loggedInAs;

  if (!isEqual(stateAuthInfo, localAuthInfo)) {
    await reduxStore.dispatch(updateLoggedInAs(localAuthInfo));
  }

  return Promise.resolve(NO_UPDATE);
}

export function isLoggedIn() {
  const { loggedInAs, justForcedLogout } = authenticationHelper();

  return loggedInAs !== null && !justForcedLogout;
}

export function getLoggedInAs() {
  updateStateWithLocalIfNecessary();

  return authenticationHelper().loggedInAs;
}

export function setAxiosInterceptors() {
  const loggedInAs = getLocalLoggedInAs();

  setRequestInterceptors(loggedInAs, requestsMiddleware);
  setResponseInterceptors(loggedInAs, authenticatedResponseFailureCallback());
}

export function setAuthCookie(loggedInAs = getLoggedInAs()) {
  Cookie.set(AUTH_COOKIE, loggedInAs.authenticationToken);
}

export function loginUser({ action, endpoint, params, redirectTo }: any) {
  reduxStore.dispatch(submitForm());

  return function* doLoginUser() {
    try {
      yield { type: action };
      yield { type: STARTING_TO_LOGIN };

      const { status, data: responseData } = yield apiReqPromise(
        endpoint,
        params,
      );

      if (status === 200) {
        reduxStore.dispatch(successLogin(responseData));
        reduxStore.dispatch(resetLoginForm());

        const fixedAuthInfo = { ...responseData };
        fixedAuthInfo.roles = fixedAuthInfo.roles.filter(role => !!Roles[role]);

        setAuthCookie(fixedAuthInfo);
        setLoggedInAs(fixedAuthInfo);
        setAxiosInterceptors();
        gaEvents.userLoggedIn();

        yield {
          type: SUCCESS_LOGIN,
          payload: { loggedInAs: fixedAuthInfo, redirectTo },
        };
      } else {
        reduxStore.dispatch(loginFailed(responseData));

        yield { type: FAILED_TO_LOGIN };
      }
    } catch (e) {
      reduxStore.dispatch(loginFailed(e));

      yield { type: FAILED_TO_LOGIN };
    }
  };
}

export function goToHomePage() {
  try {
    const { siteName } = getAccountDetails();

    jsUi[getHomePage()].goTo({ site_name: siteName });
  } catch (e) {
    reduxStore.dispatch(forceLogout());
    reduxStore.dispatch(loginFailed(e));

    throw e;
  }
}

// Actions
export function initAuthentication() {
  initAuthenticationHelper({
    getLoggedInAs,
    isLoggedIn,
    forceLogout,
    sessionsShow,
  });
  setAxiosInterceptors();

  return { type: INIT, payload: { loggedInAs: getLocalLoggedInAs() } };
}

export function startAttemptingLogin({ data, isValid, redirectTo }: any) {
  if (isValid) {
    return loginUser({
      action: ATTEMPTING_LOGIN,
      endpoint: jsApi.sessionsCreate,
      params: { params: { data } },
      redirectTo,
    });
  }

  return Promise.resolve(UNABLE_TO_LOGIN);
}

export function startAttemptingTokenLogin({ token, redirectTo }: any) {
  return loginUser({
    action: ATTEMPTING_TOKEN_LOGIN,
    endpoint: jsApi.sessionsCreateFromToken,
    params: { urlParams: { token } },
    redirectTo,
  });
}

export function startAttemptingOpenIDLogin({ hash, redirectTo }: any) {
  return loginUser({
    action: ATTEMPTING_OPEN_ID_LOGIN,
    endpoint: jsApi.sessionsCreateFromOpenidToken,
    params: { urlParams: { user_payload_hash: hash } },
    redirectTo,
  });
}

export function sessionsShow() {
  const sessionId = getSessionId();

  if (sessionId) {
    return function* doSessionsShow() {
      try {
        yield { type: SHOW_SESSION };

        yield apiReqPromise(jsApi.sessionsShow, {
          urlParams: { id: 'placeholder' },
          queryParams: `?session_id=${sessionId}`,
        });

        yield { type: SHOW_SESSION_FINISHED };
      } catch (e) {
        const result = responseFailureCallbackBase(e);

        // responseFailureCallback didn't handle one of the special
        // statuses for you, and you aren't logged out yet.
        if (result === false) {
          reduxStore.dispatch(
            resetFlash({
              from: 'AuthenticationStateManager/handleSessionVerification',
            }),
          );

          reduxStore.dispatch(forceLogout());
        }

        yield { type: SHOW_SESSION_FAILED };
      }
    };
  }

  reduxStore.dispatch(forceLogout());
  return Promise.resolve(UNABLE_TO_CHECK_SESSION);
}

export function forceLogout() {
  function cleanupAfterForcedLogout() {
    resetLocalStorage();
    ejectInterceptors();
  }
  const sessionId = getSessionId();

  if (sessionId) {
    reduxStore.dispatch(resetUnsavedSearch());

    return function* doForceLogout() {
      try {
        yield { type: STARTING_TO_FORCE_LOGOUT };
        jsUi.loginWithRedirect.goTo();

        yield apiReqPromise(jsApi.sessionsDestroy, {
          urlParams: { id: 'placeholder' },
          queryParams: `?session_id=${sessionId}`,
        });

        yield { type: FORCED_LOGOUT };

        cleanupAfterForcedLogout();
      } catch (e) {
        yield { type: FAILED_TO_FORCE_LOGOUT };

        cleanupAfterForcedLogout();
      }
    };
  }

  cleanupAfterForcedLogout();
  return Promise.resolve(UNABLE_TO_FORCE_LOGOUT);
}

export function updateLoggedInAs(loggedInAs: any) {
  return { type: UPDATE_LOGGED_IN_AS, payload: { loggedInAs } };
}

export function clearLoggedInAs() {
  return { type: CLEAR_LOGGED_IN_AS };
}

export function resetAuthentication() {
  return { type: RESET };
}

// Store
export default function AuthenticationStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case INIT:
      return { ...INITIAL_STATE, loggedInAs: payload.loggedInAs };
    case UPDATE_LOGGED_IN_AS:
      return { ...state, loggedInAs: payload.loggedInAs };
    case STARTING_TO_LOGIN:
      return {
        ...state,
        attemptingLogin: true,
        lastAttemptFailed: false,
        loginSuccessful: false,
      };
    case SUCCESS_LOGIN:
      return {
        ...state,
        attemptingLogin: false,
        loginSuccessful: true,
        justForcedLogout: false,
        loggedInAs: payload.loggedInAs,
        redirectTo: payload.redirectTo,
      };
    case STARTING_TO_FORCE_LOGOUT:
      return {
        ...state,
        justForcedLogout: true,
      };
    case CLEAR_LOGGED_IN_AS:
    case FAILED_TO_FORCE_LOGOUT:
    case FORCED_LOGOUT:
      return {
        ...state,
        attemptingLogin: false,
        loggedInAs: null,
        justForcedLogout: false,
      };
    case FAILED_TO_LOGIN:
      return {
        ...state,
        attemptingLogin: false,
        lastAttemptFailed: true,
      };
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}