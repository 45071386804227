/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isDate } from 'lodash';
import withMessages from 'component-utilities/intl';
import { formatDate, DATE_FORMATS } from 'component-utilities/dates/dates';
import {
  Dropdown,
  DropdownDatepicker,
} from 'view-components/components/Widgets/Components';

import getLocalizedData from '../../../lib/getLocalizedData';

import criteriaViewBase from './Components/CriteriaViewBase';
import styles from './Components/CriteriaViewBase.scss';

type Props = {
  getMessage: Function
  form: {
    selectedCriteria: string
    from: string
    to: string
  }
  listElem: {
    criteriaType: string
    values: {
      from: string
      to: string
      dependency: string
      selectedCriteria: boolean
    }
  }
  updateBaseState: Function
  criteriasVisibilities: {}
  setInitialFormAndOriginalValues: Function
  registerCalculateVisibleCriteriaNum: Function
  registerIsEmptyCriteria: Function
  registerRenderHeaderText: Function
  registerIsApplyButtonDisabled: Function
  changeFormValue: Function
  isDirty: Function
  isViewOnly: boolean
  killEvent: Function
  getOriginalFormValues: Function
  renderGreenText: Function
  renderContent: Function
}

@withMessages
@criteriaViewBase
export default class DatesCriteriaView extends Component<Props> {
  static propTypes = {
    getMessage: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    listElem: PropTypes.object.isRequired,
    updateBaseState: PropTypes.func.isRequired,
    criteriasVisibilities: PropTypes.object.isRequired,
    setInitialFormAndOriginalValues: PropTypes.func.isRequired,
    registerCalculateVisibleCriteriaNum: PropTypes.func.isRequired,
    registerIsEmptyCriteria: PropTypes.func.isRequired,
    registerRenderHeaderText: PropTypes.func.isRequired,
    registerIsApplyButtonDisabled: PropTypes.func.isRequired,
    changeFormValue: PropTypes.func.isRequired,
    isDirty: PropTypes.func.isRequired,
    isViewOnly: PropTypes.bool.isRequired,
    killEvent: PropTypes.func.isRequired,
    getOriginalFormValues: PropTypes.func.isRequired,
    renderGreenText: PropTypes.func.isRequired,
    renderContent: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    const { listElem: { values }, form } = props;

    this.criteriaValues = getLocalizedData.apply(this, [
      'advDatesValuesSelections',
    ]);

    props.updateBaseState({
      criteriasVisibilities: {
        ...props.criteriasVisibilities,
        isRelationVisible: false,
      },
    });

    props.setInitialFormAndOriginalValues(
      values.selectedCriteria
        ? values
        : {
          ...values,
          ...form,
          relation: 'or',
          selectedCriteria: this.criteriaValues[0].id,
          from: '',
          to: '',
        },
    );

    this.state = {
      visibleCriteria: 0,
    };

    props.registerCalculateVisibleCriteriaNum(() => {});
    props.registerIsEmptyCriteria(this.isEmptyCriteria);
    props.registerRenderHeaderText(this.renderHeaderText);
    props.registerIsApplyButtonDisabled(this.isApplyButtonDisabled);
  }

  onCriteriaChange = (widgetId: string, newValue: string) => {
    const isRangeSelection = (selectedId: string) =>
      this.criteriaValues.some(
        ({ id, isRange }: any) => id === selectedId && !!isRange,
      );

    const shouldClearToDateValue = () => {
      if (!isRangeSelection(newValue)) {
        this.props.changeFormValue('to', null);
      }
    };

    this.props.changeFormValue(widgetId, newValue, shouldClearToDateValue);
  };

  getSelectedCriteriaObject = () => {
    const { form: { selectedCriteria } } = this.props;

    return this.criteriaValues.find(({ id }: any) => selectedCriteria === id) || {};
  };

  isApplyButtonDisabled = () => {
    const isDirty = this.props.isDirty();
    const { isRange = false } = this.getSelectedCriteriaObject();
    const { form: { from, to } } = this.props;

    if (!isDate(from)) return true;

    if (isRange && (!isDate(from) || !isDate(to))) {
      return true;
    }

    return !isDirty;
  };

  isEmptyCriteria = (newValues = this.props.getOriginalFormValues()) => {
    const { isRange = false } = this.getSelectedCriteriaObject();
    const { from, to } = newValues;

    return !isDate(from) || (isRange && (!isDate(from) || !isDate(to)));
  };

  isRangeSelection = () => {
    const { isRange = false } = this.getSelectedCriteriaObject();

    return isRange;
  };

  createDatepickerOptions = () => {
    const isRangeSelection = this.isRangeSelection();
    const { isViewOnly, form: { from, to } } = this.props;

    const generalDatePickerProps = {
      type: 'small',
      onSelectedValue: !isViewOnly && this.props.changeFormValue,
      onClick: this.props.killEvent,
    };
    const datepickerProps: any = {};

    ['from', 'to'].forEach((datetype) => {
      const date = this.props.form[datetype];
      const value = isDate(date) ? formatDate(date, DATE_FORMATS.SHORT) : '';
      const placeholder = this.props.getMessage(
        datetype === 'from'
          ? `app.placeholder.${isRangeSelection ? 'from' : 'select'}_date`
          : 'app.placeholder.to_date',
      );

      datepickerProps[datetype] = {
        ...generalDatePickerProps,
        placeholder,
        datepickerOptions: {
          widgetId: datetype,
        },
        widgetId: datetype,
        selectedValue: { date, value },
      };
    });

    if (isRangeSelection) {
      const minMaxDates = {
        min: from,
        max: to,
      };

      if (minMaxDates.min) {
        datepickerProps.to.datepickerOptions.minDate = minMaxDates.min;
      }
      if (minMaxDates.max) {
        datepickerProps.from.datepickerOptions.maxDate = minMaxDates.max;
      }
    }

    return datepickerProps;
  };

  renderHeaderText = () => {
    const {
      selectedCriteria = '',
      from,
      to,
      dependency,
    } = this.props.listElem.values;

    if (isDate(from)) {
      const selectedCriteriaObject = this.criteriaValues.find(
        ({ id }: any) => selectedCriteria === id,
      );
      const { isRange } = selectedCriteriaObject;
      const renderGreenText = (value: string) =>
        this.props.renderGreenText(
          this.props.getMessage(`app.criterias.${value}`).toLowerCase(),
        );

      return (
        <data>
          {renderGreenText(dependency)}
          {'\u00A0'}
          {this.props.renderGreenText(selectedCriteriaObject.value)}
          {'\u00A0'}
          {formatDate(from, DATE_FORMATS.SHORT)}
          {isRange && (
            <data>
              {'\u00A0'}
              {'-'}
              {'\u00A0'}
              {formatDate(to, DATE_FORMATS.SHORT)}
            </data>
          )}
        </data>
      );
    }

    return null;
  };

  renderCriteriaDropdownContent = () => {
    const {
      listElem: { criteriaType },
      isViewOnly,
      form: { selectedCriteria },
    } = this.props;
    const isRange = this.isRangeSelection();
    const datepickerProps = this.createDatepickerOptions();

    return (
      <div
        className={styles[`${criteriaType}-criteria-dropdown-content`]}
        data-criteria-content={criteriaType}
      >
        <div className={styles['dates-group-wrapper']}>
          <Dropdown
            widgetId="selectedCriteria"
            onClick={this.props.killEvent}
            color="gray"
            selectedValue={selectedCriteria}
            list={this.criteriaValues}
            onSelectedValue={!isViewOnly && this.onCriteriaChange}
          />
        </div>
        {
          <DropdownDatepicker
            {...datepickerProps.from}
            className={styles['dropdown-inline-from']}
          />
        }
        {isRange && (
          <DropdownDatepicker
            {...datepickerProps.to}
            className={styles['dropdown-inline-to']}
          />
        )}
      </div>
    );
  };

  render() {
    return this.props.renderContent(this.renderCriteriaDropdownContent);
  }
}
