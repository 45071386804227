import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  TableHeader,
  TableView,
  TableRow,
  TextCell,
  DataCell,
  SortCell,
  TableBody,
} from 'v-c/TableView';
import { TextLabel } from 'v-c/Widgets/Components';
import { prettyDate } from 'component-utilities/dates/dates';
import { jsUi } from '../../../global/lib/jsUi';
import { Search } from 'global/types';

type Props = {
  getMessage: Function,
  searchList: {
    searches: Search[],
    sortOptions: {
      sortKey: string,
      sortOrder: string
    }
  },
  sortListSearchColumn: string 
}

function ListSearchTableView({ getMessage, searchList, sortListSearchColumn }: Props) {
  const [sortKey, sortOrder] = useMemo(() => {
    const { sortOptions: { sortKey: key, sortOrder: order } } = searchList;
    return [key, order];
  }, [searchList]);

  const [cellIds] = useMemo(() => [
    {
      NAME: 'name',
      CREATED_USER: 'created-user',
      LAST_MODIFIED_TIME: 'last-modified-time',
    },
  ], []);

  const [UNSORTED, sortableColumnProps] = useMemo(() => [
    SortCell.sortKeys.UNSORTED,
    [
      {
        cellId: cellIds.NAME,
        isFloatingCell: true,
        text: getMessage('app.settings.search_lists.name'),
      },
      {
        cellId: cellIds.CREATED_USER,
        isFloatingCell: false,
        text: getMessage('app.settings.search_lists.owner'),
      },
      {
        cellId: cellIds.LAST_MODIFIED_TIME,
        isFloatingCell: false,
        text: getMessage('app.settings.search_lists.last_modified'),
      },
    ],
  ], []);

  const editClicked = useCallback((search: Search) => {
    jsUi.searchesShow.goTo({ search_id: search.id });
  }, []);

  const renderContentColumns = useCallback((search: Search) => {
    const columns = [];
    sortableColumnProps.forEach(({ cellId, isFloatingCell }) => {
      const text = cellId === cellIds.LAST_MODIFIED_TIME ? prettyDate(search[cellId]) : search[cellId];
      columns.push(
        <TextCell
          key={cellId}
          cellId={cellId}
          isFloatingCell={isFloatingCell}
        >
          { text }
        </TextCell>);
    });
    columns.push(<DataCell key="DETAILS" cellId="DETAILS">
      <TextLabel onClick={() => editClicked(search)} data-action="search-details" className="table-link-text center-text">
        {getMessage('app.settings.search_lists.details')}
      </TextLabel>
    </DataCell>);
    return columns;
  }, [searchList]);

  const renderContent = useCallback(() => {
    const rows: any[] = [];
    searchList.searches.forEach((search: Search) => {
      rows.push(<TableRow index={search.id} rowId={search.id} key={search.id} onDoubleClick={() => editClicked(search)}>
        { renderContentColumns(search) }
      </TableRow>);
    });
    return rows;
  }, [searchList]);

  const renderHeader = useCallback(() => {
    const columns = [];
    sortableColumnProps.forEach(({ cellId, isFloatingCell, text }) => {
      const newSortKey = sortKey === cellId ? sortOrder : UNSORTED;
      columns.push(
        <SortCell
          cellId={cellId}
          key={cellId}
          isFloatingCell={isFloatingCell}
          onClick={sortListSearchColumn}
          sortKey={newSortKey}
          text={text}
        />,
      );
    });
    columns.push(<DataCell cellId="DETAILS" key="DETAILS" />);
    return columns;
  }, [searchList]);

  return (
    <TableView tableId="example-table">
      <TableHeader>
        <TableRow index={0} rowId="table-header-row">
          {renderHeader()}
        </TableRow>
      </TableHeader>
      <TableBody>
        {renderContent()}
      </TableBody>
    </TableView>
  );
}

ListSearchTableView.propTypes = {
  searchList: PropTypes.object.isRequired,
  getMessage: PropTypes.func.isRequired,
  sortListSearchColumn: PropTypes.func.isRequired,
};

export default ListSearchTableView;
