/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import New from 'v-c/Buttons/ActionButton/New';
import { DropdownList } from 'v-c/Widgets/Components';

import styles from './NewWithDropdown.scss';

type Props = {
  actionHelper: {
    newWithDropdown: {
      onSelectedValue: Function
      newList: any
    }
  }
}

type State = {
  isDropdownOpen: boolean
  activeNewIcon: any
}

// TODO: deal with newDropdownList. just search the code for it and fix it up.
/* eslint-disable react/no-string-refs */
export default class NewWithDropdown extends Component<Props, State> {
  static propTypes = {
    actionHelper: PropTypes.shape({
      newWithDropdown: PropTypes.shape({
        onSelectedValue: PropTypes.func.isRequired,
        newList: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
          }),
        ),
      }),
    }).isRequired,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      activeNewIcon: undefined,
      isDropdownOpen: false,
    };
  }

  onClickOutside = () => {
    this.setState({
      activeNewIcon: undefined,
      isDropdownOpen: !this.state.isDropdownOpen,
    });
  };

  onSelectedValue = (value: string, id: string) => {
    // make sure dropdown closes on selection
    this.onClickOutside();

    this.props.actionHelper.newWithDropdown.onSelectedValue(value, id);
  };

  render() {
    const extraClassName = cx(styles.new, {
      [styles.active]: this.state.isDropdownOpen,
    });

    return (
      <span className={styles.wrapper}>
        <New
          data-action="new-action"
          extraClassName={extraClassName}
          image={this.state.activeNewIcon}
          onClick={this.onClickOutside}
        />
        {this.state.isDropdownOpen && (
          <div className={styles.dropdown}>
            <DropdownList
              widgetId="new-action-dropdown"
              ref="newDropdownList"
              onClickOutside={this.onClickOutside}
              onSelectedValue={this.onSelectedValue}
              list={this.props.actionHelper.newWithDropdown.newList}
            />
          </div>
        )}
      </span>
    );
  }
}
