import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import { FormattedMessage } from 'component-utilities/intl';
import { TABS } from 'v-c/Search/SearchTool';

import reduxStore from '../../../global/reduxStore';
import LoadWhenReady from '../../../global/lib/LoadWhenReady';

import Layout from '../../../global/components/Layout';
import Loading from '../../../global/components/Loading';
import Breadcrumb from '../../../global/components/Breadcrumb';
import HeadlineLabel from '../../../global/components/HeadlineLabel';
import FlashMessages from '../../../global/components/FlashMessages';

const SearchResultsFactory = Loadable({
  loader: () => import('./SearchResultsFactory'),
  loading() {
    return <Loading />;
  },
});

const SearchFormViews = Loadable({
  loader: () => import('./SearchFormViews'),
  loading() {
    return <Loading />;
  },
});

type Props = {
  searchName: string,
  searchForm: any,
  currentSavedSearch: any
}

// TODO: get rid of inheritance
/* eslint-disable class-methods-use-this */
export default class SearchEditingBase extends Component<Props> {
  static propTypes = {
    searchForm: PropTypes.object.isRequired,
    currentSavedSearch: PropTypes.object,
    searchName: PropTypes.string,
  };

  static defaultProps = {
    currentSavedSearch: {},
    searchName: '',
  };

  constructor(props: Props) {
    super(props);

    this.isInitialLoad = true;
    this.isNeedLoadingCheck = true;
  }

  getCurrentSearchId() {
    return reduxStore.getState().currentSavedSearch.search.id;
  }

  getSectionName() {
    throw new Error(
      'SearchEditingBase.getSectionName method needs to be extended',
    );
  }

  get isSimpleView() {
    return this.props.searchForm.currentView === TABS.SIMPLE;
  }

  get isEditView() {
    return this.getSearchFormViewProps().formView === 'edit';
  }

  get layoutAttributes() {
    return {
      staticView: this.isSimpleView && this.isEditView ? true : null,
    };
  }

  get isLoaded() {
    const { currentSavedSearch } = this.props;

    if (!this.isNeedLoadingCheck || !currentSavedSearch) {
      return true;
    }

    return !currentSavedSearch.isProcessing && currentSavedSearch.isSuccessed;
  }

  renderResultsContent() {
    return (
      <SearchResultsFactory
        searchId={this.searchId}
        goToRenderedAsset={this.goToRenderedAsset}
        componentState={this.props}
      />
    );
  }

  renderSavedSearchHeader(text: string) {
    const { searchName } = this.props;

    const headlineText = (
      <FormattedMessage
        id={`app.search_criteria.${text}`}
        values={{ searchName }}
      />
    );

    return (
      <data>
        <Breadcrumb {...this.props} savedSearchID={this.getCurrentSearchId()} />
        <FlashMessages />
        <HeadlineLabel text={headlineText} />
      </data>
    );
  }

  renderUnsavedSearchHeader() {
    const headlineText = <FormattedMessage id="app.breadcrumb.new_search" />;

    return (
      <data>
        <FlashMessages />
        <HeadlineLabel text={headlineText} />
      </data>
    );
  }

  render() {
    return (
      <Layout
        section={this.getSectionName()}
        {...this.props}
        {...this.layoutAttributes}
      >
        <LoadWhenReady readyStates={[this.isLoaded]}>
          <SearchFormViews {...this.getSearchFormViewProps()} {...this.props} />
        </LoadWhenReady>
      </Layout>
    );
  }
}
