import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { reduxForm } from 'redux-form';

import { isDate, camelCase, merge, snakeCase } from 'lodash';
import { connect } from 'react-redux';
import { formatTZDate, DATE_FORMATS } from 'component-utilities/dates/dates';
import withMessages, {
  intlPropTypes,
} from 'component-utilities/intl';

import { FormErrorText } from 'v-c/Forms';
import LoadingIndicator from 'v-c/LoadingIndicator';
import { PrimaryButton } from 'view-components/components/Buttons/Components';
import HeadlineLabel from 'view-components/components/HeadlineLabel';
import NewAuditForm from 'v-c/Templates/Audits/NewAuditForm';
import generateDatePickerRangeProps from 'component-lib/generateDatePickerRangeProps';

import Container from './Container';

import configs from '../../../config/configs';

import Layout from '../../../global/components/Layout';
import Breadcrumb from '../../../global/components/Breadcrumb';
import withJobsLoad from '../../../global/components/withJobsLoad';

import validateSettingsAccess from '../components/validateSettingsAccess';

import { load as loadAccountSettings } from '../../../global/state_managers/AccountSettingsStateManager';
import {
  allImmediately,
  allWhenOptimal,
  areCategoryAuditsEnabled,
  createAuditReport,
} from '../../../global/state_managers/AuditsStateManager';
import { breadcrumbsActions } from '../../../global/state_managers/BreadcrumbsStateManager';

import styles from '../components/Settings.scss';

const { AUDITING, FILE_TYPES } = configs;
const REPORT_TYPES = ['all-user-activity'];
const REPORT_FORMATS = [FILE_TYPES.CSV];

const BASE_INTL_KEY = 'app.settings.reports';
const INITIAL_REPORT_NAME = '__initial_value__';

function formatSelection() {
  return REPORT_FORMATS.map(format => ({
    id: format,
    value: format.toUpperCase(),
  }));
}

const mapStateToProps = ({ audits, accountSettings }: any) => ({
  audits,
  jobs: audits.jobs,
  isLoading: audits.isLoading,
  isProcessing: audits.isProcessing,
  account: accountSettings.account,
  isAccountLoaded: accountSettings.isLoaded,
});

const mapActionsToProps = {
  allImmediately,
  allWhenOptimal,
  createAuditReport,
  loadAccountSettings,
  setBreadcrumbForRepportsNewReportPage:
    breadcrumbsActions.settingsReportsNewReportPage,
};

type Props = {
  getMessage: Function,
  setBreadcrumbForRepportsNewReportPage: Function,
  loadAccountSettings: Function,
  fields: any,
  createAuditReport: Function,
  values: any,
  account: {
    timezone: string
  }
  isLoading: Boolean,
  isProcessing: Boolean,
  isAccountLoaded: Boolean,
}

@withMessages
@reduxForm({
  form: 'audit-form',
  fields: AUDITING.CATEGORIES.concat([
    'dateRange',
    'fileFormat',
    'name',
    'reportType',
    ]),
  initialValues: merge(
    {
    dateRange: {
    from: '',
    to: '',
    },
    fileFormat: FILE_TYPES.CSV,
    reportType: '',
    name: INITIAL_REPORT_NAME,
    },
    AUDITING.CATEGORIES.reduce(
      (all, category) => ({ ...all, [category]: true }),
      {},
    ),
  ),
  })
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@validateSettingsAccess('settings.reports')
@withJobsLoad('audits')
export default class NewAuditReport extends Component<Props> {
  static propTypes = {
    ...intlPropTypes,
    setBreadcrumbForRepportsNewReportPage: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    props.setBreadcrumbForRepportsNewReportPage();
  }

  componentWillMount() {
    this.props.loadAccountSettings();
  }

  onFieldChange = (id: string, fieldId: string) => {
    this.props.fields[camelCase(fieldId)].onChange(id);
  };

  onDateSelect = (id: string, value: any) => {
    const { dateRange } = this.props.fields;
    dateRange.onChange({ ...dateRange.value, [id]: value });
  };

  onCreate = () => {
    this.props.createAuditReport(this.props.values);
  };

  get defaultReportName() {
    const name = this.props.getMessage(`${BASE_INTL_KEY}.name.default`);
    const timestamp = formatTZDate(
      new Date(),
      DATE_FORMATS.EXPORT_TIMESTAMP,
      this.props.account.timezone,
    );

    return `${name} ${timestamp}`;
  }

  get typeSelection() {
    return REPORT_TYPES.map(type => ({
      id: type,
      value: this.props.getMessage(`app.reports.form.${snakeCase(type)}`),
    }));
  }

  get isValidForm() {
    // NOTE: We cannot use `this.props.values` unfortunately here because there's
    //       an issue in redux-form where field values don't get set to their
    //       initial state until after the 2nd render cycle.
    const { dateRange, fileFormat, name, reportType } = this.props.fields;
    const { from, to } = dateRange.value || {};

    if (!isDate(from) || !isDate(to)) {
      return false;
    }

    if (areCategoryAuditsEnabled()) {
      return (
        !!name.value.length &&
        !this.hasDuplicateReportName() &&
        AUDITING.CATEGORIES.some(category => this.props.values[category])
      );
    }

    return !!reportType.value.length && !!fileFormat.value.length;
  }

  hasDuplicateReportName = () => {
    const { jobs, name } = this.props.fields;
    return (jobs || []).some(
      ({ jobName }: { jobName: string }) => jobName.toLowerCase() === name.toLowerCase(),
    );
  }

  renderErrorMessage = (field: string) => (
    <span className={styles['form-error']}>
      <FormErrorText error={`${field}-error`} field={field} />
    </span>
  );

  render() {
    const {
      fields,
      getMessage,
      isLoading,
      isProcessing,
      isAccountLoaded,
    } = this.props;

    const datepickerProps = generateDatePickerRangeProps({
      onSelectedValue: this.onDateSelect,
      datepickerObject: fields.dateRange.value,
      getMessage,
    });

    if (areCategoryAuditsEnabled() && (isLoading || !isAccountLoaded)) {
      return (
        <Container>
          <LoadingIndicator
            isLoading
            orientation="middle"
            text={this.props.getMessage('app.general.loading')}
          />
        </Container>
      );
    }

    // Only set the name initially. We can't do this in initialValues because
    // we need timezone information.
    if (fields.name.value === INITIAL_REPORT_NAME) {
      fields.name.onChange(this.defaultReportName);
    }

    return (
      <Layout section="settings new-report">
        <header>
          <Breadcrumb />
          <HeadlineLabel text={getMessage(`${BASE_INTL_KEY}.new_report`)} />
        </header>
        <div section="new-report" className={styles.content}>
          <NewAuditForm
            shouldRenderTitle={areCategoryAuditsEnabled()}
            shouldRenderCategories={areCategoryAuditsEnabled()}
            getMessage={getMessage}
            hasDuplicateReportName={this.hasDuplicateReportName}
            renderErrorMessage={this.renderErrorMessage}
            fields={fields}
            onFieldChange={this.onFieldChange}
            BASE_INTL_KEY={BASE_INTL_KEY}
            styles={styles}
            datepickerProps={datepickerProps}
            prefix="app"
            CATEGORIES={AUDITING.CATEGORIES}
            formatSelection={formatSelection}
            typeSelection={this.typeSelection}
          />
          <div className={styles['form-divider']} />
          <PrimaryButton
            data-action="create"
            disabled={!this.isValidForm || isProcessing}
            onClick={this.onCreate}
          >
            {getMessage('app.button.create')}
          </PrimaryButton>
        </div>
      </Layout>
    );
  }
}
