import { pickBy, includes } from 'lodash';

import { FORM_FIELDS } from 'v-c/Search/SimpleSearch';
import { SEARCH } from '../../../../../config/app.json';

const { CLIENT_DATA_VERSION } = SEARCH;

const defaultItems = {
  keywords: [],
  from: [],
  participants: [],
};

export { defaultItems };

type Data = {
  version: string,
  type: string,
  formValues?:{
    list: {},
    defaults?: {}  
  }
  items: {}

}

export default function oldClientDataConverter(clientData: Data) {
  const modifiedClientData: any = { ...clientData };

  if (modifiedClientData.version === CLIENT_DATA_VERSION) {
    return modifiedClientData;
  }

  // update the pre-release version to v1
  if (
    modifiedClientData.type === 'advanced' &&
    modifiedClientData.formValues &&
    modifiedClientData.formValues.list
  ) {
    modifiedClientData.items = {};

    modifiedClientData.defaults = modifiedClientData.formValues.defaults;
    delete modifiedClientData.formValues.defaults;

    modifiedClientData.formValues = modifiedClientData.formValues.list;
    delete modifiedClientData.formValues.list;
  } else if (
    modifiedClientData.type === 'simple' &&
    !modifiedClientData.items
  ) {
    modifiedClientData.items = { ...defaultItems };
  }

  // update v1 to v2
  if (modifiedClientData.type === 'simple' && !modifiedClientData.version) {
    modifiedClientData.formValues = Object.keys(
      modifiedClientData.formValues,
    ).reduce((all, formField) => {
      const modifiedAll = { ...all };
      const originalFormField = modifiedClientData.formValues[formField];
      let newFormField: any = {};

      switch (formField) {
        case FORM_FIELDS.KEYWORDS:
        case FORM_FIELDS.SENDER:
        case FORM_FIELDS.PARTICIPANTS:
          newFormField.query = originalFormField;

          if (
            modifiedClientData.items[formField] &&
            modifiedClientData.items[formField].length
          ) {
            newFormField.items = modifiedClientData.items[formField];
          }
          break;
        case FORM_FIELDS.DATE:
          newFormField = pickBy(
            originalFormField,
            (value, key) => value && includes(['from', 'to'], key),
          );
          newFormField.type = originalFormField.criteria;
          newFormField.localizedValue = originalFormField.criteria;
          delete newFormField.criteria;
          break;
        case FORM_FIELDS.SIZE:
          newFormField.query = originalFormField;
          newFormField.localizedValue = originalFormField;
          break;
        case FORM_FIELDS.HOLD_REASON:
          newFormField.query = originalFormField;
          break;
        default:
      }

      modifiedAll[formField] = newFormField;
      return modifiedAll;
    }, {});

    Object.keys(modifiedClientData.items || {}).forEach((formField) => {
      if (
        !modifiedClientData.formValues[formField] &&
        modifiedClientData.items[formField].length
      ) {
        modifiedClientData.formValues[formField] = {
          items: modifiedClientData.items[formField],
        };
      }
    });

    modifiedClientData.version = CLIENT_DATA_VERSION;
    delete modifiedClientData.items;
  }

  return modifiedClientData;
}
