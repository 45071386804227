import createReducer from '../lib/createReducer';
import { apiReqPromise } from '../lib/apiRequest';
import jsApi from '../lib/jsApi';

export const LOAD: string = 'contextTree/load';

export const IN_PROGRESS: string = 'contextTree/inProgress';
export const LOADED: string = 'contextTree/loaded';

interface ContextTreeState {
  tree: any,
  isLoaded: boolean,
  isLoading: boolean,
}

export const initialState: ContextTreeState = {
  tree: {},
  isLoaded: false,
  isLoading: false,
};

// actions
export function loadContextTree() {
  return function* doLoadContextTree() {
    yield { type: LOAD };

    yield { type: IN_PROGRESS };

    try {
      const { data } = yield apiReqPromise(jsApi.contextsTree, {});

      yield { type: LOADED, payload: { tree: data } };
    } catch (e) {
      console.error(e);
    }
  };
}

const ContextTreeStateManager = createReducer(initialState, {
  [LOAD]: (state: ContextTreeState) => ({
    ...state,
    tree: {},
    isLoading: false,
    isLoaded: false,
  }),
  [IN_PROGRESS]: (state: ContextTreeState) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  }),
  [LOADED]: (state: ContextTreeState, { tree }: any) => ({
    ...state,
    tree,
    isLoading: false,
    isLoaded: true,
  }),
});

export default ContextTreeStateManager;
