/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IfFeature from '../IfFeature';
import reduxStore from '../../reduxStore';
import { actions } from '../TableViewActions';

import ActionsBasePanel from './ActionsBasePanel';
import { ReduxState, SearchResults } from 'global/types';
import ifFeature from "../IfFeature";

type Props = {
  getMessage: Function
  onActionClick: Function
  multiSelect: {
    isAllSelected: boolean
    selectedRows: any[]
  },
  withMultipleSelection: boolean,
  currentHoldReason: {},
  openedAction: string,
  isJobsLoading: boolean,
  checkCompleteDropdownView: Function,
  completeDropdownView: {
    success: any
  },
  searchResults: SearchResults,
  resetPanel: Function,
  isBulkHoldAction: boolean,
  dialogOpen: boolean,
}

export function WithinActionsBasePanel(component: any) {
  function withinActionsBasePanel(props: Props) {
    return React.createElement(ActionsBasePanel, {
      ...props,
      component,
    });
  }

  withinActionsBasePanel.displayName = 'WithinActionsBasePanel';

  return withinActionsBasePanel;
}

export default function ActionsBase({
  type,
  closedStateImage,
  openedStateImage,
  onMount,
  actionCount,
}: any) {
  return (component: any) => {
    class ActionsBaseHOC extends Component<Props> {
      static propTypes = {
        getMessage: PropTypes.func.isRequired,
        onActionClick: PropTypes.func.isRequired,
        multiSelect: PropTypes.object,
        withMultipleSelection: PropTypes.bool,
        currentHoldReason: PropTypes.any,
        openedAction: PropTypes.string,
        isJobsLoading: PropTypes.bool,
        checkCompleteDropdownView: PropTypes.func,
        completeDropdownView: PropTypes.object,
        searchResults: PropTypes.object,
        resetPanel: PropTypes.func,
        isBulkHoldAction: PropTypes.bool,
        dialogOpen: PropTypes.bool,
      };

      static defaultProps = {
        multiSelect: null,
        withMultipleSelection: false,
        currentHoldReason: undefined,
        openedAction: null,
        isJobsLoading: false,
        completeDropdownView: {},
        checkCompleteDropdownView: () => {},
        searchResults: {},
        resetPanel: () => {},
        isBulkHoldAction: false,
        dialogOpen: false,
      };

      constructor(props: Props) {
        super(props);

        window.addEventListener('click', this.closeActions);
        document.addEventListener('oitclicked', this.closeActions);

        this.actionCount = actionCount;
        this.isClickOnPopup = false;

        if (onMount) {
          onMount.call(this);
        }

        this.actionCleanupMethods = {};
      }

      componentWillUnmount() {
        window.removeEventListener('click', this.closeActions);
        document.removeEventListener('oitclicked', this.closeActions);
      }

      onActionClick = () => {
        const { onActionClick } = this.props;

        onActionClick(type);

        if (type === actions.EXPORT.toLowerCase()) {
          this.props.checkCompleteDropdownView();
        }
      };

      onWrapperClick = () => {
        if (!this.isActionDisabled) {
          this.isClickOnPopup = true;
        }
      };

      getButtonText = (text: string) => this.props.getMessage(`app.button.${text}`);

      get isActionOpened() {
        const { openedAction } = this.props;

        return type === openedAction;
      }

      get isActionDisabled() {
        const { isJobsLoading, searchResults } = this.props;
        const { searchForm: { filter } } = reduxStore.getState() as ReduxState;

        if (searchResults.isLoading || (!searchResults.returnCount && searchResults.isLoaded)) {
          if (type === actions.FILTER.toLowerCase() && !!(Object.keys(filter).length)) {
            return false;
          }
          return true;
        }

        if (type === actions.HOLD.toLowerCase() && !ifFeature.isEnabled('asset_hold_tag') && !this.props.isBulkHoldAction) {
          return true;
        }

        return isJobsLoading;
      }

      get multiSelectEnabled() {
        return (
          IfFeature.isEnabled('multi_select_search_results') &&
          this.props.withMultipleSelection
        );
      }

      get noAssetsSelected() {
        const { multiSelect } = this.props;
        const isHoldOrExport = type === actions.HOLD.toLowerCase() || type === actions.EXPORT.toLowerCase();

        if (this.multiSelectEnabled && isHoldOrExport) {
          // wait for prop to come through
          if (!multiSelect) {
            return false;
          }
          return !multiSelect.selectedRows.length && !multiSelect.isAllSelected;
        }
        return false;
      }

      get isExportAction() {
        return this.props.openedAction === actions.EXPORT.toLowerCase();
      }

      get showDropdownPanel() {
        return (
          !this.isExportAction ||
          !this.newExportsDropdownEnabled ||
          typeof this.props.currentHoldReason !== 'undefined'
        );
      }

      registerActionCleanupMethods = (typeName: string, method: Function) => {
        this.actionCleanupMethods[typeName] = method;
      }

      closeActions = () => {
        const { openedAction, completeDropdownView, isBulkHoldAction, dialogOpen } = this.props;
        // will be true if user is deleting a comment
        if (dialogOpen) return;

        if (!this.isClickOnPopup && openedAction === type) {
          if (this.actionCleanupMethods[type]) {
            this.actionCleanupMethods[type]();
            delete this.actionCleanupMethods[type];
            if (!isBulkHoldAction && type === actions.HOLD.toLowerCase()) {
              this.props.resetPanel();
            }
          }
          if (this.resetForm) {
            this.resetForm();
          }

          if (type === actions.EXPORT.toLowerCase()) {
            let resetWithSuccess;
            if (completeDropdownView.success) {
              resetWithSuccess = 'resetWithSuccess';
            }

            this.props.checkCompleteDropdownView(resetWithSuccess);
          }

          this.onActionClick();
        }

        this.isClickOnPopup = false;
      };

      render() {
        return React.createElement(component, {
          ...this.props,
          type,
          openedStateImage,
          closedStateImage,
          onActionClick: this.onActionClick,
          isExportAction: this.isExportAction,
          showDropdownPanel: this.showDropdownPanel,
          noAssetsSelected: this.noAssetsSelected,
          newExportsDropdownEnabled: IfFeature.isEnabled(
            'new_exports_dropdown',
          ),
          getButtonText: this.getButtonText,
          isActionOpened: this.isActionOpened,
          isActionDisabled: this.isActionDisabled,
          onWrapperClick: this.onWrapperClick,
          closeActions: this.closeActions,
          actionCount: this.actionCount,
          registerActionCleanupMethods: this.registerActionCleanupMethods,
        });
      }
    }

    return ActionsBaseHOC;
  };
}
