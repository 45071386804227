import { Action } from '../reduxStore';
import localStore from 'local-store';

import constantsRoles from '../constants/Roles';
import { calculateVisibleMenus } from '../lib/userRights';

const { Roles } = constantsRoles;
const appLocalStore = localStore();

export const RESET: string = 'userRights/reset';
export const INIT_STATE: string = 'userRights/initState';
export const ROUTE_CHANGED: string = 'userRights/routeChanged';
export const SUCCESS_LOGIN: string = 'userRights/successLogin';

interface UserRightsState {
  activeRoles: string[],
  visibleMenus: {
    search: string[],
    nav: string[],
    sub: string[],
    other: string[],
  },
  activeRoute: string,
}

// Init
export const INITIAL_STATE: UserRightsState = {
  activeRoles: [],
  visibleMenus: {
    search: [],
    nav: [],
    sub: [],
    other: [],
  },
  activeRoute: '',
};

// Actions
export function successLogin({ roles }: any) {
  const filteredRoles = roles.filter((role: string) => !!Roles[role]);

  return {
    type: SUCCESS_LOGIN,
    payload: {
      activeRoles: filteredRoles,
      visibleMenus: calculateVisibleMenus(INITIAL_STATE, filteredRoles),
    },
  };
}

export function initUserRights() {
  const loggedInAs = appLocalStore.get('loggedInAs');
  let activeRoles = [];

  if (loggedInAs) {
    activeRoles = loggedInAs.roles;
  }

  return {
    type: INIT_STATE,
    payload: {
      activeRoles,
      visibleMenus: calculateVisibleMenus(INITIAL_STATE, activeRoles),
    },
  };
}

export function routeChanged(newRoute: string) {
  return { type: ROUTE_CHANGED, payload: { activeRoute: newRoute } };
}

export function resetUserRights() {
  return { type: RESET };
}

// Store
export default function UserRightsStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case INIT_STATE:
    case SUCCESS_LOGIN:
    case ROUTE_CHANGED:
      return { ...state, ...payload };
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}