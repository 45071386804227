import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import Loadable from 'react-loadable';

import LoadWhenReady from '../../global/lib/LoadWhenReady';
import fixedHeightContent from '../../global/lib/fixedHeightContent';
import routeLeaveHook from '../../global/lib/routeLeaveHook';

import Loading from '../../global/components/Loading';
import {
  loadLegalHoldReasons,
  searchLegalHoldReasons,
  sortLegalHoldReasons,
  updateLegalHoldReasonsActiveColumns,
  resetLegalHoldReasons,
} from '../../global/state_managers/LegalHoldReasonsStateManager';
import { resetFilter } from '../../global/state_managers/SearchFormStateManager';
import { resetUnsavedSearch } from '../../global/state_managers/UnsavedSearchStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';
import { resetStreamQuery } from '../../global/state_managers/SearchResults/searchResultsStream';

import { flashConnect, PAGES } from '../../global/components/FlashMessages';

import './HoldReasons.scss';

const Loaded = Loadable({
  loader: () => import('./Loaded'),
  loading() {
    return <Loading />;
  },
});

const mapStateToProps = ({ legalHoldReasons }: any) => ({
  legalHoldReasons,
  searchString: legalHoldReasons.searchString,
  returnCount: legalHoldReasons.reasons.length,
});

const mapActionsToProps = {
  loadLegalHoldReasons,
  searchLegalHoldReasons,
  sortLegalHoldReasons,
  updateLegalHoldReasonsActiveColumns,
  resetLegalHoldReasons,
  resetFilter,
  resetUnsavedSearch,
  setBreadcrumbForHoldReasonsPage: breadcrumbsActions.holdReasonsPage,
  resetStreamQuery,
};

@routeLeaveHook
@flashConnect(PAGES.HOLD_REASONS, 'HoldReasons')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@fixedHeightContent
export default class HoldReasons extends Component {
  static propTypes = {
    loadLegalHoldReasons: PropTypes.func.isRequired,
    resetLegalHoldReasons: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    legalHoldReasons: PropTypes.shape({
      isLoaded: PropTypes.bool.isRequired,
    }).isRequired,
    registerUnmountCleanupMethod: PropTypes.func.isRequired,
    resetUnsavedSearch: PropTypes.func.isRequired,
    setBreadcrumbForHoldReasonsPage: PropTypes.func.isRequired,
    resetStreamQuery: PropTypes.func.isRequired,
  };

  constructor(props: any) {
    super(props);

    props.resetStreamQuery();
    props.setBreadcrumbForHoldReasonsPage();
    props.resetFilter();
    props.resetUnsavedSearch();

    props.loadLegalHoldReasons({ populateCounts: true });

    props.registerUnmountCleanupMethod(() => {
      props.resetLegalHoldReasons({ from: 'holdReasons' });
    });
  }

  render() {
    return (
      <LoadWhenReady readyStates={[this.props.legalHoldReasons.isLoaded]}>
        <div data-view="hold-reasons">
          <Loaded {...this.props} />
        </div>
      </LoadWhenReady>
    );
  }
}
