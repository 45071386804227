/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from 'view-components/components/Buttons/ActionButton/shared/style.scss';
import ActionButton from 'view-components/components/Buttons/ActionButton';
import SyncNowImage from './images/sync_now.png';
import PauseSyncImage from './images/pause_sync.png';
import ResumeSyncImage from './images/resume_sync.png';
import DeleteImage from './images/delete_sync.png';

import style from './ActionButtons.scss';

export class ADSyncActionButton extends ActionButton {
  // TODO: stop this abuse of props for action buttons
  static defaultProps = {
    styles,
  }
}

type Props = {
  getMessage: Function,
  pauseButtonText: string,
  statusData: {
    status: {
      syncStatus: string
    }
  },
  resumeButtonText: string

}

export default class ActionButtons extends Component<Props> {
  static propTypes = {
    statusData: PropTypes.object,
    deleteHandler: PropTypes.func,
    syncHandler: PropTypes.func,
    pauseHandler: PropTypes.func,
    syncButtonText: PropTypes.string,
    pauseButtonText: PropTypes.string,
    resumeButtonText: PropTypes.string,
    deleteButtonText: PropTypes.string,
    getMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    deleteHandler() {
      // console.error('deleteHandler is required');
    },
    syncHandler() {
      // console.error('syncHandler is required');
    },
    pauseHandler() {
      // console.error('pauseHandler is required');
    },
    syncButtonText: 'app.ad_sync.action_buttons.sync_now',
    pauseButtonText: 'app.ad_sync.action_buttons.pause_sync',
    resumeButtonText: 'app.ad_sync.action_buttons.resume_sync',
    deleteButtonText: 'app.ad_sync.action_buttons.delete_sync',
    statusData: null,
  };

  render() {
    const { getMessage } = this.props;
    let pauseLabel = this.props.pauseButtonText;
    let pauseImage = PauseSyncImage;
    let syncButtonDisabled = false;
    if (this.props.statusData.status.syncStatus === 'paused') {
      pauseLabel = this.props.resumeButtonText;
      pauseImage = ResumeSyncImage;
      syncButtonDisabled = true;
    }

    return (
      <div className={style['action-buttons']}>
        <ADSyncActionButton
          className={style['action-button']}
          label={getMessage(this.props.syncButtonText)}
          image={SyncNowImage}
          onClick={this.props.syncHandler}
          disabled={syncButtonDisabled}
          extraClassName={syncButtonDisabled ? style['action-buttons-disabled'] : ''}
        />
        <ADSyncActionButton
          className={style['action-button']}
          label={getMessage(pauseLabel)}
          image={pauseImage}
          onClick={this.props.pauseHandler}
        />
        <ADSyncActionButton
          className={style['action-button']}
          label={getMessage(this.props.deleteButtonText)}
          image={DeleteImage}
          onClick={this.props.deleteHandler}
        />
      </div>
    );
  }
}
