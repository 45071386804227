/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { formatDate, DATE_FORMATS } from 'component-utilities/dates/dates';

import { FORM_FIELDS } from 'v-c/Search/SimpleSearch';
import Accordion from 'v-c/Accordions/Accordion';
import AccordionItem from 'v-c/Accordions/AccordionItem';

import List from 'v-c/Lists/List';
import ListItem from 'v-c/Lists/ListItem';

import { loadLegalHoldReasons } from '../../../state_managers/LegalHoldReasonsStateManager';
import IfFeature from '../../IfFeature';

import prettyPrintFormatter from '../../../lib/prettyPrintFormatter';

import styles from '../FormBase.scss';
import { HoldReason, ReduxState, Search } from 'global/types';

const mapStateToProps = ({ searchForm, legalHoldReasons }: ReduxState) => ({
  searchForm,
  reasons: legalHoldReasons.reasons,
});

const mapActionsToProps = { loadLegalHoldReasons };

type Props = {
  getMessage: Function,
  searchForm: {
    simpleSearch: Search
  },
  reasons: HoldReason[],
  loadLegalHoldReasons: Function,
  currentSavedSearch: {
    search: {
      pretty: string,
      clientData: {
        formValues: {}
      }
    }
  }
  isNewSearch: boolean
}

@connect(mapStateToProps, mapActionsToProps)
export default class SaveView extends Component<Props> {
  static propTypes = {
    getMessage: PropTypes.func.isRequired,
    searchForm: PropTypes.object.isRequired,
    reasons: PropTypes.array.isRequired,
    loadLegalHoldReasons: PropTypes.func.isRequired,
    currentSavedSearch: PropTypes.shape({
      search: PropTypes.shape({
        pretty: PropTypes.string,
      }),
    }),
    isNewSearch: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isEditButtonVisible: true,
    currentSavedSearch: null,
  };

  constructor(props: Props) {
    super(props);

    props.loadLegalHoldReasons({ loadIfNecessary: true });
    this.state = { btnText: 'Copy this query' };
  }

  prettyPrintSearchQuery = () => prettyPrintFormatter(this.props.currentSavedSearch.search.pretty || '');

  renderFormValues = () => {
    const { currentSavedSearch, searchForm, reasons } = this.props;
    const formToUse =
      (currentSavedSearch && currentSavedSearch.search.clientData.formValues) ||
      searchForm.simpleSearch;

    return Object.keys(formToUse).reduce((all, field) => {
      switch (field) {
        case FORM_FIELDS.KEYWORDS:
        case FORM_FIELDS.SENDER:
        case FORM_FIELDS.PARTICIPANTS:
        case FORM_FIELDS.ATTACHMENTS:
          ['query', 'items'].forEach((type) => {
            const value = formToUse[field][type];

            if (value) {
              const isItems = type === 'items';
              const uiValue = isItems
                ? value.map((item: any) => item.value).join(', ')
                : value;

              all.push({
                field,
                type,
                textLink: `${field}${isItems ? '_list' : ''}`,
                value: uiValue,
              });
            }

            return all;
          });
          break;
        case FORM_FIELDS.SIZE:
          ['query'].forEach((type) => {
            const value = formToUse[field].localizedValue;
            if (value) {
              all.push({ field, type, textLink: field, value });
            }

            return all;
          });
          break;
        case FORM_FIELDS.HOLD_REASON:
          ['query'].forEach((type) => {
            let value = formToUse[field][type];

            if (value) {
              value = value.map(
                (reasonId: string) =>
                  (reasons.find(reason => reason.id === reasonId) || {}).name,
              );

              all.push({ field, type, textLink: field, value });
            }

            return all;
          });
          break;
        case FORM_FIELDS.DATE:
          ['localizedValue'].forEach((type) => {
            let value = formToUse[field][type];

            if (value) {
              const { from, to } = formToUse[field];
              const formatShortDate = date =>
                formatDate(date, DATE_FORMATS.SHORT);
              value = `${value} (${formatShortDate(from)}${
                to ? ` - ${formatShortDate(to)})` : ')'
              }`;

              all.push({ field, type, textLink: field, value });
            }

            return all;
          });
          break;
        default:
      }

      return all;
    }, []);
  };

  render() {
    const { isNewSearch } = this.props;
    const { btnText } = this.state;

    return (
      <div className={styles['save-view']}>
        <List>
          {this.renderFormValues().map(({ textLink, value }) => (
            <ListItem key={`${value}-${textLink}`}>
              <strong>
                {`${this.props.getMessage(
                  `app.new_search_form.${textLink}`,
                )}: `}
              </strong>
              {`${value}`}
            </ListItem>
          ))}
        </List>
        {!isNewSearch && (
          <span
            className="criteria-container"
            style={{ marginTop: '-50px', display: 'block' }}
          >
            <Accordion size="large">
              <AccordionItem label="">
                {this.prettyPrintSearchQuery()}
              </AccordionItem>
            </Accordion>

            {IfFeature.isEnabled('copy-dsl-to-clipboard') && (
              <CopyToClipboard
                text={this.prettyPrintSearchQuery()}
                onCopy={() => this.setState({ btnText: 'Copied!' })}
              >
                <button>{btnText}</button>
              </CopyToClipboard>
            )}
          </span>
        )}
      </div>
    );
  }
}
