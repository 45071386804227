const getId = ({ jobId, id }: any) => jobId || id;

export default function mergeNewJobs(oldJobs: [], potentiallyNewJobs: any[]) {
  const currentJobIDs = oldJobs.map(getId);

  const newJobs = potentiallyNewJobs.filter(job => currentJobIDs.indexOf(getId(job)) === -1);

  return oldJobs.map((job) => {
    const id = getId(job);
    const payloadJob = potentiallyNewJobs.find(_payloadJob => getId(_payloadJob) === id);

    if (payloadJob) {
      return payloadJob;
    }

    return job;
  }).concat(newJobs);
}
