import uri from 'urijs';

import { jsUi } from './jsUi';
import reduxStore from '../reduxStore';
import { loadContextTree } from '../state_managers/ContextTreeStateManager';

import sanitizeAndProtectRedirectUrl from '../../features/Authentication/lib/sanitizeAndProtectRedirectUrl';
import navigateToRedirectPath from '../../features/Authentication/lib/navigateToRedirectPath';
import getRedirectToPath from '../../features/Authentication/lib/getRedirectToPath';
import { isRequestedPathFitToActiveRole, getHomePage } from './userRights';

import { load as loadDomainInfo } from '../state_managers/DomainInfoStateManager';
import {
  getAccountDetails,
  isLoaded as domainInfoIsLoaded,
} from './domainInfo';
import {
  getLoggedInAs,
  isLoggedIn,
  sessionsShow,
} from '../state_managers/AuthenticationStateManager';

import { routeChanged } from '../state_managers/UserRightsStateManager';
import { Params, ReduxState } from 'global/types';
import { getJobs } from '../state_managers/BulkActionsStateManager';
import IfFeature from '../components/IfFeature';

type State = {
  params: Params,
  location: {
    pathname: string
    query: {
      isPortalLogin: Boolean
    }
  }
}

async function waitForDomainInfo(nextState: State) {
  if (domainInfoIsLoaded()) {
    return Promise.resolve();
  }

  return reduxStore.dispatch(
    loadDomainInfo(nextState.params.site_name, getRedirectToPath()),
  );
}

function waitForContexts() {
  const { contextTree: { isLoaded, isLoading } } = reduxStore.getState() as ReduxState;

  if (!isLoaded && !isLoading) {
    return reduxStore.dispatch(loadContextTree());
  }

  return '';
}

function currentHref() {
  return window.location.href;
}

export function checkSessionStatus() {
  reduxStore.dispatch(sessionsShow());
}

// this is a react-router hook for checking the current user's authentication status
// when entering a new route. it fires before all other components. start here
// when debugging authentication issues.
export default async function routeAuthentication(
  nextState: State,
  replace: Function,
  callback: Function,
) {
  await waitForDomainInfo(nextState);

  const { userRights: { activeRoute } } = reduxStore.getState() as ReduxState;

  // metadata comes from the JSON UI routes defintions. if a route doesn't have
  // metadata, it's not worth protecting.
  if (!this.metadata) {
    callback();
    return;
  }

  reduxStore.dispatch(routeChanged(nextState.location.pathname));

  const { isOutsideLink, alwaysAllow } = this.metadata;
  const { isPortalLogin } = nextState.location.query;

  // If the route is outside of the need authentication area
  // and the user not logged in or if the user sends a flag in the url
  // we should continue the transition.
  if ((isOutsideLink && (!isLoggedIn() || isPortalLogin)) || alwaysAllow) {
    callback();
    return;
  }

  const isActivePathFitToActiveRole = isRequestedPathFitToActiveRole(
    this.metadata.routeRole,
  );

  // If the user not logged in then transition to the login page
  if (!isLoggedIn()) {
    replace(jsUi.loginWithRedirect.path(nextState.location.pathname));
    callback();
  } else {
    const currentUrl = uri(currentHref());

    if (!isOutsideLink && IfFeature.isEnabled('jobs_page')) {
      reduxStore.dispatch(getJobs());
    }
 
    const { redirect_to, hash } = currentUrl.search(true);

    if (activeRoute !== nextState.location.pathname) {
      checkSessionStatus();
    }
    await waitForContexts();

    // If the user logged in but the requested page isn't fit the user's roles
    // or isOutsideLink, transition to the homepage.
    if (!isActivePathFitToActiveRole || isOutsideLink) {
      const redirectToHomepage = () => {
        replace(jsUi[getHomePage()].path());
      };

      /* eslint-disable camelcase */
      if (redirect_to) {
        sanitizeAndProtectRedirectUrl(
          redirect_to,
          (url: string) => {
            navigateToRedirectPath(
              getAccountDetails().siteName,
              getLoggedInAs().authenticationToken,
              url,
            );
          },
          redirectToHomepage,
        );
      } else if (!hash) {
        // don't redirect if hash is in the url, so Login can pick it up
        redirectToHomepage();
      }
      /* eslint-enable camelcase */
    }
    callback();
  }
}
