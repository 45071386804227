/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import SaveAsNew from 'v-c/Modals/SaveAsNewModal';
import { ModalProps } from 'global/types';

export default function SaveAsNewModal(props: ModalProps) {
  const { modalOptions, ...otherProps } = props;
  return (
    <SaveAsNew
      {...otherProps}
      onClose={modalOptions.closeModal}
      onSave={modalOptions.onSave}
      searchName={modalOptions.searchName}
      searchDescription={modalOptions.searchDescription}
    />
  );
}

SaveAsNewModal.propTypes = {
  modalOptions: PropTypes.shape({
    closeModal: PropTypes.func.isRequired,
    searchName: PropTypes.string.isRequired,
    searchDescription: PropTypes.string,
  }).isRequired,
};
