import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  TextLabel,
} from 'view-components/components/Widgets/Components';
import CommentContent from './CommentContent';
import actionsBase, {
  WithinActionsBasePanel as withinPanel,
} from './ActionsBase';
import CommentIcon from './images/comments-normal.png';
import { ReduxState } from 'global/types';

const TYPE = 'comment';

const mapStateToProps = ({ asset, dialog }: ReduxState) => ({
  comments: asset.data.comments,
  dialogOpen: dialog.showDialog,
});

const mapActionsToProps = {
};

type Props = {
  comments: {}[]
  onActionClick: Function
  getMessage: Function
}

@connect(
  mapStateToProps,
  mapActionsToProps,
)
@actionsBase({
  type: TYPE,
  closedStateImage: CommentIcon,
  openedStateImage: CommentIcon,
  onMount: () => {
  },
  actionCount: props => props.comments.length,
  dialogOpen: props => props.dialogOpen,
})
@withinPanel
export default class Comment extends Component<Props> {
  static propTypes = {
    getMessage: PropTypes.func.isRequired,
    comments: PropTypes.array.isRequired,
    onActionClick: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div>
        <div className="content-header">
          <TextLabel className="head-label">
            {this.props.getMessage('app.search_results.comments.header', { length: this.props.comments.length })}
          </TextLabel>
        </div>
        <CommentContent onActionClick={this.props.onActionClick} />
      </div>
    );
  }
}
