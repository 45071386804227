/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Link } from 'react-router';

import {
  LinkLabel,
  TextLabel,
} from 'view-components/components/Widgets/Components';
import withMessages from 'component-utilities/intl';

import { jsUi } from '../../../global/lib/jsUi';

import { getAttributeMappings } from '../../../global/state_managers/ADSyncStateManager';
import { setWizardPage } from '../../../global/state_managers/ADWizardStateManager';

import style from './Summary.scss';

const mapStateToProps = ({ adSync }: any) => ({
  pairs: adSync.pairs,
});

const mapActionsToProps = { getAttributeMappings, setWizardPage };

type Props = {
  getAttributeMappings: Function,
  getMessage: Function,
  pairs: {
    pairs: any[]
  },
  setWizardPage: Function
}

@withMessages
@connect(mapStateToProps, mapActionsToProps)
export default class Summary extends Component<Props> {
  static propTypes = {
    pairs: PropTypes.object.isRequired,
    getMessage: PropTypes.func.isRequired,
    getAttributeMappings: PropTypes.func.isRequired,
    setWizardPage: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    props.getAttributeMappings();
  }

  showMappedAttributes() {
    const { getMessage } = this.props;
    const mappings = this.props.pairs.pairs.map((item, i) => {
      const source = getMessage(
        `app.ad_sync.otto_attributes.${item.internalAttribute}`,
      );
      const target = item.externalAttribute.toString();
      return { text: `${source} will be mapped to ${target}`, id: i };
    });
    return (
      <ul>{mappings.map(result => <li key={result.id}>{result.text}</li>)}</ul>
    );
  }

  render() {
    const { getMessage } = this.props;
    return (
      <div className={style.summary}>
        <TextLabel className={style['input-label']}>
          {getMessage('app.ad_sync.summary.attributes_will_be_mapped')}
        </TextLabel>
        <Link to={jsUi.settingsAdSyncAdWizard.path()}>
          <LinkLabel
            className={style.link}
            onClick={() => {
              this.props.setWizardPage(2);
            }}
          >
            &nbsp;{getMessage('app.button.edit')}
          </LinkLabel>
        </Link>
        <TextLabel className={style.value}>
          {this.showMappedAttributes()}
        </TextLabel>
        <TextLabel className={style['input-label']}>
          {getMessage('app.ad_sync.summary.sync_frequency')}
        </TextLabel>
        <TextLabel className={style.value}>
          {getMessage('app.ad_sync.summary.1_in_24')}
        </TextLabel>
      </div>
    );
  }
}
