/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withMessages from 'component-utilities/intl';

import validateSettingsAccess from './components/validateSettingsAccess';

import Layout from '../../global/components/Layout';
import VerticalHeightManager from '../../global/components/VerticalHeightManager';
import HeadlineLabel from '../../global/components/HeadlineLabel';
import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';
import Breadcrumb from '../../global/components/Breadcrumb';
import Wizard from './ADSync/Wizard/Wizard';
import AddApplication from './ADSync/AddApplication';
import AppCredentials from './ADSync/AppCredentials';
import AttributeMappings from './ADSync/AttributeMappings';
import Summary from './ADSync/Summary';
import { jsUi } from '../../global/lib/jsUi';

import {
  updateCredentials,
  createSync,
  updateAttributeMappings,
  forceSync,
} from '../../global/state_managers/ADSyncStateManager';
import { setWizardPage } from '../../global/state_managers/ADWizardStateManager';
import { getStatus } from '../../global/state_managers/ADSyncStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import style from './ADSync/ADWizard.scss';
import { Pairs, Params } from 'global/types';

const mapStateToProps = ({ domainInfo, adSync, adWizard, form }: any) => ({
  accountId: domainInfo.accountId,
  adSync,
  isStoreLoading: adSync.isLoading,
  credentials: adSync.credentialData.credentials,
  areCredentialsSet: adSync.areCredentialsSet,
  pairs: adSync.pairs,
  wizardPage: adWizard.wizardPage,
  appCredentials: form.appCredentials,
});

const mapActionsToProps = {
  setWizardPage,
  updateCredentials,
  createSync,
  updateAttributeMappings,
  forceSync,
  getStatus,
  setBreadcrumbForSettingsADSyncWizardPage:
    breadcrumbsActions.settingsAdSyncWizardPage,
};

type Props = {
  params: Params,
  headlineText: string,
  isStoreLoading: Boolean,
  credentials: {
    clientId: string,
    clientSecret: string,
    tenantId: string
  },
  areCredentialsSet: Boolean,
  pairs: Pairs,
  wizardPage: string,
  adSync: {},
  getMessage: Function,
  accountId: string,
  setWizardPage: Function,
  updateCredentials: Function,
  createSync: Function,
  updateAttributeMappings: Function,
  forceSync: Function,
  getStatus: Function,
  setBreadcrumbForSettingsADSyncWizardPage: Function,
  appCredentials: {
    clientId: {
      value: string
    },
    clientSecret: {
      value: string
    },
    tenantId: {
      value: string
    }
  },
}

@withMessages
@flashConnect(PAGES.AD_Wizard, 'ADWizard')
@validateSettingsAccess('settings.ad_sync')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class ADWizard extends Component<Props> {
  static propTypes = {
    params: PropTypes.object,
    headlineText: PropTypes.string,
    isStoreLoading: PropTypes.bool,
    credentials: PropTypes.object,
    areCredentialsSet: PropTypes.bool,
    pairs: PropTypes.object,
    wizardPage: PropTypes.number.isRequired,
    adSync: PropTypes.object.isRequired,
    getMessage: PropTypes.func.isRequired,
    accountId: PropTypes.string.isRequired,
    setWizardPage: PropTypes.func.isRequired,
    updateCredentials: PropTypes.func.isRequired,
    createSync: PropTypes.func.isRequired,
    updateAttributeMappings: PropTypes.func.isRequired,
    forceSync: PropTypes.func.isRequired,
    getStatus: PropTypes.func.isRequired,
    setBreadcrumbForSettingsADSyncWizardPage: PropTypes.func.isRequired,
    appCredentials: PropTypes.object,
  };

  static defaultProps = {
    headlineText: 'app.breadcrumb.ad_wizard',
    params: {},
    isStoreLoading: false,
    credentials: {},
    areCredentialsSet: false,
    pairs: {},
    appCredentials: null,
  };

  constructor(props: Props) {
    super(props);

    props.setBreadcrumbForSettingsADSyncWizardPage();
    props.getStatus();
  }

  componentDidUpdate() {
    const { params } = this.props;

    if (params && params.wizard_page) {
      this.props.setWizardPage(params.wizard_page);
    }
  }

  render() {
    const { getMessage, accountId } = this.props;
    const loading = this.props.isStoreLoading || false;
    const getWizardText = (text: string) => getMessage(`app.ad_sync.wizard.${text}`);

    const texts = {
      back: getWizardText('back'),
      next: getWizardText('next'),
      finish: getWizardText('finish'),
      loading: getWizardText('loading'),
    };

    const { credentials, areCredentialsSet, pairs } = this.props;

    // TODO: get the rest of these wizard child components to accept
    // adSyncStore and adSyncWizard from outside and not have to
    // re-connect to the stores each time. it's a waste of performance
    // and makes components more nested than needed. see AttributeMappings
    // as an example on how to fix this.
    const steps = [
      {
        name: getMessage('app.ad_sync.wizard.add_application'),
        component: AddApplication,
        previousStep: () => {},
        nextStep: () => {
          this.props.setWizardPage(1);
        },
      },
      {
        name: getMessage('app.ad_sync.wizard.add_credentials'),
        component: AppCredentials,
        previousStep: () => {
          this.props.setWizardPage(0);
        },
        nextStep: () => {
          if (areCredentialsSet) {
            this.props.updateCredentials({
              remoteDirectoryData: {
                ottoAccountId: accountId,
                credentials: {
                  client_id: credentials.clientId,
                  client_secret: credentials.clientSecret,
                  tenant_id: credentials.tenantId,
                },
              },
            });
          } else {
            const { clientId, clientSecret, tenantId } = this.props.appCredentials;
            this.props.createSync({
              name: 'Test name - newly created',
              ottoAccountId: accountId,
              createUserCredentials: {
                accountId,
              },
              remoteData: {
                type: 'azure',
                credentials: {
                  client_id: clientId.value,
                  client_secret: clientSecret.value,
                  tenant_id: tenantId.value,
                },
              },
            });
          }
        },
      },
      {
        name: getMessage('app.ad_sync.wizard.map_attributes'),
        component: AttributeMappings,
        previousStep: () => {
          this.props.setWizardPage(1);
        },
        nextStep: () => {
          this.props.updateAttributeMappings({
            attributeMappings: pairs.getPairs(),
          });
        },
      },
      {
        name: getMessage('app.ad_sync.wizard.summary'),
        component: Summary,
        previousStep: () => {
          this.props.setWizardPage(2);
        },
        nextStep: () => {
          this.props.forceSync();
          jsUi.settingsAdSyncAdSuccess.goTo();
        },
      },
    ];

    return (
      <Layout section="ad-sync">
        <VerticalHeightManager className="content-wrapper">
          <VerticalHeightManager.Section>
            <header className={style['wizard-header']}>
              <FlashMessages />
              <Breadcrumb {...this.props} />
              <HeadlineLabel text={getMessage(this.props.headlineText)} />
            </header>
          </VerticalHeightManager.Section>
          <VerticalHeightManager.Section maximize>
            <Wizard
              wizardTexts={texts}
              wizardSteps={steps}
              wizardLoading={loading}
              activeStep={parseInt(this.props.wizardPage, 10)}
              nextStep={this.nextStep}
              stepProps={this.props.adSync}
            />
          </VerticalHeightManager.Section>
        </VerticalHeightManager>
      </Layout>
    );
  }
}
