import React from 'react';
import PropTypes from 'prop-types';

import SplitViewAssetReview from 'v-c/Splitview/SplitViewAssetReview';
import contextTreeHelper from '../../lib/contextTreeHelper';

import ifFeature from "../IfFeature";

function ReviewDropdownComponent({ reviewDropdown, contextTree }: any) {
  const {
    REVIEW_STATUSES: {
      REVIEWED,
      NEEDS_REVIEW,
    },
  } = contextTree;

  return (
    <SplitViewAssetReview
      REVIEW_STATUSES={{ REVIEWED, NEEDS_REVIEW }}
      {...reviewDropdown}
    />
  );
}

ReviewDropdownComponent.propTypes = {
  reviewDropdown: PropTypes.shape({
    reviewState: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isHideDropDownList: PropTypes.bool.isRequired,
  }).isRequired,
  contextTree: PropTypes.shape({
    REVIEW_STATUSES: PropTypes.object.isRequired,
  }).isRequired,
};

ReviewDropdownComponent.defaultProps = {
  reviewDropdown: {
    isHideDropdownList: false,
  },
};

const ReviewDropdown = contextTreeHelper(ReviewDropdownComponent);

export default ReviewDropdown;
