/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv'

import { TextLabel } from 'view-components/components/Widgets/Components';
import RetinaImage from 'view-components/components/RetinaImage';

import withMessages from 'component-utilities/intl';

import jsApi from '../../../lib/jsApi';

import '../ActionsBase.scss';
import { apiReqPromise, apiURL } from '../../../lib/apiRequest';
import DownloadIcon from '../images/download-normal.png';


function BulkDownload(props: { getMessage: Function}) {

  const [transactionData, setTransactionData] = useState([])
  const csvLink = useRef()

  const onDownload = async () => {
    const res = await apiReqPromise(jsApi.usersBulkDownload)
    setTransactionData(res.data);
    csvLink.current.link.click()
  }
  return (
    <div className="float-right action-wrapper download">
      <a
        onClick={onDownload}
      >
        <TextLabel className="action-label">
          <RetinaImage className="action-image" image={DownloadIcon} />
          {props.getMessage('app.table_actions.download')}
        </TextLabel>
      </a>
      <CSVLink
        data={transactionData}
        filename='users.csv'
        className='hidden'
        ref={csvLink}
        target='_blank'
      />
    </div>
  );
}

BulkDownload.propTypes = {
  getMessage: PropTypes.func.isRequired,
}

export default withMessages(BulkDownload);
