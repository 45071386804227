// the list of available modals is here.
// if you want to create a new modal, import it
// here, add it to the exported object, and refer
// to it as the `type` key in `ModalsStateManager.openModal`.

import RolesModal from 'v-c/Templates/UserEditView/modals/RolesModal';
import PersonalArchiveModalSearch from './PersonalArchiveModalSearch';
import EditReport from '../../../features/Settings/Reports/EditReportModal';
import BulkEnableUsersModal from '../../../features/Users/components/Modals/BulkEnableUsersModal';
import BulkDisableUsersModal from '../../../features/Users/components/Modals/BulkDisableUsersModal';
import BulkUploadUsersModal from '../../../features/Users/components/Modals/BulkUploadUsersModal';
import AddUserModal from './AddUserModal';
import SelectListModal from './SelectListModal';
import SessionExpirationWarningModal from './SessionExpirationWarningModal';
import OverExportLimitModal from './OverExportLimitModal';
import NoItemsSelectedModal from './NoItemsSelectedModal';
import SaveAsNewModal from './SaveAsNewModal';
import SizeLimitWarningModal from './SizeLimitWarningModal';
import DeleteAssetModal from './DeleteAssetModal';
import DeleteAssetErrorModal from './DeleteAssetErrorModal';
import UnsavedChangesModal from './UnsavedChangesModal';

import {
  ROLES_MODAL,
  EDIT_REPORT,
  BULK_ENABLE_USERS,
  BULK_DISABLE_USERS,
  BULK_UPLOAD_USERS,
  ADD_USER,
  SELECT_LIST,
  SESSION_EXPIRATION,
  OVER_EXPORT_LIMIT,
  NO_ITEMS_SELECTED,
  SAVE_AS_NEW,
  SIZE_LIMIT_WARNING,
  DELETE_ASSET_MODAL,
  DELETE_ASSET_ERROR_MODAL,
  UNSAVED_CHANGES,
  PERSONAL_SEARCHES,
} from './modalMap.json';

export default {
  [ROLES_MODAL]: RolesModal,
  [EDIT_REPORT]: EditReport,
  [BULK_ENABLE_USERS]: BulkEnableUsersModal,
  [BULK_DISABLE_USERS]: BulkDisableUsersModal,
  [BULK_UPLOAD_USERS]: BulkUploadUsersModal,
  [ADD_USER]: AddUserModal,
  [SELECT_LIST]: SelectListModal,
  [SESSION_EXPIRATION]: SessionExpirationWarningModal,
  [OVER_EXPORT_LIMIT]: OverExportLimitModal,
  [NO_ITEMS_SELECTED]: NoItemsSelectedModal,
  [SAVE_AS_NEW]: SaveAsNewModal,
  [SIZE_LIMIT_WARNING]: SizeLimitWarningModal,
  [DELETE_ASSET_MODAL]: DeleteAssetModal,
  [DELETE_ASSET_ERROR_MODAL]: DeleteAssetErrorModal,
  [UNSAVED_CHANGES]: UnsavedChangesModal,
  [PERSONAL_SEARCHES]: PersonalArchiveModalSearch,
};
