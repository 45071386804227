import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'component-utilities/intl';

import {
  ButtonContainer,
  DefaultButton,
} from 'view-components/components/Dialog/Components';

type Props = {
  onDismiss: any
}

export default function CloseButtonContainer(props: Props) {
  // TODO: remove .bind
  /* eslint-disable react/jsx-no-bind */
  const onDismiss = props.onDismiss.bind(this);

  return (
    <ButtonContainer>
      <DefaultButton
        data-action="dismiss"
        onClick={onDismiss}
      >
        <FormattedMessage id="app.button.close" />
      </DefaultButton>
    </ButtonContainer>
  );
  /* eslint-enable react/jsx-no-bind */
}

CloseButtonContainer.propTypes = {
  onDismiss: PropTypes.func.isRequired,
};
