import { trim } from 'lodash';

export default function ParseSearchStringValue(_value: string) {
  const textBetweenquotes = new RegExp(/"([^"]+)"/, 'g');
  const spacesAndCommas = new RegExp(/[\s|,]+/, 'g');
  const quotes = new RegExp(/\\"|"/, 'g');

  let criteria = [];

  // 1. add values between quotes
  let match = textBetweenquotes.exec(_value);

  while (match) {
    criteria.push(match[0]);
    match = textBetweenquotes.exec(_value);
  }

  let value = _value.replace(textBetweenquotes, '');

  // 2. remove unnecessary commas and spaces
  value = trim(value.replace(spacesAndCommas, ' '));

  // 3. fix quotes
  value = value.replace(quotes, '\\"');

  // 4. add values between spaces
  criteria = criteria.concat(value.split(' '));

  return criteria;
}
