import React from 'react';
import PropTypes from 'prop-types';

import { FormattedHTMLMessage } from 'view-components/utilities/intl';
import UpperSectionAlert from 'view-components/components/Dialog/UpperSection/Alert';
import { LowerSectionText } from 'v-c/Dialog/Components';

import LowerSectionDeleteConfirm from './Components/LowerSectionDeleteConfirm';
import confirmableDialog from './Components/ConfirmableDialog';
import { DialogProps } from 'global/types';

function UpdateAccountSettingsDialogFn(props: DialogProps) {
  const {
    isConfirmed,
    onConfirm,
    onDismiss,
    toggleConfirmed,
  } = props;

  const titleID = 'app.dialog.update_account_settings.message';
  const messageID = 'app.dialog.update_account_settings.lower_section';

  return (
    <div>
      <UpperSectionAlert>
        <FormattedHTMLMessage id={titleID} />
      </UpperSectionAlert>

      <LowerSectionDeleteConfirm
        buttonText="update"
        {...{ toggleConfirmed, isConfirmed, onConfirm, onDismiss }}
        deleteMessage={
          <FormattedHTMLMessage id="app.dialog.update_account_settings.confirm_message" />
        }
        additionalTopContent={
          <LowerSectionText>
            <FormattedHTMLMessage id={messageID} />
          </LowerSectionText>
        }
      />
    </div>
  );
}

UpdateAccountSettingsDialogFn.propTypes = {
  isConfirmed: PropTypes.bool,
  onConfirm: PropTypes.func,
  onDismiss: PropTypes.func,
  toggleConfirmed: PropTypes.func,
};

UpdateAccountSettingsDialogFn.defaultProps = {
  isConfirmed: false,
  onConfirm: () => {},
  onDismiss: () => {},
  toggleConfirmed: () => {},
};

const UpdateAccountSettingsDialog = confirmableDialog(UpdateAccountSettingsDialogFn);

export default UpdateAccountSettingsDialog;
