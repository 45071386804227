import { ReduxState } from 'global/types';
import reduxStore, { RootState } from '../reduxStore';

import prepareAdvancedSearchForHighlighting from './PrepareAdvancedSearchForHighlighting';
import prepareSimpleSearchForHighlighting from './PrepareSimpleSearchForHighlighting';

export type HighlightValues = {
  subject?: [],
  author?: [],
  to?: [],
  attachment_name?: [],
  body?: [],
  attachment_body?: [],
  cc?: [],
  bcc?: [],
  from?: []
}

export default function collectHighlightKeywords() {
  let highlightValues: HighlightValues = {
    subject: [],
    author: [],
    to: [],
    attachment_name: [],
    body: [],
    attachment_body: [],
    cc: [],
    bcc: [],
  };
  const {
    searchLists: { searchLists },
    unsavedSearch: {
      data: {
        clientData: { formValues, type, defaults },
        filteredFormValues,
      },
    },
  } = reduxStore.getState() as ReduxState;

  if (formValues) {
    switch (type) {
      case 'advanced': {
        highlightValues = prepareAdvancedSearchForHighlighting(
          { list: formValues, defaults },
          searchLists,
        );
        break;
      }
      case 'simple': {
        highlightValues = prepareSimpleSearchForHighlighting(
          formValues,
          searchLists,
        );
        break;
      }
      default:
        highlightValues = {
          subject: [],
          author: [],
          to: [],
          attachment_name: [],
          body: [],
          attachment_body: [],
          cc: [],
          bcc: [],
        };
    }
  }

  if (Object.keys(filteredFormValues).length) {
    const filteredHiglightValues = prepareSimpleSearchForHighlighting(
      filteredFormValues,
    );

    Object.keys(filteredHiglightValues).forEach((highlightValue) => {
      highlightValues[highlightValue] = highlightValues[highlightValue].concat(
        filteredHiglightValues[highlightValue],
      );
    });
  }

  return highlightValues;
}
