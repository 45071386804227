/* eslint-disable filenames/match-regex */
/* eslint-disable no-undef */
import React, { Component } from 'react';
/* eslint-enable filenames/match-regex */

type Props = {
  onActionClick: Function
  actionsConfig: {}
  openedAction: string
  actionsProps: any
  actions: string[]
}

type State = {
  openedAction: string
}

export function renderActionsProps(props: Props) {
  return (
    props.actions.map(action => ({
      action,
      props: {
        ...props.actionsProps,
        openedAction: props.openedAction,
        onActionClick: props.onActionClick,
        actionHelper: props.actionsConfig,
      },
    }))
  );
}

export function stateLogic({
  tableActions,
}: any) {
  return function ActionsRendererStateLogicComposer(component: any) {
    return class ActionsRendererStateLogic extends Component<Props, State> {
      constructor(props: Props) {
        super(props);

        this.state = { openedAction: '' };
      }

      onActionClick = (handledAction: string) => {
        this.setState({
          openedAction: this.state.openedAction === handledAction ? '' : handledAction,
        });
      }

      render() {
        return React.createElement(
          component,
          {
            ...this.props,
            ...this.state,
            onActionClick: this.onActionClick,
            tableActions,
          },
        );
      }
    };
  };
}
