import sortByJobStatusOrder from 'component-lib/sortByJobStatusOrder';
import sortByNaturalString from 'component-lib/sortByNaturalString';
import sortByMomentDates from 'component-lib/sortByMomentDates';
import ensureNewVisibleColumnSortOrder from 'component-lib/ensureNewVisibleColumnSortOrder';
import toggleInArray from 'component-lib/toggleInArray';

import IfFeature from '../components/IfFeature';
import createReducer from '../lib/createReducer';

import configs from '../../config/configs';
import { Job } from 'global/types';

const {
  COLUMNS,
  ALL_COLUMNS: possibleAllColumns,
  DISABLED_COLUMNS,
} = configs.AUDITING;

const ALL_COLUMNS = possibleAllColumns.filter(
  column => IfFeature.isEnabled('category_audit') || column !== COLUMNS.NAME);

export { COLUMNS, ALL_COLUMNS, DISABLED_COLUMNS };

const {
  NAME,
  STATUS,
  REQUESTED_DATE,
  REQUESTED_BY,
  DATE_RANGE,
} = COLUMNS;

export const INITIAL_STATE = {
  filter: '',
  sort: {
    key: STATUS,
    order: 'asc',
  },
  visibleColumns: ALL_COLUMNS,
  dropdown: {
    show: false,
    success: null,
  },
};

const RESET_FILTER = 'auditsTableUI/resetFilter';
const SET_VISIBLE_COLUMNS = 'auditsTableUI/setVisibleColumns';
const SET_FILTER = 'auditsTableUI/setFilter';
const SORT_BY = 'auditsTableUI/sortBy';

export function resetFilter() {
  return {
    type: RESET_FILTER,
    payload: { filter: INITIAL_STATE.filter },
  };
}

export function sort(payload: {}) {
  return {
    type: SORT_BY,
    payload: { sort: payload },
  };
}

export function setFilter(payload: {}) {
  return {
    type: SET_FILTER,
    payload: { filter: payload },
  };
}

export function setVisibleColumns(_: any, payload: {}) {
  return {
    type: SET_VISIBLE_COLUMNS,
    payload,
  };
}

// helpers

export function sortAudits({ jobs, key, order }: any) {
  let sortOp;

  switch (key) {
    case STATUS: {
      sortOp = sortByJobStatusOrder(order).thenBy('requestedDateForSort', -1);
      break;
    }
    case REQUESTED_DATE: {
      sortOp = sortByMomentDates(order, 'requestedDateForSort');
      break;
    }
    default:
      sortOp = sortByNaturalString({ key, order });
      break;
  }

  return [...jobs].sort(sortOp);
}

export function filterAudits(exports: Job[], filter: string) {
  return exports.filter(job => (
    [NAME, REQUESTED_DATE, REQUESTED_BY, DATE_RANGE].some(field => (
      String(job[field]).toLowerCase().indexOf(filter.toLowerCase()) !== -1
    ))
  ));
}

const AuditsTableUIStateManager = createReducer(INITIAL_STATE, {
  [RESET_FILTER]: (state: {}, payload: {}) => ({ ...state, ...payload }),
  [SET_FILTER]: (state: {}, payload: {}) => ({ ...state, ...payload }),
  [SORT_BY]: (state: {}, payload: {}) => ({ ...state, ...payload }),
  [SET_VISIBLE_COLUMNS]: (state: { visibleColumns: string[] }, payload: {}) => {
    const newVisibleColumns = toggleInArray(state.visibleColumns, payload);

    return {
      ...state,
      visibleColumns: ensureNewVisibleColumnSortOrder(newVisibleColumns, ALL_COLUMNS),
    };
  },
});

export default AuditsTableUIStateManager;
