/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isEmpty, pickBy, isEqual, isObject } from 'lodash';
import reduxStore from '../../reduxStore';
import { Fields, HoldReason, MultiSelect, ReduxState, Search } from 'global/types';

const fixedFilterTypes = {
  RECEIVED: 'Received',
  SENT: 'Sent',
};

type Props = {
  onActionClick: Function,
  reasons: HoldReason[],
  updateFilter: Function,
  fields: Fields,
  values: {
    filter: {}
  },
  filter: {
    selectedSearchId: string
    type: string
    selectedFixedFilter: {}
  },
  searchId: string,
  searchForm: {
    filter: {},
  },
  loadLegalHoldReasons: Function,
  contextTree: {
    REVIEW_STATUSES: {},
    getReviewAttributeId: Function,
  },
  runFilteredUnsavedSearch: Function,
  unsavedSearch: Search,
  multiSelect: MultiSelect,
  runFixedFilterPersonalSearch: Function
}

export default function FilterDeconstructed({
  generateFilteredQuery,
  toDSL,
  QUERY_TYPES,
  openSavedSearchStream,
  openHoldReasonAssetsStream,
}: any) {
  return function FilterStateLogicComposer(component: any) {
    class FilterStateLogic extends Component<Props> {
      static propTypes = {
        onActionClick: PropTypes.func.isRequired,
        reasons: PropTypes.array.isRequired,
        updateFilter: PropTypes.func.isRequired,
        fields: PropTypes.object.isRequired,
        values: PropTypes.object.isRequired,
        filter: PropTypes.object.isRequired,
        searchId: PropTypes.string,
        searchForm: PropTypes.shape({
          filter: PropTypes.object.isRequired,
        }).isRequired,
        loadLegalHoldReasons: PropTypes.func.isRequired,
        contextTree: PropTypes.shape({
          REVIEW_STATUSES: PropTypes.object.isRequired,
          getReviewAttributeId: PropTypes.func.isRequired,
        }).isRequired,
        runFilteredUnsavedSearch: PropTypes.func.isRequired,
        unsavedSearch: PropTypes.shape({
          data: PropTypes.shape({
            query: PropTypes.string.isRequired,
            filteredFormValues: PropTypes.object.isRequired,
          }).isRequired,
        }).isRequired,
        multiSelect: PropTypes.shape({
          reset: PropTypes.func.isRequired,
        }),
        runFixedFilterPersonalSearch: PropTypes.func.isRequired,
      };

      static defaultProps = {
        multiSelectReset: () => {},
        multiSelect: {},
        searchId: null,
      };

      constructor(props: Props) {
        super(props);

        props.loadLegalHoldReasons({ loadIfNecessary: true });
        props.fields.filter.onChange(props.searchForm.filter);
      }

      componentDidUpdate(prevProps: Props) {
        if (
          !isEqual(
            this.props.unsavedSearch.data.filteredFormValues,
            prevProps.unsavedSearch.data.filteredFormValues,
          )
        ) {
          this.openNewFilteredStream();
        }
      }

      onFilter = () => {
        const {
          filter,
          searchId,
          updateFilter,
          contextTree: { REVIEW_STATUSES, getReviewAttributeId },
          runFilteredUnsavedSearch,
        } = this.props;
        const filteredQuery = generateFilteredQuery(
          filter.type,
          toDSL(this.nonEmptyFormValues, {
            searchId,
            REVIEW_STATUSES,
            getReviewAttributeId,
          }),
        );

        runFilteredUnsavedSearch(this.nonEmptyFormValues, filteredQuery);
        updateFilter(this.nonEmptyFormValues);
      };

      onCancel = () => {
        this.props.onActionClick();
      };

      onClearAll = () => {
        this.props.fields.filter.onChange({});
      };

      get holdReasons() {
        return this.props.reasons.map((reason) => {
          const modifiedReason = { ...reason };

          modifiedReason.value = reason.name;

          return modifiedReason;
        });
      }

      get nonEmptyFormValues() {
        const {
          values: { filter },
        } = this.props;

        if (!filter) {
          return {};
        }

        const fieldsWithValues = Object.keys(filter).reduce((all, field) => {
          const modifiedAll = { ...all };
          const fieldKeys = pickBy(filter[field], value => !!value.length);

          if (Object.keys(fieldKeys).length) {
            modifiedAll[field] = fieldKeys;
          }

          return modifiedAll;
        }, {});

        return pickBy(fieldsWithValues, value => Object.keys(value).length);
      }

      get fixedFormValues() {
        const { filter } = this.props.values;

        return isObject(filter) ? filter : {};
      }

      unSavedStreamFilter = () => {
        const { filter } = this.props;

        const { RECEIVED, SENT } = fixedFilterTypes;

        const userStream = (reduxStore.getState() as ReduxState).user.user.handles.reduce((handleStore: any, user: any) => [...handleStore, user.handle], []);

        let filterStream = '';

        if (filter.selectedFixedFilter === SENT) {
          filterStream = { from: { query: userStream.join(' ') } };
        } else if (filter.selectedFixedFilter === RECEIVED) {
          filterStream = { participants: { query: userStream.join(' ') } };
        }

        const unsavedSearchStream = generateFilteredQuery(
          QUERY_TYPES.UNSAVED_MY_ARCHIVE,
          toDSL(filterStream),
        );
        return unsavedSearchStream;
      }

      openNewFilteredStream() {
        const {
          filter,
          searchId,
          multiSelect,
          onActionClick,
          unsavedSearch: {
            data: { filteredFormValues, query },
          },
          runFixedFilterPersonalSearch,
        } = this.props;

        const userQuery = isEmpty(filteredFormValues) ? null : query;
        const modifiedSearchId = searchId || filter.selectedSearchId;

        let filteredQuery = userQuery;

        if (!modifiedSearchId) {
          const selectedSearchStream = this.unSavedStreamFilter();

          filteredQuery = [selectedSearchStream, userQuery].join(' ');
        }

        switch (filter.type) {
          case QUERY_TYPES.SAVED_SEARCH:
            openSavedSearchStream(searchId, filteredQuery);
            break;
          case QUERY_TYPES.HOLD_ASSETS:
            openHoldReasonAssetsStream(searchId, filteredQuery);
            break;
          case QUERY_TYPES.MY_ARCHIVE:
          case QUERY_TYPES.UNSAVED_MY_ARCHIVE:
            if (!!filter.selectedFixedFilter && isEmpty(filteredFormValues)) {
              runFixedFilterPersonalSearch(filter.selectedFixedFilter);
            } else {
              openSavedSearchStream(modifiedSearchId, filteredQuery, true);
            }
            break;
          default:
            console.error('Invalid filter type');
        }

        if (multiSelect) {
          multiSelect.reset();
        }
        onActionClick();
      }

      render() {
        return React.createElement(component, {
          ...this.props,
          ...this.state,
          onFilter: this.onFilter,
          onCancel: this.onCancel,
          onClearAll: this.onClearAll,
          holdReasons: this.holdReasons,
          nonEmptyFormValues: this.nonEmptyFormValues,
          fixedFormValues: this.fixedFormValues,
        });
      }
    }

    return FilterStateLogic;
  };
}
