import React from 'react';
import PropTypes from 'prop-types';

import LoadingIndicator from 'v-c/LoadingIndicator';
import styles from './Processing.scss';

type Props = {
  text: string
  isProcessing: boolean
}

export default function Processing({ text, isProcessing }: Props) {
  if (!isProcessing) {
    return <div />;
  }

  return (
    <data>
      <div className={styles['processing-mask-layer']} />
      <div data-processing className={styles['processing-layer']}>
        <LoadingIndicator
          orientation="middle"
          text={text}
          isLoading
        />
      </div>
    </data>
  );
}

Processing.propTypes = {
  isProcessing: PropTypes.bool,
  text: PropTypes.string,
};

Processing.defaultProps = {
  isProcessing: false,
  text: 'Loading...',
};
