import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { jsUi } from '../../global/lib/jsUi';
import { QUERY_TYPES } from '../../global/lib/generateFilteredQuery';
import withSerialResultsLoader, {
  LOAD_TYPES,
} from '../../global/lib/withSerialResultsLoader';
import routeLeaveHook from '../../global/lib/routeLeaveHook';

import SearchEditingBase from './components/SearchEditingBase';
import { openUnsavedSearchStream } from '../../global/lib/searchResultsStreamManager';
import { runUnsavedSearch } from '../../global/state_managers/UnsavedSearchStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';
import { resetStream } from '../../global/state_managers/SearchResults/searchResultsStream';
import { initTableConfig } from '../../global/state_managers/SearchResults/tableConfig';

const mapStateToProps = ({ searchForm, unsavedSearch, searchResults }: any) => ({
  searchForm,
  unsavedSearch,
  clientData: unsavedSearch.data.clientData,
  searchResults,
});
const mapActionsToProps = {
  runUnsavedSearch,
  setBreadcrumbForNewSearchResultsPage: breadcrumbsActions.newSearchResultsPage,
  resetStream,
  initTableConfig,
};

// TODO: get rid of the inheritance, replace with HOC
/* eslint-disable class-methods-use-this */
@routeLeaveHook
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withSerialResultsLoader(LOAD_TYPES.SEARCH_RESULTS_LOAD_MORE)
export default class UnsavedSearchResults extends SearchEditingBase {
  static propTypes = {
    setBreadcrumbForNewSearchResultsPage: PropTypes.func.isRequired,
    resetStream: PropTypes.func.isRequired,
    initTableConfig: PropTypes.func.isRequired,
    registerUnmountCleanupMethod: PropTypes.func.isRequired,
  };

  constructor(props: any) {
    super(props);

    const { type } = props.clientData;

    if (!type) {
      jsUi.newSearchIndex.goTo();
    }

    this.searchId = 'unsavedSearch';

    props.setBreadcrumbForNewSearchResultsPage();
    props.initTableConfig('unsavedSearch');
    props.registerUnmountCleanupMethod(props.resetStream);

    openUnsavedSearchStream({ type: QUERY_TYPES.UNSAVED_SEARCH });
  }

  get isLoaded() {
    const { searchResults } = this.props;

    if (this.isInitialLoad && searchResults.isLoading) {
      this.isInitialLoad = false;
    } else if (!this.isInitialLoad) {
      return !searchResults.isLoading && searchResults.isLoaded;
    }

    return false;
  }

  handleEdit = () => {
    jsUi.newSearchEdit.goTo();
  };

  handleSave = () => {
    jsUi.newSearchSave.goTo();
  };

  getSectionName() {
    return 'results-unsaved-search';
  }

  goToRenderedAsset(assetId: string) {
    return jsUi.newSearchResultsAsset.path({ asset_id: assetId });
  }

  getSearchFormViewProps() {
    return {
      searchType: 'new',
      formView: 'results',
      onSave: this.handleSave,
      onEdit: this.handleEdit,
      header: this.renderUnsavedSearchHeader(),
      content: this.renderResultsContent(),
      clientData: this.props.clientData,
      onSearch: this.props.runUnsavedSearch,
    };
  }
}
