import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { TABS } from 'v-c/Search/SearchTool';
import withMessages from 'component-utilities/intl';
import routeLeaveHook from '../../global/lib/routeLeaveHook';

import Layout from '../../global/components/Layout';
import HeadlineLabel from '../../global/components/HeadlineLabel';
import FormView from './NewSearchResults/FormView';

import { resetAll as resetSearchFormState } from '../../global/state_managers/SearchFormStateManager';
import { resetCurrentSavedSearch } from '../../global/state_managers/CurrentSavedSearchStateManager';
import { resetUnsavedSearch } from '../../global/state_managers/UnsavedSearchStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';
import { setInitialTableConfig } from '../../global/state_managers/SearchResults/tableConfig';

const mapStateToProps = ({ searchForm }: any) => ({ searchForm });

const mapActionsToProps = {
  resetSearchFormState,
  resetCurrentSavedSearch,
  resetUnsavedSearch,
  setBreadcrumbForNewSearchPage: breadcrumbsActions.newSearchPage,
  setInitialTableConfig,
};

type Props = {
  searchForm: {
    currentView: string
  },
  resetSearchFormState: Function,
  getMessage: Function,
  location: {
    query: any,
  },
  resetCurrentSavedSearch: Function,
  resetUnsavedSearch: Function,
  setBreadcrumbForNewSearchPage: Function,
  setInitialTableConfig: Function,

}

@routeLeaveHook
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withMessages
export default class NewSearchResults extends Component<Props> {
  static propTypes = {
    searchForm: PropTypes.shape({
      currentView: PropTypes.oneOf(Object.keys(TABS).map(tab => TABS[tab]))
        .isRequired,
    }).isRequired,
    resetSearchFormState: PropTypes.func.isRequired,
    getMessage: PropTypes.func.isRequired,
    location: PropTypes.shape({
      query: PropTypes.object,
    }).isRequired,
    resetCurrentSavedSearch: PropTypes.func.isRequired,
    resetUnsavedSearch: PropTypes.func.isRequired,
    setBreadcrumbForNewSearchPage: PropTypes.func.isRequired,
    setInitialTableConfig: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    if (!props.location.query.skipReset) {
      props.resetCurrentSavedSearch();
      props.resetSearchFormState();
      props.resetUnsavedSearch();
      props.setInitialTableConfig('unsavedSearch');
    }

    props.setBreadcrumbForNewSearchPage();
  }

  get isSimpleView() {
    return this.props.searchForm.currentView === TABS.SIMPLE;
  }

  get layoutAttributes() {
    return {
      staticView: this.isSimpleView ? true : null,
    };
  }

  render() {
    return (
      <Layout section="new-search" {...this.layoutAttributes}>
        <header>
          <HeadlineLabel
            text={this.props.getMessage('app.breadcrumb.new_search')}
          />
        </header>
        <FormView
          {...this.props}
          {...this.state}
          isSimpleView={this.isSimpleView}
          isInitialForm
        />
      </Layout>
    );
  }
}
