import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withMessages, {
  FormattedMessage,
  FormattedHTMLMessage,
} from 'component-utilities/intl';

import { TextLabel } from 'v-c/Widgets/Components';

import RetinaImage from 'v-c/RetinaImage';
import LoadingIndicator from 'v-c/LoadingIndicator';

import FilteredDropdown from './FilteredDropdown';
import ADLogoImage from './images/ad_logo.png';
import ViewLogoImage from './images/view_logo.png';
import ArrowImage from './images/wide-arrow.png';
import GrayArrowImage from './images/wide-arrow-gray.png';
import BrokenArrowImage from './images/wide-arrow-broken.png';

import { getRemoteAttributes, getLocalAttributes, getAttributeMappings, setPairs } from '../../../global/state_managers/ADSyncStateManager';

import style from './AttributeMappings.scss';
import { Pairs } from 'global/types';

const mapActionsToProps = { getRemoteAttributes, getLocalAttributes, getAttributeMappings, setPairs };

type Props = {
  getRemoteAttributes: Function,
  getLocalAttributes: Function,
  getAttributeMappings: Function,
  pairs: Pairs,
  setPairs: Function,
  ottoAttributes: any[],
  localAttributesLoading: Function,
  remoteAttributesLoading: Function,
  attributeMappingsLoading: Function,
  getMessage: Function,
  remoteAttributes: any

}

@withMessages
@connect(null, mapActionsToProps)
export default class AttributeMappings extends Component<Props> {
  static propTypes = {
    pairs: PropTypes.object,
    ottoAttributes: PropTypes.array,
    remoteAttributes: PropTypes.array,
    localAttributesLoading: PropTypes.bool,
    remoteAttributesLoading: PropTypes.bool,
    attributeMappingsLoading: PropTypes.bool,
    getMessage: PropTypes.func.isRequired,
    getRemoteAttributes: PropTypes.func.isRequired,
    getLocalAttributes: PropTypes.func.isRequired,
    getAttributeMappings: PropTypes.func.isRequired,
    setPairs: PropTypes.func.isRequired,
  };

  static defaultProps = {
    pairs: null,
    ottoAttributes: null,
    remoteAttributes: null,
    localAttributesLoading: null,
    remoteAttributesLoading: null,
    attributeMappingsLoading: null,
  };

  constructor(props: Props) {
    super(props);

    props.getRemoteAttributes();
    props.getLocalAttributes();
    props.getAttributeMappings();
  }

  getMatchingAttr = (pool: any, internalAttr: any) => {
    const attributeName = this.props.pairs.getExternal(internalAttr);
    if (attributeName) {
      return pool.find((p: any) => p.name === attributeName);
    }
    return null;
  };

  mappingsAreValid = () => {
    let validityCounter = 0;
    const attrs = this.props.ottoAttributes;
    // TODO: replace with a reduce
    attrs.forEach((attr) => {
      if (this.mappingValidation(attr) !== 'invalid') {
        validityCounter += 1;
      }
    });
    return validityCounter === attrs.length;
  };

  updateAttribute = (externalAttr: any, internalAttr: any) => {
    this.props.pairs.updatePair(externalAttr, internalAttr);
    this.props.setPairs(this.props.pairs);
  };

  resetAttribute = (externalAttr: any, internalAttr: any) => {
    this.props.pairs.resetPair(externalAttr, internalAttr);
    this.props.setPairs(this.props.pairs);
  };

  mappingValidation = (internalAttr: any) => {
    const mappingExists = this.props.pairs.mappingExists(internalAttr.name);
    let validity = 'valid';
    if (internalAttr.optional && !mappingExists) {
      validity = 'empty';
    }
    if (!internalAttr.optional && !mappingExists) {
      validity = 'invalid';
    }
    return validity;
  };

  render() {
    const {
      localAttributesLoading,
      remoteAttributesLoading,
      attributeMappingsLoading,
      getMessage,
    } = this.props;

    if (!this.props.ottoAttributes) {
      return null;
    }

    const remoteOptions = {
      emptyText: getMessage('app.ad_sync.attribute_mappings.select_attribute'),
    };

    const ottoOptions = {
      emptyText: getMessage('app.ad_sync.attribute_mappings.select_attribute'),
      disabled: true,
    };

    const permittedAttributes = (list: any, type: string) => {
      const filtering = (i: any) => i.dataType === type;
      const mapping = (item: any) => ({ ...item, localisedName: item.name });

      return list.filter(filtering).map(mapping);
    };

    const localisedAttributes = (list: any[]) =>
      list.map(item => ({
        ...item,
        localisedName: getMessage(`app.ad_sync.otto_attributes.${item.name}`),
      }));

    const remotePool = (type: string) =>
      permittedAttributes(this.props.remoteAttributes, type);

    // TODO: rework this a bit. it's pretty inefficient, especially all the
    // this.mappingValidation calls.
    // TODO: find a better key for the dropdown rows
    /* eslint-disable react/no-array-index-key */
    const localizedOttoAttributes = localisedAttributes(
      this.props.ottoAttributes,
    );

    const attributeDropdowns = localizedOttoAttributes.map((attr, i) => (
      <div className={style['dropdown-row']} key={i}>
        <div className={style['remote-dropdown']}>
          <FilteredDropdown
            pool={remotePool(attr.dataType)}
            setAttibute={(externalAttr: any) => {
              this.updateAttribute(externalAttr, attr.name);
            }}
            reset={(externalAttr: any) => {
              this.resetAttribute(externalAttr, attr.name);
            }}
            value={this.getMatchingAttr(remotePool(attr.dataType), attr.name)}
            parent={this}
            options={remoteOptions}
          />
        </div>
        {this.mappingValidation(attr) === 'valid' && (
          <RetinaImage className={style.arrow} image={ArrowImage} />
        )}{' '}
        {this.mappingValidation(attr) === 'invalid' && (
          <RetinaImage className={style.arrow} image={BrokenArrowImage} />
        )}{' '}
        {this.mappingValidation(attr) === 'empty' && (
          <RetinaImage className={style.arrow} image={GrayArrowImage} />
        )}
        <div className={style['otto-dropdown']}>
          <FilteredDropdown
            value={attr}
            pool={localizedOttoAttributes}
            options={ottoOptions}
          />
        </div>
      </div>
    ));
    /* eslint-enable react/no-array-index-key */

    // TODO: get rid of the string ref
    /* eslint-disable react/no-string-refs */
    return (
      <div className={style['attribute-mappings']} ref="attributeMapppings">
        <div className={style['mapping-header']}>
          <div className={style['mapping-logo']}>
            <RetinaImage image={ADLogoImage} />
            <TextLabel>
              <FormattedMessage id="app.ad_sync.attribute_mappings.microsoft_azure_ad" />
            </TextLabel>
          </div>
          {this.mappingsAreValid() && (
            <RetinaImage className={style['logo-arrow']} image={ArrowImage} />
          )}
          {!this.mappingsAreValid() && (
            <RetinaImage
              className={style['logo-arrow']}
              image={BrokenArrowImage}
            />
          )}
          <div className={style['mapping-logo']}>
            <RetinaImage image={ViewLogoImage} />
            <TextLabel>
              <FormattedMessage id="app.ad_sync.attribute_mappings.view_archive" />
            </TextLabel>
            <TextLabel className={style.required}>
              <FormattedHTMLMessage id="app.ad_sync.attribute_mappings.required" />
            </TextLabel>
          </div>
        </div>
        {(localAttributesLoading ||
          remoteAttributesLoading ||
          attributeMappingsLoading) && (
            <LoadingIndicator
              orientation="middle"
              text={getMessage('app.general.loading')}
              isLoading
            />
          )}
        {!localAttributesLoading &&
          !remoteAttributesLoading &&
          !attributeMappingsLoading && <div>{attributeDropdowns}</div>}
      </div>
    );
  }
}
