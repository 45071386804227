import { stemmer } from 'stemmer';

const questionMarkReplacement = '[\\w]';
const wildcardReplacement = '[\\w]*';
const SPECIAL_CHARS = ':;.,‒_(){}[]#&|<>/~@$%^!\'\\`"+=-';

export default function replaceWildcards(criteria: string, isExact = false) {

  if (criteria.length === 1 && SPECIAL_CHARS.includes(criteria)) { // add backslash to all special characters
    criteria = `\\${criteria}`;
  }

  if (isExact) {
    return `\\b${applyReplacements(criteria)}\\b`; // replace * and ? occurences
  }

  return `\\b${applyReplacements(stemmer(criteria))}${wildcardReplacement}`;
}

function applyReplacements(criteria: string) {
  return criteria
    .replaceAll('?', questionMarkReplacement)
    .replaceAll('*', wildcardReplacement)
}
