import React from 'react';
import PropTypes from 'prop-types';

import { FormattedHTMLMessage } from 'component-utilities/intl';

import {
  ModalText,
} from '../../Modals';

export default function ErrorContent({ BASE_KEY }: { BASE_KEY: string }) {
  return (
    <div data-error-message><ModalText>
      <FormattedHTMLMessage id={`${BASE_KEY}.error`} />
    </ModalText></div>
  );
}

ErrorContent.propTypes = {
  BASE_KEY: PropTypes.string.isRequired,
};

ErrorContent.displayName = 'Modals/BulkToggleUsers/ErrorContent';
