import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalText from 'v-c/Modals/ModalText';

import { FormattedHTMLMessage } from 'component-utilities/intl';

import withBulkUserBaseProps from '../withBulkUserBaseProps';
import Header from '../../../../global/components/Modals/BulkToggleUsers/Header';
import ErrorContent from '../../../../global/components/Modals/BulkToggleUsers/ErrorContent';
import Buttons from '../../../../global/components/Modals/BulkToggleUsers/Buttons.tsx';

import {
  ModalContainer,
  ModalContentCenter,
} from '../../../../global/components/Modals';

const BASE_KEY = 'app.users.bulk_disable_modal';

type Props = {
  closeModal: Function,
  modalOptions: {
    multiSelect: {},
    userCount: number,
    filters: string,
  },
  resetUsersBulkOperations: Function,
  bulkDisableUsers: Function,
  getMessage: Function,
  hasErrors: boolean,
  isProcessing: boolean,
}

@withBulkUserBaseProps
export default class BulkDisableUsersModal extends Component<Props> {
  static propTypes = {
    ...withBulkUserBaseProps.propTypes,
    closeModal: PropTypes.func.isRequired,
    modalOptions: PropTypes.shape({
      multiSelect: PropTypes.object.isRequired,
      userCount: PropTypes.number.isRequired,
      filters: PropTypes.string,
    }).isRequired,
    resetUsersBulkOperations: PropTypes.func.isRequired,
    bulkDisableUsers: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    props.resetUsersBulkOperations();
  }

  bulkDisable = () => {
    const { multiSelect, filters } = this.props.modalOptions;
    this.props.bulkDisableUsers(multiSelect, { filters });
  };

  render() {
    const {
      getMessage,
      hasErrors,
      isProcessing,
      modalOptions: { userCount },
    } = this.props;

    return (
      <ModalContainer isProcessing={isProcessing}>
        <Header
          BASE_KEY={BASE_KEY}
          hasErrors={hasErrors}
          userCount={userCount}
          type="users-disable"
          hideModal={() =>
            this.props.closeModal({ from: 'BulkDisableUsersModal/Header' })
          }
        />
        {!hasErrors && (
          <ModalContentCenter>
            <ModalText>
              <FormattedHTMLMessage
                id={`${BASE_KEY}.body`}
                values={{ count: userCount }}
              />
            </ModalText>
          </ModalContentCenter>
        )}
        {hasErrors && (
          <ModalContentCenter>
            {hasErrors && <ErrorContent BASE_KEY={BASE_KEY} />}
          </ModalContentCenter>
        )}
        <Buttons
          hasErrors={hasErrors}
          BASE_KEY={BASE_KEY}
          getMessage={getMessage}
          okAction={this.bulkDisable}
          hideModal={() =>
            this.props.closeModal({ from: 'BulkDisableUsersModal/Buttons' })
          }
        />
      </ModalContainer>
    );
  }
}
