import React from 'react';
import PropTypes from 'prop-types';

import { FormattedHTMLMessage } from 'component-utilities/intl';
import EmptyResults from 'v-c/EmptyPageViews/ExportsEmptyResults';

import Factory, {
  TableViewHeading,
} from '../../global/components/TableView/TableViewContentFactory';
import { actions } from '../../global/components/TableViewActions';

import { ALL_COLUMNS, DISABLED_COLUMNS } from '../../global/state_managers/ExportTableUIStateManager';

import TableView from './TableView';

const columns = {
  EXPORT_STATUS: 'status',
  EXPORT_NAME: 'exportName',
  REQUESTED_BY: 'requester',
  EXPIRES_ON: 'expiresOn',
  RESULTS: 'results',
  FORMAT: 'format',
};

const tableHelper = {
  columns,
  allColumns: ALL_COLUMNS,
  activeColumns: ALL_COLUMNS,
  disabledColumns: DISABLED_COLUMNS,
};

export default function Loaded(props: any) {
  const { returnCount, searchString, emptyText } = props;

  const factoryProps = {
    returnCount,
    searchString,
    heading: <TableViewHeading returnCount={returnCount} notifyHeight={() => {}} />,
    content: <TableView {...props} visibleTableActions={[actions.SEARCH]} />,
    tableHelper,
    emptyResults: (
      <EmptyResults>
        <FormattedHTMLMessage id={emptyText} />
      </EmptyResults>
    ),
  };

  return <Factory {...factoryProps} />;
}

Loaded.propTypes = {
  searchString: PropTypes.string.isRequired,
  emptyText: PropTypes.string.isRequired,
  returnCount: PropTypes.number.isRequired,
  jobs: PropTypes.array.isRequired,
};

Loaded.displayName = 'Exports/Loaded';
