import { includes, difference } from 'lodash';

export default function sortAboutSelection(list: any[], selection = [], prop = 'id', onTop = true) {
  const selectedItems = list.filter(item => includes(selection, item[prop]));
  const notSelectedItems = difference(list, selectedItems);

  return (
    onTop ? selectedItems.concat(notSelectedItems) : notSelectedItems.concat(selectedItems)
  );
}
