import React, { Component } from 'react';
import CommentContent from '../TableViewActions/CommentContent';

export default class CommentsTab extends Component {
  render() {
    return (
      <CommentContent />
    );
  }
}
