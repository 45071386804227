/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import dialogHandler from '../../lib/dialogHandler';

type Props = {
  setRouterWillLeaveValidator: Function
  retryTransition: () => boolean
}

export default function actionsDirtyCheck(dialogAction: any) {
  return function actionsDirtyCheckDecorator(component: any) {
    return class actionsDirtyCheckComponent extends Component<Props> {
      static propTypes = {
        setRouterWillLeaveValidator: PropTypes.func.isRequired,
        retryTransition: PropTypes.func.isRequired,
      };

      constructor(props: Props) {
        super(props);

        this.actionsDirtyState = {};

        if (props.setRouterWillLeaveValidator) {
          props.setRouterWillLeaveValidator(this.routerWillLeave);
        }
      }

      routerWillLeave = () => {
        if (!this.isComponentDirty()) {
          return true;
        }

        dialogHandler(dialogAction, this.props.retryTransition);

        return false;
      };

      isComponentDirty = () => {
        const actionKeys = Object.keys(this.actionsDirtyState);

        return !!actionKeys.some(action => this.actionsDirtyState[action]);
      };

      updateActionsDirtyState = (action: string, newValue: any) => {
        this.actionsDirtyState[action] = newValue;
      };

      render() {
        return React.createElement(component, {
          ...this.props,
          updateActionsDirtyState: this.updateActionsDirtyState,
          isActionsDirtyCheckComponentDirty: this.isComponentDirty,
          actionsDirtyState: this.actionsDirtyState,
        });
      }
    };
  };
}
