import reduxStore, { Action, PromiseObject } from '../reduxStore';

import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';
import {
  prioritizeRoles,
  getPageRolesWithTags,
  isActivePathRole,
} from '../lib/userRights';
import isValidEmail from '../lib/isValidEmail';
import { jsUi } from '../lib/jsUi';

import PAGES from '../constants/Pages';

import { showSuccessFlash } from './FlashMessagesStateManager';
import { goToHomePage } from './AuthenticationStateManager';

const RoleTypes = getPageRolesWithTags();

export const BASE_FLASH: string = 'user';
export const VALID_FLASH_VIEWS: string[] = [PAGES.USER_MANAGEMENT];

export const RESET: string = 'user/reset';
export const RESET_ERRORS: string = 'user/resetErrors';
export const USER_LOADING: string = 'user/userLoading';
export const USER_LOADED: string = 'user/userLoaded';
export const FAILED_TO_LOAD_USER: string = 'user/failedToLoadUser';
export const USER_CREATING: string = 'user/userCreating';
export const USER_CREATED: string = 'user/userCreated';
export const FAILED_TO_CREATE_USER: string = 'user/failedToCreateUser';
export const USER_UPDATING: string = 'user/userUpdating';
export const USER_UPDATED: string = 'user/userUpdated';
export const FAILED_TO_UPDATE_USER: string = 'user/failedToUpdateUser';
export const USER_DELETING: string = 'user/userDeleting';
export const USER_DELETED: string = 'user/userDeleted';
export const FAILED_TO_DELETE_USER: string = 'user/failedToDeleteUser';
export const DELETING_FAILED_USER_LOGIN_ATTEMPTS: string =
  'user/deletingFailedUserLoginAttempts';
export const DELETED_FAILED_USER_LOGIN_ATTEMPTS: string =
  'user/deletedFailedUserLoginAttempts';
export const FAILED_TO_DELETE_USER_LOGIN_ATTEMPTS: string =
  'user/failedToDeleteUserLoginAttempts';
export const END_USER_LOADING: string = 'user/endUserLoading';
export const END_USER_LOADED: string = 'user/endUserLoaded';
export const FAILED_TO_LOAD_END_USER: string = 'user/failedToLoadEndUser';

interface UserState {
  isLoading: boolean,
  isLoaded: boolean,
  isSaved: boolean,
  isProcessing: boolean,
  user: {
    id: string,
    active: boolean,
    isLocked: boolean,
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    confirmEmail: string,
    handles: any[],
    password: string,
    confirmPassword: string,
    roles: string[],
    sendWelcomeEmail: boolean,
  },
  errors: { alreadyTaken: any[] },
}

// Init
export const INITIAL_STATE: UserState = {
  isLoading: false,
  isLoaded: false,
  isSaved: false,
  isProcessing: false,
  user: {
    id: '',
    active: true,
    isLocked: false,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    handles: [],
    password: '',
    confirmPassword: '',
    roles: [],
    sendWelcomeEmail: true,
  },
  errors: { alreadyTaken: [] },
};

// helpers
export function populateUserData(user: any) {
  const modifieduser = {
    ...user,
    confirmEmail: user.email,
    handles: user.handles || {},
  };

  // Filter out the depreceated roles
  modifieduser.roles = user.roles.filter((role: string) => RoleTypes[role]);
  modifieduser.roles = prioritizeRoles(modifieduser.roles);

  // Filter out the already saved invalid email addresses
  const validHandle = ({ handle } : any) => isValidEmail(handle || '');

  modifieduser.handles = modifieduser.handles
    .filter(validHandle)
    .map((handle: any) => ({ ...handle, confirmHandle: handle.handle }));

  return modifieduser;
}

// Actions
export function loadUser(id: string) {
  return function* doLoadUser() {
    yield { type: USER_LOADING };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.usersShow, {
        urlParams: { id },
      });

      yield {
        type: USER_LOADED,
        payload: { user: populateUserData(result.data) },
      };
    } catch (e) {
      console.error(e);

      yield { type: FAILED_TO_LOAD_USER };
    }
  };
}

export function createUser(userData: any) {
  return function* doCreateUser() {
    yield { type: USER_CREATING, payload: { user: userData } };

    try {
      yield apiReqPromise(jsApi.usersCreate, {
        params: { data: { user: userData } },
      });

      yield { type: USER_CREATED };

      jsUi.usersIndex.goTo();

      reduxStore.dispatch(
        showSuccessFlash(`${BASE_FLASH}.create`, VALID_FLASH_VIEWS),
      );
    } catch (e) {
      console.error(e);

      yield {
        type: FAILED_TO_CREATE_USER,
        payload: { alreadyTaken: e.response.data.alreadyTaken },
      };
    }
  };
}

export function updateUser(id: string, requestData: any) {
  return function* doUpdateUser() {
    yield { type: USER_UPDATING };

    try {
      yield apiReqPromise(jsApi.usersUpdate, {
        urlParams: { id },
        params: { data: { user: requestData } },
      });

      yield { type: USER_UPDATED };

      if (isActivePathRole(PAGES.USER_MANAGEMENT)) {
        jsUi.usersIndex.goTo();
      } else {
        goToHomePage();
      }

      reduxStore.dispatch(
        showSuccessFlash(`${BASE_FLASH}.update`, [
          PAGES.DASHBOARD,
          PAGES.USER_MANAGEMENT,
          PAGES.SEARCHES,
          PAGES.MY_ARCHIVE_SPLIT_VIEW,
        ]),
      );
    } catch (e) {
      console.error(e);

      yield {
        type: FAILED_TO_UPDATE_USER,
        payload: { alreadyTaken: e.response.data.alreadyTaken },
      };
    }
  };
}

export function deleteUser(id: string, externalId: string) {
  return function* doDeleteUser() {
    yield { type: USER_DELETING };

    try {
      yield apiReqPromise(jsApi.usersDestroy, {
        urlParams: { id, 'special-access': externalId },
      });

      yield { type: USER_DELETED };

      jsUi.usersIndex.goTo();
      reduxStore.dispatch(
        showSuccessFlash(`${BASE_FLASH}.delete`, VALID_FLASH_VIEWS),
      );
    } catch (e) {
      console.error(e);

      yield { type: FAILED_TO_DELETE_USER };
    }
  };
}

export function deleteFailedUserLoginAttempts(id: string) {
  return function* doDeleteFailedUserLoginAttempts() {
    yield { type: DELETING_FAILED_USER_LOGIN_ATTEMPTS };

    try {
      yield apiReqPromise(jsApi.usersDestroyFailedLoginAttempts, {
        urlParams: { id },
      });

      yield { type: DELETED_FAILED_USER_LOGIN_ATTEMPTS };

      jsUi.usersIndex.goTo();
    } catch (e) {
      console.error(e);

      yield { type: FAILED_TO_DELETE_USER_LOGIN_ATTEMPTS };
      jsUi.usersIndex.goTo();
    }
  };
}

export function loadEndUser(id: string) {
  return function* doLoadEndUser() {
    yield { type: END_USER_LOADING };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.usersShow, {
        urlParams: { id },
      });

      yield {
        type: END_USER_LOADED,
        payload: { user: populateUserData(result.data) },
      };
    } catch (e) {
      console.error(e);

      yield {
        type: FAILED_TO_LOAD_END_USER,
        payload: { alreadyTaken: e.data.alreadyTaken },
      };
    }
  };
}

export function resetUserErrors() {
  return { type: RESET_ERRORS };
}

export function resetUser() {
  return { type: RESET };
}

// Store
export default function UserStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case USER_LOADING:
    case END_USER_LOADING:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        user: INITIAL_STATE.user,
      };
    case USER_CREATING:
      return {
        ...state,
        user: {
          ...INITIAL_STATE.user,
          ...payload.user,
          confirmPassword: payload.user.password,
          confirmEmail: payload.user.email,
        },
        isProcessing: true,
        isSaved: false,
      };
    case USER_UPDATING:
    case USER_DELETING:
    case DELETING_FAILED_USER_LOGIN_ATTEMPTS:
      return { ...state, isProcessing: true };
    case USER_LOADED:
    case END_USER_LOADED:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        user: {
          ...INITIAL_STATE.user,
          ...payload.user,
        },
      };
    case USER_CREATED:
      return {
        ...state,
        isProcessing: false,
        isSaved: true,
        user: { ...INITIAL_STATE.user },
      };
    case USER_UPDATED:
    case USER_DELETED:
    case DELETED_FAILED_USER_LOGIN_ATTEMPTS:
      return { ...state, isProcessing: false };
    case FAILED_TO_LOAD_USER:
    case FAILED_TO_LOAD_END_USER:
      return {
        ...state,
        isLoading: false,
      };
    case FAILED_TO_UPDATE_USER:
    case FAILED_TO_CREATE_USER:
      return {
        ...state,
        isProcessing: false,
        errors: {
          ...state.errors,
          alreadyTaken: payload.alreadyTaken,
        },
      };
    case FAILED_TO_DELETE_USER:
    case FAILED_TO_DELETE_USER_LOGIN_ATTEMPTS:
      return {
        ...state,
        isProcessing: false,
      };
    case RESET_ERRORS:
      return { ...state, errors: INITIAL_STATE.errors };
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
