import moment from 'moment';

import {
  formatUtcDate,
  formatTZDate,
  DATE_FORMATS,
} from 'component-utilities/dates/dates';

import reduxStore from '../reduxStore';
import jsApi from '../lib/jsApi';
import { jsUi } from '../lib/jsUi';
import { apiReqPromise } from '../lib/apiRequest';
import createDownloadForJob from '../lib/createDownloadForJob';

import domainInfo from '../lib/domainInfo';

import {
  showSuccessFlash,
  showFailureFlash,
} from './FlashMessagesStateManager';
import { getLoggedInAs } from './AuthenticationStateManager';
import { PAGES } from '../components/FlashMessages';
import IfFeature from '../components/IfFeature';

import generator from './JobsStateManagerGenerator';

import appConfig from '../../../../../config/app.json';
import configs from '../../config/configs';
import { Job } from 'global/types';


const { AUDITING } = configs;

export const areCategoryAuditsEnabled = () => IfFeature.isEnabled('category_audit');

export function processAuditJob(job: Job) {
  // This is a new job
  if ('request' in job) {
    const from = formatUtcDate(job.request.fromDate);
    const to = formatUtcDate(job.request.toDate);

    return {
      ...job,
      jobName: job.request.title,
      files: [{ 'file-size': job.size }],
      requester: job.request.username,
      requestedDate: formatUtcDate(job.request.timestamp),
      requestedDateForSort: moment(job.request.timestamp),
      dateRange: `${from} - ${to}`,
    };
  }

  const startDate = formatUtcDate(job.metadata.dateFrom);
  const endDate = formatUtcDate(job.metadata.dateTo);

  return {
    ...job,
    id: job.jobId,
    requester: job.metadata.requestingUser,
    requestedDate: formatTZDate(job.createdAt),
    startDate: job.metadata.dateFrom,
    requestedDateForSort: moment(job.createdAt),
    dateRange: `${startDate} - ${endDate}`,
  };
}

const { constants, actions, reducer } = generator({
  name: 'audits',
  types: [appConfig.JOBS.OLD_AUDIT_REPORT.TYPE, appConfig.JOBS.CATEGORY_AUDIT.TYPE].filter(
    type => type !== appConfig.JOBS.CATEGORY_AUDIT.TYPE || areCategoryAuditsEnabled()),
  processJob: processAuditJob,
});

const VALID_FLASH_VIEWS = [PAGES.REPORTS];

const { IS_PROCESSING, PROCESSING_SUCCESS, PROCESSING_FAILED } = constants;

// helper functions

function formatDSLDate(date: string, method: string) {
  const { DSL } = DATE_FORMATS;

  return moment(date)[method]('day').format(DSL);
}

export function createNewAuditReportData(formData: any) {
  const from = formatDSLDate(formData.dateRange.from, 'startOf');
  const to = formatDSLDate(formData.dateRange.to, 'endOf');

  if (areCategoryAuditsEnabled()) {
    // TODO: Build in notifications somewhere...
    return {
      title: formData.name,
      namespaces: AUDITING.CATEGORIES.filter(category => formData[category]),
      'from-date': from,
      'to-date': to,
    };
  }

  return {
    notifier: formData.notifier,
    'date-from': from,
    'date-to': to,
    'report-type': formData.reportType,
    'report-format': `${formData.fileFormat.replace('.', '')}-zip`,
  };
}

// action creators

export const download = createDownloadForJob;
export const allSuccess = actions.allSuccess;
export const someSuccess = actions.someSuccess;
export const allImmediately = actions.allImmediately;
export const allWhenOptimal = actions.allWhenOptimal;

export function createAuditReport(data: {}) {
  return function* doCreateAuditReport() {
    yield { type: IS_PROCESSING };

    try {
      // getState from the generator goes here in the future
      const { siteName, accountName } = domainInfo();

      yield apiReqPromise(jsApi.auditsCreate, {
        params: {
          data: {
            values: createNewAuditReportData({
              ...data,
              notifier: {
                account: accountName,
                'site-name': siteName,
                to: getLoggedInAs().email,
              },
            }),
          },
        },
      });

      yield {
        type: PROCESSING_SUCCESS,
      };

      reduxStore.dispatch(showSuccessFlash('audits.create', VALID_FLASH_VIEWS));
    } catch (e) {
      console.error(e);

      yield {
        type: PROCESSING_FAILED,
        payload: e,
      };

      reduxStore.dispatch(showFailureFlash('audits.create', VALID_FLASH_VIEWS));
    }

    jsUi.settingsReportsIndex.goTo();
  };
}

const AuditsStateManager = reducer;
export default AuditsStateManager;
