/* eslint-disable filenames/match-regex */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TAGS, HOLDS } from '../../data/Criteria';

import collectHighlightKeywords from '../../global/lib/collectHighlightKeywords';
import { QUERY_TYPES } from '../../global/lib/generateFilteredQuery';
import { openUnsavedSearchStream } from '../../global/lib/searchResultsStreamManager';
import { setUIValues } from '../../global/state_managers/UIHelperStateManager';

type Props = {
  results: any[],
  searchResults: any,
  params: any,
  getMessage: Function,
  resetFilter: Function,
  loadEndUser: Function,
  resetUser: Function,
  loggedInAsUserId: number,
  loadSplitViewAsset: Function,
  resetUnsavedSearch: Function,
  setBreadcrumbForMyArchivePage: Function,
  initTableConfig: Function,
  resetStream: Function,
  loadSavedSearches: Function,
  loadRetentionPolicy: Function,
  setSelectedSearchId: Function,
  setSelectedFixedFilter: Function,
  actionsConfig: any,
  asset: any,
  emptyResultsCanRender: Boolean,
  tableActions: any,
}

type State = {
  isResultsLoaded: Boolean,
  emptyResultsCanRender: Boolean,
  initSearchRun: Boolean,
  initAssetLoaded: Boolean,
}

export function viewLogic(props: Props) {
  const {
    params,
    searchResults,
    actionsConfig,
    asset,
    emptyResultsCanRender,
    tableActions,
  } = props;

  const {
    selectedSearchId,
    selectedFixedFilter,
  } = searchResults;
  function tableActionsProps() {
    return {
      actions: [tableActions.SORT, tableActions.FILTER],
      actionsProps: {
        params,
        searchResults,
        hiddenFilterCriteria: [TAGS, HOLDS],
        canResetForm: true,
        filter: {
          type: selectedFixedFilter.length ? QUERY_TYPES.UNSAVED_MY_ARCHIVE : QUERY_TYPES.MY_ARCHIVE,
          selectedSearchId,
          selectedFixedFilter,
        },
      },
      actionsConfig,
    };
  }

  function assetActionsProps() {
    return {
      actions: [tableActions.SEND_EMAIL, tableActions.DOWNLOAD],
      actionsProps: {
        asset,
      },
    };
  }

  function shouldRenderEmptyView() {
    return emptyResultsCanRender;
  }

  return {
    ...props,
    tableActionsProps,
    assetActionsProps,
    shouldRenderEmptyView: shouldRenderEmptyView(),
  };
}

export function stateLogic({
  sortSearchResults,
  goToMyArchiveSplitViewIndex,
  goToMyArchiveSplitViewAsset,
  rowStatuses,
  tableActions,
}: any) {
  return function SplitViewStateLogicComposer(component: any) {
    class SplitViewStateLogic extends Component<Props, State> {
      static propTypes = {
        results: PropTypes.array.isRequired,
        searchResults: PropTypes.object.isRequired,
        params: PropTypes.object.isRequired,
        getMessage: PropTypes.func.isRequired,
        resetFilter: PropTypes.func.isRequired,
        loadEndUser: PropTypes.func.isRequired,
        resetUser: PropTypes.func.isRequired,
        loggedInAsUserId: PropTypes.number.isRequired,
        loadSplitViewAsset: PropTypes.func.isRequired,
        resetUnsavedSearch: PropTypes.func.isRequired,
        setBreadcrumbForMyArchivePage: PropTypes.func.isRequired,
        initTableConfig: PropTypes.func.isRequired,
        resetStream: PropTypes.func.isRequired,
        loadSavedSearches: PropTypes.func.isRequired,
        loadRetentionPolicy: PropTypes.func.isRequired,
        setSelectedSearchId: PropTypes.func.isRequired,
        setSelectedFixedFilter: PropTypes.func.isRequired,
      };

      static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const updatedState: any = {};

        if (nextProps.results.length && !!prevState) {
          if (prevState.emptyResultsCanRender) updatedState.emptyResultsCanRender = false;
        }

        if (nextProps.searchResults.isLoaded && !!prevState) {
          if (!prevState.isResultsLoaded) updatedState.isResultsLoaded = true;
        }

        return updatedState;
      }

      constructor(props: Props) {
        super(props);
        this.props.resetFilter();
        this.props.loadRetentionPolicy();
        this.state = {
          isResultsLoaded: false,
          emptyResultsCanRender: true,
          initSearchRun: false,
          initAssetLoaded: false,
        };

        setUIValues({ personalArchive: true });
        props.resetUnsavedSearch();
        props.initTableConfig('splitView', true);
        props.loadEndUser(this.props.loggedInAsUserId);
        props.setBreadcrumbForMyArchivePage();
        openUnsavedSearchStream({ type: QUERY_TYPES.MY_ARCHIVE, fixedFilter: props.searchResults.selectedFixedFilter });
        props.loadSavedSearches(0, true);
      }

      componentDidUpdate(prevProps: Props) {
        const { results: newResults } = this.props;
        const { results } = prevProps;

        if (!results.length && !!newResults.length) {
          if (!this.assetId) {
            const { id: firstAssetId } = newResults[0];
            this.goToAsset(firstAssetId);
          } else {
            this.props.loadSplitViewAsset(this.assetId, newResults);
          }
        } else if (!!results.length && !newResults.length) {
          this.resetView();
        }

        const searchStatus = () => {
          if (!!this.props.searchResults.length && !this.state.initAssetLoaded) {
            const { searchResults } = this.props;
            this.setState({ initAssetLoaded: true });
            this.goToAsset(searchResults.results[0].id);
          }
        };

        searchStatus();
      }

      componentWillUnmount() {
        this.props.resetUser();
        this.props.resetStream();
      }

      get assetId() {
        return this.props.params.asset_id;
      }

      get assetDetails() {
        return (
          this.props.searchResults.results.find(
            (result: any) => result && result.id === this.assetId,
          ) || {}
        );
      }

      get actionsConfig() {
        const { searchResults, getMessage } = this.props;
        const {
          SUBJECT,
          ATTACHMENT,
          FROM,
          DATE,
        } = searchResults.tableHelper.columns;

        return {
          sort: {
            sortOptions: searchResults.sortOptions,
            sortAction: (sortKey: string, sortOrder: string) => {
              sortSearchResults(sortKey, sortOrder);
              this.resetView();
            },
            sortList: [SUBJECT, ATTACHMENT, FROM, DATE].map(column => ({
              id: column,
              value: getMessage(`app.assets.columns.${column}`),
            })),
            sortKeys: searchResults.tableHelper.tableSortKeys,
          },
        };
      }

      setInitSearchRun = () => {
        this.setState({ initSearchRun: !this.state.initSearchRun });
      }

      resetView = () => {
        $('.infinite-scroll-inner').scrollTop(0);
        goToMyArchiveSplitViewIndex();
      };

      goToAsset = (id: number) => {
        this.props.loadSplitViewAsset(id, this.props.results);
        goToMyArchiveSplitViewAsset({ asset_id: id });
      };

      render() {
        return React.createElement(component, {
          ...this.props,
          ...this.state,
          rowStatuses,
          tableActions,
          isResultsLoaded: this.state.isResultsLoaded,
          actionsConfig: this.actionsConfig,
          resetView: this.resetView,
          goToAsset: this.goToAsset,
          assetId: this.assetId,
          highlightKeywords: collectHighlightKeywords(),
          emptyResultsCanRender: this.state.emptyResultsCanRender,
          selectedAsset: this.assetDetails,
          selectedSearchId: this.props.searchResults.selectedSearchId,
          setSelectedSearchId: this.props.setSelectedSearchId,
          selectedFixedFilter: this.props.searchResults.selectedFixedFilter,
          setSelectedFixedFilter: this.props.setSelectedFixedFilter,
          setInitSearchRun: this.setInitSearchRun,
        });
      }
    }

    return SplitViewStateLogic;
  };
}
