import moment from 'moment';

import { contains } from 'lodash';

import jsApi from '../../lib/jsApi';
import { jsUi } from '../../lib/jsUi';
import { apiReqPromise } from '../../lib/apiRequest';
import { PAGES } from '../../components/FlashMessages';
import configs from '../../../config/configs';

import reduxStore, { PromiseObject } from '../../reduxStore';

import { isLoggedIn, goToHomePage } from '../AuthenticationStateManager';
import { showFailureFlash, isFlashVisible } from '../FlashMessagesStateManager';

import {
  searchResultsState,
  newResultsLoaded,
  OPEN_SEARCH_RESULTS_STREAM_START,
  OPEN_SEARCH_RESULTS_STREAM_FINISHED,
  OPEN_SEARCH_RESULTS_STREAM_FAILED,
  CLOSE_SEARCH_RESULTS_STREAM,
  ADD_DELAYED_STREAM_ID,
  REMOVE_DELAYED_STREAM_ID,
  RESET_STREAM_QUERY,
  RESET_STREAM,
  BASE_FLASH,
  VALID_FLASH_VIEWS,
  UPDATE_ALREADY_REQUESTED_PACKS,
  PACK_LIMIT,
} from '../SearchResultsStateManager';

const { JOB_STATUSES } = configs;

export const LOADING_DELAY = 5000;

let startTime : any;

// helpers
export function isIdSpecifiedBatchRunning(id: string) {
  return contains(searchResultsState().sampleSearchRunning, id);
}

export function doOpenSearchResultsStreamFn(params: any) {
  return apiReqPromise(jsApi.searchResultsStreamsCreate, {
    params: { data: { ...params } },
  });
}

function promiseTimeout(time: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export function stopMonitoringBatch(id: string) {
  return { type: REMOVE_DELAYED_STREAM_ID, payload: { id } };
}

export function calculateLoadingTime(endTime = moment()) {
  const duration = moment.duration(endTime.diff(startTime));
  return duration.asSeconds();
}

export function failedToOpenSearchResultsStream(e: any, streamId: string) {
  if (e.status === 403) {
    goToHomePage();
  } else if (!isFlashVisible()(`${BASE_FLASH}.load.failure`)) {
    if (streamId) {
      jsUi.searchesIndex.goTo();
      reduxStore.dispatch(
        showFailureFlash(`${BASE_FLASH}.open`, VALID_FLASH_VIEWS),
      );
    } else {
      jsUi.newSearchEdit.goTo();
      reduxStore.dispatch(
        showFailureFlash('unsaved_search.create', [PAGES.NEW_SEARCH_EDIT]),
      );
    }
  }
}

// actions
// if a search is being randomize, we need to check for a status
// this function will check the status and poll sawdust every 5 seconds
export function checkDelayedSearchResultData({ data, params, streamId }: any) {
  return function* doCheckDelayedSearchResultData() {
    switch (data.state) {
      case JOB_STATUSES.PENDING:
      case JOB_STATUSES.PREPARING:
      case JOB_STATUSES.STARTED: {
        yield promiseTimeout(LOADING_DELAY);

        if (isLoggedIn() && isIdSpecifiedBatchRunning(streamId)) {
          try {
            const result: PromiseObject = yield doOpenSearchResultsStreamFn(params);

            reduxStore.dispatch(
              checkDelayedSearchResultData({
                data: result.data,
                params,
                streamId,
              }),
            );
          } catch (e) {
            console.error(e);

            failedToOpenSearchResultsStream(e, streamId);

            yield { type: OPEN_SEARCH_RESULTS_STREAM_FAILED };
          }
        } else {
          yield { type: REMOVE_DELAYED_STREAM_ID, payload: { id: streamId } };
        }
        break;
      }
      case JOB_STATUSES.COMPLETE_WITH_ERRORS: {
        if (isIdSpecifiedBatchRunning(streamId)) {
          yield { type: REMOVE_DELAYED_STREAM_ID, payload: { id: streamId } };

          jsUi.searchesShow.goTo({ search_id: streamId, create_failure: true });
        }

        break;
      }
      default: {
        // done!
        if (isIdSpecifiedBatchRunning(streamId)) {
          yield { type: REMOVE_DELAYED_STREAM_ID, payload: { id: streamId } };

          yield newResultsLoaded(0, data);

          yield {
            type: OPEN_SEARCH_RESULTS_STREAM_FINISHED,
            payload: {
              stream: data,
              loadingTime: calculateLoadingTime(),
            },
          };
        }
      }
    }
  };
}

export function openSearchResultsStream(params: any) {
  return function* doOpenSearchResultsStream() {
    startTime = moment();
    const streamId = params.stream_params['query-id'];
    const fixedFilter = params.fixed_filter;
    const searchId = params.search_id;
    yield { type: OPEN_SEARCH_RESULTS_STREAM_START };
    yield {
      type: UPDATE_ALREADY_REQUESTED_PACKS,
      payload: { alreadyRequestedPacks: [[0, PACK_LIMIT]] },
    };

    try {
      const result: PromiseObject = yield doOpenSearchResultsStreamFn(params);
      const { data } = result;

      if (streamId && data.state) {
        yield { type: ADD_DELAYED_STREAM_ID, payload: { id: streamId } };

        reduxStore.dispatch(
          checkDelayedSearchResultData({
            data,
            params,
            streamId,
          }),
        );
      } else {
        yield newResultsLoaded(0, data, fixedFilter, searchId);

        yield {
          type: OPEN_SEARCH_RESULTS_STREAM_FINISHED,
          payload: {
            stream: data,
            loadingTime: calculateLoadingTime(),
          },
        };
      }
    } catch (e) {
      console.error(e);

      failedToOpenSearchResultsStream(e, streamId);

      yield { type: OPEN_SEARCH_RESULTS_STREAM_FAILED };
    }
  };
}

export function resetStreamQuery() {
  return { type: RESET_STREAM_QUERY };
}

export function closeSearchResultsStream() {
  return function* doCloseSearchResultsStream() {
    const { stream } = searchResultsState();

    if (stream.id) {
      yield { type: CLOSE_SEARCH_RESULTS_STREAM };

      try {
        yield apiReqPromise(jsApi.searchResultsStreamsDestroy, {
          urlParams: { search_results_stream_id: stream.id },
        });
      } catch (e) {
        console.error(e);
      }
    }
  };
}

export function resetStream() {
  return function* doResetStream() {
    yield closeSearchResultsStream();

    yield { type: RESET_STREAM };
  };
}