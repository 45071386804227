import React from 'react';
import PropTypes from 'prop-types';

import { FormattedHTMLMessage } from 'component-utilities/intl';

import { ModalHeader } from '../../Modals';
import { Props } from './Buttons';

interface HeaderProps extends Props {
  type: string
  userCount: number
}

export default function Header({
  BASE_KEY,
  hasErrors,
  type,
  hideModal,
  userCount,
}: HeaderProps) {
  return (
    <ModalHeader type={hasErrors ? 'warning' : type} onCloseClick={hideModal}>
      <FormattedHTMLMessage
        id={`${BASE_KEY}.header.${hasErrors ? 'error' : 'ok'}`}
        values={{ count: userCount }}
      />
    </ModalHeader>
  );
}

Header.propTypes = {
  BASE_KEY: PropTypes.string.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  userCount: PropTypes.number.isRequired,
};

Header.displayName = 'Modals/BulkToggleUsers/Header';
