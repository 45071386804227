import { contains } from 'lodash';
import getLocalizedData from '../../../lib/getLocalizedData';

import MultiSelectTypeCriteriaComposer from './Components/MultiSelectTypeCriteriaComposer';

type Props = {
  criteriasVisibilities: {
    isRelationVisible: boolean
  }
  listElem: {
    values: {
      selectedCriteria: string
    }
  },
  form: {}
  criteriaValues: {}
  updateBaseState: Function
  getMessage: Function
  setInitialFormAndOriginalValues: Function
  registerCalculateVisibleCriteriaNum: Function
  registerIsEmptyCriteria: Function
  registerRenderHeaderText: Function
  registerIsApplyButtonDisabled: Function

}

const KeywordsCriteriaView = MultiSelectTypeCriteriaComposer({
  init: function init(props: Props) {
    const { listElem: { values }, form } = props;

    const criteriaValues = getLocalizedData.apply(this, [
      'advKeywordsValuesSelections',
    ]);

    this.inTheText = props.getMessage('app.criterias.in_the').toLowerCase();

    props.updateBaseState({
      criteriasVisibilities: {
        ...props.criteriasVisibilities,
        isRelationVisible: false,
      },
      criteriaValues,
    });

    props.setInitialFormAndOriginalValues(
      values.selectedCriteria
        ? values
        : {
          ...values,
          ...form,
          selectedCriteria: criteriaValues.map(({ id }: any) => id),
          list: [''],
          relation: 'or',
        },
    );

    this.state = {
      focusedIndex: 0,
      isCriteriaDropdownVisible: props.isCriteriaDropdownVisible,
      visibleCriteria: 0,
    };
    props.registerCalculateVisibleCriteriaNum(this.calculateVisibleCriteriaNum);
    props.registerIsEmptyCriteria(this.isEmptyCriteria);
    props.registerRenderHeaderText(this.renderHeaderText);
    props.registerIsApplyButtonDisabled(this.isApplyButtonDisabled);
  },
  instances: {
    getHeaderCalculationObject: function getHeaderCalculationObject(
      formValues,
    ) {
      const { selectedCriteria = [], list = [''] } = formValues;
      const criteriaValues = this.props.criteriaValues
        .filter(({ id }: any) => contains(selectedCriteria, id))
        .map(({ value }: any) => value)
        .join(', ');
      const filteredList = list.filter(listElem => listElem.length);
      const preText: string = `${this.inTheText} ${criteriaValues}`;

      return {
        formValues,
        list: filteredList,
        preText,
        visibleCriteria: this.state.visibleCriteria,
      };
    },
    calculateVisibleCriteriaNum: function calculateVisibleCriteriaNum(
      formValues = this.props.getOriginalFormValues(),
    ) {
      const visibleCriteria = this.props.calculateVisibleCriteriaNumHelper(
        this.getHeaderCalculationObject(formValues),
      );

      if (visibleCriteria) {
        this.setState({ visibleCriteria });
      }
    },
    renderHeaderText: function renderHeaderText() {
      const formValues = this.props.listElem.values;
      const { list } = formValues;
      const headerCalculationObject = this.getHeaderCalculationObject(
        formValues,
      );
      headerCalculationObject.preText = this.props.renderGreenText(
        headerCalculationObject.preText,
      );

      if (this.isListValid(list) && !this.isEmptyCriteria()) {
        return this.props.renderHeaderTextHelper(headerCalculationObject);
      }

      return null;
    },
  },
});

export default KeywordsCriteriaView;
