/* eslint-disable no-undef */
import React from 'react';

import { contains } from 'lodash';
import {
  DATE_FORMATS,
} from 'component-utilities/dates/dates';

import LoadingIndicator from 'view-components/components/LoadingIndicator';

import {
  TableRow,
  SortCell,
  DataCell,
  UserCell,
  TextCell,
} from 'view-components/components/TableView';
import JobStatusCell from 'view-components/components/TableView/JobStatusCell';

import { TableViewContentReduxTransitional } from '../../global/components/TableView/TableViewContentFactory';

import {
  COLUMNS, ALL_COLUMNS, DISABLED_COLUMNS,
} from '../../global/state_managers/ExportTableUIStateManager';

import configs from '../../config/configs';

const { sortKeys: { UNSORTED } } = SortCell;
const { rowStatuses: { SELECTED } } = TableRow;

const {
  EXPORT_STATUS,
  EXPORT_NAME,
  REQUESTED_BY,
  EXPIRES_ON,
  RESULTS,
  FORMAT,
} = COLUMNS;

export default class TableView extends TableViewContentReduxTransitional {
  static displayName = 'Exports/TableView';

  static defaultProps = {
    tableType: 'exports',
  };

  get tableHelper() {
    return {
      allColumns: ALL_COLUMNS,
      disabledColumns: DISABLED_COLUMNS,
      activeColumns: this.props.visibleColumns,
    };
  }

  get headerContent() {
    const {
      sortKey,
      sortOrder,
    } = this.props;

    return this.props.visibleColumns.map((cellId: string, idx: number) => {
      let newSortKey = UNSORTED;
      if (sortKey === cellId) {
        newSortKey = sortOrder;
      }

      const column = {
        onClick: this.onHeaderClick,
        sortKey: newSortKey,
        key: idx,
        text: this.localizedColumnNames[cellId],
        cellId,
      };

      return <SortCell {...column} />;
    });
  }

  get bodyContent() {
    const { jobs } = this.props;
    const { selectedRows } = this.state;

    return jobs.map((job: any, rowIdx: number) => {
      // Skip jobs without the necessary metadata
      if (!job.metadata || !job.metadata.format) {
        return null;
      }

      const {
        jobId: id,
        exportName,
        requester,
        metadata: { format },
        status,
        expiresOn,
      } = job;

      const isChecked = contains(selectedRows, id);
      const rowStatus = isChecked ? SELECTED : null;

      const visibleColumns = this.props.visibleColumns.map((cellId: string) => {
        switch (cellId) {
          case EXPORT_STATUS: {
            return (
              <DataCell
                key={cellId}
                cellId={cellId}
                data-job-status={status}
              >
                <JobStatusCell
                  {...job}
                  id={id}
                  type="export"
                  downloadAction={this.props.download}
                  finishedStates={configs.JOB_STATUSES.FINISHED}
                  prefix="app"
                />
              </DataCell>
            );
          }
          case EXPORT_NAME: {
            return (
              <TextCell
                key={cellId}
                title={exportName}
                cellId={cellId}
                isFloatingCell
              >
                {this.highlightText(exportName)}
              </TextCell>
            );
          }
          case REQUESTED_BY: {
            const requesterCellContent = () => {
              if (requester) {
                return (
                  <UserCell
                    key={cellId}
                    cellId={cellId}
                    userName={this.highlightText(requester)}
                  />
                );
              }

              return (
                <DataCell
                  key={cellId}
                  cellId={cellId}
                >
                  <div className="loading-cell">
                    <LoadingIndicator orientation="left" text="" isLoading />
                  </div>
                </DataCell>
              );
            };

            return requesterCellContent();
          }
          case EXPIRES_ON: {
            return (
              <TextCell
                key={cellId}
                cellId={cellId}
              >
                {expiresOn.format(DATE_FORMATS.LONG)}
              </TextCell>
            );
          }
          case RESULTS: {
            return (
              <TextCell
                key={cellId}
                cellId={cellId}
              >
                {job.itemCount || 'n/a'}
              </TextCell>
            );
          }
          case FORMAT: {
            return (
              <TextCell
                key={cellId}
                cellId={cellId}
              >
                {this.highlightText(`.${format.replace('-zip', '')}`.toUpperCase())}
              </TextCell>
            );
          }
          default:
            return null;
        }
      });

      return (
        <TableRow
          key={id}
          index={rowIdx}
          status={rowStatus}
          rowId={id}
        >
          { visibleColumns }
        </TableRow>
      );
    });
  }
}
