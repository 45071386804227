/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LowerSectionFormText } from 'v-c/Dialog/Components';
import CheckboxContainer from './CheckboxContainer';

type Props = {
  toggleConfirmed: any
  isConfirmed: boolean
  message: string
}

type State = {
  forceHover: boolean
}

export default class DeleteConfirmationForm extends Component<Props, State> {
  static propTypes = {
    toggleConfirmed: PropTypes.func.isRequired,
    isConfirmed: PropTypes.bool.isRequired,
    message: PropTypes.node.isRequired,
  };

  constructor(props: Props) {
    super(props);

    this.toggleConfirmed = props.toggleConfirmed.bind(this);
    this.state = { forceHover: false };
  }

  setHover = (newState: boolean) => () => {
    this.setState({ forceHover: newState });
  };

  render() {
    const { forceHover } = this.state;
    const { isConfirmed, message } = this.props;

    return (
      <LowerSectionFormText>
        <div
          onMouseOver={this.setHover(true)}
          onMouseOut={this.setHover(false)}
          onClick={this.toggleConfirmed}
          data-field="confirm_deletion"
          role="checkbox"
          tabIndex="-1"
          aria-checked={isConfirmed}
        >
          <CheckboxContainer forceHover={forceHover} isConfirmed={isConfirmed}>
            {message}
          </CheckboxContainer>
        </div>
      </LowerSectionFormText>
    );
  }
}
