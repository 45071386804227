import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

// use this with standalone TableViews
export default function StandaloneContainer({ children }: { children: ReactNode }) {
  return (
    <div className="table-control-group-standalone">
      <div className="table-control-group-inner">
        {children}
      </div>
    </div>
  );
}

StandaloneContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
