import React, { Component } from 'react';

export default function ConfirmableDialog(WrappedComponent: any) {
  class ConfirmableDialogClass extends Component<any, { isConfirmed: boolean }> {
    constructor(props: any) {
      super(props);

      this.state = { isConfirmed: false };
    }

    toggleConfirmed = () => {
      this.setState({ isConfirmed: !this.state.isConfirmed });
    }

    render() {
      return (
        <WrappedComponent
          isConfirmed={this.state.isConfirmed}
          toggleConfirmed={this.toggleConfirmed}
          {...this.props}
        />
      );
    }
  }

  return ConfirmableDialogClass;
}
