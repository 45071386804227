/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { kebabCase, isEqual } from 'lodash';
import { Link, browserHistory } from 'react-router';
import withMessages from 'component-utilities/intl';

import RetinaImage from 'view-components/components/RetinaImage';
import { TextLabel } from 'view-components/components/Widgets/Components';

import {
  TableView as TableViewComponent,
  TableHeader,
  TableRow,
  SortCell,
  DataCell,
  UserCell,
  TextCell,
  AttachmentCell,
  HoldReasonsCell,
  CheckboxCell,
  CommentCell,
} from 'v-c/TableView';
import { InfiniteScrollTable } from 'v-c/TableView/InfiniteScroll';
import {
  VerticalScrollbar,
  ScrollbarFactory,
  ScrollbarTableMixin,
} from 'v-c/VerticalScrollbar';
import { EyeRedIcon, CircleGrayWithCheckmarkIcon } from 'v-c/Icons';

import attachmentGrayIcon from 'v-c/TableView/images/attachments-gray.png';
import attachmentActiveIcon from 'v-c/TableView/images/attachments-blue.png';
import commentsNormalIcon from '../../TableViewActions/images/comments-normal.png';
import commentsActiveIcon from '../../TableViewActions/images/comments-active.png';

import { sortStream } from '../../../lib/searchResultsStreamManager';
import contextTreeHelper from '../../../lib/contextTreeHelper';

import { TableViewContent } from '../TableViewContentFactory';

import collectHighlightKeywords from '../../../lib/collectHighlightKeywords';

import { shouldShowTable } from './TableViewDeconstructed';
import highlighting from '../../../lib/highlighting';

import fixAuthorColumnValue from '../../../lib/FixAuthorColumnValue';

import openImage from '../../../../../images/general/right-blue-icon.png';

import { activeColumnsChanged } from '../../../state_managers/SearchResults/tableConfig';
import { RowValues, HighlightValues } from 'global/types';

const {
  sortKeys: { UNSORTED },
} = SortCell;
const {
  rowStatuses: { SELECTED },
} = TableRow;

type Props = {
  filteredFormValues: {}[]
}

type State = {
  highlightValues: HighlightValues
}

const InfiniteScrollTableComponent = ScrollbarTableMixin({
  displayName: 'InfiniteScrollTableComponent',
  render: (props: Props) => <InfiniteScrollTable {...props} />,
});

const mapStateToProps = null;
const mapActionsToProps = { activeColumnsChanged };

@withMessages
@connect(mapStateToProps, mapActionsToProps)
@contextTreeHelper
@ScrollbarFactory
export default class TableView extends TableViewContent {
  static propTypes = {
    activeColumnsChanged: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tableType: 'assets',
    withMultipleSelection: false,
  };

  static displayName = 'AssetsView/TableView';

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (!isEqual(prevState.filteredFormValues, nextProps.filteredFormValues)) {
      return {
        filteredFormValues: nextProps.filteredFormValues,
        highlightValues: collectHighlightKeywords(),
      };
    }

    return null;
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      highlightValues: collectHighlightKeywords(),
      filteredFormValues: {},
    };
  }

  delegateActiveColumnsChange(newColumns: string[]) {
    this.props.activeColumnsChanged(this.props.searchId, newColumns);
  }

  get tableHelper() {
    const { searchResults, searchId } = this.props;

    return searchResults.tableConfigs[searchId].tableHelper;
  }

  get sortOptions() {
    const { searchResults, searchId } = this.props;

    return searchResults.tableConfigs[searchId].sortOptions;
  }

  get list() {
    const {
      searchResults: { results },
    } = this.props;

    return results;
  }

  getSortkey(column: string) {
    return this.tableHelper.tableSortKeys[column];
  }

  get multiSelect() {
    return this.props.multiSelect || { isRowSelected: () => false };
  }

  get headerContent() {
    const {
      isRawSearch,
      searchResults: { results },
      withMultipleSelection,
    } = this.props;
    const { ATTACHMENT, REVIEW, HOLDS, OPEN_ASSET, COMMENT } = this.tableColumns;
    const { sortKey, sortOrder } = this.sortOptions;
    const isChecked = this.multiSelect.isAllSelected;
    const selectedRows = this.multiSelect.selectedRows;
    const visibleColumns = [];
    const { openedAction } = this.state;
    const isCheckboxDisabled = !!openedAction;

    const onClickAction = () => {
      if (!openedAction) {
        if (selectedRows.length && !isChecked) {
          return this.multiSelect.reset();
        }
        return this.multiSelect.onAllClick();
      }
      return null;
    };

    if (results.length) {
      if (withMultipleSelection && !isRawSearch) {
        visibleColumns.push(
          <CheckboxCell
            isChecked={isChecked}
            key={this.ALL_CHECKBOX}
            cellId="row_selector"
            onClick={onClickAction}
            type="small"
            indeterminate={!!selectedRows.length}
            isDisabled={isCheckboxDisabled}
          />,
        );
      }

      this.allColumns.filter(this.filterColumns).forEach((cellId: string) => {
        let NewSortKey = UNSORTED;
        let text = this.localizedColumnNames[cellId];
        const isSortedKey = sortKey === this.getSortkey(cellId);
        if (isSortedKey) NewSortKey = sortOrder;

        if (cellId === ATTACHMENT) {
          const image = isSortedKey ? attachmentActiveIcon : attachmentGrayIcon;
          text = <RetinaImage className="attachment-image" image={image} />;
        }

        if (cellId === COMMENT) {
          const image = isSortedKey ? commentsActiveIcon : commentsNormalIcon;
          text = <RetinaImage image={image} />;
        }

        switch (cellId) {
          case REVIEW:
          case HOLDS: {
            visibleColumns.push(
              <TextCell key={cellId} cellId={this.getSortkey(cellId)}>
                {text}
              </TextCell>,
            );
            break;
          }
          default: {
            const column = {
              cellId: this.getSortkey(cellId),
              key: cellId,
              onClick: sortStream,
              sortKey: NewSortKey,
              text,
            };

            visibleColumns.push(<SortCell {...column} />);
          }
        }
      });
    }

    visibleColumns.push(<DataCell key={OPEN_ASSET} cellId={OPEN_ASSET} />);

    return visibleColumns;
  }

  getRowContent = (values: RowValues, index: number) => {
    const {
      id,
      reasonsCount,
      commentCount,
      attachmentCount,
      subject,
      author,
      date,
      reviewStatus,
    } = values;
    const {
      SUBJECT,
      ATTACHMENT,
      FROM,
      DATE,
      HOLDS,
      REVIEW,
      OPEN_ASSET,
      COMMENT,
    } = this.tableColumns;
    const {
      subject: subjectKeywords,
      author: authorKeywords,
    } = this.state.highlightValues;

    const {
      getMessage,
      withMultipleSelection,
      goToRenderedAsset,
      isRawSearch,
    } = this.props;
    const openMessage = getMessage('app.general.open');

    const isChecked = this.multiSelect.isRowSelected(id);
    const rowStatus = isChecked ? SELECTED : null;

    const visibleColumns = [];

    if (withMultipleSelection && !isRawSearch) {
      visibleColumns.push(
        <CheckboxCell
          isChecked={isChecked}
          key="row_selector"
          cellId="row_selector"
          type="small"
          onClick={() => {
            this.props.multiSelect.onRowClick(id);
          }}
        />,
      );
    }

    this.allColumns.filter(this.filterColumns).forEach((cellId: string) => {
      switch (cellId) {
        case SUBJECT: {
          visibleColumns.push(
            <TextCell
              key={cellId}
              title={subject}
              cellId={this.getSortkey(cellId)}
              isFloatingCell
            >
              {subject}
            </TextCell>,
          );
          break;
        }
        case COMMENT: {
          visibleColumns.push(
            <CommentCell
              key={cellId}
              cellId={this.getSortkey(cellId)}
              count={commentCount}
            />,
          );
          break;
        }
        case ATTACHMENT: {
          visibleColumns.push(
            <AttachmentCell
              key={cellId}
              cellId={this.getSortkey(cellId)}
              count={attachmentCount}
            />,
          );
          break;
        }
        case FROM: {
          visibleColumns.push(
            <UserCell
              key={cellId}
              title={author}
              cellId={this.getSortkey(cellId)}
              userName={fixAuthorColumnValue(author)}
            />,
          );
          break;
        }
        case DATE: {
          visibleColumns.push(
            <TextCell key={cellId} cellId={this.getSortkey(cellId)}>
              {date}
            </TextCell>,
          );
          break;
        }
        case HOLDS: {
          visibleColumns.push(
            <HoldReasonsCell
              key={cellId}
              cellId={this.getSortkey(cellId)}
              count={reasonsCount}
            />,
          );
          break;
        }
        case REVIEW: {
          let reviewImage;

          const {
            REVIEW_STATUSES: { NEEDS_REVIEW, REVIEWED },
          } = this.props.contextTree;

          switch (reviewStatus) {
            case NEEDS_REVIEW: {
              reviewImage = <EyeRedIcon />;
              break;
            }
            case REVIEWED: {
              reviewImage = <CircleGrayWithCheckmarkIcon />;
              break;
            }
            default: {
              reviewImage = null;
            }
          }

          visibleColumns.push(
            <DataCell key={cellId} cellId={this.getSortkey(cellId)}>
              <data data-review-state={reviewStatus}>{reviewImage}</data>
            </DataCell>,
          );
          break;
        }
        default: {
          visibleColumns.push(null);
        }
      }
    });

    visibleColumns.push(
      <DataCell cellId={OPEN_ASSET} key={OPEN_ASSET}>
        <Link data-action="open" to={goToRenderedAsset(id)}>
          <TextLabel className="table-link-text open-asset">
            {openMessage}
          </TextLabel>
          <RetinaImage image={openImage} />
        </Link>
      </DataCell>,
    );

    window.setTimeout(() => {
      highlighting(
        [
          $(
            `[data-table-id="assets-table"] tbody [data-index="${index}"] [data-cell-key="subject.verbatim"] span`,
          )[0],
        ],
        subjectKeywords,
      );

      highlighting(
        [$(`[data-table-id="assets-table"] tbody [data-index="${index}"] [data-cell-key="from.verbatim"] span`)[0]],
        authorKeywords,
      );
    }, 0);

    return (
      <TableRow
        key={id}
        index={index}
        status={rowStatus}
        rowId={id}
        data-asset-id={id}
        onDoubleClick={() => {
          browserHistory.push(goToRenderedAsset(id));
        }}
      >
        {visibleColumns}
      </TableRow>
    );
  };

  render() {
    if (!shouldShowTable(this.props)) {
      return null;
    }

    const {
      tableType,
      visibleTableActions,
      searchResults: {
        results,
        returnCount,
        limitedReturnCount,
        isLoaded: isSearchResultsReady,
      },
      getMessage,
      loadMoreResults,
      isRawSearch,
    } = this.props;

    const kebabType = kebabCase(tableType);
    const loadingText = getMessage('app.general.loading');
    const scrollbarStyles = {
      visibility: results.length ? 'visible' : 'hidden',
    };
    const isShowTable = isSearchResultsReady && !!returnCount;

    const infiniteScrollProps = {
      ...this.props,
      ref: 'tableBody',
      withCustomScrollbar: false,
      count: limitedReturnCount,
      resultComponent: this.getRowContent,
      onResultsNeeded: loadMoreResults,
      selectedItems: this.multiSelect.selectedRows,
      itemHeight: 40,
      loadingText: `${loadingText}...`,
      tableProps: {
        table: {
          withActiveColumn: true,
          tableId: `${kebabType}-table`,
        },
        tableBody: {},
      },
      results,
    };

    return (
      <div className="table-content-group">
        {visibleTableActions && !isRawSearch && (
          <div className="table-control-group">
            <div className="table-control-group-inner">
              {this.renderTopMenus({ isBulkHoldAction: true })}
            </div>
          </div>
        )}
        {isShowTable && (
          <div className="tables-wrapper">
            <div
              className="table-header"
              ref={(thr) => {
                this.tableHeaderRow = thr;
              }}
            >
              <TableViewComponent
                withActiveColumn
                tableId={`${kebabType}-table`}
                onColumnSelectorStateChange={this.fixTableHeaderCellsWidth}
              >
                <TableHeader columnSelector={this.columnSelector}>
                  <TableRow index={0} rowId={`${kebabType}-table-header`}>
                    {this.headerContent}
                  </TableRow>
                </TableHeader>
              </TableViewComponent>
            </div>
            <div
              className="table-content"
              ref={(tb) => {
                this.tableBody = tb;
              }}
            >
              <InfiniteScrollTableComponent {...infiniteScrollProps} />
            </div>
            <div className="table-header-mask" />
            <data style={scrollbarStyles}>
              <VerticalScrollbar {...this.props} />
            </data>
          </div>
        )}
      </div>
    );
  }
}
