/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import AppHeader from 'v-c/AppHeader';
import withMessages, { intlPropTypes } from 'component-utilities/intl';

import reduxStore from '../../reduxStore';

import { SUPPORT_URLS } from '../../../config/configs';
import rolesConfig from '../../../../../../config/roles';

import { jsUi } from '../../lib/jsUi';
import getUserFullName from '../../lib/getUserFullName';
import { getPrioritizedUserRole } from '../../lib/userRights';
import {
  forceLogout,
  goToHomePage,
} from '../../state_managers/AuthenticationStateManager';
import { setUIValues } from '../../state_managers/UIHelperStateManager';
import { ReduxState, intlShape } from 'global/types';
import IfFeature from '../IfFeature';

const archiving = 'archiving';
const personalArchiving = 'personal_archiving';

interface Props extends intlShape {
  domain: {
    accountName: string
    navLogoPath: string
  },
  isViewingPersonalArchive: boolean
  currentUser: {
    roles: string[]
    source: string
    firstName: string
    accountName: string
  },
  visibleMenus: {}
  setUIValues: Function
}

// make tests not be as flaky
function openSupportWindow(url: string) {
  /* eslint-disable no-underscore-dangle */
  if (window.__SKIP_OPEN_SUPPORT_WINDOW__) {
    document.title = url;
  } else {
    window.open(url, '_blank');
  }
  /* eslint-enable no-underscore-dangle */
}

export function determineExtraProps(props: Props) {
  let onChangePasswordClick = () => {
    jsUi.changeUserPassword.goTo();
  };
  const { source } = props.currentUser || {};

  switch (source) {
    case 'open-id':
      onChangePasswordClick = undefined;
      break;
    default:
      // nothing
      break;
  }

  const extraUserProps: any = {};

  if (
    props.currentUser &&
    props.currentUser.firstName &&
    props.domain &&
    props.domain.accountName
  ) {
    extraUserProps.accountTitle = props.domain.accountName;
    extraUserProps.name = getUserFullName(props.currentUser);
  }

  return {
    onChangePasswordClick,
    extraUserProps,
  };
}

const mapStateToProps = ({ userRights, domainInfo, authentication, bulkActions }: ReduxState) => ({
  domain: domainInfo,
  visibleMenus: userRights.visibleMenus,
  currentUser: authentication.loggedInAs || {},
  pendingJobsCount: bulkActions.pendingJobsCount,
});
const mapActionsToProps = { setUIValues };

@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withMessages
export default class Header extends Component<Props> {
  static propTypes = {
    currentUser: PropTypes.object,
    visibleMenus: PropTypes.object,
    pendingJobsCount: PropTypes.number,
    ...intlPropTypes,
  };

  static defaultProps = {
    onGetHelpClick: () => {
      openSupportWindow(SUPPORT_URLS.GET_HELP);
    },
    onWhatsNewClick: () => {
      openSupportWindow(SUPPORT_URLS.WHATS_NEW);
    },
    onSettingsClick: () => {
      jsUi.settingsIndex.goTo();
    },
    onLogOutClick: () => {
      reduxStore.dispatch(forceLogout());
    },
    activeApp: archiving,
    currentUser: null,
    visibleMenus: null,
  };

  state = { activeApp: archiving };

  componentWillMount() {
    if (this.props.isViewingPersonalArchive) {
      this.setState({ activeApp: personalArchiving });
      setUIValues({ personalArchive: true });
    } else {
      this.setState({ activeApp: archiving });
      setUIValues({ personalArchive: false });
    }
  }

  render() {
    const { domain, currentUser, pendingJobsCount } = this.props;
    const { ROLES: { ENDUSER } } = rolesConfig;

    if (!domain) {
      return <noscript />;
    }

    const { onChangePasswordClick, extraUserProps } = determineExtraProps({
      ...this.props,
      domain,
    });

    if (!extraUserProps.name) {
      return <noscript />;
    }

    const priorizedUserRole = getPrioritizedUserRole();
    const role = this.props.getMessage(`app.user_roles.${priorizedUserRole}`);
    const customNavLogoProps = {
      ...(domain.navLogoPath &&
        domain.navLogoPath.length && {
          src: domain.navLogoPath,
        }),
    };
    const hasEndUserRole = !!currentUser.roles.filter(filteredRole => filteredRole === ENDUSER).length;
    const isOnlyEndUser = priorizedUserRole === ENDUSER;
    const jobsCount = IfFeature.isEnabled('jobs_page') ? pendingJobsCount : 0;
    const onArchivingClick = () => {
      this.setState({ activeApp: archiving });
      this.props.setUIValues({ personalArchive: false });
      goToHomePage();
    };

    const onPersonalArchivingClick = () => {
      if (this.state.activeApp !== personalArchiving) {
        this.setState({ activeApp: personalArchiving });
        this.props.setUIValues({ personalArchive: true });
        jsUi.myArchiveSplitViewIndex.goTo();
      }
    };

    return (
      <AppHeader
        {...this.props}
        onChangePasswordClick={onChangePasswordClick}
        getMessage={this.props.getMessage}
        activeApp={this.state.activeApp}
        onArchivingClick={() => onArchivingClick()}
        onPersonalArchivingClick={() => onPersonalArchivingClick()}
        priorizedUserRole={priorizedUserRole}
        user={{ ...extraUserProps, role }}
        onLogoClick={goToHomePage}
        customNavLogo={customNavLogoProps}
        hasEndUserRole={hasEndUserRole}
        isOnlyEndUser={isOnlyEndUser}
        styles={{ position: 'fixed', zIndex: 1000, top: 0, left: 0 }}
        pendingJobsCount={jobsCount}
      />
    );
  }
}
