import React from 'react';
import { FormattedMessage } from 'component-utilities/intl';
import RetinaImage from 'v-c/RetinaImage';
import alert from 'v-c/Dialog/alert.png';

import styles from './Reports.scss';

export default function ReportsLoadError() {
  return (
    <div className={styles['load-error']}>
      <RetinaImage image={alert} />
      <FormattedMessage id="app.reports.reports.load_error" />
    </div>
  );
}
