import React from 'react';
import PropTypes from 'prop-types';

import DeleteAssetErrorModalComponent from 'v-c/Modals/Components/DeleteAssetErrorModal';

export default function DeleteAssetErrorModal({ closeModal }: any) {
  return <DeleteAssetErrorModalComponent onClose={closeModal} />;
}

DeleteAssetErrorModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
