import { trim, isString, camelCase } from 'lodash';
import { formatTZDate } from 'component-utilities/dates/dates';
import convertDataKeys from 'view-components/lib/convertDataKeys';

import reduxStore, { PromiseObject, Action } from '../reduxStore';
import configs from '../../config/configs';
import { SEARCH } from '../../../../../config/app.json';


import { jsUi } from '../lib/jsUi';
import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';
import oldClientDataConverter from '../lib/oldClientDataConverter';
import { PAGES } from '../components/FlashMessages';

import {
  resetSearches,
  updateCurrentView,
  updateSimpleSearch,
  updateAdvancedSearch,
} from './SearchFormStateManager';
import {
  showSuccessFlash,
  showFailureFlash,
  resetFlash,
} from './FlashMessagesStateManager';
import { storeLoadedSavedSearch } from './UnsavedSearchStateManager';
import { loadSearchLists } from './SearchListsStateManager';
import { loadSavedSearches } from './SavedSearchesStateManager';

export const RESET: string = 'currentSavedSearch/reset';
export const IS_PROCESSING: string = 'currentSavedSearch/is-processing';
export const LOAD: string = 'currentSavedSearch/load';
export const UPDATE: string = 'currentSavedSearch/update';
export const DELETE: string = 'currentSavedSearch/delete';
export const SKIP_LOADING_SEARCH: string = 'currentSavedSearch/skip-loading-search';
export const SEARCH_LOAD_SUCCESS: string = 'currentSavedSearch/search-load-success';
export const SEARCH_LOAD_FAILURE: string = 'currentSavedSearch/search-load-failure';
export const SEARCH_UPDATE_SUCCESS: string = 'currentSavedSearch/search-update-success';
export const SEARCH_UPDATE_FAILURE: string = 'currentSavedSearch/search-update-failure';
export const SEARCH_DELETE_SUCCESS: string = 'currentSavedSearch/search-delete-success';
export const SEARCH_DELETE_FAILURE: string = 'currentSavedSearch/search-delete-failure';
export const SET_ERROR: string = 'currentSavedSearch/set-error';
export const RESET_ERRORS: string = 'currentSavedSearch/reset-errors';

export const ERRORS = {
  RANDOMIZED_WITH_ZERO_RESULTS: 'RANDOMIZED_WITH_ZERO_RESULTS',
  RANDOMIZED_WITH_REVIEW: 'RANDOMIZED_WITH_REVIEW',
  SAVED_SEARCH_UPDATE_FAILED: 'SAVED_SEARCH_UPDATE_FAILED',
};

const searchFormActions = {
  simple: updateSimpleSearch,
  advanced: updateAdvancedSearch,
};

const { CLIENT_DATA_VERSION } = SEARCH;
export const BASE_FLASH: string = 'saved_search';
export { PAGES };

export const generalError = {
  type: SET_ERROR,
  payload: { type: ERRORS.SAVED_SEARCH_UPDATE_FAILED },
};

const { CRITERIA_TYPES } = configs.CRITERIA;
export { CRITERIA_TYPES };

interface CurrentSavedSearchState {
  isProcessing: boolean,
  isSuccessfullyUpdated: boolean,
  isSuccessed: boolean,
  isFailed: boolean,
  search: any,
  errors: any,
  errorTypes: any,
}

// Initial state
export const INITIAL_STATE: CurrentSavedSearchState = {
  isProcessing: false,
  isSuccessfullyUpdated: false,
  isSuccessed: false,
  isFailed: false,
  search: {},
  errors: {},
  errorTypes: ERRORS,
};

// Helpers
export function parseCriteria(criteriaList: any) {
  Object.values(criteriaList).map((criteria: any) => {
    if (criteria.type === 'criteria' && criteria.criteriaType === 'keywords') {
      const { list } = criteria.values;
      const initialListLength = list.length;

      list.forEach((listItem: any) => {
        if (listItem.match(/(".*?"|[^" ,\s]+)(?=\s*|\s*$)/g)) {
          listItem.match(/(".*?"|[^" ,\s]+)(?=\s*|\s*$)/g).forEach((item: any) => {
            list.push(item);
          });
        }
      });
      list.splice(0, initialListLength);
      return criteria;
    } else if (criteria.type === 'group') {
      return parseCriteria(criteria.children);
    }

    return criteriaList;
  });
}

export function parseClientData(clientData: any) {
  let parsedClientData: any = {};

  if (isString(clientData)) {
    parsedClientData = JSON.parse(clientData);

    // Filter out old searches
    if (!parsedClientData.type) {
      parsedClientData = {};
    }

    parsedClientData = oldClientDataConverter(parsedClientData);
  }

  reduxStore.dispatch(resetSearches());
  reduxStore.dispatch(updateCurrentView(parsedClientData.type));

  if (parsedClientData.type === 'simple') {
    const { type, formValues } = parsedClientData;

    reduxStore.dispatch(searchFormActions[type](formValues));
  }

  return parsedClientData;
}

export async function populateLoadedSavedSearchData(search: any) {
  let searchListsStore = reduxStore.getState().searchLists;

  if (!searchListsStore.isLoaded) {
    await reduxStore.dispatch(loadSearchLists());
  }

  searchListsStore = reduxStore.getState().searchLists;
  const parsedClientData = parseClientData(search.clientData);

  // Update the list names only for simple searches
  if (parsedClientData.type === 'simple') {
    [
      CRITERIA_TYPES.KEYWORDS,
      CRITERIA_TYPES.FROM,
      CRITERIA_TYPES.PARTICIPANTS,
    ].forEach((criteriaType) => {
      const criteriaValues = parsedClientData.formValues[criteriaType];

      if (criteriaValues && criteriaValues.items) {
        parsedClientData.formValues[
          criteriaType
        ].items = criteriaValues.items.map((savedList: any) => ({
          ...savedList,
          value: (
            searchListsStore.searchLists.find(
              (list: any) => list.id === savedList.id,
            ) || { name: '' }
          ).name,
        }));
      }
    });
  }

  return {
    ...search,
    clientData: parsedClientData,
    lastRunHits: Number(search.lastRunHits),
    lastModifiedTime: formatTZDate(search.lastModifiedTime),
    createdTime: formatTZDate(search.createdTime),
    samplePercentage: String(search.samplePercentage || ''),
  };
}

export function showErrorMessage(response: any) {
  if (response.status === 409) {
    switch (response.data.conflict) {
      case 'Zero Results':
        reduxStore.dispatch(
          showFailureFlash('random_search.with_zero_results', [
            PAGES.SAVE_SEARCH,
          ]),
        );
        return {
          type: SET_ERROR,
          payload: { type: ERRORS.RANDOMIZED_WITH_ZERO_RESULTS },
        };
      case 'Existing Review':
        reduxStore.dispatch(
          showFailureFlash('random_search.with_review', [PAGES.SAVE_SEARCH]),
        );
        return {
          type: SET_ERROR,
          payload: { type: ERRORS.RANDOMIZED_WITH_REVIEW },
        };
      case 'Bad List':
        reduxStore.dispatch(
          showFailureFlash(`${BASE_FLASH}.update`, [PAGES.SAVED_SEARCH_RESULTS]),
        );
        return {
          type: SET_ERROR,
          payload: { type: ERRORS.SAVED_SEARCH_UPDATE_FAILED },
        };
      default:
        reduxStore.dispatch(
          showFailureFlash(`${BASE_FLASH}.update`, [PAGES.SAVE_SEARCH]),
        );
        return generalError;
    }
  }

  reduxStore.dispatch(
    showFailureFlash(`${BASE_FLASH}.update`, [PAGES.SAVE_SEARCH]),
  );

  return generalError;
}

// Actions
export function loadSavedSearch({ searchId, loadIfNecessary = false }: any) {
  return function* doLoadSavedSearch(getState = reduxStore.getState) {
    yield { type: LOAD };

    if (loadIfNecessary) {
      const { search } = getState().currentSavedSearch;

      if (search.id === searchId) {
        reduxStore.dispatch(storeLoadedSavedSearch(search));
        yield { type: SKIP_LOADING_SEARCH };

        return;
      }
    }

    yield { type: IS_PROCESSING };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.searchesShow, {
        urlParams: { id: searchId },
      });

      const populatedData: { clientData: any } = yield populateLoadedSavedSearchData(
        convertDataKeys(result.data, camelCase),
      );

      if (populatedData.clientData.version !== CLIENT_DATA_VERSION && populatedData.clientData.type === 'advanced') {
        const { children } = populatedData.clientData.formValues[0];
        parseCriteria(children);
      }

      reduxStore.dispatch(storeLoadedSavedSearch(populatedData));

      yield {
        type: SEARCH_LOAD_SUCCESS,
        payload: {
          search: populatedData,
        },
      };
    } catch (e) {
      console.error(e);

      jsUi.searchesIndex.goTo();
      reduxStore.dispatch(
        showFailureFlash(`${BASE_FLASH}.load`, [PAGES.SEARCHES]),
      );

      yield showErrorMessage(e);
      yield { type: SEARCH_LOAD_FAILURE };
    }
  };
}

export function updateSavedSearch({ id, requestData, navigate = true }: any, personal = false) {
  return function* doUpdateSavedSearch() {
    yield { type: UPDATE };
    yield { type: IS_PROCESSING };

    reduxStore.dispatch(
      resetFlash({ from: 'CurrentSavedSearchStateManager/updateSavedSearch' }),
    );

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.searchesUpdate, {
        params: {
          data: {
            saved_search: { ...requestData, name: trim(requestData.name) },
          },
        },
        urlParams: { id },
      });

      const validFlashView = personal ? PAGES.MY_ARCHIVE_SPLIT_VIEW : PAGES.SEARCH_RESULTS;

      reduxStore.dispatch(
        showSuccessFlash(`${BASE_FLASH}.update`, [validFlashView]),
      );

      if (navigate && !personal) {
        jsUi.resultsIndex.goTo({ id });
      }

      if (personal) {
        reduxStore.dispatch(loadSavedSearches(0, true));
      }

      const populatedData: PromiseObject = yield populateLoadedSavedSearchData(
        convertDataKeys(result.data, camelCase),
      );

      reduxStore.dispatch(storeLoadedSavedSearch(populatedData));

      yield {
        type: SEARCH_UPDATE_SUCCESS,
        payload: {
          search: populatedData,
          isSuccessfullyUpdated: true,
        },
      };
    } catch (e) {
      console.error(e);

      yield showErrorMessage(e);
      yield { type: SEARCH_UPDATE_FAILURE };
    }
  };
}

export function deleteSavedSearch(searchId: string, personal = false) {
  return function* doDeleteSavedSearch() {
    yield { type: DELETE };
    yield { type: IS_PROCESSING };

    reduxStore.dispatch(
      resetFlash({ from: 'CurrentSavedSearchStateManager/deleteSavedSearch' }),
    );

    try {
      yield apiReqPromise(jsApi.searchesDestroy, {
        urlParams: { id: searchId },
      });

      const validFlashView = personal ? PAGES.MY_ARCHIVE_SPLIT_VIEW : PAGES.SEARCHES;

      reduxStore.dispatch(
        showSuccessFlash(`${BASE_FLASH}.delete`, [validFlashView]),
      );

      if (personal) {
        reduxStore.dispatch(loadSavedSearches(0, true));
      } else {
        jsUi.searchesIndex.goTo();
      }

      yield { type: SEARCH_DELETE_SUCCESS };
    } catch (e) {
      console.error(e);

      yield { type: SEARCH_DELETE_FAILURE };
    }
  };
}

export function clearSavedSearchErrors() {
  return { type: RESET_ERRORS };
}

export function resetCurrentSavedSearch() {
  return { type: RESET };
}

export default function CurrentSavedSearchStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case IS_PROCESSING:
      return {
        ...state,
        isProcessing: true,
        isSuccessed: false,
        isFailed: false,
        isSuccessfullyUpdated: false,
      };
    case SEARCH_LOAD_SUCCESS:
    case SEARCH_UPDATE_SUCCESS:
    case SEARCH_DELETE_SUCCESS:
      return {
        ...state,
        ...payload,
        isProcessing: false,
        isSuccessed: true,
      };
    case SEARCH_LOAD_FAILURE:
    case SEARCH_UPDATE_FAILURE:
    case SEARCH_DELETE_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isFailed: true,
        isSuccessfullyUpdated: false,
      };
    case SET_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [payload.type]: true,
        },
      };
    case RESET:
      return { ...INITIAL_STATE };
    case RESET_ERRORS:
      return {
        ...state,
        errors: {},
      };
    default:
      return state;
  }
}
