const asyncTypeStoreInitialConfig = {
  loadingTime: 0,
  isProcessing: false,
  isLoading: false,
  errors: {},
  searchString: '',
  sortOptions: {},
};

export default asyncTypeStoreInitialConfig;
