import { find, contains, uniq } from 'lodash';

import { HighlightValues } from './collectHighlightKeywords';
import replaceWildcards from './replaceWildcards';

export default function PrepareAdvancedSearchForHighlighting(
  formValues,
  searchLists,
  forAssetView = false,
) {
  if (!formValues.defaults) return { subject: '', author: '' };

  const {
    list: rootList,
    defaults: {
      listElemTypes: { CRITERIA, GROUP },
    },
  } = formValues;

  let keywords: any = [];
  const keywordTemps: HighlightValues = {
    subject: [],
    attachment_name: [],
    attachment_body: [],
    body: [],
    to: [],
    from: [],
    cc: [],
    bcc: [],
  };
  const keywordListsItems = {
    body: [],
    subject: [],
    attachment_name: [],
    attachment_body: [],
  };
  const userListsItems = {
    to: [],
    from: [],
    cc: [],
    bcc: [],
  };

  const collectHighlightValues = (group: any) => {
    const { children } = group;

    Object.keys(children).forEach((childKey) => {
      const child = children[childKey];
      const { type } = child;

      switch (type) {
        case CRITERIA: {
          const {
            criteriaType,
            values: { list, selectedCriteria, selectedFields, isKeywordExact },
          } = child;

          const collectCriteria = (matchCriteria: string, criteriaHolder: string[]) => {
            if (contains(selectedCriteria, matchCriteria)) {
              list.forEach((_criterion: string) => {
                const criterion = _criterion
                  .replace(/"/g, '')
                  .trim();

                if (criterion.length) {
                  criteriaHolder.push(replaceWildcards(criterion, isKeywordExact));
                }
              });
            }
          };

          const collectListItems = (itemAccumulator: string[]) => {
            selectedCriteria.forEach((id: string) => {
              const searchListItems = (find(searchLists, { id }) || {}).items;
              if (searchListItems) {
                itemAccumulator.push(...searchListItems.map((item: string) => replaceWildcards(item, isKeywordExact)));
              }
            });
          };

          switch (criteriaType) {
            case 'adv-participants':
              selectedCriteria.forEach((criteria: string) => {
                collectCriteria(criteria, keywordTemps[criteria]);
              });
              break;
            case 'keywords':
              selectedCriteria.forEach((criteria: string) => {
                collectCriteria(criteria, keywordTemps[criteria]);
              });
              break;
            case 'keyword-lists':
              selectedFields.forEach((field: string) => {
                collectListItems(keywordListsItems[field]);
              });
              break;
            case 'user-lists':
              selectedFields.forEach((field: string) => {
                collectListItems(userListsItems[field]);
              });
              break;
            default:
              // unknown criteria type
              break;
          }
          break;
        }
        case GROUP:
          collectHighlightValues(child);
          break;
        default:
          // unknown type
          break;
      }
    });
  };

  collectHighlightValues(rootList['0']);

  const criterias: HighlightValues = {
    subject: uniq([...keywordTemps.subject, ...keywordListsItems.subject]),
    author: uniq([...keywordTemps.from, ...userListsItems.from]),
    attachment_name: uniq([...keywordTemps.attachment_name, ...keywordListsItems.attachment_name]),
    body: uniq([...keywordTemps.body, ...keywordListsItems.body]),
    attachment_body: uniq([...keywordTemps.attachment_body, ...keywordListsItems.attachment_body]),
    to: uniq([
      ...keywordTemps.to,
      ...keywordTemps.cc,
      ...keywordTemps.bcc,
      ...userListsItems.bcc,
      ...userListsItems.cc,
      ...userListsItems.to,
    ]),
  };

  if (forAssetView) {
    Object.keys(criterias).forEach((criteria) => {
      keywords = keywords.concat(criterias[criteria]);
    });

    return keywords;
  }

  return criterias;
}
