/* eslint-disable no-undef */
import React, { Component } from 'react';
import { fromJS } from 'immutable';
import { contains } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import naturalCmp from './NaturalCmp';
import { formHelperPropTypes } from './formHelper';
import prettyPrintFormatter from './prettyPrintFormatter';

import { SEARCH } from '../../../../../config/app.json';
import { Search } from 'global/types';

const { PATH_INDEX_SEPARATOR } = SEARCH;

function getPathLastIndex(path: string) {
  return parseInt(path.split(PATH_INDEX_SEPARATOR).pop(), 10);
}

function keysSorter(a: string, b: string) {
  return naturalCmp(getPathLastIndex(a), getPathLastIndex(b));
}

function swapKeys(_obj: any, a: string, b: string) {
  const obj = _obj;

  const tmp = obj[a];
  delete obj[a];
  obj[b] = tmp;
}

function ensureChildrenRecalculated(node: any, newChildIdx: string) {
  const { children: newChildIdxChildren } = node;

  if (newChildIdxChildren) {
    /* eslint-disable no-use-before-define */
    recalculateChildrenPath(newChildIdxChildren, newChildIdx);
    /* eslint-enabled no-use-before-define */
  }
}

export function recalculateChildrenPath(children: any, parentPath: string) {
  // TODO: make immutable
  const modifiedChildren = children;

  const parentPathParts = parentPath.split(PATH_INDEX_SEPARATOR);

  Object.keys(modifiedChildren).forEach((childKey) => {
    const childIdx = getPathLastIndex(childKey);
    const newChildIdx = [...parentPathParts, childIdx].join(
      PATH_INDEX_SEPARATOR,
    );

    swapKeys(modifiedChildren, childKey, newChildIdx);

    ensureChildrenRecalculated(modifiedChildren[newChildIdx], newChildIdx);
  });
}

function isEmptyGroup({ children }: any) {
  return !Object.keys(children).length;
}

export function addListElem(groupIdx: number, _newItemHolder: any, _newListElem: any) {
  const newItemHolder = _newItemHolder;
  const newListElem = _newListElem;

  newListElem.UUID = uuidv4();

  const newIndex = `${groupIdx}${PATH_INDEX_SEPARATOR}${
    Object.keys(newItemHolder).length
  }`;

  newItemHolder[newIndex] = newListElem;
}

export function moveUpChildrenInThePath(
  _parentGroup: any,
  newChildren: any,
  deletedPath: string,
) {
  // TODO: make immutable
  const parentGroup = _parentGroup;

  const siblingPusher = Object.keys(newChildren).length - 1;
  const parentPathParts = deletedPath.split(PATH_INDEX_SEPARATOR);
  let deletedIdx = getPathLastIndex(deletedPath);
  parentPathParts.pop();

  // 1. if the deletedElem's children number bigger than 1
  //    push the siblings toward to make enough space for them
  if (siblingPusher) {
    Object.keys(parentGroup.children)
      .sort(keysSorter)
      .reverse()
      .forEach((childKey) => {
        const childIdx = getPathLastIndex(childKey);
        if (childIdx > deletedIdx) {
          const newChildIdx = childIdx + siblingPusher;
          parentPathParts.push(newChildIdx);
          const newPath = parentPathParts.join(PATH_INDEX_SEPARATOR);

          parentGroup.children[newPath] = parentGroup.children[childKey];
          delete parentGroup.children[childKey];

          const { children: newChildIdxChildren } = parentGroup.children[
            newPath
          ];

          if (newChildIdxChildren) {
            recalculateChildrenPath(newChildIdxChildren, newPath);
          }

          parentPathParts.pop();
        }
      });
  }

  // 2. Add the new children elems to the parent group
  Object.keys(newChildren).forEach((childKey) => {
    parentPathParts.push(deletedIdx);
    const newChildIdx = parentPathParts.join(PATH_INDEX_SEPARATOR);

    parentGroup.children[newChildIdx] = newChildren[childKey];
    const { children } = parentGroup.children[newChildIdx];

    if (children) {
      recalculateChildrenPath(children, newChildIdx);
    }

    deletedIdx += 1;
    parentPathParts.pop();
  });

  // 3. Sort the keys
  const sortedKeys = Object.keys(parentGroup.children).sort(keysSorter);
  const sortedObject = {};

  sortedKeys.forEach((childKey) => {
    sortedObject[childKey] = parentGroup.children[childKey];
  });

  parentGroup.children = sortedObject;
}

export function deleteCriteria(children: any, deletedPath: string) {
  // TODO: make immutable
  const modifiedChildren = children;

  const deletedIdx = getPathLastIndex(deletedPath);

  // We store all the children with using their paths as key
  // If we delete one of them from the middle of the group
  //  then we need recalculate all of the next siblings's key
  //  to disable the deleted child's hole
  Object.keys(modifiedChildren)
    .sort(keysSorter)
    .forEach((childKey) => {
      const childPathParts = childKey.split(PATH_INDEX_SEPARATOR);
      childPathParts.pop();
      const childIdx = getPathLastIndex(childKey);

      if (childIdx > deletedIdx) {
        const newChildIdx = [...childPathParts, childIdx - 1].join(
          PATH_INDEX_SEPARATOR,
        );

        swapKeys(modifiedChildren, childKey, newChildIdx);

        ensureChildrenRecalculated(modifiedChildren[newChildIdx], newChildIdx);
      }
    });
}

export function deleteListElem(data: any) {
  const { idx, parentGroup } = data;

  const parentGroupToModify = parentGroup;

  const deletedElem = parentGroupToModify.children[idx];
  const { children } = deletedElem;

  delete parentGroupToModify.children[idx];

  if (children && !isEmptyGroup(deletedElem)) {
    data.moveUpChildrenInThePath(parentGroup, children, idx);
  } else {
    data.deleteCriteria(parentGroup.children, idx);
  }
}

export function moveListElem({
  originalIdx,
  newIdx,
  originalParentGroup,
  newGroup,
}: any) {
  const originalParentGroupToModify = originalParentGroup;

  const originalIdxParts = originalIdx.split(PATH_INDEX_SEPARATOR);
  const movedElem = originalParentGroupToModify.children[originalIdx];
  const newGroupChildren = newGroup.children;
  const newIdxParts = newIdx.split(PATH_INDEX_SEPARATOR);
  const newIdxPosition = getPathLastIndex(newIdx);
  newIdxParts.pop();
  originalIdxParts.pop();

  // Delete item from the original position
  delete originalParentGroupToModify.children[originalIdx];

  // If the newIdx in the same group as the orginalIdx
  if (originalIdxParts.length === newIdxParts.length) {
    deleteCriteria(originalParentGroupToModify.children, originalIdx);
  }

  // If on the new place there are other elements
  // we have to increase the siblings index whose are in the
  // new place or have higher index
  if (newGroupChildren[newIdx]) {
    Object.keys(newGroupChildren)
      .sort(keysSorter)
      .reverse()
      .forEach((childKey) => {
        const childKeyParts = childKey.split(PATH_INDEX_SEPARATOR);
        const childKeyPosition = getPathLastIndex(childKey);
        const { children } = newGroupChildren[childKey];
        childKeyParts.pop();

        if (childKeyPosition >= newIdxPosition) {
          const newChildIdx = childKeyParts
            .concat(childKeyPosition + 1)
            .join(PATH_INDEX_SEPARATOR);

          newGroupChildren[newChildIdx] = newGroupChildren[childKey];

          if (children) {
            recalculateChildrenPath(children, newChildIdx);
          }
        }
      });
  }

  // Add the item to the new position and calculate the children's
  //  new indexes if the item is a group
  newGroupChildren[newIdx] = movedElem;
  if (movedElem.children) {
    recalculateChildrenPath(movedElem.children, newIdx);
  }

  // If the newIdx isn't in the same group as the orginalIdx
  if (originalIdxParts.length !== newIdxParts.length) {
    deleteCriteria(originalParentGroup.children, originalIdx);
  }
}

function getParentGroupIndex(idx: string) {
  const indexParts = idx.split(PATH_INDEX_SEPARATOR);
  indexParts.pop();

  return indexParts.join(PATH_INDEX_SEPARATOR);
}

type Props = {
  currentSearch: Search,
  fields: {
    form: {
      value: any,
      change: Function
    }
  },
  formDefaults: {
    defaultGroup: {},
    defaultCriteria: {},
    relations: {
      AND: string,
      OR: string
    },
    listElemTypes: {
      CRITERIA: string,
      GROUP: string
    }
  }
}

export default function AdvancedSearchFormHelper(component: any) {
  return class AdvancedSearchHelperComponent extends Component<Props> {
    static propTypes = {
      ...formHelperPropTypes,
    };

    getTargetListElem = (groupIdx: string) => {
      const targetGroupIndexParts = groupIdx.split(PATH_INDEX_SEPARATOR);
      const indexes: string[] = [];
      let targetListElem;

      targetGroupIndexParts.reduce((list, indexPart) => {
        indexes.push(indexPart);
        const actualIndex = indexes.join(PATH_INDEX_SEPARATOR);
        targetListElem = list[actualIndex];
        return list[actualIndex].children;
      }, this.props.fields.form.value);

      return targetListElem;
    };

    getParentGroup(idx: string) {
      const parentIndex = getParentGroupIndex(idx);

      return this.getTargetListElem(parentIndex);
    }

    get criteriaAndGroupsNumber() {
      const {
        listElemTypes: { CRITERIA, GROUP },
      } = this.props.formDefaults;
      const {
        fields: { form },
      } = this.props;

      let criteria = 0;
      let groups = 0;

      const countGroupsAndCriterias = ({ children }: any) => {
        Object.keys(children).forEach((childKey) => {
          const child = children[childKey];
          switch (child.type) {
            case CRITERIA:
              criteria += 1;
              break;
            case GROUP:
              groups += 1;
              countGroupsAndCriterias(child);
              break;
            default:
              // unknown
              break;
          }
        });
      };

      if (form.value && form.value['0']) {
        countGroupsAndCriterias(form.value['0']);
      }

      return { criteria, groups };
    }

    get prettyPrintQuery() {
      const prettyPrint =
        this.props.currentSearch && this.props.currentSearch.pretty
          ? this.props.currentSearch.pretty
          : '';

      return prettyPrintFormatter(prettyPrint);
    }

    get formValues() {
      return this.props.fields.form.value;
    }

    getGroupDepth(idx: string) {
      const foundLevels: number[] = [];
      const addLevelFn = (level: number) => {
        if (!contains(foundLevels, level)) foundLevels.push(level);
      };
      const getGroupDepthFn = ({ children }: any, level: number) => {
        addLevelFn(level);

        if (!children) return;

        Object.keys(children).forEach((child) => {
          if (children[child].children) {
            getGroupDepthFn(children[child], level + 1);
          }
        });
      };

      getGroupDepthFn(this.getTargetListElem(idx), 0);

      return foundLevels.length;
    }

    generateNewCriteria() {
      const { defaultCriteria } = this.props.formDefaults;
      const newCriteria = fromJS(defaultCriteria);

      return newCriteria.toJS();
    }

    generateNewGroup() {
      const { defaultGroup } = this.props.formDefaults;
      const newGroup = fromJS(defaultGroup);

      return newGroup.toJS();
    }

    addListElem = (groupIdx: number, type: string) => {
      let newListElem;

      if (type === 'add_group') {
        newListElem = this.generateNewGroup();
      } else {
        newListElem = Object.assign({}, this.generateNewCriteria(), {
          criteriaType: type,
        });
      }

      const newItemHolder = this.getTargetListElem(groupIdx).children;

      addListElem(groupIdx, newItemHolder, newListElem);

      this.updateList();
    };

    deleteListElem = (idx: string) => {
      const {
        relations: { AND, OR },
      } = this.props.formDefaults;
      const parentGroup: any = this.getParentGroup(idx);
      const group: any = this.getTargetListElem(idx);
      const beforeDeletionLength = Object.keys(parentGroup.children).length

      // if before deleting there is only one criteria/group 
      // set relation to deletedElem.relation
      if (beforeDeletionLength === 1) {
        if(group && group.type === 'group') {
          parentGroup.relation = group.relation;
        }
      }
      deleteListElem({
        idx,
        parentGroup,
        deleteCriteria,
        moveUpChildrenInThePath,
      });

      if (isEmptyGroup(parentGroup)) {
        parentGroup.relation = AND;
      }

      // Little hack to reset the table after deleting a group or criteria
      this.updateList({}, () => this.updateList());
    };

    moveListElem = (originalIdx: string, newIdx: string) => {
      const originalParentGroup = this.getParentGroup(originalIdx);
      const newGroup = this.getParentGroup(newIdx);

      moveListElem({
        originalIdx,
        newIdx,
        originalParentGroup,
        newGroup,
      });

      this.updateList({}, () => this.updateList());
    };

    changeRelation = (idx: string) => {
      const parentGroup: any = this.getParentGroup(idx);
      const {
        relations: { AND, OR },
      } = this.props.formDefaults;
      const newRelation = parentGroup.relation === AND ? OR : AND;
      parentGroup.relation = newRelation;

      this.updateList();
    };

    updateListElem = (listElem: any, idx: string) => {
      const parentGroup: any = this.getParentGroup(idx);
      parentGroup.children[idx] = listElem;

      this.updateList();
    };

    updateList() {
      this.props.fields.form.change(this.props.fields.form.value);
    }

    render() {
      return React.createElement(component, {
        ...this.props,
        ...this.state,
        deleteCriteria,
        getPathLastIndex,
        moveUpChildrenInThePath,
        recalculateChildrenPath,
        getParentGroupIndex,
        getTargetListElem: this.getTargetListElem,
        generateNewCriteria: this.generateNewCriteria,
        generateNewGroup: this.generateNewGroup,
        getParentGroup: this.getParentGroup,
        updateList: this.updateList,
        updateListElem: this.updateListElem,
        getGroupDepth: this.getGroupDepth,
        changeRelation: this.changeRelation,
        moveListElem: this.moveListElem,
        deleteListElem: this.deleteListElem,
        addListElem: this.addListElem,
        formValues: this.formValues,
        criteriaAndGroupsNumber: this.criteriaAndGroupsNumber,
        prettyPrintQuery: this.prettyPrintQuery,
      });
    }
  };
}
