import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import withMessages from 'component-utilities/intl';

import routeLeaveHook from '../../global/lib/routeLeaveHook';

import AppCredentials from './ADSync/AppCredentials';
import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';

import Layout from '../../global/components/Layout';
import Breadcrumb from '../../global/components/Breadcrumb';
import HeadlineLabel from '../../global/components/HeadlineLabel';
import validateSettingsAccess from './components/validateSettingsAccess';

import { resetFlash } from '../../global/state_managers/FlashMessagesStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';


const mapActionsToProps = {
  resetFlash,
  setBreadcrumbForSettingsADSyncAppCredentialsPage:
    breadcrumbsActions.settingsAdSyncAppCredentialsPage,
};

type Props = {
  getMessage: Function,
  resetFlash: Function,
  registerUnmountCleanupMethod: Function,
  setBreadcrumbForSettingsADSyncAppCredentialsPage: Function
}

@routeLeaveHook
@withMessages
@flashConnect(PAGES.APP_CREDENTIALS, 'AppCredentials')
@validateSettingsAccess('settings.ad_sync')
@connect(
  null,
  mapActionsToProps,
)
export default class ADSyncAppCredentials extends Component<Props> {
  static propTypes = {
    getMessage: PropTypes.func.isRequired,
    resetFlash: PropTypes.func.isRequired,
    registerUnmountCleanupMethod: PropTypes.func.isRequired,
    setBreadcrumbForSettingsADSyncAppCredentialsPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    manageButtonText: 'button.manage',
  };

  constructor(props: Props) {
    super(props);

    props.setBreadcrumbForSettingsADSyncAppCredentialsPage();

    props.registerUnmountCleanupMethod(() => {
      props.resetFlash({ from: 'AppCredentials' });
    });
  }

  render() {
    const { getMessage } = this.props;

    return (
      <Layout section="ad-sync">
        <header>
          <FlashMessages />
          <Breadcrumb {...this.props} />
          <HeadlineLabel text={getMessage('app.breadcrumb.app_credentials')} />
        </header>
        <AppCredentials {...this.props} />
      </Layout>
    );
  }
}
