import { jsApi as jsApiGenerator } from 'view-components/lib/url';
import {
  API,
  GLOBAL,
  ACCOUNT,
} from '../../../../../config/routes/view-frontend.json';
import {
  NEST_API,
  GLOBAL as NEST_GLOBAL
} from '../../../../../config/routes/nest-server.json';
import nestApiGenerator from './nestApiGenerator';

import apiPrefix from './apiPrefix';

const jsApi = {
  ...nestApiGenerator(NEST_API, (path: string) => `${window.location.protocol}//${window.location.host}/${NEST_GLOBAL.API_BASE}/:site_name/${path}`),
  ...jsApiGenerator(API, apiPrefix(GLOBAL.API_BASE)),
  ...jsApiGenerator(ACCOUNT, apiPrefix(GLOBAL.ACCOUNT_BASE)),
};

export default jsApi;
