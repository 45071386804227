import { sanitizeRedirectUrl } from 'view-components/lib/url';

export default function sanitizeAndProtectRedirectUrl(url: string, cb: Function, failureCB: Function) {
  if (!url || /^ *$/.test(url)) {
    failureCB();
  } else {
    try {
      cb(sanitizeRedirectUrl(url));
    } catch (e: any) {
      if (e.message.indexOf(url) !== -1) {
        console.warn('Unable to redirect to provided url!');

        if (failureCB) { failureCB(); }
      } else {
        throw e;
      }
    }
  }
}
