import React, { Component } from 'react';

/**
 * Hide and show the Zendesk widget forcibly.
 */
export default function ToggleZendeskHelp(component: any) {
  return class HideZendeskHelpComponent extends Component {
    constructor(props: any) {
      super(props);

      const hideZendeskHelp = () => {
        const launcher: any = document.getElementById('launcher');

        if (launcher) {
          this.launcher = launcher;

          launcher.parentNode.style.visibility = 'hidden';
        } else {
          setTimeout(hideZendeskHelp, 1);
        }
      };

      hideZendeskHelp();
    }

    componentWillUnmount() {
      if (this.launcher) {
        this.launcher.parentNode.style.visibility = 'visible';
      }
    }

    render() {
      return React.createElement(component, this.props);
    }
  };
}
