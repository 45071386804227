/**
 * Dialog is the old approach for showing modal screens to
 * users. It relies on placing components and callbacks
 * directly into a store, and should not be used. You should
 * be using Modals instead. See Modals/ModalView.jsx as a
 * starting point for modals.
 */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import UnsavedDialogContent from 'view-components/components/Dialog/UnsavedDialogContent';

import DialogComponent from 'view-components/components/Dialog';

import {
  confirmDialog,
  dismissDialog,
  SHOW_UNSAVED_SEARCH_DIALOG,
  SHOW_UNSAVED_HOLD_REASON_DIALOG,
  SHOW_UNSAVED_USER_DIALOG,
  SHOW_UNSAVED_ACCOUNT_SETTINGS_DIALOG,
  SHOW_UNSAVED_RETENTION_POLICIES_DIALOG,
  SHOW_UNSAVED_POP3_CONFIG_DIALOG,
  SHOW_DELETE_SEARCH_DIALOG,
  SHOW_DELETE_USER_DIALOG,
  SHOW_DELETE_ADSYNC_DIALOG,
  SHOW_UPDATE_ACCOUNT_SETTINGS_DIALOG,
  SHOW_UPDATE_SSO_SETTINGS_DIALOG,
  SHOW_REVERT_LOGIN_PROVIDER_DIALOG,
  SHOW_DELETE_LIST_DIALOG,
  SHOW_CANNOT_DELETE_LIST_DIALOG,
  SHOW_DELETE_COMMENT_DIALOG,
} from '../state_managers/DialogStateManager';

import DeleteSearchDialogContent from './Dialog/DeleteSearchDialogContent';
import DeleteUserDialogContent from './Dialog/DeleteUserDialogContent';
import DeleteADSyncDialogContent from './Dialog/DeleteADSyncDialogContent';
import UpdateAccountSettingsDialog from './Dialog/UpdateAccountSettingsDialog';
import UpdateSSOSettingsDialog from './Dialog/UpdateSSOSettingsDialog';
import DeleteListDialogContent from './Dialog/DeleteListDialogContent';
import CannotDeleteListDialogContent from './Dialog/CannotDeleteListDialogContent';
import DeleteCommentDialogContent from './Dialog/DeleteCommentDialogContent';
import { ReduxState } from 'global/types';

const mapStateToProps = ({ dialog }: ReduxState) => ({ dialog });
const mapActionsToProps = { confirmDialog, dismissDialog };

type Props = {
  dialog: {
    options: {}
    type: string
  }
  staticView: boolean
  confirmDialog: Function
  dismissDialog: Function
}

@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class Dialog extends Component<Props> {
  static propTypes = {
    dialog: PropTypes.object.isRequired,
    staticView: PropTypes.bool,
    confirmDialog: PropTypes.func.isRequired,
    dismissDialog: PropTypes.func.isRequired,
  };

  static defaultProps = {
    staticView: false,
  };

  constructor(props: Props) {
    super(props);

    this.markedAsDeprecated = false;
  }

  componentDidUpdate() {
    const dialog = $('[data-dialog="true"]');

    if (this.props.staticView && dialog.length) {
      dialog.css({ top: 0 });
    }
  }

  render() {
    const {
      dialog: { options, type },
    } = this.props;

    let DialogContent;
    switch (type) {
      case SHOW_UNSAVED_SEARCH_DIALOG:
      case SHOW_UNSAVED_HOLD_REASON_DIALOG:
      case SHOW_UNSAVED_USER_DIALOG:
      case SHOW_UNSAVED_ACCOUNT_SETTINGS_DIALOG:
      case SHOW_UNSAVED_RETENTION_POLICIES_DIALOG:
      case SHOW_UNSAVED_POP3_CONFIG_DIALOG:
        DialogContent = UnsavedDialogContent;
        break;
      case SHOW_DELETE_SEARCH_DIALOG:
        DialogContent = DeleteSearchDialogContent;
        break;
      case SHOW_DELETE_LIST_DIALOG:
        DialogContent = DeleteListDialogContent;
        break;
      case SHOW_CANNOT_DELETE_LIST_DIALOG:
        DialogContent = CannotDeleteListDialogContent;
        break;
      case SHOW_DELETE_COMMENT_DIALOG:
        DialogContent = DeleteCommentDialogContent;
        break;
      case SHOW_DELETE_USER_DIALOG:
        DialogContent = DeleteUserDialogContent;
        break;
      case SHOW_DELETE_ADSYNC_DIALOG:
        DialogContent = DeleteADSyncDialogContent;
        break;
      case SHOW_UPDATE_ACCOUNT_SETTINGS_DIALOG:
        DialogContent = UpdateAccountSettingsDialog;
        break;
      case SHOW_UPDATE_SSO_SETTINGS_DIALOG:
        DialogContent = UpdateSSOSettingsDialog;
        break;
      case SHOW_REVERT_LOGIN_PROVIDER_DIALOG:
        DialogContent = (props: Props) =>
          React.createElement(UpdateSSOSettingsDialog, {
            ...props,
            isReverting: true,
          });
        break;
      default:
        break;
    }

    let toRender = null;

    if (typeof DialogContent !== 'undefined' && DialogContent !== null) {
      const actions = {
        onConfirm: this.props.confirmDialog,
        onDismiss: this.props.dismissDialog,
      };

      if (!this.markedAsDeprecated) {
        console.warn(`
Dialog is deprecated.
Instead, use the new style of modals in ModalView.
      `);

        this.markedAsDeprecated = true;
      }

      toRender = (
        <DialogComponent onClose={actions.onDismiss} {...options}>
          {dialog => (
            <DialogContent dialog={dialog} {...options} {...actions} />
          )}
        </DialogComponent>
      );
    }

    return toRender;
  }
}
