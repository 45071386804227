/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UnsavedChangesModalView from 'v-c/Modals/UnsavedChangesModal';
import { ModalProps } from 'global/types';

export default class UnsavedChangesModal extends Component<ModalProps> {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    return (
      <UnsavedChangesModalView
        onClose={this.props.closeModal}
      />
    );
  }
}
