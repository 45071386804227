import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { pickBy } from 'lodash';
import { FORM_FIELDS } from 'v-c/Search/SimpleSearch';

import { SEARCH } from '../../../../../../config/app.json';
import {
  KEYWORDS_EXACT,
  SENDER_DOMAINS,
  PARTICIPANTS_DOMAINS,
} from '../../../data/Criteria';

const { CLIENT_DATA_VERSION } = SEARCH;

type Props = {
  reasons: any[],
  values: {
    simpleSearch: any,
    advancedSearch: any,
  },
  fields: {
    simpleSearch: any,
    currentView: string
  },
  searchForm: {
    currentView: string
  },
  searchLists: any[],
  openModal: Function,
  closeModal: Function,
  isSimpleView: Boolean,
  updateSimpleSearch: Function,
  updateAdvancedSearch: Function,
  loadLegalHoldReasons: Function,
  params: {
    search_id: String,
  },
  isInitialForm: Boolean,
  emailMatchDetails: {
    emailCount: String,
    loadingTime: String,
  },
  searchId: String,
  loadSearchLists: Function,
  runUnsavedSearch: Function,
  runModifiedSavedSearch: Function,
  loadUnsavedSearchSummary: Function,
}

export default function FormViewDeconstructed({
  goToSearchesIndex,
  goToSearchesShow,
  toDSL,
  TABS,
  MODAL_MAP,
}) {
  return function FormViewStateLogicComposer(component: any) {
    class FormViewStateLogic extends Component<Props> {
      static propTypes = {
        reasons: PropTypes.array.isRequired,
        values: PropTypes.object.isRequired,
        fields: PropTypes.object.isRequired,
        searchLists: PropTypes.array.isRequired,
        openModal: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        isSimpleView: PropTypes.bool.isRequired,
        updateSimpleSearch: PropTypes.func.isRequired,
        updateAdvancedSearch: PropTypes.func.isRequired,
        loadLegalHoldReasons: PropTypes.func.isRequired,
        params: PropTypes.object,
        isInitialForm: PropTypes.bool,
        emailMatchDetails: PropTypes.shape({
          emailCount: PropTypes.string.isRequired,
          loadingTime: PropTypes.string.isRequired,
        }).isRequired,
        searchId: PropTypes.string,
        loadSearchLists: PropTypes.func.isRequired,
        runUnsavedSearch: PropTypes.func.isRequired,
        runModifiedSavedSearch: PropTypes.func.isRequired,
        loadUnsavedSearchSummary: PropTypes.func.isRequired,
      };

      static defaultProps = {
        disabledTabs: [],
        params: null,
        isInitialForm: false,
        searchId: null,
      };

      constructor(props: Props) {
        super(props);

        props.loadLegalHoldReasons();
        props.loadSearchLists();
      }

      componentDidUpdate() {
        // CSS hack until new advanced search and layout implemented
        $('[data-form="simple-search"]').css({
          marginBottom: '50px',
        });
      }

      onSearch = () => {
        const {
          isSimpleView,
          values,
          params,
          runModifiedSavedSearch,
          runUnsavedSearch,
          loadUnsavedSearchSummary,
        } = this.props;

        if (isSimpleView) {
          this.props.updateSimpleSearch(values.simpleSearch);
        } else {
          this.props.updateAdvancedSearch(values.advancedSearch);
        }

        const searchId = params && params.search_id;
        const unsavedSearchAction = searchId
          ? runModifiedSavedSearch
          : runUnsavedSearch;

        const isKeywordExact = values.simpleSearch[FORM_FIELDS.KEYWORDS_EXACT] && values.simpleSearch[FORM_FIELDS.KEYWORDS_EXACT].query;
        const isSenderDomains = values.simpleSearch[FORM_FIELDS.SENDER_DOMAINS] && values.simpleSearch[FORM_FIELDS.SENDER_DOMAINS].query;
        const isParticipantsDomains = values.simpleSearch[FORM_FIELDS.PARTICIPANTS_DOMAINS] && values.simpleSearch[FORM_FIELDS.PARTICIPANTS_DOMAINS].query;
        const query = toDSL(this.nonEmptyFormValues, undefined, isKeywordExact, isSenderDomains, isParticipantsDomains);

        unsavedSearchAction(
          {
            clientData: {
              type: isSimpleView ? TABS.SIMPLE : TABS.ADVANCED,
              version: CLIENT_DATA_VERSION,
              formValues: this.nonEmptyFormValues,
            },
            query,
          },
          searchId,
        );

        loadUnsavedSearchSummary(query);
      };

      onCancel = () => {
        if (this.props.searchId) {
          goToSearchesShow({ search_id: this.props.searchId });
        } else {
          goToSearchesIndex();
        }
      };

      onListApply = (newItems: String[], type: string) => {
        const currentFormValues = this.props.values.simpleSearch;

        this.props.fields.simpleSearch.onChange({
          ...currentFormValues,
          [type]: {
            ...currentFormValues[type],
            items: newItems.map(item => ({
              id: item,
              value: (
                this.props.searchLists.find(list => list.id === item) || {}
              ).name,
            })),
          },
        });

        this.props.closeModal();
      };

      onListClick = (type: String, criterion: string) => {
        const {
          values: { simpleSearch },
        } = this.props;
        const currentItems =
          simpleSearch[criterion] && simpleSearch[criterion].items
            ? simpleSearch[criterion].items
            : [];

        this.props.openModal(MODAL_MAP.SELECT_LIST, {
          searchLists: this.props.searchLists,
          type,
          criterion,
          onListApply: this.onListApply,
          currentItems,
        });
      };

      get holdReasons() {
        return this.props.reasons.map((reason) => {
          const modifiedReason = { ...reason };

          modifiedReason.value = reason.name;

          return modifiedReason;
        });
      }

      get nonEmptyFormValues() {
        const {
          values: { simpleSearch, advancedSearch },
          isSimpleView,
        } = this.props;
        const form = isSimpleView ? simpleSearch : advancedSearch;

        if (!form) {
          return {};
        }

        const fieldsWithValues = Object.keys(form).reduce((all, field) => {
          const modifiedAll = { ...all };
          const fieldKeys = pickBy(form[field], (value) => {
            if (field === KEYWORDS_EXACT || field === SENDER_DOMAINS || field === PARTICIPANTS_DOMAINS) {
              return value;
            }
            return !!value.length;
          });

          if (Object.keys(fieldKeys).length) {
            modifiedAll[field] = fieldKeys;
          }

          return modifiedAll;
        }, {});

        return pickBy(fieldsWithValues, (value: Object) => Object.keys(value).length);
      }

      get emailMatchDetails() {
        if (
          this.props.isInitialForm ||
          !this.props.emailMatchDetails.loadingTime
        ) {
          return null;
        }

        return this.props.emailMatchDetails;
      }

      listOfTypeExists = (listType: String) =>
        this.props.searchLists.filter(({ type }) => type === listType).length >
        0;

      render() {
        return React.createElement(component, {
          ...this.props,
          ...this.state,
          onSearch: this.onSearch,
          onCancel: this.onCancel,
          onListClick: this.onListClick,
          onListApply: this.onListApply,
          listOfTypeExists: this.listOfTypeExists,
          holdReasons: this.holdReasons,
          nonEmptyFormValues: this.nonEmptyFormValues,
          emailMatchDetails: this.emailMatchDetails,
        });
      }
    }

    return FormViewStateLogic;
  };
}
