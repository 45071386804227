/* eslint-disable no-unused-vars */

import { Component } from 'react';

import { connect } from 'react-redux';
import withMessages from 'component-utilities/intl';

import {
  allImmediately,
  exportRequest,
} from '../../state_managers/ExportsStateManager';
import { updateDropdown } from '../../state_managers/ExportTableUIStateManager';

import { resetFlash } from '../../state_managers/FlashMessagesStateManager';
import { load as loadAccountSettings } from '../../state_managers/AccountSettingsStateManager';
import actionsBase, {
  WithinActionsBasePanel as withinPanel,
} from './ActionsBase';

import CloudExportDarkGray from './images/cloud-export-dark-gray.png';

import { viewLogic, stateLogic, viewContents } from './ExportDeconstructed';

const TYPE = 'export';

@connect(
  ({ exports, exportsTableUI, accountSettings, modals, authentication }) => ({
    exportJobList: exports.jobs,
    isJobsLoading: exports.isLoading,
    completeDropdownView: exportsTableUI.dropdown,
    isProcessing: exports.isProcessing,
    account: accountSettings.account,
    isAccountLoading: accountSettings.isLoading,
    isAccountLoaded: accountSettings.isLoaded,
    modalType: modals.type,
    loggedInAsEmail: authentication.loggedInAs.email,
    }),
  dispatch => ({
    allImmediately: (...args) => {
    dispatch(allImmediately(...args));
    },
    exportRequest: (...args) => {
    dispatch(exportRequest(...args));
    },
    checkCompleteDropdownView: (state = 'reset') => {
    dispatch(updateDropdown(state));
    },
    resetFlash: () => {
    dispatch(resetFlash({ from: 'Export' }));
    },
    loadAccountSettings: () => {
    dispatch(loadAccountSettings());
    },
    }),
)
@withMessages
@actionsBase({
  type: TYPE,
  closedStateImage: CloudExportDarkGray,
  openedStateImage: CloudExportDarkGray,
  onMount() {
  this.props.allImmediately();
  this.props.loadAccountSettings();
  },
  })
@withinPanel
@stateLogic()
export default class Export extends Component {
  render() {
    return viewContents({
      ...this.props,
      ...viewLogic(this.props),
    });
  }
}
