/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { kebabCase } from 'lodash';

import withMessages, { intlPropTypes } from 'component-utilities/intl';
import ManagementList, { ManagementListRow } from 'v-c/ManagementList';

import { jsUi, ROUTES } from '../../global/lib/jsUi';
import Layout from '../../global/components/Layout';
import HeadlineLabel from '../../global/components/HeadlineLabel';
import Breadcrumb from '../../global/components/Breadcrumb';
import VerticalHeightManager from '../../global/components/VerticalHeightManager';

import validateSettingsAccess from './components/validateSettingsAccess';
import styles from './components/Settings.scss';

import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

const tabViews = {
  EMAIL: 'email',
};

const handlers = {
  EMAIL: ROUTES.SETTINGS_COLLECTORS_EMAIL,
};

const mapStateToProps = null;
const mapActionsToProps = {
  setBreadcrumbForSettingsCollectorsPage:
    breadcrumbsActions.settingsCollectorsPage,
};

type Props = {
  setBreadcrumbForSettingsCollectorsPage: Function,
  getMessage: Function,
}

@withMessages
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@validateSettingsAccess('settings.collectors')
export default class Collectors extends Component<Props> {
  static propTypes = {
    ...intlPropTypes,
    setBreadcrumbForSettingsCollectorsPage: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    props.setBreadcrumbForSettingsCollectorsPage();
  }

  render() {
    const { getMessage } = this.props;
    const headlineText = getMessage('app.settings.collectors.tab_name');

    const contentValues = Object.keys(tabViews).map((view) => {
      const getTabText = (path: string) =>
        getMessage(`app.settings.collectors.tabs.${path}.${tabViews[view]}`);

      return {
        view,
        mainLabel: getTabText('tab_name'),
        description: getTabText('description'),
        path: jsUi[handlers[view]].path(),
      };
    });

    return (
      <Layout section="settings collectors">
        <VerticalHeightManager>
          <VerticalHeightManager.Section className={styles['vertical-section']}>
            <header>
              <Breadcrumb />
              <HeadlineLabel text={headlineText} />
            </header>
          </VerticalHeightManager.Section>
          <VerticalHeightManager.Section
            className={styles['vertical-section']}
            maximize
          >
            <div className={styles.wrapper}>
              <ManagementList>
                {contentValues.map(({ view, mainLabel, description, path }) => (
                  <ManagementListRow
                    key={view}
                    name={kebabCase(view)}
                    title={mainLabel}
                    description={description}
                    path={path}
                  />
                ))}
              </ManagementList>
            </div>
          </VerticalHeightManager.Section>
        </VerticalHeightManager>
      </Layout>
    );
  }
}
