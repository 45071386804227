import React from 'react';
import PropTypes from 'prop-types';

import { FormattedHTMLMessage } from 'view-components/utilities/intl';
import UpperSectionAlert from 'view-components/components/Dialog/UpperSection/Alert';
import { LowerSectionText } from 'v-c/Dialog/Components';

import LowerSectionDeleteConfirm from './Components/LowerSectionDeleteConfirm';
import confirmableDialog from './Components/ConfirmableDialog';
import { DialogProps } from 'global/types';

interface Props extends DialogProps {
  isReverting: boolean
}

function UpdateSSOSettingsDialogFn(props: Props) {
  const {
    isConfirmed,
    onConfirm,
    onDismiss,
    toggleConfirmed,
    isReverting,
  } = props;

  let titleID = 'app.dialog.update_sso_settings.message';
  let messageID = 'app.dialog.update_sso_settings.lower_section';

  if (isReverting) {
    titleID = 'app.dialog.update_sso_settings.reverting_title';
    messageID = 'app.dialog.update_sso_settings.reverting_message';
  }

  return (
    <div>
      <UpperSectionAlert>
        <FormattedHTMLMessage id={titleID} />
      </UpperSectionAlert>

      <LowerSectionDeleteConfirm
        buttonText="update"
        {...{ toggleConfirmed, isConfirmed, onConfirm, onDismiss }}
        deleteMessage={
          <FormattedHTMLMessage id="app.dialog.update_sso_settings.confirm_message" />
        }
        additionalTopContent={
          <LowerSectionText>
            <FormattedHTMLMessage id={messageID} />
          </LowerSectionText>
        }
      />
    </div>
  );
}

UpdateSSOSettingsDialogFn.propTypes = {
  isConfirmed: PropTypes.bool,
  isReverting: PropTypes.bool,
  onConfirm: PropTypes.func,
  onDismiss: PropTypes.func,
  toggleConfirmed: PropTypes.func,
};

UpdateSSOSettingsDialogFn.defaultProps = {
  isConfirmed: false,
  isReverting: false,
  onConfirm: () => {},
  onDismiss: () => {},
  toggleConfirmed: () => {},
};

const UpdateSSOSettingsDialog = confirmableDialog(UpdateSSOSettingsDialogFn);

export default UpdateSSOSettingsDialog;
