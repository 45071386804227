/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import withMessages from 'component-utilities/intl';

import LoadingIndicator from 'v-c/LoadingIndicator';

import LoginWrapper from 'v-c/Login/LoginWrapper';

import ChangeForgottenPasswordTemplate from 'v-c/Templates/ChangeForgottenPassword/ChangeForgottenPassword';

import loginLinks from '../../global/lib/loginLinks';
import LoginLogo from '../../global/lib/loginLogo';

import LoginBaseHOC from './components/LoginBase';
import ToggleZendeskHelp from './components/ToggleZendeskHelp';

import {
  verifyPasswordResetToken,
  sendNewPassword,
} from '../../global/state_managers/LoginFormStateManager';

import styles from './styles.scss';

const mapStateToProps = ({ loginForm }: any) => ({
  isVerifyTokenLoading: loginForm.isVerifyTokenLoading,
});

const mapActionsToProps = {
  verifyPasswordResetToken,
  sendNewPassword,
};

type Props = {
  location?: any,
  params: {
    token?: string,
    username?: string
  },
  newPassword?: string,
  confirmNewPassword?: string,
  isVerifyTokenLoading?: Boolean,
  isAccountDeleted?: Boolean,
  siteName?: string,
  getMessage?: Function,
  onValueChange: Function,
  goToLoginPage?: Function,
  supportEmailEnabled?: Boolean,
  termsUrlEnabled?: Boolean,
  verifyPasswordResetToken?: Function,
  sendNewPassword: Function,
}

type State = {
  token: string,
  openForTesting: Boolean,
}

@connect(
  mapStateToProps,
  mapActionsToProps,
)
@ToggleZendeskHelp
@LoginBaseHOC({
  state: {
  newPassword: '',
  confirmNewPassword: '',
  },
  })
@withMessages
export default class ChangePassword extends Component<Props, State> {
  static propTypes = {
    location: PropTypes.shape({
      query: PropTypes.object.isRequired,
    }).isRequired,
    params: PropTypes.object.isRequired,
    newPassword: PropTypes.string.isRequired,
    confirmNewPassword: PropTypes.string.isRequired,
    isVerifyTokenLoading: PropTypes.bool,
    isAccountDeleted: PropTypes.bool.isRequired,
    siteName: PropTypes.string,
    getMessage: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    goToLoginPage: PropTypes.func.isRequired,
    supportEmailEnabled: PropTypes.bool.isRequired,
    termsUrlEnabled: PropTypes.bool.isRequired,
    verifyPasswordResetToken: PropTypes.func.isRequired,
    sendNewPassword: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isVerifyTokenLoading: false,
    siteName: null,
  };

  constructor(props: any) {
    super(props);

    /* eslint-disable no-underscore-dangle */
    if (props.location.query.__test__) {
      this.state = { openForTesting: true };
    } else {
      this.state = { openForTesting: false };

      props.verifyPasswordResetToken(props.params.token);
    }
    /* eslint-enable no-underscore-dangle */
  }

  create = () => {
    if (this.state.openForTesting) {
      console.log('testing mode only!');

      return false;
    }

    const { token } = this.props.params;
    const { newPassword } = this.props;
    this.props.sendNewPassword({
      accountId: this.accountId,
      token,
      password: newPassword,
    });

    return false;
  };

  render() {
    const { openForTesting } = this.state;
    const {
      newPassword,
      confirmNewPassword,
      isVerifyTokenLoading,
      isAccountDeleted,
      siteName,
      supportEmailEnabled,
      termsUrlEnabled,
    } = this.props;

    const templateProps = {
      passwordConfig: {
        value: newPassword,
        onChange: this.props.onValueChange('newPassword'),
      },
      confirmPasswordConfig: {
        value: confirmNewPassword,
        onChange: this.props.onValueChange('confirmNewPassword'),
      },
      isButtonDisabled: false,
      onSubmit: this.create,
      onCancel: this.props.goToLoginPage,
      isProcessing: isVerifyTokenLoading,
      username: this.props.params.username,
    };

    const section = 'change-password';

    if (!openForTesting && isVerifyTokenLoading) {
      return (
        <LoginWrapper
          {...loginLinks()}
          isAccountDeleted={isAccountDeleted}
          siteName={siteName}
          supportEmailEnabled={supportEmailEnabled}
          termsUrlEnabled={termsUrlEnabled}
          viewLogo={<LoginLogo />}
          isPcc={false}
          section={section}
        >
          <div className={styles.loading}>
            <LoadingIndicator orientation="middle" isLoading />
          </div>
        </LoginWrapper>
      );
    }

    return (
      <LoginWrapper
        {...loginLinks()}
        isAccountDeleted={isAccountDeleted}
        siteName={siteName}
        viewLogo={<LoginLogo />}
        isPcc={false}
        section={section}
      >
        <ChangeForgottenPasswordTemplate {...templateProps} />
      </LoginWrapper>
    );
  }
}
