import {
  isFunction,
  isNull,
  isObject,
  isString,
  isUndefined,
  toString as makeString,
} from 'lodash';

export function stringify(value: any) {
  if (isUndefined(value) || isNull(value) || isFunction(value)) {
    throw new SyntaxError(`You can't store this: ${value}`);
  }
  if (isObject(value)) {
    return JSON.stringify(value);
  }
  return makeString(value);
}

export function parseString(value: string) {
  try {
    return JSON.parse(value);
  } catch (e) {
    if (e instanceof SyntaxError && isString(value)) {
      return value;
    }
    throw e;
  }
}

export default function sessionStorage() {
  const storage = window.sessionStorage;

  const setItem = (key: string, value: any) => {
    try {
      storage.setItem(key, stringify(value));
    } catch (e: any) {
      throw new SyntaxError(`For ${key}, ${e.message}`);
    }
  };

  const getItem = (key: string) => parseString(storage.getItem(key));

  const removeItem = (key: string) => storage.removeItem(key);
  const clear = () => storage.clear();

  return {
    setItem,
    getItem,
    removeItem,
    clear,
  };
}
