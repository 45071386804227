import React from 'react';
import PropTypes from 'prop-types';

import { kebabCase } from 'lodash';

import { FormattedHTMLMessage } from 'component-utilities/intl';

import {
  FormTitle,
  FormText,
  FormRow,
  FormItemFull,
} from 'v-c/Forms';

const BASE_KEY = 'app.settings.o365_integration';

type Props = {
  children: any,
  getMessage: Function,
  o365IntegrationDetails: {
    manifestXml: any,
    appTileUrl: string,
    appTileIconUrl: string
  }
}

const Wrapper = ({ children }: Props) => (
  <FormRow>
    <FormItemFull>
      {children}
    </FormItemFull>
  </FormRow>
);
const Item = ({ children }: Props) => (
  <li style={{ marginBottom: 10 }}>
    {children}
  </li>
);

Wrapper.propTypes = { children: PropTypes.node.isRequired };
Item.propTypes = { children: PropTypes.node.isRequired };

export default function Details({ getMessage, o365IntegrationDetails }: Props) {
  const { manifestXml, appTileUrl, appTileIconUrl } = o365IntegrationDetails;

  const MessageLabel = ({ id }: { id: string }) => (
    <FormTitle>{getMessage(`${BASE_KEY}.${id}`)}</FormTitle>
  );

  MessageLabel.propTypes = { id: PropTypes.string.isRequired };

  const renderItem = ([key, value]: any) => (
    <Item key={key}>
      <FormText>
        <strong><FormattedHTMLMessage id={`${BASE_KEY}.${key}`} /></strong>:
        <br />
        <span data-value={kebabCase(key)}>{value}</span>
      </FormText>
    </Item>
  );

  return (
    <div data-section="o365-integration-details">
      <Wrapper>
        <MessageLabel id="implementation_instructions" />
        <FormText>
          <ul>
            <Item><FormattedHTMLMessage id={`${BASE_KEY}.adding_a_tile`} /></Item>
            <Item><FormattedHTMLMessage id={`${BASE_KEY}.adding_an_archive_button`} /></Item>
          </ul>
        </FormText>
      </Wrapper>
      <Wrapper>
        <MessageLabel id="account_integration_details" />
        <FormText>
          <ul>
            {[
              ['manifest_xml', manifestXml],
              ['app_tile_url', appTileUrl],
              ['app_tile_icon_url', appTileIconUrl],
            ].map(renderItem)}
          </ul>
        </FormText>
      </Wrapper>
    </div>
  );
}

Details.displayName = 'Settings/O365Integration/Details';

Details.propTypes = {
  getMessage: PropTypes.func.isRequired,
  o365IntegrationDetails: PropTypes.object.isRequired,
};
