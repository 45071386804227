import { connect } from 'react-redux';

import initFlashConnect from 'component-utilities/flashMessages/flashConnect';
import initFlashMessages from 'component-utilities/flashMessages/flashMessages';

import {
  setActiveViewId,
  resetFlashView,
} from '../state_managers/FlashMessagesStateManager';

import PAGES from '../constants/Pages';
import { ReduxState } from 'global/types';

export { PAGES };

const flashConnect = initFlashConnect((component: any) =>
  connect(
    null,
    { setActiveViewId, resetFlashView },
  )(component),
);
export { flashConnect };

const FlashMessages = initFlashMessages((component: any) =>
  connect(({ flashMessages }: ReduxState) => ({ flashMessages }))(component),
);
export default FlashMessages;
