export const KEYWORDS: string = 'keywords';
export const KEYWORDS_EXACT: string = 'keywords-exact';
export const FROM: string = 'from';
export const SENDER_DOMAINS: string = 'from-domains';
export const PARTICIPANTS: string = 'participants';
export const PARTICIPANTS_DOMAINS: string = 'participants-domains';
export const ADV_PARTICIPANTS: string = 'adv-participants';
export const DATES: string = 'dates';
export const SIZES: string = 'sizes';
export const HOLDS: string = 'holds';
export const ATTACHMENTS: string = 'attachments';
export const LISTS: string = 'lists';
export const KEYWORD_LISTS: string = 'keyword-lists';
export const USER_LISTS: string = 'user-lists';
export const TAGS: string = 'tags';

const Criteria = {
  KEYWORDS,
  KEYWORDS_EXACT,
  FROM,
  SENDER_DOMAINS,
  PARTICIPANTS,
  PARTICIPANTS_DOMAINS,
  ADV_PARTICIPANTS,
  DATES,
  SIZES,
  HOLDS,
  ATTACHMENTS,
  LISTS,
  KEYWORD_LISTS,
  USER_LISTS,
  TAGS,
};

export default Criteria;
