import { contains, camelCase, difference } from 'lodash';
import { Action } from '../reduxStore';

import configs from '../../config/configs';

// eslint-disable-next-line import/no-named-as-default-member
const { BREADCRUMBS } = configs;

export const RESET: string = 'breadcrumbs/reset';
export const CONSTANTS: any = {
  // dashboard
  ACCOUNT_SUMMARY_PAGE: 'breadcrumbs/accountSummaryPage',

  // searches
  SEARCHES_PAGE: 'breadcrumbs/searchesPage',
  SEARCHES_RESULTS_PAGE: 'breadcrumbs/searchesResultsPage',
  SEARCHES_ASSET_PAGE: 'breadcrumbs/searchesAssetPage',
  SEARCHES_SHOW_PAGE: 'breadcrumbs/searchesShowPage',
  SEARCHES_SHOW_PAGE_FROM_INDEX: 'breadcrumbs/searchesShowPageFromIndex',

  // modified search
  MODIFIED_SEARCH_EDIT_PAGE: 'breadcrumbs/modifiedSearchEditPage',
  MODIFIED_SEARCH_EDIT_PAGE_FROM_INDEX:
    'breadcrumbs/modifiedSearchEditPageFromIndex',
  MODIFIED_SEARCH_RESULTS_PAGE: 'breadcrumbs/modifiedSearchResultsPage',
  MODIFIED_SEARCH_ASSET_PAGE: 'breadcrumbs/modifiedSearchAssetPage',

  // new search
  NEW_SEARCH_PAGE: 'breadcrumbs/newSearchPage',
  NEW_SEARCH_RESULTS_PAGE: 'breadcrumbs/newSearchResultsPage',
  NEW_SEARCH_EDIT_PAGE: 'breadcrumbs/newSearchEditPage',

  // unsaved search
  UNSAVED_SEARCH_ASSET_PAGE: 'breadcrumbs/unsavedSearchAssetPage',

  // my archive
  MY_ARCHIVE_PAGE: 'breadcrumbs/myArchivePage',

  // exports
  EXPORTS_PAGE: 'breadcrumbs/exportsPage',

  // hold reasons
  HOLD_REASONS_PAGE: 'breacrumbs/holdReasonsPage',
  HOLD_REASONS_NEW_REASON_PAGE: 'breadcrumbs/holdReasonsNewReasonPage',
  HOLD_REASONS_RESULTS_PAGE: 'breadcrumbs/holdReasonsResultsPage',
  HOLD_REASONS_ASSETS_PAGE: 'breadcrumbs/holdReasonsAssetsPage',

  JOBS_PAGE: 'breadcrumbs/jobsPage',

  // users
  USERS_PAGE: 'breadcrumbs/usersPage',
  USERS_NEW_USER_PAGE: 'breadcrumbs/usersNewUserPage',
  USERS_EDIT_USER_PAGE: 'breadcrumbs/usersEditUserPage',

  // settings
  SETTINGS_COLLECTORS_PAGE: 'breadcrumbs/settingsCollectorsPage',
  SETTINGS_COLLECTORS_EMAIL_PAGE: 'breadcrumbs/settingsCollectorsEmailPage',

  SETTINGS_POLICIES_PAGE: 'breadcrumbs/settingsPoliciesPage',

  SETTINGS_REPORTS_PAGE: 'breadcrumbs/settingsReportsPage',
  SETTINGS_REPORTS_NEW_REPORT_PAGE: 'breadcrumbs/settingsReportsNewReportPage',
  SETTINGS_REPORTS_EDIT_REPORT_PAGE:
    'breadcrumbs/settingsReportsEditReportPage',

  SETTINGS_SECURITY_PAGE: 'breadcrumbs/settingsSecurityPage',

  SETTINGS_O365_INTEGRATION_PAGE: 'breadcrumbs/settingsO365IntegrationPage',

  SETTINGS_ACCOUNT_SETTINGS_PAGE: 'breadcrumbs/settingsAccountSettingsPage',

  SETTINGS_SEARCH_LISTS_PAGE: 'breadcrumbs/settingsSearchListsPage',
  SETTINGS_SEARCH_LISTS_NEW_LIST_PAGE:
    'breadcrumbs/settingsSearchListsNewListPage',
  SETTINGS_SEARCH_LISTS_EDIT_LIST_PAGE:
    'breadcrumbs/settingsSearchListsEditListPage',

  SETTINGS_SSO_PAGE: 'breadcrumbs/settingsSSOPage',

  SETTINGS_AD_SYNC_PAGE: 'breadcrumbs/settingsADSyncPage',
  SETTINGS_AD_SYNC_WIZARD_PAGE: 'breadcrumbs/settingsADSyncWizardPage',
  SETTINGS_AD_SYNC_SUCCESS_PAGE: 'breadcrumbs/settingsADSyncSuccessPage',
  SETTINGS_AD_SYNC_APP_CREDENTIALS_PAGE:
    'breadcrumbs/settingsADSyncAppCredentials',
  SETTINGS_AD_SYNC_ATTRIBUTE_MAPPINGS_PAGE:
    'breadcrumbs/settingsADSyncAttributeMappingsPage',
  SETTINGS_AD_SYNC_AD_GROUPS_PAGE: 'breadcrumbs/settingsADSyncADGroupsPage',
  SETTINGS_AD_SYNC_CONFLICTS_PAGE: 'breadcrumbs/settingsADSyncConflictsPage',
  SETTINGS_AD_SYNC_LOG_PAGE: 'breadcrumbs/settingsADSyncLogPage',
};

interface BreadcrumbsState {
  route: any[],
  isRoot: boolean
}

// Init
export const INITIAL_STATE: BreadcrumbsState = {
  route: [],
  isRoot: false,
};

function validateBreadcrumbs() {
  const actionsWithoutBreadcrumbs = difference(
    Object.keys(BREADCRUMBS),
    Object.keys(CONSTANTS),
  );
  if (actionsWithoutBreadcrumbs.length > 0) {
    console.warn(
      `The following actions don't have breadcrumbs. Verify if they are being used: ${actionsWithoutBreadcrumbs.join(
        ', ',
      )}`,
    );
  }

  const breadcrumbsWithoutActions = difference(
    Object.keys(CONSTANTS),
    Object.keys(BREADCRUMBS),
  );
  if (breadcrumbsWithoutActions.length > 0) {
    console.warn(
      `The following breacrumbs don't have actions. Verify if they are being used: ${breadcrumbsWithoutActions.join(
        ', ',
      )}`,
    );
  }
}

validateBreadcrumbs();

// Helpers

// Actions
export const breadcrumbsActions: any = Object.keys(CONSTANTS).reduce(
  (builtActions, constant) => {
    const modifiedActions = { ...builtActions };

    modifiedActions[camelCase(constant)] = () => ({
      type: CONSTANTS[constant],
      payload: { ...BREADCRUMBS[constant] },
    });

    return modifiedActions;
  },
  {},
);

export function resetBreadcrumbs() {
  return { type: RESET };
}

// Store
export default function BreadcrumbsStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (true) {
    case contains(Object.values(CONSTANTS), type):
      return {
        ...state,
        ...payload,
      };
    case type === RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}