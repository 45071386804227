import ModalContainer from 'v-c/Modals/ModalContainer';
import ModalHeader from 'v-c/Modals/ModalHeader';
import ModalContentLeft from 'v-c/Modals/ModalContentLeft';
import ModalContentCenter from 'v-c/Modals/ModalContentCenter';
import ModalButtons from 'v-c/Modals/ModalButtons';
import ModalText from 'v-c/Modals/ModalText';
import ModalTextDark from 'v-c/Modals/ModalTextDark';
import ModalButtonSpacer from 'v-c/Modals/ModalButtonSpacer';

import ModalHandler from 'v-c/Modals/ModalHandler';

const Modals = {
  ModalHandler,

  ModalContainer,
  ModalHeader,
  ModalContentCenter,
  ModalContentLeft,
  ModalButtons,
  ModalText,
  ModalTextDark,
  ModalButtonSpacer,
};

export {
  ModalHandler,
  ModalContainer,
  ModalHeader,
  ModalContentCenter,
  ModalContentLeft,
  ModalButtons,
  ModalText,
  ModalTextDark,
  ModalButtonSpacer,
}

export default Modals;
