/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { snakeCase } from 'lodash';

import { Link } from 'react-router';

import withMessages, { FormattedHTMLMessage } from 'component-utilities/intl';
import { PrimaryButton, SecondaryButton } from 'v-c/Buttons/Components';

import TooltipGrayIcon from 'view-components/components/Icons/TooltipGrayIcon';
import Tooltip from 'v-c/Tooltip/Tooltip';
import {
  TextLabel,
  InputField,
} from 'view-components/components/Widgets/Components';
import LoadingIndicator from 'view-components/components/LoadingIndicator';

import {
  getCredentials,
  changeCredentialValue,
  updateCredentials,
} from '../../../global/state_managers/ADSyncStateManager';
import { jsUi } from '../../../global/lib/jsUi';

import style from './AppCredentials.scss';
import ADsyncStyle from '../ADSync/ADSync.scss';

const mapStateToProps = ({ adSync, domainInfo }: any) => ({
  accountId: domainInfo.accountId,
  credentials: adSync.credentialData.credentials,
  credentialsLoading: adSync.credentialsLoading,
});

const mapActionsToProps = { getCredentials, changeCredentialValue, updateCredentials };

type Props = {
  getCredentials: Function,
  getMessage: Function,
  credentials: {},
  credentialsLoading: Boolean,
  credentialData: {},
  changeCredentialValue: Function,
  dirty: Boolean,
  fields: any | { 
    clientId: {
      value: string
    },
    clientSecret: {
      value: string
    },
    tenantId: {
      value: string
    }
  },
  values: any | {},
  updateCredentials: Function,
  accountId: string,
}

@withMessages
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@reduxForm(
  {
  form: 'appCredentials',
  fields: ['clientId', 'clientSecret', 'tenantId'],
  enableReinitialize: true,
  },
  ({ adSync: { credentialData } }: any) => ({
    initialValues: {
    ...credentialData.credentials,
    clientSecret: '',
    },
  }),
)
export default class AppCredentials extends Component<Props> {
  static propTypes = {
    credentials: PropTypes.object,
    credentialsLoading: PropTypes.bool,
    credentialData: PropTypes.object,
    getMessage: PropTypes.func.isRequired,
    getCredentials: PropTypes.func.isRequired,
    changeCredentialValue: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    fields: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    updateCredentials: PropTypes.func.isRequired,
    accountId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    manageButtonText: 'button.manage',
    credentials: null,
    credentialData: null,
    credentialsLoading: null,
  };

  constructor(props: Props) {
    super(props);

    props.getCredentials();
  }

  onCredentialChange = (property: any) => ({ target }: any) => {
    this.props.changeCredentialValue(property, target.value);
  };

  get inputs() {
    const { getMessage } = this.props;
    const placeholderText = 'XXXXXXXX–XXXX–XXXX–XXXX–XXXXXXXX';

    return [
      {
        placeholder: placeholderText,
        type: 'client_id',
        variableName: 'clientId',
      },
      {
        placeholder: placeholderText,
        type: 'client_secret',
        variableName: 'clientSecret',
      },
      {
        placeholder: placeholderText,
        type: 'tenant_id',
        variableName: 'tenantId',
      },
    ].map(({ placeholder = '', type, variableName }) => (
      <div key={variableName}>
        <TextLabel className={style['input-label']} required>
          {getMessage(`app.ad_sync.app_credentials.${type}`)}
        </TextLabel>
        <Tooltip
          position="right"
          type="single"
          content={getMessage(`app.ad_sync.app_credentials.${type}_tooltip`)}
        >
          <TooltipGrayIcon />
        </Tooltip>
        <InputField
          {...{
            placeholder,
            'data-field': snakeCase(variableName),
            value: this.props.values[variableName] || '',
            onChange: this.props.fields[variableName].onChange,
          }}
        />
      </div>
    ));
  }

  updateHandler() {
    const { clientId, clientSecret, tenantId } = this.props.fields;

    this.props.updateCredentials({
      remoteDirectoryData: {
        ottoAccountId: this.props.accountId,
        credentials: {
          clientId: clientId.value,
          clientSecret: clientSecret.value,
          tenantId: tenantId.value,
        },
      },
    });
  }

  render() {
    const inputs = this.inputs;
    const { credentialsLoading, getMessage, dirty } = this.props;

    const getCredentialMessage = (text: string) =>
      getMessage(`app.ad_sync.app_credentials.${text}`);

    return (
      <div className={style['app-credentials']}>
        {credentialsLoading && (
          <LoadingIndicator
            orientation="middle"
            text={getMessage('app.general.loading')}
            isLoading
          />
        )}
        {!credentialsLoading && (
          <div>
            <TextLabel
              className={`${style['info-text']} ${style['hint-header']}`}
            >
              <FormattedHTMLMessage id="app.ad_sync.app_credentials.hint_header" />
            </TextLabel>
            {inputs}
          </div>
        )}
        {(!this.props.credentialData && !this.props.credentialsLoading) && (
          <div className={ADsyncStyle['action-buttons']}>
            <PrimaryButton
              disabled={!dirty}
              onClick={() => {
                this.updateHandler();
              }}
              extraClassName={ADsyncStyle.update}
              data-action="update"
            >
              {getMessage('app.button.update')}
            </PrimaryButton>
            <Link data-action="cancel" to={jsUi.settingsAdSyncIndex.path()}>
              <SecondaryButton>
                {getMessage('app.button.cancel')}
              </SecondaryButton>
            </Link>
          </div>
        )}
      </div>
    );
  }
}
