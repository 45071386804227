import CriteriaMultiSelectViewComposer from './Components/CriteriaMultiSelectViewComposer';

type Props = {
  setInitialFormAndOriginalValues: Function
  listElem: {
    values: {
      selectedCriteria: string
    }
  }
  form: {}
}

const HoldsCriteriaView = CriteriaMultiSelectViewComposer({
  init: function init(props: Props) {
    const { listElem: { values }, form } = props;

    props.setInitialFormAndOriginalValues(
      values.selectedCriteria
        ? values
        : {
          ...values,
          ...form,
          selectedCriteria: [],
          relation: 'or',
          isSeparatedAnyLogic: false,
          isAnySelected: false,
        },
    );

    this.state = {
      visibleCriteria: 0,
    };

    this.listResourceName = 'legalHolds';
  },
});

export default HoldsCriteriaView;
