import React, { Component, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv'
import { TextLabel } from 'view-components/components/Widgets/Components';

import { connect } from 'react-redux';

import withMessages, { FormattedHTMLMessage } from 'component-utilities/intl';
import { PrimaryButton, SecondaryButton } from 'v-c/Buttons';

import jsApi from '../../../../global/lib/jsApi';

import {
  resetFlash,
  showSuccessFlash,
} from '../../../../global/state_managers/FlashMessagesStateManager';
import {
  uploadUsers,
  resetBulkUploadUsers,
} from '../../../../global/state_managers/BulkUploadUsersStateManager';

import {
  ModalButtons,
  ModalButtonSpacer,
  ModalContainer,
  ModalContentCenter,
  ModalHeader,
  ModalText,
} from '../../../../global/components/Modals';

import {
  resetUsers,
  loadAllUsers,
} from '../../../../global/state_managers/UsersStateManager';

import PAGES from '../../../../global/constants/Pages';
import { apiReqPromise } from 'global/lib/apiRequest';

const VALID_FLASH_VIEWS = [PAGES.USER_MANAGEMENT];

const BASE_KEY = 'app.users.bulk_upload_modal';
const BASE_FLASH = 'bulk_upload_users';

const mapStateToProps = ({ domainInfo, bulkUploadUsers }: any) => ({
  domainInfo,
  bulkUploadUsers,
});

const mapActionsToProps = {
  resetFlash,
  showSuccessFlash,
  resetUsers,
  loadAllUsers,
  uploadUsers,
  resetBulkUploadUsers,
};

type Props = {
  getMessage: Function,
  bulkUploadUsers: {
    isFinished: boolean,
    data: {
      error: any,
      created: any,
      fileName: string,
      url: string
    },
    isProcessing: boolean
  },
  domainInfo: {
    accountId: string,
    siteName: string
  },
  resetFlash: Function,
  showSuccessFlash: Function,
  closeModal: Function,
  resetUsers: Function,
  loadAllUsers: Function,
  uploadUsers: Function,
  resetBulkUploadUsers: Function,
}

function BulkUploadUsersModal(props: Props) {
  const {
    getMessage,
    bulkUploadUsers,
    domainInfo: { accountId, siteName },
  } = props;

  const [transactionData, setTransactionData] = useState([])
  const csvLink = useRef()
  const uploader = useRef()

  const hasError = useMemo(() => props.bulkUploadUsers.data.error, [props.bulkUploadUsers.data.error]);
  const isPartialError = useMemo(() => hasError && props.bulkUploadUsers.data.created, [hasError, props.bulkUploadUsers.data.created]);
  const isFullError = useMemo(() => hasError && !props.bulkUploadUsers.data.created, [hasError, props.bulkUploadUsers.data.created]);

  const partialOrFullError = isFullError ? 'full' : 'partial';

  useEffect(() => {
    props.resetFlash({ from: 'BulkUploadUsersModal' });
  }, [])

  useEffect(() => {
    const {
      bulkUploadUsers: { isFinished, data },
    } = props;
    if (isFinished) {
      if (!data.error) {
        onClose({ showFlash: true });

        if (isFullError) {
          props.resetBulkUploadUsers();
        }
      }
    }
  }, [props.bulkUploadUsers.isFinished]);

  const onUpload = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event: any) => {
      props.uploadUsers(event.target.result, file.name || '');
    };

    reader.readAsText(file);
  };

  const onClose = ({ showFlash = false }) => {
    if (props.bulkUploadUsers.isFinished) {
      props.resetBulkUploadUsers();

      if (!isFullError) {
        if (showFlash) {
          props.showSuccessFlash(
            `${BASE_FLASH}.upload`,
            VALID_FLASH_VIEWS,
          );
        }

        props.resetUsers();
        props.loadAllUsers();
      }
    }

    props.closeModal({ from: 'BulkUploadUsersModal/onClose' });
  };

  const onDownload = async () => {
    const res = await apiReqPromise(jsApi.bulkUploadUsersTemplate);
    setTransactionData(res.data);
    csvLink.current.link.click();
  }

  return (
    <ModalContainer
      isProcessing={bulkUploadUsers.isProcessing}
      processingMessage={getMessage(`${BASE_KEY}.processing`)}
    >
      <ModalHeader
        type={hasError ? 'warning' : 'normal'}
        onCloseClick={onClose}
      >
        {!hasError && getMessage(`${BASE_KEY}.header`)}
        {hasError && getMessage(`${BASE_KEY}.header_failure`)}
      </ModalHeader>
      <ModalContentCenter>
        <ModalText>
          {!hasError && (
            <p>
              {getMessage(`${BASE_KEY}.content.start`)} <a onClick={onDownload}>{getMessage(`${BASE_KEY}.content.middle`)}</a> {getMessage(`${BASE_KEY}.content.end`)}
            </p>
          )}
          {!hasError && (
            <CSVLink
              data={transactionData}
              filename='template.csv'
              className='hidden'
              ref={csvLink}
              target='_blank'
            />
          )}
          {hasError && (
            <FormattedHTMLMessage
              id={`${BASE_KEY}.content_${partialOrFullError}_failure`}
              values={{
                file: bulkUploadUsers.data.fileName,
                downloadErrorsURL: bulkUploadUsers.data.url,
              }}
            />
          )}
        </ModalText>
      </ModalContentCenter>
      <ModalButtons>
        {!isPartialError && (
          <div>
            <PrimaryButton
              data-action="browse-files"
              onClick={() => {
                if (uploader) {
                  uploader.current.click();
                }
              }}
            >
              {getMessage(`${BASE_KEY}.buttons.upload`)}
            </PrimaryButton>
            <input
              name="upload-users"
              ref={uploader}
              type="file"
              accept=".csv"
              onChange={onUpload}
              style={{ display: 'none' }}
            />
            <ModalButtonSpacer />
            <SecondaryButton
              data-action="cancel-bulk-upload"
              onClick={onClose}
            >
              {getMessage(`${BASE_KEY}.buttons.cancel`)}
            </SecondaryButton>
          </div>
        )}
        {isPartialError && (
          <PrimaryButton
            data-action="okay-bulk-upload-failure"
            onClick={onClose}
          >
            {getMessage(`${BASE_KEY}.buttons.ok`)}
          </PrimaryButton>
        )}
      </ModalButtons>
    </ModalContainer>
  );
}

BulkUploadUsersModal.propTypes = {
  getMessage: PropTypes.func.isRequired,
  bulkUploadUsers: PropTypes.object,
  domainInfo: PropTypes.object,
  resetFlash: PropTypes.func.isRequired,
  showSuccessFlash: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  resetUsers: PropTypes.func.isRequired,
  loadAllUsers: PropTypes.func.isRequired,
  uploadUsers: PropTypes.func.isRequired,
  resetBulkUploadUsers: PropTypes.func.isRequired,
};

const connectedComponent = connect(
  mapStateToProps,
  mapActionsToProps,
)(BulkUploadUsersModal);
export default withMessages(connectedComponent);
