import { ROUTES } from '../../lib/jsUi';
import { ROLES } from '../../../../../../config/roles.json';
import Pages from '../Pages';

import {
  USER_NEEDS_SEARCH_PERMISSION,
  USER_CAN_EDIT_RETENTION_POLICY,
  USER_CAN_USE_COMMENT_API,
} from '../../../config/constants/PageRoles';

const { SEARCHES, CHANGE_PASSWORD } = Pages;
const { SEARCHES_INDEX }: any = ROUTES || {};

export default {
  name: ROLES.REVIEWER,
  tag: 'R',
  homePage: SEARCHES_INDEX,
  visibleMenus: {
    search: [SEARCHES],
    nav: [],
    sub: [],
    other: [CHANGE_PASSWORD],
  },
  enabledPageRoles: [
    USER_NEEDS_SEARCH_PERMISSION,
    USER_CAN_EDIT_RETENTION_POLICY,
    USER_CAN_USE_COMMENT_API,
  ],
};
