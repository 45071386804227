import { includes } from 'lodash';

import reduxStore from '../reduxStore';

export default function domainInfo(store = reduxStore) {
  return store.getState().domainInfo;
}

export function checkIfAccountIsDisabledOrSuspended(status: string) {
  return includes(['suspended', 'disabled'], status);
}

export function isLoaded() {
  return domainInfo().isLoaded;
}

export function isAccountDisabledOrSuspended() {
  return checkIfAccountIsDisabledOrSuspended(domainInfo().status);
}

export function getAccountDetails() {
  const { accountId, siteName } = domainInfo();

  return {
    accountId,
    siteName,
  };
}
