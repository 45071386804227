/* eslint-disable filenames/match-exported */
import { camelCase } from 'lodash';

import promiseTimeout from 'component-lib/promiseTimeout';
import convertDataKeys from 'view-components/lib/convertDataKeys';
import PAGES from '../constants/Pages';
import createReducer from '../lib/createReducer';
import processUpdateValues from './processUpdateValues';
import developerInterface from '../lib/developerInterface';

import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';

import {
  showSuccessFlash,
  showFailureFlash,
} from './FlashMessagesStateManager';

import { LOGIN_PROVIDERS } from '../../../../../config/app.json';

const { NONCE_TTL } = LOGIN_PROVIDERS;

const CLEAR: string = 'accountSettings/clear';

const LOAD_STARTED: string = 'accountSettings/loadStarted';
const LOAD_COMPLETED: string = 'accountSettings/loadCompleted';
const LOAD_FAILED: string = 'accountSettings/loadFailed';

const UPDATE_STARTED: string = 'accountSettings/updateStarted';
const UPDATE_COMPLETED: string = 'accountSettings/updateCompleted';
const UPDATE_FAILED: string = 'accountSettings/updateFailed';

const START_PROVIDER_HREF_LOAD: string = 'accountSettings/startProviderHrefLoad';
const STOP_PROVIDER_HREF_LOAD: string = 'accountSettings/stopProviderHrefLoad';
const PROVIDER_HREF_LOADED: string = 'accountSettings/providerHrefLoaded';
const PROVIDER_HREF_ERROR: string = 'accountSettings/providerHrefError';

interface ASSMPayload {
  account: Object,
  provider: any,
  url: string,
  result: string,
  email: string,
}

interface Account {
  mailbox: string,
  server: string,
  minimalIndexWarning: boolean,
  summary: {
    collectionHistory: Object,
  };
}

interface AccountSettingsState {
  account: Account,
  isLoading: boolean,
  isLoaded: boolean,
  isUpdating: boolean,
  isUpdated: boolean,
  updateFailed: boolean,
  loadFailed: boolean,
  providerToLoad: any,
  ssoLoginLink: any,
}

const initialState: AccountSettingsState = {
  account: {
    mailbox: '',
    server: '',
    minimalIndexWarning: false,
    summary: { collectionHistory: {} },
  },
  isLoading: false,
  isLoaded: false,
  isUpdating: false,
  isUpdated: false,
  updateFailed: false,
  loadFailed: false,
  providerToLoad: null,
  ssoLoginLink: null,
};

export function load() {
  return function* doLoad() {
    yield { type: LOAD_STARTED };

    try {
      const { data } = yield apiReqPromise(jsApi.settingsAccountInfo);

      yield { type: LOAD_COMPLETED, payload: { account: data } };
    } catch (e) {
      console.error(e);

      yield { type: LOAD_FAILED };
    }
  };
}

let nextUpdateFails = false;

developerInterface({
  AccountSettings: {
    nextUpdateFails: () => {
      nextUpdateFails = true;
    },
  },
});

export function update(newValues: object) {
  const BASE_KEY: string = 'account_settings.update';

  function* onUpdateFailure() {
    yield { type: UPDATE_FAILED };
    yield showFailureFlash(BASE_KEY, [PAGES.SETTINGS_ACCOUNT_SETTINGS]);
  }

  return function* doUpdate() {
    yield { type: UPDATE_STARTED };

    // for testing update failures initiated by the Developer Console
    if (nextUpdateFails) {
      yield onUpdateFailure;

      nextUpdateFails = false;

      return;
    }

    try {
      const { data } = yield apiReqPromise(jsApi.settingsUpdateAccountInfo, {
        params: {
          data: {
            values: processUpdateValues(newValues),
          },
        },
      });

      yield { type: UPDATE_COMPLETED, payload: { account: data } };
      yield showSuccessFlash(BASE_KEY, [PAGES.SETTINGS_ACCOUNT_SETTINGS]);
    } catch (e) {
      yield onUpdateFailure;
    }
  };
}

export function startLoadingProviderHref(provider: string) {
  return function* doStartLoadingProviderHref(getState: Function) {
    yield { type: START_PROVIDER_HREF_LOAD, payload: { provider } };

    while (getState().accountSettings.providerToLoad === provider) {
      try {
        const { data: payload } = yield apiReqPromise(
          jsApi.settingsProviderHref,
          {
            urlParams: { provider },
          },
        );

        yield { type: PROVIDER_HREF_LOADED, payload };
      } catch (e) {
        console.error(e);
        yield { type: PROVIDER_HREF_ERROR };
      }

      yield promiseTimeout(NONCE_TTL * 1000);
    }
  };
}

export function stopLoadingProviderHref() {
  return { type: STOP_PROVIDER_HREF_LOAD };
}

export function clear() {
  return { type: CLEAR };
}

const clearSSOTest = {
  ssoLoginLink: null,
  ssoLoginTestResult: null,
  ssoLoginExpectedEmail: null,
  isUserConfirmedForSSO: false,
  isLoadingProviderUrl: false,
};

const FlashMessagesStateManager = createReducer(initialState, {
  [CLEAR]: () => initialState,
  [LOAD_STARTED]: (state: AccountSettingsState) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
    loadFailed: false,
  }),
  [LOAD_COMPLETED]: (state: AccountSettingsState, { account }: ASSMPayload) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    account: convertDataKeys(account, camelCase),
    ...clearSSOTest,
  }),
  [LOAD_FAILED]: (state: AccountSettingsState) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    loadFailed: true,
  }),
  [UPDATE_STARTED]: (state: AccountSettingsState) => ({
    ...state,
    isUpdating: true,
    isUpdated: false,
    updateFailed: false,
  }),
  [UPDATE_COMPLETED]: (state: AccountSettingsState, { account }: ASSMPayload) => ({
    ...state,
    isUpdating: false,
    isUpdated: true,
    account: convertDataKeys(account, camelCase),
    ...clearSSOTest,
  }),
  [UPDATE_FAILED]: (state: AccountSettingsState) => ({
    ...state,
    isUpdating: false,
    isUpdated: false,
    updateFailed: true,
  }),
  [START_PROVIDER_HREF_LOAD]: (state: AccountSettingsState, { provider: providerToLoad }: ASSMPayload) => ({
    ...state,
    providerToLoad,
    ...clearSSOTest,
    isLoadingProviderUrl: true,
  }),
  [PROVIDER_HREF_LOADED]: (
    state: AccountSettingsState,
    {
      url: ssoLoginLink,
      result: ssoLoginTestResult,
      email: ssoLoginExpectedEmail,
    }: ASSMPayload,
  ) => ({
    ...state,
    ssoLoginLink,
    ssoLoginTestResult,
    ssoLoginExpectedEmail,
    isLoadingProviderUrl: false,
    isUserConfirmedForSSO: ssoLoginTestResult === 'ok',
  }),
  [PROVIDER_HREF_ERROR]: (state: AccountSettingsState) => ({
    ...state,
    ssoLoginLink: null,
  }),
  [STOP_PROVIDER_HREF_LOAD]: (state: AccountSettingsState) => ({
    ...state,
    providerToLoad: null,
    ssoLoginLink: null,
  }),
});

export default FlashMessagesStateManager;
