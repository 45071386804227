/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import withMessages from 'component-utilities/intl';
import { TextLabel } from 'view-components/components/Widgets/Components';
import RetinaImage from 'view-components/components/RetinaImage';
import { showDeleteUserDialog } from '../../../state_managers/DialogStateManager';

import '../ActionsBase.scss';

import DeleteGrayIcon from '../images/delete-gray-icon.png';

const deleteTypes = {
  USER: 'deleteUser',
  SEARCH: 'deleteSearch',
  ASSET: 'deleteAsset',
};

const { USER, ASSET } = deleteTypes;

const mapStateToProps = null;
const mapActionsToProps = {
  showDeleteUserDialog,
};

type Props = {
  type: string
  actionText: string,
  deleteText: string,
  onDelete:  Function
  withoutMargin: boolean,
  getMessage:  Function
  showDeleteUserDialog: Function
}

@connect(
  mapStateToProps,
  mapActionsToProps,
)
class DeleteComponent extends Component<Props> {
  static propTypes = {
    type: PropTypes.string.isRequired,
    actionText: PropTypes.string,
    deleteText: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
    withoutMargin: PropTypes.bool,
    getMessage: PropTypes.func.isRequired,
    showDeleteUserDialog: PropTypes.func.isRequired,
  };

  static defaultProps = {
    withoutMargin: false,
    actionText: '',
    deleteText: '',
  };

  toggleDialog = () => {
    switch (this.props.type) {
      case USER:
        this.renderUserDeleteDialog();
        break;
      case ASSET:
        this.props.onDelete();
        break;
      default:
    }
  };

  renderUserDeleteDialog() {
    const callbacks = {
      confirm: () => {
        this.props.onDelete();
      },
    };

    const options = {
      deleteName: this.props.deleteText,
      confirm: 'saved_search',
    };

    this.props.showDeleteUserDialog(callbacks, options);
  }

  render() {
    const { actionText, withoutMargin, getMessage } = this.props;
    const style = withoutMargin ? { marginLeft: 0 } : {};

    return (
      <data>
        <div
          style={style}
          className="action-wrapper"
          onClick={this.toggleDialog}
          data-action="delete-action"
          role="button"
          tabIndex="-1"
        >
          <TextLabel className="action-label">
            <RetinaImage className="action-image" image={DeleteGrayIcon} />
            {`${getMessage('app.table_actions.delete')} ${actionText}`}
          </TextLabel>
        </div>
      </data>
    );
  }
}

const Delete = withMessages(DeleteComponent);
Delete.deleteTypes = deleteTypes;

export default Delete;
