import deepMerge from 'deepmerge';

// load and deep merge all yaml files in locales
const componentsContext = require.context('../../../../../node_modules/view-components/config/locales', true, /.*\.yml?$/);
const componentsMessages = componentsContext.keys().reduce((all, k) => (
  deepMerge(all, componentsContext(k))
), {});

const context = require.context('../../../../../config/locales', true, /.*\.yml?$/);
const messages = context.keys().reduce((all: any[], k: any) => (
  deepMerge(all, context(k))
), componentsMessages);

export { messages };

export default function localizedDataLoader({ LANGUAGE, DATA_PREFIX }: any) {
  return function getLocalizedData({ LOCALE_VALUES, LOCALE_DATA }: any) {
    const localeRoot = messages[LANGUAGE][DATA_PREFIX];

    return LOCALE_DATA.map((_d: any) => {
      const d = { ..._d };

      Object.keys(LOCALE_VALUES).forEach((key) => {
        const filledHash = LOCALE_VALUES[key].replace(/#{}/, d.id);

        d[key] = filledHash.split('.').reduce((p: any, n: any) => p[n], localeRoot);
      });

      return d;
    });
  };
}
