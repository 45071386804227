/* eslint-disable no-undef */
import React, { Component } from 'react';

export default function fixedHeightContent(WrappedComponent: any) {
  return class FixedHeightContent extends Component {
    constructor(props: any) {
      super(props);

      this.interval = window.setInterval(this.setContentHeight, 250);
    }

    componentWillUnmount() {
      window.clearInterval(this.interval);
    }

    setContentHeight = () => {
      const windowHeight = $(window).height();
      const headerHeight = $('[data-app-header]').height();
      const contentArea = $(this.fhc);
      const contentPadding =
        $('.content-view-inner').innerHeight() -
        $('.content-view-inner').height();

      contentArea.css({
        position: 'relative',
        height: `${windowHeight - headerHeight - contentPadding}px`,
      });

      this.setSectionsHeights();
    };

    setSectionsHeights = () => {
      const headerContentHeight = $('[data-content="header"]').height();
      const contentAreaHeight = $(this.fhc).height();
      const personalContentAreaHeight = contentAreaHeight - headerContentHeight;

      $('[data-content="content"]').css({
        height: `${contentAreaHeight - headerContentHeight}px`,
      });

      $('[data-content="personal-searches"]').css({
        height: `${personalContentAreaHeight - 234}px`,
      });

      $('[data-sub-header]').each((idx, node) => {
        const attrValue = $(node).attr('data-sub-header');
        const nodeHeight = $(node).outerHeight();
        const parentHeight = $(node)
          .parent()
          .height();
        const contentNode = $(`[data-sub-content="${attrValue}"]`);

        if (contentNode.length) {
          contentNode.css({
            height: `${parentHeight - nodeHeight}px`,
          });
        }
      });
    };

    render() {
      return (
        <div
          ref={(fhc) => {
            this.fhc = fhc;
          }}
        >
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  };
}
