import { TABS } from 'v-c/Search/SearchTool';

import createReducer from '../lib/createReducer';

export const RESET_ALL: string = 'search-form/reset-all';
export const RESET_SEARCHES: string = 'search-form/reset-searches';
export const RESET_SIMPLE_SEARCH: string = 'search-form/reset-simple-search';
export const RESET_ADVANCED_SEARCH: string = 'search-form/reset-advanced-search';
export const RESET_FILTER: string = 'search-form/reset-filter';
export const UPDATE_CURRENT_VIEW: string = 'search-form/change-view';
export const UPDATE_SIMPLE_SEARCH: string = 'search-form/update-simple-search';
export const UPDATE_ADVANCED_SEARCH: string = 'search-form/update-advanced-search';
export const UPDATE_FILTER: string = 'search-form/update-filter';

interface SearchFormState {
  simpleSearch: any,
  advancedSearch: any,
  filter: any,
  currentView: string,
}

export const INITIAL_STATE: SearchFormState = {
  simpleSearch: {},
  advancedSearch: {},
  filter: {},
  currentView: TABS.SIMPLE,
};

export function resetAll() {
  return { type: RESET_ALL };
}

export function resetSearches() {
  return { type: RESET_SEARCHES };
}

export function resetSimpleSearch() {
  return { type: RESET_SIMPLE_SEARCH };
}

export function resetAdvancedSearch() {
  return { type: RESET_ADVANCED_SEARCH };
}

export function resetFilter() {
  return { type: RESET_FILTER };
}

export function updateCurrentView(newView: string) {
  return { type: UPDATE_CURRENT_VIEW, payload: { newView } };
}

export function updateSimpleSearch(newSimpleSearch: any) {
  return { type: UPDATE_SIMPLE_SEARCH, payload: { newSimpleSearch } };
}

export function updateAdvancedSearch(newAdvancedSearch: any) {
  return { type: UPDATE_ADVANCED_SEARCH, payload: { newAdvancedSearch } };
}

export function updateFilter(newFilter: any) {
  return { type: UPDATE_FILTER, payload: { newFilter } };
}

const SearchFormStateManager = createReducer(() => INITIAL_STATE, {
  [RESET_ALL]: () => ({ ...INITIAL_STATE }),
  [RESET_SEARCHES]: (state: SearchFormState) => ({
    ...state,
    simpleSearch: INITIAL_STATE.simpleSearch,
    advancedSearch: INITIAL_STATE.advancedSearch,
  }),
  [RESET_SIMPLE_SEARCH]: (state: SearchFormState) => ({
    ...state,
    simpleSearch: INITIAL_STATE.simpleSearch,
  }),
  [RESET_ADVANCED_SEARCH]: (state: SearchFormState) => ({
    ...state,
    advancedSearch: INITIAL_STATE.advancedSearch,
  }),
  [RESET_FILTER]: (state: SearchFormState) => ({
    ...state,
    filter: INITIAL_STATE.filter,
  }),
  [UPDATE_CURRENT_VIEW]: (state: SearchFormState, { newView }: any) => ({
    ...state,
    currentView: newView,
  }),
  [UPDATE_SIMPLE_SEARCH]: (state: SearchFormState, { newSimpleSearch }: any) => ({
    ...state,
    simpleSearch: newSimpleSearch,
  }),
  [UPDATE_ADVANCED_SEARCH]: (state: SearchFormState, { newAdvancedSearch }: any) => ({
    ...state,
    advancedSearch: newAdvancedSearch,
  }),
  [UPDATE_FILTER]: (state: SearchFormState, { newFilter }: any) => ({
    ...state,
    filter: newFilter,
  }),
});

export default SearchFormStateManager;
