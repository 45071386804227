/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton, SecondaryButton } from 'v-c/Buttons';
import { FormattedHTMLMessage } from 'react-intl';

import { underscore } from 'inflected';

import {
  FormLabel,
  FormItemLarge,
  FormText,
  FormErrorText,
  FormField,
  FormRow,
  DropdownMedium,
} from 'v-c/Forms';

import styles from '../components/Settings.scss';

import DomainComponent from '../AccountSettings/DomainComponent';
import AddDomainButton from '../AccountSettings/AddDomainButton';
import domainError from '../AccountSettings/Tabs/domainError';
import AccountSettingsButtons from '../components/AccountSettingsButtons';

const BASE_KEY = 'app.settings.account_settings.login_providers';

type Props = {
  buttons: {
    addDomain: {
      visible: Boolean
    },
    testLoginProvider: {
      visible: Boolean
    },
    revertLoginProvider: {
      visible: Boolean
    }
  },
  form: any,
  sections: {
    selfRegistrationDomainMessage: {
      visible: Boolean
    },
    selfRegistrationDomains: {
      visible: Boolean
    },
    loginProviderTestErrors: {
      visible: Boolean
    },
    testSsoButtonLoading: {
      visible: Boolean
    },
    openIDError: {
      visible: Boolean
    }
  },
  getMessage: Function,
  domainToFocus: string
}

export default function Content({ buttons, form, sections, getMessage, domainToFocus }: Props) {
  const sectionComponents = {};
  const buttonComponents = {};

  if (buttons.addDomain.visible) {
    buttonComponents.addDomain = (
      <FormRow>
        <FormItemLarge>
          <AddDomainButton
            onClick={form.onAddNewSelfRegistrationDomain}
            getMessage={getMessage}
          />
        </FormItemLarge>
      </FormRow>
    );
  }

  if (buttons.testLoginProvider.visible) {
    buttonComponents.testLoginProvider = (
      <FormRow>
        <FormItemLarge>
          <FormText>
            <FormattedHTMLMessage
              id={`${BASE_KEY}.messages.login_provider_test`}
            />
          </FormText>
        </FormItemLarge>
        <FormItemLarge>
          <a
            data-action="test-login-provider"
            href={buttons.testLoginProvider.href}
          >
            <PrimaryButton>
              {getMessage(`${BASE_KEY}.test_login_provider`)}
            </PrimaryButton>
          </a>
        </FormItemLarge>
      </FormRow>
    );
  }

  if (buttons.revertLoginProvider.visible) {
    buttonComponents.revertLoginProvider = (
      <FormRow>
        {!buttons.addDomain.visible && (
          <FormItemLarge>&nbsp;</FormItemLarge>
        )}
        <SecondaryButton
          data-action="revert-login-provider"
          onClick={form.onRevertLoginProvider}
          warning
        >
          {getMessage(`${BASE_KEY}.revert_login_provider`)}
        </SecondaryButton>
      </FormRow>
    );
  }

  if (sections.selfRegistrationDomainMessage.visible) {
    sectionComponents.selfRegistrationDomainMessage = (
      <FormText>
        <div data-section="self-registration-domain-message">
          <FormattedHTMLMessage id={`${BASE_KEY}.messages.self_registration_domain_message`} />
        </div>
      </FormText>
    );
  }

  // TODO: get a better key for the self registration domains
  /* eslint-disable react/no-array-index-key */
  if (sections.selfRegistrationDomains.visible) {
    sectionComponents.selfRegistrationDomains = (
      <div data-section="self-registration-domains">
        <FormRow>
          <FormLabel>{getMessage(`${BASE_KEY}.labels.self_registration_domains`)}</FormLabel>
          <FormText>
            {form.selfRegistrationDomains.map((domain, index) => {
              const error = domainError(domain);

              return (
                <DomainComponent
                  key={`domain-${index}`}
                  index={index}
                  onChange={form.onChangeSelfRegistrationDomain(index)}
                  onDelete={form.onRemoveSelfRegistrationDomain(index)}
                  name={domain || ''}
                  error={error}
                  isValid={error === ''}
                  focused={domainToFocus === index || null}
                  isEditable
                  getMessage={getMessage}
                />
              );
            },
            )}
          </FormText>
        </FormRow>
        {buttonComponents.addDomain}
      </div>
    );
  }
  /* eslint-enable react/no-array-index-key */

  if (sections.loginProviderTestErrors.visible) {
    sectionComponents.loginProviderTestErrors = (
      <div data-section="login-provider-test-errors">
        <FormRow>
          <FormItemLarge>
            <FormText>
              <FormattedHTMLMessage
                id={`${BASE_KEY}.messages.login_provider_test_errors.${underscore(sections.loginProviderTestErrors.result)}`}
                values={{ email: sections.loginProviderTestErrors.email }}
              />
            </FormText>
          </FormItemLarge>
        </FormRow>
      </div>
    );
  }

  if (sections.openIDError.visible) {
    sectionComponents.openIDError = (
      <div data-section="open-id-error">
        <FormRow>
          <FormItemLarge>
            <FormErrorText
              field="open-id"
              error={`components.validation_issues.${sections.openIDError.error}`}
            />
          </FormItemLarge>
        </FormRow>
      </div>
    );
  }

  if (sections.testSsoButtonLoading.visible) {
    sectionComponents.loginProviderTestErrors = (
      <div data-section="test-sso-button-loading">
        <FormRow>
          <FormItemLarge>
            <FormText>
              <FormattedHTMLMessage
                id={`${BASE_KEY}.messages.test_sso_button_loading`}
              />
            </FormText>
          </FormItemLarge>
        </FormRow>
      </div>
    );
  }

  const dropdownData = form.selectedLoginProviders.dropdownData.map(item => (
    {
      id: item.id,
      value: getMessage(`${BASE_KEY}.provider.${item.value}`),
    }
  ));

  return (
    <div className={styles.content}>
      <FormRow>
        <FormItemLarge>
          <FormLabel>{getMessage(`${BASE_KEY}.labels.login_method`)}</FormLabel>
          <FormField>
            <DropdownMedium
              id="login-providers"
              selectedId={form.selectedLoginProvider}
              onSelect={form.onSelectLoginProvider}
              disabled={form.selectedLoginProviders.disabled}
              data={dropdownData}
            />
          </FormField>
          {sectionComponents.selfRegistrationDomainMessage}
        </FormItemLarge>
      </FormRow>
      {sectionComponents.openIDError}
      {sectionComponents.loginProviderTestErrors}
      {sectionComponents.testSsoButtonLoading}
      {buttonComponents.testLoginProvider}
      {sectionComponents.selfRegistrationDomains}
      {buttonComponents.revertLoginProvider}

      <AccountSettingsButtons buttons={buttons} getMessage={getMessage} />
    </div>
  );
}

Content.displayName = 'Settings/SingleSignOn/Content';

Content.propTypes = {
  sections: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  buttons: PropTypes.object.isRequired,
  getMessage: PropTypes.func.isRequired,
  domainToFocus: PropTypes.number,
};

Content.defaultProps = {
  domainToFocus: null,
};
