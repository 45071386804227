import React from 'react';
import PropTypes from 'prop-types';

import {
  PlusBlueIcon,
} from 'v-c/Icons';
import {
  FormText,
} from 'v-c/Forms';

import styles from '../components/Settings.scss';

export default function AddDomainButton({ onClick, getMessage }: any) {
  return (
    <FormText>
      <div
        className={styles['add-wrapper']}
        data-action="add-domain"
        onClick={onClick}
        role="button"
        tabIndex="-1"
      >
        <PlusBlueIcon className={styles['email-domain-icon']} />
        <data className={styles['blue-text']}>
          {getMessage('app.settings.account_settings.add_domain')}
        </data>
      </div>
    </FormText>
  );
}

AddDomainButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  getMessage: PropTypes.func.isRequired,
};
