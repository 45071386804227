/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { has } from 'lodash';

import TagAction from 'v-c/Splitview/SplitViewTagAction';

import contextTreeHelper from '../../lib/contextTreeHelper';

import { resetFlash } from '../../state_managers/FlashMessagesStateManager';
import ifFeature from "../IfFeature";

import {
  bulkUpdateReviewStatus,
  searchRelatedJobs,
} from '../../state_managers/BulkActionsStateManager';

import configs from '../../../config/configs';
import { MultiSelect, Params, ReduxState } from 'global/types';

const { QUERY } = configs;

type Props = {
  multiSelect: MultiSelect,
  contextTree: {
    REVIEW_STATUSES: string
  },
  assetId: string,
  stream: {
    query: string
  }
  searchId: string
  resetFlash: Function
  bulkUpdateReviewStatus: Function
  searchRelatedJobs: Function
  params: Params
}

function onTagAssets(props: Props, selectedTag: string) {
  const { stream, searchId, multiSelect } = props;

  props.resetFlash({ from: 'TagAction' });

  props.bulkUpdateReviewStatus({
    searchId,
    newStatus: selectedTag,
    params: {
      query: multiSelect.createDSL(stream.query),
      new_status: selectedTag,
      search_id: searchId,
    },
  });
}

function isReviewUpdateProcessing(props: Props) {
  const jobs = props.searchRelatedJobs(props.params.id);

  return has(jobs.review, 'batch');
}

export { onTagAssets, isReviewUpdateProcessing, QUERY };

const mapStateToProps = ({ bulkActions }: ReduxState) => ({
  bulkActions,
  searchRelatedJobs,
});

const mapActionsToProps = { bulkUpdateReviewStatus, resetFlash };

@connect(
  mapStateToProps,
  mapActionsToProps,
)
@contextTreeHelper
export default class Tag extends Component<Props> {
  static propTypes = {
    multiSelect: PropTypes.object.isRequired,
    contextTree: PropTypes.shape({
      REVIEW_STATUSES: PropTypes.object.isRequired,
    }).isRequired,
    assetId: PropTypes.string,
  };

  static defaultProps = {
    assetId: '',
  };

  render() {
    const {
      multiSelect: { selectedRows, isAllSelected },
      contextTree: { REVIEW_STATUSES },
      assetId,
    } = this.props;

    return (
      <TagAction
        disabled={!selectedRows.length && !isAllSelected}
        reviewStatuses={REVIEW_STATUSES}
        onSelect={(selectedTag: any) => {
          onTagAssets(this.props, selectedTag);
        }}
        isProcessing={isReviewUpdateProcessing(this.props)}
        assetId={assetId}
      />
    );
  }
}
