import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import breadcrumbsConfig from '../../config/configs/breadcrumbs.json';
import Header from './DifferentLayout/Header';
import LeftHandNav from './DifferentLayout/LeftHandNav';
import Dialog from './Dialog';
import ModalView from './Modals/ModalView';
import DocumentViewer from './DocumentViewer';

import './DifferentLayout/Layout.scss';

type Props = {
  section: string
  children: ReactNode
  breadcrumbs: {
    route: string[]
  }
}

export default function DifferentLayout({ section, children, breadcrumbs }: Props) {
  const isViewingPersonalArchive = !!breadcrumbs && breadcrumbs.route.filter((breadcrumb: string) => breadcrumb === breadcrumbsConfig.MY_ARCHIVE_PAGE.route[0]).length;
  return (
    <div data-app="view" className={section}>
      <Header key={isViewingPersonalArchive} isViewingPersonalArchive={isViewingPersonalArchive} />
      {!isViewingPersonalArchive && <LeftHandNav staticView />}
      <section className={!isViewingPersonalArchive ? 'content-view' : 'personal-archive-view'}>
        <div className="content-view-inner">
          {children}
        </div>
      </section>
      <Dialog staticView />
      <ModalView staticView />
      <DocumentViewer staticView />
    </div>
  );
}

DifferentLayout.propTypes = {
  children: PropTypes.node.isRequired,
  section: PropTypes.string,
  breadcrumbs: PropTypes.object.isRequired,
};

DifferentLayout.defaultProps = {
  section: null,
};
