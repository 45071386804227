import Mark from 'mark.js';

export default function highlighting(
  nodes: any,
  keywords: string[],
  inIframe = false,
  additionalOptions = {},
) {
  const clearedNodes = nodes.filter((node: any) => !!node);

  if (clearedNodes.length && keywords.length) {
    const markInstance = new Mark(clearedNodes);
    const regExStr = `(${keywords.join('|')})`;
    const regex = new RegExp(regExStr, 'gi');
    markInstance.markRegExp(regex, {
      exclude: ['mark'],
      ...(inIframe && {
        iframes: true,
        iframesTimeout: 5000,
      }),
      ...additionalOptions,
    });

    return markInstance;
  }

  return null;
}
