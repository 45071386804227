import actionsDirtyCheck from './TableViewActions/actionsDirtyCheck';

import Hold from './TableViewActions/Hold';
import Export from './TableViewActions/Export';
import Search from './TableViewActions/Search/Search';
import Filter from './TableViewActions/Filter';
import Download from './TableViewActions/Download/Download';
import BulkDownload from './TableViewActions/BulkDownload/BulkDownload';
import Delete from './TableViewActions/Delete/Delete';
import Sort from './TableViewActions/Sort';
import NewButton from './TableViewActions/New/NewButton';
import NewWithDropdown from './TableViewActions/New/NewWithDropdown';
import MoreButton from './TableViewActions/More/MoreButton';
import SendEmail from './TableViewActions/SendEmail';
import RolesButton from './TableViewActions/Roles/RolesButton';
import ReviewDropdown from './TableViewActions/ReviewDropdown';
import SortDropdown from './TableViewActions/SortDropdown';
import TagAction from './TableViewActions/Tag';
import Comment from './TableViewActions/Comment';
import StandaloneContainer from './TableViewActions/StandaloneContainer';

const actions = {
  HOLD: 'Hold',
  EXPORT: 'Export',
  SEARCH: 'Search',
  FILTER: 'Filter',
  REVIEW_DROPDOWN: 'ReviewDropdown',
  DOWNLOAD: 'Download',
  BULK_DOWNLOAD: 'BulkDownload',
  DELETE: 'Delete',
  SORT: 'Sort',
  SORT_DROPDOWN: 'SortDropdown',
  NEW: 'NewButton',
  NEW_WITH_DROPDOWN: 'NewWithDropdown',
  MORE_BUTTON: 'MoreButton',
  SEND_EMAIL: 'SendEmail',
  ROLES_BUTTON: 'RolesButton',
  TAG: 'TagAction',
  COMMENT: 'Comment',
};

const TableViewActions = {
  actions,
  actionsDirtyCheck,

  Comment,
  Hold,
  Export,
  Search,
  Filter,
  ReviewDropdown,
  Download,
  BulkDownload,
  Delete,
  Sort,
  SortDropdown,
  NewButton,
  NewWithDropdown,
  MoreButton,
  SendEmail,
  RolesButton,
  TagAction,
  StandaloneContainer,
};

// TODO: get rid of this default export!
export default TableViewActions;

export {
  actions,
  actionsDirtyCheck,
  Hold,
  Export,
  Search,
  Filter,
  ReviewDropdown,
  Comment,
  Download,
  BulkDownload,
  Delete,
  Sort,
  SortDropdown,
  NewButton,
  NewWithDropdown,
  MoreButton,
  SendEmail,
  RolesButton,
  TagAction,
  StandaloneContainer,
};
