import uri from 'urijs';
import { camelCase } from 'lodash';
import { compactInteger } from 'humanize-plus';
import { v4 as uuidv4 } from 'uuid';

import { SortCell } from 'v-c/TableView';
import {
  prettyDate,
  formatTZDate,
  timezoneDate,
} from 'component-utilities/dates/dates';
import convertDataKeys from 'view-components/lib/convertDataKeys';

import createReducer from '../lib/createReducer';
import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';

import CONFIGS from '../../config/configs';
import { PromiseObject } from 'global/reduxStore';

const { DESC } = SortCell.sortKeys;

// constants
const LOAD: string = 'savedSearches/load';
const SORT: string = 'savedSearches/sort';
const SEARCH: string = 'savedSearches/search';
const UPDATE_ACTIVE_COLUMNS: string = 'savedSearches/updateActiveColumns';
const RESET: string = 'savedSearches/reset';

const RESET_SEARCHES: string = 'savedSearches/resetSearches';
const IS_SEARCH_STRING_CHANGED: string = 'savedSearches/isSearchStringChanged';
const IN_PROGRESS: string = 'savedSearches/inProgress';
const LOADED: string = 'savedSearches/loaded';
const UPDATE_SEARCHES: string = 'savedSearches/updateSearches';

const columns = {
  SEARCH_NAME: 'name',
  OWNER: 'created-user',
  LAST_MODIFIED_TIME: 'last-modified-time',
  RESULTS: 'last-run-hits',
  RUN_SEARCH: 'run-search',
};

const { SEARCH_NAME, OWNER, LAST_MODIFIED_TIME, RESULTS, RUN_SEARCH } = columns;

const allColumns = [SEARCH_NAME, OWNER, LAST_MODIFIED_TIME, RESULTS];
const disabledColumns = [SEARCH_NAME, RUN_SEARCH];

interface SavedSearchesState {
  totalCount: number,
  searches: any[],
  searchString: string,
  sortOptions: {
    sortKey: string,
    sortOrder: string,
  },
  tableHelper: {
    columns: any,
    allColumns: string[],
    activeColumns: string[],
    disabledColumns: string[],
  },
  isLoaded: boolean,
  isLoading: boolean,
  isSearchStringChanged: boolean,
  latestCallUUID: null | string,
}

// initial reducer state
export const initialState: SavedSearchesState = {
  totalCount: 0,
  searches: [],
  searchString: '',
  sortOptions: {
    sortKey: columns.LAST_MODIFIED_TIME,
    sortOrder: DESC,
  },
  tableHelper: {
    columns,
    allColumns,
    activeColumns: allColumns,
    disabledColumns,
  },
  isLoaded: false,
  isLoading: false,
  isSearchStringChanged: false,
  latestCallUUID: null,
};

// actions
export function loadSavedSearches(offset = 0, personal = false) {
  return function* doLoadSavedSearches(getState: Function) {
    const {
      sortOptions,
      searchString,
      isSearchStringChanged,
      searches,
      latestCallUUID,
    } = getState().savedSearches;

    const activeCallUUID = latestCallUUID;

    yield { type: LOAD };

    const modifiedOffset: number = isSearchStringChanged ? 0 : offset;

    yield { type: IS_SEARCH_STRING_CHANGED, payload: { isChanged: false } };

    const searchQuery = searchString.length
      ? `contains ${searchString} ${SEARCH_NAME},${OWNER},id`
      : '';
    const isDescOrder = sortOptions.sortOrder === DESC;
    const queryParams = uri('')
      .search({
        offset: modifiedOffset,
        limit: CONFIGS.GENERAL_CONFIGS.INFINITE_SCROLL.LIMIT,
        filter: searchQuery,
        'order-by': `${isDescOrder ? '-' : ''}${sortOptions.sortKey}`,
        personal,
      })
      .toString();

    yield { type: IN_PROGRESS };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.searchesIndex, { queryParams });
      const responseData = convertDataKeys(result.data, camelCase);

      const updatedSearches = responseData.searches.reduce(
        (currentSearches: any, search: any, idx: string) => {
          const modifiedSearch = { ...search };
          const modifiedSearches = [...currentSearches];

          modifiedSearch.lastRunHits = Number(modifiedSearch.lastRunHits || 0);
          modifiedSearch.lastRunHitsCompactFormat = compactInteger(
            modifiedSearch.lastRunHits,
          );
          const lastModifiedTime = timezoneDate(
            modifiedSearch.lastModifiedTime,
          );

          modifiedSearch.lastModifiedTime = formatTZDate(lastModifiedTime);
          modifiedSearch.lastModifiedTimePrettyFormat = prettyDate(
            lastModifiedTime,
          );
          modifiedSearch.owner = modifiedSearch.createdUser;

          modifiedSearches[modifiedOffset + idx] = modifiedSearch;

          return modifiedSearches;
        },
        searches,
      );

      yield {
        type: UPDATE_SEARCHES,
        payload: {
          searches: personal ? responseData.searches : updatedSearches,
          totalCount: responseData.totalCount || 0,
        },
      };
    } catch (e) {
      console.error(e);
    }

    if (getState().savedSearches.latestCallUUID === activeCallUUID) {
      yield { type: LOADED };
    } else {
      yield { type: RESET_SEARCHES };
    }
  };
}

export function sortSavedSearches(sortKey: string, sortOrder: string) {
  return {
    type: SORT,
    payload: { sortOptions: { sortKey, sortOrder }, latestCallUUID: uuidv4() },
  };
}

export function searchSavedSearches(newSearch: any) {
  return function* doSearchSavedSearches(getState: Function) {
    yield {
      type: SEARCH,
      payload: { searchString: newSearch, latestCallUUID: uuidv4() },
    };

    const { searchString, totalCount } = getState().savedSearches;

    if ((searchString.length && !totalCount) || !searchString.length) {
      yield loadSavedSearches();
    }
  };
}

export function updateSavedSearchesActiveColumns(newColumns: any) {
  return { type: UPDATE_ACTIVE_COLUMNS, payload: { newColumns } };
}

export function resetSavedSearches() {
  return { type: RESET, payload: {} };
}

const SavedSearchesStateManager = createReducer(initialState, {
  [SEARCH]: (state: SavedSearchesState, { searchString, latestCallUUID }: any) => ({
    ...state,
    searchString,
    latestCallUUID,
    isSearchStringChanged: true,
    searches: [],
  }),
  [SORT]: (state: SavedSearchesState, { sortOptions, latestCallUUID }: any) => ({
    ...state,
    sortOptions,
    latestCallUUID,
    searches: [],
  }),
  [UPDATE_ACTIVE_COLUMNS]: (state: SavedSearchesState, { newColumns }: any) => ({
    ...state,
    tableHelper: {
      ...state.tableHelper,
      activeColumns: newColumns,
    },
  }),
  [IS_SEARCH_STRING_CHANGED]: (state: SavedSearchesState, { isSearchStringChanged }: any) => ({
    ...state,
    isSearchStringChanged,
  }),
  [IN_PROGRESS]: (state: SavedSearchesState) => ({
    ...state,
    isLoading: true,
  }),
  [LOADED]: (state: SavedSearchesState) => ({
    ...state,
    isLoaded: true,
    isLoading: false,
  }),
  [UPDATE_SEARCHES]: (state: SavedSearchesState, { searches, totalCount }: any) => ({
    ...state,
    searches,
    totalCount,
  }),
  [RESET_SEARCHES]: (state: SavedSearchesState) => ({
    ...state,
    searches: [],
  }),
  [RESET]: (state: SavedSearchesState) => ({
    ...initialState,
    tableHelper: { ...state.tableHelper },
    sortOptions: { ...state.sortOptions },
  }),
});

export default SavedSearchesStateManager;
