import { PromiseObject } from 'global/reduxStore';
import { apiReqPromise } from './apiRequest';
import downloadUrl from './downloadUrl';
import jsApi from './jsApi';

// use this with an action creator for something that lets
// you download from filbert jobs.
export default function createDownloadForJob(id: string, type: string) {
  return function* doDownloadForJob() {
    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.jobsDownload, { urlParams: { id, type } });

      result.data.urls.forEach(downloadUrl);
    } catch (e) {
      console.error(e);
    }
  };
}
