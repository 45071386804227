import { AnyAction, combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import * as reducers from '../reducers';
import { RootState } from '../reduxStore';

// constants
const USER_LOGOUT: string = 'app/userLogout';

// actions
export function clearAppState() {
  return { type: USER_LOGOUT };
}

// reducer
const appReducer: Function = combineReducers({
  ...reducers,
  ...reducers.default,
  form,
});

const initialState: object = appReducer({}, {});

const AppStateManager = (state: RootState, action: AnyAction) => {
  let appState = state;
  // reset redux state on logout
  if (action.type === USER_LOGOUT) {
    // we have to keep the flashMessages store values on init
    appState = {
      ...initialState,
      flashMessages: state.flashMessages,
      domainInfo: state.domainInfo,
    };
  }

  return appReducer(appState, action);
};

export default AppStateManager;