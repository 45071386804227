import { contains, isString } from 'lodash';
import { SortCell } from 'v-c/TableView';

import naturalCmp from './NaturalCmp';

const {
  sortKeys: { ASC, DESC },
} = SortCell;

export function simpleSearch(list, searchString: string, searchFields = []) {
  return list.filter((search) => {
    const fullString = searchFields
      .map(searchField => search[searchField])
      .join('\n');

    return contains(fullString.toLowerCase(), searchString.toLowerCase());
  });
}

export function sort(list: any[], id: string, direction: string) {
  return list.sort((a, b) => {
    let a2 = a[id];
    let b2 = b[id];

    if (isString(a2) && isString(b2)) {
      a2 = a2.toLowerCase();
      b2 = b2.toLowerCase();
    }

    switch (direction) {
      case ASC:
        return naturalCmp(a2, b2);
      case DESC:
        return naturalCmp(b2, a2);
      default:
        return null;
    }
  });
}
