import sizes from './SizesCriteriaView';
import holds from './HoldsCriteriaView';
import advParticipants from './ParticipantsCriteriaView';
import keywords from './KeywordsCriteriaView';
import attachments from './AttachmentsCriteriaView';
import dates from './DatesCriteriaView';
import keywordLists from './KeywordListsCriteriaView';
import userLists from './UserListsCriteriaView';

const Components = {
  sizes,
  holds,
  advParticipants,
  keywords,
  attachments,
  dates,
  keywordLists,
  userLists,
};

export default Components;
