/* eslint-disable filenames/match-regex */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component, ReactNode } from 'react';
import PropTypes from 'prop-types';
/* eslint-enable filenames/match-regex */

import { findDOMNode } from 'react-dom';
const { detect } = require('detect-browser');
const browser = detect();

import { killEvent } from 'event-helpers';
import { snakeCase, camelCase, has } from 'lodash';

import { DATES } from 'data/Criteria';

import CriteriaViews from './CriteriaViews/Components';

import getLocalizedData from '../../lib/getLocalizedData';
import { SEARCH } from '../../../../../../config/app.json';
import { DEFAULT_ADVANCED_SEARCH } from '../../state_managers/UnsavedSearchStateManager';
import { HoldReason, Search, SearchList } from 'global/types';

const { PATH_INDEX_SEPARATOR, CLIENT_DATA_VERSION } = SEARCH;
const MAX_GROUP_DEPTH = 4;
const MIN_DISTANCE_FROM_VIEW_EDGE = 15;
const BASE_KEY = 'app.new_search_form';
const ADD_CRITERIA = 'add_criteria';
const ADD_GROUP = 'add_group';

const {
  INITIAL_DATA: {
    form: { defaults },
  },
  CRITERIA_TYPES,
  CRITERIA_LISTS,
} = DEFAULT_ADVANCED_SEARCH;

export { defaults, DATES, BASE_KEY };

type Props = {
  fields: {
    form: {
      value: string
    }
  }
  onSearch: Function
  searchLists: SearchList[]
  formView: string
  unsavedSearch: Search
  updateListElem: Function
  reasons: HoldReason[]
  searchListsByType: Function
  changeRelation: Function
  isViewOnly: boolean
  addListElem: Function
  deleteListElem: Function
  onCriteriaChange: Function
  criteriaAndGroupsNumber: {
    criteria: string
  }
  isMaskVisible: boolean
  getMessage: Function
  setOpenedCriteriaIndex: Function
  setMaskLayerVisibilityFor: Function
  loadLegalHoldReasons: Function
  loadSearchLists: Function

}

export function viewLogic(props: Props, refs: ReactNode[]) {
  function isValidForm() {
    const {
      criteriaAndGroupsNumber: { criteria: criterion },
      isMaskVisible,
    } = props;

    return !!criterion && !isMaskVisible;
  }

  function addListElemOptions(idx: string) {
    const { getMessage } = props;
    const label = getMessage(`${BASE_KEY}.add_criteria_or_group`);
    const indexParts = idx.split(PATH_INDEX_SEPARATOR);
    const generateDropdownList = () =>
      [ADD_CRITERIA, ADD_GROUP].map((menu) => {
        const menuElem: any = {
          id: menu,
          value: getMessage(`${BASE_KEY}.${menu}`),
        };

        if (menu === ADD_GROUP && indexParts.length === MAX_GROUP_DEPTH) {
          menuElem.forceDisabled = true;
        }

        if (menu === ADD_CRITERIA) {
          menuElem.submenu = CRITERIA_TYPES.map((criterion) => {
            const submenuItem: any = {
              id: criterion,
              value: getMessage(`${BASE_KEY}.${snakeCase(criterion)}`),
            };

            if (criterion === 'lists') {
              submenuItem.submenu = CRITERIA_LISTS.map((list) => {
                const type = list.split('-')[0];

                return {
                  id: list,
                  value: getMessage(
                    `${BASE_KEY}.lists_submenu.${snakeCase(list)}`,
                  ),
                  forceDisabled: props.searchListsByType(type).length === 0,
                };
              });
            }

            return submenuItem;
          });
        }

        return menuElem;
      });

    return {
      label,
      dropdownList: generateDropdownList(),
    };
  }

  function calculateOpenedListElemPosition({
    position,
    height,
    isShowMaskLayer = true,
    index = -1,
  }: any) {
    if (!position) {
      return;
    }

    const $wrapper = $(findDOMNode(refs.editWrapper));
    const currentPadding = parseInt($wrapper.css('padding-bottom'), 10);
    if (currentPadding < height) {
      $wrapper.css({ paddingBottom: height });

      if (browser.name === 'ie') {
        $wrapper.children().css({ paddingBottom: height });
      }
    }

    const wrapperHeight = $wrapper.outerHeight();
    const wrapperScrollTop = $wrapper.scrollTop();
    const wrapperOffsetTop = $wrapper.offset().top;
    const listElemTopPosition = position.top - wrapperOffsetTop;
    const listElemBottomPosition =
      listElemTopPosition + height + wrapperScrollTop;
    const wrapperBottomPosition = wrapperScrollTop + wrapperHeight;
    const maxBottomPosition = wrapperHeight - MIN_DISTANCE_FROM_VIEW_EDGE;
    const minTopPosition = wrapperScrollTop + MIN_DISTANCE_FROM_VIEW_EDGE;
    let scrollTop = listElemTopPosition + wrapperScrollTop;

    if (wrapperBottomPosition < listElemBottomPosition) {
      scrollTop -= maxBottomPosition - height;
    } else if (
      minTopPosition >
      position.top - (wrapperOffsetTop + wrapperScrollTop)
    ) {
      scrollTop -= MIN_DISTANCE_FROM_VIEW_EDGE;
    }

    if (scrollTop) {
      $wrapper.animate(
        {
          scrollTop,
        },
        300,
      );
    }

    props.setOpenedCriteriaIndex(index);
    if (isShowMaskLayer) {
      props.setMaskLayerVisibilityFor(isShowMaskLayer);
    }
  }

  function listElemOptions() {
    return {
      calculateOpenedListElemPosition,
      onCriteriaChange: props.onCriteriaChange,
      setMaskLayerVisibilityFor: props.setMaskLayerVisibilityFor,
      deleteListElem: props.deleteListElem,
      addListElem: (groupIdx: number, type: string) => {
        if (CriteriaViews[camelCase(type)] || type === 'add_group') {
          props.addListElem(groupIdx, type);
        }
      },
      changeParentRelation: (index: number) => (e: any) => {
        killEvent(e);
        if (!props.isViewOnly) {
          props.changeRelation(index);
        }
      },
      updateListElem: props.updateListElem,
    };
  }

  function listElemResources() {
    const attachmentsMapping = ({ id, value }: any) => {
      const fileTypes = props.getMessage(
        `app.selections.adv_attachments.${id}_types`,
      );

      return {
        id,
        value,
        additionalValue: ` ${fileTypes}, ...`,
      };
    };
    const attachments = getLocalizedData(
      'advAttachmentsValuesSelections',
      false,
      props,
    ).map(attachmentsMapping);
    const modifiedLegalHolds = props.reasons.map(({ enumId, name }: any) => ({
      id: enumId,
      value: name,
    }));

    const keywordLists = props.searchListsByType('keyword');
    const userLists = props.searchListsByType('user');

    return {
      legalHolds: modifiedLegalHolds,
      attachments,
      keywordLists,
      userLists,
    };
  }

  return {
    ...props,
    isValidForm: isValidForm(),
    addListElemOptions,
    calculateOpenedListElemPosition,
    listElemOptions,
    listElemResources: listElemResources(),
    searchButtonText: 'app.button.search',
    saveButtonText: 'app.button.save',
    isCancelButtonEnabled: false,
  };
}

export function stateLogic({ validateStringifiedDateValue, toDSL }: any) {
  return function AdvancedSearchFormStateLogicComposer(component: any) {
    class AdvancedSearchFormStateLogic extends Component<Props> {
      static propTypes = {
        unsavedSearch: PropTypes.object.isRequired,
        onSearch: PropTypes.func,
        searchLists: PropTypes.array.isRequired,
        fields: PropTypes.object.isRequired,
        loadLegalHoldReasons: PropTypes.func.isRequired,
        formView: PropTypes.string.isRequired,
        loadSearchLists: PropTypes.func.isRequired,
      };

      static defaultProps = {
        onSearch: null,
      };

      constructor(props: Props) {
        super(props);

        this.state = {
          isMaskVisible: false,
          openedCriteriaIndex: null,
          setWrapperToScrollable: true,
        };
        this.cachedCriteriaValues = {};

        props.loadLegalHoldReasons({ loadIfNecessary: true });
        props.loadSearchLists();
      }

      onCriteriaChange = (UUID: string, formValues: any) => {
        this.cachedCriteriaValues[UUID] = formValues;
      };

      setOpenedCriteriaIndex = (newIndex: string) => {
        this.setState({ openedCriteriaIndex: newIndex });
      };

      setMaskLayerVisibilityFor = (newValue = false, index = -1) => {
        const newStates = {};

        if (
          (!newValue && index === this.state.openedCriteriaIndex) ||
          newValue
        ) {
          newStates.isMaskVisible = newValue;
        }

        this.setState({
          ...newStates,
          setWrapperToScrollable: !newValue,
        });
      };

      get formDefaults() {
        return this.props.unsavedSearch.data.clientData.defaults || defaults;
      }

      get isViewOnly() {
        return this.props.formView === 'view';
      }

      validateNewFormDateValues = () => {
        const {
          listElemTypes: { CRITERIA, GROUP },
        } = this.formDefaults;
        const { formValues } = this.props.unsavedSearch.data.clientData;

        const validateDateValues = ({ children }: any) => {
          Object.keys(children).forEach((childKey) => {
            const child = children[childKey];

            switch (child.type) {
              case CRITERIA: {
                if (child.criteriaType === DATES) {
                  ['from', 'to'].forEach((type) => {
                    child.values[type] = validateStringifiedDateValue(
                      child.values[type],
                    );
                  });
                }
                break;
              }
              case GROUP: {
                validateDateValues(child);
                break;
              }
              default: {
                validateDateValues(child);
              }
            }
          });
        };

        if (formValues['0']) {
          validateDateValues(formValues['0']);
        }

        return formValues;
      };

      initialFormValues = () => {
        const {
          clientData: { formValues },
        } = this.props.unsavedSearch.data;
        let initialForm = { ...DEFAULT_ADVANCED_SEARCH.INITIAL_DATA.form.list };

        if (has(formValues, '0')) {
          initialForm = { ...initialForm, ...this.validateNewFormDateValues() };
        }

        return initialForm;
      };

      handleSearch = () => {
        const formValues = this.props.fields.form.value;

        this.props.onSearch({
          clientData: {
            type: 'advanced',
            formValues,
            items: {},
            version: CLIENT_DATA_VERSION,
            defaults: this.formDefaults,
          },
          isResultsView: true,
          UIValues: {},
          query: toDSL(formValues),
        });
      };

      searchListsByType = (listType: string) =>
        this.props.searchLists
          .filter(({ type }: any) => type === listType)
          .map(({ id, name }: any) => ({ id, value: name }));

      render() {
        return React.createElement(component, {
          ...this.props,
          ...this.state,
          validateNewFormDateValues: this.validateNewFormDateValues,
          initialFormValues: this.initialFormValues,
          setMaskLayerVisibilityFor: this.setMaskLayerVisibilityFor,
          setOpenedCriteriaIndex: this.setOpenedCriteriaIndex,
          searchListsByType: this.searchListsByType,
          handleSearch: this.handleSearch,
          onCriteriaChange: this.onCriteriaChange,
          cachedCriteriaValues: this.cachedCriteriaValues,
          formDefaults: this.formDefaults,
          isViewOnly: this.isViewOnly,
        });
      }
    }

    return AdvancedSearchFormStateLogic;
  };
}
