import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { snakeCase } from 'lodash';
import { browserHistory } from 'react-router';

import PrimaryButton from 'v-c/Buttons/PrimaryButton';
import { LinkLabel, TextLabel } from 'v-c/Widgets/Components';
import LoadingIndicator from 'v-c/LoadingIndicator';

import withMessages, { FormattedHTMLMessage } from 'component-utilities/intl';

import RetinaImage from 'v-c/RetinaImage';

import { URLS } from '../../config/configs';

import Layout from '../../global/components/Layout';
import VerticalHeightManager from '../../global/components/VerticalHeightManager';
import Breadcrumb from '../../global/components/Breadcrumb';
import HeadlineLabel from '../../global/components/HeadlineLabel';
import StatusArea from './ADSync/StatusArea';
import ActionButtons from './ADSync/ActionButtons';
import { jsUi } from '../../global/lib/jsUi';

import InfoImage from './ADSync/images/info.png';

import {
  forceSync,
  deleteSync,
  resumeSync,
  pauseSync,
} from '../../global/state_managers/ADSyncStateManager';
import {
  getStatus,
  updateStatus,
} from '../../global/state_managers/ADSyncStateManager';
import { showDeleteADSyncDialog } from '../../global/state_managers/DialogStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import style from './ADSync/ADSync.scss';

import { flashConnect, PAGES } from '../../global/components/FlashMessages';

import validateSettingsAccess from './components/validateSettingsAccess';

import { AD_SYNC } from '../../config/constants';

const { DELETE_AD_SYNC } = AD_SYNC;

const mapStateToProps = ({ adSync, adWizard }: any) => ({
  adSync,
  adWizard,
});

const mapActionsToProps = {
  forceSync,
  deleteSync,
  resumeSync,
  pauseSync,
  getStatus,
  updateStatus,
  showDeleteADSyncDialog,
  setBreadcrumbForSettingsAdSyncPage: breadcrumbsActions.settingsAdSyncPage,
};

type Props = {
  initialData: {},
  settings: any,
  headlineText: string,
  manageButtonText: string,
  warningText: string,
  infoTitleText: string,
  loadingText: string,
  infoSubtitleText: string,
  infoBullets: string,
  infoButtonText: string,
  infoLearnMoreText: string,
  infoLearMoreLink: string,
  type: string,
  adSync: {
    isLoading: Boolean
    status: {
      syncStatus: string
    }
    setupDone: Boolean
  },
  adWizard: {},
  getMessage: Function,
  forceSync: Function,
  deleteSync: Function,
  resumeSync: Function,
  pauseSync: Function,
  getStatus: Function,
  updateStatus: Function,
  showDeleteADSyncDialog: Function,
  setBreadcrumbForSettingsAdSyncPage: Function
}

@withMessages
@flashConnect(PAGES.AD_SYNC, 'ADSync')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@validateSettingsAccess('settings.ad_sync')
export default class ADSync extends Component<Props> {
  static propTypes = {
    initialData: PropTypes.object,
    settings: PropTypes.array,
    headlineText: PropTypes.string,
    manageButtonText: PropTypes.string,
    warningText: PropTypes.string,
    infoTitleText: PropTypes.string,
    loadingText: PropTypes.string,
    infoSubtitleText: PropTypes.string,
    infoBullets: PropTypes.string,
    infoButtonText: PropTypes.string,
    infoLearnMoreText: PropTypes.string,
    infoLearMoreLink: PropTypes.string,
    type: PropTypes.string,
    adSync: PropTypes.object,
    adWizard: PropTypes.object,
    getMessage: PropTypes.func.isRequired,
    forceSync: PropTypes.func.isRequired,
    deleteSync: PropTypes.func.isRequired,
    resumeSync: PropTypes.func.isRequired,
    pauseSync: PropTypes.func.isRequired,
    getStatus: PropTypes.func.isRequired,
    updateStatus: PropTypes.func.isRequired,
    showDeleteADSyncDialog: PropTypes.func.isRequired,
    setBreadcrumbForSettingsAdSyncPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    settings: [
      {
        name: 'syncLog',
        countColoringEnabled: false,
        dataAction: 'manage-sync-log',
        path: jsUi.settingsAdSyncSyncLog.path(),
      },
      {
        name: 'syncConflicts',
        countColoringEnabled: true,
        dataAction: 'manage-sync-conflicts',
        path: jsUi.settingsAdSyncSyncConflicts.path(),
      },
      {
        name: 'attributeMappings',
        countColoringEnabled: false,
        dataAction: 'manage-attribute-mappings',
        path: jsUi.settingsAdSyncAttributeMappings.path(),
      },
      {
        name: 'appCredentials',
        countColoringEnabled: false,
        dataAction: 'manage-app-credentials',
        path: jsUi.settingsAdSyncAppCredentials.path(),
      },
    ],
    headlineText: 'app.breadcrumb.ad_sync',
    manageButtonText: 'app.button.manage',
    warningText: 'app.ad_sync.warning_msg',
    loadingText: 'app.ad_sync.loading',
    infoTitleText: 'app.ad_sync.info.title',
    infoSubtitleText: 'app.ad_sync.info.subtitle',
    infoBullets: 'app.ad_sync.info.bullets',
    infoButtonText: 'app.ad_sync.info.button',
    infoLearnMoreText: 'app.ad_sync.info.learn_more',
    infoLearMoreLink: URLS.AD_SYNC.LEARN_MORE,
    type: DELETE_AD_SYNC,
    initialData: null,
    adSync: null,
    adWizard: null,
  };

  constructor(props: Props) {
    super(props);

    props.setBreadcrumbForSettingsAdSyncPage();
    props.getStatus();
  }

  getSettingText = (setting: any, key: string) =>
    this.props.getMessage(`app.ad_sync.${snakeCase(setting.name)}.${key}`);

  settingCounter(setting: any) {
    const settingData = this.props.adSync[setting.name];
    let counter;
    if (settingData) {
      counter = settingData.number;
    }
    if (settingData && counter) {
      const numberClass =
        setting.countColoringEnabled && counter > 0 ? style.error : '';
      return (
        <TextLabel className={`${style['label-span']} ${numberClass}`}>
          &nbsp;({counter})
        </TextLabel>
      );
    }
    return '';
  }

  settingWarning = (setting: any) => {
    const settingData = this.props.adSync[setting.name];
    if (settingData && settingData.needsAttention) {
      return (
        <TextLabel className={`${style['label-span']} ${style.error}`}>
          &nbsp; {this.props.getMessage(this.props.warningText)}
        </TextLabel>
      );
    }
    return '';
  };

  syncNow = () => {
    this.props.forceSync();
  };

  togglePause = () => {
    if (this.props.adSync.status.syncStatus === 'paused') {
      this.props.updateStatus('success');
      this.props.resumeSync();
    } else {
      this.props.updateStatus('paused');
      this.props.pauseSync();
    }
  };

  toggleDialog = () => {
    switch (this.props.type) {
      case DELETE_AD_SYNC:
        this.renderADSyncDeleteDialog();
        break;
      default:
        this.renderADSyncDeleteDialog();
    }
  };

  learnMore = () => {
    window.open(this.props.infoLearMoreLink, '_blank');
  };

  goToWizard = () => {
    this.setState({
      adWizard: { ...this.props.adWizard, wizardPage: 0 },
    });
    jsUi.settingsAdSyncAdWizard.goTo();
  };

  renderADSyncDeleteDialog = () => {
    const callbacks = {
      confirm: () => {
        this.props.deleteSync();
      },
    };

    const options = {
      deleteName: 'Azure Ad Sync',
      confirm: 'delete_ad_sync',
    };

    this.props.showDeleteADSyncDialog(callbacks, options);
  };

  render() {
    const {
      getMessage,
      adSync: { isLoading, setupDone },
    } = this.props;

    const settingItems = this.props.settings.map((setting: any) => (
      <div className={style['settings-row']} key={setting.path}>
        <TextLabel className={style['label-h4']}>
          {this.getSettingText(setting, 'name')}
          {this.settingCounter(setting)}
          {this.settingWarning(setting)}
        </TextLabel>
        <PrimaryButton
          extraClassName={style['manage-button']}
          data-action={setting.dataAction}
          onClick={() => {
            browserHistory.push(setting.path);
          }}
        >
          {getMessage(this.props.manageButtonText)}
        </PrimaryButton>
        <div className={style.description}>
          {this.getSettingText(setting, 'description')}
        </div>
      </div>
    ));
    return (
      <Layout section="settings ad-sync main-body-ad-sync">
        <VerticalHeightManager className="content-wrapper">
          <VerticalHeightManager.Section>
            <header>
              <Breadcrumb {...this.props} />
              <HeadlineLabel text={getMessage(this.props.headlineText)} />
            </header>
            {!isLoading &&
              setupDone && (
                <div className={style.settings}>
                  <ActionButtons
                    {...this.props}
                    statusData={this.props.adSync}
                    syncHandler={this.syncNow}
                    deleteHandler={this.toggleDialog}
                    pauseHandler={this.togglePause}
                  />
                  <div className={style.clearer} />
                </div>
              )}
          </VerticalHeightManager.Section>
          <VerticalHeightManager.Section maximize>
            {isLoading && (
              <LoadingIndicator
                orientation="middle"
                text={getMessage(this.props.loadingText)}
                isLoading
              />
            )}
            {!isLoading &&
              !setupDone && (
                <div className={style['settings-rows']}>
                  <div className={style['settings-info']}>
                    <div className={style['info-title']}>
                      {getMessage(this.props.infoTitleText)}
                    </div>
                    <div className={style['info-image']}>
                      <RetinaImage image={InfoImage} />
                    </div>
                    <div className={style['info-bullets']}>
                      <TextLabel className={style['info-subtitle']}>
                        {getMessage(this.props.infoSubtitleText)}
                      </TextLabel>
                      <ul>
                        <FormattedHTMLMessage id={this.props.infoBullets} />
                      </ul>
                    </div>
                    <PrimaryButton
                      onClick={() => this.goToWizard()}
                      data-action="setup_ad"
                      extraClassName={style['info-button']}
                    >
                      {getMessage(this.props.infoButtonText)}
                    </PrimaryButton>
                    <LinkLabel
                      className={style['info-link']}
                      onClick={this.learnMore}
                      data-action="learn_more"
                    >
                      {getMessage(this.props.infoLearnMoreText)}
                    </LinkLabel>
                  </div>
                </div>
              )}
            {!isLoading &&
              setupDone && (
                <div>
                  <StatusArea statusData={this.props.adSync} />
                  <div className={style['settings-rows']}>{settingItems}</div>
                </div>
              )}
          </VerticalHeightManager.Section>
        </VerticalHeightManager>
      </Layout>
    );
  }
}
