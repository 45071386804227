import { ROUTES } from '../../lib/jsUi';
import { ROLES } from '../../../../../../config/roles.json';
import Pages from '../Pages';

import {
  USER_CAN_EDIT_SAVED_SEARCHES,
  USER_CAN_DOWNLOAD_OR_SEND_MESSAGES,
  USER_CAN_DOWNLOAD_ATTACHMENTS,
  USER_CAN_REQUEST_EXPORT,
  USER_CAN_EDIT_RETENTION_POLICY,
  USER_CAN_USE_COMMENT_API,
} from '../../../config/constants/PageRoles';

const { JOBS, EXPORTS, NEW_SEARCH, SEARCHES, SEARCHES_EDIT, CHANGE_PASSWORD, SETTINGS } = Pages;
const { SEARCHES_INDEX }: any = ROUTES || {};

export default {
  name: ROLES.SEARCH_ADMINISTRATOR,
  tag: 'SA',
  homePage: SEARCHES_INDEX,
  visibleMenus: {
    search: [SEARCHES],
    nav: [EXPORTS, JOBS],
    sub: [NEW_SEARCH],
    other: [CHANGE_PASSWORD, SETTINGS, SEARCHES_EDIT],
  },
  enabledPageRoles: [
    USER_CAN_EDIT_SAVED_SEARCHES,
    USER_CAN_DOWNLOAD_OR_SEND_MESSAGES,
    USER_CAN_DOWNLOAD_ATTACHMENTS,
    USER_CAN_REQUEST_EXPORT,
    USER_CAN_EDIT_RETENTION_POLICY,
    USER_CAN_USE_COMMENT_API,
  ],
};
