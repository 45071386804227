import { camelCase } from 'lodash';

import promiseTimeout from 'component-lib/promiseTimeout';
import hasExpired from 'view-components/lib/hasExpired';
import convertDataKeys from 'view-components/lib/convertDataKeys';
import buildPollingIntervalState from 'component-lib/buildPollingIntervalState';

import { apiReqPromise } from '../lib/apiRequest';
import jsApi from '../lib/jsApi';
import mergeNewJobs from '../lib/mergeNewJobs';

import { Action, PromiseObject } from '../reduxStore';

interface JobsStateManagerState {
  jobs: any[],
  isLoading: boolean,
  isReady: boolean,
  isProcessing: boolean,
  nextPoll: {
    timeOfLastRetrieval: null | any,
    inSeconds: number
  },
}

export const INITIAL_STATE = {
  jobs: [],
  isLoading: false,
  isReady: false,
  isProcessing: false,
  nextPoll: {
    timeOfLastRetrieval: null,
    inSeconds: 0, // we are fresh, so poll immediately
  },
};

/**
 * State Managers that work with our jobs (Filbert, audit service, etc) are
 * very similar. This generator builds the base framework for a state manager
 * that works with a subset of jobs.
 *
 * It exports a set of constants, standard retrieval actions,
 * and a reducer that covers the important messages
 * you'll be receiving from the dispatcher.
 */
export default function JobsStateManagerGenerator({
  name,
  types,
  processJob,
}: any = {}) {
  const constants = {
    RESET: `${name}/reset`,
    IS_LOADING: `${name}/all-loading`,
    IS_READY: `${name}/all-ready`,
    ALL_SUCCESS: `${name}/all-success`,
    ALL_FAILURE: `${name}/all-failure`,
    SOME_SUCCESS: `${name}/some-success`,
    IS_PROCESSING: `${name}/is-processing`,
    DONE_PROCESSING: `${name}/done-processing`,
    PROCESSING_SUCCESS: `${name}/processing-success`,
    PROCESSING_FAILED: `${name}/processing-failed`,
  };

  const allSuccess = (jobs: any) => ({
    type: constants.ALL_SUCCESS,
    payload: { jobs },
  });

  const someSuccess = (jobs: any) => ({
    type: constants.SOME_SUCCESS,
    payload: { jobs },
  });

  const allImmediately = () =>
    function* doAllImmediately(getState: Function) {
      yield { type: constants.IS_LOADING };
      const { timeOfLastRetrieval } = getState()[name].nextPoll;

      try {
        const urlParams: any = { types: JSON.stringify(types) };
        if (timeOfLastRetrieval) {
          urlParams.updated_after = timeOfLastRetrieval
            .subtract(1, 'minute')
            .format('YYYY-MM-DDTHH:mm:ssZ');
        }
        const result: PromiseObject = yield apiReqPromise(jsApi.jobsIndex, { urlParams });

        if (timeOfLastRetrieval) {
          // we only retrieved the latest data
          yield someSuccess(result.data);
        } else {
          // we retrieved everything
          yield allSuccess(result.data);
        }

        yield { type: constants.IS_READY };
      } catch (e) {
        console.error(e);

        yield { type: constants.ALL_FAILURE, payload: e };
      }
    };

  const allWhenOptimal = () =>
    function* doAllWhenOptimal(getState: Function) {
      const {
        [name]: {
          nextPoll: { inSeconds },
        },
      } = getState();

      yield { type: constants.IS_LOADING };
      yield promiseTimeout(inSeconds * 1000);

      yield allImmediately();
    };

  return {
    constants,
    actions: { allSuccess, someSuccess, allImmediately, allWhenOptimal },
    reducer: (state = INITIAL_STATE, { type, payload }: Action) => {
      switch (type) {
        case constants.IS_LOADING:
          return { ...state, isLoading: true };
        case constants.IS_PROCESSING:
          return { ...state, isProcessing: true };
        case constants.IS_READY:
          return { ...state, isLoading: false, isReady: true };
        case constants.DONE_PROCESSING:
        case constants.PROCESSING_SUCCESS:
        case constants.PROCESSING_FAILED:
          return { ...state, isProcessing: false };
        case constants.ALL_FAILURE:
          return {
            ...state,
            isProcessing: false,
            isLoading: false,
            isError: true,
            isReady: true,
          };
        case constants.ALL_SUCCESS:
        case constants.SOME_SUCCESS: {
          const jobs = payload.jobs.reduce((all: any[], job: any) => {
            if (types.indexOf(job.type) !== -1 && !hasExpired(job['updated-at'])) {
              return [...all, processJob(convertDataKeys(job, camelCase))];
            }
            return all;
          }, []);

          return {
            ...state,
            ...buildPollingIntervalState(
              type === constants.ALL_SUCCESS
                ? jobs
                : mergeNewJobs(state.jobs, jobs),
            ),
          };
        }
        default:
          return state;
      }
    },
  };
}
