/* globals $, setInterval, clearInterval, window */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import { WithDeveloperShowFlash } from '../lib/DeveloperShowFlash';

import Header from './DifferentLayout/Header';
import LeftHandNav from './DifferentLayout/LeftHandNav';
import MainBody from './DifferentLayout/MainBody';
import Dialog from './Dialog';
import ModalView from './Modals/ModalView';
import DocumentViewer from './DocumentViewer';
import LayoutContainer from './DifferentLayout/LayoutContainer';
import MainBodyContainer from './DifferentLayout/MainBodyContainer';

import './Layout.scss';

// haxx
let navHeight;
let height;

type Props = {
  section: string
  staticView: string
}

@WithDeveloperShowFlash
export default class Layout extends Component<Props> {
  static propTypes = {
    section: PropTypes.string.isRequired,
    staticView: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    staticView: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      navHeight,
      height: null,
    };
  }

  componentDidMount() {
    const intervalCode = () => {
      // TODO: get this working without requiring needing to get the ref
      // of a React component instead of a raw DOM node.
      navHeight = navHeight || $(findDOMNode(this.header)).outerHeight();
      height = $(window).height() - navHeight;
      const { navHeight: stateNavHeight, height: stateHeight } = this.state;

      if (navHeight !== stateNavHeight || height !== stateHeight) {
        this.setState({ navHeight, height });
      }
    };

    intervalCode();

    this.interval = setInterval(intervalCode, 250);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { section, staticView } = this.props;

    return (
      <LayoutContainer section={this.props.section}>
        <Header
          ref={(header) => {
            this.header = header;
          }}
        />
        <MainBodyContainer {...this.state} staticView={staticView}>
          <LeftHandNav staticView={staticView} />
          <MainBody section={section} staticView={staticView}>
            {this.props.children}
          </MainBody>
        </MainBodyContainer>
        <Dialog staticView={staticView} />
        <ModalView staticView={staticView} />
        <DocumentViewer staticView={staticView} />
      </LayoutContainer>
    );
  }
}
