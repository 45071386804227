/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { camelCase, kebabCase } from 'lodash';

import withMessages, { intlPropTypes } from 'component-utilities/intl';
import { TextLabel } from 'view-components/components/Widgets/Components';

import configs from '../../../../config/configs';

import {
  showViewer,
  hideViewer,
} from '../../../state_managers/DocumentViewerStateManager';

import { viewLogic, userCanDownloadAttachments, stateLogic } from './artifact';

const {
  GENERAL_CONFIGS: { DEFAULT_ATTACHMENT },
} = configs;

const mapActionsToProps = { showViewer, hideViewer };

export type Props = {
  asset?: any
  getMessage: Function
  showViewer?: Function
  hideViewer?: Function
}

// TODO: turn into function component
@withMessages
@connect(
  null,
  mapActionsToProps,
)
@stateLogic()
export default class MetadataTab extends Component<Props> {
  static propTypes = {
    asset: PropTypes.object,
    ...intlPropTypes,
  };

  static defaultProps = {
    asset: null,
  };

  render() {
    const { asset } = this.props;
    const { tabContent } = viewLogic(this.props);

    return (
      <div className="accordion-view meta-data">
        {asset &&
          tabContent.map(({ type, value, classValue }) => {
            let newValue = value || asset.data[camelCase(type)];
            const label = this.props.getMessage(`app.asset_display.${type}`);

            if (
              type === 'attachments' &&
              (!asset.data.attachmentCount || !userCanDownloadAttachments())
            ) {
              return null;
            }

            if (type === 'attachments' && value.length) {
              newValue = value.map(({ url, attachment, comma }: any) => (
                <span key={url}>
                  <a href={url} download={attachment || DEFAULT_ATTACHMENT}>
                    {attachment || DEFAULT_ATTACHMENT}
                  </a>
                  {comma}
                </span>
              ));
            }

            return (
              <TextLabel key={type} className={`metadata-item ${classValue}`}>
                <data className="metadata-label">{label}</data>
                {': '}
                <data className="metadata-content" data-value={kebabCase(type)}>
                  {newValue}
                </data>
              </TextLabel>
            );
          })}
      </div>
    );
  }
}
