/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { killEvent } from 'event-helpers';

import { SortCell } from 'view-components/components/TableView';
import withMessages from 'component-utilities/intl';

import { DropdownList, TextLabel } from 'v-c/Widgets/Components';
import RetinaImage from 'v-c/RetinaImage';

import ArrowDownGray from 'v-c/TableView/images/arrow-down-gray.png';
import ArrowDownBlue from 'v-c/TableView/images/arrow-down-blue.png';
import ArrowUpBlue from 'v-c/TableView/images/arrow-up-blue.png';

import actionsBase from './ActionsBase';
import './Sort.scss';
import { ReduxState, SearchResults } from 'global/types';

const { ASC, DESC } = SortCell.sortKeys;

const mapStateToProps = ({ searchResults }: ReduxState) => ({ searchResults });

const sortPropTypes = {
  sort: PropTypes.shape({
    sortOptions: PropTypes.object.isRequired,
    sortAction: PropTypes.func.isRequired,
    sortList: PropTypes.array.isRequired,
    sortKeys: PropTypes.object.isRequired,
  }).isRequired,
};
const propTypes = {
  actionHelper: PropTypes.shape({ ...sortPropTypes }).isRequired,
};

const TYPE = 'sort';
const CLOSED_STATE_IMAGE = ArrowDownGray;
const OPENED_STATE_IMAGE = ArrowDownGray;

type Props = {
  isActionOpened: boolean
  onActionClick: Function
  onSortClick: Function
  searchResults: SearchResults
  onWrapperClick: Function
  closeActions: Function
  getMessage: Function
  actionHelper: {
    sort: {
      sortOptions: {},
      sortAction: Function,
      sortList: {}[],
      sortKeys: {},
    }
  }
}

type State = {
  sortOrder: string
  selectedSortType: string
  streamId: string
}

@withMessages
@actionsBase({
  type: TYPE,
  closedStateImage: CLOSED_STATE_IMAGE,
  openedStateImage: OPENED_STATE_IMAGE,
  })
@connect(
  mapStateToProps,
  null,
)
export default class Sort extends Component<Props, State> {
  static propTypes = { ...propTypes };

  constructor(props: Props) {
    super(props);

    const { sortOptions }: any = this.sortHelper;

    this.state = {
      selectedSortType: sortOptions.sortKey,
      sortOrder: sortOptions.sortOrder,
      streamId: props.searchResults.stream.id,
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const updatedState: any = {};

    // If a user selects a new search, reset the sort state
    if (prevState.streamId !== nextProps.searchResults.stream.id) {
      updatedState.streamId = nextProps.searchResults.stream.id;
      updatedState.sortOrder = 'desc';
      updatedState.selectedSortType = 'date';
    }

    return updatedState;
  }

  onSelectSortType = (id: string, value: any) => {
    this.props.closeActions();
    const { sortOrder } = this.state;

    this.sort(value, sortOrder);

    this.setState({
      selectedSortType: value,
      sortOrder,
    });
  };

  get sortHelper() {
    return this.props.actionHelper.sort;
  }

  sort(sortKey: string, sortOrder: string) {
    const { sortKeys, sortAction } = this.sortHelper;

    sortAction(sortKeys[sortKey], sortOrder);
  }

  changeSortOrder = (e: any) => {
    killEvent(e);

    const { sortOrder, selectedSortType } = this.state;
    const newOrder = sortOrder === ASC ? DESC : ASC;

    this.sort(selectedSortType, newOrder);
    this.setState({ sortOrder: newOrder });

    this.props.onWrapperClick();
    this.props.closeActions();
  };

  render() {
    const { selectedSortType, sortOrder } = this.state;
    const preImage = sortOrder === ASC ? ArrowDownBlue : ArrowUpBlue;

    return (
      <div
        onClick={this.props.onWrapperClick}
        data-action={`${TYPE}-action-wrapper`}
        className="action-wrapper"
        role="button"
        tabIndex="-1"
      >
        <TextLabel
          className="active-action-label"
          data-sort-type={selectedSortType}
          data-action={`${TYPE}-action`}
          onClick={this.props.onActionClick}
        >
          <RetinaImage
            className="action-image"
            image={preImage}
            data-sort-order={sortOrder}
            data-action="change-sort-order"
            onClick={this.changeSortOrder}
          />
          {this.props.getMessage(`app.assets.columns.${selectedSortType}`)}
        </TextLabel>
        {this.props.isActionOpened && (
          <div className="sort-action-wrapper">
            <DropdownList
              widgetId="sort-list"
              onSelectedValue={this.onSelectSortType}
              list={this.sortHelper.sortList}
            />
          </div>
        )}
      </div>
    );
  }
}
