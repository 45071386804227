import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { jsUi } from '../../../global/lib/jsUi';

import routeLeaveHook from '../../../global/lib/routeLeaveHook';

import { loadAllUsers } from '../../../global/state_managers/UsersStateManager';
import {
  resetError,
  saveUnsavedSearch,
  bodyForSaveUnsavedSearch,
} from '../../../global/state_managers/UnsavedSearchStateManager';

import SearchDetailsForm from '../components/SearchDetailsView';

import stateLogic from './SaveNewSearchDeconstructed';

type Props = {
  onSave: Function
}

const mapStateToProps = ({ unsavedSearch, searchResults }: any) => ({
  searchQuery: unsavedSearch.query,
  unsavedClientData: unsavedSearch.data.clientData,
  searchErrors: unsavedSearch.isSaveFailed,
  isSearchSaving: unsavedSearch.isSaving,
  searchType: unsavedSearch.data.clientData.type,
  returnCount: searchResults.returnCount,
});
const mapActionsToProps = {
  loadAllUsers,
  resetError,
  saveUnsavedSearch,
};

@routeLeaveHook
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@stateLogic({
  goToNewSearchIndex: jsUi.newSearchIndex.goTo,
  unsavedSearchForSave: bodyForSaveUnsavedSearch,
  })
export default class SaveNewSearch extends Component<Props> {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
  };

  render() {
    return (
      <SearchDetailsForm
        {...this.props}
        isNewSearch
        canEditFields
        onSave={this.props.onSave}
        isRawSearch={false}
      />
    );
  }
}
