import React, { Component, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withMessages from 'component-utilities/intl';
import LoadingIndicator from 'v-c/LoadingIndicator';

import Layout from '../../global/components/Layout';

import Loaded from './ADSync/SyncConflict/Loaded';

import validateSettingsAccess from './components/validateSettingsAccess';

import { getSyncConflicts } from '../../global/state_managers/ADSyncStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import './ADSync/ADSync.scss';

const mapStateToProps = ({ adSync }: any) => ({
  syncConflicts: adSync.syncConflicts,
  syncConflictsAreLoading: adSync.syncConflictsAreLoading,
});

const mapActionsToProps = {
  getSyncConflicts,
  setBreadcrumbForSettingsADSyncConflictsPage:
    breadcrumbsActions.settingsAdSyncConflictsPage,
};

type Props = {
  syncConflicts: {
    conflictEntries: any,
    searchString: string
  },
  syncConflictsAreLoading: Boolean,
  getMessage: Function,
  getSyncConflicts: Function,
  setBreadcrumbForSettingsADSyncConflictsPage: Function,
}

function ADSyncConflictsComponent(props: Props) {
  const myRef = useRef(null);

  useEffect(() => {
    props.setBreadcrumbForSettingsADSyncConflictsPage();
    props.getSyncConflicts();
  }, [])

  const {
    getMessage,
    syncConflicts: { conflictEntries, searchString },
    syncConflictsAreLoading,
  } = props;

  return (
    <div className="sync-conflicts" ref={myRef}>
    <Layout section="ad-sync">
      {syncConflictsAreLoading && (
        <LoadingIndicator
          orientation="middle"
          text={getMessage('app.general.loading')}
          isLoading
        />
      )}
      {!syncConflictsAreLoading && (
        <Loaded
          returnCount={conflictEntries.length}
          searchString={searchString}
          {...props.syncConflicts}
        />
      )}
    </Layout>
  </div>
  )
}

ADSyncConflictsComponent.propTypes = {
  syncConflicts: PropTypes.object.isRequired,
  syncConflictsAreLoading: PropTypes.bool.isRequired,
  getMessage: PropTypes.func.isRequired,
  getSyncConflicts: PropTypes.func.isRequired,
  setBreadcrumbForSettingsADSyncConflictsPage: PropTypes.func.isRequired,
};

const withMessagesComponent = withMessages(ADSyncConflictsComponent);
const validatedComponent = validateSettingsAccess('settings.ad_sync')(withMessagesComponent);
export default connect(mapStateToProps, mapActionsToProps)(validatedComponent);
