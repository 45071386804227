import { includes, isObject, omit } from 'lodash';

import { ATTACHMENT_TYPES, TAG_TYPES } from 'v-c/Dropdowns/Filter/config';

import {
  moment,
  DATE_FORMATS,
  getTZDifference,
} from 'component-utilities/dates/dates';
import Dates from 'data/selections/date.json';
import DSLFields from 'data/DSLFields';
import Criteria from 'data/Criteria';

import configs from '../../config/configs';

import parseSearchStringValue from './ParseSearchStringValue';
import messagesOnly from './MessageDSLOnly';

import { getReasonsAttributeId } from './contextTreeHelper';
import removeQuoteWrapsIfExists from './removeQuoteWrapsIfExists';

const {
  KEYWORDS,
  FROM,
  PARTICIPANTS,
  DATES,
  SIZES,
  HOLDS,
  TAGS,
  ATTACHMENTS,
  KEYWORDS_EXACT,
  SENDER_DOMAINS,
  PARTICIPANTS_DOMAINS,
} = Criteria;
const { DSL: DSLConfigs } = configs;

const { FILE_SIZES } = DSLConfigs;

export { FILE_SIZES, TAG_TYPES };

export function convertDate(value: string, type: string) {
  let convertedDate = moment(value).endOf('day');

  if (type === 'start') {
    convertedDate = moment(value).startOf('day');
  }

  convertedDate = convertedDate.add(-getTZDifference(), 's');

  return `"${convertedDate.format(DATE_FORMATS.DSL)}"`;
}

export function convertDatesValues(dates: {
  type: string,
  to: string,
  from: string
}) {
  const modifiedDates = { ...dates };

  if (modifiedDates) {
    const { DSLValues } = Dates.find(({ id }) => id === modifiedDates.type);
    const { from, to } = DSLValues;

    if (to) {
      // Set the 'to' date for the specific range
      // because we have to use range between 00:00:00 - 23:59:59 time
      modifiedDates.to = convertDate(
        modifiedDates.to || modifiedDates.from,
        to,
      );
    }

    if (from) {
      modifiedDates.from = convertDate(modifiedDates.from, from);
    }
  }

  return modifiedDates;
}

export function generateStringTypeDSL(dsl: {
  query: string[],
  items: any[]
}, criteria: string, dslKey: string, doStem: boolean, useDomain: boolean) {
  const mapper = (value: any) => {
    if (value.length) {
      const fieldMapper = (field: string) => {
        let modifiedDslKey = dslKey;
        let newField = doStem ? field : `${field}.exact`;
        if (useDomain) {
          newField = `${field}.domain`;
        }
        if (!modifiedDslKey) {
          const textBetweenquotes = new RegExp(/"([^"]+)"/);
          modifiedDslKey = !textBetweenquotes.test(value) ? 'contains' : 'contains-phrase';
        }
        const dslValue = removeQuoteWrapsIfExists(value);
        return `(${modifiedDslKey} ${newField} "${dslValue}")`;
      };

      return (DSLFields[criteria] || criteria).map(fieldMapper).join(' ');
    }

    return null;
  };
  const filter = (value: string) => value;
  let DSLValues: string[] = [];

  if (dsl.query) {
    DSLValues = DSLValues.concat(dsl.query.map(mapper));
  }

  if (dsl.items) {
    DSLValues = DSLValues.concat(
      dsl.items.map((item) => {
        if (item.id.length) {
          const fieldItemMapper = (field: string) => {
            const newField = doStem ? field : `${field}.exact`;
            return `(list-op or contains ${newField} ${item.id})`;
          };
          return DSLFields[criteria].map(fieldItemMapper).join(' ');
        }

        return null;
      }),
    );
  }

  return `(or ${DSLValues.filter(filter).join(' ')})`;
}

export function generateHoldTypesDSL(dsl: { query: string[] }) {
  const DSLValues = dsl.query.reduce((all: string[], value: string) => {
    if (value.length) {
      return [...all, `(equal ${getReasonsAttributeId()} "${value}")`];
    }

    return all;
  }, []);

  return `(or ${DSLValues.join(' ')})`;
}

export function generateRangeTypeDSL(selectedSizes, criteria: string, DSLSources) {
  const sizesDSL: string[] = [];
  DSLSources.forEach(({ id, DSLValues }: any) => {
    const { name } = DSLValues;
    const from = selectedSizes.from || DSLValues.from;
    const to = selectedSizes.to || DSLValues.to;
    const fieldMapper = (field: string) => {
      let DSLString = `(${name} ${field} ${from}`;
      if (to) DSLString += ` ${to}`;
      return `${DSLString})`;
    };

    if (includes(selectedSizes, id) || selectedSizes.criteria === id) {
      sizesDSL.push(DSLFields[criteria].map(fieldMapper).join(' '));
    }
  });

  return `(or ${sizesDSL.join(' ')})`;
}

export function generateTagTypeDSL(criteria: any, tagConfig: any) {
  const {
    searchId,
    REVIEW_STATUSES: { REVIEWED, NEEDS_REVIEW },
    getReviewAttributeId,
  } = tagConfig;

  function queryMaker(tagName: string) {
    return `(equal ${getReviewAttributeId(tagName)} "${searchId}")`;
  }

  function dslValues() {
    return criteria.query.map((criterion: string) => {
      switch (criterion) {
        case TAG_TYPES.ASSET_REVIEWED:
          return queryMaker(REVIEWED);
        case TAG_TYPES.ASSET_NEEDS_REVIEW:
          return queryMaker(NEEDS_REVIEW);
        case TAG_TYPES.NO_REVIEW_STATE:
          return `(and (not ${queryMaker(REVIEWED)}) (not ${queryMaker(
            NEEDS_REVIEW,
          )}))`;
        default:
          return '';
      }
    });
  }

  return `(or ${dslValues().join(' ')})`;
}

export function generateAttachmentTypeDSL(criteria: string) {
  const greaterThanDSL = `(greater-than ${DSLFields[ATTACHMENTS]} 0)`;
  const equalDSL = `(equal ${DSLFields[ATTACHMENTS]} 0)`;
  const dslValues = [];

  switch (criteria) {
    case ATTACHMENT_TYPES.ALL_EMAILS:
      dslValues.push(greaterThanDSL, equalDSL);
      break;
    case ATTACHMENT_TYPES.WITH_ATTACHMENTS:
      dslValues.push(greaterThanDSL);
      break;
    case ATTACHMENT_TYPES.WITHOUT_ATTACHMENTS:
      dslValues.push(equalDSL);
      break;
    default:
      return '';
  }

  return `(or ${dslValues.join(' ')})`;
}

export default function convertSimpleSearchIntoSearchDSLStructure(
  query: any,
  tagConfig: any,
  isKeywordExact: boolean,
  isSenderDomains: boolean,
  isParticipantsDomains: boolean,
) {
  const DSL: string[] = [];
  // keywords-exact gets removed here because keywords-exact is
  // used different than every other criteria, it is only used
  // on keywords and passed as the param 'isKeywordExact' 
  Object.keys(omit(query, [KEYWORDS_EXACT, SENDER_DOMAINS, PARTICIPANTS_DOMAINS])).forEach((criteria) => {
    const criteriaValue = query[criteria];

    if (criteriaValue.length || isObject(criteriaValue)) {
      const doStem = criteria !== KEYWORDS || (criteria === KEYWORDS && !isKeywordExact);
      const useDomain = (criteria === FROM && isSenderDomains) ||
                          (criteria === PARTICIPANTS && isParticipantsDomains);
      switch (criteria) {
        case KEYWORDS:
        case FROM:
        case PARTICIPANTS:
          if (criteriaValue.query) {
            criteriaValue.query = parseSearchStringValue(criteriaValue.query);
          }
          DSL.push(generateStringTypeDSL(criteriaValue, criteria, undefined, doStem, useDomain));
          break;
        case ATTACHMENTS:
          DSL.push(generateAttachmentTypeDSL(criteriaValue.query));
          break;
        case DATES:
          DSL.push(
            generateRangeTypeDSL(
              convertDatesValues(criteriaValue),
              criteria,
              Dates,
            ),
          );
          break;
        case SIZES:
          DSL.push(
            generateRangeTypeDSL(criteriaValue.query, criteria, FILE_SIZES),
          );
          break;
        case HOLDS:
          DSL.push(generateHoldTypesDSL(criteriaValue));
          break;
        case TAGS:
          DSL.push(generateTagTypeDSL(criteriaValue, tagConfig));
          break;
        default:
          throw new Error(`unknown criteria: ${criteria}`);
      }
    }
  });

  if (DSL.length > 1) {
    return messagesOnly(`(and ${DSL.join(' ')})`);
  }

  return messagesOnly(DSL.join(' '));
}
