/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { findDOMNode } from 'react-dom';

import { kebabCase, snakeCase, contains } from 'lodash';

import highlightText from 'v-c/Text/HighlightText';
import {
  TableView,
  TableHeader,
  TableBody,
  TableRow,
} from 'view-components/components/TableView';

import toggleInArray from 'component-lib/toggleInArray';

import TableViewActions from '../TableViewActions';
import { getLoggedInAs } from '../../state_managers/AuthenticationStateManager';

import './Content.scss';
import { isPageRoleEnabled, pageRoles } from '../../lib/userRights';
import { Search, SearchResults } from 'global/types';

type Props = {
  tableType: string,
  hiddenColumns?: string[],
  visibleTableActions: string[],
  withMultipleSelection?: boolean,
  selectedRows?: any[],
  getMessage: Function,
  searchResults?: SearchResults,
}

type State = {
  openedAction: string
  search: string
  selectedRows: any[]
  withVerticalScrollbar: boolean
}

export default class TableViewContent extends Component<Props, State> {
  static propTypes = {
    tableType: PropTypes.string.isRequired,
    hiddenColumns: PropTypes.array,
    visibleTableActions: PropTypes.array.isRequired,
    withMultipleSelection: PropTypes.bool,
    selectedRows: PropTypes.array,
    getMessage: PropTypes.func.isRequired,
    searchResults: PropTypes.object,
  };

  static defaultProps = {
    hiddenColumns: null,
    withMultipleSelection: false,
    selectedRows: null,
    searchResults: null,
  };

  constructor(props: Props) {
    super(props);

    this.ALL_CHECKBOX = '__allCheckbox__';

    this.state = {
      selectedRows: [],
      search: '',
      withVerticalScrollbar: false,
      openedAction: '',
    };

    const { tableType } = props;

    window.addEventListener('resize', this.fixTableHeaderCellsWidth);

    this.localizedColumnNames = {};

    this.allColumns.forEach((column) => {
      this.localizedColumnNames[column] = props.getMessage(
        `app.${snakeCase(tableType)}.columns.${snakeCase(column)}`,
      );
    });
  }

  componentDidMount() {
    this.fixTableHeaderCellsWidth();
  }

  componentDidUpdate() {
    this.fixTableHeaderCellsWidth();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.fixTableHeaderCellsWidth);
  }

  onActionClick = (action: string) => {
    const { openedAction } = this.state;
    let newOpenedAction = action;

    if (openedAction === action) newOpenedAction = '';

    this.setState({ openedAction: newOpenedAction });
  };

  onColumnSelectorChange = (widgetId: string, selectedValue: any) => {
    this.delegateActiveColumnsChange(
      toggleInArray(this.activeColumns, selectedValue),
    );
  };

  onClickRow = (cellId: string, isSelected: boolean) => {
    if (this.props.withMultipleSelection) {
      const list = this.list;
      const { selectedRows } = this.state;
      let newSelectedRows = selectedRows;

      if (cellId === this.ALL_CHECKBOX) {
        if (!isSelected) {
          const currentUserID = String(getLoggedInAs().userId);
          newSelectedRows = list
            .filter(({ id }: any) => String(id) !== currentUserID)
            .map(({ id }: any) => id);
        } else {
          newSelectedRows = [];
        }
      } else {
        newSelectedRows = toggleInArray(selectedRows, cellId);
      }

      this.setState({ selectedRows: newSelectedRows });
    }
  };

  // TODO: turn into HOC
  /* eslint-disable class-methods-use-this */
  onSearchChange() {
    throw new Error(
      'TableContentBase.onSearchChange method needs to be extended',
    );
  }
  /* eslint-enable class-methods-use-this */

  get searchHelper() {
    const { search } = this.state;
    const { getMessage } = this.props;
    const isTableHasHiddenColumns =
      this.activeColumns.length !== this.allColumns.length;
    const searchHelpText = getMessage('app.search.hidden_help');

    let onChange = this.onSearchChange;
    if (!onChange) {
      onChange = () => {};
    }

    return {
      searchText: getMessage('app.general.find'),
      placeholder: getMessage('app.search.enter_criteria'),
      onChange,
      value: search,
      isTableHasHiddenColumns,
      searchHelpText,
    };
  }

  get columnSelector() {
    return {
      activeColumns: this.activeColumns,
      allColumns: this.allColumns,
      disabledColumns: this.disabledColumns,
      localizedNames: this.localizedColumnNames,
      onChange: this.onColumnSelectorChange,
    };
  }

  get allColumns() {
    const allColumns = this.tableHelper.allColumns;
    const { hiddenColumns } = this.props;

    if (hiddenColumns) {
      const filterColumns = (column: string) => !contains(hiddenColumns, column);

      return allColumns.filter(filterColumns);
    }

    return allColumns;
  }

  get disabledColumns() {
    return this.tableHelper.disabledColumns;
  }

  get tableColumns() {
    return this.tableHelper.columns;
  }

  get activeColumns() {
    if (!isPageRoleEnabled(pageRoles.USER_CAN_USE_COMMENT_API)) {
      return this.tableHelper.activeColumns.filter(col => col !== 'comment');
    }
    return this.tableHelper.activeColumns;
  }

  // TODO: make this a HOC
  /* eslint-disable class-methods-use-this */
  get tableHelper() {
    throw new Error(
      'TableContentBase.get tableHelper method needs to be extended',
    );
  }

  get searchString() {
    throw new Error(
      'TableContentBase.get searchString method needs to be extended',
    );
  }

  get headerContent() {
    throw new Error(
      'TableContentBase.get headerContent method needs to be extended',
    );
  }

  get bodyContent() {
    throw new Error(
      'TableContentBase.get bodyContent method needs to be extended',
    );
  }

  delegateActiveColumns() {
    throw new Error(
      'TableContentBase.delegateActiveColumns method needs to be extended',
    );
  }

  highlightText(text: string) {
    const searchString = this.searchString;

    return searchString.length ? highlightText(text, [searchString]) : text;
  }
  /* eslint-enable class-methods-use-this */

  filterColumns = (column: string) => contains(this.activeColumns, column);

  /**
   * With infinite scroll tables, we need to use JavaScript to keep the widths
   * of the header and body columns, both belonging to two separate Table elements,
   * in sync. This function looks for two refs on the inherited object, tableHeaderRow
   * and tableBody. They should be DOM nodes, but, for now, we'll look up DOM nodes
   * on React components if needed.
   *
   * If your table header column widths don't match your table body column widths,
   * look here first.
   *
   * TODO: Make this way more generic and DOM-focused, so that it can be applied
   * without needing React refs.
   */
  fixTableHeaderCellsWidth = () => {
    let tableHeaderRow = this.tableHeaderRow;
    let tableBody = this.tableBody;

    if (!tableHeaderRow && !tableBody) {
      console.warn(
        'Both tableHeaderRow and tableBody need to have refs, skipping cell width fix.',
      );

      return false;
    }

    if (!tableHeaderRow.children || !tableBody.children) {
      tableHeaderRow = findDOMNode(tableHeaderRow);
      tableBody = findDOMNode(tableBody);
    }

    const tableBodyCells = $(tableBody)
      .find('tr[data-index]')
      .filter((i, elem) =>
        $(elem)
          .attr('data-index')
          .match(/[0-9]/g),
      )
      .first()
      .find('td');

    if (tableBodyCells.length) {
      $(findDOMNode(tableHeaderRow))
        .find('th')
        .each((idx, elem) => {
          const bodyCellWidth = $(tableBodyCells[idx]).outerWidth();

          $(elem).css({ minWidth: bodyCellWidth, maxWidth: bodyCellWidth });
        });
    }

    return false;
  };

  renderTopMenus(config = {}) {
    const { visibleTableActions, searchResults } = this.props;
    const { openedAction } = this.state;
    const stream = searchResults ? searchResults.stream : {};

    return visibleTableActions.map((TopMenu) => {
      const Action = TableViewActions[TopMenu];

      const actionProps: any = {};
      if (this.props.selectedRows) {
        actionProps.selectedRows = this.state.selectedRows;
      }

      return (
        <Action
          {...this.props}
          {...actionProps}
          {...config}
          actionHelper={this.actionHelper}
          stream={stream}
          searchHelper={this.searchHelper}
          selectedRows={this.props.selectedRows}
          key={TopMenu}
          onActionClick={this.onActionClick}
          openedAction={openedAction}
        />
      );
    });
  }

  render() {
    const { tableType, visibleTableActions } = this.props;
    const kebabType = kebabCase(tableType);

    return (
      <div className="table-content-group">
        {visibleTableActions && (
          <div className="table-control-group">
            <div className="table-control-group-inner">
              {this.renderTopMenus()}
            </div>
          </div>
        )}
        <div className="tables-wrapper">
          <div className="table-header">
            <TableView
              withActiveColumn
              tableId={`${kebabType}-table`}
              onColumnSelectorStateChange={this.fixTableHeaderCellsWidth}
            >
              <TableHeader columnSelector={this.columnSelector}>
                <TableRow
                  index={0}
                  rowId={`${kebabType}-table-header`}
                  ref={(thr: any) => {
                    this.tableHeaderRow = thr;
                  }}
                >
                  {this.headerContent}
                </TableRow>
              </TableHeader>
            </TableView>
          </div>
          <div className="table-content">
            <TableView withActiveColumn tableId={`${kebabType}-table`}>
              <TableBody
                ref={(tb: any) => {
                  this.tableBody = tb;
                }}
              >
                {this.bodyContent}
              </TableBody>
            </TableView>
          </div>
        </div>
      </div>
    );
  }
}
