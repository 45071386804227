import axios from 'axios';

let requestInterceptors: any = null;
let responseInterceptors: any = null;

export function setRequestInterceptors(value: any, middleware: any) {
  requestInterceptors = value
    ? axios.interceptors.request.use(middleware)
    : value;
}

export function setResponseInterceptors(value: any, callback: any) {
  responseInterceptors = value
    ? axios.interceptors.response.use(undefined, callback)
    : value;
}

export function ejectInterceptors() {
  axios.interceptors.request.eject(requestInterceptors);
  axios.interceptors.response.eject(responseInterceptors);

  requestInterceptors = null;
  responseInterceptors = null;
}