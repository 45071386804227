import axios from 'axios';

import { getAccountDetails } from './domainInfo';

const contentType = 'application/json';

export function apiURL(path: Function, { urlParams = {}, queryParams = '' } = {}) {
  const { accountId, siteName } = getAccountDetails();

  return (
    path({ account_id: accountId, site_name: siteName, ...urlParams }) +
    queryParams
  );
}

function requestConfig(request: any, options: any) {
  const { path, method, metadata } = request;
  const { params = {}, urlParams = {}, queryParams = '', responseType = 'json' } = options;
  const url = apiURL(path, { urlParams, queryParams });

  return axios({
    headers: { 'Content-Type': contentType, Accept: contentType },
    metadata,
    responseType,
    ...params,
    method,
    url,
  });
}

const handleError = (failure: Function) => (e: any) => {
  if (e instanceof Error) {
    // other error
    setTimeout(() => {
      throw e;
    }, 1);
  } else {
    // axios error
    failure(e);
  }
};

export function batchApiPromises(requests) {
  return axios.all(requests.map(({ request, options = {} }) =>
    requestConfig(request, options),
  ));
}

export function batchApiRequests(requests, handlers = {}) {
  const { success = () => {}, failure = () => {} } = handlers;

  return axios
    .all(batchApiPromises(requests))
    .then(responses => success(responses))
    .catch(handleError(failure));
}

export function apiReqPromise(request, options = {}) {
  return requestConfig(request, options);
}

export default function apiRequest(request, options = {}) {
  const { success = () => {}, failure = () => {} } = options;

  return apiReqPromise(request, options)
    .then(response => success(response))
    .catch(handleError(failure));
}
