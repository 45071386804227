import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { isEmpty } from 'lodash';
import Loadable from 'react-loadable';

import Criteria from 'data/Criteria';
import withMessages, {
  FormattedHTMLMessage,
} from 'view-components/utilities/intl';
import { TableRow } from 'v-c/TableView';
import { TABS } from 'v-c/Search/SimpleSearch';
import reduxStore from '../../global/reduxStore';

import { load as loadRetentionPolicy } from '../../global/state_managers/RetentionPolicyStateManager';
import { resetFilter, updateFilter } from '../../global/state_managers/SearchFormStateManager';
import {
  loadEndUser,
  resetUser,
} from '../../global/state_managers/UserStateManager';
import { loadSplitViewAsset } from '../../global/state_managers/AssetStateManager';
import { resetFlash } from '../../global/state_managers/FlashMessagesStateManager';

import {
  bodyForSaveUnsavedSearch,
  loadUnsavedSearchSummary,
  runMyArchiveSearch,
  runUnsavedSearch,
  resetUnsavedSearch,
  saveUnsavedSearch,
  resetFilteredFormValues,
  runFixedFilterPersonalSearch,
} from '../../global/state_managers/UnsavedSearchStateManager';

import { showDeleteSearchDialog } from '../../global/state_managers/DialogStateManager';
import {
  updateSavedSearch,
  deleteSavedSearch,
  loadSavedSearch,
} from '../../global/state_managers/CurrentSavedSearchStateManager';

import withSerialResultsLoader, {
  LOAD_TYPES,
} from '../../global/lib/withSerialResultsLoader';
import { jsUi } from '../../global/lib/jsUi';
import fixedHeightContent from '../../global/lib/fixedHeightContent';
import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';
import LoadWhenReady from '../../global/lib/LoadWhenReady';
import Loading from '../../global/components/Loading';

import {
  sortStream,
  openSavedSearchStream,
  openUnsavedSearchStream,
} from '../../global/lib/searchResultsStreamManager';
import generateFilteredQuery from '../../global/lib/generateFilteredQuery';

import {
  setSelectedSearchId,
  setSelectedFixedFilter,
} from '../../global/state_managers/SearchResultsStateManager';

import Breadcrumb from '../../global/components/Breadcrumb';
import ActionsRenderer, {
  actions,
} from '../../global/components/ActionsRenderer';

import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';
import { initTableConfig } from '../../global/state_managers/SearchResults/tableConfig';
import { resetStream } from '../../global/state_managers/SearchResults/searchResultsStream';
import {
  openModal,
  closeModal,
} from '../../global/state_managers/ModalsStateManager';

import MODAL_MAP from '../../global/components/Modals/modalMap.json';
import { stateLogic, viewLogic } from './MyArchiveDeconstructed';
import toDSL from '../../global/lib/convertSimpleSearchIntoSearchDSLStructure';
import { currentSavedSearch } from '../../global/reducers';

const { KEYWORDS, FROM, PARTICIPANTS, DATES, ATTACHMENTS, SIZES } = Criteria;

const PersonalArchiveNav = Loadable({
  loader: () => import('view-components/components/PersonalArchive/PersonalArchiveNav'),
  loading() {
    return <Loading />;
  },
});

const SplitView = Loadable({
  loader: () => import('../../global/components/SplitView/SplitView'),
  loading() {
    return <Loading />;
  },
});

const EmptyResults = Loadable({
  loader: () => import('view-components/components/EmptyPageViews/MyArchiveEmptyResults'),
  loading() {
    return <Loading />;
  },
});

function tableHeaderContent(props: Props) {
  const { tableActionsProps } = viewLogic(props);
  return <ActionsRenderer {...tableActionsProps()} />;
}

function assetViewHeaderContent(props: Props) {
  const { assetActionsProps } = viewLogic(props);
  return <ActionsRenderer {...assetActionsProps()} />;
}

function emptyResults(isLoading: Boolean, searchResults: any[]) {
  if (isLoading || !searchResults.length) {
    return (
      <EmptyResults>
        <FormattedHTMLMessage id={`app.empty_results.${isLoading ? 'is_loading' : 'my_archive_results'}`} />
      </EmptyResults>
    );
  }

  return null;
}

const fixedFilterTypes = {
  ALL: 'All',
  RECEIVED: 'Received',
  SENT: 'Sent',
};

const textFields = [KEYWORDS, FROM, PARTICIPANTS];
const localizedFields = [ATTACHMENTS, DATES, SIZES];

type Props = {
  returnCount: number,
  isUserLoaded: Boolean,
  assetId: string,
  goToAsset?: Function,
  openModal: Function,
  closeModal: Function,
  fields: {},
  saveUnsavedSearch: Function,
  runUnsavedSearch: Function,
  setSelectedSearchId: Function,
  setSelectedFixedFilter: Function,
  resetFilter: Function,
  resetFilteredFormValues: Function,
  updateSavedSearch: Function,
  setInitSearchRun: Function,
  savedSearches: {
    totalCount: number,
    searches: any[],
  },
  savedSearchesList: {}[],
  runFixedFilterPersonalSearch: Function,
  showDeleteSearchDialog: Function,
  deleteSavedSearch: Function,
  initSearchRun: Boolean,
  loadSavedSearches: Function,
  loadSavedSearch: Function,
  retentionPolicy: {
    ttl: number,
    enabled: Boolean,
  },
  getMessage: Function
}

const mapStateToProps = ({
  user,
  retentionPolicy,
  authentication,
  asset,
  unsavedSearch,
  searchResults,
  savedSearches,
  searchForm,
}: any) => ({
  retentionPolicy: retentionPolicy.policy,
  loggedInAsUserId: authentication.loggedInAs.userId,
  isUserLoaded: user.isLoaded,
  user: user.user,
  asset,
  unsavedSearch,
  savedSearches,
  searchResults,
  returnCount: searchResults.stream.returnCount || 0,
  results: searchResults.results,
  searchForm,
  savedSearchesList: savedSearches.searches,
});

const mapActionsToProps = {
  resetFilter,
  loadEndUser,
  resetUser,
  loadSplitViewAsset,
  runMyArchiveSearch,
  resetUnsavedSearch,
  setBreadcrumbForMyArchivePage: breadcrumbsActions.myArchivePage,
  initTableConfig,
  resetStream,
  openModal,
  closeModal,
  saveUnsavedSearch,
  runUnsavedSearch,
  loadUnsavedSearchSummary,
  openSavedSearchStream,
  openUnsavedSearchStream,
  updateFilter,
  resetFilteredFormValues,
  sortStream,
  showDeleteSearchDialog,
  deleteSavedSearch,
  updateSavedSearch,
  currentSavedSearch,
  runFixedFilterPersonalSearch,
  loadSavedSearch,
  loadRetentionPolicy,
  setSelectedSearchId,
  setSelectedFixedFilter,
};

@withMessages
@flashConnect(PAGES.MY_ARCHIVE_SPLIT_VIEW, 'MyArchive')
@withSerialResultsLoader(LOAD_TYPES.SAVED_SEARCHES)
@withSerialResultsLoader(LOAD_TYPES.SEARCH_RESULTS_LOAD_MORE)
@connect(
  mapStateToProps,
  mapActionsToProps,
)

@reduxForm(
  {
  form: 'personal-search',
  fields: ['newSearch', 'searchName', KEYWORDS, FROM, PARTICIPANTS, DATES, ATTACHMENTS, SIZES],
  enableReinitialize: true,
  initialState: { newSearch: '', searchName: {}, keywords: {}, from: {}, participants: {}, dates: {}, attachments: {}, sizes: {} },
  },
  () => ({
    initialValues: {
    searchName: {},
    keywords: {},
    from: {},
    participants: {},
    dates: {},
    attachments: {},
    sizes: {},
    },
    }),
)

@stateLogic({
  sortSearchResults: sortStream,
  goToMyArchiveSplitViewIndex: jsUi.myArchiveSplitViewIndex.goTo,
  goToMyArchiveSplitViewAsset: jsUi.myArchiveSplitViewAsset.goTo,
  rowStatuses: TableRow.rowStatuses,
  tableActions: actions,
  openUnsavedSearchStream,
  openSavedSearchStream,
  generateFilteredQuery,
  MODAL_MAP,
  toDSL,
  TABS,
  })
@fixedHeightContent
export default class MyArchive extends Component<Props> {
  static propTypes = {
    returnCount: PropTypes.number,
    isUserLoaded: PropTypes.bool,
    assetId: PropTypes.string,
    goToAsset: PropTypes.func,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    saveUnsavedSearch: PropTypes.func.isRequired,
    runUnsavedSearch: PropTypes.func.isRequired,
    setSelectedSearchId: PropTypes.func.isRequired,
    setSelectedFixedFilter: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    resetFilteredFormValues: PropTypes.func.isRequired,
    updateSavedSearch: PropTypes.func.isRequired,
    setInitSearchRun: PropTypes.func.isRequired,
    savedSearches: PropTypes.object.isRequired,
    savedSearchesList: PropTypes.array.isRequired,
    runFixedFilterPersonalSearch: PropTypes.func.isRequired,
    showDeleteSearchDialog: PropTypes.func.isRequired,
    deleteSavedSearch: PropTypes.func.isRequired,
    initSearchRun: PropTypes.bool.isRequired,
    loadSavedSearches: PropTypes.func.isRequired,
    loadSavedSearch: PropTypes.func.isRequired,
    retentionPolicy: PropTypes.shape({
      ttl: PropTypes.number,
      enabled: PropTypes.bool,
    }).isRequired,
    getMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    returnCount: null,
    isUserLoaded: null,
    assetId: null,
    goToAsset: null,
    user: {
      handles: [],
    },
  };

  componentDidUpdate(prevProps: Props) {
    const { savedSearches: newSavedSearches } = this.props;
    const { savedSearches } = prevProps;

    if (savedSearches.totalCount !== newSavedSearches.totalCount
      && this.props.initSearchRun) {
      this.runSavedSearch(newSavedSearches.searches[0].id);
      this.props.setInitSearchRun();
      this.props.loadSavedSearches(0, true);
    }
  }


  onFixedFilter = (selectedFilter: string, deletedSearchEvent = false) => {
    this.props.setSelectedSearchId('');
    this.props.setSelectedFixedFilter(selectedFilter);

    if (!deletedSearchEvent) {
      this.props.runFixedFilterPersonalSearch(selectedFilter);
    } else {
      this.props.runFixedFilterPersonalSearch(selectedFilter, true);
    }
  };

  openNewSearch = () => {
    this.props.resetFilter();
    this.props.resetFilteredFormValues();
    reduxStore.dispatch(
      resetFlash({ from: 'MyArchive/openNewSearch' }),
    );
    this.props.openModal(MODAL_MAP.PERSONAL_SEARCHES, {
      closeModal: this.props.closeModal,
      formValues: this.props.fields,
      isNewPersonalSearchModal: true,
      onSave: (modalForm: any) => {
        this.runSearch(modalForm);
        this.props.saveUnsavedSearch(
          {
            ...bodyForSaveUnsavedSearch(),
            name: modalForm.searchName.value.query,
            description: '',
            'special-access': [],
          },
          { isNewSearch: true },
          true,
        );
        this.props.closeModal();
        this.props.setInitSearchRun();
      },
    });
  };

  findSearch = (id: number) => {
    const selectedSearch = this.props.savedSearchesList.filter((search: any) => search.id === id);
    return selectedSearch[0];
  }

  manageSearch = (id: number) => {
    reduxStore.dispatch(
      resetFlash({ from: 'MyArchive/manageSearch' }),
    );
    this.props.resetFilter();
    this.props.resetFilteredFormValues();
    const foundSearch: any = this.findSearch(id);
    const parsedFormValues = JSON.parse(foundSearch.clientData);

    const fieldValues = Object.keys(parsedFormValues.formValues).reduce((all, field) => {
      const textFieldCheck = textFields.includes(field) && !!parsedFormValues.formValues[field].query;
      const localizedFieldCheck = localizedFields.includes(field) && !isEmpty(parsedFormValues.formValues[field]);

      const modifiedAll: any = { ...all };
      if (textFieldCheck || localizedFieldCheck) {
        modifiedAll[field] = parsedFormValues.formValues[field];
      }
      return modifiedAll;
    }, {});

    const cleanedUpFormValues = Object.keys(fieldValues).reduce((_all, field) => {
      const modifiedAll: any = { ...fieldValues };
      if (textFields.includes(field)) {
        // Ensures text criteria is a single string instead of an array of strings
        modifiedAll[field].value = { query: modifiedAll[field].query.join(' ') };
      }
      return modifiedAll;
    }, {});
    const searchName = { value: { query: foundSearch.name } };
    const fullFormValues = { ...cleanedUpFormValues, searchName };

    this.props.openModal(MODAL_MAP.PERSONAL_SEARCHES, {
      closeModal: this.props.closeModal,
      formValues: fullFormValues,
      isNewPersonalSearchModal: false,

      onDelete: () => {
        this.deleteSearch(foundSearch.id, foundSearch.name);
        this.props.closeModal();
      },

      onModify: (modalForm: any) => {
        this.runSearch(modalForm);
        this.modifySearch(modalForm, id);
        this.props.closeModal();
        this.runSavedSearch(id);
      },
    });
  };

  runSavedSearch = (id: number, isNew = false) => {
    this.props.resetFilter();
    this.props.resetFilteredFormValues();
    if (!isNew) {
      reduxStore.dispatch(
        resetFlash({ from: 'MyArchive/runSavedSearch' }),
      );
    }
    // making sure that the unsaved searches (all,sent,received) are unselected
    this.props.setSelectedFixedFilter('');
    this.props.setSelectedSearchId(id);
    this.props.loadSavedSearch({ searchId: id });
    openSavedSearchStream(id, null, true);
  };

  runSearch = (modalValues: any) => {
    const fieldValues = Object.keys(modalValues).reduce((all, field) => {
      const textFieldCheck = textFields.includes(field) && !isEmpty(modalValues[field].value);
      const localizedFieldCheck = localizedFields.includes(field) && Object.prototype.hasOwnProperty.call(modalValues[field], 'localizedValue');

      const modifiedAll: any = { ...all };
      if (textFieldCheck) {
        modifiedAll[field] = modalValues[field].value;
      }
      if (localizedFieldCheck) {
        modifiedAll[field] = modalValues[field];
      }

      return modifiedAll;
    }, {});

    const query = toDSL(fieldValues);

    this.props.runUnsavedSearch(
      {
        clientData: {
          type: 'simple',
          version: 'v2',
          personal: true,
          formValues: fieldValues,
        },
        query,
      },
    );
  };

  deleteSearch = (deleteSearchId: number, deleteSearchName: string) => {
    this.props.showDeleteSearchDialog(
      {
        confirm: () => {
          const { ALL } = fixedFilterTypes;
          this.onFixedFilter(ALL, true);
          this.props.deleteSavedSearch(deleteSearchId, true);
        },
      },
      {
        deleteName: deleteSearchName,
        content: 'CurrentSavedSearch',
      },
      true,
    );
  }

  modifySearch = (modalForm: any, id: number) => {
    const updateRequest = {
      ...bodyForSaveUnsavedSearch(),
      name: modalForm.searchName.value.query,
      description: '',
      'special-access': [],
    };
    this.props.updateSavedSearch(
      {
        id,
        requestData: updateRequest,
      },
      true,
    );
  }

  tooltipText = () => {
    const {
      retentionPolicy: {
        ttl,
        enabled,
      },
      getMessage,
    } = this.props;

    if (enabled) {
      const month = ttl % 12;
      const year = Math.floor(ttl / 12);
      return getMessage('components.personal_archive.enabled_retention', {
        year,
        month,
        // ex: 1 year 0 months, 4 years 1 month
      });
    }
    return getMessage('components.personal_archive.default_retention');
  };

  render() {
    const { shouldRenderEmptyView, selectedAsset, savedSearches, searchResults, results } = viewLogic(this.props);
    return (
      <LoadWhenReady
        readyStates={[this.props.isUserLoaded]}
      >
        <div data-view="my-archive">
          <div data-content="header">
            <FlashMessages />
            <Breadcrumb returnCount={this.props.returnCount.toLocaleString()} />
          </div>
          <div data-content="content" style={{ display: 'flex' }}>
            <PersonalArchiveNav
              createSearch={this.openNewSearch}
              onFilter={this.onFixedFilter}
              runSavedSearch={this.runSavedSearch}
              savedSearches={savedSearches.searches}
              selectedSearchId={searchResults.selectedSearchId}
              selectedFixedFilter={searchResults.selectedFixedFilter}
              manageSearch={this.manageSearch}
              tooltipText={this.tooltipText()}
            />
            {shouldRenderEmptyView && emptyResults(searchResults.isLoading, results)}
            {!shouldRenderEmptyView && (
              <SplitView
                goToAsset={this.props.goToAsset}
                assetId={this.props.assetId}
                tableHeaderContent={tableHeaderContent(this.props)}
                assetHeaderContent={assetViewHeaderContent(this.props)}
                selectedAsset={selectedAsset}
                isUserAbleToDownloadAttachments
                personalArchive
                isLoading={searchResults.isLoading}
              />
            )}
          </div>
        </div>
      </LoadWhenReady>
    );
  }
}
