/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import PersonalArchiveModals from 'v-c/Modals/Components/PersonalArchiveModals';
import { ModalProps } from 'global/types';

export default function PersonalArchiveModalSearch(props: ModalProps) {
  const { modalOptions } = props;

  return (
    <PersonalArchiveModals
      onClose={modalOptions.closeModal}
      onCancel={modalOptions.closeModal}
      formValues={modalOptions.formValues}
      onSave={modalOptions.onSave}
      isNewPersonalSearchModal={modalOptions.isNewPersonalSearchModal}
      onDelete={modalOptions.onDelete}
      onModify={modalOptions.onModify}
    />
  );
}

PersonalArchiveModalSearch.propTypes = {
  modalOptions: PropTypes.shape({
    closeModal: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    isNewPersonalSearchModal: PropTypes.bool,
  }).isRequired,
};

PersonalArchiveModalSearch.defaultProps = {
  modalOptions: {
    isNewPersonalSearchModal: false,
  },
};
