import React from 'react';
import PropTypes from 'prop-types';

import { FormattedHTMLMessage } from 'view-components/utilities/intl';

import UpperSectionAlert from 'view-components/components/Dialog/UpperSection/Alert';
import { LowerSectionText } from 'view-components/components/Dialog/Components';

import LowerSectionDeleteConfirm from './Components/LowerSectionDeleteConfirm';
import confirmableDialog from './Components/ConfirmableDialog';
import { DialogProps } from 'global/types';

interface Props extends DialogProps {
  deleteName: string
}

function LowerContent() {
  return (
    <LowerSectionText>
      <FormattedHTMLMessage
        id="app.dialog.delete_list.lower_section"
      />
    </LowerSectionText>
  );
}

function DeleteListDialogContentFn(props: Props) {
  const {
    deleteName,
    isConfirmed,
    onConfirm,
    onDismiss,
    toggleConfirmed,
  } = props;

  return (
    <div>
      <UpperSectionAlert>
        <FormattedHTMLMessage
          id="app.dialog.delete_list.message"
          values={{ listName: deleteName }}
        />
      </UpperSectionAlert>

      <LowerSectionDeleteConfirm
        toggleConfirmed={toggleConfirmed}
        isConfirmed={isConfirmed}
        deleteMessage={
          <FormattedHTMLMessage
            id="app.dialog.delete_list.confirm_message"
          />
        }
        onConfirm={onConfirm}
        additionalTopContent={<LowerContent />}
        onDismiss={onDismiss}
      />
    </div>
  );
}

DeleteListDialogContentFn.propTypes = {
  deleteName: PropTypes.string,
  isConfirmed: PropTypes.bool,
  onConfirm: PropTypes.func,
  onDismiss: PropTypes.func,
  toggleConfirmed: PropTypes.func,
};

DeleteListDialogContentFn.defaultProps = {
  deleteName: null,
  isConfirmed: false,
  onConfirm: () => {},
  onDismiss: () => {},
  toggleConfirmed: () => {},
};

const DeleteListDialogContent = confirmableDialog(DeleteListDialogContentFn);

export default DeleteListDialogContent; // DeleteSearchDialogContent
