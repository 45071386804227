import { trim } from 'lodash';

import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';
import { Action } from 'global/reduxStore';

export const RESET: string = 'purge/reset';
export const IS_PROCESSING: string = 'purge/is-processing';
export const SUCCESS_PURGE: string = 'purge/success-purge';
export const FAILED_PURGE: string = 'purge/failed-purge';

interface PurgeState {
  isProcessing: boolean,
  isSuccessed: boolean,
  isFailed: boolean,
}

export const INITIAL_STATE: PurgeState = {
  isProcessing: false,
  isSuccessed: false,
  isFailed: false,
};

export function purgeAsset(options: any) {
  return function* doPurgeAsset() {
    yield { type: IS_PROCESSING };

    try {
      yield apiReqPromise(jsApi.purgeDocument, {
        params: { data: { purge: { ...options, reason: trim(options.reason) } } },
      });

      yield { type: SUCCESS_PURGE };
    } catch (e) {
      console.error(e);

      yield { type: FAILED_PURGE };
    }
  };
}

export function resetPurge() {
  return { type: RESET };
}

export default function PurgeStateManager(state = INITIAL_STATE, { type }: Action) {
  switch (type) {
    case RESET:
      return { ...INITIAL_STATE };
    case IS_PROCESSING:
      return {
        ...INITIAL_STATE,
        isProcessing: true,
        isSuccessed: false,
        isFailed: false,
      };
    case SUCCESS_PURGE:
      return { ...state, isProcessing: false, isSuccessed: true };
    case FAILED_PURGE:
      return { ...state, isProcessing: false, isFailed: true };
    default:
      return state;
  }
}
