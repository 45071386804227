/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withMessages, { intlPropTypes } from 'component-utilities/intl';

import { FormLabel, FormErrorText, InputField, Textarea } from 'v-c/Forms';
import {
  PrimaryButton,
  SecondaryButton,
} from 'view-components/components/Buttons/Components';

import constants from '../../../config/constants';
import configs from '../../../config/configs';

import isValidEmail from '../../lib/isValidEmail';
import withForm from '../../lib/formHelper';
import actionsBase, {
  WithinActionsBasePanel as withinPanel,
} from './ActionsBase';
import SuccessContent from './SuccessContent';
import FailedContent from './FailedContent';
import Processing from '../Processing';

import {
  recoverMessage,
  resetRecoverMessageStatus,
} from '../../state_managers/AssetStateManager';

import CloudExportDarkGray from './images/send-dark.png';
import { Asset, Fields, intlShape, Params, ReduxState } from 'global/types';

const { GENERAL_CONFIGS } = configs;

const { STATUSES } = constants;

const TYPE = 'send_email';
const CLOSED_STATE_IMAGE = CloudExportDarkGray;
const OPENED_STATE_IMAGE = CloudExportDarkGray;

const mapStateToProps = ({ domainInfo, authentication }: ReduxState) => ({
  accountId: domainInfo.accountId,
  siteName: domainInfo.siteName,
  loggedInAs: authentication.loggedInAs,
});

const mapActionsToProps = {
  recoverMessage,
  resetRecoverMessageStatus,
};

interface Props extends intlShape {
  assetId: string
  areUnloadedAssetsChecked: any
  getButtonText: Function
  hiddenFilterCriteria: {}[]
  isActionOpened: boolean
  isReasonLoading: any
  onActionClick: Function
  onHoldReasonsUpdate: Function
  params: Params
  reasons: any
  searchResults: any
  selectedRows: any
  accountId: string
  siteName: string
  loggedInAs: {
    firstName: string
    lastName: string
  }
  recoverMessage: Function
  resetRecoverMessageStatus: Function
  openedAction: string
  fields: Fields
  resetFields: Function
  asset: Asset
}

@withMessages
@withForm({
  form: 'send-email',
  fields: ['to', 'message'],
  })
@actionsBase({
  type: TYPE,
  closedStateImage: CLOSED_STATE_IMAGE,
  openedStateImage: OPENED_STATE_IMAGE,
  })
@withinPanel
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class SendEmail extends Component<Props> {
  static propTypes = {
    ...intlPropTypes,
    areUnloadedAssetsChecked: PropTypes.any,
    getButtonText: PropTypes.func.isRequired,
    hiddenFilterCriteria: PropTypes.array,
    isActionOpened: PropTypes.bool,
    isReasonLoading: PropTypes.any,
    onActionClick: PropTypes.func.isRequired,
    onHoldReasonsUpdate: PropTypes.func,
    params: PropTypes.object,
    reasons: PropTypes.any,
    searchResults: PropTypes.any,
    selectedRows: PropTypes.any,
    accountId: PropTypes.string.isRequired,
    siteName: PropTypes.string.isRequired,
    loggedInAs: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    recoverMessage: PropTypes.func.isRequired,
    resetRecoverMessageStatus: PropTypes.func.isRequired,
  };

  static defaultProps = {
    areUnloadedAssetsChecked: false,
    hiddenFilterCriteria: [],
    isActionOpened: false,
    isReasonLoading: false,
    reasons: [],
    searchResults: [],
    selectedRows: [],
    onHoldReasonsUpdate: () => {},
    params: {},
  };

  constructor(props: Props) {
    super(props);

    this.state = { isInitialValidation: true };
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.openedAction !== TYPE &&
      this.props.openedAction === TYPE &&
      this.isStatusView
    ) {
      this.resetForm();
    }
  }

  onCancel = () => {
    this.resetForm();
    this.props.onActionClick();
  };

  onSend = () => {
    const {
      accountId,
      siteName: account,
      loggedInAs: { firstName, lastName },
      asset: { data: { sizeInBytes, downloadableAttachments } },
    } = this.props;
    const messageId = this.props.assetId || this.props.asset.id;

    const requestData = {
      ...this.props.getFieldValues(),
      firstName,
      lastName,
      accountId,
      account,
      messageId,
      downloadableAttachments,
    };

    this.props.recoverMessage(messageId, requestData, sizeInBytes);
  };

  get recoverMessageStatus() {
    return this.props.asset.recoverMessageStatus;
  }

  get isInvalidEmail() {
    const { fields } = this.props;

    return (
      !!fields.to.value.length &&
      !isValidEmail(fields.to.value) &&
      !this.isInitialValidation
    );
  }

  get isFormValid() {
    const { fields } = this.props;

    return !!fields.to.value.length && !this.isInvalidEmail;
  }

  get tableContent() {
    const { getMessage } = this.props;
    const { fields } = this.props;
    const formError = this.isInvalidEmail ? 'components.validation_issues.invalid_email' : '';

    return [
      {
        firstColumnClass: 'first-column-with-input',
        firstColumnText: 'to',
        secondColumnData: (
          <data>
            <InputField
              focused
              data-field="to"
              onBlur={() => {
                this.isInitialValidation = false;
              }}
              {...fields.to}
            />
            <FormErrorText field="to-error" error={formError} />
          </data>
        ),
      },
      {
        firstColumnClass: 'first-column',
        firstColumnText: 'message',
        secondColumnData: <Textarea data-field="message" {...fields.message} />,
      },
    ].map(({ firstColumnClass, firstColumnText, secondColumnData }) => (
      <tr key={firstColumnText}>
        <th className={firstColumnClass}>
          <FormLabel>{getMessage(`app.general.${firstColumnText}`)}</FormLabel>
        </th>
        <th className="second-column">{secondColumnData}</th>
      </tr>
    ));
  }

  get content() {
    const { getMessage } = this.props;

    switch (this.recoverMessageStatus) {
      case STATUSES.SUCCESS:
        return (
          <SuccessContent onClick={this.closeModal}>
            <FormLabel>
              <div style={{ textAlign: 'center' }}>
                {getMessage('app.send_email.email_sent_success')}
              </div>
            </FormLabel>
          </SuccessContent>
        );
      case STATUSES.FAILED:
        return (
          <FailedContent onClick={this.closeModal}>
            <FormLabel>
              {getMessage('app.send_email.email_sent_error')}
            </FormLabel>
          </FailedContent>
        );
      case STATUSES.EXCEEDS_SIZE_LIMIT:
        return (
          <FailedContent onClick={this.closeModal}>
            <FormLabel>
              {getMessage('app.send_email.email_exceeds_size_limit')}
            </FormLabel>
          </FailedContent>
        );
      default:
        return (
          <table className="form-table">
            <tbody>{this.tableContent}</tbody>
          </table>
        );
    }
  }

  get isStatusView() {
    return [STATUSES.SUCCESS, STATUSES.FAILED, STATUSES.EXCEEDS_SIZE_LIMIT].some(
      status => status === this.recoverMessageStatus,
    );
  }

  get isInitialValidation() {
    return this.state.isInitialValidation;
  }

  set isInitialValidation(newValue) {
    this.setState({ isInitialValidation: newValue });
  }

  closeModal = () => {
    // TODO: deal with setState on unmounted component issue
    this.resetForm();
    this.props.onActionClick();
  };

  resetForm = () => {
    this.isInitialValidation = true;
    this.props.resetFields();
    this.props.resetRecoverMessageStatus();
  };

  render() {
    const { getMessage } = this.props;
    const processingText = `${getMessage('app.general.processing')}...`;

    if (this.isStatusView) {
      window.setTimeout(() => {
        if (!this.isInitialValidation) {
          this.closeModal();
        }
      }, GENERAL_CONFIGS.STATUS_SCREEN_LIFETIME);
    }

    return (
      <div className="content-wrapper">
        <Processing
          text={processingText}
          isProcessing={this.resetRecoverMessageStatus === STATUSES.PROCESSING}
        />
        {!this.isStatusView && (
          <div className="content-header">
            <FormLabel>{getMessage('app.send_email.header_text')}</FormLabel>
          </div>
        )}
        <div className="content-main">{this.content}</div>
        {!this.isStatusView && (
          <div className="content-footer">
            <PrimaryButton
              disabled={!this.isFormValid}
              extraClassName="add-button"
              onClick={this.onSend}
              data-action="send"
            >
              {this.props.getButtonText('send')}
            </PrimaryButton>
            <SecondaryButton
              extraClassName="cancel-button"
              onClick={this.onCancel}
              data-action="cancel"
            >
              {this.props.getButtonText('cancel')}
            </SecondaryButton>
          </div>
        )}
      </div>
    );
  }
}
