/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { findDOMNode } from 'react-dom';
import { killEvent } from 'event-helpers';

import classNames from 'classnames/bind';

import RetinaImage from 'view-components/components/RetinaImage';
import ChevronUpImage from './images/chevron-up.png';
import ChevronDownImage from './images/chevron-down.png';

import style from './FilteredDropdown.scss';

const cx = classNames.bind(style);

type State = {
  shouldDropUp: Boolean,
  isOpen: Boolean,
  attributes: any,
  selectedAttribute: any
}

type Props = {
  options: {
    disabled: Boolean,
    emptyText: string
  },
  value: {
    optional: Boolean,

  },
  pool: any[],
  setAttibute: Function,
  reset: Function,
  parent: {
    scrollheight: number
  },
}

export default class FilteredDropdown extends Component<Props, State> {
  static propTypes = {
    options: PropTypes.object,
    value: PropTypes.object,
    pool: PropTypes.array,
    setAttibute: PropTypes.func,
    reset: PropTypes.func,
    parent: PropTypes.object,
  };

  static defaultProps = {
    parent: null,
    pool: null,
    value: null,
    options: null,
    setAttibute: () => {},
    reset: () => {},
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedAttribute: this.props.value || null,
      attributes: this.props.pool,
    };
  }
  componentDidMount() {
    document.addEventListener('click', this.closeDropdown, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeDropdown, true);
  }

  resetDropdown = (e: any) => {
    this.setState({
      isOpen: false,
    });
    killEvent(e);
    this.props.reset(this.state.selectedAttribute.name);
    this.state.selectedAttribute = null;
  };

  closeDropdown = (e: any) => {
    if (!findDOMNode(this).contains(e.target)) {
      this.setState({
        isOpen: false,
      });
    }
  }

  attributeRowClicked(e: any, rowOptions: any) {
    killEvent(e);
    if (!rowOptions.headerRow) {
      this.setState({
        selectedAttribute: rowOptions.attribute,
        isOpen: false,
      });
      if (this.props.setAttibute) {
        this.props.setAttibute(rowOptions.attribute.name);
      }
    } else if (!this.props.options.disabled) {
      const rect = e.target.offsetParent.getClientRects()[0];
      const selfHeight = rect.height;
      const selfTop = rect.top;
      const parentHeight = this.props.parent.scrollheight;
      this.setState({
        isOpen: !this.state.isOpen,
        shouldDropUp: parentHeight - (6 * selfHeight) < selfTop,
      });
    }
  }

  attributeRow(rowOptions: any) {
    const chevronClasses = cx({
      hidden: !rowOptions.headerRow,
    });
    const { selectedAttribute } = this.state;
    const rowClasses = cx({
      'attribute-row': true,
      resetable: rowOptions.headerRow &&
        selectedAttribute &&
        !this.props.options.disabled,
    });

    return (
      <div
        className={rowClasses}
        onClick={e => this.attributeRowClicked(e, rowOptions)}
        role="button"
        tabIndex="-1"
      >
        <div className={style.attribute}>
          { !rowOptions.headerRow &&
            rowOptions.attribute.localisedName
          }{ rowOptions.headerRow && selectedAttribute &&
            selectedAttribute.localisedName
          }{ rowOptions.headerRow && !selectedAttribute &&
            <em>{this.props.options.emptyText}</em>
          }
          <div className={style.asterisk}>&nbsp;*</div>
          <div
            className={style.reset}
            onClick={this.resetDropdown}
            role="button"
            tabIndex="-1"
          >
            &nbsp;&nbsp;✕
          </div>
        </div>
        <div className={style.type}>
          { !rowOptions.headerRow &&
            rowOptions.attribute.dataType
          }{ rowOptions.headerRow && selectedAttribute &&
             selectedAttribute.dataType
          }
        </div>
        <div className={style.chevron}>
          { rowOptions.headerRow && !this.props.options.disabled && this.state.isOpen &&
            <RetinaImage className={chevronClasses} image={ChevronUpImage} />
          }
          { rowOptions.headerRow && !this.props.options.disabled && !this.state.isOpen &&
            <RetinaImage className={chevronClasses} image={ChevronDownImage} />
          }
        </div>
      </div>
    );
  }

  render() {
    const dropdownClasses = cx({
      'filtered-dropdown': true,
      'is-open': this.state.isOpen,
      'drop-up': this.state.shouldDropUp,
      disabled: this.props.options.disabled,
      required: this.props.options.disabled && !this.props.value.optional,
    });

    // TODO: no more string refs
    /* eslint-disable react/no-string-refs */
    return (
      <div className={dropdownClasses} ref="filteredDropdown">
        <div>
          {this.attributeRow({ headerRow: true, attribute: { localisedName: 'Select attribute' } })}
        </div>
        {
          this.state.isOpen &&

          <div className={style['dropdown-panel']}>
            { this.state.attributes.map((attribute: any) => (
              <div key={attribute.name}>
                {this.attributeRow({ headerRow: false, attribute })}
              </div>
            )) }
          </div>
        }
      </div>
    );
  }
}
