import ga from 'react-ga';

import { getLoggedInAs } from '../state_managers/AuthenticationStateManager';

function getAccountLabel() {
  const siteName = window.location.pathname.split('/')[1];
  const currentUser = getLoggedInAs();

  let userRoles = [];
  if (currentUser) {
    userRoles = currentUser.roles;
  }

  return `${siteName} [${userRoles.join(', ')}]`;
}

export default {
  userLoggedIn: () => {
    ga.event({
      category: 'User',
      action: 'Logged In',
      label: getAccountLabel(),
    });
  },

};
