import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';
import { Action, PromiseObject } from 'global/reduxStore';

export const RESET: string = 'pop3Config/reset';
export const LOADING: string = 'pop3Config/loading';
export const LOADED: string = 'pop3Config/loaded';
export const FAILED_TO_LOAD: string = 'pop3Config/failedToLoad';
export const TESTING: string = 'pop3Config/testing';
export const TESTED: string = 'pop3Config/tested';
export const FAILED_TO_TEST: string = 'pop3Config/failedToTest';
export const COLLECTING: string = 'pop3Config/collecting';
export const COLLECTED: string = 'pop3Config/collected';
export const FAILED_TO_COLLECT: string = 'pop3Config/failedToCollect';
export const STATUS_CHANGED: string = 'pop3Config/statusChanged';
export const FAILED_TO_CHANGE_STATUS: string = 'pop3Config/failedToChangeStatus';
export const CHANGING_STATUS: string = 'pop3Config/changingStatus';

interface Pop3ConfigState {
  isLoading: boolean,
  isLoaded: boolean,
  isProcessing: boolean,
  config: {
    status: string,
    accountId: string,
    collectionInterval: string,
    ctime: string,
    enabled: boolean,
    ipAddress: string,
    lastCollectedAt: string,
    maxMessageSize: string,
    mtime: string,
    pop3ConfigName: string,
    port: string,
    ssl: boolean,
    username: string,
    password: string,
  },
}

// Init
export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  isProcessing: false,
  config: {
    status: '',
    accountId: '',
    collectionInterval: '',
    ctime: '',
    enabled: false,
    ipAddress: '',
    lastCollectedAt: '',
    maxMessageSize: '0',
    mtime: '',
    pop3ConfigName: '',
    port: '0',
    ssl: false,
    username: '',
    password: '',
  },
};

// Actions
export function loadPop3Config() {
  return function* doLoadPop3Config() {
    yield { type: LOADING };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.settingsPop3Config);

      yield {
        type: LOADED,
        payload: { config: result.data },
      };
    } catch (e) {
      console.error(e);

      yield { type: FAILED_TO_LOAD };
    }
  };
}

export function testPop3Config(config: any) {
  return function* doTestPop3Config() {
    yield { type: TESTING };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.settingsTestPop3Config, {
        params: { data: { pop3: config } },
      });

      yield {
        type: TESTED,
        payload: { config: result.data },
      };
    } catch (e) {
      console.error(e);
      const { response: { data } }: any = e;
      yield { type: FAILED_TO_TEST, payload: { config: data } };
    }
  };
}

export function updatePop3Status(enabled: boolean) {
  return function* doUpdatePop3Status() {
    yield { type: CHANGING_STATUS };
    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.settingsSetPop3ConfigStatus, {
        params: { data: { enabled } },
      });

      yield {
        type: STATUS_CHANGED,
        payload: { config: result.data },
      };
    } catch (e) {
      console.error(e);
      const { response: { data } }: any = e;
      yield { type: FAILED_TO_CHANGE_STATUS, payload: { config: data } };
    }
  }
}

export function collectPop3Messages(config: any) {
  return function* doCollectPop3Messages() {
    yield { type: COLLECTING };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.settingsCollectPop3Messages, {
        params: { data: { pop3: config } },
      });

      yield {
        type: COLLECTED,
        payload: { config: result.data },
      };
    } catch (e) {
      console.error(e);
      const { response: { data } }: any = e;
      yield { type: FAILED_TO_COLLECT, payload: { config: data } };
    }
  };
}

export function resetPop3Config() {
  return { type: RESET };
}

// Store
export default function Pop3ConfigStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case LOADING:
      return { ...state, isLoading: true, isLoaded: false };
    case TESTING:
    case CHANGING_STATUS:
    case COLLECTING:
      return { ...state, isProcessing: true };
    case LOADED:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        config: payload.config,
      };
    case FAILED_TO_LOAD:
      return {
        ...state,
        isLoading: false,
      };
    case FAILED_TO_CHANGE_STATUS:
    case FAILED_TO_COLLECT:
    case FAILED_TO_TEST:
    case TESTED:
    case COLLECTED:
      return {
        ...state,
        isProcessing: false,
        config: payload.config,
      };
    case STATUS_CHANGED:
      return {
        ...state,
        isProcessing: false,
        config: {
          ...state.config,
          enabled: payload.config.enabled
        },
      };
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
