import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumb from '../Breadcrumb';
import FlashMessages from '../FlashMessages';

type Props = {
  notifyHeight: number
}

export default function Heading(props: Props) {
  return (
    <header>
      <FlashMessages notifyHeight={props.notifyHeight} />
      <Breadcrumb {...props} />
    </header>
  );
}

Heading.displayName = 'TableView/Heading';

Heading.propTypes = {
  notifyHeight: PropTypes.func.isRequired,
};
