/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { flatten, without } from 'lodash';

import {
  LeftColumnWrapper,
  LeftHandNav as LeftHandNavComponent,
} from 'view-components/components/LeftHandNav/Components';
import withMessages from 'component-utilities/intl';

import { jsUi, ROUTES } from '../../lib/jsUi';

import Pages from '../../constants/Pages';
import pathsByType, { menuOrder } from '../../constants/pathsByType';

import IfFeature from '../IfFeature';
import { ReduxState } from 'global/types';

const { SEARCHES_EDIT, JOBS, DASHBOARD, MY_ARCHIVE_SPLIT_VIEW } = Pages;

type Props = {
  visibleMenus: {
    search: string[],
    nav: string
  },
  staticView: string
  getMessage: Function
}

// if split view is not enabled, don't show these links
export const NO_SEARCHES_EDIT = [SEARCHES_EDIT];

// verify pathsByType is correct and return friendly error messages if it's not.
pathsByType.forEach(({ path }) => {
  if (!ROUTES[path]) {
    throw new Error(
      `No route defined for ${path}. Check the ROUTES object keys.`,
    );
  }

  if (!jsUi[ROUTES[path]]) {
    throw new Error(
      `No route info defined for ${path} (I tried ${
        ROUTES[path]
      }). Check the ROUTES object keys.`,
    );
  }
});

const paths = pathsByType.reduce(
  (all, { type, path }) => ({ ...all, [type]: jsUi[ROUTES[path]] }),
  {},
);

const mapStateToProps = ({ userRights }: ReduxState) => ({
  visibleMenus: userRights.visibleMenus,
  activeRoute: userRights.activeRoute,
});

export function prioritizeLinks(links: any[]) {
  return links.sort((a, b) => menuOrder.indexOf(a) - menuOrder.indexOf(b));
}

export function buildMenuStructure({ navLinks, searchLinks }: any) {
  let visibleNavLinks = prioritizeLinks(navLinks);
  let visibleSearchLinks = prioritizeLinks(searchLinks);

  if (IfFeature.isDisabled('account.summary')) {
    visibleSearchLinks = without(visibleSearchLinks, DASHBOARD);
  }
  if (IfFeature.isDisabled('jobs_page')) {
    visibleNavLinks = without(visibleNavLinks, JOBS)
  }
  visibleSearchLinks = without(visibleSearchLinks, ...NO_SEARCHES_EDIT);

  return flatten([visibleSearchLinks, visibleNavLinks]);
}

@connect(mapStateToProps)
@withMessages
export default class LeftHandNav extends Component<Props> {
  static propTypes = {
    visibleMenus: PropTypes.object.isRequired,
    staticView: PropTypes.bool,
    getMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    staticView: false,
  };

  makeLinks = (links: any[]) =>
    links.map(type => ({
      name: this.props.getMessage(`app.left_hand_nav.${type}`),
      type,
      path: paths[type].path(),
    }));

  render() {
    const {
      visibleMenus: { search: searchLinks, nav: navLinks },
      staticView,
    } = this.props;
    const modifiedSearchLinks = searchLinks.filter((link: string) => link !== MY_ARCHIVE_SPLIT_VIEW);
    const links = this.makeLinks(buildMenuStructure({ navLinks, searchLinks: modifiedSearchLinks }));

    const wrapperClass = `left-hand-nav${staticView ? '-static' : ''}`;

    return (
      <div className={wrapperClass}>
        <LeftColumnWrapper>
          <LeftHandNavComponent links={links} />
        </LeftColumnWrapper>
      </div>
    );
  }
}
