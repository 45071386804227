import React from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton, SecondaryButton, Spacer } from 'v-c/Buttons/Components';

import styles from './Settings.scss';

export default function AccountSettingsButtons({ buttons, getMessage }: any) {
  return (
    <div className={styles['account-settings-buttons']}>
      <PrimaryButton
        data-action="save"
        {...buttons.save}
      >
        {getMessage('app.settings.account_settings.contents.buttons.save')}
      </PrimaryButton>
      <Spacer />
      <SecondaryButton
        data-action="cancel"
        {...buttons.cancel}
      >
        {getMessage('app.settings.account_settings.contents.buttons.cancel')}
      </SecondaryButton>
    </div>
  );
}

AccountSettingsButtons.propTypes = {
  buttons: PropTypes.shape({
    save: PropTypes.object.isRequired,
    cancel: PropTypes.object.isRequired,
  }).isRequired,
  getMessage: PropTypes.func.isRequired,
};
