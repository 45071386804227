import configs from '../../../../config/configs';

const { REGEXPS } = configs;

export default function domainError(domainName: string) {
  const isDomainValid = new RegExp(REGEXPS.SIMPLE_DOMAIN).test(domainName);
  let formError = '';

  if (!domainName || (!!domainName.length && !isDomainValid)) {
    formError = 'app.settings.account_settings.invalid_domain';
  }

  return formError;
}
