/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { forceLogout } from '../../global/state_managers/AuthenticationStateManager';

const mapActionsToProps = { forceLogout };

type Props = {
  forceLogout: Function
}

@connect(
  null,
  mapActionsToProps,
)
export default class Logout extends Component {
  static propTypes = {
    forceLogout: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    props.forceLogout();
  }

  render() {
    return <noscript />;
  }
}
