import React from 'react';
import PropTypes from 'prop-types';

import { LowerSection } from 'view-components/components/Dialog/Components';

import CloseButtonContainer from './CloseButtonContainer';

type Props = {
  additionalTopContent: string,
  onDismiss: Function,
}

const LowerSectionDismiss = (props: Props) => {
  const {
    additionalTopContent,
    onDismiss,
  } = props;

  return (
    <LowerSection>
      { additionalTopContent }
      <CloseButtonContainer
        onDismiss={onDismiss}
      />
    </LowerSection>
  );
};

LowerSectionDismiss.propTypes = {
  additionalTopContent: PropTypes.node.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default LowerSectionDismiss;
