/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import reduxStore from '../reduxStore';

import configs from '../../config/configs';
import { ReduxState } from 'global/types';

const mapStateToProps = ({ contextTree }: ReduxState) => ({ contextTree });
const mapActionsToProps = {};

const {
  REVIEW_ATTRIBUTES: {
    CONTEXT: REVIEW_CONTEXT,
    ATTRIBUTES: {
      reviewed,
      needsReview,
      noReviewState,
    },
  },
  LEGAL_HOLD_ATTRIBUTES: {
    CONTEXT: LEGAL_HOLD_CONTEXT,
    ATTRIBUTES: {
      REASONS,
    },
  },
} = configs.CONTEXTS;

export {
  REVIEW_CONTEXT,
  LEGAL_HOLD_CONTEXT,
  REASONS,
  reviewed,
  needsReview,
  noReviewState,
};

export const REVIEW_STATUSES = {
  REVIEWED: reviewed,
  NEEDS_REVIEW: needsReview,
  NO_REVIEW_STATE: noReviewState,
};

export function getTree() {
  return reduxStore.getState().contextTree.tree;
}

export function getContext({ contextName }: any, tree = getTree()) {
  const contextId = Object.keys(tree).find(
    context => tree[context].name === contextName,
  );

  return tree[contextId];
}

export function getAttribute({ contextName, attributeName }: any) {
  const context = getContext({ contextName });

  return Object.keys(context.attributes).find(
    attribute => context.attributes[attribute].name === attributeName,
  );
}

export function getReviewAttributeId(status: string) {
  return getAttribute({ contextName: REVIEW_CONTEXT, attributeName: status });
}

export function getReasonsAttributeId() {
  return getAttribute({ contextName: LEGAL_HOLD_CONTEXT, attributeName: REASONS });
}

export default function contextTreeHelper(component: any) {
  @connect(mapStateToProps, mapActionsToProps)
  class ContextTreeHelper extends Component<{
    contextTree: {
      tree: any
    }
  }> {
    static propTypes = {
      contextTree: PropTypes.object.isRequired,
    };

    get tree() {
      return this.props.contextTree.tree;
    }

    getContext = getContext;

    getAttribute = getAttribute;

    getReviewAttributeId = getReviewAttributeId;

    getReasonsAttributeId = getReasonsAttributeId;

    render() {
      return React.createElement(component, {
        ...this.props,
        contextTree: {
          value: this.props.contextTree,
          REVIEW_STATUSES,
          getReviewAttributeId: this.getReviewAttributeId,
          getReasonsAttributeId: this.getReasonsAttributeId,
        },
      });
    }
  }

  return ContextTreeHelper;
}
