/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isArray } from 'lodash';
import { ModalProps } from 'global/types';

export const PURGE_ACTION_NAME = 'On Demand Purge';

export function getStringValue(value: string | string[]) {
  return isArray(value) ? value.join(', ') : value;
}

interface Props extends ModalProps {
  values: {
    reason: {}
  },
  purgeAsset: Function,
  activeRoles: string[],
  resetPurge: Function,
  closeModal: Function,
  purge: {
    isProcessing: boolean,
    isSuccessed: boolean,
    isFailed: boolean,
  },
}

export default function DeleteAssetModalDeconstructed({
  DELETE_ASSET_MODAL_STATUSES,
}: any) {
  return function DeleteAssetModalStateLogicComposer(component: any) {
    class DeleteAssetModalStateLogic extends Component<Props> {
      static propTypes = {
        modalOptions: PropTypes.object.isRequired,
        fields: PropTypes.object.isRequired,
        values: PropTypes.object.isRequired,
        purgeAsset: PropTypes.func.isRequired,
        activeRoles: PropTypes.array.isRequired,
        resetPurge: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        purge: PropTypes.shape({
          isProcessing: PropTypes.bool.isRequired,
          isSuccessed: PropTypes.bool.isRequired,
          isFailed: PropTypes.bool.isRequired,
        }).isRequired,
      };

      constructor(props: Props) {
        super(props);

        // We need to rerender the component once more because of the redux-form bug
        props.fields.isConfirmed.onChange(false);
      }

      onDelete = () => {
        this.props.purgeAsset(this.purgeOptions);
      };

      onCloseModal = () => {
        if (this.props.purge.isSuccessed) {
          this.props.modalOptions.onSuccess();
        }

        this.props.resetPurge();
        this.props.closeModal();
      };

      get modalStatus() {
        const { purge } = this.props;

        switch (true) {
          case purge.isProcessing:
            return DELETE_ASSET_MODAL_STATUSES.PENDING;
          case purge.isSuccessed:
            return DELETE_ASSET_MODAL_STATUSES.SUCCESS;
          case purge.isFailed:
            return DELETE_ASSET_MODAL_STATUSES.FAILED;
          default:
        }

        return DELETE_ASSET_MODAL_STATUSES.NORMAL;
      }

      get confirmProp() {
        const {
          fields: { isConfirmed },
        } = this.props;

        return {
          checked: isConfirmed.value || false,
          onClick: () => {
            isConfirmed.onChange(!isConfirmed.value);
          },
        };
      }

      get purgeOptions() {
        const {
          activeRoles,
          values,
          modalOptions: { asset },
        } = this.props;

        return {
          roles: activeRoles,
          action: PURGE_ACTION_NAME,
          reason: values.reason,
          date: asset.data.date,
          id: asset.id,
          from: getStringValue(asset.data.from),
          to: getStringValue(asset.data.to),
          cc: getStringValue(asset.data.cc),
          bcc: getStringValue(asset.data.bcc),
        };
      }

      render() {
        return React.createElement(component, {
          ...this.props,
          onCloseModal: this.onCloseModal,
          onDelete: this.onDelete,
          modalStatus: this.modalStatus,
          confirmProp: this.confirmProp,
          purgeOptions: this.purgeOptions,
        });
      }
    }

    return DeleteAssetModalStateLogic;
  };
}
