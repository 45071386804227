import React from 'react';
import PropTypes from 'prop-types';

import {
  CustomCheckbox,
  TextLabel,
} from 'view-components/components/Widgets/Components';

type Props = {
  id: {},
  onClick: {},
  onMouseOver: {},
  isChecked: {},
  forceHover: {},
  reasonName: {},
}

const HoldReason = ({ id, onClick, onMouseOver, isChecked, forceHover, reasonName }: Props) => (
  <div
    className="hold-reason-row"
    data-row={id}
    {...{ onClick, onMouseOver }}
  >
    <CustomCheckbox
      widgetId={id.toString()}
      className="content-checkbox"
      {...{ isChecked, forceHover }}
    />
    <TextLabel className="text-label">
      {reasonName}
    </TextLabel>
  </div>
);

HoldReason.propTypes = {
  id: PropTypes.any.isRequired,
  onClick: PropTypes.any.isRequired,
  onMouseOver: PropTypes.any.isRequired,
  isChecked: PropTypes.any.isRequired,
  forceHover: PropTypes.any.isRequired,
  reasonName: PropTypes.any.isRequired,
};

export default HoldReason;
