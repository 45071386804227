/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { last, includes } from 'lodash';

import withMessages from 'component-utilities/intl';
import { DropdownMultiSelect } from 'view-components/components/Widgets/Components';
import { LargeCheckbox } from 'v-c/Forms/Checkboxes';
import FormErrorText from 'v-c/Forms/FormErrorText';
import FormHint from 'v-c/Forms/FormHint';
import TooltipGrayIcon from 'v-c/Icons/TooltipGrayIcon';
import Tooltip from 'v-c/Tooltip/Tooltip';
import toggleInArray from 'component-lib/toggleInArray';

import CriteriaMultiTextEditor from './CriteriaMultiTextEditor';
import criteriaViewBase from './CriteriaViewBase';
import headerTextRender from './HeaderTextRender';

import styles from './CriteriaViewBase.scss';
import {
  KEYWORDS,
  ADV_PARTICIPANTS,
} from '../../../../../data/Criteria';
import validDomain from '../../../../../features/Settings/lib/validDomain';

type Props = {
  isCriteriaDropdownVisible: boolean
  getMessage: Function,
  updateBaseState: Function,
  justCancelled: boolean,
  justApplied: boolean,
  form: {
    selectedCriteria: string
    list: string[]
    relation: string
    isAdvParticipantsDomain: boolean
    isKeywordExact: boolean
  },
  __originalFormValues__: {},
  getOriginalFormValues: Function,
  changeFormValue: Function,
  criteriaValues: {}[],
  isDirty: Function,
  defaults: {
    relations: {
      AND: string
      OR: string
    }
  },
  listElem: {
    criteriaType: string
  },
  isViewOnly: boolean,
  renderContent: Function,
  showMatchKeywordExact: boolean,
  showDomains: boolean
}

type State = {
  isCriteriaDropdownVisible: boolean
  focusedIndex: number
}

export default function MultiSelectTypeCriteriaComposer({ init, instances }: any) {
  @withMessages
  @headerTextRender
  @criteriaViewBase
  class MultiSelectTypeCriteria extends Component<Props, State> {
    static propTypes = {
      getMessage: PropTypes.func.isRequired,
      updateBaseState: PropTypes.func.isRequired,
      justCancelled: PropTypes.bool.isRequired,
      justApplied: PropTypes.bool.isRequired,
      form: PropTypes.object.isRequired,
      __originalFormValues__: PropTypes.object,
      getOriginalFormValues: PropTypes.func.isRequired,
      changeFormValue: PropTypes.func.isRequired,
      criteriaValues: PropTypes.array.isRequired,
      isDirty: PropTypes.func.isRequired,
      defaults: PropTypes.object.isRequired,
      listElem: PropTypes.object.isRequired,
      isViewOnly: PropTypes.bool.isRequired,
      renderContent: PropTypes.func.isRequired,
      showMatchKeywordExact: PropTypes.bool.isRequired,
      showDomains: PropTypes.bool.isRequired,
    };

    static defaultProps = {
      __originalFormValues__: {},
    };

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
      const { isCriteriaDropdownVisible: nextVisibleValue } = nextProps;
      const { isCriteriaDropdownVisible: currentVisibleValue } = prevState;

      if (nextVisibleValue && !currentVisibleValue) {
        return {
          focusedIndex: nextProps.form.list.length - 1,
          isCriteriaDropdownVisible: nextVisibleValue,
        };
      }

      return { isCriteriaDropdownVisible: nextVisibleValue };
    }

    constructor(props: Props) {
      super(props);

      Object.keys(instances).forEach((instance) => {
        this[instance] = instances[instance].bind(this);
      });

      init.apply(this, [props]);
    }

    componentDidUpdate() {
      if (this.props.justCancelled || this.props.justApplied) {
        const {
          form: { list },
          __originalFormValues__,
        } = this.props;

        if (last(list).length) {
          list.push('');
          __originalFormValues__.list.push('');
        }

        this.props.updateBaseState({
          justApplied: false,
          justCancelled: false,
        });
      }
    }

    onBlur = (widgetId: string, idx: number) => () => {
      const list = this.getList();

      // If a list elem lost focus and it's value is empty,
      //   then we have to delete it
      if (!list[idx].length && list.length > 1 && idx !== list.length - 1) {
        list.splice(idx, 1);

        this.props.changeFormValue(widgetId, list);
      }
    };

    onClick = (idx: number) => {
      this.setState({ focusedIndex: idx });
    };

    onChange = (widgetId: string, idx: number) => ({ target: { value } }: any) => {
      const list = this.getList();
      list[idx] = value;

      this.props.changeFormValue(widgetId, list);
    };

    onKeyUp = (widgetId: string, idx: number) => ({ keyCode, which, shiftKey, target }: any) => {
      const validKeyCode = keyCode || which;
      const list = this.getList();
      // quoteCount tracks how many double quotes havee been entered on the current criiteria
      const quoteCount = list[idx].match(/"/g) || [];
      const doubleQuotePressed = !!(keyCode === 222 && shiftKey);

      // If current criteria starts with double quote OR if there are two or more double quotes pasted in the criteria
      if (!list[idx].startsWith('"') || quoteCount.length >= 2) {
        // If current criteria has one more more quotes OR if the current criteria starts with a double quote
        // Then create an array to break each phrase and term into its own criteria
        // Otherwise use spaces and commas to create new criteria
        const splitKeyword = quoteCount.length >= 1 || list[idx][0] === '"' ?
          list[idx].match(/(".*?"|[^" ,\s]+)(?=\s*|\s*$)/g) :
          list[idx].split(/[ ,]+/);
        const nextValues = list.splice(idx + 1);

        // Below condition prevents new empty criteria from being created
        if (last(splitKeyword) === '') {
          splitKeyword.pop();
        }

        // Create list index for each split value
        splitKeyword.forEach((item: {}, i: number) => {
          list[idx + i] = splitKeyword[i];
          this.setState({
            focusedIndex: idx + (splitKeyword.length - 1),
          });
        });

        // Re-adds each of the spliced criteria to the list array
        nextValues.forEach((item: string) => {
          list.push(item);
        });
      }

      // If the user pressed the backspace button second time
      //   in an empty field, then we have to remove it
      if (validKeyCode === 8 && this.canDelete) {
        list.splice(idx, 1);
        this.canDelete = false;
        this.setState({ focusedIndex: idx - 1 });

        // If the user pressed the backspace button first time
        //   in an empty field
      } else if (validKeyCode === 8 && !list[idx].length && list.length > 1) {
        this.canDelete = true;

      // If the user presses the left arrow key on the last criteria
      // and the criteria is empty, move the focused index up one level
      } else if (validKeyCode === 37 && !list[idx].length) {
        this.setState({ focusedIndex: idx - 1 });

      // If the user presses Enter or a double quote and the current criteria is a phrase
      // If the user presses Enter, Space, or Comma and the criteria is not a phrase
      // If there are characters following a quoted phrase in a single criteria
      } else if (((includes([13, 32, 188], validKeyCode) || doubleQuotePressed) && quoteCount.length > 1) ||
        (!list[idx].startsWith('"') && includes([13, 32, 188], validKeyCode)) ||
        (quoteCount.length === 2 && !list[idx].endsWith('"'))) {
        // on the last list elem what's value isn't empty,
        //   then we have to add a new empty elem to the list's end
        if (last(list).length && idx === list.length - 1) {
          list.push('');
        }

        // Corrects the focused index when there are less than two quotes for current criteria
        if (quoteCount.length < 2 ||
          ((includes([13], validKeyCode) || doubleQuotePressed) && quoteCount.length > 1) ||
          (includes([32, 188], validKeyCode) && target.selectionStart === (list[idx].length + 1))) {
          this.setState({
            focusedIndex: Math.min(idx + 1, list.length - 1),
          });
        }
      } else {
        this.canDelete = false;
      }

      this.props.changeFormValue(widgetId, list);
    };

    onCriteriaChanged = (widgetId: string, newValues: any) => {
      const {
        form: { selectedCriteria },
      } = this.props;

      this.props.changeFormValue(
        widgetId,
        toggleInArray(selectedCriteria, newValues),
      );
    };

    getList = () => this.props.form.list.slice(0);

    isListValid = (list: string[]) => {
      const validList = list || this.props.form.list;

      if (!validList) {
        return false;
      }

      return !!validList.filter(listElem => listElem.length).length;
    };

    isApplyButtonDisabled = () => {
      const isDirty = this.props.isDirty();
      const {
        form: { selectedCriteria },
      } = this.props;

      if (
        !this.isListValid()
        || !selectedCriteria.length 
        || this.getDomainError() !== null
        || this.domainInvalidHelper() !== null
      ) return true;

      return !isDirty;
    };

    toggleRelation = () => {
      const {
        defaults: {
          relations: { AND, OR },
        },
        form: { relation },
      } = this.props;
      const newRelation = relation === AND ? OR : AND;
      this.props.changeFormValue('relation', newRelation);
    };

    isEmptyCriteria = (newValues = this.props.getOriginalFormValues()) => {
      const { list, selectedCriteria } = newValues;

      return !this.isListValid(list) || !selectedCriteria.length;
    };

    getDomainError = () => {
      const {
        form: { list, isAdvParticipantsDomain },
      } = this.props;
      const allAreValid = list.filter(l => l.trim().length > 0).every(l => !l.includes('*') && !l.includes('@')) 
      if (isAdvParticipantsDomain && !allAreValid) {
        return 'app.criterias.domains_error';
      }
      return null
    };

    domainInvalidHelper = () => {
      const {
        form: { list, isAdvParticipantsDomain },
      } = this.props;
      const allAreValid = list.filter(l => l.trim().length > 0).every(l => validDomain(l)) 
      if (isAdvParticipantsDomain && !allAreValid) {
        return 'app.criterias.domains_error';
      }
      return null
    }

    renderCriteriaDropdownContent = () => {
      const {
        form: { selectedCriteria, list, relation, isKeywordExact, isAdvParticipantsDomain },
        listElem: { criteriaType },
        isViewOnly,
        criteriaValues,
        showMatchKeywordExact,
        showDomains,
      } = this.props;
      const relationText = this.props
        .getMessage(`app.criterias.${relation}`)
        .toLowerCase();

      return (
        <div
          className={styles[`${criteriaType}-criteria-dropdown-content`]}
          data-criteria-content={criteriaType}
        >
          <div className={styles[`${criteriaType}-group-wrapper`]}>
            <DropdownMultiSelect
              widgetId="selectedCriteria"
              prefixText={this.inTheText}
              color="gray"
              list={criteriaValues}
              selectedValues={selectedCriteria}
              onSelectedValue={!isViewOnly && this.onCriteriaChanged}
            />
          </div>
          {showDomains && criteriaType === ADV_PARTICIPANTS && (
            <div>
              <div className={styles.exact}>
              <LargeCheckbox
                checked={isAdvParticipantsDomain}
                onClick={() => this.props.changeFormValue(
                  'isAdvParticipantsDomain',
                  !isAdvParticipantsDomain,
                )}
              >
                {this.props.getMessage('app.criterias.domains')}
              </LargeCheckbox>
              <Tooltip
                position="right"
                type="medium"
                content={this.props.getMessage('app.new_search.tooltips.domain')}
              >
                <TooltipGrayIcon />
              </Tooltip>
              </div>
              <FormErrorText
                field="advanced-search-participants-error"
                error={this.getDomainError()}
              />
              {
                !this.getDomainError()
                && this.domainInvalidHelper()
                && <FormHint>
                  {this.props.getMessage(this.domainInvalidHelper())}
                </FormHint>
              }
            </div>)
          }
          {showMatchKeywordExact && criteriaType === KEYWORDS && (
            <div className={styles.exact}>
              <LargeCheckbox
                checked={isKeywordExact}
                onClick={() => this.props.changeFormValue(
                  'isKeywordExact',
                  !isKeywordExact,
                )}
              >
                {this.props.getMessage('app.criterias.exact')}
              </LargeCheckbox>
            </div>)
          }
          <div className={styles[`${criteriaType}-text-editor-wrapper`]}>
            <CriteriaMultiTextEditor
              widgetId="list"
              list={list}
              relation={relationText}
              toggleRelation={this.toggleRelation}
              focusedIndex={this.state.focusedIndex}
              onClick={this.onClick}
              onBlur={this.onBlur}
              onKeyUp={this.onKeyUp}
              onChange={this.onChange}
              isViewOnly={isViewOnly}
              criteriaType={criteriaType}
              isAdvParticipantsDomain={isAdvParticipantsDomain}
            />
          </div>
        </div>
      );
    };

    render() {
      return this.props.renderContent(
        this.renderCriteriaDropdownContent,
        this.renderCriteriaLabel,
      );
    }
  }

  return MultiSelectTypeCriteria;
}
