import { Action } from 'global/reduxStore';
import { has } from 'lodash';

export default function createReducer(initialState: any, handlers: any) {
  const generateState = () => {
    if (typeof initialState === 'function') {
      return initialState();
    }
    return initialState;
  };

  return function reducer(state = generateState(), { type, payload }: Action) {
    if (has(handlers, type)) {
      return handlers[type](state, payload);
    }
    return state;
  };
}
