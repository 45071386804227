import React from 'react';
import PropTypes from 'prop-types';

import withMessages, { intlPropTypes } from 'component-utilities/intl';

import { SearchField, TextLabel } from 'v-c/Widgets/Components';

type Props = {
  actionHelper: {
    search: string
  }
  getMessage: Function
  searchHelper: {
    value: any
    isTableHasHiddenColumns: boolean
    placeholder: string
  }
}

function search(props: Props) {
  const { getMessage } = props;
  const searchHelper = props.searchHelper || props.actionHelper.search;

  const {
    value,
    isTableHasHiddenColumns,
    placeholder,
  } = searchHelper;

  const searchHelpText = getMessage('app.search.hidden_help');
  const searchFieldProps = {
    searchText: getMessage('app.general.find'),
    placeholder: placeholder || getMessage('app.search.enter_criteria'),
  };

  return (
    <data>
      {
        !!value.length && isTableHasHiddenColumns &&
        <TextLabel className="search-help">{searchHelpText}</TextLabel>
      }
      <div
        className="search-field"
        data-action="search-action"
      >
        <SearchField {...searchHelper} {...searchFieldProps} />
      </div>
    </data>
  );
}

search.propTypes = {
  searchHelper: PropTypes.object,
  actionHelper: PropTypes.object,
  ...intlPropTypes,
};

search.defaultProps = {
  searchHelper: null,
  actionHelper: {},
};

const Search = withMessages(search);

export default Search;
