import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EditReport from 'v-c/Modals/EditReport';

type Props = {
  modalOptions: {
    onButtonClick: Function,
    reportType: string
  },
  closeModal: Function
}

type State = {
  modal: {
    doNotShowAgain: Boolean
  }
}

export default class EditReportModal extends Component<Props, State> {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    modalOptions: PropTypes.shape({
      onButtonClick: PropTypes.func.isRequired,
      reportType: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      modal: { doNotShowAgain: false },
    };
  }

  onPrimaryButtonClick = () => {
    this.props.modalOptions.onButtonClick({
      buttonPressed: 'primary',
      doNotShowAgain: this.state.modal.doNotShowAgain,
    });
  };

  onSecondaryButtonClick = () => {
    this.props.closeModal({ from: 'EditReportModal/cancel' });
  };

  toggleDoNotShowAgain = () => {
    this.setState({
      modal: { doNotShowAgain: !this.state.modal.doNotShowAgain },
    });
  };

  render() {
    const { modal } = this.state;
    const { modalOptions: { reportType }, closeModal } = this.props;

    return (
      <EditReport
        modal={modal}
        type={reportType}
        doNotShowAgainFor={reportType}
        toggleDoNotShowAgain={this.toggleDoNotShowAgain}
        close={() => closeModal({ from: 'EditReportModal/close' })}
        onPrimaryButtonClick={this.onPrimaryButtonClick}
        onSecondaryButtonClick={this.onSecondaryButtonClick}
      />
    );
  }
}
