import sortByJobStatusOrder from 'component-lib/sortByJobStatusOrder';
import sortByMomentDates from 'component-lib/sortByMomentDates';
import sortByNaturalString from 'component-lib/sortByNaturalString';

import ensureNewVisibleColumnSortOrder from 'component-lib/ensureNewVisibleColumnSortOrder';
import withExpiresSort from '../lib/withExpiresSort';
import createReducer from '../lib/createReducer';

const RESET_FILTER: string = 'exportTableUI/resetFilter';
const SET_FILTER: string = 'exportTableUI/setFilter';
const SORT_BY: string = 'exportTableUI/sortBy';
const SET_VISIBLE_COLUMNS: string = 'exportTableUI/setVisibleColumns';
const DROPDOWN_SUCCESS: string = 'exportTableUI/dropdownSuccess';
const DROPDOWN_DUPLICATED: string = 'exportTableUI/dropdownDuplicated';
const DROPDOWN_FAILURE: string = 'exportTableUI/dropdownFailure';
const DROPDOWN_RESET: string = 'exportTableUI/dropdownReset';
const DROPDOWN_RESET_WITH_SUCCESS: string = 'exportsTableUI/dropdownResetWithSuccess';

export const COLUMNS = {
  EXPORT_STATUS: 'status',
  EXPORT_NAME: 'exportName',
  REQUESTED_BY: 'requester',
  EXPIRES_ON: 'expiresOn',
  RESULTS: 'itemCount',
  FORMAT: 'format',
};

const {
  EXPORT_STATUS,
  EXPORT_NAME,
  REQUESTED_BY,
  EXPIRES_ON,
  RESULTS,
  FORMAT,
} = COLUMNS;

export const ALL_COLUMNS = [
  EXPORT_STATUS,
  EXPORT_NAME,
  REQUESTED_BY,
  EXPIRES_ON,
  RESULTS,
  FORMAT,
];
export const DISABLED_COLUMNS = [EXPORT_STATUS, EXPORT_NAME];

interface ExportTableUIState {
  filter: string,
  sort: {
    key: string,
    order: string,
  },
  visibleColumns: string[],
  dropdown: {
    show: boolean,
    success: null | boolean,
  },
}

export const INITIAL_STATE = {
  filter: '',
  sort: {
    key: 'status',
    order: 'asc',
  },
  visibleColumns: ALL_COLUMNS,
  dropdown: {
    show: false,
    success: null,
  },
};

export function sort(payload: any) {
  return {
    type: SORT_BY,
    payload: { sort: payload },
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
    payload: { filter: INITIAL_STATE.filter },
  };
}

export function setFilter(filter: string) {
  return {
    type: SET_FILTER,
    payload: { filter },
  };
}

export function setVisibleColumns(payload: any) {
  return {
    type: SET_VISIBLE_COLUMNS,
    payload,
  };
}

export function filterExports(exports: any, filter: any) {
  return exports.filter((job: any) =>
    [REQUESTED_BY, EXPORT_NAME, RESULTS].some(
      field =>
        String(job[field]).toLowerCase().indexOf(filter.toLowerCase()) !== -1,
    ),
  );
}

export function sortExports({ exports, key, order }: any) {
  let sortOp;

  switch (key) {
    case EXPORT_STATUS: {
      sortOp = withExpiresSort(sortByJobStatusOrder(order));
      break;
    }
    case EXPIRES_ON: {
      sortOp = sortByMomentDates(order, 'expiresOn');
      break;
    }
    default:
      sortOp = withExpiresSort(sortByNaturalString({ key, order }));
      break;
  }

  return [...exports].sort(sortOp);
}

export function updateDropdown(state: string) {
  switch (state) {
    case 'success':
      return { type: DROPDOWN_SUCCESS };
    case 'duplicated':
      return { type: DROPDOWN_DUPLICATED };
    case 'failure':
      return { type: DROPDOWN_FAILURE };
    case 'reset':
      return { type: DROPDOWN_RESET };
    case 'resetWithSuccess':
      return { type: DROPDOWN_RESET_WITH_SUCCESS };
    default:
      throw new Error(`Unknown state: ${state}`);
  }
}

const ExportTableUIStateManager = createReducer(INITIAL_STATE, {
  [RESET_FILTER]: (state: ExportTableUIState, payload: any) => ({ ...state, ...payload }),
  [SET_FILTER]: (state: ExportTableUIState, payload: any) => ({ ...state, ...payload }),
  [SORT_BY]: (state: ExportTableUIState, payload: any) => ({ ...state, ...payload }),
  [SET_VISIBLE_COLUMNS]: (state: ExportTableUIState, payload: any) => ({
    ...state, visibleColumns: ensureNewVisibleColumnSortOrder(payload, ALL_COLUMNS),
  }),
  [DROPDOWN_SUCCESS]: (state: ExportTableUIState) => ({
    ...state, dropdown: { show: true, success: true },
  }),
  [DROPDOWN_DUPLICATED]: (state: ExportTableUIState) => ({
    ...state, dropdown: { show: true, success: true, duplicated: true },
  }),
  [DROPDOWN_FAILURE]: (state: ExportTableUIState) => ({
    ...state, dropdown: { show: true, success: false },
  }),
  [DROPDOWN_RESET]: (state: ExportTableUIState) => ({
    ...state, dropdown: { show: false, success: null },
  }),
  [DROPDOWN_RESET_WITH_SUCCESS]: (state: ExportTableUIState) => ({
    ...state, dropdown: { show: false, success: true },
  }),
});

export default ExportTableUIStateManager;
