/* eslint-disable no-undef */
import React, {
  Children,
  cloneElement,
  Component,
  ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash';

import { findDOMNode } from 'react-dom';

type Props = {
  maximize: boolean,
  notifyHeight: Function,
  children: ReactNode,
  className: string,
}

export default class Section extends Component<Props> {
  static propTypes = {
    maximize: PropTypes.bool,
    notifyHeight: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    maximize: false,
    notifyHeight: () => {},
    children: null,
  };

  componentDidMount() {
    window.setTimeout(this.sizeIt, 0);
  }

  componentDidUpdate() {
    window.setTimeout(this.sizeIt, 0);
  }

  get DOMNodeHeight() {
    return $(findDOMNode(this)).outerHeight(true);
  }

  sizeIt = () => {
    try {
      findDOMNode(this);
    } catch (e) {
      return null;
    }

    if (!this.props.maximize) {
      this.props.notifyHeight(this.DOMNodeHeight);
    }

    return true;
  }

  render() {
    const { maximize, children, className } = this.props;

    const modifiedChildren = Children.map(children, (child) => {
      if (child && !isString(child.type)) {
        return cloneElement(child, {
          notifyHeight: () => {
            this.props.notifyHeight(this.DOMNodeHeight);
          },
        });
      }

      return child;
    });

    const classes = `vertical-section ${className}`;

    return (
      <section
        className={classes}
        data-maximized={maximize}
      >
        { modifiedChildren }
      </section>
    );
  }
}
