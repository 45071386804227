import changeObjectCase from 'change-object-case';
import deepmerge from 'deepmerge';
import reduxStore, { PromiseObject } from '../reduxStore';

import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';

import {
  resetFlash,
  showSuccessFlash,
  showFailureFlash,
} from './FlashMessagesStateManager';
import { PAGES } from '../components/FlashMessages';

const VALID_FLASH_VIEWS = [PAGES.REPORTS];

const IS_LOADING = 'reports/is-loading';
const IS_READY = 'reports/is-ready';
const IS_ERROR = 'reports/is-error';
const ALL_REPORTS = 'reports/all-reports';
const SINGLE_REPORT = 'reports/single-report';
const REPLACE_REPORT_IN_ALL_REPORTS = 'reports/replace-report-in-all-reports';

const STATUS_IS_UPDATING = 'reports/status-is-updating';
const STATUS_IS_DONE_UPDATING = 'reports/status-is-done-updating';

const SINGLE_IS_UPDATING = 'reports/single-is-updating';
const SINGLE_IS_UPDATE_SUCCESS = 'reports/single-is-update-success';
const SINGLE_IS_UPDATE_FAILURE = 'reports/single-is-update-failure';

function userId() {
  return reduxStore.getState().authentication.loggedInAs.userId;
}

export function all() {
  return function* doAll() {
    yield {
      type: IS_LOADING,
    };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.reportsIndex, {
        urlParams: {
          user_id: userId(),
        },
      });

      yield {
        type: ALL_REPORTS,
        payload: result.data,
      };

      yield {
        type: IS_READY,
      };
    } catch (e) {
      console.error(e);

      yield {
        type: IS_ERROR,
        payload: e,
      };
    }
  };
}

export function single(id: string) {
  return function* doAll() {
    yield {
      type: IS_LOADING,
    };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.reportsShow, {
        urlParams: { id, user_id: userId() },
      });

      yield {
        type: SINGLE_REPORT,
        payload: result.data,
      };

      yield {
        type: IS_READY,
      };
    } catch (e) {
      console.error(e);

      yield {
        type: IS_ERROR,
        payload: e,
      };
    }
  };
}

function updateReport(id: string, data: any) {
  return apiReqPromise(jsApi.reportsUpdate, {
    params: { data },
    urlParams: { id, user_id: userId() },
  });
}

export function update(id: string, newData: any) {
  return function* doUpdate() {
    yield {
      type: SINGLE_IS_UPDATING,
      payload: id,
    };

    try {
      yield updateReport(id, changeObjectCase.snakeKeys(newData));

      yield {
        type: SINGLE_IS_UPDATE_SUCCESS,
      };
    } catch (e) {
      console.error(e);

      yield {
        type: SINGLE_IS_UPDATE_FAILURE,
        payload: e,
      };
    }
  };
}

export function toggle(report: any) {
  return function* doToggle() {
    yield {
      type: STATUS_IS_UPDATING,
      payload: report.id,
    };

    const newEnabled = !report.enabled;

    reduxStore.dispatch(resetFlash({ from: 'ReportStateManager' }));

    try {
      const result: PromiseObject = yield updateReport(report.id, { enabled: newEnabled });

      yield {
        type: REPLACE_REPORT_IN_ALL_REPORTS,
        payload: result.data,
      };

      if (newEnabled) {
        reduxStore.dispatch(
          showSuccessFlash('reports.toggle.enabled', VALID_FLASH_VIEWS),
        );
      } else {
        reduxStore.dispatch(
          showSuccessFlash('reports.toggle.disabled', VALID_FLASH_VIEWS),
        );
      }
    } catch (e) {
      console.error(e);

      reduxStore.dispatch(
        showFailureFlash('reports.toggle', VALID_FLASH_VIEWS),
      );
    }

    yield {
      type: STATUS_IS_DONE_UPDATING,
      payload: report.id,
    };
  };
}

const INITIAL_STATE = {
  reports: null,
  singleReport: null,
  isLoading: false,
  isReady: false,
  isError: false,
};

function setStatusOnSingleReport(reports: any, { id, statusIsUpdating }: any) {
  return reports.map((report: any) => {
    if (report.id === id) {
      return { ...report, statusIsUpdating };
    }

    return report;
  });
}

export default function ReportsStateManager(
  state = INITIAL_STATE,
  { type, payload }: any,
) {
  switch (type) {
    case IS_LOADING:
      return { ...INITIAL_STATE, isLoading: true, isError: false };
    case IS_READY:
      return { ...state, isLoading: false, isReady: true };
    // this seems good, so it should all be here
    case IS_ERROR:
      return { ...state, isLoading: false, isError: true, error: payload };
    case ALL_REPORTS:
      return { ...state, reports: payload };
    case SINGLE_REPORT:
      return {
        ...state,
        isUpdating: false,
        isUpdated: false,
        isError: false,
        singleReport: changeObjectCase.camelKeys(payload),
      };
    case REPLACE_REPORT_IN_ALL_REPORTS:
      return {
        ...state,
        reports: state.reports.map((report: any) => {
          if (report.id === payload.id) {
            // the payload has most of the data, but not all of it. merge it into this
            // existing report.
            return deepmerge(report, payload);
          }

          return report;
        }),
      };

    case STATUS_IS_UPDATING:
      return {
        ...state,
        reports: setStatusOnSingleReport(state.reports, {
          id: payload,
          statusIsUpdating: true,
        }),
      };
    case STATUS_IS_DONE_UPDATING:
      return {
        ...state,
        reports: setStatusOnSingleReport(state.reports, {
          id: payload,
          statusIsUpdating: false,
        }),
      };

    case SINGLE_IS_UPDATING:
      return { ...state, isUpdating: true, isUpdated: false, isError: false };
    case SINGLE_IS_UPDATE_SUCCESS:
      return { ...state, isUpdating: false, isUpdated: true };
    case SINGLE_IS_UPDATE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
        isError: true,
        error: payload,
      };

    default:
      return state;
  }
}
