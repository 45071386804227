import localStore from 'local-store';
import developerInterface from '../developerInterface';
import IfFeature from '../../components/IfFeature';

const appLocalStore = localStore();

const KEY = 'DeveloperInterface-features';

export { KEY };

function getLocalStore() {
  const result = appLocalStore.get(KEY);
  if (!result) { appLocalStore.set(KEY, {}); }

  return appLocalStore.get(KEY);
}

function setFeatureData(data: any) {
  IfFeature.featureData = () => data;
}

function getFeatureData() {
  return IfFeature.featureData() || {};
}

function manageStore(feature: string, _store: any, keyToAddTo: string, keyToRemoveFrom: string) {
  const store = _store;

  if (
    store[keyToRemoveFrom] &&
    store[keyToRemoveFrom].indexOf(feature) !== -1
  ) {
    store[keyToRemoveFrom] =
      store[keyToRemoveFrom].filter((f: string) => f !== feature);

    if (store[keyToRemoveFrom].length === 0) {
      delete store[keyToRemoveFrom];
    }
  } else {
    store[keyToAddTo] = store[keyToAddTo] || [];
    store[keyToAddTo].push(feature);
  }
}

export function performActionOn(code: Function) {
  const enabledFeatures = getFeatureData().enabled;
  const store = getLocalStore();

  const result = code({ enabledFeatures, store });

  if (!result) { return; }

  appLocalStore.set(KEY, result.store);
  setFeatureData({ enabled: result.enabledFeatures });
}

export function enable(feature: string) {
  performActionOn(({ enabledFeatures, store }: any) => {
    if (enabledFeatures.indexOf(feature) !== -1) { return null; }

    manageStore(feature, store, 'enabled', 'disabled');

    return {
      store,
      enabledFeatures: [...enabledFeatures, feature],
    };
  });
}

export function disable(feature: string) {
  performActionOn(({ enabledFeatures, store }: any) => {
    if (enabledFeatures.indexOf(feature) === -1) { return null; }

    manageStore(feature, store, 'disabled', 'enabled');

    return {
      store,
      enabledFeatures: enabledFeatures.filter(
        (oldFeature: string) => oldFeature !== feature,
      ),
    };
  });
}

function applyManualFeatures(
  enabledFeatures: string[],
  store: any,
  keyToAddFrom: string,
  keyToRemoveFrom: string,
) {
  return (enabledFeatures || [])
    .concat(store[keyToAddFrom] || [])
    .filter(f => (store[keyToRemoveFrom] || []).indexOf(f) === -1);
}

export function reset() {
  const enabledFeatures = getFeatureData().enabled;
  const store = getLocalStore();

  setFeatureData({
    enabled: applyManualFeatures(enabledFeatures, store, 'disabled', 'enabled'),
  });

  appLocalStore.set(KEY, {});
}

export function load() {
  const enabledFeatures = getFeatureData().enabled;
  const store = getLocalStore();

  if (store && (store.enabled || store.disabled)) {
    console.info(
      `Overriding feature data with local data!
Run DeveloperInterface().Features.reset() to use default feature data.
Current overrides:`,
    );

    console.table(store);
  }

  setFeatureData({
    enabled: applyManualFeatures(enabledFeatures, store, 'enabled', 'disabled'),
  });
}

developerInterface({
  Features: {
    listEnabled: () => IfFeature.featureData(),
    enable,
    disable,
    reset,
  },
});
