/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import withMessages, { intlPropTypes } from 'component-utilities/intl';
import LoadingIndicator from 'v-c/LoadingIndicator';

import {
  resetFlash,
  showGeneralFlash,
} from '../../global/state_managers/FlashMessagesStateManager';

import { setTimezone } from '../../global/state_managers/DomainInfoStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import {
  update as updateAccountSettings,
  load as loadAccountSettings,
} from '../../global/state_managers/AccountSettingsStateManager';

import routeLeaveHook, {
  routeLeaveHookPropTypes,
} from '../../global/lib/routeLeaveHook';
import dialogHandler from '../../global/lib/dialogHandler';
import withForm, { formHelperPropTypes } from '../../global/lib/formHelper';

import Layout from '../../global/components/Layout';
import Breadcrumb from '../../global/components/Breadcrumb';
import HeadlineLabel from '../../global/components/HeadlineLabel';
import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';

import {
  stateLogic,
  getInitialFields,
  viewLogic,
} from './AccountSettings/AccountSettingsDeconstructed';
import Contents from './AccountSettings/Contents';
import validateSettingsAccess from './components/validateSettingsAccess';

import {
  showUnsavedAccountSettingsDialog,
  showUpdateAccountSettingsDialog,
} from '../../global/state_managers/DialogStateManager';

const mapStateToProps = ({ flashMessages, accountSettings }: any) => ({
  isFlashVisible: !!flashMessages.indicators.length,
  ...accountSettings,
});

const mapActionsToProps = {
  resetFlash,
  showGeneralFlash,
  loadAccountSettings,
  updateAccountSettings,
  setTimezone,
  showUpdateAccountSettingsDialog,
  setBreadcrumbForSettingsAccountSettingsPage:
    breadcrumbsActions.settingsAccountSettingsPage,
};

type Props = {
  setBreadcrumbForSettingsAccountSettingsPage: Function,
  loadAccountSettings: Function,
  setRouterWillLeaveValidator: Function,
  registerFieldsChangeCallback: Function,
  isLoaded: Boolean,
  getMessage: Function,
  isDirtyFields: Boolean,
  retryTransition: Boolean,
  onFormChange: Function
}

@routeLeaveHook
@withMessages
@flashConnect(PAGES.SETTINGS_ACCOUNT_SETTINGS, 'Settings/AccountSettings')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withForm({
  form: 'account-settings',
  fields: [
  'accountName',
  'siteName',
  'domain',
  'domains',
  'timezone',
  'firstName',
  'lastName',
  'phoneNumber',
  'companyName',
  'country',
  'address1',
  'address2',
  'city',
  'state',
  'zipcode',
  'mailboxCount',
  ],
  onNextProps: (props: Props, nextProps: Props, setFields) => {
  if (
    (!props.isLoaded && nextProps.isLoaded)
    || (!props.isUpdated && nextProps.isUpdated)
  ) {
  setFields({
    ...getInitialFields(nextProps.account),
    });
  }
  },
  })
@stateLogic({
  updateAccountInfo: updateAccountSettings,
  })
@validateSettingsAccess('settings.account_settings')
export default class AccountSettings extends Component<Props> {
  static propTypes = {
    ...intlPropTypes,
    ...routeLeaveHookPropTypes,
    ...formHelperPropTypes,
    setBreadcrumbForSettingsAccountSettingsPage: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState) {
    if (nextProps.isLoaded && !prevState.isReady) {
      return { isReady: true };
    }

    return null;
  }

  constructor(props: Props) {
    super(props);

    this.state = { isReady: props.isLoaded };

    props.setBreadcrumbForSettingsAccountSettingsPage();
    props.loadAccountSettings();

    props.setRouterWillLeaveValidator(this.routerWillLeave);
    props.registerFieldsChangeCallback(props.onFormChange);
  }

  routerWillLeave = () => {
    if (!this.props.isDirtyFields) {
      return true;
    }

    dialogHandler(showUnsavedAccountSettingsDialog, this.props.retryTransition);

    return false;
  };

  render() {
    const { getMessage } = this.props;
    const { buttons, tabs } = viewLogic(this.props);
    const { isReady }: any = this.state;

    return (
      <Layout section="settings account-settings">
        <header>
          <FlashMessages />
          <Breadcrumb />
          <HeadlineLabel
            text={getMessage('app.settings.account_settings.tab_name')}
          />
        </header>
        {!isReady && (
          <LoadingIndicator
            orientation="middle"
            text={getMessage('app.general.loading')}
            isLoading
          />
        )}
        {isReady && (
          <Contents
            {...this.props}
            PAGES={PAGES}
            buttons={buttons}
            tabs={tabs}
          />
        )}
      </Layout>
    );
  }
}
