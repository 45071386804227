/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withMessages from 'component-utilities/intl';
import { TextLabel } from 'view-components/components/Widgets/Components';

import styles from './CriteriaMultiTextEditor.scss';
import {
  ADV_PARTICIPANTS,
} from '../../../../../data/Criteria';

type Props = {
  widgetId: string,
  list: any[],
  onKeyUp: Function
  onClick: Function
  onBlur: Function
  focusedIndex: number,
  relation: string,
  toggleRelation: Function
  getMessage: Function,
  isViewOnly: boolean,
  onChange: Function | boolean,
  criteriaType: string,
  isAdvParticipantsDomain: boolean,
}

@withMessages
export default class CriteriaMultiTextEditor extends Component<Props> {
  static propTypes = {
    widgetId: PropTypes.string,
    list: PropTypes.array,
    onKeyUp: PropTypes.func,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    focusedIndex: PropTypes.number,
    relation: PropTypes.string,
    toggleRelation: PropTypes.func,
    getMessage: PropTypes.func.isRequired,
    isViewOnly: PropTypes.bool.isRequired,
    onChange: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool,
    ]),
    criteriaType: PropTypes.string,
    isAdvParticipantsDomain: PropTypes.bool,
  };

  static defaultProps = {
    widgetId: '',
    list: [],
    onKeyUp: () => {},
    onChange: () => {},
    onClick: () => {},
    onBlur: () => {},
    focusedIndex: 0,
    relation: '',
    toggleRelation: () => {},
    criteriaType: 'keyword',
    isAdvParticipantsDomain: false,
  };

  componentDidMount() {
    this.focusOnActiveListElem();
  }

  componentDidUpdate() {
    this.focusOnActiveListElem();
  }

  onClick(idx: string) {
    return () => {
      const { onClick } = this.props;

      onClick(idx);
      this.lastFocusedIndex = idx;
    };
  }

  focusOnActiveListElem() {
    const { focusedIndex, list } = this.props;

    if (this.lastFocusedIndex !== focusedIndex) {
      $(this[`list-elem-${focusedIndex}`])
        .focus()
        .val('')
        .val(list[focusedIndex]);
    }

    this.lastFocusedIndex = focusedIndex;
  }

  testSimulate = (e: any) => {
    // Since the Poltergeist driver event handling issue
    //  we have to add this little hack to set first elem of the list value's
    //  for the correct test string
    const { onChange, onKeyUp, widgetId } = this.props;
    const e2 = e;
    e2.target.value = e.target.value || 'Criterion';
    e2.keyCode = 13;

    const simulateOnChange = onChange(widgetId, 0);
    const simulateOnKeyUp = onKeyUp(widgetId, 0);

    simulateOnChange(e);
    window.setTimeout(() => simulateOnKeyUp(e), 0);
  }

  renderSelectionRow(values: any) {
    const { listElem, idx } = values;
    const { isAdvParticipantsDomain, criteriaType } = this.props;

    const inputPlaceholder =
      criteriaType === ADV_PARTICIPANTS ?
        this.props.getMessage(`app.criterias.start_typing_and_press.${criteriaType}-${isAdvParticipantsDomain ? 'domain' : 'email'}`) :
        this.props.getMessage(`app.criterias.start_typing_and_press.${criteriaType}`);
    const styleName = `text-view-row-${idx === 0 ? 'first' : 'sibling'}`;
    const {
      widgetId,
      relation,
      toggleRelation,
      onChange,
      onKeyUp,
      onBlur,
      isViewOnly,
    } = this.props;

    if (isViewOnly && !listElem.length) {
      return null;
    }

    return (
      <div
        className={styles[styleName]}
        key={idx}
        data-field={`text-view-row-${idx}`}
      >
        {
          idx !== 0 &&
          <TextLabel
            onClick={!isViewOnly && toggleRelation}
            data-value={relation}
            data-action={'toggle-relation'}
            className={styles['relation-text']}
            role="button"
            tabIndex="-1"
          >
            {relation}
          </TextLabel>
        }
        <input
          data-field="editable-content"
          ref={(c) => { this[`list-elem-${idx}`] = c; }}
          data-index={idx}
          value={listElem}
          placeholder={inputPlaceholder}
          onKeyUp={onKeyUp(widgetId, idx)}
          onClick={this.onClick(idx)}
          onBlur={onBlur(widgetId, idx)}
          onChange={!isViewOnly && onChange(widgetId, idx)}
        />
        <input
          data-field="test-simulate"
          type="hidden"
          onClick={this.testSimulate}
        />
      </div>
    );
  }

  render() {
    const { list, getMessage } = this.props;
    const addedText = getMessage('app.criterias.added');
    const filterList = (listElem: string) => listElem.length;

    return (
      <div className={styles['criteria-multi-text-editor-view']}>
        <div className={styles['header-wrapper']}>
          <TextLabel className={styles['added-text']}>
            {`${addedText} (${list.filter(filterList).length})`}
          </TextLabel>
        </div>
        <div className={styles['content-wrapper']}>
          {
            list.map((listElem, idx) =>
              this.renderSelectionRow({ listElem, idx }),
            )
          }
        </div>
      </div>
    );
  }
}
