import reduxStore from '../reduxStore';

export default function dialogHandler(
  action: Function,
  retryTransition = () => true,
  handlers = {},
) {
  const { confirm = () => true, dismiss = () => true } = handlers;

  reduxStore.dispatch(
    action({
      confirm: () => {
        confirm();
        retryTransition();
      },
      dismiss,
    }),
  );
}
