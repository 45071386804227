import { expiresOn } from 'view-components/lib/hasExpired';

import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';
import createDownloadForJob from '../lib/createDownloadForJob';
import reduxStore, { PromiseObject } from '../reduxStore';

import {
  showSuccessFlash,
  showFailureFlash,
} from './FlashMessagesStateManager';
import { PAGES } from '../components/FlashMessages';

import IfFeature from '../components/IfFeature';
import generator from './JobsStateManagerGenerator';
import { updateDropdown } from './ExportTableUIStateManager';

import appConfig from '../../../../../config/app.json';
import configs from '../../config/configs';

const { EXPORT } = appConfig.JOBS;
const { JOB_STATUSES } = configs;


export function processJob(job: any) {
  const { jobName, metadata } = job;

  const uiData: any = {};
  let exportName = null;
  if (metadata) {
    exportName = metadata.searchName;

    uiData.requester = metadata.requestingUser;
    uiData.results = Number(metadata.fileCount);
    uiData.itemCount = Number(metadata.itemCount);

    if (metadata.format) {
      uiData.format = metadata.format.toLowerCase().replace(/-zip$/, '');
    }
  }

  if (IfFeature.isEnabled('new_exports_dropdown')) {
    exportName = jobName || exportName;
  }

  uiData.exportName = exportName;
  uiData.expiresOn = expiresOn(job.updatedAt);

  return { ...job, ...uiData };
}

const { constants, actions, reducer } = generator({
  name: 'exports',
  types: [EXPORT.TYPE],
  processJob,
});

const { RESET, IS_PROCESSING, DONE_PROCESSING } = constants;

const IN_PROCESS_JOB_TYPES = [
  JOB_STATUSES.STARTED,
  JOB_STATUSES.PENDING,
  JOB_STATUSES.SCHEDULED,
];

const BASE_FLASH = 'export';
const VALID_FLASH_VIEWS = [PAGES.EXPORTS];

// redux actions

export function reset() {
  return {
    type: RESET,
  };
}

export const download = createDownloadForJob;
export const allSuccess = actions.allSuccess;
export const someSuccess = actions.someSuccess;
export const allImmediately = actions.allImmediately;
export const allWhenOptimal = actions.allWhenOptimal;

export function exportRequest(id: string, options: any) {
  return function* doExportRequest() {
    const {
      fileType,
      searchType,
      query,
      name,
      checkedAssets,
      sendNotification,
      notificationRecipients,
    } = options;

    const exportType =
      EXPORT.FORMATS[fileType.toUpperCase()] || EXPORT.FORMATS.EML;
    const isSavedSearch = searchType === 'savedSearch';
    const searchRoute = isSavedSearch
      ? jsApi.searchesExport
      : jsApi.legalHoldReasonsExport;
    const CURRENT_VALID_FLASH_VIEWS = VALID_FLASH_VIEWS.concat(
      isSavedSearch ? PAGES.SEARCH_RESULTS : PAGES.HOLD_REASON_ASSETS,
    );

    const data: any = { format: exportType, query, name };

    if (sendNotification) {
      data.notification_recipients = [notificationRecipients];
      data.search_id = id;
    }
    const showFlashWith = ({ status }: any) => {
      if (!isSavedSearch || !IfFeature.isEnabled('new_exports_dropdown')) {
        if (status === 413) {
          reduxStore.dispatch(
            showFailureFlash(
              `${BASE_FLASH}.over_limit`,
              CURRENT_VALID_FLASH_VIEWS,
            ),
          );
        } else if (status >= 400 && status !== 504) {
          reduxStore.dispatch(
            showFailureFlash(
              `${BASE_FLASH}.request`,
              CURRENT_VALID_FLASH_VIEWS,
            ),
          );
        } else {
          reduxStore.dispatch(
            showSuccessFlash(
              `${BASE_FLASH}.request`,
              CURRENT_VALID_FLASH_VIEWS,
            ),
          );
        }
      }
    };

    if (typeof checkedAssets !== 'undefined') {
      data.checked_assets = checkedAssets;
    }

    yield { type: IS_PROCESSING };

    try {
      const result: PromiseObject = yield apiReqPromise(searchRoute, {
        params: { data },
        urlParams: { id },
      });

      yield { type: DONE_PROCESSING };
      if (result.data.duplicateRejected) {
        yield updateDropdown('duplicated');
      } else {
        yield updateDropdown('success');
      }

      yield allImmediately();

      showFlashWith({ error: false });
    } catch (e) {
      console.error(e);

      yield { type: DONE_PROCESSING };

      if (e.status === 504) {
        yield updateDropdown('success');
        showFlashWith({ error: false });
      } else {
        yield updateDropdown('failure');
        showFlashWith({ status: e.status });
      }
    }
  };
}

// helper functions

export function inProcessJobs(jobs: any[]) {
  return jobs.filter(job => IN_PROCESS_JOB_TYPES.indexOf(job.status) !== -1);
}

export function currentUserJobs(jobs: any[], { externalId, username }: any) {
  return jobs.filter(
    job => !!job.metadata &&
      job.metadata.userExternalId === externalId &&
      job.metadata.requestingUser === username,
  );
}

export function canPerformExport(jobs: any[], loggedInUser: any) {
  const userJobs = currentUserJobs(jobs, loggedInUser);
  // should return false when === EXPORT.CONCURRENCY_LIMIT because user cannot
  // export over limit
  return inProcessJobs(userJobs).length < EXPORT.CONCURRENCY_LIMIT;
}

const ExportsStateManager = reducer;
export default ExportsStateManager;
