import reduxStore, { Action } from '../reduxStore';

export const SHOW_UNSAVED_SEARCH_DIALOG: string = 'dialog/showUnsavedSearch';
export const SHOW_UNSAVED_HOLD_REASON_DIALOG: string = 'dialog/showUnsavedHoldReason';
export const SHOW_UNSAVED_USER_DIALOG: string = 'dialog/showUnsavedUser';
export const SHOW_UNSAVED_ACCOUNT_SETTINGS_DIALOG: string =
  'dialog/showUnsavedAccountSettings';
export const SHOW_DELETE_LIST_DIALOG: string = 'dialog/showDeleteList';
export const SHOW_CANNOT_DELETE_LIST_DIALOG: string = 'dialog/cannotShowDeleteListDialog';
export const SHOW_DELETE_SEARCH_DIALOG: string = 'dialog/showDeleteSearch';
export const SHOW_DELETE_USER_DIALOG: string = 'dialog/showDeleteUser';
export const SHOW_DELETE_COMMENT_DIALOG: string = 'dialog/showDeleteCommentDialog';
export const SHOW_DELETE_ADSYNC_DIALOG: string = 'dialog/showDeleteADSync';
export const SHOW_UPDATE_ACCOUNT_SETTINGS_DIALOG: string =
  'dialog/showUpdateAccountSettings';
export const SHOW_UPDATE_SSO_SETTINGS_DIALOG: string = 'dialog/showUpdateSSOSettings';
export const SHOW_REVERT_LOGIN_PROVIDER_DIALOG: string =
  'dialog/showRevertLoginProviderDialog';
export const SHOW_UNSAVED_RETENTION_POLICIES_DIALOG: string =
  'dialog/showUnsavedRetentionPolicies';
export const SHOW_UNSAVED_POP3_CONFIG_DIALOG: string = 'dialog/showUnsavedPop3Config';
export const CONFIRM_DIALOG: string = 'dialog/confirm';
export const DISMISS_DIALOG: string = 'dialog/dismiss';
export const CLOSE_DIALOG: string = 'dialog/close';
export const RESET: string = 'dialog/reset';

interface DialogState {
  showDialog: boolean,
  callbacks: any,
  options: any,
}

// Init
export const INITIAL_STATE: DialogState = {
  showDialog: false,
  callbacks: {},
  options: {},
};

// Helpers
export const createDialogFor = (type: any) => (callbacks = {}, options = {}) => ({
  type,
  payload: { type, callbacks, options },
});

export function triggeringIfAvailable(method: any, param = {}) {
  const { dialog } = reduxStore.getState();
  const methodFn = dialog.callbacks && dialog.callbacks[method];

  if (methodFn) {
    methodFn(param);
  }
}

// Actions
export const showUnsavedSearchDialog = createDialogFor(
  SHOW_UNSAVED_SEARCH_DIALOG,
);

export const showUnsavedHoldReasonDialog = createDialogFor(
  SHOW_UNSAVED_HOLD_REASON_DIALOG,
);

export const showUnsavedUserDialog = createDialogFor(SHOW_UNSAVED_USER_DIALOG);

export const showUnsavedAccountSettingsDialog = createDialogFor(
  SHOW_UNSAVED_ACCOUNT_SETTINGS_DIALOG,
);

export const showUnsavedRetentionPoliciesDialog = createDialogFor(
  SHOW_UNSAVED_RETENTION_POLICIES_DIALOG,
);

export const showUnsavedPop3ConfigDialog = createDialogFor(
  SHOW_UNSAVED_POP3_CONFIG_DIALOG,
);

export const showDeleteSearchDialog = createDialogFor(
  SHOW_DELETE_SEARCH_DIALOG,
);

export const showDeleteCommentDialog = createDialogFor(
  SHOW_DELETE_COMMENT_DIALOG,
);

export const showDeleteListDialog = createDialogFor(
  SHOW_DELETE_LIST_DIALOG,
);

export const showCannotDeleteListDialog = createDialogFor(
  SHOW_CANNOT_DELETE_LIST_DIALOG,
);

export const showDeleteUserDialog = createDialogFor(SHOW_DELETE_USER_DIALOG);

export const showDeleteADSyncDialog = createDialogFor(
  SHOW_DELETE_ADSYNC_DIALOG,
);

export const showUpdateAccountSettingsDialog = createDialogFor(
  SHOW_UPDATE_ACCOUNT_SETTINGS_DIALOG,
);

export const showUpdateSSOSettingsDialog = createDialogFor(
  SHOW_UPDATE_SSO_SETTINGS_DIALOG,
);

export const showRevertLoginProviderDialog = createDialogFor(
  SHOW_REVERT_LOGIN_PROVIDER_DIALOG,
);

export function confirmDialog(options: any) {
  return function* doConfirmDialog() {
    yield { type: CONFIRM_DIALOG };

    triggeringIfAvailable('confirm', options);

    yield { type: CLOSE_DIALOG };
  };
}

export function dismissDialog() {
  return function* doDismissDialog() {
    yield { type: DISMISS_DIALOG };

    triggeringIfAvailable('dismiss');

    yield { type: CLOSE_DIALOG };
  };
}

export function resetDialog() {
  return { type: RESET };
}

// Store
export default function DialogStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case SHOW_UNSAVED_SEARCH_DIALOG:
    case SHOW_UNSAVED_HOLD_REASON_DIALOG:
    case SHOW_UNSAVED_USER_DIALOG:
    case SHOW_UNSAVED_ACCOUNT_SETTINGS_DIALOG:
    case SHOW_UNSAVED_POP3_CONFIG_DIALOG:
    case SHOW_DELETE_SEARCH_DIALOG:
    case SHOW_DELETE_COMMENT_DIALOG:
    case SHOW_DELETE_USER_DIALOG:
    case SHOW_DELETE_ADSYNC_DIALOG:
    case SHOW_UPDATE_ACCOUNT_SETTINGS_DIALOG:
    case SHOW_UPDATE_SSO_SETTINGS_DIALOG:
    case SHOW_REVERT_LOGIN_PROVIDER_DIALOG:
    case SHOW_UNSAVED_RETENTION_POLICIES_DIALOG:
    case SHOW_CANNOT_DELETE_LIST_DIALOG:
    case SHOW_DELETE_LIST_DIALOG:
      return {
        ...state,
        ...payload,
        showDialog: true,
      };
    case CLOSE_DIALOG:
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
