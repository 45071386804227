/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withMessages, { intlPropTypes } from 'component-utilities/intl';
import { connect } from 'react-redux';

import LoadingIndicator from 'v-c/LoadingIndicator';

import maybeShowPreTargetDialog from 'component-lib/maybeShowPreTargetDialog';

import validateSettingsAccess from './components/validateSettingsAccess';

import { jsUi } from '../../global/lib/jsUi';
import routeLeaveHook from '../../global/lib/routeLeaveHook';

import Container from './Reports/Container';
import LoadedContents from './Reports/LoadedContents';

import withJobsLoad from '../../global/components/withJobsLoad';

import {
  openModal,
  confirmModal,
} from '../../global/state_managers/ModalsStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import MODAL_MAP from '../../global/components/Modals/modalMap.json';

import {
  allImmediately,
  allWhenOptimal,
  download,
} from '../../global/state_managers/AuditsStateManager';
import {
  setVisibleColumns,
  sort as doSort,
  setFilter,
  resetFilter,
} from '../../global/state_managers/AuditsTableUIStateManager';
import {
  all as allReports,
  toggle,
} from '../../global/state_managers/ReportsStateManager';
import {
  load as loadUserSettings,
  update as updateUserSettings,
} from '../../global/state_managers/UserSettingsStateManager';
import { resetFlash } from '../../global/state_managers/FlashMessagesStateManager';

import './components/Settings.scss';
import { User } from 'global/types';

const mapStateToProps = ({
  audits,
  auditsTableUI,
  reports,
  userSettings,
  modals,
  authentication,
}: any) => ({
  audits,
  auditsTableUI,
  reports,
  userSettings,
  user: {
    settings: userSettings.userSettings || {},
    id: authentication.loggedInAs.userId,
  },
  modals,
});

const mapActionsToProps = {
  allImmediately,
  allWhenOptimal,
  setVisibleColumns,
  doSort,
  setFilter,
  resetFilter,
  download,
  allReports,
  toggle,
  loadUserSettings,
  updateUserSettings,
  openModal,
  confirmModal,
  setBreadcrumbForSettingsReportsPage: breadcrumbsActions.settingsReportsPage,
  resetFlash,
};

type Props = {
  isError: boolean,
  updateUserSettings: Function,
  openModal: Function,
  confirmModal: Function,
  setBreadcrumbForSettingsReportsPage: Function,
  resetFilter: Function,
  allReports: Function,
  loadUserSettings: Function,
  registerUnmountCleanupMethod: Function,
  resetFlash: Function,
  user: User,
  modals: {
    confirmData: {
      doNotShowAgain: boolean,
      markUserAsHavingSeenDialog: Function,
      goToTarget: Function,
      buttonPressed: string
    }
  }
  doSort: Function,
  reports: {
    isError: Boolean,
    isLoading: Boolean,
    reports: {}
  }
  isLoading: boolean,
  userSettings: {
    isLoading: boolean
  },
  getMessage: Function
}

@routeLeaveHook
@validateSettingsAccess('settings.reports')
@withMessages
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withJobsLoad('audits')
// maybeShowPreTargetDialog wraps up this common behavior:
//
// * place the user's settings into props: { user: { settings } }
// * define a key (`name`) that maps to a user setting for permamently hiding this dialog
// * click a link for something that may be stopped by a dialog
//   * if the user had chosen to permanently hide this dialog
//     (user.settings[name] === true), follow the link (`goToTarget`).
//   * otherwise, execute the code to show the modal and handle user
//     feedback.
//   * after the user has handled the modal:
//     * if they choose to never see this dialog again, handle this request
//       (`markUserAsHavingSeenDialog` and `markUserDialogPreference`)./
//     * send the user to the link target (`goToTarget`).
//
// how the modal is handled is up to how actions and data stores are configured
// in the app.
@maybeShowPreTargetDialog({
  name: 'attemptToEditReport',
  dialogKeyTemplate: ({ type }: any) => `do_not_show_again_for_${type}`,
  markUserDialogPreference: (id: string, key: string, props: Props) => {
    props.updateUserSettings(id, {
      [key]: true,
    });
  },
  goToTarget: ({ reportId }: any) => {
  jsUi.settingsReportsReportsEdit.goTo({ id: reportId });
  },
  openDialog: function doOpenDialog(
    { type }: any,
    props: Props,
    { goToTarget, markUserAsHavingSeenDialog }: any,
  ) {
    props.openModal(MODAL_MAP.EDIT_REPORT, {
      reportType: type,
      onButtonClick: (confirmData: any) => {
        props.confirmModal({
          type,
          goToTarget,
          markUserAsHavingSeenDialog,
          ...confirmData,
        });
      },
    });
  },
})
export default class Reports extends Component<Props, any> {
  static propTypes = {
    ...intlPropTypes,
    openModal: PropTypes.func.isRequired,
    confirmModal: PropTypes.func.isRequired,
    modals: PropTypes.object.isRequired,
    setBreadcrumbForSettingsReportsPage: PropTypes.func.isRequired,
    registerUnmountCleanupMethod: PropTypes.func.isRequired,
    resetFlash: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      reportsSort: { key: 'name', order: 'asc' },
    };

    props.setBreadcrumbForSettingsReportsPage();

    props.resetFilter();
    props.allReports();
    props.loadUserSettings(props.user.id);

    props.registerUnmountCleanupMethod(() => {
      props.resetFlash({ from: 'Reports' });
    });
  }

  componentDidUpdate() {
    const {
      modals: { confirmData },
    } = this.props;

    if (
      !this.isStartedToHandleConfirmData &&
      confirmData &&
      confirmData.buttonPressed === 'primary'
    ) {
      this.isStartedToHandleConfirmData = true;

      const {
        doNotShowAgain,
        markUserAsHavingSeenDialog,
        goToTarget,
      } = confirmData;

      if (doNotShowAgain) {
        markUserAsHavingSeenDialog();
      }

      goToTarget();
    }
  }

  doAuditsSort = (key: string, order: string) => {
    this.props.doSort({ key, order });
  };

  // reports can only be sorted by one column, and there's
  // no filtering, so handle this here until we need to handle
  // more, then move it to a state manager./
  doReportsSort = (key: string, order: string) => {
    this.setState({ reportsSort: { key, order } });
  };

  render() {
    if (
      this.props.isLoading ||
      this.props.reports.isLoading ||
      this.props.userSettings.isLoading ||
      !this.props.reports.reports
    ) {
      return (
        <Container>
          <LoadingIndicator
            isLoading
            orientation="middle"
            text={this.props.getMessage('app.general.loading')}
          />
        </Container>
      );
    }

    return (
      <LoadedContents
        {...this.props}
        isReportsLoaded={!this.props.isError && !this.props.reports.isError}
        reportsSort={this.state.reportsSort}
        doReportsSort={this.doReportsSort}
        doAuditsSort={this.doAuditsSort}
      />
    );
  }
}
