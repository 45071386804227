import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { inRange } from 'lodash';

import ViewTemplates from 'v-c/templates';
import withMessages from 'component-utilities/intl';
import ViewComponents from 'v-c/components';

import { RANDOM_SEARCH } from '../../../config/configs';
import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../../global/components/FlashMessages';
import { jsUi } from '../../../global/lib/jsUi';
import breadcrumbs from '../../../global/lib/Breadcrumbs';
import MODAL_MAP from '../../../global/components/Modals/modalMap.json';
import { USER_NEEDS_SEARCH_PERMISSION } from '../../../config/constants/PageRoles';
import roles from '../../../global/constants/Roles';
import dialogHandler from '../../../global/lib/dialogHandler';
import Processing from '../../../global/components/Processing';

import {
  openModal,
  closeModal,
} from '../../../global/state_managers/ModalsStateManager';
import { resetFilter } from '../../../global/state_managers/SearchFormStateManager';
import {
  resetFlash,
  showGeneralFlash,
} from '../../../global/state_managers/FlashMessagesStateManager';
import { resetCurrentSavedSearch } from '../../../global/state_managers/CurrentSavedSearchStateManager';
import {
  saveUnsavedSearch,
  resetFilteredFormValues,
  bodyForSaveUnsavedSearch,
} from '../../../global/state_managers/UnsavedSearchStateManager';
import { showDeleteSearchDialog } from '../../../global/state_managers/DialogStateManager';
import { breadcrumbsActions } from '../../../global/state_managers/BreadcrumbsStateManager';
import { resetStreamQuery } from '../../../global/state_managers/SearchResults/searchResultsStream';

import { getPrioritizedUserRole } from '../../../global/lib/userRights';

import SimpleSearchSaveView from '../../../global/components/UnsavedSearchForms/SimpleSearch/SaveView';
import AdvancedSearchView from '../../../global/components/UnsavedSearchForms/AdvancedSearchForm.tsx';

import stateLogic, { viewLogic } from './SearchDetailsViewDeconstructed';

const { Roles } = roles;
const { PERCENTAGE_RANGE } = RANDOM_SEARCH;

function isSamplePercentageInValidRange(value: any) {
  return inRange(value, PERCENTAGE_RANGE.min, PERCENTAGE_RANGE.max);
}

const mapStateToProps = ({
  users,
  unsavedSearch,
  breadcrumbs: breadcrumbsStore,
  searchResults,
}: any) => ({
  allUsers: users.allUsers,
  users: users.users,
  unsavedClientData: unsavedSearch.data.clientData,
  unsavedSearchSummary: unsavedSearch.summary,
  isSearchSaving: unsavedSearch.isSaving,
  searchType: unsavedSearch.data.clientData.type,
  breadcrumbsStore,
  returnCount: searchResults.returnCount,
  searchLoadingTime: searchResults.loadingTime,
});

const mapActionsToProps = {
  resetFilter,
  openModal,
  closeModal,
  resetFlash,
  resetCurrentSavedSearch,
  showGeneralFlash,
  saveUnsavedSearch,
  resetFilteredFormValues,
  showDeleteSearchDialog,
  setBreadcrumbForSearchesShowPage: breadcrumbsActions.searchesShowPage,
  setBreadcrumbForSearchesShowPageFromIndex:
    breadcrumbsActions.searchesShowPageFromIndex,
  resetStreamQuery,
};

@withMessages
@flashConnect(PAGES.SAVE_SEARCH, 'SaveSearchForm')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@reduxForm(
  {
  form: 'search-details',
  fields: [
  'searchName',
  'searchDescription',
  'samplePercentage',
  'userAccessList',
  ],
  enableReinitialize: true,
  },
  ({ currentSavedSearch: { search } }: any) => ({
    initialValues: {
    searchName: search.name || '',
    searchDescription: search.description || '',
    samplePercentage: search.samplePercentage || '',
    userAccessList: search.specialAccess || [],
    },
    }),
)
@stateLogic({
  goToSearchesIndex: jsUi.searchesIndex.goTo,
  goToSearchesEdit: jsUi.searchesEdit.goTo,
  goToResultsIndex: jsUi.resultsIndex.goTo,
  goToNewSearchEdit: jsUi.newSearchEdit.goTo,
  unsavedSearchForSave: bodyForSaveUnsavedSearch,
  getPrioritizedUserRole,
  isSamplePercentageInValidRange,
  dialogHandler,
  Roles,
  actionButtons: ViewComponents.actionButtons,
  breadcrumbs,
  SimpleSearchSaveView,
  AdvancedSearchView,
  MODAL_MAP,
  USER_NEEDS_SEARCH_PERMISSION,
  PAGES,
  })
export default class SearchDetailsView extends Component {
  render() {
    const {
      dirty,
      isSearchProcessing,
      breadcrumbsConfig,
      actionsConfig,
      fields,
      searchConfig,
      userAccessConfig,
      buttonsConfig,
      getMessage,
      values,
      isNewSearch,
      currentSearch,
    } = viewLogic(this.props);

    if (((!values.searchName && !dirty) || !currentSearch.id) && !isNewSearch) {
      return null;
    }

    return (
      <div>
        <FlashMessages />
        <ViewTemplates.DetailsPage
          isNewSearch={isNewSearch}
          processing={
            <Processing
              text={getMessage('app.general.processing')}
              isProcessing={isSearchProcessing}
            />
          }
          breadcrumbs={breadcrumbsConfig}
          actionsConfig={actionsConfig}
          formConfig={fields}
          searchConfig={searchConfig}
          userAccessConfig={userAccessConfig}
          buttonsConfig={buttonsConfig}
        />
      </div>
    );
  }
}
