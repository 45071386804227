import { trim, camelCase } from 'lodash';
import { compactInteger } from 'humanize-plus';

import { SortCell } from 'v-c/TableView';
import convertDataKeys from 'view-components/lib/convertDataKeys';

import reduxStore, { PromiseObject } from '../reduxStore';
import createReducer from '../lib/createReducer';
import jsApi from '../lib/jsApi';
import { jsUi } from '../lib/jsUi';
import { apiReqPromise } from '../lib/apiRequest';
import simpleSort from './helpers/simpleSort';
import simpleSearch from './helpers/simpleSearch';

import {
  showSuccessFlash,
  showFailureFlash,
} from './FlashMessagesStateManager';
import { PAGES } from '../components/FlashMessages';

// constants
export const LOAD: string = 'legalHoldReasons/load';
export const CREATE: string = 'legalHoldReasons/create';
export const SEARCH: string = 'legalHoldReasons/search';
export const SORT: string = 'legalHoldReasons/sort';
export const UPDATE_ACTIVE_COLUMNS: string = 'legalHoldReasons/updateActiveColumns';
export const RESET: string = 'legalHoldReasons/reset';
export const HARD_RESET: string = 'legalHoldReasons/hardReset';

export const IN_PROGRESS: string = 'legalHoldReasons/inProgress';
export const COUNT_IN_PROGRESS: string = 'legalHoldReasons/countInProgress';
export const UPDATE: string = 'legalHoldReasons/update';
export const LOADED: string = 'legalHoldReasons/loaded';

export const COLUMNS = {
  REASON_NAME: 'name',
  RUN_REASON_SEARCH: 'run-now',
};

const { REASON_NAME, RUN_REASON_SEARCH } = COLUMNS;

// helper contstants
const VALID_FLASH_VIEWS = [PAGES.NEW_HOLD_REASON];

interface LegalHoldReasonsState {
  reasons: any[],
  originalReasons: any[],
  isLoaded: boolean,
  isLoading: boolean,
  isCounting: boolean,
  searchString: string,
  tableHelper: {
    columns: any,
    allColumns: string[],
    activeColumns: string[],
    disabledColumns: string[],
  },
  sortOptions: {
    sortKey: string,
    sortOrder: string,
  },
}

// initial reducer state
export const initialState: LegalHoldReasonsState = {
  reasons: [],
  originalReasons: [],
  isLoaded: false,
  isLoading: false,
  isCounting: false,
  searchString: '',
  tableHelper: {
    columns: COLUMNS,
    allColumns: [REASON_NAME],
    activeColumns: [REASON_NAME],
    disabledColumns: [REASON_NAME, RUN_REASON_SEARCH],
  },
  sortOptions: {
    sortKey: REASON_NAME,
    sortOrder: SortCell.sortKeys.ASC,
  },
};

// helpers
export function populateReasonsListData(responseData: any[]) {
  return responseData.reduce(
    (reasons, reason) => [
      ...reasons,
      convertDataKeys({ ...reason, name: reason['enum-value'] }, camelCase),
    ],
    [],
  );
}

export function populateCountsData(counts: any, reasons: any[]) {
  return reasons.reduce((newReasons, reason) => {
    const reasonCount: any = counts[reason.enumId] || 0;

    return [
      ...newReasons,
      {
        ...reason,
        results: reasonCount,
        formattedResults: compactInteger(reasonCount),
      },
    ];
  }, []);
}

export function currentHoldReason(enumId: string, store = reduxStore) {
  const { legalHoldReasons }: any = store.getState();

  return (
    legalHoldReasons.reasons.find((reason: any) => reason.enumId === enumId) || {}
  );
}

// actions
export function sortLegalHoldReasons(sortKey: string, sortOrder: string) {
  return function* doSortLegalHoldReasons(getState: Function = reduxStore.getState) {
    const { reasons, originalReasons } = getState().legalHoldReasons;
    const sortOptions = { sortKey, sortOrder };

    const sortedReasons = simpleSort(reasons, sortKey, sortOrder);
    const sortedOriginalReasons = simpleSort(
      originalReasons,
      sortKey,
      sortOrder,
    );

    return yield {
      type: SORT,
      payload: {
        sortOptions,
        reasons: sortedReasons,
        originalReasons: sortedOriginalReasons,
      },
    };
  };
}

export function loadLegalHoldReasons(params: any = {}) {
  return function* doLoadLegalHoldReasons(getState: Function = reduxStore.getState) {
    if (params.loadIfNecessary) {
      const { isLoading, reasons } = getState().legalHoldReasons;

      if (!isLoading && reasons.length) {
        yield {
          type: LOADED,
          payload: { from: 'loadLegalHoldReasons/loadIfNecessary' },
        };

        return;
      }
    }

    yield { type: LOAD };
    yield { type: IN_PROGRESS };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.legalHoldReasonsIndex);
      const reasons = populateReasonsListData(result.data.enums);

      yield {
        type: UPDATE,
        payload: { reasons, originalReasons: reasons },
      };

      yield sortLegalHoldReasons(REASON_NAME, SortCell.sortKeys.ASC);

      yield {
        type: LOADED,
        payload: { from: 'loadLegalHoldReasons/success' },
      };
    } catch (e) {
      yield showFailureFlash('hold_reasons.load', [PAGES.HOLD_REASONS, e]);

      console.error('LegalHoldReasonsStateManager/load error:', e);

      yield {
        type: LOADED,
        payload: { from: 'loadLegalHoldReasons/error' },
      };
    }
  };
}

export function createLegalHoldReason({ name, description }: any) {
  return function* doCreateLegalHoldReason() {
    yield { type: CREATE };
    yield { type: IN_PROGRESS };

    try {
      yield apiReqPromise(jsApi.legalHoldReasonsCreate, {
        params: {
          data: { enum: { value: trim(name), description: trim(description) } },
        },
      });

      jsUi.holdReasonsIndex.goTo();

      yield showSuccessFlash('hold_reason.create', [PAGES.HOLD_REASONS]);
    } catch (e) {
      switch (e.status) {
        case 409:
          yield showFailureFlash('hold_reason.conflict', VALID_FLASH_VIEWS);
          break;
        default:
          yield showFailureFlash('hold_reason.create', VALID_FLASH_VIEWS);
      }

      console.error('LegalHoldReasonsStateManager/create', e);
    }

    return yield {
      type: LOADED,
      payload: { from: 'createLegalHoldReason' },
    };
  };
}

export function searchLegalHoldReasons(searchString: any) {
  return function* doSearchLegalHoldReasons(getState: Function = reduxStore.getState) {
    const originalReasons = getState().legalHoldReasons.originalReasons;

    let filteredReasons = originalReasons;

    if (searchString.length) {
      filteredReasons = simpleSearch(originalReasons, searchString, [
        'name',
        'formattedResults',
      ]);
    }

    return yield {
      type: SEARCH,
      payload: { searchString, reasons: filteredReasons },
    };
  };
}

export function updateLegalHoldReasonsActiveColumns(newColumns: string[]) {
  return { type: UPDATE_ACTIVE_COLUMNS, payload: { newColumns } };
}

export function resetLegalHoldReasons({ from }: any) {
  return { type: RESET, payload: { from } };
}

export function hardResetLegalHoldReasons({ from }: any) {
  return { type: HARD_RESET, payload: { from } };
}

const LegalHoldReasonsStateManager = createReducer(initialState, {
  [LOAD]: (state: LegalHoldReasonsState) => ({
    ...state,
    reasons: [],
  }),
  [IN_PROGRESS]: (state: LegalHoldReasonsState) => ({
    ...state,
    isLoading: true,
  }),
  [UPDATE]: (state: LegalHoldReasonsState, { reasons, originalReasons }: any) => ({
    ...state,
    reasons,
    originalReasons,
  }),
  [LOADED]: (state: LegalHoldReasonsState) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
  }),
  [SEARCH]: (state: LegalHoldReasonsState, { searchString, reasons }: any) => ({
    ...state,
    searchString,
    reasons,
  }),
  [SORT]: (state: LegalHoldReasonsState, { sortOptions, reasons, originalReasons }: any) => ({
    ...state,
    sortOptions,
    reasons,
    originalReasons,
  }),
  [UPDATE_ACTIVE_COLUMNS]: (state: LegalHoldReasonsState, { newColumns }: any) => ({
    ...state,
    tableHelper: {
      ...state.tableHelper,
      activeColumns: newColumns,
    },
  }),
  [RESET]: (state: LegalHoldReasonsState) => ({
    ...initialState,
    reasons: state.reasons,
    originalReasons: state.originalReasons,
    tableHelper: { ...state.tableHelper },
    sortOptions: { ...state.sortOptions },
  }),
  [HARD_RESET]: (state: LegalHoldReasonsState) => ({
    ...initialState,
    tableHelper: { ...state.tableHelper },
    sortOptions: { ...state.sortOptions },
  }),
});

export default LegalHoldReasonsStateManager;
