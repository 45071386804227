import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';

import LoadWhenReady from '../../global/lib/LoadWhenReady';
import fixedHeightContent from '../../global/lib/fixedHeightContent';
import routeLeaveHook from '../../global/lib/routeLeaveHook';
import withSerialResultsLoader, {
  LOAD_TYPES,
} from '../../global/lib/withSerialResultsLoader';

import Loading from '../../global/components/Loading';
import Breadcrumb from '../../global/components/Breadcrumb';
import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';

import { getStatus as getAzureADStatus } from '../../global/state_managers/ADSyncStateManager';
import { resetUsers } from '../../global/state_managers/UsersStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

const TableView = Loadable({
  loader: () => import('./components/UsersTableView'),
  loading() {
    return <Loading />;
  },
})

const mapStateToProps = ({ users, usersBulkOperations, adSync }: any) => ({
  users,
  usersBulkOperations,
  returnCount: users.totalCount,
  searchString: users.searchString,
  isUsersLoaded: users.isLoaded,
  isUsersLoading: users.isLoading,
  tableHelper: users.tableHelper,
  azureADSetupDone: adSync.setupDone,
});

const mapActionsToProps = {
  resetUsers,
  getAzureADStatus,
  setBreadcrumbForUsersPage: breadcrumbsActions.usersPage,
};

type Props = {
  setBreadcrumbForUsersPage: Function,
  serialResultsLoader: {
    start: Function,
    stop: Function
  },
  isUsersLoaded: boolean,
  isUsersLoading: boolean,
  searchString: string,
  returnCount: number,
  tableHelper: {},
  registerUnmountCleanupMethod: Function,
  resetUsers: Function,
  getAzureADStatus: Function,
}

@routeLeaveHook
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withSerialResultsLoader(LOAD_TYPES.USERS)
@flashConnect(PAGES.USER_MANAGEMENT, 'Users')
@fixedHeightContent
export default class Users extends Component<Props> {
  static propTypes = {
    isUsersLoaded: PropTypes.bool.isRequired,
    isUsersLoading: PropTypes.bool.isRequired,
    searchString: PropTypes.string.isRequired,
    returnCount: PropTypes.number.isRequired,
    tableHelper: PropTypes.object.isRequired,
    registerUnmountCleanupMethod: PropTypes.func.isRequired,
    serialResultsLoader: PropTypes.shape({
      start: PropTypes.func.isRequired,
      stop: PropTypes.func.isRequired,
    }).isRequired,
    resetUsers: PropTypes.func.isRequired,
    getAzureADStatus: PropTypes.func.isRequired,
    setBreadcrumbForUsersPage: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    props.setBreadcrumbForUsersPage();
    props.serialResultsLoader.start();

    props.registerUnmountCleanupMethod(() => {
      props.serialResultsLoader.stop();
      props.resetUsers();
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.returnCount && !!this.props.returnCount) {
      this.props.getAzureADStatus();
    }
  }

  render() {
    return (
      <LoadWhenReady readyStates={[this.props.isUsersLoaded]}>
        <div data-view="users">
          <div data-content="header">
            <FlashMessages />
            <Breadcrumb
              {...this.props}
              showZeroResults
              isProcessing={this.props.isUsersLoading}
            />
          </div>
          <div data-content="content">
            <TableView {...this.props} />
          </div>
        </div>
      </LoadWhenReady>
    );
  }
}
