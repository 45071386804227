import stateManager, {
  setReduxStore,
} from 'component-utilities/flashMessages/stateManager';

import reduxStore from '../reduxStore';

setReduxStore(reduxStore);

export {
  SET_ACTIVE_VIEW_ID,
  SHOW,
  SHOW_SUCCESS,
  SHOW_GENERAL,
  SHOW_FAILURE,
  RESET_VIEW,
  RESET,
  RESET_ONLY,
  SUCCESS,
  GENERAL,
  FAILURE,
  INITIAL_STATE,
  setActiveViewId,
  showFlash,
  showSuccessFlash,
  showGeneralFlash,
  showFailureFlash,
  resetFlashView,
  resetFlash,
  resetOnlyFlash,
  isFlashVisible,
  addIndicatorToState,
  filterOutCurrentViewFlashes,
} from 'component-utilities/flashMessages/stateManager';

const FlashMessagesStateManager = stateManager;

export default FlashMessagesStateManager;
