/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { snakeCase, debounce, uniq } from 'lodash';
import { killEvent } from 'event-helpers';
import { is, fromJS } from 'immutable';

import highlightText from 'v-c/Text/HighlightText';
import toggleInArray from 'component-lib/toggleInArray';

import { GENERAL_CONFIGS } from '../../config/configs';
import domainInfo from '../lib/domainInfo';
import { User } from 'global/types';

type Props = {
  tableHelper: {
    activeColumns: string[],
    allColumns: string[],
    disabledColumns: string[]
  },
  users: {
    totalCount: number,
    users: User[],
    searchString: string,
    sortOptions: string
  },
  usersBulkOperations: {
    lastBulkUpdate: number | number[],
  },
  multiSelect: {
    reset: Function,
    selectedRows: string[],
    setResultCounts: Function,
    isAllSelected: boolean,
    onReturnCountChange: Function,
    onFilterChange: Function
  },
  getMessage: Function,
  openModal: Function,
  returnCount: number,
  filterUsers: Function,
  activeColumnsChanged: Function,
  changeUserStatus: Function,
  loggedInAsUserId: number,
  azureADSetupDone: boolean,
  localizedColumnNames: string[],
  onRolesClick: Function,
  onNewActionSelected: boolean,
  onMoreActionSelected: boolean,
  tableFilter: string,
  onSearchChange: Function,
  onColumnSelectorChange: Function,
  serialResultsLoader: {
    pushToQueue: Function
  },
}

const ACTION_KEYWORDS = {
  USER: 'user',
  AD_SYNC: 'ad-sync',
  CSV: 'csv',
  ENABLE: 'enable',
  DISABLE: 'disable',
};

const BASE_KEY = 'app.users';
const NEW_DROPDOWN_BASE = `${BASE_KEY}.new_dropdown`;
const MORE_DROPDOWN_BASE = `${BASE_KEY}.more_dropdown`;

let searchUsers: Function;

export default {
  ACTION_KEYWORDS,
  BASE_KEY,
  NEW_DROPDOWN_BASE,
  MORE_DROPDOWN_BASE,
};

export function setUsersTableSortkey({
  sortOptions,
  cellId,
  FIRST_NAME,
  FULL_NAME,
  UNSORTED,
}: any) {
  let matchSortKey = sortOptions.sortKey[0];

  if (matchSortKey === FIRST_NAME) {
    matchSortKey = FULL_NAME;
  }
  if (matchSortKey === cellId) {
    return sortOptions.sortOrder;
  }

  return UNSORTED;
}

export function highlightContent(text: string, filter: string) {
  return filter.length ? highlightText(text, [filter]) : text;
}

export function viewLogic(props: Props) {
  const {
    azureADSetupDone,
    getMessage,
    localizedColumnNames,
    onRolesClick,
    onNewActionSelected,
    onMoreActionSelected,
    returnCount,
    tableHelper: { activeColumns, allColumns, disabledColumns },
    tableFilter,
    onSearchChange,
    onColumnSelectorChange,
    multiSelect,
    loggedInAsUserId,
    serialResultsLoader,
    users,
  } = props;


  function isAdSync() {
    return !azureADSetupDone && domainInfo().adSyncSettingsEnabled;
  }

  function actionsHelper() {
    const buildList = (...list: string[]) =>
      list
        .slice(0, -1)
        .filter(id => id !== ACTION_KEYWORDS.AD_SYNC || isAdSync())
        .map(id => ({
          id,
          value: getMessage(`${list[list.length - 1]}.${snakeCase(id)}`),
        }));

    const newList = buildList(
      ACTION_KEYWORDS.USER,
      ACTION_KEYWORDS.CSV,
      ACTION_KEYWORDS.AD_SYNC,
      NEW_DROPDOWN_BASE,
    );
    const moreList = buildList(
      ACTION_KEYWORDS.ENABLE,
      ACTION_KEYWORDS.DISABLE,
      MORE_DROPDOWN_BASE,
    );

    const isTableHasHiddenColumns = activeColumns.length !== allColumns.length;

    return {
      rolesButton: {
        onRolesClick,
        users: props.users,
        multiSelect: props.multiSelect,
        loggedInAsId: loggedInAsUserId,
      },
      newWithDropdown: {
        newList,
        onSelectedValue: onNewActionSelected,
      },
      moreButton: {
        moreList,
        onSelectedValue: onMoreActionSelected,
        multiSelect: props.multiSelect,
      },
      search: {
        onChange: onSearchChange,
        value: tableFilter,
        isTableHasHiddenColumns,
        placeholder: getMessage(`${BASE_KEY}.search_placeholder`),
      },
    };
  }

  return {
    actionsHelper: actionsHelper(),
    columnSelector: {
      activeColumns,
      allColumns,
      disabledColumns,
      localizedNames: localizedColumnNames,
      onChange: onColumnSelectorChange,
    },
    returnCount,
    selectedRows: multiSelect.selectedRows,
    onResultsNeeded: serialResultsLoader.pushToQueue,
    usersList: users.users,
    searchString: users.searchString,
    sortOptions: users.sortOptions,
  };
}

export function stateLogic({
  MODAL_MAP,
  resetFlash,
  goToSettingsAdSyncIndex,
  goToUsersNew,
  goToUserEdit,
  sortKeys,
  rowStatuses,
}: any) {
  return function UsersTableStateLogicComposer(component: any) {
    return class UsersTableStateLogic extends Component<Props, any> {
      static propTypes = {
        tableHelper: PropTypes.shape({
          activeColumns: PropTypes.array,
          allColumns: PropTypes.array,
        }).isRequired,
        users: PropTypes.object.isRequired,
        usersBulkOperations: PropTypes.shape({
          lastBulkUpdate: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.array,
          ]),
        }).isRequired,
        multiSelect: PropTypes.object.isRequired,
        getMessage: PropTypes.func.isRequired,
        openModal: PropTypes.func.isRequired,
        returnCount: PropTypes.number.isRequired,
        filterUsers: PropTypes.func.isRequired,
        activeColumnsChanged: PropTypes.func.isRequired,
        changeUserStatus: PropTypes.func.isRequired,
        loggedInAsUserId: PropTypes.number.isRequired,
      };

      constructor(props: Props) {
        super(props);

        props.multiSelect.setResultCounts(props.users.totalCount - 1);
        this.state = { tableFilter: '' };
      }

      componentDidUpdate(prevProps: Props) {
        const {
          selectedRows,
          isAllSelected,
          onReturnCountChange,
        } = prevProps.multiSelect;

        if (prevProps.returnCount !== this.props.returnCount) {
          onReturnCountChange(this.props.returnCount, this.userIds);
        }

        if (
          (selectedRows.length || isAllSelected) &&
          !is(
            fromJS(prevProps.usersBulkOperations.lastBulkUpdate),
            fromJS(this.props.usersBulkOperations.lastBulkUpdate),
          )
        ) {
          this.props.multiSelect.reset();
        }
      }

      onSearchChange = (filter: string) => {
        if (!searchUsers) {
          searchUsers = debounce((search) => {
            $('.infinite-scroll-inner').scrollTop(0);
            this.props.multiSelect.onFilterChange(this.userIds);
            this.props.filterUsers(search);
          }, GENERAL_CONFIGS.INFINITE_SCROLL.SEARCH_DELAY);
        }

        if (this.state.tableFilter !== filter) {
          searchUsers(filter);
          this.setState({ tableFilter: filter });
        }
      };

      onColumnSelectorChange = (widgetId: string, selectedValue: any) => {
        this.props.activeColumnsChanged(
          toggleInArray(this.props.tableHelper.activeColumns, selectedValue),
        );
      };

      onNewActionSelected = (value: any, id: string) => {
        switch (id) {
          case ACTION_KEYWORDS.USER:
            return goToUsersNew();
          case ACTION_KEYWORDS.AD_SYNC:
            return goToSettingsAdSyncIndex();
          case ACTION_KEYWORDS.CSV: {
            resetFlash({ from: 'UsersArtifact' });
            return this.props.openModal(MODAL_MAP.BULK_UPLOAD_USERS);
          }
          default:
            throw new Error(`Unexpected ID: ${id}`);
        }
      };

      onToggleStatus = (id: string, active: boolean) => (e: any) => {
        killEvent(e);
        this.props.changeUserStatus(id, active);
      };

      onGotoEditUser = (id: string) => (e: any) => {
        killEvent(e);
        goToUserEdit({ user_id: id });
      };

      onRolesClick = () => {
        const { selectedRows, isAllSelected } = this.props.multiSelect;
        if (!selectedRows.length && !isAllSelected) {
          this.props.openModal(MODAL_MAP.NO_ITEMS_SELECTED, {
            type: 'roles',
            name: 'users',
          });

          return false;
        }

        return true;
      };

      onMoreActionSelected = (value: any, id: string) => {
        const {
          multiSelect: { selectedRows, isAllSelected },
          returnCount,
          loggedInAsUserId,
        } = this.props;
        const except = this.isActiveUserOnTheList
          ? uniq([loggedInAsUserId].concat(isAllSelected ? selectedRows : []))
          : [];

        const userCount = isAllSelected
          ? returnCount - except.length
          : selectedRows.length;

        if (!userCount) {
          return this.props.openModal(MODAL_MAP.NO_ITEMS_SELECTED, {
            type: 'enabled',
            name: 'users',
          });
        }

        let modalType = MODAL_MAP.BULK_DISABLE_USERS;
        if (id === ACTION_KEYWORDS.ENABLE) {
          modalType = MODAL_MAP.BULK_ENABLE_USERS;
        }

        this.props.openModal(modalType, {
          multiSelect: {
            selectedRows,
            except,
            isAllSelected,
          },
          userCount,
          filters: this.state.tableFilter,
        });

        return {};
      };

      get isActiveUserOnTheList() {
        return this.props.users.users
          .filter(user => user && user.id)
          .some(({ id }: any) => id === this.props.loggedInAsUserId);
      }

      get localizedColumnNames() {
        return this.props.tableHelper.allColumns.reduce(
          (all, column) => ({
            ...all,
            [column]: this.props.getMessage(
              `${BASE_KEY}.columns.${snakeCase(column)}`,
            ),
          }),
          {},
        );
      }

      get userIds() {
        return this.props.users.users.map(({ id }) => id);
      }

      render() {
        return React.createElement(component, {
          ...this.props,
          ...this.state,
          localizedColumnNames: this.localizedColumnNames,
          onSearchChange: this.onSearchChange,
          onColumnSelectorChange: this.onColumnSelectorChange,
          onToggleStatus: this.onToggleStatus,
          onGotoEditUser: this.onGotoEditUser,
          onRolesClick: this.onRolesClick,
          onMoreActionSelected: this.onMoreActionSelected,
          onNewActionSelected: this.onNewActionSelected,
          isActiveUserOnTheList: this.isActiveUserOnTheList,
          sortKeys,
          rowStatuses,
        });
      }
    };
  };
}
