import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withMessages from 'component-utilities/intl';

import Factory from '../../../../global/components/TableView/TableViewContentFactory';
import Heading from '../../../../global/components/TableView/AssetsView/Heading';
import TableView from './TableView';

import { actions } from '../../../../global/components/TableViewActions';

@withMessages
export default class Loaded extends Component {
  static propTypes = {
    returnCount: PropTypes.number,
    searchString: PropTypes.string,
    getMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    returnCount: 0,
    searchString: '',
  };

  render() {
    const { returnCount, searchString, getMessage }: any = this.props;
    const factoryProps = {
      returnCount,
      searchString,
      heading: (
        <Heading
          returnCount={returnCount}
          isSearchForceReady
          searchName={getMessage('app.ad_sync.sync_conflicts.title')}
          visibleTableActions={[actions.SEARCH]}
        />
      ),
      content: (
        <TableView {...this.props} visibleTableActions={[actions.SEARCH]} />
      ),
    };
    return <Factory {...factoryProps} />;
  }
}
