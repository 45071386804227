import { isNumber } from 'lodash';

import reduxStore, { Action } from '../reduxStore';

import { jsUi } from '../lib/jsUi';
import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';

import {
  showSuccessFlash,
  showFailureFlash,
} from './FlashMessagesStateManager';
import { PAGES } from '../components/FlashMessages';

export const INIT: string = 'loginForm/init';
export const RESET: string = 'loginForm/reset';
export const VALIDATE_FORM: string = 'loginForm/validateForm';
export const UPDATE_FIELD: string = 'loginForm/updateField';
export const SUBBMIT_FORM: string = 'loginForm/submitForm';
export const LOGIN_FAILED: string = 'loginForm/loginFailed';
export const RESET_PASSWORD_START: string = 'loginForm/resetPasswordStart';
export const RESET_PASSWORD_FINISHED: string = 'loginForm/resetPasswordFinished';
export const RESET_PASSWORD_FAILED: string = 'loginForm/resetPasswordFailed';
export const VERIFY_PASSWORD_RESET_TOKEN_START: string =
  'loginForm/verifyPasswordResetTokenStart';
export const VERIFY_PASSWORD_RESET_TOKEN_FINISHED: string =
  'loginForm/verifyPasswordResetTokenFinished';
export const VERIFY_PASSWORD_RESET_TOKEN_FAILED: string =
  'loginForm/verifyPasswordResetTokenFailed';
export const SEND_NEW_PASSWORD_START: string = 'loginForm/sendNewPasswordStart';
export const SEND_NEW_PASSWORD_FINISHED: string = 'loginForm/sendNewPasswordFinished';
export const SEND_NEW_PASSWORD_FAILED: string = 'loginForm/sendNewPasswordFailed';

export const FORM_ERRORS = {
  GENERAL_ERROR: 'general_error',
  EXPIRED_LINK: 'expired_link',
  LINK_ALREADY_USED: 'link_already_used',
  UPDATE: 'update',
};

export const BASE_FLASH: string = 'change_password';
export const VALID_FLASH_VIEWS: string[] = [PAGES.LOGIN, PAGES.RESET_PASSWORD];

// Init
const initialErrors = {
  sessionExpired: false,
  userPayloadManipulated: false,
};

interface LoginFormState {
  values: {
    username: string,
    password: string,
    resetPasswordValue: string,
  },
  errors: any,
  userIsLockedOrDisabled: boolean,
  formSubmitted: boolean,
  startTokenLogin: boolean,
  validatedFormValues: boolean,
  showInvalidMessage: boolean,
  showLoginFailedMessage: boolean,
  passwordResetIsLoading: boolean,
  isVerifyTokenLoading: boolean,
  loginAttemptErrors: any,
}

export const INITIAL_STATE = {
  values: {
    username: '',
    password: '',
    resetPasswordValue: '',
  },
  errors: { ...initialErrors },
  userIsLockedOrDisabled: false,
  formSubmitted: false,
  startTokenLogin: false,
  validatedFormValues: true,
  showInvalidMessage: false,
  showLoginFailedMessage: false,
  passwordResetIsLoading: false,
  isVerifyTokenLoading: true,
  loginAttemptErrors: {},
};

// helper
export function loginFormStore() {
  return reduxStore ? reduxStore.getState().loginForm : INITIAL_STATE;
}

export function validateForm() {
  const modifiedStore = { ...loginFormStore() };
  const {
    showLoginFailedMessage,
    formSubmitted,
    values: { username, password },
  } = modifiedStore;

  modifiedStore.validatedFormValues = [username, password].map((value) => {
    if (!showLoginFailedMessage) {
      return formSubmitted ? !!value.length : true;
    }

    return false;
  });
  modifiedStore.showInvalidMessage =
    !showLoginFailedMessage &&
    !modifiedStore.validatedFormValues.every((value: any) => value);

  return modifiedStore;
}

// Actions
export function initLoginFormStore() {
  return {
    type: INIT,
    payload: {
      startTokenLogin:
        !!document && document.location.href.indexOf('token=') !== -1,
    },
  };
}

export function resetPassword(accountId: string) {
  return function* doResetPassword(getState: Function) {
    const {
      values: { resetPasswordValue },
    } = getState().loginForm;

    yield { type: RESET_PASSWORD_START };

    try {
      yield apiReqPromise(jsApi.passwordResetRequestToken, {
        params: { data: { user: resetPasswordValue } },
        urlParams: { account_id: accountId },
      });

      yield { type: RESET_PASSWORD_FINISHED };
    } catch (e) {
      console.error(e);

      yield {
        type: RESET_PASSWORD_FAILED,
        payload: { userIsLockedOrDisabled: e.status === 403 },
      };
    }
  };
}

export function verifyPasswordResetToken(token: string) {
  return function* doVerifyPasswordResetToken() {
    yield { type: VERIFY_PASSWORD_RESET_TOKEN_START };

    try {
      yield apiReqPromise(jsApi.passwordResetVerifyToken, {
        queryParams: `?token=${token}`,
      });

      yield { type: VERIFY_PASSWORD_RESET_TOKEN_FINISHED };
    } catch (e: any) {
      console.error(e);

      let message = FORM_ERRORS.GENERAL_ERROR;

      if (e.status === 400 && e.data['token-expired']) {
        message = FORM_ERRORS.EXPIRED_LINK;
      } else if (e.status === 404) {
        message = FORM_ERRORS.LINK_ALREADY_USED;
      }

      reduxStore.dispatch(
        showFailureFlash(`${BASE_FLASH}.${message}`, VALID_FLASH_VIEWS),
      );

      jsUi.resetPassword.goTo();

      yield { type: VERIFY_PASSWORD_RESET_TOKEN_FAILED };
    }
  };
}

export function sendNewPassword({ accountId, token, password }: any) {
  return function* doSendNewPassword() {
    yield { type: SEND_NEW_PASSWORD_START };

    try {
      yield apiReqPromise(jsApi.passwordResetChangePassword, {
        params: { data: { password, token } },
        urlParams: { account_id: accountId },
      });

      jsUi.loginWithRedirect.goTo();
      reduxStore.dispatch(
        showSuccessFlash(`${BASE_FLASH}.update`, VALID_FLASH_VIEWS),
      );

      yield { type: SEND_NEW_PASSWORD_FINISHED };
    } catch (e) {
      console.error(e);

      let error = FORM_ERRORS.GENERAL_ERROR;

      if (e.data['token-expired']) {
        error = FORM_ERRORS.EXPIRED_LINK;
        jsUi.resetPassword.goTo();
      } else {
        error = FORM_ERRORS.UPDATE;
        jsUi.loginWithRedirect.goTo();
      }

      reduxStore.dispatch(
        showFailureFlash(`${BASE_FLASH}.${error}`, VALID_FLASH_VIEWS),
      );

      yield { type: SEND_NEW_PASSWORD_FAILED };
    }
  };
}

export function updateField(field: string, value: string) {
  return {
    type: UPDATE_FIELD,
    payload: { values: { [field]: value } },
  };
}

export function submitForm() {
  return { type: SUBBMIT_FORM };
}

export function loginFailed({ data }: any = {}) {
  let loginAttemptErrors: any = {};

  if (data) {
    const {
      loginFailureCount,
      maxFailedLogins,
      nextLoginAttemptAllowedAt,
    } = data;

    switch (true) {
      case isNumber(loginFailureCount) && loginFailureCount < maxFailedLogins:
        loginAttemptErrors.accountWillLockIn =
          maxFailedLogins - loginFailureCount;
        break;
      case isNumber(loginFailureCount) && loginFailureCount === maxFailedLogins:
      case !!nextLoginAttemptAllowedAt:
        loginAttemptErrors.locked = true;
        break;
      default:
        loginAttemptErrors = {};
    }
  }

  return {
    type: LOGIN_FAILED,
    payload: { loginAttemptErrors: { ...loginAttemptErrors } },
  };
}

export function resetLoginForm() {
  return { type: RESET };
}

// Store
export default function LoginFormStateManager(
  state = validateForm(),
  { type, payload }: Action,
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        startTokenLogin: payload.startTokenLogin,
      };
    case UPDATE_FIELD:
      return {
        ...state,
        values: {
          ...state.values,
          ...payload.values,
        },
        showLoginFailedMessage: false,
        loginAttemptErrors: {},
      };
    case SUBBMIT_FORM:
      return {
        ...state,
        formSubmitted: true,
        showLoginFailedMessage: false,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        showLoginFailedMessage: true,
        startTokenLogin: false,
        loginAttemptErrors: payload.loginAttemptErrors,
      };
    case RESET_PASSWORD_START:
      return {
        ...state,
        passwordResetIsLoading: true,
      };
    case RESET_PASSWORD_FINISHED:
      return {
        ...state,
        passwordResetIsLoading: false,
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        passwordResetIsLoading: false,
        userIsLockedOrDisabled: payload.userIsLockedOrDisabled,
      };
    case VERIFY_PASSWORD_RESET_TOKEN_START:
      return {
        ...state,
        isVerifyTokenLoading: true,
      };
    case VERIFY_PASSWORD_RESET_TOKEN_FINISHED:
    case VERIFY_PASSWORD_RESET_TOKEN_FAILED:
      return {
        ...state,
        isVerifyTokenLoading: false,
      };
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
