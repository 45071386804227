import { contains, has, omit } from 'lodash';
import { sort } from '../lib/tableviewHelper';

import createReducer from '../lib/createReducer';
import { apiReqPromise } from '../lib/apiRequest';
import jsApi from '../lib/jsApi';

import configs from '../../config/configs';

import reduxStore, { PromiseObject } from '../reduxStore';
import { Job } from 'global/types';

const {
  GENERAL_CONFIGS,
  JOB_STATUSES,
  BULK_ACTIONS,
} = configs;

interface BullkActionsState {
  jobs: any
  jobsList: any[],
  isJobsLoaded: boolean,
}

export { JOB_STATUSES, BULK_ACTIONS };

export const BULK_TYPES = {
  REVIEW_TAG: 'REVIEW_TAG',
  HOLD_REASONS: 'HOLD_REASONS',
};

export const BULK_UPDATE_REVIEW_STATUSES: string = 'bulkActionsStateManager/bulkUpdateReviewStatuses';
export const RESET_REVIEW_JOB: string = 'bulkActionsStateManager/resetReviewJob';

export const BULK_UPDATE_HOLD_REASONS: string = 'bulkActionsStateManager/bulkUpdateHolReasons';
export const RESET_HOLD_REASONS_JOB: string = 'bulkActionsStateManager/resetHoldReasonsjob';

export const UPDATE_STATUS_CHECK: string = 'bulkActionsStateManager/updateStatusCheck';
export const BATCH_CREATED: string = 'bulkActionsStateManager/batchCreated';
export const UPDATE_JOB: string = 'bulkActionsStateManager/updateJob';
export const BATCH_FINISHED: string = 'bulkActionsStateManager/batchFinished';

export const JOBS_FETCH_SUCCESS: string = 'bulkActionsStateManager/JOBS_FETCH_SUCCESS';
export const JOBS_FETCH_FAILURE: string = 'bulkActionsStateManager/JOBS_FETCH_FAILURE';
export const JOBS_FETCH_START: string = 'bulkActionsStateManager/JOBS_FETCH_START';
export const SORT_JOBS: string = 'bulkActionsStateManager/SORT_JOBS';

export const initialState: BullkActionsState = {
  jobs: {},
  jobsList: [],
  isJobsLoaded: false,
};

// helpers
export function generateJobKey(TYPE: string, id: string) {
  return `${TYPE}-${id}`;
}

export function reviewJobKey(searchId: string) {
  return generateJobKey(BULK_TYPES.REVIEW_TAG, searchId);
}

export function holdReasonsJobKey(searchId: string) {
  return generateJobKey(BULK_TYPES.HOLD_REASONS, searchId);
}

export function bulkActionsState() {
  return reduxStore.getState().bulkActions;
}

export function isPendingJob(batch: any) {
  return contains(
    [
      JOB_STATUSES.STARTED,
      JOB_STATUSES.FAILED,
      JOB_STATUSES.PENDING,
      JOB_STATUSES.PREPARING,
    ],
    batch.status
  )
}

export function isBatchComplete(batch: any) {
  return contains(
    JOB_STATUSES.FINISHED,
    batch.status,
  )
}

export function isBatchProcessing(batch: any) {
  return contains(
    [
      JOB_STATUSES.STARTED,
      JOB_STATUSES.PENDING,
      JOB_STATUSES.SCHEDULED,
      JOB_STATUSES.PREPARING,
    ],
    batch.status,
  );
}

export function monitoringBatch(key: string, apiMethod: any) {
  const job = bulkActionsState().jobs[key];

  if (job) {
    if (
      isBatchProcessing(job.batch) &&
      job.attempts <= GENERAL_CONFIGS.MONITORING_MAX_ATTEMPTS
    ) {
      window.setTimeout(
        /* eslint-disable no-use-before-define */
        () => reduxStore.dispatch(getBatchStatus(key, job, apiMethod)),
        GENERAL_CONFIGS.MONITORING_INTERVAL,
      );
    } else {
      reduxStore.dispatch(
        {
          type: BATCH_FINISHED,
          payload: { key, job },
        },
      );
    }
  }
}

export function getBatchStatus(key: string, job: any, apiMethod: any) {
  const updatedJob = { ...job };

  return function* doGetBatchStatus() {
    yield { type: UPDATE_STATUS_CHECK, payload: { key, job } };

    try {
      const { data } = yield apiReqPromise(jsApi[apiMethod], {
        urlParams: { batch_id: updatedJob.batch['batch-id'] },
      });

      updatedJob.attempts += 1;
      updatedJob.batch = data;

      yield {
        type: UPDATE_JOB,
        payload: {
          key,
          job: updatedJob,
        },
      };

      monitoringBatch(key, apiMethod);
    } catch (e) {
      console.error(e);
    }
  };
}

export function fixBulkHoldParams(params: any) {
  const { newAndDeletedIDs: { newIDs, deletedIDs } } = params;

  const fixedParams = {
    ...params,
    attributes: newIDs.length ? newIDs : deletedIDs,
    OPS: newIDs.length ? BULK_ACTIONS.OPS.ADD : BULK_ACTIONS.OPS.REMOVE,
  };

  return omit(fixedParams, 'newAndDeletedIDs');
}

// actions
export function bulkUpdateHelper(key: string, params: object, route: object) {
  return function* doBulkUpdateHelper() {
    yield {
      type: BULK_UPDATE_REVIEW_STATUSES,
      payload: { key },
    };

    try {
      const { data: { batch } } = yield apiReqPromise(route, {
        params: { data: { ...params } },
      });


      yield {
        type: BATCH_FINISHED,
        payload: {
          key,
          batch,
        },
      };
    } catch (e) {
      console.error(e);

      yield {
        type: UPDATE_JOB,
        payload: { key, job: { isSuccess: false } },
      };
    }
  };
}

export function bulkUpdateReviewStatus({ params, searchId }: any) {
  const key = reviewJobKey(searchId);
  return bulkUpdateHelper(key, params, jsApi.bulkUpdateReviewStatus);
}

export function bulkUpdateHoldReasonsStatus({ params, searchId }: any) {
  const key = holdReasonsJobKey(searchId);
  return bulkUpdateHelper(key, fixBulkHoldParams(params), jsApi.bulkUpdateHoldReasonsStatus);
}

export function getJobs() {
  return function* doGetJobs() {
    try {

      yield {
        type: JOBS_FETCH_START,
      }

      let { data: jobs }: PromiseObject = yield apiReqPromise(jsApi.jobsAll);
      jobs = jobs
        .filter((job: Job) => isBatchProcessing(job))
        .map((job: Job) => ({
          ...job,
          ...job.metadata,
        }));
      yield {
        type: JOBS_FETCH_SUCCESS,
        payload: {
          jobsList: jobs,
          pendingJobsCount: jobs.length,
        }
      }
    } catch (e) {
      yield {
        type: JOBS_FETCH_FAILURE
      }
    }
  }
}

export function searchRelatedJobs(searchId: string) {
  const { jobs } = bulkActionsState();

  return {
    review: jobs[reviewJobKey(searchId)],
    holdReasons: jobs[holdReasonsJobKey(searchId)],
  };
}

export function isHoldReasonsJobRunning(searchId: string) {
  const { holdReasons } = searchRelatedJobs(searchId);

  return !!(
    holdReasons &&
    Object.keys(holdReasons).length &&
    !has(holdReasons, 'isSuccess')
  );
}

export function resetReviewJob(searchId: string) {
  return {
    type: RESET_REVIEW_JOB,
    payload: { key: reviewJobKey(searchId) },
  };
}

export function resetHoldReasonsjob(searchId: string) {
  return {
    type: RESET_HOLD_REASONS_JOB,
    payload: { key: holdReasonsJobKey(searchId) },
  };
}

export function sortJobs(direction: string, key: string) {
  return {
    type: SORT_JOBS,
    payload: {
      direction,
      key
    }
  }
}

const BulkActionsStateManager = createReducer(initialState, {
  [BULK_UPDATE_HOLD_REASONS]: (state: BullkActionsState, { key }: any) => ({
    ...state,
    jobs: { ...state.jobs, [key]: { batch: {} } },
  }),
  [BULK_UPDATE_REVIEW_STATUSES]: (state: BullkActionsState, { key }: any) => ({
    ...state,
    jobs: { ...state.jobs, [key]: { batch: {} } },
  }),
  [BATCH_CREATED]: (state: BullkActionsState, { key, batch, newStatus = '' }: any) => ({
    ...state,
    jobs: { ...state.jobs, [key]: { batch, attempts: 0, newStatus } },
  }),
  [UPDATE_JOB]: (state: BullkActionsState, { key, job }: any) => ({
    ...state,
    jobs: { ...state.jobs, [key]: job },
  }),
  [BATCH_FINISHED]: (state: BullkActionsState, { key }: any) => ({
    ...state,
    jobs: {
      ...state.jobs,
      [key]: {
        isSuccess: true
      },
    },
  }),
  [RESET_REVIEW_JOB]: (state: BullkActionsState, { key }: any) => ({
    ...state,
    jobs: { ...state.jobs, [key]: {} },
  }),
  [RESET_HOLD_REASONS_JOB]: (state: BullkActionsState, { key }: any) => ({
    ...state,
    jobs: { ...state.jobs, [key]: {} },
  }),
  [SORT_JOBS]: (state: BullkActionsState, { direction, key }: any) => ({
    ...state,
    jobsList: sort(state.jobsList, key, direction)
  }),
  [JOBS_FETCH_START]: (state: BullkActionsState) => ({
    ...state,
    isJobsLoaded: false,
  }),
  [JOBS_FETCH_SUCCESS]: (state: BullkActionsState, { pendingJobsCount, jobsList }: any) => ({
    ...state,
    jobsList,
    pendingJobsCount,
    isJobsLoaded: true,
  }),
  [JOBS_FETCH_FAILURE]: (state: BullkActionsState) => ({
    ...state,
    jobsList: [],
    isJobsLoaded: true,
    pendingJobsCount: 0,
  }),
});

export default BulkActionsStateManager;