/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

import { includes } from 'lodash';

import { jsUi } from '../../../global/lib/jsUi';
import getVisibleRows from './getVisibleRows';

const mapStateToProps = ({ userRights, domainInfo }: any) => ({
  activeRoles: userRights.activeRoles,
  domainInfo,
});

export default function validateSettingsAccess(pageId: string) {
  return (component: any) =>
    @connect(mapStateToProps)
    class ValidateSettingsAccess extends Component {
      constructor(props: any) {
        super(props);

        if (!includes(getVisibleRows(props), pageId)) {
          browserHistory.push(jsUi.settingsIndex.path());
        } else {
          this.shouldRender = true;
        }
      }

      render() {
        if (!this.shouldRender) {
          return null;
        }

        return React.createElement(component, this.props);
      }
    };
}
