import { SortCell } from 'v-c/TableView';
import asyncTypeStoreInitialConfig from './asyncTypeStoreInitialConfig';

const columns = {
  STATUS: 'status',
  REQUESTED_DATE: 'requestedDate',
  REQUESTED_BY: 'requester',
  DATE_RANGE: 'dateRange',
};

const {
  STATUS,
  REQUESTED_DATE,
  REQUESTED_BY,
  DATE_RANGE,
} = columns;

const allColumns = [STATUS, REQUESTED_DATE, REQUESTED_BY, DATE_RANGE];
const disabledColumns = [STATUS, REQUESTED_BY];

const reportsStoreConfig = {
  initialState: {
    ...asyncTypeStoreInitialConfig,
    id: null,
    status: '',
    reports: [],
    originalReports: [],
    options: {},
    tableHelper: {
      columns,
      allColumns,
      activeColumns: allColumns,
      disabledColumns,
    },
    sortOptions: {
      sortKey: columns.DATE_RANGE,
      sortOrder: SortCell.sortKeys.DESC,
    },
  },
  columns,
};

export default reportsStoreConfig;
