import IfFeature from '../../components/IfFeature';

import { ROUTES } from '../../lib/jsUi';
import { ROLES } from '../../../../../../config/roles.json';
import Pages from '../Pages';
import {
  USER_CAN_EDIT_SAVED_SEARCHES,
  USER_CAN_DOWNLOAD_OR_SEND_MESSAGES,
  USER_CAN_DOWNLOAD_ATTACHMENTS,
  USER_CAN_REQUEST_EXPORT,
  USER_CAN_DELETE_ASSETS,
  USER_CAN_SEE_COLLECTION_CONFIGURATION,
  USER_CAN_EDIT_RETENTION_POLICY,
  USER_CAN_USE_COMMENT_API,
  USER_CAN_USE_AD_SYNC,
} from '../../../config/constants/PageRoles';

const {
  SEARCHES,
  SEARCHES_EDIT,
  EXPORTS,
  HOLD_REASONS,
  NEW_HOLD_REASON,
  NEW_SEARCH,
  NEW_USER,
  SETTINGS,
  USER_MANAGEMENT,
  CHANGE_PASSWORD,
  DASHBOARD,
  JOBS
} = Pages;

const { DASHBOARD: DASHBOARD_INDEX, SEARCHES_INDEX }: any = ROUTES || {};

export default {
  name: ROLES.ARCHIVE_ADMINISTRATOR,
  tag: 'AA',
  homePage: IfFeature.isDisabled('account.summary')
    ? SEARCHES_INDEX
    : DASHBOARD_INDEX,
  visibleMenus: {
    search: [DASHBOARD, SEARCHES],
    nav: [EXPORTS, HOLD_REASONS, USER_MANAGEMENT, JOBS],
    sub: [NEW_SEARCH, NEW_HOLD_REASON, NEW_USER],
    other: [CHANGE_PASSWORD, SETTINGS, SEARCHES_EDIT],
  },
  enabledPageRoles: [
    USER_CAN_EDIT_SAVED_SEARCHES,
    USER_CAN_DOWNLOAD_OR_SEND_MESSAGES,
    USER_CAN_DOWNLOAD_ATTACHMENTS,
    USER_CAN_SEE_COLLECTION_CONFIGURATION,
    USER_CAN_REQUEST_EXPORT,
    USER_CAN_DELETE_ASSETS,
    USER_CAN_EDIT_RETENTION_POLICY,
    USER_CAN_USE_COMMENT_API,
    USER_CAN_USE_AD_SYNC,
  ],
};
