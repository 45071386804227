import uri from 'urijs';
import IfFeature from '../components/IfFeature';
import reduxStore, { PromiseObject, Action } from '../reduxStore';
import { resetFlash, showSuccessFlash, showFailureFlash } from './FlashMessagesStateManager';
import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';

import constants from '../../config/constants';
import { isPageRoleEnabled, pageRoles } from '../lib/userRights';
import { PAGES } from './CurrentSavedSearchStateManager';
import { setCommentCount } from './SearchResultsStateManager';

export const SAVE_COMMENT_START: string = 'asset/saveCommentStart';
export const SAVE_COMMENT_FINISH: string = 'asset/saveCommentFinish';
export const SAVE_COMMENT_FAIL: string = 'asset/saveCommentFail';
export const EDIT_COMMENT_START: string = 'asset/editCommentStart';
export const EDIT_COMMENT_FINISH: string = 'asset/editCommentFinish';
export const EDIT_COMMENT_FAIL: string = 'asset/editCommentFail';
export const DELETE_COMMENT_START: string = 'asset/deleteCommentStart';
export const DELETE_COMMENT_FINISH: string = 'asset/deleteCommentFinish';
export const DELETE_COMMENT_FAIL: string = 'asset/deleteCommentFail';
export const LOAD_ASSET_START: string = 'asset/loadAssetStart';
export const LOAD_ASSET_FINISHED: string = 'asset/loadAssetFinished';
export const LOAD_ASSET_FAILED: string = 'asset/loadAssetFailed';
export const LOAD_ASSET_SKIP: string = 'asset/loadAssetSkip';
export const PREFETCH_ASSETS_SKIP: string = 'asset/prefetchAssetsSkip';
export const PREFETCH_ASSETS_START: string = 'asset/prefetchAssetsStart';
export const PREFETCH_ASSETS_FINISHED: string = 'asset/prefetchAssetsFinished';
export const PREFETCH_ASSETS_FAILED: string = 'asset/prefetchAssetsFailed';
export const UPDATE_ASSOCIATED_HOLD_REASONS_START: string =
  'asset/updateAssociatedHoldReasonsStart';
export const UPDATE_ASSOCIATED_HOLD_REASONS_FINISHED: string =
  'asset/updateAssociatedHoldReasonsFinished';
export const UPDATE_ASSOCIATED_HOLD_REASONS_FAILED: string =
  'asset/updateAssociatedHoldReasonsFailed';
export const GET_ASSOCIATED_REVIEW_STATUS_START: string =
  'asset/getAssociatedReviewStatusStart';
export const GET_ASSOCIATED_REVIEW_STATUS_FINISHED: string =
  'asset/getAssociatedReviewStatusFinished';
export const GET_ASSOCIATED_REVIEW_STATUS_FAILED: string =
  'asset/getAssociatedReviewStatusFailed';
export const UPDATE_ASSOCIATED_REVIEW_STATUS_START: string =
  'asset/updateAssociatedReviewStatusStart';
export const UPDATE_ASSOCIATED_REVIEW_STATUS_FINISHED: string =
  'asset/updateAssociatedReviewStatusFinished';
export const UPDATE_ASSOCIATED_REVIEW_STATUS_FAILED: string =
  'asset/updatedAssociatedReviewStatusFailed';
export const RECOVER_MESSAGE_START: string = 'asset/recoverMessageStart';
export const RECOVER_MESSAGE_FINISHED: string = 'asset/recoverMessageFinished';
export const RECOVER_MESSAGE_FAILED: string = 'asset/recoverMessageFailed';
export const RECOVER_MESSAGE_EXCEEDS_SIZE_LIMIT: string =
  'asseet/recoverMessageExceedsSizeLimit';
export const ATTACHMENTS_INFO_LOADED: string = 'asset/attachmentsInfoLoaded';
export const RESET_RECOVER_MESSAGE_STATUS: string = 'asset/resetRecoverMessageStatus';
export const RESET: string = 'asset/reset';

export const { STATUSES } = constants;
const assetSizeLimit: number = 24999999;
const VALID_VIEWS: string[] = [PAGES.SEARCH_RESULTS, PAGES.ASSET_VIEW];

interface Comment {
  'comment-id': string,
  author: string,
  body: string,
  ctime: string,
  mtime: string,
}

interface Attachment {
  filename: string,
  offset: number,
}

interface AssetData {
  subject: string,
  legalHoldReasons: [],
  attachments: Attachment[],
  downloadableAttachments: Attachment[],
  comments: Comment[],
}

interface AssetState {
  isLoading: boolean,
  isLoaded: boolean,
  isUpdating: boolean,
  updatedAssetId: null,
  id: string | null,
  data: {
    subject: '',
    legalHoldReasons: [],
    attachments: [],
    downloadableAttachments: [],
    comments: [],
  },
  recoverMessageStatus: string,
}

// Init
export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  isUpdating: false,
  updatedAssetId: null,
  id: null,
  data: {
    subject: '',
    legalHoldReasons: [],
    attachments: [],
    downloadableAttachments: [],
    comments: [],
  },
  recoverMessageStatus: STATUSES.INIT,
};

// helpers
export function loadAsset(id: string, withAdditionalInfo = false) {
  return function* doLoadAsset(getState: Function) {
    yield { type: LOAD_ASSET_START, payload: { id } };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.assetsShow, {
        urlParams: { asset_id: id },
        queryParams: `?withAdditionalInfo=${withAdditionalInfo}`,
      });

      let comments = [];

      if (IfFeature.isEnabled('comments') && isPageRoleEnabled(pageRoles.USER_CAN_USE_COMMENT_API)) {
        const res: PromiseObject = yield apiReqPromise(jsApi.commentsIndex, {
          urlParams: {
            account_id: getState().domainInfo.accountId,
            document_id: id,
          },
        });
        comments = res.data.comments;
      }

      if (getState().asset.id === id) {
        const modifiedData = {
          ...result.data,
          legalHoldReasons: result.data.reasons,
          comments,
        };
        delete modifiedData.reasons;

        yield {
          type: LOAD_ASSET_FINISHED,
          payload: { data: modifiedData },
        };
      } else {
        yield { type: LOAD_ASSET_SKIP };
      }
    } catch (e) {
      console.error(e);

      yield { type: LOAD_ASSET_FAILED };
    }
  };
}

export function deleteComment(documentId: string, commentId: string) {
  return function* doDeleteComment(getState: Function) {
    yield { type: DELETE_COMMENT_START };
    reduxStore.dispatch(resetFlash({ from: 'AssetStateManager/deleteComment' }));
    try {
      yield apiReqPromise(jsApi.commentsDestroy, {
        urlParams: {
          account_id: getState().domainInfo.accountId,
          document_id: documentId,
          comment_id: commentId,
        },
      });
      const comments = [...getState().asset.data.comments].map((comment) => {
        const newComment = { ...comment };
        if (newComment['comment-id'] === commentId) {
          newComment.body = null;
        }
        return newComment;
      });
      reduxStore.dispatch(showSuccessFlash('asset_comment.delete', VALID_VIEWS));
      yield { type: DELETE_COMMENT_FINISH, payload: comments };
    } catch (e) {
      reduxStore.dispatch(showFailureFlash('asset_comment.delete', VALID_VIEWS));
      yield { type: DELETE_COMMENT_FAIL };
    }
  };
}

export function editComment(documentId: string, body: string, commentId: string) {
  return function* doEditComment(getState: Function) {
    yield { type: EDIT_COMMENT_START };
    reduxStore.dispatch(resetFlash({ from: 'AssetStateManager/editComment' }));

    try {
      yield apiReqPromise(jsApi.commentsEdit, {
        urlParams: {
          account_id: getState().domainInfo.accountId,
          document_id: documentId,
          body,
          comment_id: commentId,
        },
      });
      const comments = [...getState().asset.data.comments].map((comment) => {
        if (comment['comment-id'] === commentId) {
          return { ...comment, body, mtime: new Date() };
        }
        return comment;
      });
      reduxStore.dispatch(showSuccessFlash('asset_comment.update', VALID_VIEWS));
      yield { type: EDIT_COMMENT_FINISH, payload: comments };
    } catch (e) {
      reduxStore.dispatch(showFailureFlash('asset_comment.update', VALID_VIEWS));
      yield { type: EDIT_COMMENT_FAIL };
    }
  };
}

export function saveComment(documentId: string, body: string) {
  return function* doSaveComment(getState: Function) {
    yield { type: SAVE_COMMENT_START };
    reduxStore.dispatch(resetFlash({ from: 'AssetStateManager/saveComment' }));

    try {
      const { accountId } = getState().domainInfo;

      yield apiReqPromise(jsApi.commentsCreate, {
        urlParams: {
          account_id: accountId,
          document_id: documentId,
          body,
        },
      });
      reduxStore.dispatch(showSuccessFlash('asset_comment.create', VALID_VIEWS));
      const { data: { comments } } = yield apiReqPromise(jsApi.commentsIndex, {
        urlParams: {
          account_id: accountId,
          document_id: documentId,
        },
      });

      // set comment count for doc in order to show red comment icon in split view
      reduxStore.dispatch(setCommentCount(documentId, comments.length));
      yield { type: SAVE_COMMENT_FINISH, payload: comments };
    } catch (e) {
      if (e.status === 409) {
        reduxStore.dispatch(showFailureFlash(e.data.message, VALID_VIEWS));
      } else {
        reduxStore.dispatch(showFailureFlash('asset_comment.create', VALID_VIEWS));
      }
      yield { type: SAVE_COMMENT_FAIL };
    }
  };
}

// Actions
export function prefetchAssets(assets: string[]) {
  return function* doPrefetchAssets() {
    if (assets && !!assets.length) {
      yield { type: PREFETCH_ASSETS_START };

      try {
        yield apiReqPromise(jsApi.renderedAssetsPrefetch, {
          params: {
            data: { assets },
          },
        });

        yield { type: PREFETCH_ASSETS_FINISHED };
      } catch (e) {
        console.error(e);

        yield { type: PREFETCH_ASSETS_FAILED };
      }
    } else {
      yield { type: PREFETCH_ASSETS_SKIP };
    }
  };
}

export function loadAssetViewAsset(id: string) {
  return loadAsset(id, true);
}

export function loadSplitViewAsset(id: string) {
  return loadAsset(id);
}

export function updateAssociatedHoldReasons(assetId: string, ids: string[]) {
  return function* doUpdateAssociatedHoldReasons() {
    yield { type: UPDATE_ASSOCIATED_HOLD_REASONS_START };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.assetsReasonsUpdate, {
        params: { data: { ids } },
        urlParams: { asset_id: assetId },
      });

      yield {
        type: UPDATE_ASSOCIATED_HOLD_REASONS_FINISHED,
        payload: { legalHoldReasons: result.data, assetId },
      };
    } catch (e) {
      console.error(e);

      yield { type: UPDATE_ASSOCIATED_HOLD_REASONS_FAILED };
    }
  };
}

export function getAssociatedReviewStatus({ assetId, searchId } : { assetId: string, searchId: string }) {
  return function* doGetAssociatedReviewStatus() {
    yield { type: GET_ASSOCIATED_REVIEW_STATUS_START };

    try {
      const queryParams = uri('')
        .search({ search_id: searchId })
        .toString();

      const result: PromiseObject = yield apiReqPromise(jsApi.assetsReviewTagStatus, {
        urlParams: { asset_id: assetId },
        queryParams,
      });

      yield {
        type: GET_ASSOCIATED_REVIEW_STATUS_FINISHED,
        payload: { reviewStatus: result.data.reviewStatus },
      };
    } catch (e) {
      console.error(e);

      yield { type: GET_ASSOCIATED_REVIEW_STATUS_FAILED };
    }
  };
}

export function updateAssociatedReviewStatus({ assetId, params } : { assetId: string, params: any }) {
  return function* doUpdateAssociatedReviewStatus() {
    yield { type: UPDATE_ASSOCIATED_REVIEW_STATUS_START };

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi.assetsReviewTagUpdate, {
        params: { data: { ...params } },
        urlParams: { asset_id: assetId },
      });

      yield {
        type: UPDATE_ASSOCIATED_REVIEW_STATUS_FINISHED,
        payload: { reviewStatus: result.data.reviewStatus, assetId },
      };
    } catch (e) {
      console.error(e);

      yield { type: UPDATE_ASSOCIATED_REVIEW_STATUS_FAILED };
    }
  };
}

export function recoverMessage(assetId: string, requestData: any, assetSize: number) {
  return function* doUpdateAssociatedReviewStatus() {
    yield { type: RECOVER_MESSAGE_START };
    const attachmentsSize = (requestData.downloadableAttachments || []).reduce(
      (all, item) => all + ((item || {}).size || 0),
      0,
    );

    if (assetSize + attachmentsSize <= assetSizeLimit) {
      try {
        yield apiReqPromise(jsApi.assetsRecover, {
          urlParams: { asset_id: assetId },
          params: { data: { recover: requestData } },
        });

        yield { type: RECOVER_MESSAGE_FINISHED };
      } catch (e) {
        console.error(e);

        yield { type: RECOVER_MESSAGE_FAILED };
      }
    } else {
      yield { type: RECOVER_MESSAGE_EXCEEDS_SIZE_LIMIT };
    }
  };
}

export function attachmentsInfoLoaded(attachments: Attachment[]) {
  return { type: ATTACHMENTS_INFO_LOADED, payload: { attachments } };
}

export function resetRecoverMessageStatus() {
  return { type: RESET_RECOVER_MESSAGE_STATUS };
}

export function resetAssetStore() {
  return { type: RESET };
}

// Store
export default function AssetStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case DELETE_COMMENT_FINISH:
    case SAVE_COMMENT_FINISH:
    case EDIT_COMMENT_FINISH:
      return {
        ...state,
        data: {
          ...state.data,
          comments: payload,
        },
      };
    case LOAD_ASSET_START:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        data: INITIAL_STATE.data,
        id: payload.id,
      };
    case LOAD_ASSET_FINISHED:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: payload.data,
      };
    case LOAD_ASSET_FAILED:
      return {
        ...state,
        isLoading: false,
        id: null,
      };
    case UPDATE_ASSOCIATED_HOLD_REASONS_START:
      return {
        ...state,
        isUpdating: true,
        updatedAssetId: null,
      };
    case UPDATE_ASSOCIATED_HOLD_REASONS_FINISHED:
      return {
        ...state,
        isUpdating: false,
        isLoading: false,
        updatedAssetId: payload.assetId,
        data: {
          ...state.data,
          legalHoldReasons: payload.legalHoldReasons,
        },
      };
    case UPDATE_ASSOCIATED_HOLD_REASONS_FAILED:
      return {
        ...state,
        isUpdating: false,
        isLoading: false,
      };
    case GET_ASSOCIATED_REVIEW_STATUS_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ASSOCIATED_REVIEW_STATUS_FINISHED:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          reviewStatus: payload.reviewStatus,
        },
      };
    case GET_ASSOCIATED_REVIEW_STATUS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_ASSOCIATED_REVIEW_STATUS_START:
      return {
        ...state,
        isUpdating: true,
        updatedAssetId: null,
      };
    case UPDATE_ASSOCIATED_REVIEW_STATUS_FINISHED:
      return {
        ...state,
        isUpdating: false,
        updatedAssetId: payload.assetId,
        data: {
          ...state.data,
          reviewStatus: payload.reviewStatus,
        },
      };
    case UPDATE_ASSOCIATED_REVIEW_STATUS_FAILED:
      return {
        ...state,
        isUpdating: false,
      };
    case RECOVER_MESSAGE_START:
      return {
        ...state,
        recoverMessageStatus: STATUSES.PROCESSING,
      };
    case RECOVER_MESSAGE_FINISHED:
      return {
        ...state,
        recoverMessageStatus: STATUSES.SUCCESS,
      };
    case RECOVER_MESSAGE_FAILED:
      return {
        ...state,
        recoverMessageStatus: STATUSES.FAILED,
      };
    case RECOVER_MESSAGE_EXCEEDS_SIZE_LIMIT:
      return {
        ...state,
        recoverMessageStatus: STATUSES.EXCEEDS_SIZE_LIMIT,
      };
    case ATTACHMENTS_INFO_LOADED:
      return {
        ...state,
        data: {
          ...state.data,
          attachments: payload.attachments,
        },
      };
    case RESET_RECOVER_MESSAGE_STATUS:
      return {
        ...state,
        recoverMessageStatus: STATUSES.INIT,
      };
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}