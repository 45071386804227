/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { TextLabel } from 'view-components/components/Widgets/Components';
import RetinaImage from 'view-components/components/RetinaImage';
import DropdownPanel from 'view-components/components/CompositeComponents/DropdownPanel';

import { openModal } from '../../state_managers/ModalsStateManager';
import MODAL_MAP from '../Modals/modalMap.json';

import './ActionsBase.scss';

import { canPerformExport } from '../../state_managers/ExportsStateManager';
import { ReduxState } from 'global/types';
import ifFeature from "../IfFeature";

type Props = {
  actionCount: Function
  component: any
  getMessage: Function
  isActionDisabled: any
  onActionClick: Function
  onWrapperClick: any
  type: any
  shouldRenderTitles: boolean
  isActionOpened: boolean
  newExportsDropdownEnabled: boolean
  noAssetsSelected: boolean
  modalType: string
  openedAction: string
  completeDropdownView: {
    show: boolean
    success: boolean
  }
  openedStateImage: any
  closedStateImage: any
  isExportAction: boolean
  exportJobList: any[]
  showDropdownPanel: boolean,
  isHoldReasonAssets: boolean,
  loggedInAs: string,
  closeActions: Function
  openModal: Function
}

function calculatePanelSettings(props: Props) {
  const {
    isActionOpened,
    openedStateImage,
    closedStateImage,
    completeDropdownView,
    isExportAction,
    exportJobList,
    noAssetsSelected,
    showDropdownPanel,
    isHoldReasonAssets,
    loggedInAs,
  } = props;

  const classPreText = isActionOpened ? 'opened-' : '';
  const preImage = isActionOpened ? openedStateImage : closedStateImage;

  const isOverProcessingLimit =
    isExportAction &&
    exportJobList &&
    !canPerformExport(exportJobList, loggedInAs);

  const actionOpenAndAssetsSelected =
    isActionOpened &&
    !noAssetsSelected &&
    (!isOverProcessingLimit || completeDropdownView.show);

  let renderContentInDropdownPanel = false;
  let renderContentRaw = false;

  if (actionOpenAndAssetsSelected) {
    if (isExportAction && !isHoldReasonAssets) {
      renderContentRaw = true;
    } else {
      renderContentInDropdownPanel = showDropdownPanel;
    }
  }

  return {
    classPreText,
    preImage,
    isOverProcessingLimit,
    renderContentInDropdownPanel,
    renderContentRaw,
  };
}

const mapStateToProps = ({ modals, authentication }: ReduxState) => ({
  modalType: modals.type,
  loggedInAs: authentication.loggedInAs,
});
const mapActionsToProps = { openModal };

@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class ActionsBasePanel extends Component<Props> {
  // TODO: figure out what's required and what's not
  /* eslint-disable react/require-default-props */
  static propTypes = {
    actionCount: PropTypes.func,
    component: PropTypes.any,
    getMessage: PropTypes.func.isRequired,
    isActionDisabled: PropTypes.any,
    onActionClick: PropTypes.func.isRequired,
    onWrapperClick: PropTypes.any,
    type: PropTypes.any,
    shouldRenderTitles: PropTypes.bool,
  };

  static defaultProps = {
    shouldRenderTitles: true,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState) {
    const {
      completeDropdownView,
      type,
      isActionOpened,
      newExportsDropdownEnabled,
      noAssetsSelected,
      modalType,
      openedAction,
    } = nextProps;
    const updatedStates = { openedAction };

    if (
      prevState.openedAction !== 'export' &&
      nextProps.openedAction === 'export'
    ) {
      updatedStates.isExportLimitModalAlreadyOpened = false;
    }

    const { isOverProcessingLimit } = calculatePanelSettings(nextProps);

    if (isActionOpened && !modalType) {
      if (noAssetsSelected) {
        nextProps.openModal(MODAL_MAP.NO_ITEMS_SELECTED, {
          type,
          name: 'assets',
        });

        nextProps.closeActions();
      }

      if (
        newExportsDropdownEnabled &&
        !prevState.isExportLimitModalAlreadyOpened &&
        isOverProcessingLimit &&
        !completeDropdownView.show &&
        !completeDropdownView.success
      ) {
        updatedStates.isExportLimitModalAlreadyOpened = true;
        nextProps.openModal(MODAL_MAP.OVER_EXPORT_LIMIT);
      }
    }

    return updatedStates;
  }

  constructor(props: Props) {
    super(props);

    this.state = { isExportLimitModalAlreadyOpened: false, openedAction: '' };
  }

  get renderContent() {
    return React.createElement(this.props.component, this.props);
  }

  get renderActionCount() {
    const { actionCount } = this.props;

    if (actionCount && !!actionCount(this.props)) {
      return ` (${actionCount(this.props)})`;
    }

    return '';
  }

  render() {
    const {
      component,
      type,
      onActionClick,
      getMessage,
      isActionDisabled,
      onWrapperClick,
      shouldRenderTitles,
    } = this.props;


    if (!component || !type) {
      throw new Error('This should be wrapped inside of ActionsBase');
    }

    const {
      classPreText,
      preImage,
      renderContentInDropdownPanel,
      renderContentRaw,
    } = calculatePanelSettings(this.props);

    const updatedActionClick = isActionDisabled ? null : onActionClick;

    return (
      <div
        className={`action-wrapper${isActionDisabled ? '-disabled' : ''}`}
        onClick={onWrapperClick}
        data-action={`${type}-action`}
        role="button"
        tabIndex="-1"
      >
        <TextLabel
          className={`${classPreText}action-label`}
          onClick={updatedActionClick}
        >
          <RetinaImage className={shouldRenderTitles ? 'action-image' : 'labeless-action-image'} image={preImage} />
          {shouldRenderTitles && `${getMessage(`app.table_actions.${type}`)}${
            this.renderActionCount
          }`}
        </TextLabel>
        {renderContentInDropdownPanel && (
          <DropdownPanel>{this.renderContent}</DropdownPanel>
        )}
        {renderContentRaw && this.renderContent}
      </div>
    );
  }
}
