/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isNaN } from 'lodash';

import withMessages from 'component-utilities/intl';

import {
  Dropdown,
  InputField,
} from 'view-components/components/Widgets/Components';

import getLocalizedData from '../../../lib/getLocalizedData';

import criteriaViewBase from './Components/CriteriaViewBase';
import styles from './Components/CriteriaViewBase.scss';

type Props = {
  getMessage: Function
  form: {
    selectedCriteria: string
    to: {
      size: number
      value: string
    }
    from: {
      size: number
      value: string
    }
  }
  listElem: {
    values: {
      from: {
        size: number
        value: string
      },
      to: {
        size: number
        value: string
      },
      dependency: string
      relation: string
      selectedCriteria: string
    }
    criteriaType: string
  }
  updateBaseState: Function
  criteriasVisibilities: {}
  setInitialFormAndOriginalValues: Function
  registerCalculateVisibleCriteriaNum: Function
  registerIsEmptyCriteria: Function
  registerRenderHeaderText: Function
  registerIsApplyButtonDisabled: Function
  changeFormValue: Function
  isDirty: Function
  isViewOnly: boolean
  killEvent: Function
  getOriginalFormValues: Function
  renderGreenText: Function
  renderContent: Function
}

@withMessages
@criteriaViewBase
export default class SizesCriteriaView extends Component<Props> {
  static propTypes = {
    getMessage: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    listElem: PropTypes.object.isRequired,
    updateBaseState: PropTypes.func.isRequired,
    criteriasVisibilities: PropTypes.object.isRequired,
    setInitialFormAndOriginalValues: PropTypes.func.isRequired,
    registerCalculateVisibleCriteriaNum: PropTypes.func.isRequired,
    registerIsEmptyCriteria: PropTypes.func.isRequired,
    registerRenderHeaderText: PropTypes.func.isRequired,
    registerIsApplyButtonDisabled: PropTypes.func.isRequired,
    changeFormValue: PropTypes.func.isRequired,
    isDirty: PropTypes.func.isRequired,
    isViewOnly: PropTypes.bool.isRequired,
    killEvent: PropTypes.func.isRequired,
    getOriginalFormValues: PropTypes.func.isRequired,
    renderGreenText: PropTypes.func.isRequired,
    renderContent: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    const { listElem: { values }, form } = props;

    this.criteriaValues = getLocalizedData.apply(this, ['advSizeSelections']);
    this.sizeValues = getLocalizedData.apply(this, ['advSizeValuesSelections']);

    props.updateBaseState({
      criteriasVisibilities: {
        ...props.criteriasVisibilities,
        isRelationVisible: false,
      },
    });

    props.setInitialFormAndOriginalValues(
      values.selectedCriteria
        ? values
        : {
          ...values,
          ...form,
          relation: 'or',
          selectedCriteria: this.criteriaValues[0].id,
          from: {
            value: '',
            size: this.sizeValues[0].id,
          },
          to: {
            value: '',
            size: this.sizeValues[0].id,
          },
        },
    );

    this.state = {
      visibleCriteria: 0,
    };

    props.registerCalculateVisibleCriteriaNum(() => {});
    props.registerIsEmptyCriteria(this.isEmptyCriteria);
    props.registerRenderHeaderText(this.renderHeaderText);
    props.registerIsApplyButtonDisabled(this.isApplyButtonDisabled);
  }

  onSizeInputValueChanged = (widgetId: string) => (e: any) => {
    const newFormValue = this.props.form[widgetId];
    const newValue = e.target.value;
    const { isViewOnly } = this.props;

    if (
      !isViewOnly &&
      ((!isNaN(parseInt(newValue, 10)) && newValue !== '0') || !newValue.length)
    ) {
      newFormValue.value = newValue.length ? `${parseInt(newValue, 10)}` : '';

      this.props.changeFormValue(widgetId, newFormValue);
    }
  };

  onCriteriaChanged = (widgetId: string, value: string) => {
    const { isRange } =
      this.criteriaValues.find(({ id }: any) => value === id) || {};

    if (!isRange) {
      const toValue = this.props.form.to;
      toValue.value = '';
      this.props.changeFormValue('to', toValue);
    }

    this.props.changeFormValue(widgetId, value);
  };

  onValueDropdownChanged = (widgetId: string, value: string) => {
    const newValue = this.props.form[widgetId];
    newValue.size = value;

    this.props.changeFormValue(widgetId, newValue);
  };

  getSelectedCriteriaObject = () => {
    const { form: { selectedCriteria } } = this.props;

    return this.criteriaValues.find(({ id }: any) => selectedCriteria === id) || {};
  };

  isApplyButtonDisabled = () => {
    const isDirty = this.props.isDirty();
    const { isRange = false } = this.getSelectedCriteriaObject();
    const { form: { from, to } } = this.props;

    if (!from.value.length) return true;

    if (isRange && (!from.value.length || !to.value.length)) {
      return true;
    }

    return !isDirty;
  };

  isEmptyCriteria = (newValues = this.props.getOriginalFormValues()) => {
    const { isRange = false } = this.getSelectedCriteriaObject();
    const { from: { value: fromValue }, to: { value: toValue } } = newValues;

    return (
      !fromValue.length || (isRange && (!fromValue.length || !toValue.length))
    );
  };

  renderHeaderText = () => {
    const {
      selectedCriteria = '',
      from = { value: '' },
      to = {},
      dependency,
      relation,
    } = this.props.listElem.values;

    if (from.value.length) {
      const selectedCriteriaObject = this.criteriaValues.find(
        ({ id }: any) => selectedCriteria === id,
      );
      const { isRange } = selectedCriteriaObject;
      const renderGreenText = (value: string) =>
        this.props.renderGreenText(
          this.props.getMessage(`app.criterias.${value}`).toLowerCase(),
        );
      const getLocalizedValue = (value: string) =>
        this.sizeValues.find(({ id }: any) => value === id).value;

      return (
        <data>
          {renderGreenText(dependency)}
          {'\u00A0'}
          {this.props.renderGreenText(selectedCriteriaObject.value)}
          {'\u00A0'}
          {`${from.value} ${getLocalizedValue(from.size)}`}
          {isRange && (
            <data>
              {'\u00A0'}
              {renderGreenText(relation)}
              {'\u00A0'}
              {`${to.value} ${getLocalizedValue(to.size)}`}
            </data>
          )}
        </data>
      );
    }

    return null;
  };

  renderValueSelectorGroup = (valueName: string, placeholder: string) => {
    const { form: { [valueName]: { value, size } }, isViewOnly } = this.props;
    const { isRange = false } = this.getSelectedCriteriaObject();
    const className = `sizes-group-wrapper${isRange ? '' : '-taller'}`;

    return (
      <div className={styles[className]}>
        <InputField
          widgetId={valueName}
          data-field={`sizes-${valueName}`}
          placeholder={placeholder}
          onChange={this.onSizeInputValueChanged(valueName)}
          className={styles['size-numerical-value']}
          value={value}
          isFocused
        />
        <Dropdown
          widgetId={valueName}
          color="gray"
          onClick={this.props.killEvent}
          selectedValue={size}
          className={styles['size-value']}
          list={this.sizeValues}
          onSelectedValue={!isViewOnly && this.onValueDropdownChanged}
        />
      </div>
    );
  };

  renderCriteriaDropdownContent = () => {
    const {
      listElem: { criteriaType },
      getMessage,
      isViewOnly,
      form: { selectedCriteria },
    } = this.props;
    const { isRange = false } = this.getSelectedCriteriaObject();
    const fromPlaceholder = getMessage(
      `app.criterias.sizes.placeholder${isRange ? '_from' : ''}`,
    );
    const toPlaceholder = getMessage('app.criterias.sizes.placeholder_to');

    return (
      <div
        className={styles[`${criteriaType}-criteria-dropdown-content`]}
        data-criteria-content={criteriaType}
      >
        <div className={styles['sizes-group-wrapper']}>
          <Dropdown
            widgetId="selectedCriteria"
            color="gray"
            onClick={this.props.killEvent}
            selectedValue={selectedCriteria}
            list={this.criteriaValues}
            onSelectedValue={!isViewOnly && this.onCriteriaChanged}
          />
        </div>
        {this.renderValueSelectorGroup('from', fromPlaceholder)}
        {isRange && this.renderValueSelectorGroup('to', toPlaceholder)}
      </div>
    );
  };

  render() {
    return this.props.renderContent(this.renderCriteriaDropdownContent);
  }
}
