import React from 'react';

import RetinaImage from 'v-c/RetinaImage';
import BetaImage from '../ADSync/images/beta.png';

import '../ADSync/ADSync.scss';

export default function Beta() {
  return (
    <data className="beta-image">
      <RetinaImage image={BetaImage} />
    </data>
  );
}
