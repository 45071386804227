import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import withMessages from 'component-utilities/intl';

import { trim, filter, debounce } from 'lodash';

import {
  FormContainer,
  FormLabel,
  FormItemSmall,
  FormItemLarge,
} from 'v-c/Forms';
import InputField from 'v-c/Forms/InputFields/InputField';
import Textarea from 'v-c/Forms/Textarea/Textarea';

import { PrimaryButton, SecondaryButton } from 'v-c/Buttons/Components';
import { SpacerXSmall } from 'v-c/Buttons/Spacer';

import { GENERAL_CONFIGS } from '../../../config/configs';
import { jsUi } from '../../../global/lib/jsUi';
import styles from './styles.scss';

const BASE_KEY = 'app.search_list';

type Props = {
  name: string,
  content: any,
  loadSearchLists: Function,
  currentLists: any[],
  onSave: Function,
  children: any,
  getMessage: Function
}

function SearchListFormComponent(props: Props) {
  const [name, setName] = useState(props.name);
  const [content, setContent] = useState(props.content);
  const [error, setError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const debounceLoadSearchLists = useCallback(
    debounce(props.loadSearchLists, GENERAL_CONFIGS.GET_SEARCH_LISTS_DELAY, { leading: true, trailing: false }),
    [],
  );
  useEffect(() => {
    if (trim(name) === props.name && content === props.content) {
      setIsDisabled(true);
    } else {
      setIsDisabled(trim(name).length === 0 || trim(content).length === 0 || error);
    }
  }, [name, content]);

  const onListNameChange = (e: any) => {
    debounceLoadSearchLists();
    setName(e.target.value);
    setError(
      props.currentLists.some(({ name: listName }) => {
        if (listName !== props.name) {
          return listName === trim(e.target.value);
        }
        return false;
      }),
    );
  };

  const onContentChange = (e: any) => {
    setContent(e.target.value);
  };

  const onSave = () => {
    // trim to remove whitespace & filter removes empty strings
    props.onSave({
      name,
      items: filter(
        content
          .replace(/"/g, '\\"')
          .replace(/\n/g, ' ')
          .replace(/\s\s+/, ' ')
          .split(',')
          .map((item: any) => item.trim()),
      ),
    });
  };

  const textHeightGrowth = () => {
    const scrollHeight = $('#textarea').get(0).scrollHeight;
    $('#textarea').css('height', scrollHeight, 'px');
  };
  const { children, getMessage } = props;

  return (
    <div className={styles['search-lists-container']}>
      <FormContainer>
        <FormItemLarge>
          <FormLabel required>
            {getMessage(`${BASE_KEY}.list_name`)}
          </FormLabel>
          <div className={styles['field-width']}>
            <InputField
              data-field="list-name"
              onChange={onListNameChange}
              value={name}
              status={error ? 'error' : undefined}
              focused
            />
            {error && (
              <span className={styles.error}>{getMessage(`${BASE_KEY}.error`)}</span>
            )}
          </div>
        </FormItemLarge>
        <FormItemLarge>
          <FormLabel required>{getMessage(`${BASE_KEY}.content`)}</FormLabel>
          <Textarea
            data-field="content"
            rows={6}
            placeholder={getMessage(`${BASE_KEY}.content_placeholder`)}
            onChange={onContentChange}
            onFocus={textHeightGrowth}
            focused={false}
            value={content.replace(/\\"/g, '"')}
          />
        </FormItemLarge>
        <FormItemSmall>
          <PrimaryButton
            disabled={isDisabled}
            data-action="save"
            onClick={onSave}
          >
            {getMessage('app.button.save')}
          </PrimaryButton>
          <SpacerXSmall />
          <SecondaryButton
            onClick={jsUi.settingsSearchListsIndex.goTo}
            data-action="cancel"
          >
            {getMessage('app.button.cancel')}
          </SecondaryButton>
        </FormItemSmall>
        {children}
      </FormContainer>
    </div>
  );
}

SearchListFormComponent.propTypes = {
  getMessage: PropTypes.func.isRequired,
  children: PropTypes.node,
  content: PropTypes.string,
  currentLists: PropTypes.array.isRequired,
  name: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  loadSearchLists: PropTypes.func.isRequired,
};

SearchListFormComponent.defaultProps = {
  content: '',
  name: '',
  children: null,
};

const SearchListForm = withMessages(SearchListFormComponent);

export default SearchListForm;
