import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isEmpty } from 'lodash';
import ActionDeleteList from 'v-c/Buttons/ActionButtons/ActionDeleteList';
import {
  FormLabel,
} from 'v-c/Forms';
import MetadataTable from 'v-c/Footers/MetadataTable';
import Divider from 'v-c/Widgets/Divider';
import withMessages from 'component-utilities/intl';

import Layout from '../../global/components/Layout';
import Loading from '../../global/components/Loading';
import HeadlineLabel from '../../global/components/HeadlineLabel';
import Breadcrumb from '../../global/components/Breadcrumb';
import ListSearchTableView from './SearchLists/ListSearchTableView';
import {
  loadSearchList,
  loadSearchLists,
  updateSearchList,
  deleteSearchList,
  sortListSearchColumn,
} from '../../global/state_managers/SearchListsStateManager';
import {
  resetFlash,
} from '../../global/state_managers/FlashMessagesStateManager';
import { showDeleteListDialog, showCannotDeleteListDialog } from '../../global/state_managers/DialogStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';
import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';

import validateSettingsAccess from './components/validateSettingsAccess';

import SearchListForm from './SearchLists/SearchListForm';

import styles from './SearchLists/styles.scss';
import { SearchList } from 'global/types';

const mapStateToProps = ({ searchLists }: any) => ({
  searchList: searchLists.searchList,
  isSearchListLoading: searchLists.isSingleListLoading,
  isSearchListDeleting: searchLists.isDeleting,
  searchLists: searchLists.searchLists,
});

const mapActionsToProps = {
  loadSearchList,
  loadSearchLists,
  updateSearchList,
  setBreadcrumbForSearchListsEditListPage:
    breadcrumbsActions.settingsSearchListsEditListPage,
  showDeleteListDialog,
  showCannotDeleteListDialog,
  deleteSearchList,
  sortListSearchColumn,
  resetFlash,
};

type Props = {
  resetFlash: Function,
  loadSearchList: Function,
  setBreadcrumbForSearchListsEditListPage: Function,
  routeParams: {
    id: string
  },
  searchList: SearchList,
  searchLists: SearchList[]
  showDeleteListDialog: Function,
  updateSearchList: Function,
  deleteSearchList: Function,
  showCannotDeleteListDialog: Function,
  getMessage: Function,
  isSearchListLoading: Function,
  isSearchListDeleting: Function,
  sortListSearchColumn: Function,
  loadSearchLists: Function
}

function EditSearchListComponent(props: Props) {
  useEffect(() => {
    props.loadSearchList(props.routeParams.id);
    props.setBreadcrumbForSearchListsEditListPage();

    return () => {
      props.resetFlash({ viewId: PAGES.EDIT_LIST });
    };
  }, []);

  const onSave = useCallback((id: string) => (data: any) => props.updateSearchList(id, data), [props.updateSearchList]);

  const deleteClicked = () => {
    const { searchList } = props;
    if (searchList.searches.length === 0) {
      props.showDeleteListDialog(
        {
          confirm: () => {
            props.deleteSearchList(searchList.id);
          },
        },
        {
          deleteName: searchList.name,
          content: 'CurrentSavedList',
        },
        true,
      );
      return;
    }
    props.showCannotDeleteListDialog(
      {
        confirm: () => {},
      },
      {
        length: searchList.searches.length,
        content: 'CurrentSavedList',
      },
      true,
    );
  };

  const {
    getMessage,
    isSearchListLoading,
    isSearchListDeleting,
    searchList,
    searchLists,
  } = props;
  let length = 0;
  if (searchList.searches) {
    length = searchList.searches.length; // first time render runs searchList is an empty object {}
  }

  const metadataItem = {
    createdBy: searchList.createdUser,
    createdAt: searchList.createdTime,
    updatedBy: searchList.lastModifiedUser,
    updatedAt: searchList.lastModifiedTime,
  };
  const metadataFields = Object.keys(metadataItem);

  return (
    <Layout section="settings search-lists edit">
      {(isSearchListLoading || isSearchListDeleting) && <Loading />}
      {!isSearchListLoading &&
        !isSearchListDeleting &&
        !isEmpty(searchList) && (
          <div>
            <header>
              <div className={styles['header-rows']}>
                <FlashMessages />
                <Breadcrumb />
                <HeadlineLabel text={searchList.name} />
              </div>
            </header>
            <div className={styles['actions-inner']}>
              <ActionDeleteList
                status=""
                onClick={deleteClicked}
              />
            </div>

            <SearchListForm
              name={searchList.name}
              content={searchList.items.join(', ')}
              onSave={onSave(props.routeParams.id)}
              currentLists={searchLists}
              loadSearchLists={props.loadSearchLists}
            >
              <Divider />
              <div className={styles['metadata-container']}>
                <MetadataTable
                  item={metadataItem}
                  fields={metadataFields}
                  baseKey="app.search_list.meta_data"
                  getMessage={getMessage}
                />
              </div>
              <Divider />
              <div className={styles['activity-container']}>
                <FormLabel>{getMessage('app.settings.search_lists.searches_header')}</FormLabel>
                {!length && getMessage('app.settings.search_lists.contains_no_searches')}
                {!!length && getMessage('app.settings.search_lists.contains_searches', {
                  length,
                })}
                {!!length && <div className={styles['list-searches-container']}>
                  <ListSearchTableView
                    sortListSearchColumn={props.sortListSearchColumn}
                    searchList={searchList}
                    className={styles['activity-container']}
                    getMessage={getMessage}
                  />
                </div>}
              </div>
            </SearchListForm>
          </div>
        )}
    </Layout>
  );
}

EditSearchListComponent.propTypes = {
  routeParams: PropTypes.object.isRequired,
  searchLists: PropTypes.array.isRequired,
  getMessage: PropTypes.func.isRequired,
  isSearchListLoading: PropTypes.bool.isRequired,
  isSearchListDeleting: PropTypes.bool.isRequired,
  searchList: PropTypes.object.isRequired,
  loadSearchList: PropTypes.func.isRequired,
  loadSearchLists: PropTypes.func.isRequired,
  updateSearchList: PropTypes.func.isRequired,
  setBreadcrumbForSearchListsEditListPage: PropTypes.func.isRequired,
  showDeleteListDialog: PropTypes.func.isRequired,
  showCannotDeleteListDialog: PropTypes.func.isRequired,
  deleteSearchList: PropTypes.func.isRequired,
  sortListSearchColumn: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  resetFlash: PropTypes.func.isRequired,
};

const withMessagesComponent = withMessages(EditSearchListComponent);
const connectedComponent = connect(
  mapStateToProps,
  mapActionsToProps,
)(withMessagesComponent);
const flashConnectedComponent = flashConnect(
  PAGES.EDIT_LIST,
  'Settings/EditSearchLists',
)(connectedComponent);
const EditSearchList = validateSettingsAccess('settings.search_lists')(flashConnectedComponent);

export default EditSearchList;
