/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import withMessages from 'component-utilities/intl';

import DropdownFilter from 'v-c/Dropdowns/Filter/DropdownFilter';

import reduxStore from '../../reduxStore';
import Processing from '../Processing';
import toDSL from '../../lib/convertSimpleSearchIntoSearchDSLStructure';
import generateFilteredQuery, {
  QUERY_TYPES,
} from '../../lib/generateFilteredQuery';
import {
  openSavedSearchStream,
  openHoldReasonAssetsStream,
  openUnsavedSearchStream,
} from '../../lib/searchResultsStreamManager';

import contextTreeHelper from '../../lib/contextTreeHelper';

import { updateFilter } from '../../state_managers/SearchFormStateManager';
import { loadLegalHoldReasons } from '../../state_managers/LegalHoldReasonsStateManager';
import { runFilteredUnsavedSearch, runFixedFilterPersonalSearch } from '../../state_managers/UnsavedSearchStateManager';

import actionsBase, {
  WithinActionsBasePanel as withinPanel,
} from './ActionsBase';

import stateLogic from './FilterDeconstructed';

import FilterDarkGrayIcon from './images/filter-gray.png';
import { Fields, HoldReason, ReduxState, SearchResults } from 'global/types';

const TYPE = 'filter';
const CLOSED_STATE_IMAGE = FilterDarkGrayIcon;
const OPENED_STATE_IMAGE = FilterDarkGrayIcon;

const mapStateToProps = ({ searchForm, legalHoldReasons, unsavedSearch }: ReduxState) => ({
  searchForm,
  reasons: legalHoldReasons.originalReasons,
  unsavedSearch,
});

const mapActionsToProps = {
  updateFilter,
  loadLegalHoldReasons,
  runFilteredUnsavedSearch,
  runFixedFilterPersonalSearch,
};

type Props = {
  searchResults: SearchResults,
  hiddenFilterCriteria: {}[],
  getMessage: Function,
  fields: Fields,
  onFilter: Function,
  onCancel: Function,
  onClearAll: Function,
  holdReasons: HoldReason[],
  fixedFormValues: {},
}

@withMessages
@contextTreeHelper
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@actionsBase({
  type: TYPE,
  closedStateImage: CLOSED_STATE_IMAGE,
  openedStateImage: OPENED_STATE_IMAGE,
  actionCount: () => {
  const {
  data: { filteredFormValues },
  } = reduxStore.getState().unsavedSearch;
  return Object.keys(filteredFormValues).length;
  },
  })
@reduxForm(
  {
  form: 'search-filter',
  fields: ['filter'],
  enableReinitialize: true,
  },
  ({ searchForm }) => ({
    initialValues: { filter: searchForm.filter },
    }),
)
@withinPanel
@stateLogic({
  generateFilteredQuery,
  toDSL,
  QUERY_TYPES,
  openSavedSearchStream,
  openUnsavedSearchStream,
  openHoldReasonAssetsStream,
  })
export default class Filter extends Component<Props> {
  static propTypes = {
    searchResults: PropTypes.any.isRequired,
    hiddenFilterCriteria: PropTypes.array,
    getMessage: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    onFilter: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClearAll: PropTypes.func.isRequired,
    holdReasons: PropTypes.array.isRequired,
    fixedFormValues: PropTypes.object.isRequired,
  };

  static defaultProps = {
    hiddenFilterCriteria: [],
  };

  render() {
    const {
      searchResults: { isLoading },
      fields: { filter },
      hiddenFilterCriteria,
      onFilter,
      onCancel,
      onClearAll,
      holdReasons,
      fixedFormValues,
    } = this.props;
    const processingText = `${this.props.getMessage(
      'app.general.processing',
    )}...`;

    return (
      <div className="content-wrapper">
        <Processing text={processingText} isProcessing={isLoading} />
        <DropdownFilter
          formValues={fixedFormValues}
          originalFormValues={fixedFormValues}
          onFormChange={filter.onChange}
          holdReasons={holdReasons}
          onClearAll={onClearAll}
          onCancel={onCancel}
          onSearch={onFilter}
          disabledFields={hiddenFilterCriteria}
        />
      </div>
    );
  }
}
