/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component, ReactNode } from 'react';
import PropTypes from 'prop-types';

import withCSS from 'react-css-modules';
import { TextLabel } from 'v-c/Widgets/Components';
import withMessages, {
  FormattedHTMLMessage,
} from 'view-components/utilities/intl';
import ArrowUpIcon from 'v-c/Icons/ArrowUpIcon';
import ArrowDownIcon from 'v-c/Icons/ArrowDownIcon';
import { MinusRedIcon } from 'v-c/Icons';

import RemoveIcon from './Images/remove.png';

import AddCriteria from './AddCriteria';

import styles from './FormBase.scss';

import { SEARCH } from '../../../../../../config/app.json';

const { PATH_INDEX_SEPARATOR } = SEARCH;

type Props = {
  index: string
  listElem: {
    children: ReactNode
  }
  addListElemOptions: {}
  listElemOptions: {
    addListElem: Function
    deleteListElem: Function
  }
  defaults: {
    listElemTypes: {
      CRITERIA: string
      GROUP: string
    } 
  }
  parentRelation?: string
  children: ReactNode,
  getMessage: Function
  isViewOnly: boolean
  changeParentRelation: Function
}

@withMessages
@withCSS(styles)
export default class GroupView extends Component<Props> {
  static propTypes = {
    index: PropTypes.string.isRequired,
    listElem: PropTypes.object.isRequired,
    addListElemOptions: PropTypes.object.isRequired,
    listElemOptions: PropTypes.object.isRequired,
    defaults: PropTypes.object.isRequired,
    parentRelation: PropTypes.string,
    children: PropTypes.node.isRequired,
    getMessage: PropTypes.func.isRequired,
    isViewOnly: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    parentRelation: '',
  };

  constructor(props: Props) {
    super(props);

    this.state = { isOpenedGroup: true };
  }

  onAddListElem = (widgetId: string, value: string) => {
    const { index, listElemOptions: { addListElem } } = this.props;

    addListElem(index, value);
  };

  deleteListElem = () => {
    const { index, listElemOptions: { deleteListElem } } = this.props;

    deleteListElem(index);
  };

  toggleGroup = () => {
    const { isOpenedGroup } = this.state;

    if (isOpenedGroup) {
      const {
        listElem,
        defaults: { listElemTypes: { CRITERIA, GROUP } },
      } = this.props;
      const countGroupsAndCriterias = ({ children }: any) => {
        Object.keys(children).forEach((childKey) => {
          const child = children[childKey];
          switch (child.type) {
            case CRITERIA: {
              this.nestedCriteria += 1;
              break;
            }
            case GROUP: {
              this.nestedGroups += 1;
              countGroupsAndCriterias(child);
              break;
            }
            default:
              this.nestedCriteria += 1;
          }
        });
      };
      this.nestedGroups = 0;
      this.nestedCriteria = 0;

      countGroupsAndCriterias(listElem);
    }

    this.setState({ isOpenedGroup: !isOpenedGroup });
  };

  renderCollapsedGroupText() {
    return (
      <div styleName={'collapsed-group-inner'} data-type="group-inner">
        <TextLabel styleName="collapsed-group-text">
          <FormattedHTMLMessage
            id="app.new_search_form.collapsed_group"
            values={{
              groupsCount: this.nestedGroups.toString(),
              criteriaCount: this.nestedCriteria.toString(),
            }}
          />
        </TextLabel>
      </div>
    );
  }

  render() {
    const {
      index,
      parentRelation,
      listElem: { children },
      addListElemOptions,
      listElemOptions,
      isViewOnly,
    } = this.props;
    const isRootGroup = index === '0';
    const isFirstElemInGroup =
      parseInt(index.split(PATH_INDEX_SEPARATOR).pop(), 10) === 0;
    const { isOpenedGroup } = this.state;
    const styleNamePrefix = isRootGroup ? 'root' : 'nested';
    const wrapperStyleName = isRootGroup
      ? styleNamePrefix
      : `${isFirstElemInGroup ? 'first' : 'sibling'}-${styleNamePrefix}`;
    const renderGreenText = (value: string) =>
      this.props.getMessage(`app.criterias.${value}`).toLowerCase();
    const addListWrapper = {};

    if (Object.keys(children).length) {
      addListWrapper.styleName = 'group-with-children';
    }

    return (
      <div
        styleName={`${wrapperStyleName}-group-wrapper`}
        className="advanced-list-elem"
        data-list-item-index={index}
      >
        {!isRootGroup &&
          !isViewOnly && (
            <div
              onClick={this.deleteListElem}
              data-action={`remove-${index}`}
              data-type="remove"
              image={RemoveIcon}
              styleName="delete-icon"
              role="button"
              tabIndex="-1"
            >
              <MinusRedIcon />
            </div>
          )}

        {!isFirstElemInGroup && (
          <TextLabel
            styleName={`parent-relation-label${
              this.props.isViewOnly ? '-disabled' : ''
            }`}
            className="parent-relation-label"
            onClick={listElemOptions.changeParentRelation(index)}
          >
            {renderGreenText(parentRelation)}
          </TextLabel>
        )}

        {isOpenedGroup && (
          <div
            styleName={`${styleNamePrefix}-group-wrapper-inner`}
            data-type="group-inner"
          >
            {this.props.children}
            {!isViewOnly && (
              <div {...addListWrapper}>
                <AddCriteria
                  {...addListElemOptions}
                  {...listElemOptions}
                  index={index}
                  addCriteria={this.onAddListElem}
                />
              </div>
            )}
          </div>
        )}
        {!isOpenedGroup && this.renderCollapsedGroupText()}
        {!isRootGroup &&
          isOpenedGroup && (
            <ArrowUpIcon
              data-action={`close-${index}`}
              onClick={this.toggleGroup}
              styleName="arrow-icon"
            />
          )}
        {!isRootGroup &&
          !isOpenedGroup && (
            <ArrowDownIcon
              data-action={`open-${index}`}
              onClick={this.toggleGroup}
              styleName="arrow-icon"
            />
          )}
      </div>
    );
  }
}
