import reduxStore from '../reduxStore';

import { openSearchResultsStream } from '../state_managers/SearchResults/searchResultsStream';
import { performLoadMoreResults as performLoadMoreResultsAction } from '../state_managers/SearchResults/loadMoreResults';
import { sortTable } from '../state_managers/SearchResults/tableConfig';
import { searchResultsState } from '../state_managers/SearchResultsStateManager';

import { QUERY_TYPES } from './generateFilteredQuery';
import { ReduxState } from 'global/types';

const DEFAULT_PARAMS = {
  limit: 50,
  offset: 0,
  fields: [],
};

export { DEFAULT_PARAMS };

export function reviewAttributeName(searchId: string) {
  return `review-${searchId}`;
}

export function collectSavedSearchStreamParams({
  id,
  fields,
  filteredQuery,
  isSavedSearch,
  type,
  personal,
}: any) {
  const {
    unsavedSearch: {
      data: { filteredFormValues, query },
    },
  } = reduxStore.getState() as ReduxState;
  const searchResultsStore = searchResultsState();
  const searchIdTableConfig = searchResultsStore.tableConfigs[id];
  let sortOptions = searchResultsStore.sortOptions;

  if (searchIdTableConfig) {
    sortOptions = searchIdTableConfig.sortOptions;
  }

  let streamParams = {
    ...DEFAULT_PARAMS,
    sort: sortOptions.sortKey,
    'sort-order': sortOptions.sortOrder,
    fields,
    type,
  };

  if (!filteredQuery && Object.keys(filteredFormValues).length) {
    streamParams = {
      ...streamParams,
      query,
    };
  } else if (!filteredQuery && isSavedSearch) {
    streamParams['query-id'] = id;
  } else {
    streamParams = {
      ...streamParams,
      query: filteredQuery || '',
    };
  }

  return { ...streamParams, personal };
}

export function collectUnsavedSearchStreamParams({ query, fields, type }: any) {
  const {
    sortOptions: { sortKey, sortOrder },
  } = searchResultsState();
  const { unsavedSearch } = reduxStore.getState() as ReduxState;
  const isPersonalSearch = type === QUERY_TYPES.MY_ARCHIVE;

  return {
    ...DEFAULT_PARAMS,
    query: query || unsavedSearch.data.query,
    fields: unsavedSearch.data.fields || fields,
    sort: sortKey,
    'sort-order': sortOrder,
    type,
    personal: isPersonalSearch || unsavedSearch.data.clientData.personal,
  };
}

export function collectMoreResultsStreamParams({ min, max, fields }: any) {
  return {
    offset: min,
    limit: max - min,
    fields,
  };
}

export function openSavedSearchStream(searchId: string, filteredQuery: {}, personal = false, fixedFilter: {}) {
  const streamParams = collectSavedSearchStreamParams({
    id: searchId,
    fields: [],
    filteredQuery,
    isSavedSearch: true,
    type: QUERY_TYPES.SAVED_SEARCH,
    personal,
  });

  reduxStore.dispatch(
    openSearchResultsStream({
      stream_params: streamParams,
      search_id: searchId,
      fixed_filter: fixedFilter,
    }),
  );
}

export function openHoldReasonAssetsStream(reasonId: string, filteredQuery: {}) {
  const streamParams = collectSavedSearchStreamParams({
    id: reasonId,
    fields: [],
    filteredQuery,
    isSavedSearch: false,
    type: QUERY_TYPES.HOLD_ASSETS,
  });

  reduxStore.dispatch(
    openSearchResultsStream({
      stream_params: {
        ...streamParams,
        reason_id: reasonId,
      },
    }),
  );
}

export function openUnsavedSearchStream({ query, type, fixedFilter }: any = {}) {
  const streamParams = collectUnsavedSearchStreamParams({
    query,
    type,
    fields: [],
  });

  if (
    streamParams.query.length ||
    streamParams.type === QUERY_TYPES.MY_ARCHIVE
  ) {
    reduxStore.dispatch(
      openSearchResultsStream({
        stream_params: streamParams,
        fixed_filter: fixedFilter,
      }),
    );
  }
}

export function performLoadMoreResults(min: number, max: number) {
  return function* doPerformLoadMoreResults() {
    const { activeSearchId, stream } = searchResultsState();

    if (activeSearchId && stream.id) {
      try {
        const streamParams = collectMoreResultsStreamParams({
          min,
          max,
          fields: [],
        });

        yield reduxStore.dispatch(
          performLoadMoreResultsAction(
            { stream_params: streamParams, search_id: activeSearchId },
            stream.id,
          ),
        );
      } catch (e) {
        console.error('Unable to load more results', e);
      }
    } else {
      console.error('performLoadMoreResults - Cannot find opened stream');
    }
  };
}

export function sortStream(sortKey: string, sortOrder: string) {
  const { stream } = searchResultsState();

  if (stream.id) {
    reduxStore.dispatch(
      sortTable(stream.id, {
        sort: sortKey,
        'sort-order': sortOrder,
      }),
    );
  } else {
    console.error('sortStream - Cannot find opened stream');
  }
}
