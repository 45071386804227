/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withCSS from 'react-css-modules/dist/linkClass';
import { contains } from 'lodash';

import withMessages, { intlPropTypes } from 'view-components/utilities/intl';
import {
  TextLabel,
  CustomCheckbox,
  SearchField,
} from 'view-components/components/Widgets/Components';
import LoadingGrayIcon from 'view-components/components/Icons/LoadingGrayIcon';
import toggleInArray from 'component-lib/toggleInArray';

import styles from './CriteriaMultiSelectView.scss';
import { intlShape, Search } from 'global/types';

const SELECT_ALL_ID = 'all';

interface Props extends intlShape {
  onChange: Function
  onAnyChange: Function
  list: any[]
  selectedValues: string[]
  widgetId: string
  isSeparatedAnyLogic: boolean
  isAnySelected: boolean
  isViewOnly: boolean
  selectAllOption: boolean
}

type State = {
  hoveredId: null | string,
  isSearchOpened: boolean
  search: string
}

@withMessages
export default class CriteriaMultiSelectView extends Component<Props, State> {
  static propTypes = {
    ...intlPropTypes,
    widgetId: PropTypes.string,
    list: PropTypes.array,
    selectedValues: PropTypes.array,
    isSeparatedAnyLogic: PropTypes.bool,
    isAnySelected: PropTypes.bool,
    onChange: PropTypes.func,
    onAnyChange: PropTypes.func,
    selectAllOption: PropTypes.bool,
    isViewOnly: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    widgetId: '',
    list: [],
    isSeparatedAnyLogic: false,
    isAnySelected: false,
    selectedValues: [],
    onChange: () => {},
    onAnyChange: () => {},
    selectAllOption: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      hoveredId: null,
      isSearchOpened: false,
      search: '',
    };
  }

  onBlurList = () => {
    this.setState({ hoveredId: null });
  }

  onRowHovered = (hoveredId: string) =>
    () => this.setState({ hoveredId })

  onSearch = (search: string) => {
    this.setState({ search });
  }

  onItemClick(id: string, newValue: any) {
    return () => {
      const {
        onChange,
        onAnyChange,
        list,
        selectedValues,
        widgetId,
        isSeparatedAnyLogic,
      } = this.props;
      let newSelectedValues;
      let alreadyChanged = false;

      if (id === SELECT_ALL_ID) {
        if (isSeparatedAnyLogic) {
          onAnyChange();
          alreadyChanged = true;
        } else {
          newSelectedValues =
            newValue
              ? list.map(({ id: itemId }: any) => itemId)
              : [];
        }
      } else {
        newSelectedValues = toggleInArray(selectedValues, id);
      }

      if (!alreadyChanged) onChange(widgetId, newSelectedValues);
    };
  }

  renderSelectionRow(values: any) {
    const { list: { id, value, additionalValue }, idx } = values;
    const { hoveredId } = this.state;
    const { selectedValues, list, isAnySelected, isSeparatedAnyLogic, isViewOnly } = this.props;
    const isAllId = id === SELECT_ALL_ID;
    const isHovered = id === hoveredId;
    const selectedValuesCheck =
      !isSeparatedAnyLogic && list.length && selectedValues.length === list.length;
    const isChecked =
      isAllId
        ? !!(selectedValuesCheck || isAnySelected)
        : contains(selectedValues, id);
    const labelStyle = `value-label${isAllId ? '-all' : ''}`;

    return (
      <div
        styleName="selection-row"
        data-field={`selection-row-${id}`}
        onClick={!isViewOnly && this.onItemClick(id, !isChecked)}
        onMouseOver={this.onRowHovered(id)}
        key={idx}
        role="button"
        tabIndex="-1"
      >
        <CustomCheckbox
          styleName="custom-checkbox"
          isChecked={isChecked}
          forceHover={isHovered}
        />

        <TextLabel styleName={labelStyle}>
          { value }
          {
            additionalValue &&
            <data className={styles['additional-value']}>
              { additionalValue }
            </data>
          }
        </TextLabel>
      </div>
    );
  }

  renderList() {
    const { search } = this.state;
    const { getMessage, list, selectAllOption } = this.props;
    const filter = ({ id, value }: any) => {
      if (id === SELECT_ALL_ID && search.length) return false;
      return contains(value.toUpperCase(), search.toUpperCase());
    };

    let listToRender = [...list];

    if (selectAllOption) {
      listToRender = [
        {
          id: SELECT_ALL_ID,
          value: getMessage(`app.criterias.${SELECT_ALL_ID}`),
        },
        ...list,
      ];
    }

    return (
      <div>
        {
          list.length === 0 &&
          <LoadingGrayIcon styleName="loading-indicator" />
        }
        {
          list.length > 0 &&
          listToRender.filter(filter).map((actualList, idx) => (
            this.renderSelectionRow({ list: actualList, idx })
          ))
        }
      </div>
    );
  }

  render() {
    const { getMessage, selectedValues } = this.props;
    const { search } = this.state;
    const selectedText = getMessage('app.criterias.selected');
    const searchPlaceholder = getMessage('app.criterias.start_typing');
    const selectedNumber = selectedValues.length;

    return withCSS(
      <div styleName="criteria-multiselect-view">
        <div styleName="header-wrapper">
          <TextLabel
            styleName="selected-text"
            data-selected={selectedNumber}
          >
            {`${selectedText} (${selectedNumber})`}
          </TextLabel>
          <SearchField
            placeholder={searchPlaceholder}
            value={search}
            onChange={this.onSearch}
          />
        </div>
        <div
          styleName="content-wrapper"
          onMouseOut={this.onBlurList}
        >
          {this.renderList()}
        </div>
      </div>,
      styles,
    );
  }
}
