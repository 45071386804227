import { isString } from 'lodash';

import { SortCell } from 'v-c/TableView';

import naturalCmp from '../../lib/NaturalCmp';

const { sortKeys } = SortCell;

export default function simpleSort(list: any[], sortKey: string, sortOrder: string) {
  return list.sort((a: any, b: any) => {
    let a2 = a[sortKey];
    let b2 = b[sortKey];

    if (isString(a2) && isString(b2)) {
      a2 = a2.toLowerCase();
      b2 = b2.toLowerCase();
    }

    switch (sortOrder) {
      case sortKeys.ASC:
        return naturalCmp(a2, b2);
      case sortKeys.DESC:
        return naturalCmp(b2, a2);
      default:
        return null;
    }
  });
}