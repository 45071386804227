/* eslint-disable filenames/match-regex */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';
import IfFeature from '../../global/components/IfFeature';

import { DASHBOARD } from '../../global/constants/Pages';
import { Account } from 'global/types';
import ifFeature from "../../global/components/IfFeature";
import moment from 'moment';
/* eslint-enable filenames/match-regex */

type Props = {
  account: Account
  getStatus: Function
  loadAccountSettings: Function
  loadRetentionPolicy: Function
  clearAccountSettings: Function
  resetFlash: Function
  showGeneralFlash: Function
  minimalIndexWarning: boolean
  registerUnmountCleanupMethod: Function
  setBreadcrumbForAccountSummaryPage: Function}

export function convertDate(date: string) {
  if (date) {
    const dateParts = date.split('/');

    return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
  }

  return date;
}

export function stateLogic({
  DATE_FORMATS,
  reduxStore,
  isPageRoleEnabled,
  pageRoles,
}: any) {
  return (component: any) =>
    class AccountSummaryStateLogic extends Component<Props> {
      static propTypes = {
        account: PropTypes.shape({
          summary: PropTypes.shape({
            collectionHistory: PropTypes.object,
          }),
          ctime: PropTypes.string,
        }).isRequired,
        getStatus: PropTypes.func.isRequired,
        loadAccountSettings: PropTypes.func.isRequired,
        loadRetentionPolicy: PropTypes.func.isRequired,
        clearAccountSettings: PropTypes.func.isRequired,
        resetFlash: PropTypes.func.isRequired,
        showGeneralFlash: PropTypes.func.isRequired,
        minimalIndexWarning: PropTypes.bool,
        registerUnmountCleanupMethod: PropTypes.func.isRequired,
        setBreadcrumbForAccountSummaryPage: PropTypes.func.isRequired,
      };

      static defaultProps = {
        minimalIndexWarning: false,
      };

      constructor(props: any) {
        super(props);

        props.setBreadcrumbForAccountSummaryPage();

        props.registerUnmountCleanupMethod(() => {
          props.clearAccountSettings();
          reduxStore.dispatch(props.resetFlash({ from: 'AccountSummaryDeconstructed' }));
        });
      }

      componentDidMount() {
        this.props.loadAccountSettings();
        this.props.loadRetentionPolicy();
        if (isPageRoleEnabled(pageRoles.USER_CAN_USE_AD_SYNC)) {
          this.props.getStatus();
        }
        if(!IfFeature.isEnabled('no_indexing')) {
          reduxStore.dispatch(this.props.showGeneralFlash('indexing_backlog', DASHBOARD));
        }
        if(!ifFeature.isEnabled('asset_hold_tag')) {
          reduxStore.dispatch(this.props.showGeneralFlash('blue_green_migration_banner', DASHBOARD));
        }
      }

      componentDidUpdate() {
        const { minimalIndexWarning, showGeneralFlash } = this.props;

        if (minimalIndexWarning && IfFeature.isEnabled('minimal_index')) {
          reduxStore.dispatch(showGeneralFlash('initial_index_completion', DASHBOARD));
        }

      }

      isCollectedNormally() {
        return isNumber(
          this.props.account.summary.collectionHistory.last24Hours,
        );
      }

      lastCollectedDate() {
        const {
          account: {
            ctime,
            summary: { collectionHistory },
          },
        } = this.props;

        if (!!!collectionHistory || !!!collectionHistory.lastHistory) {
          return '';
        }

        const lastCollectedDate =
          convertDate(collectionHistory?.lastHistory?.date) || ctime;

        return moment.utc(lastCollectedDate).format(DATE_FORMATS.LONG);
      }

      render() {
        return React.createElement(component, {
          ...this.props,
          isCollectedNormally: this.isCollectedNormally(),
          lastCollectedDate: this.lastCollectedDate(),
        });
      }
    };
}
