import localStore from 'local-store';

const appLocalStore = localStore();

export const STORAGE_KEYS = {
  LOGGED_IN_AS: 'loggedInAs',
  ACCOINT_ID: 'accountId',
};

export function resetLocalStorage() {
  appLocalStore.delete(STORAGE_KEYS.LOGGED_IN_AS);
  appLocalStore.delete(STORAGE_KEYS.ACCOINT_ID);
}

export function getLocalValue(prop: any) {
  let result;

  try {
    result = appLocalStore.get(prop);
  } catch (e) {
    result = null;
  }

  return result;
}

export function setLocalValue(prop: any, value: any) {
  try {
    appLocalStore.set(prop, value);
  } catch (e) {
    console.warn(e);
  }
}

export function getLoggedInAs() {
  return getLocalValue(STORAGE_KEYS.LOGGED_IN_AS);
}

export function setLoggedInAs(value: any) {
  return setLocalValue(STORAGE_KEYS.LOGGED_IN_AS, value);
}
