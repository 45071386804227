/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { camelCase, isNumber } from 'lodash';

import withMessages, { FormattedHTMLMessage } from 'component-utilities/intl';
import {
  FormContainer,
  FormLabel,
  FormItemSmall,
  FormItemLarge,
} from 'v-c/Forms';
import DropdownMedium from 'v-c/Forms/Dropdowns/DropdownMedium';
import { PrimaryButton, SecondaryButton } from 'v-c/Buttons/Components';
import { SpacerXSmall } from 'v-c/Buttons/Spacer';

import { jsUi } from '../../global/lib/jsUi';

import Loading from '../../global/components/Loading';
import Layout from '../../global/components/Layout';
import Breadcrumb from '../../global/components/Breadcrumb';
import HeadlineLabel from '../../global/components/HeadlineLabel';

import { SECURITY_OPTIONS } from '../../config/configs';
import validateSettingsAccess from './components/validateSettingsAccess';

import {
  loadLoginAttemptRestriction,
  createLoginAttemptRestriction,
} from '../../global/state_managers/LoginAttemptRestrictionStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import styles from './components/Settings.scss';

const { FAILED_LOGIN_ATTEMPTS, LOGIN_LOCK_DURATIONS } = SECURITY_OPTIONS;
const BASE_KEY = 'app.settings.security';

function lockDurationMinutesOptions() {
  // where `0` represents indefinite to correspond with Otto
  return LOGIN_LOCK_DURATIONS.map(duration => ({
    id: String(duration),
    value: (
      <FormattedHTMLMessage
        id={`${BASE_KEY}.options.lock_duration`}
        values={{ duration }}
      />
    ),
  }));
}

const mapStateToProps = ({ loginAttemptRestriction }: any) => ({
  isLoading: loginAttemptRestriction.isLoading,
  loginAttemptRestriction: loginAttemptRestriction.data,
});

const mapActionsToProps = {
  loadLoginAttemptRestriction,
  createLoginAttemptRestriction,
  setBreadcrumbForSettingsSecurityPage: breadcrumbsActions.settingsSecurityPage,
};

type State = {
  lockDurationMinutes: string,
  failedAttemptCount: string,
  isInitialDataSet: boolean
}

type Props = {
  isLoading: boolean,
  loginAttemptRestriction: {
    enabled: boolean,
    failedAttemptCount: number,
    lockDurationMinutes: number
  },
  setBreadcrumbForSettingsSecurityPage: Function,
  loadLoginAttemptRestriction: Function,
  createLoginAttemptRestriction: Function,
  getMessage: Function,
}

@withMessages
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@validateSettingsAccess('settings.security')
export default class Security extends Component<Props, State> {
  static propTypes = {
    getMessage: PropTypes.func.isRequired,
    loginAttemptRestriction: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loadLoginAttemptRestriction: PropTypes.func.isRequired,
    createLoginAttemptRestriction: PropTypes.func.isRequired,
    setBreadcrumbForSettingsSecurityPage: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { isLoading, loginAttemptRestriction } = nextProps;

    if (
      !isLoading &&
      !prevState.isInitialDataSet &&
      loginAttemptRestriction.enabled
    ) {
      return {
        isInitialDataSet: true,
        failedAttemptCount: String(loginAttemptRestriction.failedAttemptCount),
        lockDurationMinutes: String(
          loginAttemptRestriction.lockDurationMinutes,
        ),
      };
    }

    return null;
  }

  constructor(props: Props) {
    super(props);

    props.setBreadcrumbForSettingsSecurityPage();
    props.loadLoginAttemptRestriction();

    this.state = {
      failedAttemptCount: '',
      lockDurationMinutes: '',
      isInitialDataSet: false,
    };
  }

  onSelect = (id: string, componentId: string) => {
    const FAILED_ATTEMPT_DROPDOWN = 'failed-attempt-count';
    const failedAttemptDropdownSelected =
      componentId === FAILED_ATTEMPT_DROPDOWN;

    const state = {
      [camelCase(componentId)]: id,
    };

    if (failedAttemptDropdownSelected && !id) {
      state.lockDurationMinutes = '';
    } else if (failedAttemptDropdownSelected && id) {
      state.lockDurationMinutes = String(LOGIN_LOCK_DURATIONS[0]);
    }

    this.setState(state);
  };

  onSave = () => {
    const { failedAttemptCount, lockDurationMinutes } = this.state;
    const data = {
      enabled: true,
      failedAttemptCount: Number(failedAttemptCount),
      lockDurationMinutes: Number(lockDurationMinutes),
    };

    if (failedAttemptCount === '' && lockDurationMinutes === '') {
      // when enabled is false, other values do not matter
      data.enabled = false;
    }

    this.props.createLoginAttemptRestriction(data);
  };

  get failedAttemptCountOptions() {
    return FAILED_LOGIN_ATTEMPTS.map((attempts) => {
      let value = this.props.getMessage(`${BASE_KEY}.options.off`);
      if (isNumber(attempts)) {
        value = (
          <FormattedHTMLMessage
            id={`${BASE_KEY}.options.failed_attempt`}
            values={{ attempts }}
          />
        );
      }
      return { id: String(attempts), value };
    });
  }

  get isLockDurationMinutesDisabled() {
    if (!this.state.failedAttemptCount) {
      return true;
    }
    return false;
  }

  get isDisabled() {
    const { loginAttemptRestriction: original } = this.props;
    const { failedAttemptCount, lockDurationMinutes } = this.state;

    const emptyFailedAttemptCount = failedAttemptCount === '';
    const emptyLockDurationMinutes = lockDurationMinutes === '';
    const isOriginalAttemptCount =
      failedAttemptCount === String(original.failedAttemptCount);
    const isOriginalLockDuration =
      lockDurationMinutes === String(original.lockDurationMinutes);

    if (isOriginalAttemptCount && isOriginalLockDuration) {
      return true;
    }

    if (emptyFailedAttemptCount && emptyLockDurationMinutes) {
      return !original.enabled;
    }

    return emptyFailedAttemptCount || emptyLockDurationMinutes;
  }

  render() {
    const { getMessage, isLoading } = this.props;

    return (
      <Layout section="settings security">
        {isLoading && <Loading />}
        {!isLoading && (
          <div>
            <header>
              <Breadcrumb />
              <HeadlineLabel text={getMessage('app.breadcrumb.security')} />
            </header>
            <div className={styles['security-container']}>
              <FormContainer>
                <FormItemLarge>
                  <FormLabel>
                    {getMessage(`${BASE_KEY}.label.failed_attempt_count`)}
                  </FormLabel>
                  <DropdownMedium
                    id="failed-attempt-count"
                    onSelect={this.onSelect}
                    data={this.failedAttemptCountOptions}
                    selectedId={this.state.failedAttemptCount}
                  />
                </FormItemLarge>
                <FormItemLarge>
                  <FormLabel>
                    {getMessage(`${BASE_KEY}.label.lock_duration_minutes`)}
                  </FormLabel>
                  <DropdownMedium
                    id="lock-duration-minutes"
                    onSelect={this.onSelect}
                    data={lockDurationMinutesOptions()}
                    selectedId={this.state.lockDurationMinutes}
                    disabled={this.isLockDurationMinutesDisabled}
                  />
                </FormItemLarge>
                <FormItemSmall>
                  <div className={styles['security-buttons']}>
                    <PrimaryButton
                      data-action="save"
                      onClick={this.onSave}
                      disabled={this.isDisabled}
                    >
                      {getMessage('app.button.save')}
                    </PrimaryButton>
                    <SpacerXSmall />
                    <SecondaryButton
                      data-action="cancel"
                      onClick={jsUi.settingsIndex.goTo}
                    >
                      {getMessage('app.button.cancel')}
                    </SecondaryButton>
                  </div>
                </FormItemSmall>
              </FormContainer>
            </div>
          </div>
        )}
      </Layout>
    );
  }
}
