import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import SearchEditingBase from './components/SearchEditingBase';

import { loadSavedSearch } from '../../global/state_managers/CurrentSavedSearchStateManager';
import {
  getUIValue,
  CAME_FROM_SEARCHES_INDEX,
} from '../../global/state_managers/UIHelperStateManager';
import { runModifiedSavedSearch } from '../../global/state_managers/UnsavedSearchStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';
import { Params } from 'global/types';

const mapStateToProps = ({ currentSavedSearch, searchForm }: any) => ({
  searchForm,
  currentSavedSearch,
  clientData: currentSavedSearch.search.clientData,
  searchName: currentSavedSearch.search.name,
  searchId: currentSavedSearch.search.id || '',
});

const mapActionsToProps = {
  loadSavedSearch,
  runModifiedSavedSearch,
  setBreadcrumbForModifiedSearchEditPage:
    breadcrumbsActions.modifiedSearchEditPage,
  setBreadcrumbForModifiedSearchEditPageFromIndex:
    breadcrumbsActions.modifiedSearchEditPageFromIndex,
};

interface Props {
  currentSavedSearch: any,
  searchName: string,
  searchForm: {},
  setBreadcrumbForModifiedSearchEditPage: Function,
  setBreadcrumbForModifiedSearchEditPageFromIndex: Function,
  params: Params,
  searchId: string
}

// TODO: get rid of the inheritance
/* eslint-disable class-methods-use-this */
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class SavedSearchEdit extends SearchEditingBase {
  static propTypes = {
    setBreadcrumbForModifiedSearchEditPage: PropTypes.func.isRequired,
    setBreadcrumbForModifiedSearchEditPageFromIndex: PropTypes.func.isRequired,
  };

  constructor(props: Props, state: any) {
    super(props);

    this.state = state;

    const {
      params: { search_id: paramsSearchID },
      searchId,
    } = props;

    this.isNeedLoadingCheck = !searchId;

    if (!searchId) {
      this.props.loadSavedSearch({ searchId: paramsSearchID });
    }

    if (getUIValue(CAME_FROM_SEARCHES_INDEX)) {
      props.setBreadcrumbForModifiedSearchEditPageFromIndex();
    } else {
      props.setBreadcrumbForModifiedSearchEditPage();
    }
  }

  handleSearch(options) {
    this.props.runModifiedSavedSearch(options, this.getCurrentSearchId());
  }

  getSectionName() {
    return 'edit-saved-search';
  }

  getSearchFormViewProps() {
    return {
      searchType: 'saved',
      formView: 'edit',
      onSearch: this.handleSearch.bind(this),
      header: this.renderSavedSearchHeader('edit'),
      clientData: this.props.clientData,
    };
  }
}
