import React from 'react';

import WarningModal from './WarningModal';

const BASE_KEY = 'app.general.size_limit_warning';

export default function SizeLimitWarningModal() {
  return (
    <WarningModal
      displayName="SizeLimitWarning"
      header={`${BASE_KEY}.title`}
      content={`${BASE_KEY}.description`}
    />
  );
}
