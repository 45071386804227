import React from 'react';

import { connect } from 'react-redux';

import { contains, snakeCase } from 'lodash';
import { killEvent } from 'event-helpers';
import withMessages from 'component-utilities/intl';
import { prettyDate } from 'component-utilities/dates/dates';

import { TextLabel } from 'v-c/Widgets/Components';
import {
  TableRow,
  SortCell,
  DataCell,
  UserCell,
  TextCell,
} from 'v-c/TableView';

import { jsUi } from '../../../global/lib/jsUi';

import { TableViewContent } from '../../../global/components/TableView/TableViewContentFactory';
import {
  activeColumnsChanged,
  searchSearchLists,
  sortColumn,
} from '../../../global/state_managers/SearchListsStateManager';

const {
  sortKeys: { UNSORTED },
} = SortCell;
const {
  rowStatuses: { SELECTED },
} = TableRow;
const NEW_DROPDOWN_BASE = 'app.settings.search_lists.new_dropdown';

const mapActionsToProps = {
  activeColumnsChanged,
  searchSearchLists,
  sortColumn,
};

// TODO: get rid of TableViewContent inheritance
@withMessages
@connect(
  null,
  mapActionsToProps,
)
export default class TableView extends TableViewContent {
  static defaultProps = {
    tableType: 'searchLists',
  };

  static displayName = 'SearchLists/TableView';

  delegateActiveColumnsChange = (newColumns: any) => {
    this.props.activeColumnsChanged(newColumns);
  };

  onSearchChange = (newValue: any) => {
    this.props.searchSearchLists(newValue);

    this.setState({ search: newValue });
  };

  editSearchList = (id: string) => (e: any) => {
    killEvent(e);
    jsUi.settingsSearchListsEdit.goTo({ id });
  };

  get tableHelper() {
    return this.props.tableHelper;
  }

  get list() {
    return this.props.searchLists;
  }

  get searchString() {
    return this.props.searchString;
  }

  get actionHelper() {
    const keyword = 'keyword';
    const user = 'user';
    const { getMessage } = this.props;

    const buildList = (...list: any) =>
      list.slice(0, -1).map((id: string) => ({
        id,
        value: getMessage(`${list[list.length - 1]}.${snakeCase(id)}`),
      }));

    const newList = buildList(keyword, user, NEW_DROPDOWN_BASE);

    return {
      newWithDropdown: {
        onSelectedValue: (value: any, id: string) => {
          switch (id) {
            case user:
            case keyword:
              return jsUi.settingsSearchListsNew.goTo({ type: id });
            default:
              throw new Error(`Unexpected ID: ${id}`);
          }
        },
        newList,
      },
    };
  }

  get headerContent() {
    const {
      sortOptions: { sortKey, sortOrder },
    } = this.props;
    const { OPEN_SEARCH } = this.tableColumns;
    const visibleColumns = [];

    this.allColumns.filter(this.filterColumns).forEach((cellId: string, idx: number) => {
      let newSortKey = UNSORTED;
      if (sortKey === cellId) newSortKey = sortOrder;

      const column = Object.assign(
        {},
        {
          onClick: this.props.sortColumn,
          sortKey: newSortKey,
          key: idx,
          text: this.localizedColumnNames[cellId],
          cellId,
        },
      );

      visibleColumns.push(<SortCell {...column} />);
    });

    visibleColumns.push(<DataCell key={OPEN_SEARCH} cellId={OPEN_SEARCH} />);

    return visibleColumns;
  }

  get bodyContent() {
    const { searchLists } = this.props;
    const { selectedRows } = this.state;
    const { NAME, TYPE, USER, MODIFIED_DATE, OPEN_SEARCH } = this.tableColumns;

    return searchLists.map((searchList: any, rowIdx: numbert) => {
      const { id, type, name, createdUser, lastModifiedTime } = searchList;
      const isChecked = contains(selectedRows, id);
      const rowStatus = isChecked ? SELECTED : null;

      const visibleColumns = [];

      this.allColumns.filter(this.filterColumns).forEach((cellId: string) => {
        switch (cellId) {
          case NAME: {
            visibleColumns.push(
              <TextCell isFloatingCell key={cellId} cellId={NAME}>
                {this.highlightText(name)}
              </TextCell>,
            );
            break;
          }
          case USER: {
            visibleColumns.push(
              <UserCell
                style={{ minWidth: 150, maxWidth: 250 }}
                key={cellId}
                cellId={USER}
                userName={this.highlightText(createdUser)}
              />,
            );
            break;
          }
          case MODIFIED_DATE: {
            visibleColumns.push(
              <TextCell
                style={{ minWidth: 150, maxWidth: 250 }}
                key={cellId}
                cellId={MODIFIED_DATE}
              >
                {this.highlightText(prettyDate(lastModifiedTime))}
              </TextCell>,
            );
            break;
          }
          case TYPE: {
            visibleColumns.push(
              <TextCell
                style={{ minWidth: 150, maxWidth: 250 }}
                key={cellId}
                cellId={TYPE}
              >
                {this.highlightText(type)}
              </TextCell>,
            );
            break;
          }

          default: {
            visibleColumns.push(null);
          }
        }
      });

      visibleColumns.push(
        <DataCell cellId={OPEN_SEARCH} key={OPEN_SEARCH}>
          <data
            data-action="edit-search"
            onClick={this.editSearchList(id)}
            role="button"
            tabIndex="-1"
          >
            <TextLabel className="table-link-text run-search">
              {this.props.getMessage('app.settings.search_lists.edit')}
            </TextLabel>
          </data>
        </DataCell>,
      );

      return (
        <TableRow
          key={id}
          index={rowIdx}
          status={rowStatus}
          rowId={id}
          onDoubleClick={this.editSearchList(id)}
        >
          {visibleColumns}
        </TableRow>
      );
    });
  }
}
