import React from 'react';
import PropTypes from 'prop-types';

import { timeZonesDropdownValues } from 'component-utilities/dates/dates';
import TooltipGrayIcon from 'v-c/Icons/TooltipGrayIcon';
import Tooltip from 'v-c/Tooltip/Tooltip';
import { FormLabel, InputField, DropdownMedium } from 'v-c/Forms';

import IfFeature from '../../../../global/components/IfFeature';

import styles from '../../components/Settings.scss';

const BASE_KEY = 'app.settings.account_settings';

function BasicInfoTab(props: any) {
  const { getMessage, fields } = props;

  const changeTimeZone = (timezone: string) => {
    fields.timezone.change(timezone);
  };

  return (
    <div>
      <div className={styles['tab-content-column']}>
        <div className={styles['form-row']}>
          <FormLabel>
            {getMessage(`${BASE_KEY}.account_name`)}
            <Tooltip
              position="right"
              type="medium"
              content={getMessage(`${BASE_KEY}.tooltips.account_name`)}
            >
              <TooltipGrayIcon />
            </Tooltip>
          </FormLabel>
          <div className={styles['medium-input']}>
            <InputField
              focused
              data-field="account-name"
              status="disabled"
              {...fields.accountName}
            />
          </div>
        </div>
      </div>
      {IfFeature.isEnabled('settings.account_settings.timezone') &&
        <div className={styles['tab-content-column']}>
          <div className={styles['form-row']}>
            <FormLabel>
              {getMessage(`${BASE_KEY}.time_zone`)}
              <Tooltip
                position="right"
                type="medium"
                content={getMessage(`${BASE_KEY}.tooltips.time_zone`)}
              >
                <TooltipGrayIcon />
              </Tooltip>
            </FormLabel>
            <DropdownMedium
              id="time_zone"
              selectedId={fields.timezone.value}
              onSelect={changeTimeZone}
              data={timeZonesDropdownValues}
            />
          </div>
        </div>}
      <div style={{ clear: 'left' }} />
    </div>
  );
}

BasicInfoTab.propTypes = {
  getMessage: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
};

BasicInfoTab.displayName = 'AccountSettings/BasicInfoTab';

export default BasicInfoTab;
