import deepmerge from 'deepmerge';
import IfFeature from '../components/IfFeature';

const settings: any = {};

if (typeof (window) === 'undefined') {
  console.warn('no global window object, DeveloperInterface is not available!');
} else {
  window.DeveloperInterface = () => {
    settings.allMethods = settings.allMethods || {};
    settings.featureOnlyMethods = settings.featureOnlyMethods || {};

    if (IfFeature.isEnabled('developer_interface')) {
      return deepmerge(settings.allMethods, settings.featureOnlyMethods);
    }

    return settings.allMethods;
  };
}

export default function developerInterface(methods: any, disableFeatureCheck = false) {
  settings.allMethods = settings.allMethods || {};
  settings.featureOnlyMethods = settings.featureOnlyMethods || {};

  Object.keys(methods).forEach((method) => {
    if (disableFeatureCheck) {
      settings.allMethods[method] = methods[method];
    } else {
      settings.featureOnlyMethods[method] = methods[method];
    }
  });
}
