import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default function SaveNewSearchDeconstructed({
  goToNewSearchIndex,
  unsavedSearchForSave,
}: any) {

  type Props = {
    unsavedClientData: {
      type: any,
    },
    loadAllUsers: Function,
    resetError: Function,
    saveUnsavedSearch: Function,
  }

  return function SaveNewSearchStateLogicComposer(component: any) {
    class SaveNewSearchStateLogic extends Component<Props> {
      static propTypes = {
        unsavedClientData: PropTypes.object.isRequired,
        loadAllUsers: PropTypes.func.isRequired,
        resetError: PropTypes.func.isRequired,
        saveUnsavedSearch: PropTypes.func.isRequired,
      };

      constructor(props: Props) {
        super(props);

        if (!props.unsavedClientData.type) {
          goToNewSearchIndex();
        }

        props.resetError();
        props.loadAllUsers();
      }

      onSave = (fieldValues: any) => {
        this.props.saveUnsavedSearch({
          ...unsavedSearchForSave(),
          ...fieldValues,
        });
      };

      render() {
        return React.createElement(component, {
          ...this.props,
          ...this.state,
          onSave: this.onSave,
        });
      }
    }

    return SaveNewSearchStateLogic;
  };
}
