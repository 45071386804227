import reports from './state_managers/ReportsStateManager';
import exports from './state_managers/ExportsStateManager';
import exportsTableUI from './state_managers/ExportTableUIStateManager';
import audits from './state_managers/AuditsStateManager';
import auditsTableUI from './state_managers/AuditsTableUIStateManager';
import userSettings from './state_managers/UserSettingsStateManager';
import flashMessages from './state_managers/FlashMessagesStateManager';
import accountSettings from './state_managers/AccountSettingsStateManager';
import modals from './state_managers/ModalsStateManager';
import serialResultsLoader from './state_managers/SerialResultsLoaderStateManager';
import savedSearches from './state_managers/SavedSearchesStateManager';
import legalHoldReasons from './state_managers/LegalHoldReasonsStateManager';
import contextTree from './state_managers/ContextTreeStateManager';
import bulkActions from './state_managers/BulkActionsStateManager';
import searchForm from './state_managers/SearchFormStateManager';
import purge from './state_managers/PurgeStateManager';
import currentSavedSearch from './state_managers/CurrentSavedSearchStateManager';
import userRights from './state_managers/UserRightsStateManager';
import documentViewer from './state_managers/DocumentViewerStateManager';
import searchLists from './state_managers/SearchListsStateManager';
import pop3Config from './state_managers/Pop3ConfigStateManager';
import loginAttemptRestriction from './state_managers/LoginAttemptRestrictionStateManager';
import domainInfo from './state_managers/DomainInfoStateManager';
import user from './state_managers/UserStateManager';
import users from './state_managers/UsersStateManager';
import usersBulkOperations from './state_managers/UsersBulkOperationsStateManager';
import retentionPolicy from './state_managers/RetentionPolicyStateManager';
import authentication from './state_managers/AuthenticationStateManager';
import adSync from './state_managers/ADSyncStateManager';
import adWizard from './state_managers/ADWizardStateManager';
import loginForm from './state_managers/LoginFormStateManager';
import asset from './state_managers/AssetStateManager';
import UIHelper from './state_managers/UIHelperStateManager';
import unsavedSearch from './state_managers/UnsavedSearchStateManager';
import holdPanel from './state_managers/HoldPanelStateManager';
import bulkUploadUsers from './state_managers/BulkUploadUsersStateManager';
import dialog from './state_managers/DialogStateManager';
import breadcrumbs from './state_managers/BreadcrumbsStateManager';
import searchResults from './state_managers/SearchResultsStateManager';

// Export reducers in alphabetical order
// Makes Redux DevTools easier to navigate
export {
  accountSettings,
  adSync,
  adWizard,
  asset,
  audits,
  auditsTableUI,
  authentication,
  breadcrumbs,
  bulkActions,
  bulkUploadUsers,
  contextTree,
  currentSavedSearch,
  dialog,
  documentViewer,
  domainInfo,
  exports,
  exportsTableUI,
  flashMessages,
  holdPanel,
  loginAttemptRestriction,
  loginForm,
  legalHoldReasons,
  modals,
  pop3Config,
  purge,
  reports,
  retentionPolicy,
  savedSearches,
  searchForm,
  searchLists,
  searchResults,
  serialResultsLoader,
  UIHelper,
  unsavedSearch,
  user,
  users,
  userRights,
  userSettings,
  usersBulkOperations,
};
