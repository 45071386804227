import { has } from 'lodash';

import validations from './constants/validationIssues';
import settings from './constants/settings.json';
import ADSyncConstants from './constants/ADSyncConstants';
import statuses from './constants/statuses';

// tif the namespace is 'settings', turns a list of constants
// ['dog', 'cat'] into {settings: {dog: 'settings.dog', cat: 'settings.cat'}}
//
// also accepts [{id: 'dog'}, {id: 'cat'}]
function populateConstants(constants: any[], namespace: string) {
  return constants.reduce((h, keyOrObjectWithId) => {
    let key;
    if (has(keyOrObjectWithId, 'id')) {
      key = keyOrObjectWithId.id;
    } else {
      key = keyOrObjectWithId;
    }

    const keyWithNamespace = `${namespace.toUpperCase()}.${key}`;
    const parts = keyWithNamespace.split('.');
    let current = h;
    while (parts.length > 1) {
      const nextPart = parts.shift();
      current[nextPart] = current[nextPart] || {};
      current = current[nextPart];
    }

    current[parts.shift()] = keyWithNamespace.toLowerCase();

    return h;
  }, {});
}

export default {
  ...populateConstants(validations, 'validation_issues'),
  ...populateConstants(settings, 'settings'),
  ...populateConstants(ADSyncConstants, 'ad_sync'),
  ...populateConstants(statuses, 'statuses'),
};
