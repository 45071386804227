/* eslint-disable filenames/match-regex */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { has, snakeCase } from 'lodash';
import { MultiSelect } from 'global/types';

export function isLoading({ currentSavedSearch, triedLoadingContext }: any) {
  return currentSavedSearch.isLoading || !triedLoadingContext;
}

export function isReady({ currentSavedSearch }: any) {
  return !currentSavedSearch.isProcessing && currentSavedSearch.isSuccessed;
}

export function isSplitView({ searchResults }: any) {
  return searchResults.appView === searchResults.appViews.SPLIT;
}

type Props = {
  searchResults: {
    appView: String, 
    appViews: {
      SPLIT: String,
      SINGLE: String
    }
  },
  resetReviewJob: Function,
  resetHoldReasonsjob: Function,
  showSuccessFlash: Function,
  showFailureFlash: Function,
  params: {
    id: String
  },
  changeAppView: Function,
  resetResults: Function,
  multiSelect: MultiSelect,
}

export function stateLogic({
  searchRelatedJobs,
  isHoldReasonsJobRunning,
  VALID_FLASH_VIEWS,
}: any) {
  return (component: any) =>
    class extends Component<Props> {
      static displayName = 'SearchResults/StateLogic';

      static propTypes = {
        searchResults: PropTypes.object,
        resetReviewJob: PropTypes.func.isRequired,
        resetHoldReasonsjob: PropTypes.func.isRequired,
        showSuccessFlash: PropTypes.func.isRequired,
        showFailureFlash: PropTypes.func.isRequired,
        params: PropTypes.object.isRequired,
        changeAppView: PropTypes.func.isRequired,
        resetResults: PropTypes.func.isRequired,
        multiSelect: PropTypes.shape({
          reset: PropTypes.func.isRequired,
        }).isRequired,
      };

      static defaultProps = {
        searchResults: {},
      };

      constructor(props: Props) {
        super(props);

        this.state = {
          triedLoadingContext: false,
        };
      }

      onChangeAppView = () => {
        const { appView, appViews } = this.props.searchResults;

        this.setState(
          () => {
            this.props.changeAppView(
              appView === appViews.SPLIT ? appViews.SINGLE : appViews.SPLIT,
            );
          },
        );
      };

      checkBulkOperationStatuses = (searchId: String) => {
        const jobs = searchRelatedJobs(searchId);

        if (jobs.review && has(jobs.review, 'isSuccess')) {
          this.props.resetReviewJob(searchId);

          if (jobs.review.isSuccess) {
            this.props.showSuccessFlash(
              'bulk_operations',
              VALID_FLASH_VIEWS,
            );
          } else {
            this.props.showFailureFlash('bulk_operations', VALID_FLASH_VIEWS);
          }
        }

        if (jobs.holdReasons && has(jobs.holdReasons, 'isSuccess')) {
          this.props.resetHoldReasonsjob(searchId);

          if (jobs.holdReasons.isSuccess) {
            this.props.showSuccessFlash(
              'bulk_operations',
              VALID_FLASH_VIEWS,
            );
          } else {
            this.props.showFailureFlash('bulk_operations', VALID_FLASH_VIEWS);
          }
        }
      };

      render() {
        return React.createElement(component, {
          ...this.props,
          ...this.state,
          onChangeAppView: this.onChangeAppView,
          checkBulkOperationStatuses: this.checkBulkOperationStatuses,
          isHoldReasonsJobRunning: isHoldReasonsJobRunning(
            this.props.params.id,
          ),
        });
      }
    };
}
