import { ReduxState } from 'global/types';
import { connect } from 'react-redux';
import ModalViewComponent from 'v-c/Modals/ModalView';

import {
  closeModal,
  confirmModal,
} from '../../state_managers/ModalsStateManager';

import modals from './modals';

const mapStateToProps = ({ modals: modalsStore }: ReduxState) => ({
  modal: {
    type: modalsStore.type,
  },
  modalOptions: modalsStore.modalOptions,
});
const mapActionsToProps = {
  closeModal,
  confirmModal,
};

const ModalView = connect(
  mapStateToProps,
  mapActionsToProps,
)(ModalViewComponent(modals));

export default ModalView;
