import { Component } from 'react';

import { connect } from 'react-redux';

import multiSelectHoC from 'component-utilities/multiSelect/MultiSelect';
import withSerialResultsLoader, {
  LOAD_TYPES,
} from '../../../global/lib/withSerialResultsLoader';
import routeLeaveHook from '../../../global/lib/routeLeaveHook';
import fixedHeightContent from '../../../global/lib/fixedHeightContent';
import { actionsDirtyCheck } from '../../../global/components/TableViewActions';
import { jsUi } from '../../../global/lib/jsUi';

import { openHoldReasonAssetsStream } from '../../../global/lib/searchResultsStreamManager';
import {
  loadLegalHoldReasons,
  resetLegalHoldReasons,
  currentHoldReason,
} from '../../../global/state_managers/LegalHoldReasonsStateManager';

import {
  bulkUpdateHoldReasonsStatus,
  searchRelatedJobs,
  resetHoldReasonsjob,
  isHoldReasonsJobRunning,
} from '../../../global/state_managers/BulkActionsStateManager';
import { showUnsavedHoldReasonDialog } from '../../../global/state_managers/DialogStateManager';
import { breadcrumbsActions } from '../../../global/state_managers/BreadcrumbsStateManager';
import { initTableConfig } from '../../../global/state_managers/SearchResults/tableConfig';
import { loadMoreResults } from '../../../global/state_managers/SearchResults/loadMoreResults';
import {
  COLUMNS as SEARCH_RESULTS_TABLE_COLUMNS,
  resetResults,
} from '../../../global/state_managers/SearchResultsStateManager';
import { resetStream } from '../../../global/state_managers/SearchResults/searchResultsStream';

import { flashConnect, PAGES } from '../../../global/components/FlashMessages';

import {
  resetFlash,
  showSuccessFlash,
  showFailureFlash,
} from '../../../global/state_managers/FlashMessagesStateManager';

import TableViewHeading from '../../../global/components/TableView/AssetsView/Heading';
import TableView from '../../../global/components/TableView/AssetsView/TableView';

import {
  stateLogic,
  viewLogic,
  viewContents,
} from './HoldReasonAssetsDeconstructed';

const mapStateToProps = ({
  legalHoldReasons,
  bulkActions,
  unsavedSearch,
  searchResults,
}: any) => ({
  legalHoldReasons,
  bulkActions,
  unsavedSearch,
  searchResults,
  returnCount: searchResults.stream.returnCount,
});

const mapActionsToProps = {
  loadLegalHoldReasons,
  resetLegalHoldReasons,
  bulkUpdateHoldReasonsStatus,
  resetFlash,
  resetHoldReasonsjob,
  showSuccessFlash,
  showFailureFlash,
  setBreadcrumbForHoldReasonsResultsPage:
    breadcrumbsActions.holdReasonsResultsPage,
  initTableConfig,
  loadMoreResults,
  resetResults,
  resetStream,
};

const VALID_FLASH_VIEWS = [PAGES.HOLD_REASON_ASSETS];

@multiSelectHoC
@routeLeaveHook
@flashConnect(PAGES.HOLD_REASON_ASSETS, 'HoldReasonAssets/componentWillUnmount')
@withSerialResultsLoader(LOAD_TYPES.SEARCH_RESULTS_LOAD_MORE)
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@actionsDirtyCheck(showUnsavedHoldReasonDialog)
@fixedHeightContent
@stateLogic({
  currentHoldReason,
  TableViewHeading,
  TableView,
  showHoldReasonAssset: jsUi.holdReasonsShowAssets,
  openHoldReasonAssetsStream,
  searchRelatedJobs,
  isHoldReasonsJobRunning,
  REVIEW: SEARCH_RESULTS_TABLE_COLUMNS.REVIEW,
  VALID_FLASH_VIEWS,
  })
export default class HoldReasonAssets extends Component {
  static displayName = 'HoldReasons/Assets/Show';

  constructor(props: any) {
    super(props);

    this.alreadyRendered = false;
  }

  componentDidUpdate() {
    if (viewLogic(this.props).readyStates.every((readyState: any) => readyState)) {
      this.alreadyRendered = true;
    }
  }

  render() {
    return viewContents({
      ...this.props,
      ...viewLogic(this.props),
      alreadyRendered: this.alreadyRendered,
    });
  }
}
