import React from 'react';
import PropTypes from 'prop-types';

import { omit, snakeCase } from 'lodash';

import { TextLabel } from 'view-components/components/Widgets/Components';

import styles from './HeadlineLabel.scss';

type Props = {
  text: string
}

export default function HeadlineLabel(props: Props) {
  const { text } = props;
  const widgetProps = omit(props, ['text', 'styleName', 'styles']);

  return (
    <TextLabel
      {...widgetProps}
      data-headline={snakeCase(text)}
      className={styles['headline-label']}
    >
      {text}
    </TextLabel>
  );
}

HeadlineLabel.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

HeadlineLabel.defaultProps = {
  text: '',
};
