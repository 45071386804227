import React from 'react';
import PropTypes from 'prop-types';

import SplitViewSortAction from 'v-c/Splitview/SplitViewSortAction';

function SortDropdown(props: { sortDropdown: any }) {
  return (
    <SplitViewSortAction {...props.sortDropdown} />
  );
}

SortDropdown.propTypes = {
  sortDropdown: PropTypes.object.isRequired,
};

export default SortDropdown;
