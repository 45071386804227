/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { is, fromJS } from 'immutable';

import {
  has,
  pick,
  trimLeft,
  isString,
  isObject,
  isArray,
  isBoolean,
  isDate,
} from 'lodash';

const VALIDATION_PROPERTY = '__isValidForm__';

export { VALIDATION_PROPERTY };

type Props = {
  formValues: any,
  onFormChange: Function,
}

export default function formBack(component: any) {
  class FormBack extends Component<Props> {
    static propTypes = {
      formValues: PropTypes.object,
      onFormChange: PropTypes.func,
    };

    static defaultProps = {
      formValues: null,
      onFormChange: null,
    };

    constructor(props: Props) {
      super(props);

      this.VALIDATION_PROPERTY = '__isValidForm__';

      this.state = {
        form: {},
        __originalFormValues__: {},
      };
    }

    componentDidUpdate(prevProps: Props, prevState: any) {
      if (
        this.isNewViewBase &&
        this.state.initialForm &&
        !prevState.initialForm
      ) {
        let form;
        if (this.getInitialFormValues) {
          form = this.getInitialFormValues();
        } else {
          form = this.props.formValues || {};
        }

        this.setInitialFormAndOriginalValues(form);
      }
    }

    setInitialFormAndOriginalValues = (form: any) => {
      this.setState(
        {
          form: { ...form },
          __originalFormValues__: { ...form },
        },
        () => {
          this.validateFormValues();
          this.resetForm();
        },
      );
    };

    setFormValidateValue = (newValue: boolean) => {
      this.changeFormValue(this.VALIDATION_PROPERTY, newValue);
    };

    getFormValues = (properties = null) => {
      let form = Object.assign({}, this.state.form);
      delete form[this.VALIDATION_PROPERTY];

      if (isArray(properties)) {
        form = pick(form, properties);
      }

      return form;
    };

    getOriginalFormValues = () => {
      const { __originalFormValues__ = {} } = this.state;
      delete __originalFormValues__[this.VALIDATION_PROPERTY];

      return { ...__originalFormValues__ };
    };

    changeFormValue = (name: string, _newValue: any, cb: Function) => {
      let newValue = _newValue;
      const form = fromJS(this.state.form).toJS();

      if (isString(newValue)) {
        newValue = trimLeft(newValue);
      }

      form[name] = newValue;

      this.setState({ form }, () => {
        if (name !== this.VALIDATION_PROPERTY) {
          this.validateFormValues();
          if (cb) cb();
        }
      });

      if (this.props.onFormChange) {
        this.props.onFormChange(form, name, newValue);
      }
    };

    isValidForm = () => this.state.form[this.VALIDATION_PROPERTY];

    validateFormValues = (updateState = true) => {
      const form = this.getFormValues();
      const validatedFormValues = {};

      Object.keys(form).forEach((field) => {
        const fieldValue = form[field];

        switch (true) {
          case isArray(fieldValue):
          case isString(fieldValue):
            validatedFormValues[field] = !!fieldValue.length;
            break;
          case isDate(fieldValue):
            validatedFormValues[field] = true;
            break;
          case isObject(fieldValue):
            validatedFormValues[field] = has(
              fieldValue,
              this.VALIDATION_PROPERTY,
            )
              ? fieldValue[this.VALIDATION_PROPERTY]
              : !!Object.keys(fieldValue).length;
            break;
          case isBoolean(fieldValue):
            validatedFormValues[field] = fieldValue;
            break;
          default:
            validatedFormValues[field] = false;
        }
      });

      let isValidForm = false;

      if (Object.keys(validatedFormValues).length) {
        const reduce = (field: string) => validatedFormValues[field];
        isValidForm = Object.keys(validatedFormValues).some(reduce);
      }

      if (updateState) {
        if (this.validateStoredFormValues) {
          this.setFormValidateValue(
            this.validateStoredFormValues(isValidForm, validatedFormValues),
          );
        } else {
          this.setFormValidateValue(isValidForm);
        }
      }

      return { isValidForm, validatedFormValues };
    };

    isDirty = () =>
      !is(fromJS(this.getOriginalFormValues()), fromJS(this.getFormValues()));

    makeUndirty = () => {
      this.setState({
        __originalFormValues__: fromJS(this.getFormValues()).toJS(),
      });
    };

    resetForm = () => {
      this.setState({ form: fromJS(this.getOriginalFormValues()).toJS() });
    };

    clearFormValue = (name: string) => {
      delete this.state.form[name];
      this.validateFormValues();
    };

    handleChange = (name: string) => (e: any) => this.changeFormValue(name, e.target.value);

    render() {
      return React.createElement(component, {
        ...this.props,
        ...this.state,
        setInitialFormAndOriginalValues: this.setInitialFormAndOriginalValues,
        setFormValidateValue: this.setFormValidateValue,
        getFormValues: this.getFormValues,
        getOriginalFormValues: this.getOriginalFormValues,
        changeFormValue: this.changeFormValue,
        isValidForm: this.isValidForm,
        validateFormValues: this.validateFormValues,
        isDirty: this.isDirty,
        makeUndirty: this.makeUndirty,
        resetForm: this.resetForm,
        clearFormValue: this.clearFormValue,
        handleChange: this.handleChange,
      });
    }
  }

  return FormBack;
}
