/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable filenames/match-regex */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';

import { isNumber, has, isEmpty, isEqual } from 'lodash';

import { TAGS } from '../../../data/Criteria';

import LoadWhenReady from '../../../global/lib/LoadWhenReady';

import { QUERY_TYPES } from '../../../global/lib/generateFilteredQuery';

import { actions } from '../../../global/components/TableViewActions';
import Loading from '../../../global/components/Loading';

const TableFactory = Loadable({
  loader: () => import('../../../global/components/TableView/TableViewContentFactory'),
  loading() {
    return <Loading />;
  },
});

type Props = {
  returnCount: number,
  params: {
    id: string,
  },
  updateActionsDirtyState: Function,
  loadLegalHoldReasons: Function,
  resetLegalHoldReasons: Function,
  bulkUpdateHoldReasonsStatus: Function,
  resetFlash: Function,
  resetHoldReasonsjob: Function,
  showSuccessFlash: Function,
  showFailureFlash: Function,
  registerUnmountCleanupMethod: Function,
  setBreadcrumbForHoldReasonsResultsPage: Function,
  initTableConfig: Function,
  resetResults: Function,
  resetStream: Function,
  unsavedSearch: {
    data: {
      filteredFormValues: any,
    },
  },
  TableViewHeading: any,
  TableView: any,
  tableHeadingProps: any,
  tableContentProps: any,
  alreadyRendered: Boolean,
  readyStates: string[],
  legalHoldReasons: any,
  isStreamLoaded: Boolean,
  searchString: string,
  currentHoldReason: any,
  holdReasonId: string,
  isHoldActionDirty: Boolean,
  loadMoreResults: Function,
  goToRenderedAsset: Function,
  onHoldReasonsUpdate: Function,
  isHoldReasonsJobRunning: Boolean,
  REVIEW: string,
  factoryProps: any
}

export function viewLogic(props: Props) {
  const {
    legalHoldReasons,
    isStreamLoaded,
    returnCount,
    searchString,
    currentHoldReason,
    holdReasonId,
    isHoldActionDirty,
    loadMoreResults,
    goToRenderedAsset,
    onHoldReasonsUpdate,
    isHoldReasonsJobRunning,
    unsavedSearch: {
      data: { filteredFormValues },
    },
    REVIEW,
  } = props;

  const visibleTableActions = () => {
    if (returnCount) {
      return [actions.FILTER, actions.HOLD, actions.EXPORT];
    }

    return isEmpty(filteredFormValues) ? [] : [actions.FILTER];
  };

  return {
    ...props,
    readyStates: [legalHoldReasons.isLoaded, isStreamLoaded],
    factoryProps: {
      returnCount: returnCount || 0,
      searchString,
      forceVisibleTable: isStreamLoaded,
    },
    tableHeadingProps: {
      searchName: currentHoldReason.name,
      returnCount,
    },
    tableContentProps: {
      ...props,
      searchId: holdReasonId,
      isHoldReasonAssets: true,
      isHoldActionDirty,
      onHoldReasonsUpdate,
      isHoldReasonsJobRunning,
      loadMoreResults,
      hiddenColumns: [REVIEW],
      hiddenFilterCriteria: [TAGS],
      visibleTableActions: visibleTableActions(),
      goToRenderedAsset,
      filter: { type: QUERY_TYPES.HOLD_ASSETS },
    },
  };
}

export function stateLogic({
  TableViewHeading,
  TableView,
  showHoldReasonAssset,
  openHoldReasonAssetsStream,
  searchRelatedJobs,
  isHoldReasonsJobRunning,
  currentHoldReason,
  REVIEW,
  VALID_FLASH_VIEWS,
}: any) {
  return function HoldReasonAssetsStateLogicComposer(component: any) {
    class HoldReasonAssetsStateLogic extends Component<Props> {
      static displayName = 'HoldReasonAssets/stateLogic';

      static propTypes = {
        returnCount: PropTypes.number,
        params: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
        updateActionsDirtyState: PropTypes.func.isRequired,
        loadLegalHoldReasons: PropTypes.func.isRequired,
        resetLegalHoldReasons: PropTypes.func.isRequired,
        bulkUpdateHoldReasonsStatus: PropTypes.func.isRequired,
        resetFlash: PropTypes.func.isRequired,
        resetHoldReasonsjob: PropTypes.func.isRequired,
        showSuccessFlash: PropTypes.func.isRequired,
        showFailureFlash: PropTypes.func.isRequired,
        registerUnmountCleanupMethod: PropTypes.func.isRequired,
        setBreadcrumbForHoldReasonsResultsPage: PropTypes.func.isRequired,
        initTableConfig: PropTypes.func.isRequired,
        resetResults: PropTypes.func.isRequired,
        resetStream: PropTypes.func.isRequired,
        unsavedSearch: PropTypes.shape({
          data: PropTypes.shape({
            filteredFormValues: PropTypes.object.isRequired,
          }).isRequired,
        }).isRequired,
      };

      static defaultProps = {
        returnCount: null,
      };

      constructor(props: Props) {
        super(props);
        const { params } = props;

        props.setBreadcrumbForHoldReasonsResultsPage();
        props.initTableConfig(params.id);
        props.loadLegalHoldReasons({ loadIfNecessary: true });
        openHoldReasonAssetsStream(params.id);

        props.registerUnmountCleanupMethod(() => {
          props.resetStream();
          props.resetLegalHoldReasons({
            from: 'HoldReasonAssetsDeconstructed',
          });
        });
      }

      componentDidUpdate(prevProps: Props) {
        const { returnCount, unsavedSearch, params } = this.props;

        const jobs = searchRelatedJobs(this.holdReasonId);
        if (
          !returnCount &&
          (!isEqual(unsavedSearch.data.filteredFormValues, prevProps.unsavedSearch.data.filteredFormValues))
        ) {
          openHoldReasonAssetsStream(params.id);
        }

        if (jobs.holdReasons && has(jobs.holdReasons, 'isSuccess')) {
          this.props.resetHoldReasonsjob(this.holdReasonId);

          const baseFlashPath = 'bulk_operations.hold_reasons';

          if (jobs.holdReasons.isSuccess) {
            this.props.showSuccessFlash(baseFlashPath, VALID_FLASH_VIEWS);
          } else {
            this.props.showFailureFlash(baseFlashPath, VALID_FLASH_VIEWS);
          }
        }
      }

      onHoldReasonsUpdate = (newAndDeletedIDs: string[]) => {
        const { bulkUpdateHoldReasonsStatus, resetFlash } = this.props;

        resetFlash({ from: 'HoldReasonAssetsDeconstructed' });
        bulkUpdateHoldReasonsStatus({
          searchId: this.holdReasonId,
          params: {
            newAndDeletedIDs,
            query: this.holdReasonId,
          },
        });
      };

      get holdReasonId() {
        return this.props.params.id;
      }

      get isStreamLoaded() {
        return isNumber(this.props.returnCount);
      }

      isHoldActionDirty = (isDirty: Boolean) => {
        this.props.updateActionsDirtyState('hold', isDirty);
      };

      goToRenderedAsset = (assetId: string) => {
        const { enumId } = currentHoldReason(this.holdReasonId);

        return showHoldReasonAssset.path({ id: enumId, asset_id: assetId });
      };

      render() {
        return React.createElement(component, {
          ...this.props,
          ...this.state,
          isStreamLoaded: this.isStreamLoaded,
          holdReasonId: this.holdReasonId,
          currentHoldReason: currentHoldReason(this.holdReasonId),
          isHoldActionDirty: this.isHoldActionDirty,
          onHoldReasonsUpdate: this.onHoldReasonsUpdate,
          goToRenderedAsset: this.goToRenderedAsset,
          isHoldReasonsJobRunning: isHoldReasonsJobRunning(this.holdReasonId),
          TableViewHeading,
          TableView,
          REVIEW,
          TAGS,
        });
      }
    }

    return HoldReasonAssetsStateLogic;
  };
}

export function viewContents(props: Props) {
  const {
    TableViewHeading,
    TableView,
    tableHeadingProps,
    tableContentProps,
    alreadyRendered,
  } = props;

  const renderTableHeading = <TableViewHeading {...tableHeadingProps} />;
  const renderTableContent = <TableView {...tableContentProps} />;

  const factoryProps = {
    ...props.factoryProps,
    heading: renderTableHeading,
    content: renderTableContent,
  };

  const readyStates = alreadyRendered ? [true] : props.readyStates;

  return (
    <LoadWhenReady readyStates={readyStates}>
      <div data-view="hold-reason-assets">
        <TableFactory {...factoryProps} />
      </div>
    </LoadWhenReady>
  );
}

viewContents.propTypes = {
  readyStates: PropTypes.array.isRequired,
  isStreamLoaded: PropTypes.bool.isRequired,
  factoryProps: PropTypes.object.isRequired,
  tableHeadingProps: PropTypes.object.isRequired,
  tableContentProps: PropTypes.object.isRequired,
  TableView: PropTypes.node.isRequired,
  TableViewHeading: PropTypes.node.isRequired,
  alreadyRendered: PropTypes.bool.isRequired,
};
