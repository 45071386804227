import { kebabCase } from 'lodash';

import convertDataKeys from 'view-components/lib/convertDataKeys';

export default function processUpdateValues(newValues: any) {
  const values = convertDataKeys(newValues, kebabCase);

  // we don't want to convert these two keys
  if (values.contacts && values.contacts.length) {
    const contactValues = values.contacts[0];
    contactValues.address1 = contactValues['address-1'];
    contactValues.address2 = contactValues['address-2'];
    delete contactValues['address-1'];
    delete contactValues['address-2'];
    values.contacts = [contactValues];
  }

  return values;
}
