import { isString, contains, isArray } from 'lodash';
import { SortCell } from 'view-components/components/TableView';
import naturalCmp from './NaturalCmp';

const {
  sortKeys: { ASC, DESC },
} = SortCell;
const { sortKeys } = SortCell;

function sort(list: any[], id: string, direction: string) {
  const sortId = isArray(id) ? [...id] : [id];
  return list.sort((a: any, b: any) => {
    let a2 = a.getIn(sortId);
    let b2 = b.getIn(sortId);

    if (isString(a2) && isString(b2)) {
      a2 = a2.toLowerCase();
      b2 = b2.toLowerCase();
    }

    switch (direction) {
      case ASC:
        return naturalCmp(a2, b2);
      case DESC:
        return naturalCmp(b2, a2);
      default:
        return null;
    }
  });
}

function simpleSearch(list: any[], searchString: string, searchFields = []) {
  return list.filter((search) => {
    const fullString = searchFields.map(
      searchField => search.get(searchField),
    ).join('\n');

    return contains(
      fullString.toLowerCase(),
      searchString.toLowerCase(),
    );
  });
}

function onActiveColumnsChanged(newColumns: string[]) {
  this.updateState(this.state
    .setIn(['tableHelper', 'activeColumns'], newColumns),
  );
}

function sortAboutSortOptions() {
  const { sortKey, sortOrder } = this.state.get('sortOptions').toJS();

  this.onSort(sortKey, sortOrder);
}

const StoresHelper = {
  ASC,
  DESC,

  simpleSearch,
  onActiveColumnsChanged,
  sortAboutSortOptions,
  sort,
};

export { sortKeys, sort, simpleSearch, onActiveColumnsChanged, sortAboutSortOptions, ASC, DESC };
export default StoresHelper;
