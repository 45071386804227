/* eslint-disable react/no-multi-comp */
/* eslint-disable react/sort-comp */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';

import deepmerge from 'deepmerge';

import DomainComponent from '../DomainComponent';
import AddDomainButton from '../AddDomainButton';
import domainError from './domainError';
import { Pages } from '../../../../global/types'
const BASE_KEY = 'email_domains_tab';

type Props = {
  fields: {
    domains: any
  },
  sections: any, 
  form: any, 
  getMessage: Function,
  showGeneralFlash: Function,
  PAGES: Pages
}

export function stateLogic(component: any) {
  return class EmailDomainStateLogic extends Component<Props> {
    static propTypes = {
      fields: PropTypes.object.isRequired,
      showGeneralFlash: PropTypes.func.isRequired,
      PAGES: PropTypes.object.isRequired,
    };

    static displayName = 'EmailDomainsTab.stateLogic';

    constructor(props: Props) {
      super(props);

      this.showEmptyDomainsFlashIfNecessary(props.fields.domains);
    }

    componentDidUpdate() {
      this.showEmptyDomainsFlashIfNecessary(this.props.fields.domains);
    }

    onChange = (domainIdx: number) => ({ target }: any) => {
      const { fields } = this.props;
      const modifiedDomains = fields.domains.value;

      modifiedDomains[domainIdx] = { domainName: target.value };

      fields.domains.change(modifiedDomains);
    };

    showEmptyDomainsFlashIfNecessary(domains: any) {
      // When page is loaded, domains.value is an empty string. 
      // Banner/flash always shows on load if we don't have the first check.
      // domains.value changes to type object when form is edited
      if (typeof domains.value === 'object' && isEmpty(domains.value)) {
        this.props.showGeneralFlash(
          `${BASE_KEY}.update`,
          this.props.PAGES.SETTINGS_ACCOUNT_SETTINGS,
        );
      }
    }

    addNewEmailDomain = () => {
      const { fields } = this.props;
      const modifiedDomains = fields.domains.value || [];

      modifiedDomains.push({
        domainName: '',
      });

      fields.domains.change(modifiedDomains);
    };

    deleteEmailDomain = (index: number) => () => {
      const { fields } = this.props;
      const modifiedDomains = [...fields.domains.value];

      modifiedDomains.splice(index, 1);

      fields.domains.change(modifiedDomains);
    };

    render() {
      return React.createElement(
        component,
        deepmerge(this.props, {
          form: {
            onChange: this.onChange,
            addNewEmailDomain: this.addNewEmailDomain,
            deleteEmailDomain: this.deleteEmailDomain,
          },
        }),
      );
    }
  };
}

export function viewLogic(props: Props) {
  const { fields: { domains } } = props;
  const hasDomains = !!domains.value;

  let processedDomains = null;
  if (hasDomains) {
    processedDomains = domains.value.map((domain: { domainName: string, domainId: string }, index: number) => {
      const { domainName, domainId } = domain;
      const error = domainError(domainName);

      return {
        name: domainName,
        id: domainId,
        index,
        isEditable: !domainId,
        isValid: error === '',
        error,
        original: domain,
      };
    });
  }

  return deepmerge(props, {
    sections: {
      domains: {
        visible: hasDomains,
      },
    },
    form: {
      domains: processedDomains,
    },
  });
}

viewLogic.propTypes = {
  fields: PropTypes.object.isRequired,
};

export function viewContents(props: Props) {
  const { sections, form, getMessage } = props;

  return (
    <div>
      {sections.domains.visible &&
        form.domains.map((domain: any, index: number) =>
          React.createElement(
            DomainComponent,
            deepmerge(domain, {
              getMessage,
              key: index,
              onDelete: form.deleteEmailDomain(index),
              onChange: form.onChange(index),
            }),
          ),
        )}
      <AddDomainButton
        onClick={form.addNewEmailDomain}
        getMessage={getMessage}
      />
    </div>
  );
}

viewContents.propTypes = {
  getMessage: PropTypes.func.isRequired,
  sections: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

@stateLogic
export default class EmailDomainsTab extends Component {
  render() {
    return viewContents(viewLogic(this.props));
  }
}
