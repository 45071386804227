import { browserHistory } from 'react-router';
import { GLOBAL } from '../../../../../../config/routes/view-frontend';

export default function navigateToRedirectPath(
  siteName: string,
  userToken: string,
  path: null | string = null,
) {
  if (!siteName) {
    throw new Error('No siteName defined!');
  }
  if (!userToken) {
    throw new Error('No userToken defined!');
  }

  if (path && path.split('/')[2] === GLOBAL.APP_NAME) {
    browserHistory.push(path);
  }

  return false;
}
