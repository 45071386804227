import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import { LowerSection } from 'view-components/components/Dialog/Components';

import ConfirmableButtonContainer from './ConfirmableButtonContainer';
import DeleteConfirmationForm from './DeleteConfirmationForm';

type Props = {
  additionalTopContent: ReactNode,
  deleteMessage: ReactNode,
  toggleConfirmed: Function,
  onConfirm: Function,
  onDismiss: Function,
  isConfirmed: boolean,
  buttonText: string,
}

const LowerSectionDeleteConfirm = (props: Props) => {
  const {
    additionalTopContent,
    deleteMessage,
    isConfirmed,
    onConfirm,
    onDismiss,
    toggleConfirmed,
    buttonText,
  } = props;

  return (
    <LowerSection>
      { additionalTopContent }

      <DeleteConfirmationForm
        toggleConfirmed={toggleConfirmed}
        isConfirmed={isConfirmed}
        message={deleteMessage}
      />

      <ConfirmableButtonContainer
        buttonText={buttonText}
        isConfirmed={isConfirmed}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
    </LowerSection>
  );
};

LowerSectionDeleteConfirm.propTypes = {
  additionalTopContent: PropTypes.node.isRequired,
  deleteMessage: PropTypes.node.isRequired,
  toggleConfirmed: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
};

LowerSectionDeleteConfirm.defaultProps = {
  buttonText: '',
};

export default LowerSectionDeleteConfirm;
