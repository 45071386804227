import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../../global/components/DifferentLayout';
import Breadcrumb from '../../../global/components/Breadcrumb';
import FlashMessages from '../../../global/components/FlashMessages';

export default function Container({ children }: any) {
  return (
    <Layout section="settings reports">
      <FlashMessages />
      <Breadcrumb />

      {children}
    </Layout>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
};
