import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import generatorThunk from 'redux-generator-thunk';

import appReducer from './state_managers/AppStateManager';

const VALID_DOMAINS_FOR_REDUX_DEBUGGER = [
  'localhost',
  'dev-view-us',
  'qa-view-us',
  'ua-view-us',
];

const isReduxDevToolsEnabled = new RegExp(
  VALID_DOMAINS_FOR_REDUX_DEBUGGER.join('|'),
  'gi',
).test(window.location.hostname);

/* eslint-disable no-underscore-dangle */
const createStoreWithMiddleware = applyMiddleware(generatorThunk)(
  applyMiddleware(thunk)(
    window.__REDUX_DEVTOOLS_EXTENSION__ && isReduxDevToolsEnabled
      ? window.__REDUX_DEVTOOLS_EXTENSION__()(createStore)
      : createStore,
  ),
);
/* eslint-enable no-underscore-dangle */

const reduxStore = createStoreWithMiddleware(compose(appReducer));

export type RootState = ReturnType<typeof reduxStore.getState>;
export type PromiseObject = {
  data: any
}
export type AppDispatch = typeof reduxStore.dispatch
export interface Action { 
  type: string,
  payload: any
}
export interface ApiError {
  data: any
}
export default reduxStore;
