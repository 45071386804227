import Uri from 'urijs';

/* eslint-disable camelcase */
export default function getRedirectToPath() {
  const query = new Uri();
  query.query(window.location.query);
  const { redirect_to } = query.query(true);

  return redirect_to;
}
