import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { jsUi } from '../../global/lib/jsUi';

import SearchEditingBase from './components/SearchEditingBase';

import { flashConnect, PAGES } from '../../global/components/FlashMessages';

import { runUnsavedSearch } from '../../global/state_managers/UnsavedSearchStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

const mapStateToProps = ({ searchForm, unsavedSearch, domainInfo }: any) => ({
  searchForm,
  unsavedSearch,
  clientData: unsavedSearch.data.clientData,
  showMatchKeywordExact: domainInfo.exactMatchClientEnabled,
  showDomains: domainInfo.domainSearchClientEnabled,
});
const mapActionsToProps = {
  runUnsavedSearch,
  setBreadcrumbForNewSearchEditPage: breadcrumbsActions.newSearchEditPage,
};

// TODO: get rid of the inheritance, replace with HOC
/* eslint-disable class-methods-use-this */
@flashConnect(PAGES.NEW_SEARCH_EDIT, 'UnsavedSearch/Edit')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class UnsavedSearchEdit extends SearchEditingBase {
  static propTypes = {
    setBreadcrumbForNewSearchEditPage: PropTypes.func.isRequired,
  };

  constructor(props: any) {
    super(props);

    const { type } = props.clientData;

    if (!type) {
      jsUi.newSearchIndex.goTo();
    }

    this.isNeedLoadingCheck = false;

    props.setBreadcrumbForNewSearchEditPage();
  }

  handleSearch = (options) => {
    this.props.runUnsavedSearch(options);
  };

  getSectionName() {
    return 'edit-unsaved-search';
  }

  getSearchFormViewProps() {
    return {
      searchType: 'new',
      formView: 'edit',
      onSearch: this.handleSearch.bind(this),
      header: this.renderUnsavedSearchHeader(),
      clientData: this.props.clientData,
    };
  }
}
