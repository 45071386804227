import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import RenderedAssetViewBase from '../../global/components/AssetView/RenderedAssetViewBase';
import routeLeaveHook from '../../global/lib/routeLeaveHook';

import { loadSavedSearch } from '../../global/state_managers/CurrentSavedSearchStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';
import { Params } from 'global/types';

const mapStateToProps = ({ currentSavedSearch }: any) => ({
  currentSavedSearch,
  searchId: currentSavedSearch.search.id || '',
  searchName: currentSavedSearch.search.name,
});

const mapActionsToProps = {
  loadSavedSearch,
  setBreadcrumbForSearchesAssetPage: breadcrumbsActions.searchesAssetPage,
};

type Props = {
  params: Params,
  loadSavedSearch: Function,
  setBreadcrumbForSearchesAssetPage: Function
}

@routeLeaveHook
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class RenderedAsset extends Component<Props> {
  static propTypes = {
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    loadSavedSearch: PropTypes.func.isRequired,
    setBreadcrumbForSearchesAssetPage: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    const {
      params: { id },
    } = this.props;

    props.loadSavedSearch({ searchId: id, loadIfNecessary: true });
    props.setBreadcrumbForSearchesAssetPage();
  }

  render() {
    return <RenderedAssetViewBase {...this.props} />;
  }
}
