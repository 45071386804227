/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import withMessages, { intlPropTypes } from 'component-utilities/intl';
import TooltipGrayIcon from 'v-c/Icons/TooltipGrayIcon';
import Tooltip from 'v-c/Tooltip/Tooltip';
import { PrimaryButton, SecondaryButton } from 'v-c/Buttons/Components';
import { FormLabel, InputField, DropdownSmall, FormHint } from 'v-c/Forms';

import { LargeCheckbox } from 'v-c/Forms/Checkboxes';

import { POP3, POP3_STATUSES } from '../../../../config/configs';

import {
  testPop3Config,
  collectPop3Messages,
  updatePop3Status
} from '../../../../global/state_managers/Pop3ConfigStateManager';

import styles from '../../components/Settings.scss';

const BASE_KEY = 'app.settings.collectors.email';

// TODO: turn into separate component?
function FormInputBoxComponent(props) {
  const { field, label, focused, getMessage, small, type } = props;

  const inputSize = small ? 'small-size-input' : 'medium-input';

  return (
    <div className={styles['form-row']}>
      <FormLabel>
        {getMessage(`${BASE_KEY}.${label}`)}
        <Tooltip
          position="right"
          type="medium"
          content={getMessage(`${BASE_KEY}.tooltips.${label}`)}
        >
          <TooltipGrayIcon />
        </Tooltip>
      </FormLabel>
      <div className={styles[inputSize]}>
        <InputField
          type={type}
          focused={focused}
          data-field={`pop3-${label}`}
          {...field}
        />
      </div>
    </div>
  );
}

FormInputBoxComponent.propTypes = {
  small: PropTypes.bool,
  field: PropTypes.object,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  getMessage: PropTypes.func.isRequired,
  focused: PropTypes.bool,
};

FormInputBoxComponent.defaultProps = {
  type: 'text',
  small: true,
  focused: false,
  field: {},
};

const FormInputBox = withMessages(FormInputBoxComponent);

const mapActionsToProps = {
  collectPop3Messages,
  testPop3Config,
  updatePop3Status
};

function maxMessageSizeDropdownData() {
  return Object.keys(POP3.MESSAGE_SIZES).map(key => ({
    id: key,
    value: POP3.MESSAGE_SIZES[key],
  }));
}

@withMessages
@connect(
  null,
  mapActionsToProps,
)
export default class Pop3Tab extends Component {
  static propTypes = {
    ...intlPropTypes,
    testPop3Config: PropTypes.func.isRequired,
    collectPop3Messages: PropTypes.func.isRequired,
    updatePop3Status: PropTypes.func.isRequired
  };

  onTestConfiguration = () => {
    this.props.testPop3Config(this.fieldValues);
  };

  onCollectPop3Messsages = () => {
    this.props.collectPop3Messages(this.fieldValues);
  };

  onDisableEnablePop3 = async () => {
    await this.props.fields.enabled.toggle()
    this.props.updatePop3Status(this.fieldValues.enabled);
  }

  get fieldValues() {
    const {
      collectionInterval,
      enabled,
      host,
      maxMessageSize,
      password,
      pop3ConfigId,
      pop3ConfigName,
      port,
      ssl,
      username,
    } = this.props.getFieldValues();

    return {
      collectionInterval,
      enabled,
      host,
      maxMessageSize,
      password,
      pop3ConfigId,
      pop3ConfigName,
      port,
      ssl,
      username,
    };
  }

  get intervalDropdownData() {
    return Object.keys(POP3.INTERVALS).map(key => ({
      id: POP3.INTERVALS[key],
      value: this.props.getMessage(`${BASE_KEY}.intervals.${key}`),
    }));
  }

  get isTestButtonDisabled() {
    const fieldValues = this.fieldValues;

    delete fieldValues.pop3ConfigId;
    delete fieldValues.ssl;
    delete fieldValues.enabled;

    return Object.keys(fieldValues).some(
      key => !String(fieldValues[key]).length,
    );
  }

  get pop3StatusText() {
    const { pop3Config, getMessage } = this.props;

    const pop3StatusMessage = getMessage(
      `${BASE_KEY}.statuses.${pop3Config.status}`,
    );

    switch (pop3Config.status) {
      case POP3_STATUSES.SUCCESS_TEST:
      case POP3_STATUSES.SUCCESS_COLLECT:
        return (
          <span className={styles['form-green-text']}>{pop3StatusMessage}</span>
        );
      case POP3_STATUSES.FAILED_TEST:
      case POP3_STATUSES.FAILED_COLLECT:
        return (
          <span className={styles['form-red-text']}>{pop3StatusMessage}</span>
        );
      default:
        return <FormHint>{pop3StatusMessage}</FormHint>;
    }
  }

  get isShowCollectButton() {
    const { pop3Config, isDirtyFields } = this.props;

    return (
      (pop3Config.status === POP3_STATUSES.SUCCESS_TEST ||
        pop3Config.status === POP3_STATUSES.SUCCESS_COLLECT) &&
      !isDirtyFields
    );
  }

  render() {
    const {
      getMessage,
      fields,
      pop3Config,
      isProcessing,
      isDirtyFields,
    } = this.props;

    if (!pop3Config.status) {
      return null;
    }

    // TODO: find a way to get rid of those clearing divs
    // TODO: fix up the labels
    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <div className={styles['checkbox-tab-content']} data-section="pop3">
        <div className={styles['pop3-form-content']} data-section="pop3-form">
          {!pop3Config.isInitialData && (
            <data>
              <div className={styles['form-headline-label']}>
                <label className={styles['green-text-label']}>
                  {getMessage(`${BASE_KEY}.server_info`)}
                </label>
              </div>
              <div className={styles['tab-content-column']}>
                <FormInputBox
                  field={fields.pop3ConfigName}
                  label="name"
                  focused
                />
                <FormInputBox field={fields.host} label="ip_dns" />
                <div className={styles['form-row']}>
                  <LargeCheckbox
                    checked={!!fields.ssl.value}
                    data-action="use-ssl-checkbox"
                    onClick={fields.ssl.toggle}
                  >
                    {getMessage(`${BASE_KEY}.use_ssl`)}
                    <Tooltip
                      position="right"
                      type="medium"
                      content={getMessage(`${BASE_KEY}.tooltips.use_ssl`)}
                    >
                      <TooltipGrayIcon />
                    </Tooltip>
                  </LargeCheckbox>
                </div>
              </div>
              <div className={styles['tab-content-column']}>
                <div className={styles['form-row']}>
                  <FormLabel>
                    {getMessage(`${BASE_KEY}.interval`)}
                    <Tooltip
                      position="right"
                      type="medium"
                      content={getMessage(`${BASE_KEY}.tooltips.interval`)}
                    >
                      <TooltipGrayIcon />
                    </Tooltip>
                  </FormLabel>
                  <DropdownSmall
                    id="collection-interval"
                    selectedId={fields.collectionInterval.value}
                    onSelect={fields.collectionInterval.change}
                    data={this.intervalDropdownData}
                  />
                </div>
                <FormInputBox
                  small
                  field={fields.port}
                  type="number"
                  label="port"
                />
              </div>
              <div style={{ clear: 'left' }} />
              <div className={styles.divider} />
              <div className={styles['form-headline-label']}>
                <label className={styles['green-text-label']}>
                  {getMessage(`${BASE_KEY}.account_info`)}
                </label>
              </div>
              <div className={styles['tab-content-column']}>
                <FormInputBox field={fields.username} label="username" />
              </div>
              <div className={styles['tab-content-column']}>
                <FormInputBox
                  type="password"
                  field={fields.password}
                  label="password"
                />
              </div>
              <div style={{ clear: 'left' }} />
              <div className={styles.divider} />
              <div className={styles['form-headline-label']}>
                <label className={styles['green-text-label']}>
                  {getMessage(`${BASE_KEY}.options`)}
                </label>
              </div>
              <div className={styles['tab-content-column']}>
                <div className={styles['form-row']}>
                  <FormLabel>
                    {getMessage(`${BASE_KEY}.message_size`)}
                    <FormHint>
                      {`(${getMessage(`${BASE_KEY}.maximum`)})`}
                    </FormHint>
                    <Tooltip
                      position="right"
                      type="medium"
                      content={getMessage(`${BASE_KEY}.tooltips.message_size`)}
                    >
                      <TooltipGrayIcon />
                    </Tooltip>
                  </FormLabel>
                  <DropdownSmall
                    id="max-message-size"
                    selectedId={fields.maxMessageSize.value}
                    onSelect={fields.maxMessageSize.change}
                    data={maxMessageSizeDropdownData()}
                  />
                </div>
                <div className={styles['form-row']}>
                  <FormLabel>
                    {getMessage(`${BASE_KEY}.status`)}
                    <FormHint>
                      {`(${getMessage(`${BASE_KEY}.${fields.enabled.value ? 'enabled' : 'disabled'}`)})`}
                    </FormHint>
                    <Tooltip
                      position="right"
                      type="medium"
                      content={getMessage(`${BASE_KEY}.tooltips.status`)}
                    >
                      <TooltipGrayIcon />
                    </Tooltip>
                  </FormLabel>
                  <PrimaryButton disabled={isProcessing || (!fields.enabled.value && !this.isShowCollectButton) } onClick={this.onDisableEnablePop3} warning={fields.enabled.value}>
                    {getMessage(`${BASE_KEY}.${fields.enabled.value ? 'disable' : 'enable'}`)}
                  </PrimaryButton>
                </div>
              </div>
            </data>
          )}
        </div>
        <div className={styles['pop3-button-section']}>
          {this.isShowCollectButton && (
            <PrimaryButton
              data-action="collect"
              disabled={isProcessing}
              onClick={this.onCollectPop3Messsages}
              showIndicator={isProcessing}
            >
              {isProcessing && getMessage('app.button.processing')}
              {!isProcessing && getMessage('app.button.manual_collection')}
            </PrimaryButton>
          )}
          {!this.isShowCollectButton && (
            <data>
              <PrimaryButton
                data-action="test"
                disabled={this.isTestButtonDisabled || isProcessing}
                onClick={this.onTestConfiguration}
                showIndicator={isProcessing}
              >
                {isProcessing && getMessage('app.button.processing')}
                {!isProcessing && getMessage('app.button.test_configuration')}
              </PrimaryButton>
              <SecondaryButton
                data-action="cancel"
                disabled={!isDirtyFields || isProcessing}
                onClick={this.props.resetFields}
                extraClassName="additional-button"
              >
                {getMessage('app.button.cancel')}
              </SecondaryButton>
            </data>
          )}
          {
            // possible statuses
            // DEFAULT: initial text
            // SUCCESS_TEST
            // FAILED_TEST
            // SUCCESS_COLLECT
            // FAILED_COLLECT
          }
          {!isProcessing &&
            pop3Config.status !== POP3_STATUSES.SAVED && (
              <div
                className={styles['pop3-form-description']}
                data-status={pop3Config.status}
              >
                {this.pop3StatusText}
              </div>
            )}
        </div>
      </div>
    );
  }
}
