/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import withCSS from 'react-css-modules';
import { camelCase } from 'lodash';

import withMessages, {
  intlPropTypes,
  FormattedHTMLMessage,
} from 'component-utilities/intl';
import {
  TextLabel,
  LinkLabel,
  Divider,
} from 'view-components/components/Widgets/Components';
import {
  PrimaryButton,
  SecondaryButton,
} from 'view-components/components/Buttons/Components';

import Accordion from 'view-components/components/Accordions/Accordion';
import AccordionItem from 'view-components/components/Accordions/AccordionItem';
import { resetFlash } from '../../state_managers/FlashMessagesStateManager';

import withForm from '../../lib/formHelper';
import { DSL } from '../../../config/configs';
import developerInterface from '../../lib/developerInterface';
import advancedSearchFormHelper from '../../lib/AdvancedSearchFormHelper';
import toDSL from '../../lib/convertAdvancedSearchIntoSearchDSLStructure';
import validateStringifiedDateValue from '../../lib/ValidateStringifiedDateValue';
import IfFeature from '../IfFeature';

import { loadLegalHoldReasons } from '../../state_managers/LegalHoldReasonsStateManager';
import { loadSearchLists } from '../../state_managers/SearchListsStateManager';

import GroupView from './GroupView';
import CriteriaViews from './CriteriaViews/Components';

import styles from './FormBase.scss';
import { SEARCH } from '../../../../../../config/app.json';

import { stateLogic, viewLogic } from './AdvancedSearchFormDeconstructed';
import { intlShape, ReduxState } from 'global/types';

const { PATH_INDEX_SEPARATOR } = SEARCH;

interface Props extends intlShape {
  initialFormValues: Function,
  formValues: string | {}
  moveListElem: Function
  setInitialFields: Function
  fields: {
    form: {
      value: {}
    }
  }
  isValidForm: boolean
  isInitialForm: boolean
  canResetForm: boolean
  isCancelButtonEnabled: boolean
  getMessage: Function
  isViewOnly: boolean
  handleSearch: Function
  searchButtonText: string
  closeFilterModal: Function
  addListElemOptions: Function
  listElemOptions: Function
  listElemResources: {}
  cachedCriteriaValues: {}
  returnCount: number
  onEdit: Function
  onSave: Function
  onBack: Function
  saveButtonText: string
  loadingTime: number
  criteriaAndGroupsNumber: {
    criteria: string
    groups: string[]
  }
  formDefaults: {
    listElemTypes: {
      GROUP: string
      CRITERIA: string
    }
  }
  prettyPrintQuery: {}
  showMatchKeywordExact: boolean
  showDomains: boolean
  formView: string
}

// TODO: untwist the string refs handling
/* eslint-disable react/no-string-refs */
function viewContents(props: Props, { onCopySuccess, btnText }: any) {
  const {
    isValidForm,
    isInitialForm,
    canResetForm,
    isCancelButtonEnabled,
    getMessage,
    handleSearch,
    searchButtonText,
    closeFilterModal,
    formValues,
    addListElemOptions,
    listElemOptions,
    listElemResources,
    cachedCriteriaValues,
    returnCount = 0,
    onEdit,
    onSave,
    onBack,
    saveButtonText,
    loadingTime = 0,
    criteriaAndGroupsNumber,
    formDefaults,
    prettyPrintQuery,
    showMatchKeywordExact,
    showDomains,
  } = props;

  /* eslint-disable react/prop-types */
  const renderListElem = ({ key, listElem, parentRelation }: any) => {
    function orderChildKeys(k1: string, k2: string) {
      const k1Key = Number(k1.split(PATH_INDEX_SEPARATOR).slice(-1));
      const k2Key = Number(k2.split(PATH_INDEX_SEPARATOR).slice(-1));

      return k1Key - k2Key;
    }
    /* eslint-enable react/prop-types */
    if (!listElem) return null;

    const { UUID, type, children } = listElem;
    const {
      listElemTypes: { GROUP, CRITERIA },
    } = formDefaults;
    const viewHelper = {
      UUID,
      key,
      listElem,
      defaults: formDefaults,
      index: key,
      parentRelation,
      cachedFormValues: cachedCriteriaValues[UUID],
      listElemOptions: listElemOptions(),
      listElemResources,
      isViewOnly: props.isViewOnly,
      showMatchKeywordExact,
      showDomains,
    };

    switch (type) {
      case GROUP: {
        return (
          <GroupView
            ref={`group-${key}`}
            {...viewHelper}
            addListElemOptions={addListElemOptions(key)}
          >
            {Object.keys(children)
              .sort(orderChildKeys)
              .map(childKey =>
                renderListElem({
                  key: childKey,
                  listElem: children[childKey],
                  parentRelation: listElem.relation,
                }),
              )}
          </GroupView>
        );
      }
      case CRITERIA: {
        const CriteriaView = CriteriaViews[camelCase(listElem.criteriaType)];

        if (CriteriaView) {
          return <CriteriaView {...viewHelper} />;
        }

        return null;
      }
      default: {
        return null;
      }
    }
  };

  viewContents.propTypes = {
    ...intlPropTypes,
  };

  // TODO: make these actual components
  const renderEditView = () => {
    let isButtonForceEnabled = false;
    if (canResetForm && this.isFormEmpty) {
      isButtonForceEnabled = this.isFormEmpty();
    }

    return (
      <div styleName="advanced-view" data-view="advanced-view">
        <Divider styleName="top-divider" />

        <div styleName="form-content">
          <div
            className="advanced-edit-wrapper"
            styleName="advanced-edit-wrapper"
            ref="editWrapper"
          >
            {renderListElem({ key: '0', listElem: formValues['0'] })}
          </div>
        </div>

        <Divider className={styles.divider} />

        <data styleName="button-group">
          <PrimaryButton
            onClick={handleSearch}
            disabled={!isValidForm && !isButtonForceEnabled}
            data-action="search"
            extraClassName={styles['search-button']}
          >
            {getMessage(searchButtonText)}
          </PrimaryButton>
          {isCancelButtonEnabled && (
            <SecondaryButton
              onClick={closeFilterModal}
              data-action="cancel"
              extraClassName={styles['search-button']}
            >
              {getMessage('app.button.cancel')}
            </SecondaryButton>
          )}
          {!isInitialForm && (
            <TextLabel data-field="search-results-details">
              <FormattedHTMLMessage
                id="app.new_search_form.result_details"
                values={{
                  time: loadingTime.toString(),
                  emailCount: returnCount.toLocaleString(),
                }}
              />
            </TextLabel>
          )}
        </data>
      </div>
    );
  };

  const renderViewOnly = () => (
    <div styleName="advanced-view" data-view="advanced-view">
      <Divider styleName="top-divider" />

      <div styleName="form-content">
        <div
          className="advanced-edit-wrapper"
          styleName="advanced-edit-wrapper"
          ref="editWrapper"
        >
          {renderListElem({ key: '0', listElem: formValues['0'] })}
        </div>
      </div>

      <Divider className={styles.divider} />

      <data styleName="button-group">
        <PrimaryButton
          onClick={onBack}
          data-action="back-view"
          extraClassName={styles['back-button']}
        >
          {getMessage('app.button.back')}
        </PrimaryButton>
        <TextLabel data-field="search-results-details">
          <FormattedHTMLMessage
            id="app.new_search_form.result_details"
            values={{
              time: loadingTime.toString(),
              emailCount: returnCount.toLocaleString(),
            }}
          />
        </TextLabel>
      </data>
    </div>
  );

  const renderResultsView = () => (
    <data styleName="button-group">
      <TextLabel data-return-count={returnCount}>
        <FormattedHTMLMessage
          id="app.new_search_form.result_details"
          values={{
            time: loadingTime.toString(),
            emailCount: returnCount.toLocaleString(),
          }}
        />
      </TextLabel>

      <LinkLabel onClick={onEdit} data-action="edit" styleName="edit-label">
        {getMessage('app.button.edit')}
      </LinkLabel>

      <PrimaryButton
        onClick={onSave}
        extraClassName={styles['save-button']}
        data-action="save"
      >
        {getMessage(saveButtonText)}
      </PrimaryButton>
    </data>
  );

  const renderSaveView = () => {
    const { criteria: criteriaNum, groups } = criteriaAndGroupsNumber;

    return (
      <span className="criteria-container">
        <Accordion size="large">
          <AccordionItem
            label={
              <FormattedHTMLMessage
                id="app.new_search_form.advanced_search_counts"
                values={{
                  groupsCount: groups.toString(),
                  criteriaCount: criteriaNum.toString(),
                }}
              />
            }
          >
            {prettyPrintQuery}
          </AccordionItem>
        </Accordion>

        {prettyPrintQuery && IfFeature.isEnabled('copy-dsl-to-clipboard') && (
          <CopyToClipboard
            text={prettyPrintQuery}
            onCopy={onCopySuccess}
          >
            <button>{btnText}</button>
          </CopyToClipboard>
        )}
      </span>
    );
  };

  if (props.formView) {
    switch (props.formView) {
      case 'edit':
        return renderEditView();
      case 'view':
        return renderViewOnly();
      case 'results':
        return renderResultsView();
      case 'save':
        return renderSaveView();
      default:
        return null;
    }
  }

  return null;
}

const mapStateToProps = ({
  legalHoldReasons,
  searchLists,
  unsavedSearch,
  searchResults,
  domainInfo,
}: ReduxState) => ({
  reasons: legalHoldReasons.originalReasons,
  reasonsLoaded: legalHoldReasons,
  searchLists: searchLists.searchLists,
  unsavedSearch,
  returnCount: searchResults.returnCount,
  loadingTime: searchResults.loadingTime,
  showMatchKeywordExact: domainInfo.exactMatchClientEnabled,
  showDomains: domainInfo.domainSearchClientEnabled,
});

const mapActionsToProps = {
  loadLegalHoldReasons,
  loadSearchLists,
  resetFlash,
};

@withMessages
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withForm({
  form: 'advanced-search',
  fields: ['form'],
  })
@stateLogic({
  validateStringifiedDateValue,
  toDSL,
  })
@advancedSearchFormHelper
// TODO: get rid of react-css-modules
@withCSS(styles)
export default class AdvancedSearchForm extends Component<Props> {
  static propTypes = {
    initialFormValues: PropTypes.func.isRequired,
    formValues: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
    moveListElem: PropTypes.func.isRequired,
    ...intlPropTypes,
  };

  constructor(props: Props) {
    super(props);

    developerInterface({
      getDSL: () => toDSL(this.props.formValues),
      getHelperObject: () => this.props.fields.form.value,
      moveListElem: (from: string, to: string) => {
        this.props.moveListElem(from, to);
        return this.props.fields.form.value;
      },
    });

    this.state = { btnText: 'Copy this query' };
    this.sizeValues = [...DSL.FILE_SIZES];

    props.setInitialFields({
      form: props.initialFormValues(),
    });
  }

  componentDidUpdate() {
    if (this.props.setWrapperToScrollable && this.refs.editWrapper) {
      const $wrapper = $(findDOMNode(this.refs.editWrapper));
      $wrapper.css({ overflowY: 'scroll' });
    }
  }

  render() {
    const { btnText } = this.state;
    const onCopySuccess = () => { this.setState({ btnText: 'Copied!' }); };

    return viewContents(viewLogic(this.props, this.refs), { onCopySuccess, btnText });
  }
}
