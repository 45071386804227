import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Link } from 'react-router';
import withMessages, { FormattedHTMLMessage } from 'component-utilities/intl';

import {
  LinkLabel,
  TextLabel,
} from 'view-components/components/Widgets/Components';
import RetinaImage from 'view-components/components/RetinaImage';

import validateSettingsAccess from './components/validateSettingsAccess';

import { jsUi } from '../../global/lib/jsUi';

import Layout from '../../global/components/Layout';
import HeadlineLabel from '../../global/components/HeadlineLabel';
import Breadcrumb from '../../global/components/Breadcrumb';
import VerticalHeightManager from '../../global/components/VerticalHeightManager';

import RightArrowImage from './ADSync/images/right-arrow.png';
import SuccessImage from './ADSync/images/success_large.png';

import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import wizard from './ADSync/Wizard/Wizard.scss';
import custom from './ADSync/ADWizard.scss';

const mapStateToProps = null;
const mapActionsToProps = {
  setBreadcrumbForSettingsADSyncSuccessPage:
    breadcrumbsActions.settingsAdSyncSuccessPage,
};

type Props = {
  setBreadcrumbForSettingsADSyncSuccessPage: Function,
  getMessage: Function,
  headlineText: string
}

@withMessages
@validateSettingsAccess('settings.ad_sync')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class ADSuccess extends Component<Props> {
  static propTypes = {
    headlineText: PropTypes.string,
    getMessage: PropTypes.func.isRequired,
    setBreadcrumbForSettingsADSyncSuccessPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    headlineText: 'app.breadcrumb.ad_wizard',
  };

  constructor(props: Props) {
    super(props);

    props.setBreadcrumbForSettingsADSyncSuccessPage();
  }

  render() {
    const { getMessage } = this.props;
    return (
      <Layout section="ad-sync">
        <VerticalHeightManager className="content-wrapper">
          <VerticalHeightManager.Section>
            <header>
              <Breadcrumb {...this.props} />
              <HeadlineLabel text={getMessage(this.props.headlineText)} />
            </header>
          </VerticalHeightManager.Section>
          <VerticalHeightManager.Section maximize>
            <div className={wizard.wizard}>
              <div className={wizard['wizard-header']}>
                <TextLabel className={custom['success-header']}>
                  {getMessage('app.ad_sync.wizard.success.header')}
                </TextLabel>
              </div>
              <div
                className={`${wizard['wizard-body']} ${custom['wizard-body']}`}
              >
                <div className={custom['success-image']}>
                  <RetinaImage image={SuccessImage} />
                </div>
                <TextLabel className={custom['success-title']}>
                  {getMessage('app.ad_sync.wizard.success.title')}
                </TextLabel>
                <TextLabel className={custom['success-subtitle']}>
                  <FormattedHTMLMessage id="app.ad_sync.wizard.success.subtitle" />
                </TextLabel>
                <Link
                  data-action="success-link"
                  to={jsUi.settingsAdSyncIndex.path()}
                >
                  <LinkLabel className={custom['success-link']}>
                    {getMessage('app.ad_sync.wizard.success.manage_ad_link')}
                    <RetinaImage image={RightArrowImage} />
                  </LinkLabel>
                </Link>
              </div>
              <div className={wizard['wizard-footer']} />
            </div>
          </VerticalHeightManager.Section>
        </VerticalHeightManager>
      </Layout>
    );
  }
}
