import dataLoader from 'data/dataLoader';

export default function getLocalizedData(dataType: string, only: any, props: any) {
  const config = dataLoader[dataType];
  let localizedData;

  const getMessage = props ? props.getMessage : this.props.getMessage;

  localizedData =
    config.data.map((_d: any) => {
      const d = { ..._d };

      const hash = config.hash.replace(/#{}/, d.id);
      d.value = getMessage(hash);
      return d;
    });

  if (only) {
    localizedData =
      localizedData.filter(({ id }: any) => only.includes(id));
  }

  return localizedData;
}
