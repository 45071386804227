import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withMessages from 'component-utilities/intl';
import LoadingIndicator from 'v-c/LoadingIndicator';

import Layout from '../../global/components/Layout';

import Loaded from './ADSync/SyncLog/Loaded';

import validateSettingsAccess from './components/validateSettingsAccess';

import { getSyncLogs } from '../../global/state_managers/ADSyncStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import './ADSync/ADSync.scss';

const mapStateToProps = ({ adSync }: any) => ({
  syncLogs: adSync.syncLogs,
  syncLogsAreLoading: adSync.syncLogsAreLoading,
});

const mapActionsToProps = {
  getSyncLogs,
  setBreadcrumbForSettingsADSyncLogPage:
    breadcrumbsActions.settingsAdSyncLogPage,
};

type Props = {
  setBreadcrumbForSettingsADSyncLogPage: Function,
  getSyncLogs: Function,
  syncLogs: {
    logEntries: any,
    searchString: string
  },
  syncLogsAreLoading: Boolean,
  getMessage: Function,
}

@withMessages
@validateSettingsAccess('settings.ad_sync')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class ADSyncLog extends Component<Props> {
  static propTypes = {
    syncLogs: PropTypes.object,
    syncLogsAreLoading: PropTypes.bool,
    getMessage: PropTypes.func.isRequired,
    getSyncLogs: PropTypes.func.isRequired,
    setBreadcrumbForSettingsADSyncLogPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    syncLogs: {},
    syncLogsAreLoading: false,
  };

  constructor(props: Props) {
    super(props);

    props.setBreadcrumbForSettingsADSyncLogPage();
    props.getSyncLogs();
  }

  render() {
    const {
      syncLogs: { logEntries, searchString },
      syncLogsAreLoading,
      getMessage,
    } = this.props;

    // TODO: get rid of string refs
    /* eslint-disable react/no-string-refs */
    return (
      <div className="sync-logs" ref="syncLogs">
        <Layout section="ad-sync">
          {syncLogsAreLoading && (
            <LoadingIndicator
              orientation="middle"
              text={getMessage('app.general.loading')}
              isLoading
            />
          )}
          {!syncLogsAreLoading && (
            <Loaded
              returnCount={logEntries.length}
              searchString={searchString}
              {...this.props.syncLogs}
            />
          )}
        </Layout>
      </div>
    );
  }
}
