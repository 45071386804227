/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import LoadingIndicator from 'v-c/LoadingIndicator';

import { showFlash } from '../../../global/state_managers/FlashMessagesStateManager';
import sanitizeAndProtectRedirectUrl from './sanitizeAndProtectRedirectUrl';
import navigateToRedirectPath from './navigateToRedirectPath';

import { LOGIN_PROVIDERS } from '../../../../../../config/app.json';

import PAGES from '../../../global/constants/Pages';

const VALID_FLASH_VIEWS = [PAGES.LOGIN];

const { OPENID } = LOGIN_PROVIDERS;

type Props = {
  location: {
    query: boolean
  }
  getMessage: Function
  authentication: {
    redirectTo: string
    loggedInAs: {
      authenticationToken: string
    }
    loginSuccessful: boolean
    lastAttemptFailed: boolean
  }
  showFlash: Function
  startAttemptingLogin: Function
  startAttemptingTokenLogin: Function
  startAttemptingOpenIDLogin: Function
  clearLoggedInAs: Function
}

function getLoadingIndicator(getMessage: Function) {
  return (
    <LoadingIndicator
      orientation="middle"
      text={getMessage('app.general.loading')}
      isLoading
    />
  );
}

export default function loginAndRedirectHandler({
  goToHomePage,
  getAccountDetails,
}: any) {
  return (component: any) => {
    class LoginAndRedirectHandler extends Component<Props> {
      static propTypes = {
        location: PropTypes.object.isRequired,
        getMessage: PropTypes.func.isRequired,
        authentication: PropTypes.object,
        showFlash: PropTypes.func.isRequired,
        startAttemptingLogin: PropTypes.func.isRequired,
        startAttemptingTokenLogin: PropTypes.func.isRequired,
        startAttemptingOpenIDLogin: PropTypes.func.isRequired,
        clearLoggedInAs: PropTypes.func.isRequired,
      };

      static defaultProps = {
        authentication: {},
      };

      static getDerivedStateFromProps(nextProps: Props, prevState: any) {
        if (!prevState.showForm && nextProps.authentication.lastAttemptFailed) {
          nextProps.showFlash('login.other_failure.failure', VALID_FLASH_VIEWS);

          return { showForm: true };
        }

        return null;
      }

      constructor(props: any) {
        super(props);

        this.state = { showForm: true };

        const { token, hash } = this.query;
        const openIDError = this.query[OPENID.ERROR_PARAM];

        // alert us of errors that may sneak in here
        try {
          if (token) {
            this.state.showForm = false;

            props.clearLoggedInAs();

            props.startAttemptingTokenLogin({
              token,
              redirectTo: this.redirectTo,
            });
          }

          if (hash) {
            this.state.showForm = false;

            props.clearLoggedInAs();

            props.startAttemptingOpenIDLogin({
              hash,
              redirectTo: this.redirectTo,
            });
          }
        } catch (e) {
          console.error(e);
        }

        if (openIDError) {
          this.props.showFlash(
            `openid.errors.${openIDError}.failure`,
            VALID_FLASH_VIEWS,
          );
        }
      }

      componentDidUpdate(prevProps: any) {
        if (!this.props.authentication) {
          throw new Error('Must pass in authentication store');
        }

        if (
          !prevProps.authentication.loginSuccessful &&
          this.props.authentication.loginSuccessful
        ) {
          const {
            redirectTo,
            loggedInAs: { authenticationToken },
          } = this.props.authentication;

          if (redirectTo) {
            navigateToRedirectPath(
              getAccountDetails().siteName,
              authenticationToken,
              redirectTo,
            );
          } else {
            goToHomePage();
          }
        }
      }

      get query() {
        return (this.props.location && this.props.location.query) || {};
      }

      get redirectTo() {
        return this.query.redirect_to;
      }

      performLoginWithRedirect = (data: any) => {
        sanitizeAndProtectRedirectUrl(
          this.redirectTo,
          (url: string) => {
            this.props.startAttemptingLogin({
              ...data,
              redirectTo: url,
            });
          },
          () => {
            this.props.startAttemptingLogin(data);
          },
        );
      };

      render() {
        if (!this.state.showForm) {
          return getLoadingIndicator(this.props.getMessage);
        }

        return React.createElement(component, {
          ...this.props,
          performLoginWithRedirect: this.performLoginWithRedirect,
        });
      }
    }

    return connect(
      null,
      { showFlash },
    )(LoginAndRedirectHandler);
  };
}
