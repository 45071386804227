/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { contains } from 'lodash';

import Accordion from 'v-c/Accordions/Accordion';
import AccordionItem from 'v-c/Accordions/AccordionItem';

import withMessages, { intlPropTypes } from 'component-utilities/intl';
import toggleInArray from 'component-lib/toggleInArray';

import { InboxIcon } from 'v-c/Icons';

import { POP3_STATUSES } from '../../config/configs';

import routeLeaveHook from '../../global/lib/routeLeaveHook';
import dialogHandler from '../../global/lib/dialogHandler';

import withForm, { formHelperPropTypes } from '../../global/lib/formHelper';
import Layout from '../../global/components/Layout';
import Breadcrumb from '../../global/components/Breadcrumb';
import HeadlineLabel from '../../global/components/HeadlineLabel';
import FlashMessages from '../../global/components/FlashMessages';
import IfFeature from '../../global/components/IfFeature';

import SmtpTab from './Collectors/Email/SmtpTab';
import Pop3Tab from './Collectors/Email/Pop3Tab';

import { load as loadAccountSettings } from '../../global/state_managers/AccountSettingsStateManager';
import {
  loadPop3Config,
  resetPop3Config,
} from '../../global/state_managers/Pop3ConfigStateManager';
import { showUnsavedPop3ConfigDialog } from '../../global/state_managers/DialogStateManager';

import validateSettingsAccess from './components/validateSettingsAccess';
import { resetFlash } from '../../global/state_managers/FlashMessagesStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import styles from './components/Settings.scss';

const tabs = {
  SMTP: 'smtp',
  POP3: 'pop3',
};

const { SMTP, POP3 } = tabs;

const tabsComponents = {
  [SMTP]: SmtpTab,
  [POP3]: Pop3Tab,
};

const mapStateToProps = ({ accountSettings, pop3Config, domainInfo }: any) => ({
  mailbox: accountSettings.account.mailbox,
  server: accountSettings.account.server,
  pop3Config: pop3Config.config,
  isProcessing: pop3Config.isProcessing,
  isLoaded: pop3Config.isLoaded,
  domainInfo,
});

const mapActionsToProps = {
  resetFlash,
  loadAccountSettings,
  loadPop3Config,
  resetPop3Config,
  setBreadcrumbForSettingsCollectorsEmailPage:
    breadcrumbsActions.settingsCollectorsEmailPage,
};

type Props = {
  isDirtyFields: Boolean,
  retryTransition: any,
  domainInfo: {
    pop3SettingsEnabled: Boolean
  },
  pop3Config: {
    status: string
  },
  isLoaded: Boolean,
  isProcessing: Boolean,
  mailbox: {},
  loadAccountSettings: Function,
  setInitialFields: Function,
  setRouterWillLeaveValidator: Function,
  registerFieldsChangeCallback: Function,
  resetFlash: Function,
  registerUnmountCleanupMethod: Function,
  loadPop3Config: Function,
  resetPop3Config: Function,
  setBreadcrumbForSettingsCollectorsEmailPage: Function,
  getMessage: Function
}

type State = {
  openedTabs: string[]
}

@routeLeaveHook
@withMessages
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withForm({
  form: 'account-collectors-email',
  fields: [
  'collectionInterval',
  'enabled',
  'host',
  'pop3ConfigName',
  'pop3ConfigId',
  'port',
  'ssl',
  'username',
  'password',
  'maxMessageSize',
  ],
  })
@validateSettingsAccess('settings.collectors')
export default class Email extends Component<Props, State> {
  static propTypes = {
    resetFlash: PropTypes.func.isRequired,
    registerUnmountCleanupMethod: PropTypes.func.isRequired,
    loadPop3Config: PropTypes.func.isRequired,
    resetPop3Config: PropTypes.func.isRequired,
    setBreadcrumbForSettingsCollectorsEmailPage: PropTypes.func.isRequired,
    ...intlPropTypes,
    ...formHelperPropTypes,
  };

  constructor(props: Props) {
    super(props);

    this.state = { openedTabs: [] };

    const { mailbox } = props;

    if (!IfFeature.isDisabled('settings.pop3')) {
      this.props.loadPop3Config();
    }

    if (!mailbox) {
      props.loadAccountSettings();
    }

    props.setBreadcrumbForSettingsCollectorsEmailPage();
    props.setInitialFields(props.pop3Config);
    props.setRouterWillLeaveValidator(this.routerWillLeave);
    props.registerFieldsChangeCallback(() => {
      props.resetFlash({ from: 'Settings/POP3 config' });
    });

    props.registerUnmountCleanupMethod(() => {
      this.props.resetPop3Config();
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (
      (!prevProps.isLoaded && this.props.isLoaded) ||
      (prevProps.isProcessing && !this.props.isProcessing)
    ) {
      const isResetForm = contains(
        [
          POP3_STATUSES.DEFAULT,
          POP3_STATUSES.SUCCESS_TEST,
          POP3_STATUSES.SUCCESS_COLLECT,
        ],
        this.props.pop3Config.status,
      );

      if (isResetForm) {
        this.props.setInitialFields(this.props.pop3Config);
      }
    }
  }

  setTabs = (tab: string) => () => {
    this.setState({
      openedTabs: toggleInArray(this.state.openedTabs, tab),
    });
  };

  get tabs() {
    const { getMessage } = this.props;

    const modifiedTabs: any = { ...tabs };

    if (
      IfFeature.isDisabled('settings.pop3') ||
      !this.props.domainInfo.pop3SettingsEnabled
    ) {
      delete modifiedTabs.POP3;
    }

    return Object.keys(modifiedTabs).map((key) => {
      const tab = tabs[key];
      const Tab = tabsComponents[tab];

      // TODO: extract to standalone component
      // TODO: fix that form label
      /* eslint-disable has-for */
      /* eslint-disable jsx-a11y/label-has-for */
      function TabHeader() {
        return (
          <div className={styles['email-tab']}>
            <InboxIcon />
            <div className={styles['email-tab-label']}>
              <label className={styles['form-big-label-inline']}>
                {getMessage(`app.settings.collectors.tabs.${tab}`)}
              </label>
            </div>
          </div>
        );
      }
      /* eslint-enable jsx-a11y/label-has-for */
      /* eslint-enable has-for */

      return (
        <AccordionItem
          key={key}
          checked={false}
          label={<TabHeader />}
          content={<Tab {...this.props} />}
          dataLabel={tab}
          onClick={this.setTabs(tab)}
        />
      );
    });
  }

  routerWillLeave = () => {
    if (!this.props.isDirtyFields) {
      return true;
    }

    dialogHandler(showUnsavedPop3ConfigDialog, this.props.retryTransition);

    return false;
  };

  render() {
    const { getMessage } = this.props;

    return (
      <Layout section="settings collectors email" staticView>
        <data>
          <header>
            <FlashMessages />
            <Breadcrumb />
            <HeadlineLabel
              text={getMessage('app.settings.collectors.tabs.tab_name.email')}
            />
          </header>
          <div
            section="collectors"
            data-section="collectors"
            className={styles['checkbox-accordion-content']}
          >
            <Accordion>{this.tabs}</Accordion>
          </div>
        </data>
      </Layout>
    );
  }
}
