import React from 'react';

export default function Loading() {
  return (
    <div>
      <span className="loading">loading...</span>
      <span className="image-loading" />
    </div>
  );
}
