import loginLinksGenerator from 'v-c/Login/lib/loginLinks';

import { jsUi } from './jsUi';
import APP from '../../../../../config/app.json';
import reduxStore from '../reduxStore';
import { ReduxState } from 'global/types';

export default function loginLinks(withForgotPasswordLink = false) {
  const { domainInfo } = reduxStore.getState() as ReduxState;

  return loginLinksGenerator({
    ...(withForgotPasswordLink && {
      forgotYourPasswordAction: jsUi.resetPassword.goTo,
    }),
    defaults: APP.METADATA_DEFAULTS,
    domainInfo,
  });
}
