import { head, tail, omit } from 'lodash';
import createReducer from '../lib/createReducer';

const constant = (type: string) => `serialResultsLoader/${type}`;

// constants
const ADD_LOADER = constant('add');
const RESET_LOADER = constant('resetLoader');
const RESET_LOADERS = constant('reset');
const PUSH_TO_QUEUE = constant('push');
const SHIFT_QUEUE = constant('shift');
const CLEAR_QUEUE = constant('clear');
const IS_PROCESSING = constant('isProcessing');

interface SerialResultsLoaderState {}

// initial state
export const initialState: SerialResultsLoaderState = {};

// helpers
function loaderState(type: string, getState: Function) {
  return getState().serialResultsLoader[type];
}

// actions

// params for the actions below (type, action, and args) are per loader,
// and not to be confused with redux actions or types
export function addLoader(type: string, action: any) {
  return { type: ADD_LOADER, payload: { type, action } };
}

export function resetLoader(type: string) {
  return { type: RESET_LOADER, payload: { type } };
}

export function resetLoaders() {
  return { type: RESET_LOADERS };
}

export function clearQueue(type: string) {
  return { type: CLEAR_QUEUE, payload: { type } };
}

export function shiftQueue(type: string) {
  return { type: SHIFT_QUEUE, payload: { type } };
}

export function processLoop(type: string) {
  return function* doProcessLoop(getState: Function) {
    yield { type: IS_PROCESSING, payload: { type, isProcessing: true } };

    try {
      while (
        loaderState(type, getState) &&
        loaderState(type, getState).queue.length > 0
      ) {
        const { action, queue } = loaderState(type, getState);

        // take the first set of args that need to be passed to the loader action
        const args = head(queue);

        // call the action
        yield action(...args);
        // remove the used args from the queue
        yield shiftQueue(type);
      }
    } catch (e) {
      console.error(e);
    }

    yield { type: IS_PROCESSING, payload: { type, isProcessing: false } };
  };
}

export function pushToQueue(type: string, ...args: any) {
  return function* doPushToQueue(getState: Function) {
    yield { type: PUSH_TO_QUEUE, payload: { args, type } };

    // if queue is not processing, kick it off again
    if (!loaderState(type, getState).isProcessing) {
      yield processLoop(type);
    }
  };
}

// reducer
const SerialResultsLoaderStateManager = createReducer(initialState, {
  [ADD_LOADER]: (state: SerialResultsLoaderState, { action, type }: any) => ({
    ...state,
    [type]: {
      action,
      isProcessing: false,
      queue: [],
    },
  }),
  [RESET_LOADER]: (state: SerialResultsLoaderState, { type }: any) => omit(state, type),
  [RESET_LOADERS]: () => initialState,
  [CLEAR_QUEUE]: (state: SerialResultsLoaderState, { type }: any) => ({
    ...state,
    [type]: {
      ...state[type],
      isProcessing: false,
      queue: [],
    },
  }),
  [PUSH_TO_QUEUE]: (state: SerialResultsLoaderState, { args, type }: any) => ({
    ...state,
    [type]: {
      ...state[type],
      queue: [...state[type].queue, args],
    },
  }),
  [SHIFT_QUEUE]: (state: SerialResultsLoaderState, { type }: any) => ({
    ...state,
    ...(state[type] && {
      [type]: {
        ...state[type],
        queue: tail(state[type].queue),
      },
    }),
  }),
  [IS_PROCESSING]: (state: SerialResultsLoaderState, { type, isProcessing }: any) => ({
    ...state,
    [type]: {
      ...state[type],
      isProcessing,
    },
  }),
});

export default SerialResultsLoaderStateManager;
