/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { filesize } from 'filesize';
import { formatTZDate } from 'component-utilities/dates/dates';
import generateAttachmentLinks, {
  downloadAttachmentURL,
} from '../../../lib/generateAttachmentLinks';
import { isPageRoleEnabled, pageRoles } from '../../../lib/userRights';
import { Props } from './MetadataTab';

export function userCanDownloadAttachments() {
  return isPageRoleEnabled(pageRoles.USER_CAN_DOWNLOAD_ATTACHMENTS);
}

export function viewLogic(props: Props = {}) {
  const {
    recipients,
    sizeInBytes,
    date,
    downloadableAttachments = [],
  } = props.asset.data;
  let attachmentLinks = [];
  const numOfAttachments = downloadableAttachments.length;

  if (numOfAttachments) {
    attachmentLinks = downloadableAttachments.map(({ id, filename }, index) => {
      const url = downloadAttachmentURL(id, filename);
      const comma = index < numOfAttachments - 1 ? ', ' : '';
      return { comma, url, attachment: filename };
    });
  }

  return {
    tabContent: [
      {
        type: 'subject',
        classValue: 'first-meta-item',
      },
      { type: 'from' },
      {
        type: 'recipients',
        value: recipients && recipients.join(', '),
      },
      {
        type: 'date',
        value: formatTZDate(date),
      },
      {
        type: 'size',
        value: filesize(sizeInBytes, { standard: "jedec" }),
      },
      {
        type: 'attachment_count',
        value: numOfAttachments,
      },
      {
        type: 'attachments',
        value: attachmentLinks,
      },
      { type: 'message_id' },
    ],
  };
}

export function stateLogic() {
  return function MetadataTabStateLogicComposer(component: any) {
    return class MetadataTabStateLogic extends Component<Props> {
      static propTypes = {
        showViewer: PropTypes.func.isRequired,
        hideViewer: PropTypes.func.isRequired,
      };

      constructor(props: Props) {
        super(props);

        document.addEventListener(
          'oitattachmentclicked',
          this.onOitAttachmentClicked,
        );
      }

      componentWillUnmount() {
        document.removeEventListener(
          'oitattachmentclicked',
          this.onOitAttachmentClicked,
        );
      }

      onOitAttachmentClicked = ({ detail }: any) => {
        this.props.showViewer({
          userCanDownloadDocuments: userCanDownloadAttachments(),
          documentIndex: Number(detail.index),
          documents: generateAttachmentLinks(this.props, detail.index),
          onClose: this.props.hideViewer,
        });
      };

      render() {
        return React.createElement(component, {
          ...this.props,
          onOitAttachmentClicked: this.onOitAttachmentClicked,
        });
      }
    };
  };
}
