export const USER_CAN_EDIT_SAVED_SEARCHES = 'userCanEditSavedSearches';
export const USER_CAN_DOWNLOAD_OR_SEND_MESSAGES = 'user_can_download_or_send_messages';
export const USER_CAN_DOWNLOAD_ATTACHMENTS = 'user_can_download_attachments';
export const USER_CAN_REQUEST_EXPORT = 'user_can_request_export';
export const USER_NEEDS_SEARCH_PERMISSION = 'user_needs_search_permission';
export const USER_CAN_DELETE_ASSETS = 'user_can_delete_assets';
export const USER_CAN_SEE_COLLECTION_CONFIGURATION = 'user_can_see_collection_configuration';
export const USER_CAN_EDIT_RETENTION_POLICY = 'user_can_edit_retention_policy';
export const USER_CAN_USE_COMMENT_API = 'user_can_use_comment_api';
export const USER_CAN_USE_AD_SYNC = 'user_can_use_ad_sync';
const PageRoles = {
  USER_CAN_EDIT_SAVED_SEARCHES,
  USER_CAN_DOWNLOAD_OR_SEND_MESSAGES,
  USER_CAN_DOWNLOAD_ATTACHMENTS,
  USER_CAN_REQUEST_EXPORT,
  USER_NEEDS_SEARCH_PERMISSION,
  USER_CAN_DELETE_ASSETS,
  USER_CAN_SEE_COLLECTION_CONFIGURATION,
  USER_CAN_EDIT_RETENTION_POLICY,
  USER_CAN_USE_COMMENT_API,
  USER_CAN_USE_AD_SYNC,
};

export default PageRoles;
