import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { includes } from 'lodash';

import UserEditView, {
  TABS,
  TYPES,
} from 'v-c/Templates/UserEditView/UserEditView';

import { jsUi } from '../../../global/lib/jsUi';
import getUserFullName from '../../../global/lib/getUserFullName';

import Breadcrumb from '../../../global/components/Breadcrumb';

import dialogHandler from '../../../global/lib/dialogHandler';

import IfFeature from '../../../global/components/IfFeature';

import {
  showUnsavedUserDialog,
  showDeleteUserDialog,
} from '../../../global/state_managers/DialogStateManager';
import {
  openModal,
  closeModal,
} from '../../../global/state_managers/ModalsStateManager';

import MODAL_MAP from '../../../global/components/Modals/modalMap.json';

import {
  updateUser,
  deleteFailedUserLoginAttempts,
  deleteUser,
  resetUserErrors,
} from '../../../global/state_managers/UserStateManager';

import { getAllRoles } from '../../../global/lib/userRights';
import { User } from 'global/types';

const mapStateToProps = ({ userRights, authentication }: any) => ({
  activeRoles: userRights.activeRoles,
  loggedInAsUserId: authentication.loggedInAs.userId,
});

const mapActionsToProps = {
  updateUser,
  deleteFailedUserLoginAttempts,
  deleteUser,
  resetUserErrors,
  openModal,
  closeModal,
  showDeleteUserDialog,
};

type Props = {
  user: User,
  type: string,
  updateUser: Function,
  deleteFailedUserLoginAttempts: Function,
  deleteUser: Function,
  loginAttemptRestriction: {
    enabled: boolean
  },
  setRouterWillLeaveValidator: Function,
  retryTransition: () => boolean,
  registerUnmountCleanupMethod: Function,
  activeRoles: string[],
  resetUserErrors: Function,
  loggedInAsUserId: number,
  openModal: Function,
  closeModal: Function,
  showDeleteUserDialog: Function,
  onSave: Function,
  onDelete: Function,
}

@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class UserForm extends Component<Props> {
  static propTypes = {
    user: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    updateUser: PropTypes.func.isRequired,
    deleteFailedUserLoginAttempts: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    loginAttemptRestriction: PropTypes.object,
    setRouterWillLeaveValidator: PropTypes.func,
    retryTransition: PropTypes.func,
    registerUnmountCleanupMethod: PropTypes.func.isRequired,
    activeRoles: PropTypes.array.isRequired,
    resetUserErrors: PropTypes.func.isRequired,
    loggedInAsUserId: PropTypes.number.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    showDeleteUserDialog: PropTypes.func.isRequired,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    setRouterWillLeaveValidator: () => {},
    retryTransition: () => {},
    loginAttemptRestriction: null,
    onDelete: null,
    onSave: null,
  };

  constructor(props: Props) {
    super(props);

    props.setRouterWillLeaveValidator(this.routerWillLeave);
    props.registerUnmountCleanupMethod(() => {
      this.props.resetUserErrors();
    });

    this.isFormDirty = false;
  }

  onSave = (formDataToSubmit: {}, shouldDeleteFailedUserLoginAttempts: boolean) => {
    const { user } = this.props.user;

    this.props.updateUser(user.id, formDataToSubmit);

    if (shouldDeleteFailedUserLoginAttempts) {
      this.props.deleteFailedUserLoginAttempts(user.id);
    }
  };

  onDelete = ({ id: userId, externalId }: any) => {
    this.props.deleteUser(userId, externalId);
  };

  onCancel = () => {
    jsUi.usersIndex.goTo();
  };

  onDelegateDirtyState = (isDirty: boolean) => {
    this.isFormDirty = isDirty;
  };

  get isAdminOnly() {
    return includes(this.props.activeRoles, 'adminOnly');
  }

  get showDeleteButton() {
    const isEditUserTypeForm = this.props.type === TYPES.EDIT_USER;
    const isArchiveAdministrator = includes(
      this.props.activeRoles,
      'archiveAdministrator',
    );

    return (
      (isArchiveAdministrator || this.isAdminOnly) &&
      isEditUserTypeForm &&
      !this.isLoggedInUser
    );
  }

  get isLoggedInUser() {
    return this.props.loggedInAsUserId === Number(this.props.user.user.id);
  }

  get tabs() {
    const hiddenTabs: string[] = [];
    const isArchiveAdministrator = includes(
      this.props.activeRoles,
      'archiveAdministrator',
    );

    if (this.isChangePasswordForm) {
      hiddenTabs.push(TABS.BASIC_INFO, TABS.EMAILS, TABS.ROLES_GROUPS_STATUS);
    }

    const tabsArray =
      isArchiveAdministrator || (this.isAdminOnly && !this.isLoggedInUser)
        ? [
          TABS.BASIC_INFO,
          TABS.EMAILS,
          TABS.PASSWORD,
          TABS.ROLES_GROUPS_STATUS,
        ]
        : [TABS.BASIC_INFO, TABS.PASSWORD];

    return tabsArray.filter(tab => !includes(hiddenTabs, tab));
  }

  get loginAttemptRestrictionEnabled() {
    const { loginAttemptRestriction } = this.props;

    if (loginAttemptRestriction && loginAttemptRestriction.enabled) {
      return loginAttemptRestriction.enabled;
    }
    return undefined;
  }

  get deleteActionConfig() {
    if (!this.showDeleteButton) {
      return null;
    }

    return {
      showModal: this.props.showDeleteUserDialog,
      onDelete: this.props.onDelete || this.onDelete,
    };
  }

  get titleConfig() {
    const { user, type } = this.props;

    if (type === TYPES.NEW_USER) {
      return null;
    }

    return {
      'data-field': 'edit-user-label',
      text: `${getUserFullName(user.user)} (${user.user.username})`,
    };
  }

  get userHasAdministratorRights() {
    return includes(this.props.activeRoles, 'archiveAdministrator');
  }

  routerWillLeave = () => {
    if (this.isFormDirty) {
      dialogHandler(showUnsavedUserDialog, this.props.retryTransition);

      return false;
    }

    return true;
  };

  render() {
    const { onSave, user, type, loggedInAsUserId } = this.props;

    return (
      <UserEditView
        Breadcrumb={<Breadcrumb />}
        user={user}
        onSave={onSave || this.onSave}
        onCancel={this.onCancel}
        resetUserErrors={this.props.resetUserErrors}
        type={type}
        loggedInAsUserId={loggedInAsUserId}
        isLoggedInUser={this.isLoggedInUser}
        loginAttemptRestrictionEnabled={this.loginAttemptRestrictionEnabled}
        userHasAdministratorRights={this.userHasAdministratorRights}
        shouldRenderContent
        isHandlesStatusEnabled={IfFeature.isEnabled('handles_status')}
        tabs={this.tabs}
        titleConfig={this.titleConfig}
        rolesModalOptions={{
          open: this.props.openModal,
          close: this.props.closeModal,
          type: MODAL_MAP.ROLES_MODAL,
        }}
        delegateDirtyState={this.onDelegateDirtyState}
        deleteActionConfig={this.deleteActionConfig}
        allowedRoles={getAllRoles()}
      />
    );
  }
}
