import { contains } from 'lodash';
import getLocalizedData from '../../../lib/getLocalizedData';

import MultiSelectTypeCriteriaComposer from './Components/MultiSelectTypeCriteriaComposer';

type Props = {
  listElem: {
    values: {
      selectedCriteria: string[]
    }
  }
  form: {}
  updateBaseState: Function
  setInitialFormAndOriginalValues: Function
  isCriteriaDropdownVisible: Function
  registerCalculateVisibleCriteriaNum: Function
  registerIsEmptyCriteria: Function
  registerRenderHeaderText: Function
  registerIsApplyButtonDisabled: Function
}

const ParticipantsCriteriaView = MultiSelectTypeCriteriaComposer({
  init: function init(props: Props) {
    const {
      listElem: { values },
      form,
    } = props;

    const criteriaValues = getLocalizedData.apply(this, [
      'advParticipantsValuesSelections',
    ]);

    props.updateBaseState({
      criteriasVisibilities: {
        ...props.criteriasVisibilities,
        isRelationVisible: false,
      },
      criteriaValues,
    });

    props.setInitialFormAndOriginalValues(
      values.selectedCriteria
        ? values
        : {
          ...values,
          ...form,
          selectedCriteria: criteriaValues.map(({ id }) => id),
          list: [''],
          relation: 'or',
        },
    );

    this.state = {
      focusedIndex: 0,
      isCriteriaDropdownVisible: props.isCriteriaDropdownVisible,
      visibleCriteria: 0,
    };

    props.registerCalculateVisibleCriteriaNum(this.calculateVisibleCriteriaNum);
    props.registerIsEmptyCriteria(this.isEmptyCriteria);
    props.registerRenderHeaderText(this.renderHeaderText);
    props.registerIsApplyButtonDisabled(this.isApplyButtonDisabled);
  },
  instances: {
    getHeaderCalculationObject: function getHeaderCalculationObject(
      formValues,
    ) {
      const { list = [''] } = formValues;
      const modifiedList = list.filter((listElem: string) => listElem.length);

      return {
        formValues,
        list: modifiedList,
        visibleCriteria: this.state.visibleCriteria,
      };
    },
    calculateVisibleCriteriaNum: function calculateVisibleCriteriaNum(
      formValues = this.props.getOriginalFormValues(),
    ) {
      const visibleCriteria = this.props.calculateVisibleCriteriaNumHelper(
        this.getHeaderCalculationObject(formValues, this.state),
      );

      if (visibleCriteria) {
        this.setState({ visibleCriteria });
      }
    },
    renderHeaderText: function renderHeaderText() {
      const formValues = this.props.listElem.values;
      const { list } = formValues;

      if (this.isListValid(list) && !this.isEmptyCriteria()) {
        return this.props.renderHeaderTextHelper(
          this.getHeaderCalculationObject(formValues, this.state),
        );
      }

      return null;
    },
    renderCriteriaLabel: function renderCriteriaLabel() {
      const { selectedCriteria } = this.props.getOriginalFormValues();
      const selectedCriteriaValues: string[] = this.props.criteriaValues.filter(
        ({ id }: any) => contains(selectedCriteria, id),
      );

      return selectedCriteriaValues.map(({ value }: any) => value).join(', ');
    },
  },
});

export default ParticipantsCriteriaView;
