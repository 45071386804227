import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

type Props = {
  children: ReactNode,
  section: string,
  staticView: boolean,
}

export default function MainBody(props: Props) {
  const { children, section, staticView } = props;
  const classes = cx(
    `main-body${staticView ? '-static' : ''}`,
    `main-body-${section}`,
  );

  return (
    <section className={classes}>
      <div className="main-body-inner">
        {children}
      </div>
    </section>
  );
}

MainBody.propTypes = {
  children: PropTypes.node.isRequired,
  section: PropTypes.string.isRequired,
  staticView: PropTypes.bool,
};

MainBody.defaultProps = {
  staticView: false,
};
