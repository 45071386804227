/* eslint-disable filenames/match-regex */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
/* eslint-enable filenames/match-regex */

import { kebabCase } from 'lodash';
import { Asset } from 'global/types';

export type Props = {
  isSelected: boolean
  searchId: string
  assetId: string,
  asset: Asset,
  updateAssociatedReviewStatus: Function
  setStatus: Function,
  contextTree: {
    REVIEW_STATUSES: {
      NEEDS_REVIEW: string,
      REVIEWED: string
    },
  },
  getAssociatedReviewStatus: Function
}

export function viewLogic(props: Props) {
  const {
    asset: {
      data: { reviewStatus },
    },
    contextTree: {
      REVIEW_STATUSES: { NEEDS_REVIEW, REVIEWED },
    },
  } = props;

  // We only show needs review and revieved boxes in the asset's view review tab
  const reviewBoxesProps = [NEEDS_REVIEW, REVIEWED].map((status) => {
    const isActive = reviewStatus === status;

    return {
      status,
      isActive,
      imageStatus: isActive ? 'selected' : 'unselected',
      kebabCaseStatus: kebabCase(status),
    };
  });

  const isAssetUpdating = props.asset.isUpdating || props.asset.isLoading;

  return {
    ...props,
    reviewBoxesProps,
    isAssetUpdating,
  };
}

export function stateLogic() {
  return function ReviewTabStateLogicComponent(component: any) {
    class ReviewTabStateLogic extends Component<Props> {
      static propTypes = {
        searchId: PropTypes.string.isRequired,
        assetId: PropTypes.string.isRequired,
        asset: PropTypes.shape({
          data: PropTypes.shape({
            reviewStatus: PropTypes.string,
          }),
        }).isRequired,
        updateAssociatedReviewStatus: PropTypes.func.isRequired,
      };

      static getDerivedStateFromProps(nextProps: Props, prevState: any) {
        if (nextProps.isSelected && !prevState.assetReviewStatusLoaded) {
          const { searchId, assetId } = nextProps;

          nextProps.getAssociatedReviewStatus({ assetId, searchId });

          return { assetReviewStatusLoaded: true };
        }

        return null;
      }

      constructor(props: Props) {
        super(props);

        this.state = { assetReviewStatusLoaded: false };
      }

      setStatus = (newStatus: string) => () => {
        const {
          searchId,
          assetId,
          asset: {
            data: { reviewStatus },
          },
        } = this.props;

        if (searchId) {
          let modifiedNewStatus = newStatus;

          if (reviewStatus === newStatus) {
            modifiedNewStatus = '';
          }

          this.props.updateAssociatedReviewStatus({
            assetId,
            params: {
              new_state: modifiedNewStatus,
              search_id: searchId,
            },
          });
        }

        return true;
      };

      render() {
        return React.createElement(component, {
          ...this.props,
          ...this.state,
          setStatus: this.setStatus,
        });
      }
    }

    return ReviewTabStateLogic;
  };
}
