import uri from 'urijs';
import { snakeCase } from 'lodash';
import { browserHistory } from 'react-router';
import { jsUi as buildJSUI } from 'view-components/lib/url';
import { UI } from '../../../../../config/routes/view-frontend.json';

import uiPrefix from './uiPrefix';
import developerInterface from './developerInterface';

const jsUi = buildJSUI(UI, (path: string) => uiPrefix(path), browserHistory);

const ROUTES: any = {};
Object.keys(jsUi).forEach((key) => {
  ROUTES[snakeCase(key).toUpperCase()] = key;
});

jsUi.loginWithRedirect = {
  path: (overrideRedirectTo = null, currentHref = window.location.href) => {
    let query = '';
    let redirectTo = null;

    if (overrideRedirectTo) {
      redirectTo = overrideRedirectTo;
    } else {
      const currentUrl = uri(currentHref);
      redirectTo = currentUrl.search(true).redirect_to;
    }

    if (redirectTo) {
      query = `?redirect_to=${redirectTo}`;
    }

    return jsUi.login.path() + query;
  },
  goTo: () => {
    browserHistory.push(jsUi.loginWithRedirect.path());
  },
};

developerInterface(
  {
    getUIRoutes: function getUIRoutes() {
      return { jsUi, ROUTES };
    },
  },
  true,
);

export { jsUi, ROUTES };
