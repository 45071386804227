/* eslint-disable filenames/match-regex */
/* eslint-disable react/sort-comp */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isArray, isString, has, isEmpty } from 'lodash';
import { datesConfig } from 'component-utilities/dates/dates';

import TooltipGrayIcon from 'v-c/Icons/TooltipGrayIcon';
import Tooltip from 'v-c/Tooltip/Tooltip';

import BasicInfoTab from './Tabs/BasicInfoTab';
import EmailDomainsTab from './Tabs/EmailDomainsTab';

import validDomain from '../lib/validDomain';

import {
  commonViewLogicSetup,
  commonViewLogicButtonReturn,
} from '../lib/commonViewLogic.ts';
import { Account } from 'global/types';

function getTabs() {
  const definedTabs = [
    { id: 'basic_info', component: BasicInfoTab },
    { id: 'email_domains', component: EmailDomainsTab },
  ];

  return definedTabs;
}

type Props = {
  isUpdating: Boolean,
  getMessage: Function,
  makeFieldsUndirty: Function,
  getChangedFieldValues: Function,
  isFlashVisible: Boolean,
  fields: {
    domains: {
      value: any
    }
  },
  isUpdated: Boolean,
  resetFlash: Function,
  updateAccountSettings: Function,
  setTimezone: Function,
  showUpdateAccountSettingsDialog: Function,

};

export function getInitialFields(account: Account) {
  const {
    accountName,
    siteName,
    server,
    domains,
    timezone,
    mailboxCount,
    contacts,
  } = account;

  const {
    country,
    address1,
    address2,
    city,
    organization,
    phone,
    state,
    zip,
    firstName,
    lastName,
    type,
    email,
  } = contacts[0];

  const fixedTimezone = isString(timezone)
    ? timezone
    : datesConfig.DEFAULT_TIMEZONE;
  const domain = server.split('.');
  domain.shift();

  return {
    accountName,
    siteName,
    domain: domain.join('.'),
    timezone: fixedTimezone,
    domains,
    country,
    address1,
    address2,
    city,
    organization,
    phone,
    state,
    zip,
    firstName,
    lastName,
    mailboxCount,
    type,
    email,
  };
}

// TODO: don't return React components here, return data that can
// be transformed into react components.
export function translateTabData(props: Props, { tabs }: any) {
  const { getMessage } = props;

  return tabs.map((tabData: any) => ({
    content: React.createElement(tabData.component, props),
    id: tabData.id,
    label: (
      <data>
        {getMessage(`app.settings.account_settings.tabs.${tabData.id}`)}
        {tabData.id === 'email_domains' && (
          <Tooltip
            position="right"
            type="medium"
            content={getMessage(
              'app.settings.account_settings.tooltips.email_domains',
            )}
          >
            <TooltipGrayIcon />
          </Tooltip>
        )}
      </data>
    ),
  }));
}

export function viewLogic(props: Props = {}) {
  const { isCancelButtonEnabled } = commonViewLogicSetup(props);

  const { isUpdating, fields } = props;

  const nameInDomain = (domain: any) => domain.domainName;

  const isEmptyFields = [
    'firstName',
    'lastName',
    'phone',
    'address1',
    'city',
    'zip',
  ].some(field => !fields[field].value.length);

  const isSaveButtonEnabled =
    isCancelButtonEnabled &&
    isArray(fields.domains.value) &&
    !isEmptyFields &&
    fields.domains.value.every(d => validDomain(nameInDomain(d))) &&
    !isEmpty(fields.domains.value);

  return {
    ...commonViewLogicButtonReturn({
      isSaveButtonEnabled,
      isCancelButtonEnabled,
      isUpdating,
      props,
    }),
    tabs: getTabs(),
  };
}

export function stateLogic() {
  return function AccountSettingComposer(component: any) {
    return class AccountSettingsStateLogic extends Component<Props> {
      static propTypes = {
        makeFieldsUndirty: PropTypes.func.isRequired,
        getChangedFieldValues: PropTypes.func.isRequired,
        isFlashVisible: PropTypes.bool.isRequired,
        fields: PropTypes.object.isRequired,
        isUpdated: PropTypes.bool.isRequired,
        resetFlash: PropTypes.func.isRequired,
        updateAccountSettings: PropTypes.func.isRequired,
        setTimezone: PropTypes.func.isRequired,
        showUpdateAccountSettingsDialog: PropTypes.func.isRequired,
      };

      static displayName = 'AccountSettings/artifact.stateLogic';

      componentDidUpdate(prevProps: Props) {
        if (this.props.isUpdated && !prevProps.isUpdated) {
          this.props.makeFieldsUndirty();
        }
      }

      onFormChange = () => {
        if (this.props.isFlashVisible) {
          this.props.resetFlash({ from: 'AccountSettingsDeconstructed' });
        }
      };

      onUpdate = () => {
        this.props.showUpdateAccountSettingsDialog({
          confirm: this.onConfirmUpdateAccount,
        });
      };

      onConfirmUpdateAccount = () => {
        const changedValues = this.props.getChangedFieldValues();
        const contact = {};
        [
          'country',
          'address1',
          'address2',
          'city',
          'organization',
          'phone',
          'state',
          'zip',
          'firstName',
          'lastName',
          'type',
          'email',
        ].forEach((contactField) => {
          if (has(changedValues, contactField)) {
            delete changedValues[contactField];
          }

          contact[contactField] = this.props.fields[contactField].value;
        });

        changedValues.contacts = [contact];

        if (changedValues.domains) {
          changedValues.domains = changedValues.domains.filter(
            (domain: any) => domain.domainName.length,
          );
        }

        if (changedValues.timezone) {
          this.props.setTimezone(changedValues.timezone);
        }

        this.props.updateAccountSettings(changedValues);
      };

      render() {
        return React.createElement(component, {
          ...this.props,
          onFormChange: this.onFormChange,
          onConfirmUpdateAccount: this.onConfirmUpdateAccount,
          onUpdate: this.onUpdate,
        });
      }
    };
  };
}
