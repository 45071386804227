import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalText from 'v-c/Modals/ModalText';
import { StackedCheckboxContainer, LargeCheckbox } from 'v-c/Forms/Checkboxes';

import { FormattedHTMLMessage } from 'component-utilities/intl';

import withBulkUserBaseProps from '../withBulkUserBaseProps';
import Header from '../../../../global/components/Modals/BulkToggleUsers/Header';
import ErrorContent from '../../../../global/components/Modals/BulkToggleUsers/ErrorContent';
import Buttons from '../../../../global/components/Modals/BulkToggleUsers/Buttons.tsx';

import {
  ModalContainer,
  ModalContentCenter,
} from '../../../../global/components/Modals';

const BASE_KEY = 'app.users.bulk_enable_modal';

type State = {
  sendWelcomeEmail: boolean
}

type Props = {
  resetUsersBulkOperations: Function,
  closeModal: Function,
  modalOptions: {
    multiSelect: {},
    filters: string,
    userCount: string
  },
  getMessage: Function,
  hasErrors: boolean,
  isProcessing: boolean,
  bulkEnableUsers: Function
}

@withBulkUserBaseProps
export default class BulkEnableUsersModal extends Component<Props, State> {
  static propTypes = {
    ...withBulkUserBaseProps.propTypes,
    closeModal: PropTypes.func.isRequired,
    modalOptions: PropTypes.shape({
      multiSelect: PropTypes.object.isRequired,
      filters: PropTypes.string,
    }).isRequired,
    resetUsersBulkOperations: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    props.resetUsersBulkOperations();

    this.state = { sendWelcomeEmail: false };
  }

  toggleWelcomeEmail = () => {
    this.setState({ sendWelcomeEmail: !this.state.sendWelcomeEmail });
  };

  bulkEnable = () => {
    const { multiSelect, filters } = this.props.modalOptions;
    this.props.bulkEnableUsers(multiSelect, { ...this.state, filters });
  };

  render() {
    const {
      getMessage,
      hasErrors,
      isProcessing,
      modalOptions: { multiSelect, userCount },
    } = this.props;
    const { sendWelcomeEmail } = this.state;

    return (
      <ModalContainer isProcessing={isProcessing}>
        <Header
          type="users-enable"
          BASE_KEY={BASE_KEY}
          hasErrors={hasErrors}
          userCount={userCount}
          multiSelect={multiSelect}
          hideModal={() =>
            this.props.closeModal({ from: 'BulkEnableUsersModal/Header' })
          }
        />
        {hasErrors && (
          <ModalContentCenter>
            <ErrorContent BASE_KEY={BASE_KEY} />
          </ModalContentCenter>
        )}
        {!hasErrors && (
          <ModalContentCenter>
            <ModalText>
              <FormattedHTMLMessage
                id={`${BASE_KEY}.body`}
                values={{ count: userCount }}
              />
              <StackedCheckboxContainer>
                <LargeCheckbox
                  data-action="send-welcome-email"
                  checked={sendWelcomeEmail}
                  onClick={this.toggleWelcomeEmail}
                  rawChildren
                >
                  <ModalText checkbox>
                    {getMessage(`${BASE_KEY}.send_welcome_email`)}
                  </ModalText>
                </LargeCheckbox>
              </StackedCheckboxContainer>
            </ModalText>
          </ModalContentCenter>
        )}
        <Buttons
          hasErrors={hasErrors}
          BASE_KEY={BASE_KEY}
          getMessage={getMessage}
          okAction={this.bulkEnable}
          hideModal={() =>
            this.props.closeModal({ from: 'BulkEnableUsersModal/Buttons' })
          }
        />
      </ModalContainer>
    );
  }
}
