/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import withMessages, { intlPropTypes } from 'component-utilities/intl';
import VCBreadcrumb from 'v-c/Navigation/Breadcrumb';

import { jsUi, ROUTES } from '../lib/jsUi';

import { runModifiedSavedSearch } from '../state_managers/UnsavedSearchStateManager';

import styles from './Breadcrumb.scss';
import { intlShape, Params, ReduxState, Search } from 'global/types';

interface Props extends intlShape {
  currentSavedSearch: Search,
  currentHoldReason?: Function,
  savedSearchID?: string,
  returnCount?: number,
  breadcrumbs: {
    route: string[],
    isRoot: boolean,
  },
  searchName?: string,
  searchId?: string,
  showZeroResults?: boolean,
  isProcessing?: boolean,
  unsavedSearch: Search,
  runModifiedSavedSearch: Function,
  params: Params
}

const mapStateToProps = ({ unsavedSearch, breadcrumbs }: ReduxState) => ({
  unsavedSearch,
  breadcrumbs,
});
const mapActionsToProps = {
  runModifiedSavedSearch,
};

@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withMessages
export default class Breadcrumb extends Component<Props> {
  static propTypes = {
    currentSavedSearch: PropTypes.object,
    currentHoldReason: PropTypes.func,
    savedSearchID: PropTypes.string,
    returnCount: PropTypes.any,
    breadcrumbs: PropTypes.shape({
      route: PropTypes.array.isRequired,
      isRoot: PropTypes.bool.isRequired,
    }).isRequired,
    searchName: PropTypes.string,
    searchId: PropTypes.string,
    showZeroResults: PropTypes.bool,
    isProcessing: PropTypes.bool,
    unsavedSearch: PropTypes.shape({
      clientData: PropTypes.object,
    }).isRequired,
    runModifiedSavedSearch: PropTypes.func.isRequired,
    ...intlPropTypes,
  };

  static defaultProps = {
    showZeroResults: false,
    isProcessing: false,
    currentSavedSearch: null,
    currentHoldReason: null,
    savedSearchID: null,
    returnCount: null,
    searchName: null,
    searchId: null,
  };

  createAttributes(breadcrumb: string) {
    let attributes: any;
    let breadcrumbValue;

    // TODO: turn these into proper child components that pass in
    // behaviors, rather than generating different, complex
    // attributes structures.
    const createReturnToSearchResultsAttributes = () => {
      const { searchName, searchId } = this.props;
      attributes = {
        'data-value': 'search_name',
        'data-breadcrumb': 'search-name',
        'data-action': 'return-to-search-results',
      };

      if (searchName) {
        attributes.onClick = () => {
          jsUi.resultsIndex.goTo({ id: searchId });
        };
        breadcrumbValue = searchName;
      } else {
        const {
          clientData: { type },
        } = this.props.unsavedSearch.data;

        attributes['data-unsaved-search'] = true;
        attributes.onClick =
          type === 'simple' || type === 'advanced'
            ? jsUi.newSearchResultsIndex.goTo
            : jsUi.newSearchIndex.goTo;
        breadcrumbValue = this.props.getMessage('app.breadcrumb.new_search');
      }
    };

    const createReturnToHoldReasonsResultsAttributes = () => {
      const { currentHoldReason, params } = this.props;

      if (currentHoldReason) {
        const { enumId, name } = currentHoldReason(params.id);

        attributes = {
          'data-value': 'hold_reason_name',
          'data-action': 'return-to-hold-reason-assets',
          title: name,
          to: jsUi.holdReasonsShowIndex.path({ id: enumId }),
        };

        breadcrumbValue = name;
      }
    };

    const createSavedSearchDetailsAttributes = () => {
      const { savedSearchID, currentSavedSearch } = this.props;

      if (savedSearchID || currentSavedSearch) {
        const type = 'saved-search-details';
        const searchID = savedSearchID || currentSavedSearch.search.id;

        attributes = {
          'data-breadcrumb': type,
          'data-action': type,
          to: jsUi.searchesShow.path({ search_id: searchID }),
        };

        breadcrumbValue = this.props.getMessage('app.breadcrumb.details');
      }
    };

    const createModifiedSavedSearchAttributes = () => {
      const { currentSavedSearch, unsavedSearch } = this.props;

      if (currentSavedSearch) {
        const searchData = {
          query: unsavedSearch.data.query,
          clientData: unsavedSearch.data.clientData,
        };
        const searchID = currentSavedSearch.search.id;
        const type = 'modified_search_results';
        attributes = {
          'data-breadcrumb': type,
          'data-action': type,
          onClick: () => this.props.runModifiedSavedSearch(searchData, searchID),
        };

        breadcrumbValue = this.props.getMessage(
          'app.breadcrumb.unsaved_results',
        );
      }
    };

    switch (breadcrumb) {
      case 'RETURN_TO_SEARCH_RESULTS': {
        createReturnToSearchResultsAttributes();
        break;
      }
      case 'RETURN_TO_HOLD_REASON_RESULTS': {
        createReturnToHoldReasonsResultsAttributes();
        break;
      }
      case 'SAVED_SEARCH_DETAILS': {
        createSavedSearchDetailsAttributes();
        break;
      }
      case 'MODIFIED_SEARCH_RESULTS': {
        createModifiedSavedSearchAttributes();
        break;
      }
      default: {
        const targets = [
          { name: 'SEARCHES', target: ROUTES.SEARCHES_INDEX },
          { name: 'NEW_SEARCH', target: ROUTES.SEARCHES_INDEX },
          { name: 'HOLD_REASONS', target: ROUTES.HOLD_REASONS_INDEX },
          { name: 'USER_MANAGEMENT', target: ROUTES.USERS_INDEX },
          { name: 'SETTINGS', target: ROUTES.SETTINGS_INDEX },
          { name: 'LISTS', target: ROUTES.SETTINGS_SEARCH_LISTS_INDEX },
          { name: 'AD_SYNC', target: ROUTES.SETTINGS_AD_SYNC_INDEX },
          { name: 'REPORTS', target: ROUTES.SETTINGS_REPORTS_INDEX },
          { name: 'COLLECTORS', target: ROUTES.SETTINGS_COLLECTORS_INDEX },
        ];

        const { target }: any = targets.find(({ name }) => name === breadcrumb);
        const attrValue = breadcrumb.toLowerCase();

        attributes = {
          'data-breadcrumb': attrValue,
          'data-action': attrValue,
          to: jsUi[target].path(),
        };

        breadcrumbValue = this.props.getMessage(`app.breadcrumb.${attrValue}`);
      }
    }

    return { attributes, breadcrumbValue };
  }

  renderBreadcrumb = (breadcrumb: string) => {
    const { attributes, breadcrumbValue } = this.createAttributes(breadcrumb);

    if (attributes) {
      return {
        text: breadcrumbValue,
        routeName: attributes['data-breadcrumb'],
        ...attributes,
      };
    }

    return { empty: true };
  };

  render() {
    const { route, isRoot } = this.props.breadcrumbs;

    if (isRoot) {
      let message = this.props.getMessage(
        `app.breadcrumb.${route[0].toLowerCase()}`,
      );
      const attributes = {
        'data-breadcrumb': route[0].toLowerCase(),
      };
      const { returnCount, showZeroResults, isProcessing } = this.props;

      if (
        !isProcessing &&
        (returnCount || (Number.isInteger(returnCount) && showZeroResults))
      ) {
        message += ` (${returnCount})`;
        attributes['data-return-count'] = returnCount;
      }

      return (
        <div className={styles['app-breadcrumb']} data-field="app-breadcrumb">
          <span className={styles.title} {...attributes}>
            {message}
          </span>
        </div>
      );
    }

    return (
      <div className={styles['app-breadcrumb']} data-field="app-breadcrumb">
        <VCBreadcrumb
          nodes={route.map(this.renderBreadcrumb).concat([{ empty: true }])}
        />
      </div>
    );
  }
}
