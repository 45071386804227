import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';

import withMessages, { intlPropTypes } from 'component-utilities/intl';
import { InputField } from 'v-c/Forms';

import LoadWhenReady from '../../global/lib/LoadWhenReady';
import withForm, { formHelperPropTypes } from '../../global/lib/formHelper';
import routeLeaveHook from '../../global/lib/routeLeaveHook';
import dialogHandler from '../../global/lib/dialogHandler';

import Loading from '../../global/components/Loading';
import Breadcrumb from '../../global/components/Breadcrumb';
import HeadlineLabel from '../../global/components/HeadlineLabel';
import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';

import { resetFlash } from '../../global/state_managers/FlashMessagesStateManager';
import { showUnsavedRetentionPoliciesDialog } from '../../global/state_managers/DialogStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import validateSettingsAccess from './components/validateSettingsAccess';

import {
  getInitialFields,
  stateLogic,
  viewLogic,
} from './Policies/policiesArtifact';

import {
  load as loadRetentionPolicy,
  update as updateRetentionPolicy,
} from '../../global/state_managers/RetentionPolicyStateManager';

import styles from './components/Settings.scss';

const BASE_KEY = 'app.settings.policies';

type Props = {
  onUpdate: Function,
  getMessage: Function,
  setFields: Function,
  isFlashVisible: Boolean,
  retentionPolicy: {
    isUpdating: Boolean,
    policy: { retentionPolicyId: string; },
    isLoaded: Boolean
  },
  resetFlash: Function,
  updateRetentionPolicy: Function,
  loggedInAsUserId: number,
  makeFieldsUndirty: Function,
  getFieldValues: Function,
  fields: {
    enabled: {
      toggle: Boolean
    },
    days: number
  },
  isDirtyFields: Boolean,
  onDayChange: Function,
  onMonthChange: Function,
  onYearChange: Function,
  retryTransition: () => boolean,
  setRouterWillLeaveValidator: Function,
  registerFieldsChangeCallback: Function,
  onFormChange: Function,
  loadRetentionPolicy: Function,
  setBreadcrumbForSettingsPoliciesPage: Function,
}

const Content = Loadable.Map({
  loader: {
    WidgetComponents: () => import('v-c/Widgets/Components'),
    FormComponents: () => import('v-c/Forms'),
    CheckboxesComponents: () => import('v-c/Forms/Checkboxes'),
    ButtonsComponents: () => import('v-c/Buttons/Components'),
    TooltipGrayIcon: () => import('v-c/Icons/TooltipGrayIcon'),
    Tooltip: () => import('v-c/Tooltip/Tooltip'),
  },
  loading() {
    return <Loading />;
  },
  render(loaded, props: Props) {
    const {
      isDaysUnit,
      areDropdownsDisabled,
      updateButton,
      selectedYear,
      selectedMonth,
      isPolicyEnabled,
      isContentVisible,
      yearsDropdownValue,
      monthsDropdownValue,
    } = viewLogic(props);
    const { getMessage } = props;
    const { 
      WidgetComponents: {
        TextLabel 
      },
      FormComponents: {
        DropdownSmall, FormText
      },
      CheckboxesComponents: {
        LargeCheckbox
      },
      ButtonsComponents: {
        PrimaryButton
      },
      TooltipGrayIcon: {
        default: TooltipGrayIcon
      },
      Tooltip: {
        default: Tooltip
      }
    } = loaded;

    function renderMonthsUnit() {
      return <>
        <tr>
          <td>
            <TextLabel className={styles.label}>
              {getMessage('app.general.years')}
            </TextLabel>
          </td>
          <td>
            <DropdownSmall
              id="years"
              disabled={areDropdownsDisabled}
              selectedId={selectedYear}
              onSelect={props.onYearChange}
              data={yearsDropdownValue} />
          </td>
        </tr>
        <tr>
          <td>
            <TextLabel className={styles.label}>
              {getMessage('app.general.months')}
            </TextLabel>
          </td>
          <td>
            <DropdownSmall
              id="months"
              disabled={areDropdownsDisabled}
              selectedId={selectedMonth}
              onSelect={props.onMonthChange}
              data={monthsDropdownValue} />
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <div className={styles['policies-hint']}>
              <FormText>
                {getMessage(`${BASE_KEY}.hint_text`)}
              </FormText>
            </div>
          </td>
        </tr>
      </>
    }

    function renderDaysUnit() {
      return <tr>
        <td>
          <TextLabel className={styles.label}>
            {getMessage('app.general.days')}
          </TextLabel>
        </td>
        <td>
          <InputField
            type="number"
            data-field="policies-days"
            placeholder="5"
            value={props.fields.days.value}
            status={areDropdownsDisabled ? "disabled" : ""}
            onChange={props.onDayChange}
            />
        </td>
      </tr>
    }

    return (
      <div data-content="content">
        {isContentVisible && (
          <div section="policies" className={styles.content}>
            <div>
              <TextLabel className={styles['main-label']}>
                {getMessage(`${BASE_KEY}.email_policy`)}
              </TextLabel>
              <Tooltip
                position="right"
                type="medium"
                content={getMessage(`${BASE_KEY}.email_policy_tooltip`)}
              >
                <TooltipGrayIcon />
              </Tooltip>
            </div>
            <table>
              <tbody>
                <tr>
                  <td className={styles['table-first-column']}>
                    <TextLabel className={styles.label}>
                      {getMessage(`${BASE_KEY}.enable_retention`)}
                    </TextLabel>
                  </td>
                  <td>
                    <LargeCheckbox
                      id="retention"
                      data-field="confirm-retention"
                      onClick={props.fields.enabled.toggle}
                      checked={isPolicyEnabled}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <TextLabel className={styles['main-label']}>
                      {getMessage(`${BASE_KEY}.retention_duration`)}
                    </TextLabel>
                  </td>
                </tr>
                { !isDaysUnit && renderMonthsUnit() }
                { isDaysUnit && renderDaysUnit() }
              </tbody>
            </table>
            <PrimaryButton
              data-action="update_policies"
              extraClassName={styles['update-button']}
              onClick={props.onUpdate}
              {...updateButton}
            >
              {getMessage('app.button.update')}
            </PrimaryButton>
          </div>
        )}
      </div>
    );
  }
});

const mapStateToProps = ({
  flashMessages,
  retentionPolicy,
  authentication,
}: any) => ({
  loggedInAsUserId: authentication.loggedInAs.userId,
  isFlashVisible: !!flashMessages.indicators.length,
  retentionPolicy,
});

const mapActionsToProps = {
  resetFlash,
  loadRetentionPolicy,
  updateRetentionPolicy,
  setBreadcrumbForSettingsPoliciesPage: breadcrumbsActions.settingsPoliciesPage,
};

@routeLeaveHook
@withMessages
@flashConnect(PAGES.SETTINGS_RETENTION_POLICY, 'Settings/Policies')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@withForm({
  form: 'retention-policy',
  fields: ['enabled', 'years', 'months'],
  onNextProps: (props, nextProps, setFields) => {
  if (
    props.retentionPolicy.isLoading &&
    !nextProps.retentionPolicy.isLoading
  ) {
  setFields(getInitialFields(nextProps.retentionPolicy.policy));
  }
  },
  })
@stateLogic({})
@validateSettingsAccess('settings.policies')
export default class Policies extends Component<Props> {
  static propTypes = {
    ...intlPropTypes,
    ...formHelperPropTypes,
    retentionPolicy: PropTypes.object.isRequired,
    loadRetentionPolicy: PropTypes.func.isRequired,
    setBreadcrumbForSettingsPoliciesPage: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    props.setBreadcrumbForSettingsPoliciesPage();
    this.props.loadRetentionPolicy();

    props.registerFieldsChangeCallback(props.onFormChange);
    props.setRouterWillLeaveValidator(this.routerWillLeave);
  }

  routerWillLeave = () => {
    if (!this.props.isDirtyFields) {
      return true;
    }

    dialogHandler(
      showUnsavedRetentionPoliciesDialog,
      this.props.retryTransition,
    );

    return false;
  };

  render() {
    const { getMessage, fields } = this.props;

    return (
      <LoadWhenReady readyStates={[this.props.retentionPolicy.isLoaded]}>
        <div data-view="settings-policies">
          <div data-content="header">
            <header>
              <FlashMessages />
              <Breadcrumb />
              <HeadlineLabel text={getMessage(`${BASE_KEY}.tab_name`)} />
            </header>
          </div>
          <Content { ...this.props } />
        </div>
      </LoadWhenReady>
    );
  }
}
