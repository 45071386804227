/* eslint-disable filenames/match-regex */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { truncate, isUndefined } from 'lodash';
import { formatTZDate, DATE_FORMATS } from 'component-utilities/dates/dates';

import DropdownBox from 'v-c/Dropdowns/DropdownBox';
import ExportDropdown from 'v-c/Dropdowns/ExportDropdown';
import ExportLoadingDropdown from 'v-c/Dropdowns/ExportLoadingDropdown';
import ExportCompleteDropdown from 'v-c/Dropdowns/ExportCompleteDropdown';

import appConfig from '../../../../../../config/app.json';
import { jsUi } from '../../lib/jsUi';

import Processing from '../Processing';
import ExportOldDropdown from './ExportOldDropdown';
import { Job, SearchResults, Search, HoldReason, MultiSelect, Asset, Account } from 'global/types';

const { EXPORT } = appConfig.JOBS;

const ACTIVE_FILE_TYPES = {
  EML: 'eml',
  PST: 'pst',
  PDF: 'pdf',
};

type Props = {
  currentHoldReason: HoldReason,
  currentSavedSearch: Search,
  exportJobList: Job[],
  newExportsDropdownEnabled: boolean,
  returnCount: number,
  searchId: string,
  searchResults: SearchResults,
  multiSelect: MultiSelect,
  exportRequest: Function,
  resetFlash: Function,
  loggedInAsEmail: string,
  onActionClick: Function,
  isProcessing: boolean,
  isJobsLoading: boolean
  isAccountLoading: boolean
  account: Account,
  hasAllSelected: boolean,
  appendTimestampTo: Function,
  assetsSelected: Asset[],
  checkCompleteDropdownView: Function,
  completeDropdownView: {
    show: boolean
    success: boolean
    duplicated: boolean
  },
  currentSearchName: string,
  currentUserEmail: string,
  exportNames: string[],
  getButtonText: Function,
  getMessage: Function,
  goToExportsPage: Function,
  isInitialDataLoading: boolean,
  onExportSelected: Function,
  onExportSubmit: Function,
  onOldExportSubmit: Function,
  selectedFileType: string,
  shouldRenderOldDropdown: boolean,
}

export function stateLogic() {
  return (WrappedComponent: any) =>
    class extends Component<Props> {
      static displayName = 'Export/StateLogic';

      static propTypes = {
        account: PropTypes.object.isRequired,
        currentHoldReason: PropTypes.object,
        currentSavedSearch: PropTypes.object,
        exportJobList: PropTypes.array.isRequired,
        newExportsDropdownEnabled: PropTypes.bool.isRequired,
        returnCount: PropTypes.number.isRequired,
        searchId: PropTypes.string.isRequired,
        searchResults: PropTypes.object.isRequired,
        multiSelect: PropTypes.object.isRequired,
        exportRequest: PropTypes.func.isRequired,
        resetFlash: PropTypes.func.isRequired,
        loggedInAsEmail: PropTypes.string.isRequired,
        onActionClick: PropTypes.func.isRequired,
        isProcessing: PropTypes.bool.isRequired,
      };

      static defaultProps = {
        currentHoldReason: null,
        currentSavedSearch: null,
      };

      constructor(props: Props) {
        super(props);

        let selectedFileType = '';

        if (this.shouldRenderOldDropdown) {
          props.resetFlash({ from: 'ExportDeconstructed/Mount' });
          selectedFileType = ACTIVE_FILE_TYPES.EML;
        }

        this.state = { selectedFileType };
      }

      componentDidUpdate(prevProps: Props) {
        if (
          this.shouldRenderOldDropdown &&
          prevProps.isProcessing &&
          !this.props.isProcessing
        ) {
          this.props.onActionClick();
        }
      }

      onExportSelected = (type: string) => () => {
        this.setState({ selectedFileType: type });
      };

      onSubmit = (data: any) => {
        this.props.exportRequest(this.props.searchId, data);
      };

      // should go away once new export flow is added to holds
      onOldExportSubmit = () => {
        let checkedAssets = this.props.returnCount;
        let name = this.currentHoldName;
        let query = this.props.searchResults.stream.query;
        let searchType = 'holdReason';

        if (this.props.currentSavedSearch) {
          checkedAssets = this.assetsSelected;
          name = this.currentSearchName;
          query = this.exportQuery;
          searchType = 'savedSearch';
        }

        this.onSubmit({
          checkedAssets,
          query,
          searchType,
          fileType: this.state.selectedFileType,
          name: this.appendTimestampTo(name),
        });
      };

      onExportSubmit = ({ fileFormat, name, sendNotification, userEmail }: any) => {
        this.onSubmit({
          name,
          sendNotification,
          checkedAssets: this.assetsSelected,
          fileType: fileFormat,
          query: this.exportQuery,
          searchType: 'savedSearch',
          notificationRecipients: userEmail,
        });
      };

      get exportQuery() {
        const {
          multiSelect,
          searchResults: {
            stream: { query },
          },
        } = this.props;

        return multiSelect.createDSL(query);
      }

      get exportNames() {
        return this.props.exportJobList.map(({ exportName }) => exportName);
      }

      get currentSearchName() {
        const { currentSavedSearch } = this.props;

        if (!currentSavedSearch) {
          return false;
        }
        return currentSavedSearch.search.name;
      }

      get currentHoldName() {
        const { currentHoldReason } = this.props;

        if (!currentHoldReason) {
          return false;
        }
        return this.props.currentHoldReason.name;
      }

      get timestamp() {
        return formatTZDate(
          new Date(),
          DATE_FORMATS.EXPORT_TIMESTAMP,
          this.props.account.timezone,
        );
      }

      get shouldRenderOldDropdown() {
        return !this.props.newExportsDropdownEnabled || !this.currentSearchName;
      }

      get hasAllSelected() {
        const { isAllSelected, selectedRows } = this.props.multiSelect;

        return isAllSelected && !selectedRows.length;
      }

      get assetsSelected() {
        const {
          returnCount,
          multiSelect: { isAllSelected, selectedRows },
        } = this.props;

        if (isAllSelected) {
          return returnCount - selectedRows.length;
        }

        return selectedRows.length;
      }

      appendTimestampTo = (name: string) => {
        const timestamp = ` ${this.timestamp}`;
        const length = EXPORT.NAME_CHARACTER_LIMIT - timestamp.length;
        return `${truncate(name, { length })}${timestamp}`;
      };

      isActionDisabled = () => {
        const {
          searchResults: { isLoading, results = [] },
        } = this.props;

        return isLoading || (!isLoading && !results.length);
      };

      render() {
        return (
          <WrappedComponent
            {...this.state}
            {...this.props}
            appendTimestampTo={this.appendTimestampTo}
            assetsSelected={this.assetsSelected}
            currentSearchName={this.currentSearchName}
            currentUserEmail={this.props.loggedInAsEmail}
            exportNames={this.exportNames}
            goToExportsPage={jsUi.exports.goTo}
            hasAllSelected={this.hasAllSelected}
            isActionDisabled={this.isActionDisabled}
            onExportSelected={this.onExportSelected}
            onExportSubmit={this.onExportSubmit}
            onOldExportSubmit={this.onOldExportSubmit}
            shouldRenderOldDropdown={this.shouldRenderOldDropdown}
          />
        );
      }
    };
}

export function viewLogic(props: Props) {
  const isInitialDataLoading =
    props.isJobsLoading ||
    props.isAccountLoading ||
    isUndefined(props.account.exportLimit);
  const isProcessing = props.isProcessing;

  return {
    isInitialDataLoading,
    isProcessing,
  };
}

export function viewContents(props: Props) {
  const {
    account: { exportLimit },
    hasAllSelected,
    appendTimestampTo,
    assetsSelected,
    checkCompleteDropdownView,
    completeDropdownView,
    currentSearchName,
    currentUserEmail,
    exportNames,
    getButtonText,
    getMessage,
    goToExportsPage,
    isInitialDataLoading,
    isProcessing,
    onActionClick,
    onExportSelected,
    onExportSubmit,
    onOldExportSubmit,
    selectedFileType,
    shouldRenderOldDropdown,
  } = props;
  const selectedText = getMessage('app.exports.select_format');
  const processingText = `${getMessage('app.general.processing')}...`;

  if (shouldRenderOldDropdown) {
    return (
      <ExportOldDropdown
        activeFileTypes={ACTIVE_FILE_TYPES}
        getButtonText={getButtonText}
        isProcessing={isProcessing}
        onActionClick={onActionClick}
        onExportSelected={onExportSelected}
        onOldExportSubmit={onOldExportSubmit}
        processingText={processingText}
        selectedFileType={selectedFileType}
        selectedText={selectedText}
      />
    );
  }

  return (
    <div style={{ width: 'auto' }}>
      <DropdownBox>
        <Processing text={processingText} isProcessing={isProcessing} />
        {isInitialDataLoading && <ExportLoadingDropdown />}
        {!completeDropdownView.show &&
          !isInitialDataLoading && (
            <ExportDropdown
              allSelected={hasAllSelected}
              assetLimit={exportLimit}
              assetsSelected={assetsSelected}
              exportNames={exportNames}
              nameCharacterLimit={EXPORT.NAME_CHARACTER_LIMIT}
              onSubmit={onExportSubmit}
              searchName={appendTimestampTo(currentSearchName)}
              userEmail={currentUserEmail}
            />
          )}
        {completeDropdownView.show &&
          !isInitialDataLoading && (
            <ExportCompleteDropdown
              goToExportsPage={goToExportsPage}
              onRetry={checkCompleteDropdownView}
              success={completeDropdownView.success}
              duplicated={completeDropdownView.duplicated}
            />
          )}
      </DropdownBox>
    </div>
  );
}

viewContents.displayName = 'Exports/ViewContents';

viewContents.propTypes = {
  account: PropTypes.object.isRequired,
  hasAllSelected: PropTypes.bool.isRequired,
  appendTimestampTo: PropTypes.func.isRequired,
  assetsSelected: PropTypes.bool.isRequired,
  checkCompleteDropdownView: PropTypes.func.isRequired,
  completeDropdownView: PropTypes.object.isRequired,
  currentSearchName: PropTypes.string.isRequired,
  currentUserEmail: PropTypes.string.isRequired,
  exportNames: PropTypes.array.isRequired,
  goToExportsPage: PropTypes.func.isRequired,
  getButtonText: PropTypes.func.isRequired,
  getMessage: PropTypes.func.isRequired,
  isInitialDataLoading: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  onActionClick: PropTypes.func.isRequired,
  onExportSelected: PropTypes.func.isRequired,
  onExportSubmit: PropTypes.func.isRequired,
  onOldExportSubmit: PropTypes.func.isRequired,
  selectedFileType: PropTypes.string.isRequired,
  shouldRenderOldDropdown: PropTypes.bool.isRequired,
};
