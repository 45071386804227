/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { omit } from 'lodash';

import {
  addLoader,
  resetLoader,
  clearQueue,
  processLoop,
  pushToQueue,
} from '../../global/state_managers/SerialResultsLoaderStateManager';

import reduxStore from '../../global/reduxStore';

import { loadSavedSearches } from '../../global/state_managers/SavedSearchesStateManager';
import { loadUsersPack } from '../../global/state_managers/UsersStateManager';
import { performLoadMoreResults } from '../../global/lib/searchResultsStreamManager';

export const LOAD_TYPES = {
  SEARCH_RESULTS_LOAD_MORE: 'SearchResultsActions.loadMoreResults',
  SAVED_SEARCHES: 'SavedSearchActions.loadMoreResults',
  USERS: 'Users.load',
  USERS_LOAD_MORE: 'Users.loadMoreResults',
};

const LOADERS = [
  {
    type: LOAD_TYPES.SEARCH_RESULTS_LOAD_MORE,
    action: performLoadMoreResults,
  },
  {
    type: LOAD_TYPES.SAVED_SEARCHES,
    action: (...args) => {
      const [offset] = args;
      const { values: { personalArchive } } = reduxStore.getState().UIHelper;
      return reduxStore.dispatch(loadSavedSearches(offset, personalArchive));
    },
  },
  {
    type: LOAD_TYPES.USERS,
    action: (...args) => reduxStore.dispatch(loadUsersPack(...args)),
  },
  { type: LOAD_TYPES.USERS_LOAD_MORE, action: loadUsersPack },
];

export default function withSerialResultsLoader(loaderType) {
  const mapStateToProps = state => ({
    loaders: state.serialResultsLoader,
  });

  const mapActionsToProps = {
    addLoader,
    resetLoader,
    clearQueue,
    processLoop,
    pushToQueue,
    loadSavedSearches,
  };

  return (WrappedComponent) => {
    @connect(mapStateToProps, mapActionsToProps)
    class WithSerialResultsLoader extends Component {
      static propTypes = {
        addLoader: PropTypes.func.isRequired,
        clearQueue: PropTypes.func.isRequired,
        loaders: PropTypes.object.isRequired,
        processLoop: PropTypes.func.isRequired,
        pushToQueue: PropTypes.func.isRequired,
        resetLoader: PropTypes.func.isRequired,
        loadSavedSearches: PropTypes.func.isRequired,
      };

      constructor(props) {
        super(props);

        const { type, action } = this.selectedLoader();

        props.addLoader(type, action);
      }

      componentWillUnmount() {
        this.props.resetLoader(loaderType);
      }

      selectedLoader = () => LOADERS.find(loader => loader.type === loaderType);

      start = () => this.props.pushToQueue(loaderType, 0);

      stop = () => this.props.clearQueue(loaderType);

      pushToQueue = (...args) => this.props.pushToQueue(loaderType, ...args);

      render() {
        const propsToOmit = [
          'addLoader',
          'clearQueue',
          'loaders',
          'processLoop',
          'pushToQueue',
          'resetLoader',
        ];
        const otherProps = omit(this.props, propsToOmit);

        const serialResultsLoader = {
          pushToQueue: this.pushToQueue,
          start: this.start,
          stop: this.stop,
        };

        return (
          <WrappedComponent
            {...otherProps}
            serialResultsLoader={serialResultsLoader}
          />
        );
      }
    }

    return WithSerialResultsLoader;
  };
}
