import CriteriaMultiSelectViewComposer from './Components/CriteriaMultiSelectViewComposer';
import getLocalizedData from '../../../lib/getLocalizedData';

type Props = {
  listElem: {
    values: {
      selectedCriteria: string[]
    }
  }
  form: {}
}

const KeywordListsCriteriaView = CriteriaMultiSelectViewComposer({
  init: function init(props: Props) {
    const { listElem: { values }, form } = props;

    this.criteriaFields = getLocalizedData.apply(this, [
      'advKeywordsValuesSelections',
    ]);

    this.prefixText = props.getMessage('app.criterias.in_the').toLowerCase();
    this.dropdownPrefixText = this.prefixText;

    props.setInitialFormAndOriginalValues(
      values.selectedCriteria
        ? values
        : {
          ...values,
          ...form,
          selectedCriteria: [],
          selectedFields: this.criteriaFields.map(({ id }: any) => id),
          relation: 'and',
          isSeparatedAnyLogic: false,
          isAnySelected: false,
          selectAllOption: false,
        },
    );

    this.state = {
      visibleCriteria: 0,
    };

    this.listResourceName = 'keywordLists';
  },
});

export default KeywordListsCriteriaView;
