import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { filesize } from 'filesize';
import { formatTZDate, DATE_FORMATS } from 'component-utilities/dates/dates';
import withMessages, { FormattedHTMLMessage } from 'component-utilities/intl';

import Loading from '../../global/components/Loading';

import { jsUi } from '../../global/lib/jsUi';
import routeLeaveHook from '../../global/lib/routeLeaveHook';

import fixedHeightContent from '../../global/lib/fixedHeightContent';
import LoadWhenReady from '../../global/lib/LoadWhenReady';

import Breadcrumb from '../../global/components/Breadcrumb';
import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';
import {
  load as loadAccountSettings,
  clear as clearAccountSettings,
} from '../../global/state_managers/AccountSettingsStateManager';
import {
  load as loadRetentionPolicy,
} from '../../global/state_managers/RetentionPolicyStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';
import { getStatus } from "../../global/state_managers/ADSyncStateManager";
import {
  showGeneralFlash,
  resetFlash,
} from '../../global/state_managers/FlashMessagesStateManager';
import reduxStore from '../../global/reduxStore';
import sessionStorage from '../../global/lib/sessionStorage';

import { stateLogic } from './AccountSummaryDeconstructed';
import { isPageRoleEnabled, pageRoles } from '../../global/lib/userRights';
import styles from './styles.scss';
import RetinaImage from 'v-c/RetinaImage';
import alert from 'v-c/Dialog/alert.png';
import { Account, ReduxState } from 'global/types';
import moment from 'moment';

type Props = {
  account: Account
  isAccountLoaded: boolean
  isCollectedNormally: boolean
  lastCollectedDate: string
  retentionPolicy: {
    ttl: number
    ttlUnit: string
    enabled: boolean
  }
  adSyncSetupDone: boolean
  needsAttention: boolean
  getMessage: Function
}

const AccountSummaryComponentLoadable = Loadable({
  loader: () => import('v-c/AccountSummary/AccountSummary'),
  loading() {
    return <Loading />;
  },
  render(loaded: any, props: Props) {
    const {
      default: AccountSummaryComponent,
      AccountSummaryTimestamp,
      AccountSummaryHead,
      AccountInfoHeadBox,
      AccountStatusNormalHeadBox,
      AccountStatusWarningHeadBox,
      AccountSummaryBody,
      StorageSummaryBodyHead,
      AccountSummaryBodyRow,
      TotalEmailsSummaryItem,
      TotalFilesSummaryItem,
      TotalSizeSummaryItem,
      RetentionPolicyBox,
    } = loaded;
    const {
      account: {
        accountName,
        ctime,
        summary: {
          collectionHistory,
          messages = 0,
          attachments = 0,
          bytes = 0,
          modifiedAt,
        },
      },
      isCollectedNormally,
      lastCollectedDate,
      retentionPolicy,
      adSyncSetupDone,
      needsAttention,
      getMessage,
    } = props;
    return (
      <div data-view="account-summary">
        <div data-content="header">
          <FlashMessages />
          <Breadcrumb />
        </div>
        <div data-content="content">
          { adSyncSetupDone && needsAttention && <div className={styles.ad_sync_banner}>
            <RetinaImage image={alert} />
            <div>
              <FormattedHTMLMessage
                id={'app.settings.ad_sync.banner'}
              />
            </div>
          </div> }
          <AccountSummaryComponent>
            <AccountSummaryTimestamp
              date={moment.utc(modifiedAt).format(DATE_FORMATS.LONG)}
            />
            <AccountSummaryHead>
              <AccountInfoHeadBox
                accountName={accountName}
                accountCreated={moment.utc(ctime).format(DATE_FORMATS.SHORT)}
              />
              {(!isCollectedNormally && isPageRoleEnabled(pageRoles.USER_CAN_SEE_COLLECTION_CONFIGURATION)) && (
                <AccountStatusWarningHeadBox
                  lastCollectedDate={lastCollectedDate}
                  onClick={jsUi.settingsCollectorsEmail.goTo}
                  userMessage={false}
                />
              )}
              {(!isCollectedNormally && !isPageRoleEnabled(pageRoles.USER_CAN_SEE_COLLECTION_CONFIGURATION)) && (
                <AccountStatusWarningHeadBox
                  userMessage
                  lastCollectedDate={lastCollectedDate}
                />
              )}
              {isCollectedNormally && (
                <AccountStatusNormalHeadBox
                  withDefaultTitle
                  archivedEmails={collectionHistory.last24Hours}
                />
              )}
            </AccountSummaryHead>
            <AccountSummaryBody>
              <StorageSummaryBodyHead />
              <AccountSummaryBodyRow>
                <TotalEmailsSummaryItem
                  totalEmails={messages.toLocaleString()}
                />
                <TotalFilesSummaryItem
                  totalFiles={attachments.toLocaleString()}
                />
                <TotalSizeSummaryItem
                  totalSize={filesize(bytes, { standard: "jedec" })}
                />
              </AccountSummaryBodyRow>
            </AccountSummaryBody>
          </AccountSummaryComponent>
          <RetentionPolicyBox
            onClick={jsUi.settingsPolicies.goTo}
            value={retentionPolicy.ttl}
            isDaysUnit={retentionPolicy.ttlUnit === "days"}
            status={retentionPolicy.enabled}
            showManageLink={isPageRoleEnabled(pageRoles.USER_CAN_EDIT_RETENTION_POLICY)}
          />
        </div>
      </div>
    );
  },
});

const mapStateToProps = ({ accountSettings, adSync, retentionPolicy, userRights }: ReduxState) => ({
  account: accountSettings.account,
  activeRoles: userRights.activeRoles,
  isAccountLoaded: accountSettings.isLoaded,
  minimalIndexWarning: accountSettings.account.minimalIndexWarning,
  retentionPolicy: retentionPolicy.policy,
  adSyncSetupDone: adSync.setupDone,
  needsAttention: adSync.appCredentials && adSync.appCredentials.needsAttention,
});
const mapActionsToProps = {
  loadAccountSettings,
  clearAccountSettings,
  loadRetentionPolicy,
  showGeneralFlash,
  resetFlash,
  setBreadcrumbForAccountSummaryPage: breadcrumbsActions.accountSummaryPage,
  getStatus,
};

@routeLeaveHook
@fixedHeightContent
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@flashConnect(PAGES.DASHBOARD, 'AccountSummary')
@stateLogic({
  formatTZDate,
  DATE_FORMATS,
  reduxStore,
  sessionStorage: sessionStorage(),
  isPageRoleEnabled,
  pageRoles,
  USER_CAN_SEE_COLLECTION_CONFIGURATION: pageRoles.USER_CAN_SEE_COLLECTION_CONFIGURATION,
  })
@withMessages
export default class AccountSummary extends Component<Props> {
  static propTypes = {
    account: PropTypes.shape({
      accountName: PropTypes.string,
      ctime: PropTypes.string,
      summary: PropTypes.shape({
        collectionHistory: PropTypes.object,
        messages: PropTypes.number,
        attachments: PropTypes.number,
        bytes: PropTypes.number,
        modifiedAt: PropTypes.string,
      }),
    }).isRequired,
    isAccountLoaded: PropTypes.bool.isRequired,
    isCollectedNormally: PropTypes.bool.isRequired,
    lastCollectedDate: PropTypes.string.isRequired,
    retentionPolicy: PropTypes.shape({
      ttl: PropTypes.number,
      ttlUnit: PropTypes.string,
      enabled: PropTypes.bool,
    }).isRequired,
  };

  render() {
    const {
      isAccountLoaded,
    } = this.props;
    return (
      <LoadWhenReady readyStates={[isAccountLoaded]}>
        <AccountSummaryComponentLoadable {...this.props} />
      </LoadWhenReady>
    );
  }
}
