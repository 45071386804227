import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { reduxForm } from 'redux-form';

import { withRouter, browserHistory } from 'react-router';
import objectDiff from 'object-diff';

import withMessages, { intlPropTypes } from 'component-utilities/intl';
import { connect } from 'react-redux';

import LoadingIndicator from 'v-c/LoadingIndicator';
import ReportEditForm from 'v-c/Reports/ReportEditForm';

import validateSettingsAccess from '../components/validateSettingsAccess';

import {
  single as loadSingleReport,
  update as updateSingleReport,
} from '../../../global/state_managers/ReportsStateManager';

import Layout from '../../../global/components/DifferentLayout';
import Breadcrumb from '../../../global/components/Breadcrumb';
import HeadlineLabel from '../../../global/components/HeadlineLabel';

import dialogHandler from '../../../global/lib/dialogHandler';

import {
  showSuccessFlash,
  showFailureFlash,
  resetFlash,
} from '../../../global/state_managers/FlashMessagesStateManager';
import { showUnsavedSearchDialog } from '../../../global/state_managers/DialogStateManager';
import { breadcrumbsActions } from '../../../global/state_managers/BreadcrumbsStateManager';

import { PAGES } from '../../../global/components/FlashMessages';

import { jsUi } from '../../../global/lib/jsUi';
import { Params } from 'global/types';

const DROPDOWN_DELIVERY_ID = 'delivery-options';
const DELIVERY_OPTIONS = ['daily', 'weekly', 'monthly'];

const VALID_FLASH_VIEWS = [PAGES.REPORTS];

// we go back to the reports list with the cancel button or on a
// successful submit
const returnToReportsList = () => {
  jsUi.settingsReportsIndex.goTo();
};

const mapStateToProps = ({ reports, authentication }: any) => ({
  reports,
  loggedInAsEmail: authentication.loggedInAs.email,
});
const mapActionsToProps = {
  loadSingleReport,
  updateSingleReport,
  showSuccessFlash,
  showFailureFlash,
  resetFlash,
  showUnsavedSearchDialog,
  setBreadcrumbForRepportsEditReportPage:
    breadcrumbsActions.settingsReportsEditReportPage,
};

type State = {
  isReady: Boolean
}

type Props = {
  showSuccessFlash: Function,
  showFailureFlash: Function,
  resetFlash: Function,
  loggedInAsEmail: string,
  showUnsavedSearchDialog: Function,
  setBreadcrumbForRepportsEditReportPage: Function,
  reports: {
    isUpdated: Boolean,
    isReady: Boolean,
    singleReport: any,
    isError: Boolean,
    isLoading: Boolean,
    isUpdating: Boolean
  },
  loadSingleReport: Function,
  params: Params,
  router: {
    setRouteLeaveHook: Function
  },
  route: string,
  updateSingleReport: Function,
  fields: {
    enabled: {
      onChange: Function,
      value: any
    },
    sendByEmailFrequency: {
      onChange: Function,
      value: any
    } 
  },
  values: {},
  handleSubmit: Function,
  dirty: Boolean,
  invalid: Boolean,
  getMessage: Function,
}

@validateSettingsAccess('settings.reports')
@withRouter
@withMessages
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@reduxForm(
  {
  form: 'edit-report',
  fields: [
  'name',
  'description',
  'enabled',
  'sendByEmail',
  'sendByEmailFrequency',
  ],
  },
  ({ reports }: any) => {
  if (!reports.singleReport) {
  return {};
  }

  return {
  initialValues: reports.singleReport,
  };
  },
)
export default class EditReport extends Component<Props, State> {
  static propTypes = {
    ...intlPropTypes,
    showSuccessFlash: PropTypes.func.isRequired,
    showFailureFlash: PropTypes.func.isRequired,
    resetFlash: PropTypes.func.isRequired,
    loggedInAsEmail: PropTypes.string.isRequired,
    showUnsavedSearchDialog: PropTypes.func.isRequired,
    setBreadcrumbForRepportsEditReportPage: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (
      nextProps.reports.isReady &&
      nextProps.reports.singleReport &&
      prevState &&
      !prevState.isReady
    ) {
      return { isReady: true };
    }

    return null;
  }

  constructor(props: Props) {
    super(props);

    this.state = { isReady: false };

    props.setBreadcrumbForRepportsEditReportPage();
    props.loadSingleReport(props.params.id);

    // be able to stop checking for dirty form on submit/confirm dialog
    this.unsubscribeLeaveHook = props.router.setRouteLeaveHook(
      props.route,
      this.routerWillLeave,
    );
  }

  componentDidUpdate(prevProps: Props) {
    // the report was updated...
    if (this.props.reports.isUpdated && !prevProps.reports.isUpdated) {
      this.unsubscribeLeaveHook();
      this.props.resetFlash({ from: 'EditReport' });

      if (this.props.reports.isError) {
        // but it failed :(
        // details will be in console.error and on the server
        this.props.showFailureFlash('reports.update', VALID_FLASH_VIEWS);
      } else {
        // and it succeeded :)
        this.props.showSuccessFlash('reports.update', VALID_FLASH_VIEWS);
      }

      returnToReportsList();
    }
  }

  onSubmitForm = () => {
    // only submit changed fields
    this.props.updateSingleReport(
      this.props.params.id,
      objectDiff(this.props.reports.singleReport, this.props.values),
    );
  };

  onCancelForm = () => {
    returnToReportsList();
  };

  onToggle = () => {
    const { enabled } = this.props.fields;

    enabled.onChange(!enabled.value);
  };

  routerWillLeave = (route: any) => {
    if (!this.props.dirty || route.pathname === `/${this.props.params.site_name}/ui/login`) {
      return true;
    }

    dialogHandler(this.props.showUnsavedSearchDialog, () => {
      this.unsubscribeLeaveHook();
      browserHistory.push(route.pathname);
    });

    return false;
  };

  render() {
    const {
      getMessage,
      reports,
      params,
      fields,
      values,
      handleSubmit,
      dirty,
      invalid,
      loggedInAsEmail,
    } = this.props;
    const { isLoading, isUpdating } = reports;
    const { isReady } = this.state;

    if (isLoading) {
      return (
        <Layout section="settings reports report">
          <Breadcrumb />
          <LoadingIndicator
            isLoading
            orientation="middle"
            text={this.props.getMessage('app.general.loading')}
          />
        </Layout>
      );
    }

    if (isReady) {
      return (
        <Layout section="settings reports report">
          <Breadcrumb />

          <HeadlineLabel text={reports.singleReport.name} />

          <ReportEditForm
            DELIVERY_OPTIONS={DELIVERY_OPTIONS}
            DROPDOWN_DELIVERY_ID={DROPDOWN_DELIVERY_ID}
            cancelForm={this.onCancelForm}
            dirty={dirty}
            emailAddress={loggedInAsEmail}
            fields={fields}
            frequency={fields.sendByEmailFrequency.value}
            getKeyedMessage={getMessage}
            getMessage={getMessage}
            handleSubmit={handleSubmit}
            invalid={invalid}
            isUpdating={isUpdating}
            onSelect={fields.sendByEmailFrequency.onChange}
            onToggle={this.onToggle}
            report={{ report: reports.singleReport }}
            report_id={params.id}
            shouldDisable={false}
            submitForm={this.onSubmitForm}
            values={values}
          />
        </Layout>
      );
    }

    return (
      <Layout section="settings reports report">
        <Breadcrumb />
      </Layout>
    );
  }
}
