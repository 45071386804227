import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';

import fixedHeightContent from '../../global/lib/fixedHeightContent';
import LoadWhenReady from '../../global/lib/LoadWhenReady';
import routeLeaveHook from '../../global/lib/routeLeaveHook';
import { isPageRoleEnabled, pageRoles } from '../../global/lib/userRights';

import Breadcrumb from '../../global/components/Breadcrumb';
import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';
import Loading from '../../global/components/Loading';
import withSerialResultsLoader, {
  LOAD_TYPES,
} from '../../global/lib/withSerialResultsLoader';

import {
  searchSavedSearches,
  sortSavedSearches,
  updateSavedSearchesActiveColumns,
  resetSavedSearches,
} from '../../global/state_managers/SavedSearchesStateManager';
import { resetFilter } from '../../global/state_managers/SearchFormStateManager';
import { resetUnsavedSearch } from '../../global/state_managers/UnsavedSearchStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';
import {
  resetStreamQuery,
  resetStream,
} from '../../global/state_managers/SearchResults/searchResultsStream';

const TableView = Loadable({
  loader: () => import ('./components/SavedSearchListTable'),
  loading() {
    return <Loading />;
  },
});

const mapStateToProps = ({ savedSearches, userRights }: any) => ({
  savedSearches,
  isSearchesLoading: savedSearches.isLoading,
  isSavedSearchesLoaded: savedSearches.isLoaded,
  searchString: savedSearches.searchString,
  tableHelper: savedSearches.tableHelper,
  returnCount: savedSearches.totalCount,
  visibleMenus: userRights.visibleMenus,
});

const mapActionsToProps = {
  searchSavedSearches,
  sortSavedSearches,
  updateSavedSearchesActiveColumns,
  resetSavedSearches,
  resetFilter,
  resetUnsavedSearch,
  setBreadcrumbForSearchesPage: breadcrumbsActions.searchesPage,
  resetStream,
  resetStreamQuery,
};

type Props = {
  isSearchesLoading: Boolean,
  isSavedSearchesLoaded: Boolean,
  location: {},
  serialResultsLoader: {
    pushToQueue: Function,
    start: Function,
    stop: Function,
  },
  searchSavedSearches: Function,
  sortSavedSearches: Function,
  updateSavedSearchesActiveColumns: Function,
  resetSavedSearches: Function,
  resetFilter: Function,
  registerUnmountCleanupMethod: Function,
  resetUnsavedSearch: Function,
  setBreadcrumbForSearchesPage: Function,
  resetStream: Function,
  resetStreamQuery: Function,
  loadSavedSearches: Function,
}

@routeLeaveHook
@flashConnect(PAGES.SEARCHES, 'SavedSearchList')
@withSerialResultsLoader(LOAD_TYPES.SAVED_SEARCHES)
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@fixedHeightContent
export default class SavedSearchList extends Component<Props> {
  static propTypes = {
    isSearchesLoading: PropTypes.bool.isRequired,
    isSavedSearchesLoaded: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    serialResultsLoader: PropTypes.shape({
      pushToQueue: PropTypes.func.isRequired,
      start: PropTypes.func.isRequired,
      stop: PropTypes.func.isRequired,
    }).isRequired,
    searchSavedSearches: PropTypes.func.isRequired,
    sortSavedSearches: PropTypes.func.isRequired,
    updateSavedSearchesActiveColumns: PropTypes.func.isRequired,
    resetSavedSearches: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    registerUnmountCleanupMethod: PropTypes.func.isRequired,
    resetUnsavedSearch: PropTypes.func.isRequired,
    setBreadcrumbForSearchesPage: PropTypes.func.isRequired,
    resetStream: PropTypes.func.isRequired,
    resetStreamQuery: PropTypes.func.isRequired,
    loadSavedSearches: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    props.resetUnsavedSearch();
    props.resetStreamQuery();
    props.resetStream();
    props.setBreadcrumbForSearchesPage();

    props.resetSavedSearches();
    props.serialResultsLoader.start();
    props.resetFilter();

    props.registerUnmountCleanupMethod(() => {
      props.serialResultsLoader.stop();
      props.resetSavedSearches();
    });
    props.loadSavedSearches();
  }

  render() {
    return (
      <LoadWhenReady readyStates={[this.props.isSavedSearchesLoaded]}>
        <div data-view="saved-searches">
          <div data-content="header">
            <FlashMessages />
            <Breadcrumb
              {...this.props}
              showZeroResults
              isProcessing={this.props.isSearchesLoading}
            />
          </div>
          <div data-content="content">
            <TableView
              {...this.props}
              userCanEditSearches={isPageRoleEnabled(
                pageRoles.USER_CAN_EDIT_SAVED_SEARCHES,
              )}
              loadMoreResults={this.props.serialResultsLoader.pushToQueue}
            />
          </div>
        </div>
      </LoadWhenReady>
    );
  }
}
