/*
  Reusable component for creating wizards any number of steps.
  See Readme.md for usage
*/

/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  LinkLabel,
  TextLabel,
} from 'view-components/components/Widgets/Components';
import PrimaryButton from 'view-components/components/Buttons/PrimaryButton';
import RetinaImage from 'view-components/components/RetinaImage';
import LoadingIndicator from 'view-components/components/LoadingIndicator';
import SuccessImage from './images/success.png';
import LeftArrowImage from './images/left-arrow.png';
import RightArrowImage from './images/right-arrow.png';

import style from './Wizard.scss';

type Props = {
  wizardTexts: {
    back: Function,
    next: Function,
    finish: Function,
    loading: Function,
  },
  wizardSteps: any[],
  wizardLoading: Boolean,
  activeStep: number,
  stepProps: {},
}

type State = {
  activeStep: number
}

export default class Wizard extends Component<Props, State> {
  static propTypes = {
    wizardTexts: PropTypes.object.isRequired,
    wizardSteps: PropTypes.array.isRequired,
    wizardLoading: PropTypes.bool.isRequired,
    activeStep: PropTypes.number,
    stepProps: PropTypes.object,
  };

  static defaultProps = {
    activeStep: undefined,
    stepProps: {},
  };

  static getDerivedStateFromProps(nextProps: Props) {
    if (typeof nextProps.activeStep !== 'undefined') {
      return { activeStep: nextProps.activeStep };
    }

    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      activeStep: props.activeStep || 0,
    };
  }

  render() {
    const steps = this.props.wizardSteps;
    const stepObject = this.props.wizardSteps[this.state.activeStep];
    const StepComponent = stepObject.component;
    const activeStep = this.state.activeStep;
    const doneClass = (i: number) => (this.state.activeStep > i ? 'done' : null);
    const activeClass = (i: number) => (this.state.activeStep === i ? 'active' : null);

    // TODO: find something better to key this off of
    /* eslint-disable react/no-array-index-key */
    const wizardSteps = this.props.wizardSteps.map((step, i) => (
      <div
        className={`${style['wizard-step']} ${style[doneClass(i)]} ${
          style[activeClass(i)]
        }`}
        key={i}
      >
        {!doneClass(i) && (
          <TextLabel className={style['step-counter']}> {i + 1} </TextLabel>
        )}
        {doneClass(i) && (
          <RetinaImage image={SuccessImage} className={style['step-done']} />
        )}
        <TextLabel className={style['step-label']}>{step.name}</TextLabel>
      </div>
    ));
    /* eslint-enabled react/no-array-index-key */

    return (
      <div className={style.wizard}>
        <div className={style['wizard-header']}>{wizardSteps}</div>
        <div className={style['wizard-body']}>
          {this.props.wizardLoading && (
            <LoadingIndicator
              orientation="middle"
              text={this.props.wizardTexts.loading}
              isLoading
            />
          )}
          {!this.props.wizardLoading &&
            React.createElement(StepComponent, this.props.stepProps)}
        </div>
        <div className={style['wizard-footer']}>
          {activeStep !== 0 && (
            <LinkLabel
              onClick={() => stepObject.previousStep(activeStep)}
              className={style['back-button']}
              data-action="previous"
            >
              <RetinaImage image={LeftArrowImage} />
              {this.props.wizardTexts.back}
            </LinkLabel>
          )}
          {activeStep < steps.length - 1 && (
            <LinkLabel
              onClick={() => stepObject.nextStep(activeStep)}
              className={style['next-button']}
              data-action="next"
            >
              {this.props.wizardTexts.next}
              <RetinaImage image={RightArrowImage} />
            </LinkLabel>
          )}
          {activeStep === steps.length - 1 && (
            <PrimaryButton
              onClick={() => stepObject.nextStep(activeStep)}
              extraClassName={style['finish-button']}
              data-action="finish"
            >
              {this.props.wizardTexts.finish}
            </PrimaryButton>
          )}
        </div>
      </div>
    );
  }
}
