import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { diff } from 'deep-object-diff';
import { fromJS, is as isEqual } from 'immutable';
import { isArray, trim } from 'lodash';

import { formatTZDate, DATE_FORMATS } from 'component-utilities/dates/dates';

import {
  CAME_FROM_SEARCHES_INDEX,
  getUIValue,
} from '../../../global/state_managers/UIHelperStateManager';
import { showUnsavedSearchDialog } from '../../../global/state_managers/DialogStateManager';
import { User } from 'global/types';

type Props = {
  currentSearch: any | {
    name: string,
    description: string,
    samplePercentage: any,
    currentClientData: {},
    sampleTime: any,
    sampleFullCount: any,
    lastRunHits: number,
    raw: Boolean,
    createdUser: any,
    createdTime: string,
    lastModifiedUser: any,
    lastModifiedTime: string
  },
  values: any | {
    searchName: string,
    searchDescription: string,
    samplePercentage: any,
    userAccessList: any[],
  },
  unsavedClientData: {},
  searchId: string,
  isRawSearch: Boolean,
  location: any,
  openModal: Function,
  closeModal: Function,
  isNewSearch: Boolean,
  fields: any | {},
  searchErrors: Boolean,
  dirty: Boolean,
  retryTransition: Function,
  onSave: Function,
  clearSavedSearchErrors: Function,
  deleteSavedSearch: Function,
  resetFlash: Function,
  users: User[],
  allUsers: User[]
  setRouterWillLeaveValidator: Function,
  resetCurrentSavedSearch: Function,
  searchType: string,
  showGeneralFlash: Function,
  registerUnmountCleanupMethod: Function,
  canEditFields: Boolean,
  isSavedSearchProcessing: Boolean,
  saveUnsavedSearch: Function,
  showDeleteSearchDialog: Function,
  setBreadcrumbForSearchesShowPageFromIndex: Function,
  setBreadcrumbForSearchesShowPage: Function,
  resetStreamQuery: Function,
  getMessage: Function,
  userAccessNames: string[],
  onManageUserAccess: Function,
  actionButtons: any[],
  searchLoadingTime: number,
  onEdit: Function,
  samplePercentageIsValid: Boolean,
  isSamplePercentageInValidRange: Boolean,
  isSaveButtonDisabled: Boolean,
  onCancel: Boolean,
  breadcrumbs: any,
  onSaveAsNewSearch: Function,
  onDeleteSearch: Function,
  criteriaList: any[],
  onPrint: Function,
  breadcrumbsStore: any,
  returnCount: number,
  unsavedSearchSummary: any,
}

export function viewLogic(props: Props) {
  const {
    isNewSearch,
    isRawSearch,
    canEditFields,
    currentSearch = {},
    getMessage,
    searchId,
    userAccessNames,
    onManageUserAccess,
    actionButtons,
    isSavedSearchProcessing,
    searchLoadingTime,
    values,
    onEdit,
    samplePercentageIsValid,
    isSamplePercentageInValidRange,
    fields,
    isSaveButtonDisabled,
    onSave,
    onCancel,
    breadcrumbs,
    onSaveAsNewSearch,
    onDeleteSearch,
    criteriaList,
    onPrint,
    breadcrumbsStore,
    returnCount,
    unsavedSearchSummary,
  }: Props = props;

  const shouldHideActionButtons = isNewSearch || !canEditFields;
  const shouldHideSaveAsNewButton = isRawSearch;

  const isSamplePercentageDisabled =
    !canEditFields || isRawSearch || !!currentSearch.samplePercentage;
  const searchMetadata = {
    createdBy: currentSearch.createdUser,
    creationDate: currentSearch.createdTime,
    lastModifiedBy: currentSearch.lastModifiedUser,
    lastModifiedDate: currentSearch.lastModifiedTime,
  };

  function breadcrumbsConfig() {
    return breadcrumbs({
      breadcrumbs: breadcrumbsStore.route,
      getMessage,
      params: {
        searchName: currentSearch.name,
        searchId,
      },
    });
  }

  function actionsConfig() {
    const {
      actionStatuses: { NORMAL, INACTIVE },
    } = actionButtons;
    const buttonsStatus = isSavedSearchProcessing ? INACTIVE : NORMAL;

    const print = {
      status: buttonsStatus,
      onClick: onPrint,
    };

    const saveAsNew = {
      status: buttonsStatus,
      onClick: onSaveAsNewSearch,
    };

    const deleteSearch = {
      status: buttonsStatus,
      onClick: onDeleteSearch,
    };

    if (shouldHideActionButtons) {
      return {};
    } else if (shouldHideSaveAsNewButton) {
      return { print, deleteSearch };
    }

    return { print, saveAsNew, deleteSearch };
  }

  function samplePercentageErrors() {
    if (!samplePercentageIsValid) {
      return isNaN(parseInt(fields.samplePercentage.value, 10))
        ? 'value'
        : 'number';
    }

    return false;
  }

  currentSearch.summary = Object.keys(unsavedSearchSummary).length ? unsavedSearchSummary : (currentSearch.summary || {});

  const totalResultsCount = currentSearch.summary.results || returnCount || 0;

  return {
    ...props,
    shouldHideActionButtons,
    shouldHideSaveAsNewButton,
    isSamplePercentageDisabled,
    searchMetadata,
    isSearchProcessing: isSavedSearchProcessing,
    breadcrumbsConfig: breadcrumbsConfig(),
    userAccessConfig: {
      users: userAccessNames,
      onManageUserAccess,
    },
    actionsConfig: actionsConfig(),
    searchConfig: {
      isRandomized: !!currentSearch.samplePercentage,
      isNewSearch,
      details: isNewSearch ? null : searchMetadata,
      userCanEditFields: canEditFields && !isRawSearch,
      userCanEditSearch:
        canEditFields && !isRawSearch && !currentSearch.samplePercentage,
      onEdit,
      searchName: currentSearch.name || '',
      criteriaList,
      results: {
        total: totalResultsCount,
        time: String(searchLoadingTime),
        samplePercentageErrors: samplePercentageErrors(),
        isSamplePercentageInValidRange,
        randomizedDetails: isSamplePercentageDisabled
          ? {
            sampled: String(totalResultsCount),
            sampleFullCount: String(currentSearch.sampleFullCount || ''),
            samplePercentage: values.samplePercentage,
            date: currentSearch.sampleTime
              ? formatTZDate(currentSearch.sampleTime, DATE_FORMATS.SHORT)
              : '',
          }
          : null,
        holdCount: currentSearch.summary.hold || 0,
        tagsReviewedCount: currentSearch.summary.reviewed || 0,
        tagsNeedReviewCount: currentSearch.summary.needsReview || 0,
      },
    },
    buttonsConfig: {
      save: {
        disabled: isSaveButtonDisabled,
        onClick: onSave,
      },
      cancel: {
        disabled: isSavedSearchProcessing,
        onClick: onCancel,
      },
    },
  };
}

export default function SearchDetailsViewDeconstructed({
  unsavedSearchForSave,
  goToSearchesIndex,
  goToSearchesEdit,
  goToResultsIndex,
  goToNewSearchEdit,
  getPrioritizedUserRole,
  isSamplePercentageInValidRange,
  dialogHandler,
  Roles,
  actionButtons,
  breadcrumbs,
  SimpleSearchSaveView,
  AdvancedSearchView,
  MODAL_MAP,
  USER_NEEDS_SEARCH_PERMISSION,
  PAGES,
}: any) {
  return function SearchDetailsViewStateLogicComposer(component: any) {
    class SearchDetailsViewStateLogic extends Component<Props> {
      static propTypes = {
        currentSearch: PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
          samplePercentage: PropTypes.any,
          currentClientData: PropTypes.object,
          sampleTime: PropTypes.any,
          sampleFullCount: PropTypes.any,
          lastRunHits: PropTypes.number,
          raw: PropTypes.bool,
        }).isRequired,
        values: PropTypes.shape({
          searchName: PropTypes.string,
          searchDescription: PropTypes.string,
          samplePercentage: PropTypes.any,
        }).isRequired,
        unsavedClientData: PropTypes.object.isRequired,
        searchId: PropTypes.string,
        isRawSearch: PropTypes.bool.isRequired,
        location: PropTypes.object,
        openModal: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        isNewSearch: PropTypes.bool,
        fields: PropTypes.object.isRequired,
        searchErrors: PropTypes.bool.isRequired,
        dirty: PropTypes.bool.isRequired,
        retryTransition: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        clearSavedSearchErrors: PropTypes.func,
        deleteSavedSearch: PropTypes.func,
        resetFlash: PropTypes.func.isRequired,
        users: PropTypes.array.isRequired,
        setRouterWillLeaveValidator: PropTypes.func.isRequired,
        resetCurrentSavedSearch: PropTypes.func.isRequired,
        searchType: PropTypes.string.isRequired,
        showGeneralFlash: PropTypes.func.isRequired,
        registerUnmountCleanupMethod: PropTypes.func.isRequired,
        canEditFields: PropTypes.bool,
        isSavedSearchProcessing: PropTypes.bool,
        saveUnsavedSearch: PropTypes.func.isRequired,
        showDeleteSearchDialog: PropTypes.func.isRequired,
        setBreadcrumbForSearchesShowPageFromIndex: PropTypes.func.isRequired,
        setBreadcrumbForSearchesShowPage: PropTypes.func.isRequired,
        resetStreamQuery: PropTypes.func.isRequired,
      };

      static defaultProps = {
        currentSearch: {},
        location: {},
        isNewSearch: false,
        canEditFields: false,
        returnCount: null,
        isSavedSearchProcessing: false,
        searchId: null,
        searchMetadata: {},
        searchResults: {},
        clearSavedSearchErrors: () => {},
        deleteSavedSearch: () => {},
      };

      constructor(props: any) {
        super(props);

        this.skipUnsavedDialog = false;

        if (props.isNewSearch || getUIValue(CAME_FROM_SEARCHES_INDEX)) {
          props.setBreadcrumbForSearchesShowPageFromIndex();
        } else {
          props.setBreadcrumbForSearchesShowPage();
        }

        if (this.isMigratedSearch) {
          props.showGeneralFlash(
            'migrated_search.information',
            PAGES.SAVE_SEARCH,
          );
        }

        props.registerUnmountCleanupMethod(() => {
          props.resetCurrentSavedSearch();
        });
      }

      componentDidMount() {
        this.props.setRouterWillLeaveValidator(this.routerWillLeave);
      }

      componentDidUpdate(prevProps: Props) {
        if (this.props.searchErrors) {
          this.skipUnsavedDialog = false;
        }

        if (
          Object.keys(diff(prevProps.values, this.props.values)).length &&
          this.props.searchErrors
        ) {
          this.props.clearSavedSearchErrors();
          this.props.resetFlash({
            from: 'SearchDetailsView/componentDidUpdate',
          });
        }
      }

      onCancel = () => {
        const { location, isNewSearch, searchId } = this.props;

        if (
          location.query.create_failure ||
          isNewSearch ||
          getUIValue(CAME_FROM_SEARCHES_INDEX)
        ) {
          goToSearchesIndex();
        } else {
          goToResultsIndex({ id: searchId });
        }
      };

      onSave = () => {
        const { values } = this.props;
        this.skipUnsavedDialog = true;

        this.props.resetStreamQuery();

        const saveAttributes: {
          name: string,
          description: string,
          'special-access': any[],
          'sample-percentage'?: string,
          } = {
            name: values.searchName,
            description: values.searchDescription,
            'special-access': values.userAccessList,
          };

        if (values.samplePercentage && String(values.samplePercentage).length) {
          saveAttributes['sample-percentage'] = String(values.samplePercentage);
        }

        this.props.onSave(saveAttributes);
      };

      onEdit = () => {
        this.skipUnsavedDialog = false;

        if (this.props.isNewSearch) {
          goToNewSearchEdit();
        } else {
          goToSearchesEdit({ search_id: this.props.searchId });
        }
      };

      onSaveAsNewSearch = () => {
        if (!this.props.dirty) {
          const { currentSearch, values } = this.props;

          this.props.openModal(MODAL_MAP.SAVE_AS_NEW, {
            closeModal: this.props.closeModal,
            onSave: (modalForm: any) => {
              this.props.closeModal();

              this.props.saveUnsavedSearch(
                {
                  ...unsavedSearchForSave(),
                  name: modalForm.searchName,
                  description: modalForm.searchDescription,
                  'special-access': values.userAccessList,
                },
                { isNewSearch: true },
              );
            },
            searchName: currentSearch.name,
            searchDescription: currentSearch.description,
          });
        } else {
          this.props.openModal(MODAL_MAP.UNSAVED_CHANGES, {
            closeModal: this.props.closeModal,
          });
        }
      };

      onDeleteSearch = () => {
        const { searchId, currentSearch, deleteSavedSearch } = this.props;

        this.props.showDeleteSearchDialog(
          {
            confirm: () => {
              this.skipUnsavedDialog = true;
              deleteSavedSearch(searchId);
            },
          },
          {
            deleteName: currentSearch.name,
            content: 'CurrentSavedSearch',
          },
        );
      };

      onManageUserAccess = () => {
        const { values, fields } = this.props;

        this.props.openModal(MODAL_MAP.ADD_USER, {
          userAccessList: values.userAccessList,
          onAddUser: fields.userAccessList.onChange,
          filterUsers: (user: any) => {
            if (!user.roles.length) {
              return false;
            }

            const priorizedRole = getPrioritizedUserRole(user.roles);

            return Roles[priorizedRole].enabledPageRoles.some(
              (pageRole: string) => pageRole === USER_NEEDS_SEARCH_PERMISSION,
            );
          },
        });
      };

      onPrint = () => {
        if (!this.isSaveButtonDisabled) {
          this.props.openModal(MODAL_MAP.UNSAVED_CHANGES, {
            closeModal: this.props.closeModal,
          });
        } else {
          window.print();
        }
      };

      get isClientDataDirty() {
        const { isNewSearch, currentSearch, unsavedClientData } = this.props;

        if (isNewSearch) {
          return false;
        }

        if (!currentSearch.clientData || this.props.isRawSearch) {
          return false;
        }

        return !isEqual(
          fromJS(currentSearch.clientData),
          fromJS(unsavedClientData),
        );
      }

      get userAccessNames() {
        const { values } = this.props;

        if (!isArray(values.userAccessList)) {
          return [];
        }

        return this.props.allUsers.reduce((userNames, user) => {
          if (
            values.userAccessList.some(
              userExternalId => userExternalId === user.externalId,
            )
          ) {
            userNames.push(user.fullName);
          }

          return userNames;
        }, []);
      }

      get samplePercentageIsValid() {
        const samplePercentageValue = String(
          this.props.values.samplePercentage,
        );

        if (
          !samplePercentageValue.length ||
          this.isSamplePercentageInValidRange
        ) {
          return true;
        }

        return false;
      }

      get isSamplePercentageInValidRange() {
        const samplePercentageValue = String(
          this.props.values.samplePercentage,
        );

        return isSamplePercentageInValidRange(samplePercentageValue);
      }

      get criteriaList() {
        switch (this.props.searchType) {
          case 'simple':
            return <SimpleSearchSaveView {...this.props} formView="save" />;
          case 'advanced':
            return <AdvancedSearchView {...this.props} formView="save" />;
          default:
            return null;
        }
      }

      get isMigratedSearch() {
        return this.props.currentSearch.raw;
      }

      get isSaveButtonDisabled() {
        const {
          isRawSearch,
          canEditFields,
          isSavedSearchProcessing,
          fields,
          searchErrors,
          dirty,
        } = this.props;

        return (
          searchErrors.randomizedWithReviewFailed ||
          !canEditFields ||
          isRawSearch ||
          !trim(fields.searchName.value || '').length ||
          !dirty ||
          isSavedSearchProcessing ||
          !this.samplePercentageIsValid
        );
      }

      routerWillLeave = () => {
        if (this.skipUnsavedDialog || !this.props.dirty) {
          return true;
        }

        dialogHandler(showUnsavedSearchDialog, this.props.retryTransition);

        return false;
      };

      render() {
        return React.createElement(component, {
          ...this.props,
          ...this.state,
          actionButtons,
          breadcrumbs,
          onCancel: this.onCancel,
          onSave: this.onSave,
          onEdit: this.onEdit,
          onPrint: this.onPrint,
          onSaveAsNewSearch: this.onSaveAsNewSearch,
          onDeleteSearch: this.onDeleteSearch,
          onManageUserAccess: this.onManageUserAccess,
          isClientDataDirty: this.isClientDataDirty,
          userAccessNames: this.userAccessNames,
          samplePercentageIsValid: this.samplePercentageIsValid,
          routerWillLeave: this.routerWillLeave,
          criteriaList: this.criteriaList,
          skipUnsavedDialog: this.skipUnsavedDialog,
          isSamplePercentageInValidRange: this.isSamplePercentageInValidRange,
          isMigratedSearch: this.isMigratedSearch,
          cameFromSearchesIndex: getUIValue(CAME_FROM_SEARCHES_INDEX),
          isSaveButtonDisabled: this.isSaveButtonDisabled,
        });
      }
    }

    return SearchDetailsViewStateLogic;
  };
}
