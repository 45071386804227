import React from 'react';
import PropTypes from 'prop-types';

import { Accordion, AccordionItem } from 'v-c/Accordions';

import { translateTabData } from './AccountSettingsDeconstructed';
import styles from '../components/Settings.scss';

import AccountSettingsButtons from '../components/AccountSettingsButtons';

export default function Contents(props: any) {
  const { buttons, tabs, getMessage } = props;

  return (
    <div section="account-settings" className={styles['accordion-content']}>
      <Accordion>
        {translateTabData(props, { tabs }).map((item: any) => (
          <AccordionItem
            checked={false}
            label={item.label}
            key={item.id}
          >
            {item.content}
          </AccordionItem>
        ))}

        <AccountSettingsButtons buttons={buttons} getMessage={getMessage} />
      </Accordion>
    </div>
  );
}

Contents.displayName = 'AccountSettings/Contents';

Contents.propTypes = {
  getMessage: PropTypes.func.isRequired,
  buttons: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
};
