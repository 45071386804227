type Props = {
  isUpdating: Boolean,
  isDirtyFields: Boolean,
  isCancelButtonEnabled: Boolean,
  isSaveButtonEnabled: Boolean
}
// stuff shared between here and sso
export function commonViewLogicSetup(props: Props = {}) {
  const {
    isUpdating, isDirtyFields,
  } = props;

  const isCancelButtonEnabled = (
    !isUpdating && isDirtyFields
  );

  return { isCancelButtonEnabled };
}

// Be sure that you deepmerge this with any other props
// you're assembling for the rest of the UI.
export function commonViewLogicButtonReturn({
  isSaveButtonEnabled,
  isCancelButtonEnabled,
  isUpdating,
  props,
}: Props) {
  return {
    buttons: {
      save: {
        disabled: !isSaveButtonEnabled,
        loading: isUpdating,
        onClick: props.onUpdate,
      },
      cancel: {
        disabled: !isCancelButtonEnabled,
        onClick: props.resetFields,
      },
    },
  };
}

