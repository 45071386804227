import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withMessages from 'component-utilities/intl';

import Layout from '../../global/components/Layout';
import HeadlineLabel from '../../global/components/HeadlineLabel';
import Breadcrumb from '../../global/components/Breadcrumb';

import validateSettingsAccess from './components/validateSettingsAccess';

import SearchListForm from './SearchLists/SearchListForm';

import {
  loadSearchLists,
  createSearchList,
} from '../../global/state_managers/SearchListsStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';
import { SearchList } from 'global/types';

const mapStateToProps = ({ searchLists }: any) => ({
  searchLists: searchLists.searchLists,
});

const mapActionsToProps = {
  loadSearchLists,
  createSearchList,
  setBreadcrumbForSearchListsNewListPage:
    breadcrumbsActions.settingsSearchListsNewListPage,
};

type Props = {
  setBreadcrumbForSearchListsNewListPage: Function,
  createSearchList: Function,
  getMessage: Function,
  routeParams: {
    type: string
  },
  searchLists: SearchList[],
  loadSearchLists: Function
};

function NewSearchListComponent(props: Props) {
  useEffect(() => {
    props.setBreadcrumbForSearchListsNewListPage();
  }, []);

  const onSave = useCallback((type: string) => (data: any) => props.createSearchList(type, data), [props.createSearchList]);

  const { getMessage, routeParams, searchLists } = props;

  return (
    <Layout section="settings search-lists new">
      <header>
        <Breadcrumb />
        <HeadlineLabel text={getMessage('app.breadcrumb.new_list')} />
      </header>
      <SearchListForm
        onSave={onSave(routeParams.type)}
        currentLists={searchLists}
        loadSearchLists={props.loadSearchLists}
      />
    </Layout>
  );
}

NewSearchListComponent.propTypes = {
  getMessage: PropTypes.func.isRequired,
  routeParams: PropTypes.object.isRequired,
  searchLists: PropTypes.array.isRequired,
  loadSearchLists: PropTypes.func.isRequired,
  createSearchList: PropTypes.func.isRequired,
  setBreadcrumbForSearchListsNewListPage: PropTypes.func.isRequired,
};

const withMessagesComponent = withMessages(NewSearchListComponent);
const connectedComponent = connect(
  mapStateToProps,
  mapActionsToProps,
)(withMessagesComponent);
const NewSearchList = validateSettingsAccess('settings.search_lists')(connectedComponent);

export default NewSearchList;
