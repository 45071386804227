import uri from 'urijs';
import jsApi from './jsApi';
import { apiURL } from './apiRequest';

function downloadQuery(index: string, filename = '') {
  return uri('')
    .search({ index, filename })
    .toString();
}

export function renderAttachmentURL(assetId: string, offset: string) {
  return apiURL(jsApi.renderedAssetsShowAttachment.path, {
    urlParams: { asset_id: assetId, offset },
  });
}

export function downloadAttachmentURL(assetID: string, filename: string, offset: string) {
  return apiURL(jsApi.assetsDownload.path, {
    urlParams: { asset_id: assetID },
    queryParams: downloadQuery(offset, filename),
  });
}

// Todo: Remove url and downloadUrl values, they are no longer used
export default function generateAttachmentLinks({ asset, assetId }: any) {
  return asset.data.downloadableAttachments.map(
    ({ id, filename, isRenderable, offset, 'content-type': type }: any) => ({
      type,
      offset,
      id,
      assetId,
      filename: filename || '',
      url: renderAttachmentURL(assetId, offset),
      downloadUrl: downloadAttachmentURL(assetId, filename, offset),
      isRenderable,
    }),
  );
}
