/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// use below line when using nest.js routes for attachments
// import DocumentViewerContainer from './DocumentViewer/DocumentViewerContainer';
import DocumentViewerContainer from 'v-c/DocumentViewer/DocumentViewerContainer';

import { connect } from 'react-redux';

import { hideViewer } from '../state_managers/DocumentViewerStateManager';
import { ReduxState } from 'global/types';

const mapStateToProps = ({ documentViewer }: ReduxState) => ({
  showViewer: documentViewer.showViewer,
  viewerData: documentViewer.viewerData,
});

const mapActionsToProps = { hideViewer };

type Props = {
  hideViewer: Function
  staticView: boolean
  showViewer: boolean
  viewerData: {}
}

@connect(mapStateToProps, mapActionsToProps)
export default class DocumentViewer extends Component<Props> {
  static propTypes = {
    showViewer: PropTypes.bool.isRequired,
    viewerData: PropTypes.object,
    staticView: PropTypes.bool,
    hideViewer: PropTypes.func.isRequired,
  };

  static defaultProps = {
    staticView: false,
    viewerData: null,
  };

  constructor(props: Props) {
    super(props);

    this.props.hideViewer();
  }

  componentDidMount() {
    if (!this.props.staticView) {
      $('body').css('overflow', 'hidden');
    }
  }

  componentDidUpdate() {
    const currentBodyVisibility = $('body').css('overflow');

    if (this.props.staticView) {
      if (!this.isViewerVisible && currentBodyVisibility === 'hidden') {
        $('body').css('overflow', 'auto');
      } else if (this.isViewerVisible) {
        $('body').css('overflow', 'hidden');
      }
    }
  }

  componentWillUnmount() {
    if (!this.props.staticView) {
      $('body').css('overflow', 'auto');
    }
  }

  get isViewerVisible() {
    return this.props.showViewer;
  }

  render() {
    if (!this.isViewerVisible) {
      return null;
    }

    const { viewerData } = this.props;

    return (
      <div className="modal-view-container">
        <DocumentViewerContainer {...viewerData} />
      </div>
    );
  }
}
