import { apiReqPromise } from '../../lib/apiRequest';
import jsApi from '../../lib/jsApi';

import {
  searchResultsState,
  SET_INITIAL_TABLE_CONFIG,
  INIT_TABLE_CONFIG,
  ACTIVE_COLUMNS_CHANGED,
  SORT_TABLE_START,
  SORT_TABLE_FINISHED,
  SORT_TABLE_FAILED,
} from '../SearchResultsStateManager';

// actions
export function setInitialTableConfig(id: string) {
  const { tableHelper, sortOptions, tableConfigs } = searchResultsState();

  return {
    type: SET_INITIAL_TABLE_CONFIG,
    payload: {
      tableConfigs: {
        ...tableConfigs,
        [id]: {
          tableHelper: { ...tableHelper },
          sortOptions: { ...sortOptions },
        },
      },
    },
  };
}

export function initTableConfig(id: string, isMyArchive = false) {
  return function* onInitTableConfig() {
    if (!searchResultsState().tableConfigs[id]) {
      yield setInitialTableConfig(id);
    }

    yield {
      type: INIT_TABLE_CONFIG,
      payload: { activeSearchId: id, isMyArchive },
    };
  };
}

export function activeColumnsChanged(id: string, newColumns: string[]) {
  const { tableConfigs } = searchResultsState();
  const nmodifiedTableConfigs = { ...tableConfigs };

  nmodifiedTableConfigs[id].tableHelper.activeColumns = [...newColumns];

  return {
    type: ACTIVE_COLUMNS_CHANGED,
    payload: { tableConfigs: nmodifiedTableConfigs },
  };
}

export function sortTable(streamId: string, sortOptions: any) {
  return function* doSortTable() {
    yield { type: SORT_TABLE_START, payload: sortOptions };

    try {
      yield apiReqPromise(jsApi.searchResultsStreamsSort, {
        params: {
          data: {
            sort_options: sortOptions,
          },
        },
        urlParams: { search_results_stream_id: streamId },
      });

      const { activeSearchId, tableConfigs } = searchResultsState();
      const nmodifiedTableConfigs = { ...tableConfigs };

      nmodifiedTableConfigs[activeSearchId].sortOptions = {
        sortKey: sortOptions.sort,
        sortOrder: sortOptions['sort-order'],
      };

      yield {
        type: SORT_TABLE_FINISHED,
        payload: { tableConfigs: nmodifiedTableConfigs },
      };
    } catch (e) {
      console.error(e);

      yield { type: SORT_TABLE_FAILED };
    }
  };
}