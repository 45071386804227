import { isEmpty } from 'lodash';

import reduxStore, { Action, PromiseObject } from '../reduxStore';

import jsApi from '../lib/jsApi';
import { apiReqPromise } from '../lib/apiRequest';

import { closeModal } from './ModalsStateManager';
import {
  showSuccessFlash,
  showFailureFlash,
} from './FlashMessagesStateManager';

import { PAGES } from '../components/FlashMessages';

import {
  USERS_STATUSES_CHANGED,
  USERS_ROLES_CHANGED,
  columns,
  FIRST_NAME,
  LAST_NAME,
} from './UsersStateManager';

const BASE_FLASH = 'bulk_user';
const VALID_FLASH_VIEWS = [PAGES.USER_MANAGEMENT];

export const RESET: string = 'usersBulkOperations/reset';
export const BULK_ENABLE_USERS_PROCESSING: string =
  'usersBulkOperations/bulkEnableUsersProcessing';
export const BULK_ENABLE_USERS_FINISHED: string =
  'usersBulkOperations/bulkEnableUsersFinished';
export const BULK_ENABLE_USERS_FAILED: string =
  'usersBulkOperations/bulkEnableUsersFailed';
export const BULK_DISABLE_USERS_PROCESSING: string =
  'usersBulkOperations/bulkDisableUsersProcessing';
export const BULK_DISABLE_USERS_FINISHED: string =
  'usersBulkOperations/bulkDisableUsersFinished';
export const BULK_DISABLE_USERS_FAILED: string =
  'usersBulkOperations/bulkDisableUsersFailed';
export const BULK_ADD_USER_ROLES_PROCESSING: string =
  'usersBulkOperations/bulkAddUserRolesProcessing';
export const BULK_ADD_USER_ROLES_FINISHED: string =
  'usersBulkOperations/bulkAddUserRolesFinished';
export const BULK_ADD_USER_ROLES_FAILED: string =
  'usersBulkOperations/bulkAddUserRolesFailed';
export const BULK_REMOVE_USER_ROLES_PROCESSING: string =
  'usersBulkOperations/bulkRemoveUserRolesProcessing';
export const BULK_REMOVE_USER_ROLES_FINISHED: string =
  'usersBulkOperations/bulkRemoveUserRolesFinished';
export const BULK_REMOVE_USER_ROLES_FAILED: string =
  'usersBulkOperations/bulkRemoveUserRolesFailed';

export const UPDATE_USER_TYPES = {
  ENABLE: 'Enable',
  DISABLE: 'Disable',
};

export const UPDATE_ROLES_TYPES = {
  ADD: 'Add',
  REMOVE: 'Remove',
};

interface UsersBulkOperationsState {
  errors: null | any[],
  isProcessing: boolean,
  lastBulkUpdate: null | number,
}

// Init
export const INITIAL_STATE: UsersBulkOperationsState = {
  errors: null,
  isProcessing: false,
  lastBulkUpdate: null,
};

// helpers
export function buildFilter(multiSelect: any, search: string) {
  if (multiSelect.isAllSelected) {
    return search.length
      ? `contains ${search} ${[
        columns.EMAIL,
        columns.USERNAME,
        FIRST_NAME,
        LAST_NAME,
        columns.ROLES,
      ].join(',')}`
      : 'all';
  }

  return null;
}

export function bulkUpdateUserStatuses({
  type,
  actions,
  multiSelect,
  options = {},
}: any) {
  return function* doBulkUpdateUserStatuses() {
    yield { type: actions.processing };

    const filter = buildFilter(multiSelect, options.filters);

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi[`usersBulk${type}`], {
        params: {
          data: {
            ids: filter ? [] : multiSelect.selectedRows,
            except: multiSelect.except,
            active: type === UPDATE_USER_TYPES.ENABLE,
            filter,
            options: {
              'send-welcome-email': options.sendWelcomeEmail,
            },
          },
        },
      });

      if (isEmpty(result.data['not-updated'])) {
        yield { type: actions.done };

        reduxStore.dispatch({
          type: USERS_STATUSES_CHANGED,
          payload: {
            ids: result.data.updated.map(String),
            isActive: type === UPDATE_USER_TYPES.ENABLE,
          },
        });

        reduxStore.dispatch(closeModal());
        reduxStore.dispatch(
          showSuccessFlash(`${BASE_FLASH}.update`, VALID_FLASH_VIEWS),
        );
      } else {
        yield {
          type: actions.failed,
          payload: { errors: result.data['not-updated'] },
        };
      }
    } catch (e) {
      console.error(e);

      yield {
        type: actions.failed,
        payload: { errors: e },
      };
    }
  };
}

export function bulkUpdateUserRoles(type: string, actions: any, params: any) {
  return function* doBulkUpdateUserRoles(getState: Function) {
    yield { type: actions.processing };

    const { searchString } = getState().users;
    const filter = buildFilter(params.multiSelect, searchString);

    try {
      const result: PromiseObject = yield apiReqPromise(jsApi[`usersBulkRoles${type}`], {
        params: {
          data: {
            ids: filter ? [] : params.multiSelect.ids,
            except: params.multiSelect.except,
            roles: params.multiSelect.roles,
            filter,
          },
        },
      });

      if (!isEmpty(result.data.updated)) {
        yield { type: actions.done };

        reduxStore.dispatch({
          type: USERS_ROLES_CHANGED,
          payload: {
            ids: result.data.updated.map(String),
            selectedRoles: params.multiSelect.roles,
            isAdd: type === UPDATE_ROLES_TYPES.ADD,
          },
        });

        reduxStore.dispatch(
          showSuccessFlash(`${BASE_FLASH}.update`, VALID_FLASH_VIEWS),
        );
      } else {
        yield {
          type: actions.failed,
          payload: { errors: result.data['not-updated'] },
        };

        reduxStore.dispatch(
          showFailureFlash(`${BASE_FLASH}.update`, VALID_FLASH_VIEWS),
        );
      }
    } catch (e) {
      console.error(e);

      yield {
        type: actions.failed,
        payload: { errors: e },
      };

      reduxStore.dispatch(
        showFailureFlash(`${BASE_FLASH}.update`, VALID_FLASH_VIEWS),
      );
    }
  };
}

// Actions
export function bulkEnableUsers(multiSelect: any, options: any) {
  return bulkUpdateUserStatuses({
    type: UPDATE_USER_TYPES.ENABLE,
    actions: {
      processing: BULK_ENABLE_USERS_PROCESSING,
      done: BULK_ENABLE_USERS_FINISHED,
      failed: BULK_ENABLE_USERS_FAILED,
    },
    multiSelect,
    options,
  });
}

export function bulkDisableUsers(multiSelect: any, options: any) {
  return bulkUpdateUserStatuses({
    type: UPDATE_USER_TYPES.DISABLE,
    actions: {
      processing: BULK_DISABLE_USERS_PROCESSING,
      done: BULK_DISABLE_USERS_FINISHED,
      failed: BULK_DISABLE_USERS_FAILED,
    },
    multiSelect,
    options,
  });
}

export function bulkAddUserRoles(params: any) {
  const actions = {
    processing: BULK_ADD_USER_ROLES_PROCESSING,
    done: BULK_ADD_USER_ROLES_FINISHED,
    failed: BULK_ADD_USER_ROLES_FAILED,
  };
  return bulkUpdateUserRoles(UPDATE_ROLES_TYPES.ADD, actions, params);
}

export function bulkRemoveUserRoles(params: any) {
  const actions = {
    processing: BULK_REMOVE_USER_ROLES_PROCESSING,
    done: BULK_REMOVE_USER_ROLES_FINISHED,
    failed: BULK_REMOVE_USER_ROLES_FAILED,
  };
  return bulkUpdateUserRoles(UPDATE_ROLES_TYPES.REMOVE, actions, params);
}

export function resetUsersBulkOperations() {
  return { type: RESET };
}

// Store
export default function UsersBulkOperationsStateManager(
  state = INITIAL_STATE,
  { type, payload }: Action,
) {
  switch (type) {
    case RESET:
      return { ...INITIAL_STATE };
    case BULK_ADD_USER_ROLES_PROCESSING:
    case BULK_REMOVE_USER_ROLES_PROCESSING:
    case BULK_ENABLE_USERS_PROCESSING:
    case BULK_DISABLE_USERS_PROCESSING:
      return {
        ...state,
        isProcessing: true,
        errors: null,
      };
    case BULK_ENABLE_USERS_FINISHED:
    case BULK_DISABLE_USERS_FINISHED:
    case BULK_ADD_USER_ROLES_FINISHED:
    case BULK_REMOVE_USER_ROLES_FINISHED:
      return {
        ...state,
        isProcessing: false,
        lastBulkUpdate: new Date().getTime(),
      };
    case BULK_ENABLE_USERS_FAILED:
    case BULK_DISABLE_USERS_FAILED:
    case BULK_ADD_USER_ROLES_FAILED:
    case BULK_REMOVE_USER_ROLES_FAILED:
      return {
        ...state,
        isProcessing: false,
        errors: payload.errors,
      };
    default:
      return state;
  }
}
