/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { isNumber } from 'lodash';

import withMessages from 'component-utilities/intl';

import FlagsContainer from 'view-components/components/Flags/FlagsContainer';
import FlagRandomized from 'view-components/components/Flags/FlagRandomized';
import FlagsSmall from 'view-components/components/Flags/FlagsSmall';

import ToggleButtonToSplitView from 'v-c/Splitview/ToggleButtonToSplitView';
import { LinkLabel } from 'v-c/Widgets/Components';

import Breadcrumb from '../../Breadcrumb';
import FlashMessages from '../../FlashMessages';
import HeadlineLabel from '../../HeadlineLabel';
import { ReduxState } from 'global/types';

const mapStateToProps = ({ searchResults }: ReduxState) => ({
  isSearchReady: searchResults.isLoaded,
});
const mapActionsToProps = {};

type Props = {
  searchName: string
  searchId: string
  returnCount: number
  notifyHeight: number
  isSearchReady: boolean
  getMessage: Function
  toggleView: Function
  samplePercentage: number
  isSearchForceReady: boolean
  goToCriteria: Function
}

@withMessages
@connect(
  mapStateToProps,
  mapActionsToProps,
)
export default class Heading extends Component<Props> {
  static propTypes = {
    searchName: PropTypes.string,
    searchId: PropTypes.string,
    returnCount: PropTypes.number,
    isSearchReady: PropTypes.bool,
    notifyHeight: PropTypes.func.isRequired,
    getMessage: PropTypes.func.isRequired,
    toggleView: PropTypes.func,
    goToCriteria: PropTypes.func,
    samplePercentage: PropTypes.string,
    isSearchForceReady: PropTypes.bool,
  };

  static defaultProps = {
    isSearchReady: false,
    searchId: null,
    searchName: null,
    returnCount: 0,
    toggleView: null,
    goToCriteria: null,
    samplePercentage: null,
    isSearchForceReady: false,
  };

  static displayName = 'AssetsView/Heading';

  goToCriteria = () => {
    const { searchId, goToCriteria } = this.props;

    goToCriteria(searchId);
  };

  render() {
    const {
      searchName,
      searchId,
      returnCount,
      notifyHeight,
      isSearchReady,
      getMessage,
      toggleView,
      samplePercentage,
      isSearchForceReady,
    } = this.props;

    const detailsMessage = getMessage('app.general.details');
    const countLabel = isNumber(returnCount) && (
      <data>
        &nbsp;
        {`(${returnCount.toLocaleString()})`}
      </data>
    );

    return (
      <header>
        <FlashMessages notifyHeight={notifyHeight} />
        <Breadcrumb {...this.props} />
        {searchName && (
          <data>
            <HeadlineLabel
              title={searchName}
              className="search-name-label"
              text={searchName}
            />
            <HeadlineLabel
              className="search-count-label"
              text={isSearchForceReady || isSearchReady ? countLabel : ''}
            />
          </data>
        )}
        {searchId && (
          <LinkLabel
            data-action="view-details"
            className="details-label"
            onClick={this.goToCriteria}
          >
            {detailsMessage}
          </LinkLabel>
        )}
        {samplePercentage && (
          <div className="randomized-flag">
            <FlagsContainer>
              <FlagsSmall>
                <FlagRandomized />
              </FlagsSmall>
            </FlagsContainer>
          </div>
        )}
        {toggleView && (
          <div className="toggle-button-holder">
            <ToggleButtonToSplitView onClick={toggleView} />
          </div>
        )}
      </header>
    );
  }
}
