/* eslint-disable no-undef */
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import VerticalHeightManager from '../VerticalHeightManager';

import TableViewHeading from './Heading';
import TableViewContent from './TableViewContent';
import TableViewActions from '../TableViewActions';

import './Content.scss';

type Props = {
  doSort: Function
  searchString: string
  heading: ReactNode
  emptyResults: ReactNode
  headerActions: string[]
  actionHelper: Boolean
  forceVisibleTable: Boolean
  content: ReactNode
  returnCount: number
}

const TableViewContentFactory = (props: Props) => {
  const {
    heading: Heading,
    content: Content,
    returnCount,
    emptyResults,
    searchString,
    headerActions,
    actionHelper,
    forceVisibleTable = false,
  } = props;

  const isReturnCount = !!returnCount;
  let renderContent = null;
  let renderTableActions = null;

  if (
    forceVisibleTable ||
    isReturnCount ||
    (!isReturnCount && searchString && searchString.length)
  ) {
    renderContent = Content;
  } else if (emptyResults) {
    if (headerActions) {
      renderTableActions = (
        <div className="header-control-group">
          <div className="table-control-group-inner">
            {headerActions.map((action) => {
              const Action = TableViewActions[action];

              return <Action actionHelper={actionHelper} key={action} />;
            })}
          </div>
        </div>
      );
    }

    renderContent = emptyResults;
  }

  return (
    <VerticalHeightManager className="content-wrapper">
      <VerticalHeightManager.Section>{Heading}</VerticalHeightManager.Section>
      <VerticalHeightManager.Section maximize>
        {renderTableActions}
        {renderContent}
      </VerticalHeightManager.Section>
    </VerticalHeightManager>
  );
};

TableViewContentFactory.propTypes = {
  returnCount: PropTypes.number.isRequired,
  searchString: PropTypes.string,
  heading: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  emptyResults: PropTypes.node,
  headerActions: PropTypes.array,
  actionHelper: PropTypes.object,
  forceVisibleTable: PropTypes.bool,
};

TableViewContentFactory.defaultProps = {
  searchString: '',
  headerActions: null,
  actionHelper: {},
  forceVisibleTable: false,
  emptyResults: <noscript />,
};

export default TableViewContentFactory;
export { TableViewHeading, TableViewContent };

// as we transition all stores/actions to redux, use this TableViewContent
// class on tables to simplify bolierplate that standard redux stores will
// handle for us.
export class TableViewContentReduxTransitional extends TableViewContent {
  delegateActiveColumnsChange(newColumns) {
    this.props.setVisibleColumns(newColumns);
  }

  onSearchChange = (newValue) => {
    this.props.setFilter(newValue);
    this.setState({ search: newValue });
  };

  get searchString() {
    return this.props.searchString;
  }

  onHeaderClick = (...args: any) => {
    this.props.doSort(...args);
  };
}
