import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';

import withMessages, { intlPropTypes } from 'component-utilities/intl';
import { connect } from 'react-redux';

import EmptyResults from 'v-c/EmptyPageViews/SearchListsEmptyResults';
import ButtongroupCenter from 'v-c/Buttons/ButtonGroup/buttongroupCenter';

import Layout from '../../global/components/Layout';
import Breadcrumb from '../../global/components/Breadcrumb';
import HeadlineLabel from '../../global/components/HeadlineLabel';
import { jsUi } from '../../global/lib/jsUi';
import LoadWhenReady from '../../global/lib/LoadWhenReady';
import routeLeaveHook from '../../global/lib/routeLeaveHook';
import Loading from '../../global/components/Loading';

import TableView from './SearchLists/TableView';
import { actions } from '../../global/components/TableViewActions';

import FlashMessages, {
  flashConnect,
  PAGES,
} from '../../global/components/FlashMessages';

import { loadSearchLists, resetSearchLists } from '../../global/state_managers/SearchListsStateManager';
import { breadcrumbsActions } from '../../global/state_managers/BreadcrumbsStateManager';

import validateSettingsAccess from './components/validateSettingsAccess';

import './components/Settings.scss';

const BASE_KEY = 'app.empty_results.lists';

const Factory = Loadable({
  loader: () => import('../../global/components/TableView/TableViewContentFactory'),
  loading() {
    return <Loading />;
  },
})

const mapStateToProps = ({ searchLists }: any) => ({
  isSearchListsLoading: searchLists.isLoading,
  isSearchListsLoaded: searchLists.isLoaded,
  searchListsCount: searchLists.searchLists.length,
  searchString: searchLists.searchString,
  tableProps: {
    searchString: searchLists.searchString,
    searchLists: searchLists.searchLists,
    tableHelper: searchLists.tableHelper,
    sortOptions: searchLists.sortOptions,
  },
});

const mapActionsToProps = {
  loadSearchLists,
  resetSearchLists,
  setBreadcrumbForSettingsSearchListsPage:
    breadcrumbsActions.settingsSearchListsPage,
};

type Props = {
  loadSearchLists: Function,
  setBreadcrumbForSettingsSearchListsPage: Function,
  registerUnmountCleanupMethod: Function,
  resetSearchLists: Function,
  searchListsCount: number,
  getMessage: Function,
  searchString: string,
  tableProps: {},
  isSearchListsLoaded: boolean
}

@routeLeaveHook
@withMessages
@flashConnect(PAGES.LISTS, 'Settings/SearchLists')
@connect(
  mapStateToProps,
  mapActionsToProps,
)
@validateSettingsAccess('settings.search_lists')
export default class SearchLists extends Component<Props> {
  static propTypes = {
    ...intlPropTypes,
    loadSearchLists: PropTypes.func.isRequired,
    setBreadcrumbForSettingsSearchListsPage: PropTypes.func.isRequired,
    registerUnmountCleanupMethod: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    props.loadSearchLists();

    props.setBreadcrumbForSettingsSearchListsPage();
    props.registerUnmountCleanupMethod(() => {
      props.resetSearchLists();
    });
  }

  get tableHeading() {
    const { searchListsCount } = this.props;

    const text = this.props.getMessage('app.settings.search_lists.tab_name');
    const labelText = `${text} ${
      searchListsCount ? `(${searchListsCount})` : ''
    }`;

    return (
      <header>
        <FlashMessages />
        <Breadcrumb />
        <HeadlineLabel text={labelText} data-return-count={searchListsCount} />
      </header>
    );
  }

  // TODO: rename that property from role to something else
  /* eslint-disable jsx-a11y/aria-role */
  render() {
    const { searchString } = this.props;
    const emptyText = this.props.getMessage(`${BASE_KEY}.text`);
    const keywordButton = this.props.getMessage(`${BASE_KEY}.keyword_button`);
    const userButton = this.props.getMessage(`${BASE_KEY}.user_button`);
    const headerActions = this.props.searchListsCount
      ? [actions.NEW_WITH_DROPDOWN]
      : [];

    const factoryProps = {
      returnCount: this.props.searchListsCount,
      searchString,
      heading: this.tableHeading,
      headerActions,
      content: (
        <TableView
          {...this.props.tableProps}
          visibleTableActions={[actions.SEARCH, actions.NEW_WITH_DROPDOWN]}
        />
      ),
      emptyResults: (
        <EmptyResults role="placeholder">
          <ButtongroupCenter
            text={emptyText}
            items={[
              {
                title: 'keywordListCreate',
                content: keywordButton,
                onClick: () =>
                  jsUi.settingsSearchListsNew.goTo({ type: 'keyword' }),
              },
              {
                title: 'userListCreate',
                content: userButton,
                onClick: () =>
                  jsUi.settingsSearchListsNew.goTo({ type: 'user' }),
              },
            ]}
          />
        </EmptyResults>
      ),
    };

    return (
      <Layout section="settings search-lists">
        <LoadWhenReady readyStates={[this.props.isSearchListsLoaded]}>
          <Factory {...factoryProps} />
        </LoadWhenReady>
      </Layout>
    );
  }
}
